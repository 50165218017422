import React from "react";
import PropTypes from "prop-types";

import styles from "./GlowOverlay.module.scss";

const propTypesGlowOverlay = {};
const defaultPropsGlowOverlay = {};

const GlowOverlay = () => {
  return <div className={styles.container} />;
};

GlowOverlay.propTypes = propTypesGlowOverlay;
GlowOverlay.defaultProps = defaultPropsGlowOverlay;

export default GlowOverlay;
