import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import styles from "./FeatureDetail.module.scss";
import {
  GET_FEATURE_REQUEST_DETAILS,
  UPDATE_FEATURE_REQUEST,
} from "../../GraphQl/roadmap";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Spinner from "../../UI/Spinner/Spinner";
import BackButton from "../UI/BackButton/BackButton";
import FeatureHeader from "./FeatureHeader/FeatureHeader";
import { GET_CURRENT_USER } from "../../GraphQl/user";
import useAuthentication from "../../Utils/UseAuthentication";
import useFeatureRequestModal from "../UI/FeatureRequestModal/FeatureRequestModal";
import useDeleteFeatureRequestModal from "../UI/DeleteFeatureRequestModal/DeleteFeatureRequestModal";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import FeatureComments from "./FeatureComments/FeatureComments";
import useUserProfileModal from "../../UI/Modal/UserProfileModal/UserProfileModal";

const propTypesFeatureDetail = {
  match: PropTypes.object.isRequired,
};
const defaultPropsFeatureDetail = {};

const FeatureDetail = ({ match }) => {
  const { t } = useTranslation();
  const authenticated = useAuthentication();
  const history = useHistory();
  const { featureRequestId } = match.params;

  const [
    loadUser,
    { loading: getUserLoading, data: getUserData },
  ] = useLazyQuery(GET_CURRENT_USER);
  const user = getUserData ? getUserData.me : null;

  const {
    loading: getFeatureLoading,
    data: getFeatureData,
    refetch: refetchFeature,
  } = useQuery(GET_FEATURE_REQUEST_DETAILS, {
    variables: {
      id: featureRequestId,
    },
  });
  const featureRequest = getFeatureData ? getFeatureData.featureRequest : null;

  const [
    updateFeatureRequest,
    { loading: updateFeatureRequestLoading },
  ] = useMutation(UPDATE_FEATURE_REQUEST, {
    onError: (err) => {
      Logger.error(err);
    },
    onCompleted: () => {},
  });

  const isAuthor =
    user && featureRequest && user.id === featureRequest.author.id;
  const isAdmin = user && user.admin;

  const {
    open: openFeatureRequestModal,
    content: featureRequestModalContent,
  } = useFeatureRequestModal({
    onOpen: () => {},
    onClose: () => {},
  });

  const {
    open: openDeleteFeatureRequestModal,
    content: deleteFeatureRequestModalContent,
  } = useDeleteFeatureRequestModal({
    onOpen: () => {},
    onClose: () => {},
    onDelete: () => {
      history.push("/roadmap");
    },
  });

  const {
    open: openUserProfileModal,
    content: userProfileModalContent,
  } = useUserProfileModal({
    onOpen: () => {},
    onClose: () => {},
  });

  useEffect(() => {
    refetchFeature();
  }, []);

  useEffect(() => {
    if (authenticated) loadUser();
  }, [authenticated]);

  const handleEditClicked = () => {
    openFeatureRequestModal(featureRequest);
  };

  const handleDeleteClicked = () => {
    openDeleteFeatureRequestModal(featureRequest);
  };

  const handleChangeStatusClicked = (targetStatus) => {
    updateFeatureRequest({
      variables: {
        id: featureRequestId,
        input: {
          status: targetStatus,
        },
      },
    });
  };

  let content = null;
  if (getFeatureLoading || getUserLoading || updateFeatureRequestLoading) {
    content = <Spinner />;
  } else {
    let contentStatusUpButton = null;
    let contentStatusDownButton = null;

    if (isAdmin) {
      let targetStatusUp = null;
      let targetStatusDown = null;

      switch (featureRequest.status) {
        case "IDEA":
          targetStatusUp = "PLANNED";
          break;
        case "PLANNED":
          targetStatusUp = "IN_PROGRESS";
          targetStatusDown = "IDEA";
          break;
        case "IN_PROGRESS":
          targetStatusUp = "COMPLETE";
          targetStatusDown = "PLANNED";
          break;

        case "COMPLETE":
          targetStatusDown = "IN_PROGRESS";
          break;
      }

      if (targetStatusUp) {
        contentStatusUpButton = (
          <button
            className={`${styles.changeStatusButton} button is-light`}
            onClick={() => {
              handleChangeStatusClicked(targetStatusUp);
            }}
          >
            <span className={`icon ${styles.grayIcon}`}>
              <i className="fas fa-arrow-up" />
            </span>
            <span>{t(`wisdomtree.roadmap.status.${targetStatusUp}`)}</span>
          </button>
        );
      }

      if (targetStatusDown) {
        contentStatusDownButton = (
          <button
            className={`${styles.changeStatusButton} button is-light`}
            onClick={() => {
              handleChangeStatusClicked(targetStatusDown);
            }}
          >
            <span className={`icon ${styles.grayIcon}`}>
              <i className="fas fa-arrow-down" />
            </span>
            <span>{t(`wisdomtree.roadmap.status.${targetStatusDown}`)}</span>
          </button>
        );
      }
    }

    const contentComments = (
      <div className={styles.commentsContainer}>
        <FeatureComments
          featureRequestId={featureRequestId}
          comments={featureRequest.comments}
          onUserClicked={(userId) => {
            openUserProfileModal(userId);
          }}
          user={user}
        />
      </div>
    );

    let title = featureRequest
      ? `${featureRequest.title} – Roadmap – WisdomTree`
      : "Roadmap – WisdomTree";

    content = (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className={styles.contextButtonsContainer}>
          <BackButton linkTo="/roadmap" />
          {isAuthor || isAdmin ? (
            <div className={styles.editButtonsContainer}>
              <button
                className={`${styles.editButton} button is-light`}
                onClick={handleEditClicked}
              >
                <span className={`icon ${styles.grayIcon}`}>
                  <i className="fas fa-pen" />
                </span>
              </button>
              <button
                className={`${styles.deleteButton} button is-light`}
                onClick={handleDeleteClicked}
              >
                <span className={`icon ${styles.grayIcon}`}>
                  <i className="fas fa-trash" />
                </span>
              </button>
            </div>
          ) : null}
          {contentStatusUpButton}
          {contentStatusDownButton}
        </div>
        <div>
          <FeatureHeader
            feature={featureRequest}
            onUserClicked={(userId) => {
              openUserProfileModal(userId);
            }}
          />
        </div>
        {contentComments}
      </>
    );
  }

  return (
    <div>
      {content}
      {featureRequestModalContent}
      {deleteFeatureRequestModalContent}
      {userProfileModalContent}
    </div>
  );
};

FeatureDetail.propTypes = propTypesFeatureDetail;
FeatureDetail.defaultProps = defaultPropsFeatureDetail;

export default FeatureDetail;
