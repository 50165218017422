/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { TooltipDelayed } from "../../Tooltip/Tooltip";

import styles from "./DropdownItems.module.scss";
import QuestIcon from "../../QuestIcon/QuestIcon";

const propTypesDropdownItems = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.string,
      text: PropTypes.string,
      content: PropTypes.element,
      tooltip: PropTypes.string,
    })
  ).isRequired,
  onItemClicked: PropTypes.func.isRequired,
};
const defaultPropsDropdownItems = {};

const DropdownItems = ({ items, onItemClicked }) => {
  const contentDropdownItems = items.map((item) => {
    let contentItem = null;
    if (item.content) {
      contentItem = item.content;
    } else {
      let contentIcon = null;
      if (item.icon) {
        contentIcon = (
          <span
            className={`icon ${styles.menuIcon} ${
              item.iconActive ? styles.iconActive : null
            }`}
          >
            <i className={`fas ${item.icon}`} />
          </span>
        );
      }

      let contentText = null;
      if (item.text) {
        contentText = <p className={styles.typeText}>{item.text}</p>;
      }

      contentItem = (
        <>
          {contentIcon}
          {contentText}
          {item.hasQuest ? (
            <div className={styles.questContainer}>
              <QuestIcon />
            </div>
          ) : null}
        </>
      );
    }

    let contentWithLink = (
      <a
        key={item.key}
        onMouseDown={() => onItemClicked(item)}
        className={`dropdown-item ${styles.dropdownItem}`}
        role="menuitem"
      >
        {contentItem}
      </a>
    );

    if (item.tooltip) {
      contentWithLink = (
        <TooltipDelayed content={item.tooltip} key={item.key} placement="right">
          {contentWithLink}
        </TooltipDelayed>
      );
    }

    return contentWithLink;
  });
  return contentDropdownItems;
};

DropdownItems.propTypes = propTypesDropdownItems;
DropdownItems.defaultProps = defaultPropsDropdownItems;

export default DropdownItems;
