import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDoubleTap } from "use-double-tap";

import styles from "./ReferenceLink.module.scss";
import { truncate } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import BlockIdentifier from "../../BlockIdentifier/BlockIdentifier";
import Reference from "../Reference/Reference";

const propTypesReferenceLink = {
  povType: PropTypes.string.isRequired,
  povId: PropTypes.string.isRequired,
  deleteLoading: PropTypes.bool,
  editMode: PropTypes.bool,
  onGoTo: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  reference: PropTypes.shape({
    descriptionTo: PropTypes.string,
    descriptionFrom: PropTypes.string,
    source: PropTypes.shape({
      entry: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      block: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
        entry: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          image: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
    target: PropTypes.shape({
      entry: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      block: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
        entry: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          image: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }).isRequired,
};
const defaultPropsReferenceLink = {
  deleteLoading: false,
  sharingId: null,
  editMode: true,
};

const ReferenceLink = ({
  reference,
  povType,
  povId,
  deleteLoading,
  editMode,
  onGoTo,
  onEdit,
  onRemove,
}) => {
  const { t } = useTranslation();

  let text;
  let image;
  let description;
  let block;

  let povIsSource = false;
  let povIsTarget = false;

  const sourceIsEntry = reference.source.entry != null;
  const sourceIsBlock = reference.source.block != null;

  const targetIsEntry = reference.target.entry != null;
  const targetIsBlock = reference.target.block != null;

  let referencedType;
  let referencedEntryId;
  let referencedBlockId;

  if (povType === "entry") {
    if (sourceIsEntry && reference.source.entry.id === povId) {
      povIsSource = true;
    } else {
      povIsTarget = true;
    }
  } else if (povType === "block") {
    if (sourceIsBlock && reference.source.block.id === povId) {
      povIsSource = true;
    } else {
      povIsTarget = true;
    }
  }

  if (povIsSource) {
    if (targetIsEntry) {
      text = reference.target.entry.name;
      image = reference.target.entry.image;
      referencedType = "entry";
      referencedEntryId = reference.target.entry.id;
    } else {
      text = reference.target.block.entry.name;
      image = reference.target.block.entry.image;
      block = reference.target.block;
      referencedType = "block";
      referencedEntryId = reference.target.block.entry.id;
      referencedBlockId = reference.target.block.id;
    }
    description = reference.descriptionTo;
  } else {
    if (sourceIsEntry) {
      text = reference.source.entry.name;
      image = reference.source.entry.image;
      referencedType = "entry";
      referencedEntryId = reference.source.entry.id;
    } else {
      text = reference.source.block.entry.name;
      image = reference.source.block.entry.image;
      block = reference.source.block;
      referencedType = "block";
      referencedEntryId = reference.source.block.entry.id;
      referencedBlockId = reference.source.block.id;
    }
    description = reference.descriptionFrom;
  }

  const onItemClicked = (item) => {
    if (item.key === "goto") {
      onGoTo(referencedType, referencedEntryId, referencedBlockId);
    }
    if (item.key === "edit") {
      onEdit();
    }
    if (item.key === "remove") {
      onRemove();
    }
  };

  const dropdownItems = [
    {
      key: "goto",
      text:
        block == null
          ? t("wisdomtree.entry_browser.reference_goto_entry")
          : t("wisdomtree.entry_browser.reference_goto_block"),

      icon: "fa-eye",
      tooltip:
        block == null
          ? t("wisdomtree.entry_browser.tooltips.reference_goto_entry")
          : t("wisdomtree.entry_browser.tooltips.reference_goto_block"),
    },
  ];

  if (editMode) {
    dropdownItems.push({
      key: "edit",
      text: t("wisdomtree.entry_browser.reference_edit"),
      icon: "fa-pen",
      tooltip: t("wisdomtree.entry_browser.tooltips.reference_edit"),
    });
    dropdownItems.push({
      key: "remove",
      text: t("wisdomtree.entry_browser.reference_remove"),
      icon: "fa-unlink",
      tooltip: t("wisdomtree.entry_browser.tooltips.reference_remove"),
    });
  }

  let titleNode;
  if (block) {
    titleNode = <BlockIdentifier block={block} />;
  } else {
    titleNode = <p className={styles.text}>{truncate(text, 32, true)}</p>;
  }

  return (
    <Reference
      titleNode={titleNode}
      imageUrl={image ? image.url : null}
      imagePlaceholderIcon="fa-image"
      description={description ? truncate(description, 16, true) : null}
      onDoubleTap={() => {
        onGoTo(referencedType, referencedEntryId, referencedBlockId);
      }}
      dropdownItems={dropdownItems}
      onDropdownItemClicked={onItemClicked}
      deleteLoading={deleteLoading}
      previewImage={true}
    />
  );
};

ReferenceLink.propTypes = propTypesReferenceLink;
ReferenceLink.defaultProps = defaultPropsReferenceLink;

export default ReferenceLink;
