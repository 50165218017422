import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import styles from "./Inbox.module.scss";
import { GET_UNREAD_MESSAGES_OF_USERS } from "../../../GraphQl/feedback";
import Spinner from "../../../UI/Spinner/Spinner";
import UnreadMessage from "./UnreadMessage/UnreadMessage";
import Title from "../../UI/Title/Title";

const propTypesInbox = {};
const defaultPropsInbox = {};

const Inbox = () => {
  const [unreadConversations, setUnreadConversations] = useState([]);

  const {
    loading: getUnreadMessagesLoading,
    data: getUnreadMessagesData,
    refetch: refetchUnreadMessages,
  } = useQuery(GET_UNREAD_MESSAGES_OF_USERS, {
    pollInterval: 4000,
  });

  useEffect(() => {
    refetchUnreadMessages();
  }, []);

  useEffect(() => {
    if (getUnreadMessagesData && getUnreadMessagesData.unreadMessagesOfUsers) {
      const conversations = {};
      getUnreadMessagesData.unreadMessagesOfUsers.forEach((message) => {
        if (conversations[message.fromUser.id] == null)
          conversations[message.fromUser.id] = {};

        conversations[message.fromUser.id].user = message.fromUser;

        const messagesFromUser = conversations[message.fromUser.id].messages;

        if (messagesFromUser != null) {
          messagesFromUser.push(message);
        } else {
          conversations[message.fromUser.id].messages = [message];
        }
      });
      Object.keys(conversations).forEach((userId) => {
        const latestMessage = conversations[userId].messages.reduce(
          (prev, current) => {
            return prev.createdAt > current.createdAt ? prev : current;
          }
        );
        conversations[userId].latestMessageTimestamp = latestMessage.createdAt;
      });
      let arrayFromObject = Object.values(conversations);
      arrayFromObject = arrayFromObject.sort((a, b) => {
        return a.createdAt - b.createdAt;
      });
      setUnreadConversations(arrayFromObject);
    }
  }, [getUnreadMessagesData]);

  let content = null;
  if (getUnreadMessagesLoading) {
    content = <Spinner />;
  } else {
    content = unreadConversations.map((conversation) => {
      return (
        <UnreadMessage
          key={conversation.user.id}
          user={conversation.user}
          timestamp={conversation.latestMessageTimestamp}
        />
      );
    });
  }

  if (unreadConversations.length > 0) {
    return (
      <div className={styles.container}>
        <Title text="Inbox" icon="fa-inbox" />

        {content}
      </div>
    );
  }
  return <div />;
};

Inbox.propTypes = propTypesInbox;
Inbox.defaultProps = defaultPropsInbox;

export default Inbox;
