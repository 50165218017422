import { gql } from "@apollo/client";
import {
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
  FRAGMENT_USER_UPDATE,
} from "./fragments";
import { mergeUserUpdates, processExpEvent } from "./user";

const FRAGMENT_BLOCK_DAILY_QUOTE = gql`
  fragment DailyQuoteBlock on Block {
    id
    entryId
    type
    position
    title
    content
    source
    image {
      id
      url
    }
    dailyQuote
  }
`;

const GET_DAILY_QUOTE_TODAY_UNSEEN = gql`
  query {
    dailyQuoteTodayUnseen {
      id
      block {
        ...DailyQuoteBlock
      }
      viewed
      createdAt
    }
  }
  ${FRAGMENT_BLOCK_DAILY_QUOTE}
`;

const GET_DAILY_QUOTE = gql`
  query($id: ID!) {
    dailyQuote(id: $id) {
      id
      block {
        ...DailyQuoteBlock
      }
      viewed
      createdAt
    }
  }
  ${FRAGMENT_BLOCK_DAILY_QUOTE}
`;

const MARK_DAILY_QUOTE_AS_VIEWED = gql`
  mutation($id: ID!) {
    markDailyQuoteAsViewed(id: $id) {
      code
      message
      updatedUser {
        ...UserUpdate
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const mergeAfterViewDailyQuote = (cache, mutationResult) => {
  const { updatedUser } = mutationResult.data.markDailyQuoteAsViewed;
  const { expEvent } = mutationResult.data.markDailyQuoteAsViewed;

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }
};

export {
  GET_DAILY_QUOTE_TODAY_UNSEEN,
  GET_DAILY_QUOTE,
  MARK_DAILY_QUOTE_AS_VIEWED,
  mergeAfterViewDailyQuote,
};
