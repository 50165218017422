/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
import styles from "./DeleteBlockModal.module.css";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onDeleteBlock: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  nested: PropTypes.bool,
  entry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  loading: false,
  nested: false,
};

const useDeleteBlockModal = ({
  onOpen,
  onClose,
  entry,
  onDeleteBlock,
  loading,
  nested,
}) => {
  const { isOpened, open, close } = useBaseModal({ onOpen, onClose });
  const [block, setBlock] = useState(null);

  let content = null;
  if (isOpened) {
    content = (
      <DeleteBlockModal
        show={isOpened}
        onCloseHandler={close}
        entry={entry}
        block={block}
        onDeleteHandler={onDeleteBlock}
        deleteLoading={loading}
        nested={nested}
      />
    );
  }

  const openModal = (blockToDelete) => {
    setBlock(blockToDelete);
    open();
  };

  const closeModal = () => {
    close();
    setBlock(null);
  };

  return { open: openModal, close: closeModal, content };
};

useDeleteBlockModal.propTypes = propTypes;
useDeleteBlockModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  block: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
  entry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  deleteLoading: PropTypes.bool.isRequired,
  onDeleteHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
};

const DeleteBlockModal = ({
  show,
  block,
  entry,
  deleteLoading,
  onDeleteHandler,
  onCloseHandler,
}) => {
  const { t } = useTranslation();
  let text = t("wisdomtree.modal_delete_block.text_no_title", {
    entry_name: entry.name,
  });
  if (block.title) {
    text = t("wisdomtree.modal_delete_block.text_with_title", {
      entry_name: entry.name,
      block_title: block.title,
    });
  }

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!show) return;
      onDeleteHandler(block);
    },
    dependsOn: [onDeleteHandler, show],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!show) return;
      onCloseHandler();
    },
    dependsOn: [onCloseHandler, show],
  });

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_delete_block.title")}
          </p>
          <ModalCloseButton onClick={onCloseHandler} disabled={deleteLoading} />
        </header>
        <section className="modal-card-body">
          <p>{text}</p>
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-danger ${deleteLoading ? `is-loading` : ``}`}
            type="submit"
            onClick={() => {
              onDeleteHandler(block);
            }}
          >
            {t("wisdomtree.modal_delete_block.delete")}
          </button>
          <button
            className="button"
            type="button"
            onClick={onCloseHandler}
            disabled={deleteLoading}
          >
            {t("wisdomtree.modal_delete_block.cancel")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

DeleteBlockModal.propTypes = propTypesModal;

export default useDeleteBlockModal;
