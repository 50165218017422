import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import modalStyles from "../ChooseImageModal.module.scss";
import styles from "./ViewImage.module.scss";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import { useHotkey } from "../../../../Utils/Hotkeys";
import ImageGradient from "../SearchImage/ImageGallery/Image/ImageGradient/ImageGradient";
import ImageSourceInfo from "../SearchImage/ImageGallery/Image/ImageSourceInfo/ImageSourceInfo";
import useResizeObserver from "use-resize-observer";

const propTypesViewImage = {
  image: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  entityName: PropTypes.string,
  entityType: PropTypes.string,
  onRemoveImage: PropTypes.func.isRequired,
  onReplaceImage: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
};
const defaultPropsViewImage = {
  entityName: null,
  entityType: null,
  editMode: true,
};

const ViewImage = ({
  image,
  entityName,
  entityType,
  onRemoveImage,
  onReplaceImage,
  onCloseModal,
  editMode,
}) => {
  const { t } = useTranslation();
  const { ref: imageRef, width: imageWidth = 0 } = useResizeObserver();

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onCloseModal();
    },
    dependsOn: [],
  });

  let bodyContent = null;
  let footerContent = null;

  let sourceInfoContent = null;
  if (image.source && image.author && image.authorUrl) {
    sourceInfoContent = (
      <>
        <div className={styles.gradient} style={{ width: imageWidth }}>
          <ImageGradient />
        </div>
        <div className={styles.info}>
          <ImageSourceInfo
            source={image.source}
            author={image.author}
            authorUrl={image.authorUrl}
          />
        </div>
      </>
    );
  }

  bodyContent = (
    <div className={styles.imageContainer}>
      <img
        ref={imageRef}
        className={styles.image}
        src={image.url}
        alt="preview"
      />
      {sourceInfoContent}
    </div>
  );

  footerContent = (
    <footer className="modal-card-foot">
      {editMode ? (
        <>
          <button className="button" type="button" onClick={onRemoveImage}>
            {t("wisdomtree.modal_choose_image.view.remove")}
          </button>
          <button className="button" type="button" onClick={onReplaceImage}>
            {t("wisdomtree.modal_choose_image.view.replace")}
          </button>
        </>
      ) : null}
      <button className="button" type="button" onClick={onCloseModal}>
        {t("wisdomtree.modal_choose_image.view.finish")}
      </button>
    </footer>
  );

  let title = t("wisdomtree.modal_choose_image.view.title");
  if (entityName != null) {
    title = entityName;
  } else if (entityType != null) {
    title = t(`wisdomtree.modal_choose_image.view.title_${entityType}`);
  }

  return (
    <div className={`modal-card ${styles.modalCard}`}>
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${modalStyles.cardBody}`}>
        {bodyContent}
      </section>
      {footerContent}
    </div>
  );
};

ViewImage.propTypes = propTypesViewImage;
ViewImage.defaultProps = defaultPropsViewImage;

export default ViewImage;
