import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import styles from "./IntroVideo.module.scss";
import tutorialStyles from "../../TutorialModal.module.scss";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import Spinner from "../../../../Spinner/Spinner";

const propTypesIntroVideo = {
  videoUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onHighestProgressUpdate: PropTypes.func,
};
const defaultPropsIntroVideo = {
  onHighestProgressUpdate: () => {},
};

const IntroVideo = ({ videoUrl, text, onHighestProgressUpdate }) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  const [highestProgressPercent, setHighestProgressPercent] = useState(0);
  const [playerReady, setPlayerReady] = useState(false);

  useEffect(() => {
    onHighestProgressUpdate(highestProgressPercent);
  }, [highestProgressPercent]);

  let videoWidth = 640;
  let videoHeight = 360;
  if (containerRef.current) {
    videoWidth = Math.min(videoWidth, containerRef.current.clientWidth);
    videoHeight = videoWidth * 0.5625;
  }

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.videoContainer}>
        {!playerReady ? (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        ) : null}

        <ReactPlayer
          ref={playerRef}
          onProgress={() => {
            const duration = playerRef.current.getDuration();
            const curentTime = playerRef.current.getCurrentTime();

            let progressPercent = 0;
            if (duration != null && curentTime != null && curentTime > 0) {
              progressPercent = Math.round((curentTime / duration) * 100);
            }

            if (progressPercent > highestProgressPercent)
              setHighestProgressPercent(progressPercent);
          }}
          controls
          onReady={() => setPlayerReady(true)}
          url={videoUrl}
          width={videoWidth}
          height={videoHeight}
        />
      </div>

      <span className={`${tutorialStyles.text}`}>{text}</span>
    </div>
  );
};

IntroVideo.propTypes = propTypesIntroVideo;
IntroVideo.defaultProps = defaultPropsIntroVideo;

export default IntroVideo;
