import React from "react";
import PropTypes from "prop-types";

import styles from "./CommonEntryImageSearchQueries.module.scss";

const propTypesCommonEntryImageSearchQueries = {
  imageSearchQueries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      query: PropTypes.string,
      userCount: PropTypes.integer,
      approved: PropTypes.bool,
      createdAt: PropTypes.string,
    })
  ).isRequired,
};
const defaultPropsCommonEntryImageSearchQueries = {};

const CommonEntryImageSearchQueries = ({ imageSearchQueries }) => {
  const queryContent = imageSearchQueries.map((imageQuery) => {
    return (
      <div className={styles.imageQueryContainer}>
        <span className="tag is-normal">{`${imageQuery.query} (${imageQuery.userCount})`}</span>
      </div>
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.searchIconContainer}>
        <span className={`icon ${styles.gray}`}>
          <i className="fas fa-search" />
        </span>
      </div>
      {queryContent}
    </div>
  );
};

CommonEntryImageSearchQueries.propTypes = propTypesCommonEntryImageSearchQueries;
CommonEntryImageSearchQueries.defaultProps = defaultPropsCommonEntryImageSearchQueries;

export default CommonEntryImageSearchQueries;
