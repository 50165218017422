/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";

const propTypes = {
  editMode: PropTypes.bool,
  elementId: PropTypes.string,
  elementTitle: PropTypes.string,
  hintText: PropTypes.string,
  stylesClassName: PropTypes.string,
  focusInitially: PropTypes.bool,
  onTitleChanged: PropTypes.func,
  titleCanBeUpdatedByProps: PropTypes.bool,
  limit: PropTypes.number,
};

const defaultProps = {
  editMode: false,
  elementId: null,
  elementTitle: null,
  stylesClassName: null,
  hintText: null,
  focusInitially: false,
  onTitleChanged: () => {},
  titleCanBeUpdatedByProps: false,
  limit: 50,
};

class TitleNameQuillEditor extends React.Component {
  bindings = {
    enter: {
      key: 13,
      handler: this.handleEnter.bind(this),
    },
    escape: {
      key: 27,
      handler: this.handleEsc.bind(this),
    },
    tab: {
      key: 9,
      handler: () => {
        return true;
      },
    },
  };

  modules = {
    keyboard: {
      bindings: this.bindings,
    },
    preventImages: true,
  };

  constructor(props) {
    super(props);
    this.quillRef = null; // Quill instance
    this.reactQuillRef = null; // ReactQuill component
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.attachQuillRefs();

    let initialContent = "";
    if (this.props.elementTitle) {
      initialContent = this.props.elementTitle;
    }
    this.updatePlaceholderAccordingToEditMode();
    this.quillRef.setText(initialContent);
    this.quillRef.setSelection(null);
    if (this.props.focusInitially) {
      setTimeout(() => {
        if (this.props.editMode) this.quillRef.focus();
      }, 25);
    }

    this.quillRef.on("text-change", this.onTextChange.bind(this));
  }

  componentDidUpdate(prevProps) {
    this.attachQuillRefs();

    if (prevProps.editMode !== this.props.editMode) {
      this.updatePlaceholderAccordingToEditMode();
    }

    if (prevProps.elementId !== this.props.elementId) {
      this.updateStateForEntry();
    }

    if (
      this.props.titleCanBeUpdatedByProps &&
      prevProps.elementTitle !== this.props.elementTitle
    ) {
      const currentText = this.quillRef.getText().trim();
      if (currentText !== this.props.elementTitle) {
        if (this.props.elementTitle) {
          this.quillRef.setText(this.props.elementTitle);
        } else {
          this.quillRef.setText("");
        }
      }
    }

    if (prevProps.focusInitially !== this.props.focusInitially) {
      if (this.props.focusInitially) this.quillRef.focus();
    }
  }

  onTextChange(delta) {
    const limit = this.props.limit;
    if (this.quillRef.getLength() <= limit) {
      return;
    }
    const { ops } = delta;
    let updatedOps;
    if (ops.length === 1) {
      // text is inserted at the beginning
      updatedOps = [{ delete: ops[0].insert.length }];
    } else {
      if (ops[1].insert) {
        updatedOps = [ops[0], { delete: ops[1].insert.length }];
      } else if (ops[1].delete) {
        updatedOps = [ops[0], { delete: ops[1].delete }];
      }
    }
    this.quillRef.updateContents({ ops: updatedOps });
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  focusEditor = () => {
    this.quillRef.focus();
  };

  handleChange() {
    let text = this.quillRef.getText().trim();
    if (this.props.elementTitle !== text) {
      text = text.replace(/(\r\n|\n|\r)/gm, "");
      this.props.onTitleChanged(text);
    }
  }

  updatePlaceholderAccordingToEditMode() {
    this.quillRef.root.dataset.placeholder = this.props.editMode
      ? this.props.hintText
      : "";
  }

  updateStateForEntry() {
    if (this.props.elementTitle) {
      this.quillRef.setText(this.props.elementTitle);
    } else {
      this.quillRef.setContents({ ops: [] });
    }
    this.quillRef.setSelection(null);
  }

  handleEnter() {
    // this.props.onEnterPressed();
    return false;
  }

  handleEsc() {
    // this.props.onEscPressed();
    return true;
  }

  render() {
    const emptyDefault = "";
    return (
      <div
        className={`${this.props.stylesClassName} ${
          this.props.editMode ? "editMode" : "readMode"
        }`}
      >
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          theme={null}
          modules={this.modules}
          defaultValue={emptyDefault}
          onChange={this.handleChange}
          readOnly={!this.props.editMode}
          formats={[]}
        />
      </div>
    );
  }
}

TitleNameQuillEditor.propTypes = propTypes;
TitleNameQuillEditor.defaultProps = defaultProps;

export default TitleNameQuillEditor;
