import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import tinycolor from "tinycolor2";

import styles from "./Tag.module.scss";
import DropdownTippy from "../../DropdownTippy/DropdownTippy";
import {
  getDefaultTagColor,
  getDefaultTagColorSelected,
  getTagTextColor,
  isBrightnessLight,
} from "../../../Utils/Utils";

const propTypesTag = {
  tag: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.shape({
      hex: PropTypes.string,
    }),
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  onChangeColor: PropTypes.func.isRequired,
  onDeleteEverywhere: PropTypes.func.isRequired,
  tooltipRemove: PropTypes.string,
  tooltipDeleteEverywhere: PropTypes.string,
  editMode: PropTypes.bool,
};
const defaultPropsTag = {
  tooltipRemove: null,
  tooltipDeleteEverywhere: null,
  editMode: true,
};

const Tag = ({
  tag,
  onRemove,
  onChangeColor,
  onDeleteEverywhere,
  tooltipRemove,
  tooltipDeleteEverywhere,
  editMode,
}) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const dropdownItems = [
    {
      key: "remove",
      text: t("wisdomtree.entry_browser.tag_remove"),
      icon: "fa-minus",
      tooltip: tooltipRemove,
    },
    {
      key: "color",
      text: tag.color
        ? t("wisdomtree.entry_browser.tag_change_color")
        : t("wisdomtree.entry_browser.tag_set_color"),
      icon: "fa-palette",
      tooltip: tag.color
        ? t("wisdomtree.entry_browser.tooltips.tag_change_color")
        : t("wisdomtree.entry_browser.tooltips.tag_set_color"),
    },
    {
      key: "delete",
      text: t("wisdomtree.entry_browser.tag_delete_everywhere"),
      icon: "fa-trash",
      tooltip: tooltipDeleteEverywhere,
    },
  ];

  const onItemClicked = (item) => {
    if (item.key === "remove") {
      onRemove(tag);
    }
    if (item.key === "color") {
      onChangeColor(tag);
    }
    if (item.key === "delete") {
      onDeleteEverywhere(tag);
    }
    setDropdownOpened(false);
  };

  const isDarkMode = document.documentElement.className.includes("theme-dark");
  const defaultTagColor = getDefaultTagColor(isDarkMode);
  const defaultTagColorSelected = getDefaultTagColorSelected(isDarkMode);

  let tagColor = tag.color ? tag.color.hex : defaultTagColor;
  const brightness = tinycolor(tagColor).getBrightness();
  const isLight = isBrightnessLight(brightness);

  if (editMode && (hovered || dropdownOpened)) {
    if (tag.color) {
      tagColor = isLight
        ? tinycolor(tagColor).darken(6)
        : tinycolor(tagColor).lighten(6);
    } else {
      tagColor = defaultTagColorSelected;
    }
  }

  const textColor = getTagTextColor(brightness);

  return (
    <DropdownTippy
      visible={dropdownOpened}
      onClickOutside={() => setDropdownOpened(false)}
      items={dropdownItems}
      onItemClicked={onItemClicked}
    >
      <span
        className={`tag is-light is-normal ${styles.tag} ${
          dropdownOpened ? styles.active : null
        } ${editMode ? styles.editable : null}`}
        style={{ backgroundColor: tagColor, color: textColor }}
        onClick={() => {
          if (!editMode) return;
          setDropdownOpened(!dropdownOpened);
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        role="button"
        tabIndex="0"
        onKeyDown={(e) => {
          if (!editMode) return;
          // enter
          if (e.keyCode === 13) {
            setDropdownOpened(true);
          }

          // esc key
          if (e.keyCode === 27) {
            setDropdownOpened(false);
          }

          //   // esc key
          //   if (e.keyCode === 46 || e.keyCode === 8) {
          //     onRemove(tag);
          //   }
        }}
      >
        {tag.name}
      </span>
    </DropdownTippy>
  );
};

Tag.propTypes = propTypesTag;
Tag.defaultProps = defaultPropsTag;

export default Tag;
