import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { throttle } from "lodash";

import styles from "./NewQuest.module.scss";
import useTutorialModal from "../../UI/Modal/TutorialModal/TutorialModal";
import {
  START_NEXT_QUEST,
  mergeUserAfterStartNextQuest,
} from "../../GraphQl/quests";
import { useMutation } from "@apollo/client";
import Logger from "js-logger";
import { getContentForQuest } from "../../Utils/QuestContent";

const propTypesNewQuest = {
  user: PropTypes.shape({
    id: PropTypes.string,
    nextQuest: PropTypes.string,
  }).isRequired,
  onDialogOpenedChanged: PropTypes.func.isRequired,
};
const defaultPropsNewQuest = {};

const NewQuest = ({ user, onDialogOpenedChanged }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const visibleRef = useRef(visible);

  useEffect(() => {
    window.addEventListener("scroll", throttle(handleWindowScroll, 10));
    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);

  useEffect(() => {
    visibleRef.current = visible;
  }, [visible]);

  const [startQuest] = useMutation(START_NEXT_QUEST, {
    update: (cache, mutationResult) => {
      mergeUserAfterStartNextQuest(cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  const {
    open: openTutorialModal,
    content: tutorialModalContent,
  } = useTutorialModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
  });

  const handleWindowScroll = () => {
    const graphContainer = document.getElementById("graphContainer");
    const scrollYMax = graphContainer ? graphContainer.clientHeight - 300 : 0;

    if (window.scrollY > scrollYMax) {
      if (visibleRef.current) {
        setVisible(false);
      }
    }

    if (window.scrollY < scrollYMax) {
      if (!visibleRef.current) {
        setVisible(true);
      }
    }
  };

  const clickOnNewQuest = () => {
    openTutorialModal({
      cancelable: true,
      content: getContentForQuest(user.nextQuest, user.jobToBeDone, true),
      onFinish: async () => {
        await startQuest();
      },
    });
  };

  return (
    <>
      <div
        className={`${styles.container} ${
          visible && !tutorialModalContent ? styles.visible : styles.invisible
        }`}
      >
        <div
          className={`box ${styles.innerContainer}`}
          data-id="button.new-quest"
          onClick={() => {
            clickOnNewQuest();
          }}
        >
          <div className={styles.questInfo}>
            <div className={styles.questInfoText}>
              <span>{t("wisdomtree.quests.new_quest")}</span>
            </div>
            <span className={`icon ${styles.icon}`}>
              <i className={`fas fa-exclamation-circle`} />
            </span>
          </div>
        </div>
      </div>
      {tutorialModalContent}
    </>
  );
};

NewQuest.propTypes = propTypesNewQuest;
NewQuest.defaultProps = defaultPropsNewQuest;

export default NewQuest;
