import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./Reference.module.scss";
import DropdownTippy from "../../DropdownTippy/DropdownTippy";
import { useDoubleTap } from "use-double-tap";
import ClickableImage from "../../ClickableImage/ClickableImage";

const propTypesReference = {
  title: PropTypes.string,
  titleNode: PropTypes.node,
  imageUrl: PropTypes.string,
  imagePlaceholderIcon: PropTypes.string,
  description: PropTypes.string,
  previewImage: PropTypes.bool,
  deleteLoading: PropTypes.bool.isRequired,
  dropdownItems: PropTypes.array.isRequired,
  onDropdownItemClicked: PropTypes.func.isRequired,
  onDoubleTap: PropTypes.func,
};
const defaultPropsReference = {
  previewImage: false,
  imageUrl: null,
  description: null,
  imagePlaceholderIcon: null,
  onDoubleTap: () => {},
};

const Reference = ({
  title,
  titleNode,
  imageUrl,
  imagePlaceholderIcon,
  description,
  previewImage,
  deleteLoading,
  dropdownItems,
  onDropdownItemClicked,
  onDoubleTap,
}) => {
  const [hovered, setHovered] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const bind = useDoubleTap(() => {
    onDoubleTap();
    setDropdownOpened(false);
  });

  let imageContent = null;

  if (imageUrl != null) {
    imageContent = (
      <div className={styles.image}>
        <ClickableImage
          preview={previewImage}
          height={24}
          width={24}
          imageUrl={imageUrl}
          onImageClicked={() => {}}
          dropAllowed={false}
        />
      </div>
    );
  } else if (imagePlaceholderIcon != null) {
    imageContent = (
      <span className={`icon is-small ${styles.imagePlaceholder}`}>
        <i
          className={`fas ${imagePlaceholderIcon} ${styles.imagePlaceholderIcon}`}
        />
      </span>
    );
  }

  return (
    <div {...bind}>
      <DropdownTippy
        visible={dropdownOpened && !deleteLoading}
        onClickOutside={() => setDropdownOpened(false)}
        items={dropdownItems}
        onItemClicked={(item) => {
          onDropdownItemClicked(item);
          setDropdownOpened(false);
        }}
      >
        <span
          className={`tag is-light is-medium ${styles.container} ${
            deleteLoading ? styles.deleteLoading : null
          } ${hovered || dropdownOpened ? styles.hovered : null}`}
          onClick={() => setDropdownOpened(!dropdownOpened)}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            // enter
            if (e.keyCode === 13) {
              setDropdownOpened(true);
            }

            // esc key
            if (e.keyCode === 27) {
              setDropdownOpened(false);
            }
          }}
        >
          <div className={styles.iconContainer}>
            <span className={`icon is-small ${styles.icon}`}>
              <i className="fas fa-link" />
            </span>
          </div>
          {description ? (
            <p className={styles.description}>{description}:</p>
          ) : null}
          {imageContent}
          {titleNode ? titleNode : title}
          {/* {description ? (
            <span className={styles.description}>— {description}</span>
          ) : null} */}
        </span>
      </DropdownTippy>
    </div>
  );
};

Reference.propTypes = propTypesReference;
Reference.defaultProps = defaultPropsReference;

export default Reference;
