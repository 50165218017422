/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { useMutation, gql } from "@apollo/client";
import validator from "email-validator";
import queryString from "query-string";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Logger from "js-logger";
import { Helmet } from "react-helmet";
import classNames from "classnames";

import Input from "../../UI/Input/Input";
import ErrorMessages from "../../Utils/ErrorMessages";
import styles from "./Login.module.scss";
import { isLoggedInVar } from "../../../config/apollo-cache";
import GoogleLoginWrapper from "../GoogleLoginWrapper/GoogleLoginWrapper";
import SeparatorWithText from "../../UI/SeparatorWithText/SeparatorWithText";

const propTypes = {
  location: PropTypes.object.isRequired,
};

const defaultProps = {};

const LOGIN_USER = gql`
  mutation($input: LoginUserInput!) {
    loginUser(input: $input) {
      code
      message
      token
    }
  }
`;

const VERIFY_USER = gql`
  mutation($token: String!) {
    verifyUser(token: $token) {
      code
      message
    }
  }
`;

const Login = ({ location }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [loginUser, { loading: loginUserLoading }] = useMutation(LOGIN_USER, {
    onError: (error) => {
      Logger.error(error);
      const errorCode = ErrorMessages.getErrorCode(error);
      setUnspecificError(errorCode);
    },
    onCompleted: (data) => {
      toast.dismiss();
      localStorage.setItem("token", data.loginUser.token);
      isLoggedInVar(true);
    },
  });
  const [verifyUser] = useMutation(VERIFY_USER, {
    onError: (error) => {
      Logger.error(error);
      const errorCode = ErrorMessages.getErrorCode(error);
      if (errorCode === "VERIFICATION_TOKEN_EXPIRED") {
        toast.info(t(`error.${errorCode}`));
      } else {
        toast.error(t(`error.${errorCode}`));
      }

      history.push("/auth/login");
    },
    onCompleted: () => {
      toast.info(t(`auth.signup_validation_successful`));
      history.push("/auth/login");
    },
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [unspecificError, setUnspecificError] = useState(null);

  useEffect(() => {
    if (location && location.search) {
      const params = queryString.parse(location.search);

      const { verifyToken } = params;
      if (verifyToken) {
        verifyUser({
          variables: { token: verifyToken },
        });
      }
    }
  }, [location, verifyUser]);

  const validateEmail = (emailToValidate) => {
    if (!emailToValidate || emailToValidate.length === 0) {
      setEmailError("LOGIN_EMAIL_MISSING");
      return false;
    }

    if (!validator.validate(emailToValidate)) {
      setEmailError("LOGIN_EMAIL_INVALID");
      return false;
    }
    return true;
  };

  const validatePassword = (passwordToValidate) => {
    if (!passwordToValidate || passwordToValidate.length === 0) {
      setPasswordError("LOGIN_PASSWORD_MISSING");
      return false;
    }
    return true;
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(null);
    if (unspecificError === "INCORRECT_LOGIN_CREDENTIALS")
      setUnspecificError(null);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(null);
    if (unspecificError === "INCORRECT_LOGIN_CREDENTIALS")
      setUnspecificError(null);
  };

  const clearAllErrors = () => {
    setEmailError(null);
    setPasswordError(null);
    setUnspecificError(null);
  };

  const handleLogin = (event) => {
    event.preventDefault();

    clearAllErrors();

    const emailValid = validateEmail(email);
    const passwordValid = validatePassword(password);

    if (emailValid && passwordValid) {
      const timeZoneOffset = -new Date().getTimezoneOffset() / 60;
      loginUser({
        variables: { input: { email, password, timeZoneOffset } },
      });
    }
  };

  let errorContent = null;
  if (unspecificError) {
    const errorMessage = t(`error.${unspecificError}`);
    errorContent = <p className={styles.Error}>{errorMessage}</p>;
  }

  const loginForm = (
    <>
      <Helmet>
        <title>WisdomTree - {t(`auth.login_title`)}</title>
      </Helmet>
      <section className={styles.loginContainer}>
        <form onSubmit={handleLogin}>
          <Input
            type="text"
            name="email"
            placeholder={t("auth.login_email_input")}
            autoComplete="email"
            spellCheck="false"
            iconleft="fas fa-envelope"
            help={emailError ? t(`error.${emailError}`) : null}
            isDanger={
              emailError !== null ||
              unspecificError === "INCORRECT_LOGIN_CREDENTIALS"
            }
            value={email}
            onChange={handleEmailChange}
          />

          <Input
            type="password"
            name="password"
            placeholder={t("auth.login_password_input")}
            autoComplete="current-password"
            spellCheck="false"
            iconleft="fas fa-lock"
            help={passwordError ? t(`error.${passwordError}`) : null}
            isDanger={
              passwordError !== null ||
              unspecificError === "INCORRECT_LOGIN_CREDENTIALS"
            }
            value={password}
            onChange={handlePasswordChange}
          />

          <div className="field">
            <div className="control text-align-center">
              <button
                className={`button is-primary ${styles.loginButton} ${
                  loginUserLoading ? "is-loading" : ""
                }`}
                type="submit"
              >
                {t("auth.login_button")}
              </button>
            </div>
          </div>

          {errorContent}
        </form>
        <div className={styles.separatorContainer}>
          <SeparatorWithText text={t("auth.login_social_separator")} />
        </div>
        <GoogleLoginWrapper text={t("auth.login_social_google")} />
      </section>
      <div className={classNames(styles.registerContainer)}>
        <hr />

        <p className={styles.moreOptions}>
          <Trans i18nKey="auth.login_forgot_password">
            Forgot password?
            <Link to={`/auth/forgot-password${location.search}`}>Reset it</Link>
          </Trans>
        </p>
        <p className={styles.moreOptions}>
          <Trans i18nKey="auth.login_no_account">
            New to WisdomTree?
            <Link to={`/auth/register${location.search}`}>Sign up</Link>
          </Trans>
        </p>
      </div>
    </>
  );

  return <div>{loginForm}</div>;
};

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

export default withRouter(Login);
