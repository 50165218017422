import React, { useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./ChooseReferenceType.module.scss";
import modalStyles from "../AddReferenceModal.module.scss";
import CardButton from "../../../CardButton/CardButton";
import { useTranslation } from "react-i18next";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import { useHotkey } from "../../../../Utils/Hotkeys";

const propTypesChooseReferenceType = {
  onCloseModal: PropTypes.func.isRequired,
  onChooseType: PropTypes.func.isRequired,
};
const defaultPropsChooseReferenceType = {};

const ChooseReferenceType = ({ onCloseModal, onChooseType }) => {
  const { t } = useTranslation();

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onCloseModal();
    },
    dependsOn: [onCloseModal],
  });

  useEffect(() => {
    document.getElementById("buttonContainer").focus();
  }, []);

  const bodyContent = (
    <div id="buttonContainer" className={styles.buttonContainer} tabIndex={-1}>
      <CardButton
        title={t("wisdomtree.modal_add_reference.choose_type.type_link")}
        subtitle={t(
          "wisdomtree.modal_add_reference.choose_type.type_link_desc"
        )}
        icon="fa-project-diagram"
        onClick={() => {
          document.activeElement.blur();
          onChooseType("link");
        }}
      />
      <CardButton
        title={t("wisdomtree.modal_add_reference.choose_type.type_weblink")}
        subtitle={t(
          "wisdomtree.modal_add_reference.choose_type.type_weblink_desc"
        )}
        icon="fa-globe"
        onClick={() => {
          document.activeElement.blur();
          onChooseType("link_web");
        }}
      />
      <CardButton
        title={t("wisdomtree.modal_add_reference.choose_type.type_text")}
        subtitle={t(
          "wisdomtree.modal_add_reference.choose_type.type_text_desc"
        )}
        icon="fa-edit"
        onClick={() => {
          document.activeElement.blur();
          onChooseType("text");
        }}
      />
    </div>
  );

  return (
    <div className={`modal-card`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.modal_add_reference.choose_type.title")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body`}>{bodyContent}</section>
      <footer className="modal-card-foot">
        <button className="button" type="button" onClick={onCloseModal}>
          {t("wisdomtree.modal_add_reference.choose_type.cancel")}
        </button>
      </footer>
    </div>
  );
};

ChooseReferenceType.propTypes = propTypesChooseReferenceType;
ChooseReferenceType.defaultProps = defaultPropsChooseReferenceType;

export default ChooseReferenceType;
