import React, { useState } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";

import styles from "./AddText.module.scss";
import modalStyles from "../AddReferenceModal.module.scss";
import { useTranslation } from "react-i18next";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import { useMutation } from "@apollo/client";
import {
  CREATE_REFERENCE,
  mergeAfterCreate,
} from "../../../../GraphQl/references";
import { useHotkey } from "../../../../Utils/Hotkeys";
import EditReferenceText from "../../../References/EditReferenceText/EditReferenceText";

const propTypesAddInternalLink = {
  onCloseModal: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
};
const defaultPropsAddInternalLink = {};

const AddText = ({ onCloseModal, onBack, entityType, entityId }) => {
  const { t } = useTranslation();
  const [titleText, setTitleText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");

  const onChangeTitleTextHandler = (event) => {
    const title = event.target.value;
    setTitleText(title);
  };

  const onChangeDescriptionTextHandler = (event) => {
    const description = event.target.value;
    setDescriptionText(description);
  };

  const [createReference, { loading: createReferenceLoading }] = useMutation(
    CREATE_REFERENCE,
    {
      update: (cache, mutationResult) => {
        mergeAfterCreate(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        onCloseModal();
      },
    }
  );

  const onSubmit = () => {
    createReference({
      variables: {
        input: {
          type: "TEXT",
          sourceType: entityType,
          sourceId: entityId,
          title: titleText,
          descriptionTo: descriptionText.length > 0 ? descriptionText : null,
        },
      },
    });
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (titleText.length === 0 || createReferenceLoading) return;
      onSubmit();
    },
    dependsOn: [onSubmit, titleText, createReferenceLoading],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onBack();
    },
    dependsOn: [onBack],
  });

  const content = (
    <EditReferenceText
      title={titleText}
      description={descriptionText}
      onChangeTitle={onChangeTitleTextHandler}
      onChangeDescription={onChangeDescriptionTextHandler}
    />
  );

  return (
    <div className={`modal-card ${modalStyles.modalCard}`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.modal_add_reference.add_text.title")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${styles.body}`}>{content}</section>
      <footer className="modal-card-foot">
        <button
          id="submitButton"
          className={`button is-primary ${
            createReferenceLoading ? `is-loading` : ``
          }`}
          type="submit"
          disabled={titleText.length === 0}
          onClick={() => {
            onSubmit();
          }}
        >
          {t("wisdomtree.modal_add_reference.add_text.add")}
        </button>
        <button className="button" type="button" onClick={onBack}>
          {t("wisdomtree.modal_add_reference.add_text.back")}
        </button>
      </footer>
    </div>
  );
};

AddText.propTypes = propTypesAddInternalLink;
AddText.defaultProps = defaultPropsAddInternalLink;

export default AddText;
