import { gql } from "@apollo/client";
import apolloClient from "../../config/apollo";

export const setImageUpdatingForEntity = ({
  typename,
  id,
  isUpdating,
  fieldName = "isImageUpdating",
}) => {
  let data = {};
  data[fieldName] = isUpdating;

  apolloClient.writeFragment({
    id: `${typename}:${id}`,
    fragment: gql`
          fragment ${typename}ImageUpdating on ${typename} {
            ${fieldName} @client
          }
        `,
    data,
  });
};
