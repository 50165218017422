/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./CardType.module.css";
import { getIconForCardType } from "../../../../../../Utils/Utils";

const propTypes = {
  cardType: PropTypes.string.isRequired
};

const defaultProps = {};

const CardType = ({ cardType }) => {
  const { t } = useTranslation();

  return (
    <div id={styles.container}>
      <span className={`icon ${styles.gray}`}>
        <i className={`fas ${getIconForCardType(cardType)}`} />
      </span>
      <p id={styles.typeText} className={styles.gray}>
        {t(`wisdomtree.card.${cardType}`)}
      </p>
    </div>
  );
};

CardType.propTypes = propTypes;
CardType.defaultProps = defaultProps;

export default CardType;
