/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./Dropdown.module.scss";
import { TooltipDelayed } from "../Tooltip/Tooltip";
import DropdownTippy from "../DropdownTippy/DropdownTippy";
import GlowOverlay from "../GlowOverlay/GlowOverlay";
import QuestIcon from "../QuestIcon/QuestIcon";

const propTypes = {
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonStyle: PropTypes.string,
  buttonHidden: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  hasQuest: PropTypes.bool,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.string,
      text: PropTypes.string,
      content: PropTypes.element,
      tooltip: PropTypes.string,
    })
  ).isRequired,
  onItemClicked: PropTypes.func.isRequired,
  onDropdownFocus: PropTypes.func,
  onDropdownBlur: PropTypes.func,
  buttonTooltip: PropTypes.string,
};

const defaultProps = {
  buttonText: null,
  buttonIcon: null,
  buttonHidden: false,
  buttonStyle: "is-light",
  showDropdownIcon: true,
  isLoading: false,
  small: false,
  disabled: false,
  hasQuest: false,
  buttonTooltip: null,
  onDropdownFocus: () => {},
  onDropdownBlur: () => {},
};

const Dropdown = ({
  buttonText,
  buttonIcon,
  buttonHidden,
  buttonStyle,
  showDropdownIcon,
  small,
  disabled,
  hasQuest,
  isLoading,
  items,
  onItemClicked,
  buttonTooltip,
  onDropdownFocus,
  onDropdownBlur,
}) => {
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (buttonClicked) onDropdownFocus();
    if (!buttonClicked) onDropdownBlur();
  }, [buttonClicked]);

  useEffect(() => {
    if (buttonHidden && buttonClicked) {
      setButtonClicked(false);
    }
  }, [buttonHidden]);

  const onItemClickedInternally = (item) => {
    setButtonClicked(false);
    onItemClicked(item);
  };

  let contentButtonIcon = null;
  if (buttonIcon && !isLoading) {
    contentButtonIcon = (
      <span className={`icon ${styles.icon}`}>
        <i className={`fas ${buttonIcon}`} />
      </span>
    );
  }

  let contentDropdownIcon = null;
  if (!isLoading && showDropdownIcon) {
    contentDropdownIcon = (
      <span className={`icon is-small ${styles.icon}`}>
        <i className="fas fa-angle-down" aria-hidden="true" />
      </span>
    );
  }

  let button = (
    <button
      className={`button ${buttonStyle} ${styles.button} ${
        buttonClicked ? styles.active : null
      } ${buttonHidden ? styles.hidden : null} ${
        isLoading ? `is-loading` : null
      } ${small ? styles.small : null}`}
      type="button"
      aria-haspopup="true"
      aria-controls="dropdown-menu4"
      onClick={(event) => {
        setButtonClicked(!buttonClicked);
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
      }}
      disabled={isLoading || disabled}
    >
      {contentButtonIcon}
      {buttonText !== null ? <span>{buttonText}</span> : null}
      {hasQuest && !isLoading && !disabled ? <QuestIcon /> : null}
      {contentDropdownIcon}
    </button>
  );

  if (buttonTooltip) {
    button = <TooltipDelayed content={buttonTooltip}>{button}</TooltipDelayed>;
  }

  const isDropdownOpened = buttonClicked && !isLoading;

  return (
    <DropdownTippy
      visible={isDropdownOpened}
      items={items}
      onItemClicked={onItemClickedInternally}
      onClickOutside={() => setButtonClicked(false)}
    >
      <div>{button}</div>
    </DropdownTippy>
  );
};

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
