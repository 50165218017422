import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./QuizButton.module.scss";
import { Tooltip, TooltipDelayed } from "../../../UI/Tooltip/Tooltip";

const propTypesQuizButton = {
  entryId: PropTypes.string,
  entryName: PropTypes.string,
  cardsActiveAmount: PropTypes.number,
  cardsDueAmount: PropTypes.number,
  noText: PropTypes.bool,
  dataId: PropTypes.string,
};
const defaultPropsQuizButton = {
  entryId: null,
  entryName: null,
  cardsActiveAmount: 0,
  cardsDueAmount: 0,
  noText: false,
  dataId: null,
};

const QuizButton = ({
  entryId,
  entryName,
  dataId,
  cardsActiveAmount,
  cardsDueAmount,
  noText,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [initializing, setInitializing] = useState(false);

  useEffect(() => {
    setInitializing(true);
    setTimeout(() => {
      setInitializing(false);
    }, 200);
  }, [entryId]);

  let learnButtonClasses = `button is-light`;
  let learnButtonIconClasses = `icon ${styles.learnIcon}`;
  if (cardsDueAmount > 0) {
    learnButtonClasses = `button ${styles.learnButtonColored}`;
    learnButtonIconClasses = `icon ${styles.learnIconColored} ${
      initializing ? styles.noTransition : null
    }`;
  }

  const quizLink = entryId != null ? `/quiz?rootId=${entryId}` : `/quiz`;

  const contentButton = (
    <button
      id="learnButton"
      type="button"
      data-id={dataId}
      className={learnButtonClasses}
      onClick={() => {
        history.push(quizLink);
      }}
    >
      <span className={learnButtonIconClasses}>
        <i className="fas fa-brain" />
      </span>
      {noText && cardsDueAmount === 0 ? null : (
        <span
          className={cardsDueAmount > 0 ? styles.learnButtonTextColored : null}
        >
          {!noText ? t("wisdomtree.entry_browser.entry_learn") : ""}
          {!noText && cardsDueAmount > 0 ? " " : ""}
          {cardsDueAmount > 0 ? `(${cardsDueAmount})` : null}
        </span>
      )}
    </button>
  );

  return (
    <TooltipDelayed
      content={
        entryId
          ? t("wisdomtree.entry_browser.tooltips.quiz_button_entry", {
              entry: entryName,
            })
          : t("wisdomtree.entry_browser.tooltips.quiz_button_home")
      }
    >
      {contentButton}
    </TooltipDelayed>
  );
};

QuizButton.propTypes = propTypesQuizButton;
QuizButton.defaultProps = defaultPropsQuizButton;

export default QuizButton;
