import { gql } from "@apollo/client";

const GET_EXISTING_EXPORT = gql`
  query {
    existingExport {
      id
      running
      failed
      url
      createdAt
    }
  }
`;

const START_EXPORT = gql`
  mutation {
    startExport {
      code
      message
      startedExport {
        id
        running
        failed
        url
        createdAt
      }
    }
  }
`;

const mergeExistingAfterStart = (cache, mutationResult) => {
  const { startedExport } = mutationResult.data.startExport;

  cache.writeQuery({
    query: GET_EXISTING_EXPORT,
    data: { existingExport: { ...startedExport } },
  });
};

export { GET_EXISTING_EXPORT, START_EXPORT, mergeExistingAfterStart };
