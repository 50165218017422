import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./Recents.module.scss";
import { useQuery } from "@apollo/client";
import {
  GET_Recent_ENTRIES,
  GET_RECENT_ENTRIES,
} from "../../../../GraphQl/dashboard";
import Entry from "../../Entry/Entry";
import Spinner from "../../../../UI/Spinner/Spinner";
import EntryGroup from "../../../../UI/EntryGroup/EntryGroup";

const propTypesRecents = {};
const defaultPropsRecents = {};

const Recents = () => {
  const { t } = useTranslation();

  const {
    loading: getRecentEntriesLoading,
    data: getRecentEntriesData,
  } = useQuery(GET_RECENT_ENTRIES, {
    fetchPolicy: "cache-and-network",
  });
  console.log({ getRecentEntriesData });
  const entries = getRecentEntriesData ? getRecentEntriesData.recents : null;

  let contentEntries = null;

  if (entries) {
    contentEntries = (
      <div className={styles.listContainer}>
        {entries.map((entry) => (
          <div key={entry.id} className={styles.entryContainer}>
            <Entry entry={entry} showSize showTimeTracked />
          </div>
        ))}
      </div>
    );
  } else if (getRecentEntriesLoading) {
    contentEntries = <Spinner />;
  }

  return (
    <EntryGroup
      icon="fas fa-history"
      title={t("wisdomtree.entry_browser.recents_list_title")}
    >
      {contentEntries}
    </EntryGroup>
  );
};

Recents.propTypes = propTypesRecents;
Recents.defaultProps = defaultPropsRecents;

export default Recents;
