/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isMobile as isMobileDevice } from "react-device-detect";
import { useDoubleTap } from "use-double-tap";

import styles from "./ContentBlock.module.scss";
import entryBrowserStyles from "../../../EntryBrowser.module.scss";
import TitleQuillEditor from "../../../../../UI/Editor/TitleQuillEditor";
import ContentQuillEditor from "../../../../../UI/Editor/ContentQuillEditor";
import BlockType from "../../../BlockType/BlockType";
import { isDOMElement } from "../../../../../Utils/Utils";
import { useMobileOrTablet } from "../../../../../Utils/Responsive";
import ClickableImage from "../../../../../UI/ClickableImage/ClickableImage";
import Tags from "../../../../../UI/Tags/Tags";
import BlockActionsButton from "./BlockActionsButton/BlockActionsButton";
import BlockImage from "./BlockImage/BlockImage";
import BlockNote from "./BlockNote/BlockNote";
import BlockCardsButton from "./BlockCardsButton/BlockCardsButton";
import References from "../../../../../UI/References/References";
import BlockQuote from "./BlockQuote/BlockQuote";
import BlockAddBeforeButton from "./BlockAddBeforeButton/BlockAddBeforeButton";
import BlockWeb from "./BlockWeb/BlockWeb";
import useReadBlockTracking from "../../../../../Utils/UseReadBlockTracking";
import ReadBlockProgress from "../../../../../UI/ReadBlockProgress/ReadBlockProgress";
import BlockDailyQuoteButton from "./BlockDailyQuoteButton/BlockDailyQuoteButton";

const propTypes = {
  editMode: PropTypes.bool,
  sharingId: PropTypes.string,
  deleteLoading: PropTypes.bool,
  createBlockLoading: PropTypes.bool,
  isDragging: PropTypes.bool,
  block: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.object,
    position: PropTypes.number,
    cardsAmount: PropTypes.number,
    cardsActiveAmount: PropTypes.number,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    isImageUpdating: PropTypes.bool,
    dusted: PropTypes.bool,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    references: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
        target: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
      })
    ),
    referencedBy: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
        target: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
      })
    ),
  }).isRequired,
  user: PropTypes.shape({
    currentQuest: PropTypes.object,
  }),
  listSize: PropTypes.number,
  onUpdateBlock: PropTypes.func.isRequired,
  onMoveToEntry: PropTypes.func.isRequired,
  onMoveToTop: PropTypes.func.isRequired,
  onMoveToBottom: PropTypes.func.isRequired,
  onRemoveBockTitle: PropTypes.func.isRequired,
  onRemoveBockText: PropTypes.func.isRequired,
  onManageCards: PropTypes.func.isRequired,
  onAddCard: PropTypes.func.isRequired,
  onAddReference: PropTypes.func.isRequired,
  onCreateBlock: PropTypes.func.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  onAddImage: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  onDialogOpenedChanged: PropTypes.func,
  onEditWebLink: PropTypes.func.isRequired,
  onToggleDailyQuote: PropTypes.func.isRequired,
};

const defaultProps = {
  editMode: false,
  sharingId: null,
  deleteLoading: false,
  user: null,
  createBlockLoading: false,
  isDragging: false,
  listSize: 0,
  onDialogOpenedChanged: () => {},
};

const ContentBlock = React.forwardRef(
  (
    {
      editMode,
      sharingId,
      deleteLoading,
      createBlockLoading,
      isDragging,
      block,
      user,
      listSize,
      onUpdateBlock,
      onMoveToEntry,
      onMoveToTop,
      onMoveToBottom,
      onRemoveBockTitle,
      onRemoveBockText,
      onManageCards,
      onAddCard,
      onAddReference,
      onCreateBlock,
      onDeleteBlock,
      onAddImage,
      onRemoveImage,
      onDialogOpenedChanged,
      onEditWebLink,
      onToggleDailyQuote,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isHoverDisabled, setIsHoverDisabled] = useState(true);
    const [hasTitle, setHasTitle] = useState(block.title != null);
    const [hasTags, setHasTags] = useState(false);
    const [hasText, setHasText] = useState(block.content != null);
    const [wasDusted, setWasDusted] = useState(block.dusted);

    const blockContainerRef = useRef();
    if (
      isHovered &&
      blockContainerRef.current &&
      blockContainerRef.current.matches(":hover") === false
    )
      setIsHovered(false);

    const { progress: readProgress } = useReadBlockTracking({
      active: !sharingId,
      block,
      blockRef: blockContainerRef,
    });

    useEffect(() => {
      // hover is initially disabled to avoid unintented initial focus of editors
      setTimeout(() => {
        setIsHoverDisabled(false);
      }, 50);
    }, []);

    useEffect(() => {
      setIsFocused(false);
      setIsHovered(false);
    }, [isDragging]);

    useEffect(() => {
      setHasTags(block.tags.length > 0);
    }, [block]);

    const titleEditorRef = useRef(null);
    const contentEditorRef = useRef(null);

    let blockActionsButtonContent = null;

    if (editMode) {
      blockActionsButtonContent = (
        <BlockActionsButton
          block={block}
          user={user}
          buttonHidden={
            deleteLoading ||
            (!isMobileDevice && !isFocused && (!isHovered || isDragging))
          }
          listSize={listSize}
          hasTitle={hasTitle}
          hasText={hasText}
          hasTags={hasTags}
          onEditWebsite={() => {
            onEditWebLink();
          }}
          onAddTitle={() => {
            setHasTitle(true);
            setTimeout(() => {
              // no autfocus on tablet or mobile
              if (isMobileDevice) return;
              titleEditorRef.current.focusEditor();
            }, 50);
          }}
          onRemoveTitle={() => {
            onRemoveBockTitle({ id: block.id });
            setHasTitle(false);
            setIsFocused(false);
          }}
          onAddText={() => {
            setHasText(true);
            setTimeout(() => {
              // no autfocus on tablet or mobile
              if (isMobileDevice) return;
              contentEditorRef.current.focusEditor();
            }, 50);
          }}
          onRemoveText={() => {
            onRemoveBockText({ id: block.id });
            setHasText(false);
            setIsFocused(false);
          }}
          onAddTags={() => {
            setTimeout(() => {
              setHasTags(true);
            }, 50);
          }}
          onAddReference={() => {}}
          onAddImage={onAddImage}
          onRemoveImage={onRemoveImage}
          onManageCards={onManageCards}
          onAddCard={onAddCard}
          onAddReference={onAddReference}
          onMoveToEntry={onMoveToEntry}
          onMoveToTop={onMoveToTop}
          onMoveToBottom={onMoveToBottom}
          onDeleteBlock={onDeleteBlock}
          onToggleDailyQuote={onToggleDailyQuote}
        />
      );
    }

    let blockReadProgressContainer = null;
    if (wasDusted && !sharingId) {
      blockReadProgressContainer = (
        <div className={styles.blockProgressContainer}>
          <ReadBlockProgress percent={readProgress * 100} />
        </div>
      );
    }
    const bindDoubleTap = useDoubleTap(() => {
      if (!editMode) return;
      if (block.type === "WEB") return;

      if (hasTitle && block.title == null) {
        setHasTitle(false);
      } else if (!hasTitle) {
        setHasTitle(true);
        setTimeout(() => {
          // no autfocus on tablet or mobile
          if (isMobileDevice) return;
          titleEditorRef.current.focusEditor();
        }, 50);
      }
    });

    const blockTypeContent = (
      <div
        id={entryBrowserStyles.blockType}
        className={styles.blockTypeContainer}
        {...bindDoubleTap}
      >
        <BlockType blockType={block.type} />
        {blockReadProgressContainer}
      </div>
    );

    let blockTitleEditorContent = null;
    if (hasTitle) {
      blockTitleEditorContent = (
        <div className={entryBrowserStyles.blockTitleContainer}>
          <TitleQuillEditor
            elementId={block.id}
            elementTitle={block.title}
            ref={titleEditorRef}
            limit={120}
            hintText={t("wisdomtree.entry_browser.block_title_hint")}
            stylesClassName="blockTitleEditor"
            editMode={
              (isHovered || isFocused || isMobileDevice) &&
              editMode &&
              !isDragging
            }
            focusInitially={false}
            onTitleChanged={(blockTitle) => {
              let titleUpdate = blockTitle;
              if (blockTitle.length === 0) {
                titleUpdate = null;
              }
              onUpdateBlock({ id: block.id, title: titleUpdate });
            }}
          />
        </div>
      );
    }

    let blockCardsButtonContent = null;

    if (editMode) {
      blockCardsButtonContent = (
        <BlockCardsButton
          block={block}
          user={user}
          isFocused={isFocused}
          isHovered={isHovered}
          buttonHidden={deleteLoading}
          onManageCards={(id) => {
            onManageCards(id);
          }}
          onAddCard={(id) => {
            onAddCard(id);
          }}
        />
      );
    }

    let blockDailyQuoteButtonContent = null;
    if (editMode && block.type === "QUOTE") {
      blockDailyQuoteButtonContent = (
        <BlockDailyQuoteButton
          block={block}
          isFocused={isFocused}
          isHovered={isHovered}
          buttonHidden={deleteLoading}
          onToggle={(dailyQuoteEnabled) => {
            onToggleDailyQuote(dailyQuoteEnabled);
          }}
        />
      );
    }

    let blockContentEditorContent = null;
    if (hasText || (editMode && block.type !== "IMAGE")) {
      blockContentEditorContent = (
        <div className={entryBrowserStyles.blockContentContainer}>
          <ContentQuillEditor
            elementId={block.id}
            elementContent={block.content}
            ref={contentEditorRef}
            hintText={t("wisdomtree.entry_browser.block_content_hint")}
            stylesClassName="blockContentEditor"
            editMode={
              !deleteLoading &&
              (isHovered || isFocused || isMobileDevice) &&
              editMode &&
              !isDragging
            }
            focusInitially={false}
            onContentChanged={(blockContent) => {
              onUpdateBlock({ id: block.id, content: blockContent });
              // showAddCardButtonIfNeeded(blockContent);
            }}
          />
        </div>
      );
    }

    let blockImageContent = null;
    if (block.image || block.isImageUpdating) {
      blockImageContent = (
        <ClickableImage
          editMode={editMode || block.image}
          imageUrl={block.image ? block.image.url : null}
          isLoading={block.isImageUpdating}
          height={74}
          width={74}
          onImageClicked={() => {
            if (!block.isImageUpdating) onAddImage();
          }}
          onImageDropped={(droppedContent) => {
            if (!block.isImageUpdating) onAddImage(droppedContent);
          }}
          altText="block"
          borderRadius={{
            topLeft: 6,
          }}
          preview={!isMobileDevice}
        />
      );
    }

    let blockTagsContent = null;
    if (hasTags) {
      blockTagsContent = (
        <div className={styles.tagsContainer}>
          <Tags
            editMode={editMode}
            tags={block.tags}
            onUpdateTags={(tags) => {
              onUpdateBlock({
                id: block.id,
                tags,
              });
            }}
            onDialogOpenedChanged={onDialogOpenedChanged}
            onNewTagBlur={() => {
              if (block.tags.length === 0) setHasTags(false);
            }}
            onTagRemoved={() => {
              setIsFocused(false);
            }}
            onTagColorChanged={() => {
              setIsFocused(false);
            }}
            showNewButton={isMobileDevice || isHovered}
            tooltipAddNew={t("wisdomtree.entry_browser.tooltips.tag_add_block")}
            tooltipRemove={t(
              "wisdomtree.entry_browser.tooltips.tag_remove_block"
            )}
            tooltipDeleteEverywhere={t(
              "wisdomtree.entry_browser.tooltips.tag_delete_everywhere"
            )}
          />
        </div>
      );
    }

    const allReferences = [...block.references, ...block.referencedBy];
    let blockReferencesContent = null;
    if (allReferences != null && allReferences.length > 0) {
      blockReferencesContent = (
        <div className={styles.referencesContainer}>
          <References
            editMode={editMode}
            sharingId={sharingId}
            references={allReferences}
            povId={block.id}
            povType="block"
            onDialogOpenedChanged={onDialogOpenedChanged}
          />
        </div>
      );
    }

    let blockContent;
    if (block.type === "NOTE") {
      blockContent = (
        <BlockNote
          editMode={editMode}
          blockTypeContent={blockTypeContent}
          blockActionsButtonContent={blockActionsButtonContent}
          blockTitleEditorContent={blockTitleEditorContent}
          blockCardsButtonContent={blockCardsButtonContent}
          blockContentEditorContent={blockContentEditorContent}
          blockImageContent={blockImageContent}
          blockTagsContent={blockTagsContent}
          blockReferencesContent={blockReferencesContent}
        />
      );
    } else if (block.type === "IMAGE") {
      blockContent = (
        <BlockImage
          block={block}
          editMode={editMode}
          blockTypeContent={blockTypeContent}
          blockActionsButtonContent={blockActionsButtonContent}
          blockTitleEditorContent={blockTitleEditorContent}
          blockContentEditorContent={blockContentEditorContent}
          blockTagsContent={blockTagsContent}
          blockReferencesContent={blockReferencesContent}
          onAddImage={onAddImage}
        />
      );
    } else if (block.type === "QUOTE") {
      blockContent = (
        <BlockQuote
          block={block}
          onAddImage={onAddImage}
          onUpdateBlockSource={(source) => {
            onUpdateBlock({
              id: block.id,
              source,
            });
          }}
          editMode={editMode}
          blockTypeContent={blockTypeContent}
          blockActionsButtonContent={blockActionsButtonContent}
          blockTitleEditorContent={blockTitleEditorContent}
          blockContentEditorContent={blockContentEditorContent}
          blockTagsContent={blockTagsContent}
          blockReferencesContent={blockReferencesContent}
          blockDailyQuoteButtonContent={blockDailyQuoteButtonContent}
        />
      );
    } else if (block.type === "WEB") {
      blockContent = (
        <BlockWeb
          block={block}
          editMode={editMode}
          blockTypeContent={blockTypeContent}
          blockActionsButtonContent={blockActionsButtonContent}
          blockTagsContent={blockTagsContent}
          blockReferencesContent={blockReferencesContent}
          onEditWebLink={onEditWebLink}
        />
      );
    }

    return (
      <div
        className={`${entryBrowserStyles.blockOuterContainer} ${
          deleteLoading ? styles.deleteLoading : null
        }`}
        id={`block_${block.id}`}
        data-id={block.id}
      >
        <BlockAddBeforeButton
          onCreateBlock={onCreateBlock}
          createBlockLoading={createBlockLoading}
          blockPosition={block.position}
        />
        <div
          className={`box ${styles.container} ${
            blockTagsContent && blockReferencesContent
              ? styles.tagsAndReferences
              : null
          }`}
          ref={blockContainerRef}
          onMouseOver={() => {
            if (isHoverDisabled) return;
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={(e) => {
            // don't loose focus if editor inside the block container got focussed
            // this is needed so that the editor can be get autofocus
            let classNameOfNewTarget = null;
            if (e.relatedTarget) {
              classNameOfNewTarget = e.relatedTarget.className;
              if (
                typeof e.relatedTarget.className === "string" &&
                classNameOfNewTarget.includes("ql-editor")
              ) {
                // only keep focus if ql editor is of this block
                let tmp = e.relatedTarget;
                while (tmp.parentNode) {
                  const dataId = isDOMElement(tmp.parentNode)
                    ? tmp.parentNode.getAttribute("data-id")
                    : null;
                  if (dataId) {
                    if (dataId === block.id) {
                      return;
                    }
                    break;
                  }
                  tmp = tmp.parentNode;
                }
              }
            }
            setIsFocused(false);
          }}
          tabIndex={-1}
        >
          <ContentBlockFocusHandler
            ref={ref}
            onFocusContentEditor={() => {
              if (isMobileDevice) {
                // no autofocus on mobile or tablet
                return;
              }
              setIsFocused(true);
              setTimeout(() => {
                if (contentEditorRef.current)
                  contentEditorRef.current.focusEditor();
              }, 50);
            }}
          />

          {blockContent}
        </div>
      </div>
    );
  }
);

class ContentBlockFocusHandler extends React.Component {
  focus() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onFocusContentEditor();
  }

  render() {
    return <div />;
  }
}
ContentBlockFocusHandler.propTypes = {
  onFocusContentEditor: PropTypes.func.isRequired,
};
ContentBlockFocusHandler.defaultProps = {};

ContentBlock.propTypes = propTypes;
ContentBlock.defaultProps = defaultProps;

export default ContentBlock;
