import React from "react";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";

import styles from "./Spinner.module.scss";

const propTypesSpinner = {
  size: PropTypes.number,
};
const defaultPropsSpinner = {
  size: 10,
  color: "",
};

const Spinner = ({ size, color }) => {
  const isDarkMode = document.documentElement.className.includes("theme-dark");
  return (
    <div className={styles.Loader}>
      <BeatLoader
        sizeUnit="px"
        size={size}
        color={isDarkMode ? "#dfdfdf" : "#7A7A7A"}
        loading
        css="display: flex;"
      />
    </div>
  );
};

Spinner.propTypes = propTypesSpinner;
Spinner.defaultProps = defaultPropsSpinner;

export default Spinner;
