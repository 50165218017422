import React from "react";
import PropTypes from "prop-types";

import styles from "./JobCard.module.scss";

const propTypesJobCard = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
const defaultPropsJobCard = {};

const JobCard = ({ title, subtitle, icon, selected, onClick }) => {
  return (
    <div
      className={`box ${styles.container} ${selected ? styles.selected : null}`}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        // enter key
        if (e.keyCode === 13) {
          onClick();
        }
      }}
    >
      <div className={styles.iconContainer}>
        <span className={`icon ${styles.icon}`}>
          <i className={`fas ${icon}`} />
        </span>
      </div>
      <div className={styles.textContainer}>
        <span className={styles.title}>{title}</span>
        {selected ? <span className={styles.subtitle}>{subtitle}</span> : null}
      </div>
    </div>
  );
};

JobCard.propTypes = propTypesJobCard;
JobCard.defaultProps = defaultPropsJobCard;

export default JobCard;
