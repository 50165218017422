import React, { useState } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";

import styles from "./AddLink.module.scss";
import modalStyles from "../AddReferenceModal.module.scss";
import { useTranslation } from "react-i18next";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import {
  SEARCH_ADD_REFERENCE_SUGGESTIONS,
  SEARCH_GLOBAL,
} from "../../../../GraphQl/search";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SearchWithSuggestions from "../../../SearchWithSuggestions/SearchWithSuggestions";
import {
  CREATE_REFERENCE,
  mergeAfterCreate,
} from "../../../../GraphQl/references";
import EditReferenceLink from "../../../References/EditReferenceLink/EditReferenceLink";

const propTypesAddInternalLink = {
  onCloseModal: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
};
const defaultPropsAddInternalLink = {};

const AddLink = ({ onCloseModal, onBack, entityType, entityId }) => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState(null);
  const [showDescription, setShowDescription] = useState(false);
  const [descriptionToText, setDescriptionToText] = useState("");
  const [descriptionFromText, setDescriptionFromText] = useState("");

  const onChangeDescriptionToTextHandler = (event) => {
    const description = event.target.value;
    setDescriptionToText(description);
  };

  const onChangeDescriptionFromTextHandler = (event) => {
    const description = event.target.value;
    setDescriptionFromText(description);
  };

  const [createReference, { loading: createReferenceLoading }] = useMutation(
    CREATE_REFERENCE,
    {
      update: (cache, mutationResult) => {
        mergeAfterCreate(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        onCloseModal();
      },
    }
  );

  const onSubmit = ({ type, entity }) => {
    createReference({
      variables: {
        input: {
          type: "LINK_INTERNAL",
          sourceType: entityType,
          sourceId: entityId,
          targetType: type,
          targetId: entity.id,
          descriptionTo:
            descriptionToText.length > 0 ? descriptionToText : null,
          descriptionFrom:
            descriptionFromText.length > 0 ? descriptionFromText : null,
        },
      },
    });
  };

  const content = (
    <EditReferenceLink
      entityType={entityType}
      entityId={entityId}
      descriptionTo={descriptionToText}
      descriptionFrom={descriptionFromText}
      onChangeDescriptionTo={onChangeDescriptionToTextHandler}
      onChangeDescriptionFrom={onChangeDescriptionFromTextHandler}
      showDescription={showDescription}
      onChangeShowDescription={setShowDescription}
      onSelect={(selection) => {
        setSelection(selection);
      }}
      onSubmit={(selection) => {
        onSubmit(selection);
      }}
      onCancel={() => {
        onBack();
      }}
      showEntitySelection={true}
    />
  );

  return (
    <div className={`modal-card ${modalStyles.modalCard}`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.modal_add_reference.add_link.title")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${styles.body}`}>{content}</section>
      <footer className="modal-card-foot">
        <button
          className={`button is-primary ${
            createReferenceLoading ? `is-loading` : ``
          }`}
          type="submit"
          disabled={selection == null}
          onClick={() => {
            onSubmit(selection);
          }}
        >
          {t("wisdomtree.modal_add_reference.add_link.add")}
        </button>
        <button className="button" type="button" onClick={onBack}>
          {t("wisdomtree.modal_add_reference.add_link.back")}
        </button>
      </footer>
    </div>
  );
};

AddLink.propTypes = propTypesAddInternalLink;
AddLink.defaultProps = defaultPropsAddInternalLink;

export default AddLink;
