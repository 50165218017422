/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
import styles from "./ManageCardsModal.module.css";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../../../UI/Modal/BaseModal/BaseModal";
import ManageCardsContent from "./ManageCardsContent/ManageCardsContent";
import ModalCloseButton from "../../../UI/Modal/ModalCloseButton/ModalCloseButton";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useManageCardsModal = ({ onOpen, onClose }) => {
  const { t } = useTranslation();
  const [blockId, setBlockId] = useState(null);
  const [nestedDialogOpened, setNestedDialogOpened] = useState(false);
  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose,
    hasNestedOpened: nestedDialogOpened,
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!isOpened) return;
      if (nestedDialogOpened) return;
      close();
    },
    dependsOn: [close, nestedDialogOpened, isOpened],
  });

  let content = null;
  if (isOpened) {
    const innerContent = (
      <ManageCardsContent
        editMode
        selectedBlockId={blockId}
        onChangeNestedDialogOpened={(opened) => setNestedDialogOpened(opened)}
      />
    );

    content = (
      <BaseModal show={isOpened} onCloseHandler={close}>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {t("wisdomtree.modal_manage_cards.title")}
            </p>
            <ModalCloseButton onClick={close} />
          </header>
          <section className="modal-card-body">{innerContent}</section>
          <footer className="modal-card-foot">
            <button className="button" type="button" onClick={close}>
              {t("wisdomtree.modal_manage_cards.confirm")}
            </button>
          </footer>
        </div>
      </BaseModal>
    );
  }

  const openModal = (blockIdToManageCardsFor) => {
    setBlockId(blockIdToManageCardsFor);
    open();
  };

  const closeModal = () => {
    close();
    setBlockId(null);
  };

  return { open: openModal, close: closeModal, content };
};

useManageCardsModal.propTypes = propTypes;
useManageCardsModal.defaultProps = defaultProps;

export default useManageCardsModal;
