import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

import styles from "./UserDetail.module.scss";
import { GET_USER } from "../../GraphQl/user";
import Spinner from "../../UI/Spinner/Spinner";
import UserInfo from "./UserInfo/UserInfo";
import UserFeedback from "./UserFeedback/UserFeedback";
import BackButton from "../UI/BackButton/BackButton";

const propTypesUserDetail = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};
const defaultPropsUserDetail = {};

const UserDetail = ({ match }) => {
  const { userId } = match.params;

  const {
    loading: getUserLoading,
    data: getUserData,
    refetch: refetchUser,
  } = useQuery(GET_USER, {
    variables: {
      id: userId,
    },
  });
  const user = getUserData ? getUserData.user : null;

  useEffect(() => {
    refetchUser();
  }, []);

  let content = null;
  if (getUserLoading) {
    content = <Spinner />;
  } else {
    content = (
      <div>
        <UserInfo user={user} />
        <UserFeedback userId={user.id} />
      </div>
    );
  }

  return (
    <div>
      <BackButton linkTo="/admin/users" />

      {content}
    </div>
  );
};

UserDetail.propTypes = propTypesUserDetail;
UserDetail.defaultProps = defaultPropsUserDetail;

export default UserDetail;
