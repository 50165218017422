class FixEnterNotWorkingModule {
  constructor(quill, options) {
    quill.on("editor-change", function(eventName, ...args) {
      if (eventName === "text-change") {
        // args[0] will be delta
        var ops = args[0]["ops"];
        var oldSelection = quill.getSelection();
        var oldPos = oldSelection ? oldSelection.index : null;
        var oldSelectionLength = oldSelection ? oldSelection.length : 0;

        if (
          ops[0]["retain"] === undefined ||
          !ops[1] ||
          !ops[1]["insert"] ||
          !ops[1]["insert"] ||
          ops[1]["insert"] != "\n" ||
          oldSelectionLength > 0
        ) {
          return;
        }

        setTimeout(function() {
          var newPos = quill.getSelection().index;
          if (newPos === oldPos) {
            quill.setSelection(quill.getSelection().index + 1, 0);
          }
        }, 30);
      }
    });
  }
}

export default FixEnterNotWorkingModule;
