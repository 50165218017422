/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ViewImage from "./ViewImage/ViewImage";
import EditImage from "./EditImage/EditImage";
import SearchImage from "./SearchImage/SearchImage";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onImageChosen: PropTypes.func.isRequired,
  nested: PropTypes.bool,
  onlyFileUpload: PropTypes.bool,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  loading: false,
  nested: false,
  onlyFileUpload: false,
};

const useChooseImageModal = ({
  onOpen,
  onClose,
  onImageChosen,
  nested,
  onlyFileUpload,
}) => {
  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose,
    isNested: nested,
  });
  const [entity, setEntity] = useState(null);
  const [entityName, setEntityName] = useState(null);
  const [entityType, setEntityType] = useState(null);
  const [entityId, setEntityId] = useState(null);
  const [existingImage, setExistingImage] = useState(null);
  const [droppedContent, setDroppedContent] = useState(null);
  const [forceSquare, setForceSquare] = useState(true);
  const [editMode, setEditMode] = useState(true);

  const onImageChosenHandler = (imageInput) => {
    if (entity) {
      onImageChosen(entity, imageInput);
    } else {
      onImageChosen(imageInput);
    }
  };

  let content = null;
  if (isOpened) {
    content = (
      <ChooseImageModal
        show={isOpened}
        editMode={editMode}
        onCloseHandler={close}
        onImageChosen={onImageChosenHandler}
        nested={nested}
        onlyFileUpload={onlyFileUpload}
        forceSquare={forceSquare}
        existingImageProp={existingImage}
        entityName={entityName}
        entityType={entityType}
        entityId={entityId}
        droppedContent={droppedContent}
      />
    );
  }

  const openModal = ({
    entityToChooseImageFor = null,
    settingForceSquare = true,
    existingImageOfEntity = null,
    entityName = null,
    entityType = null,
    entityId = null,
    editMode = true,
    droppedContent = null,
  }) => {
    setEntity(entityToChooseImageFor);
    setForceSquare(settingForceSquare);
    setExistingImage(existingImageOfEntity);
    setEntityName(entityName);
    setEntityType(entityType);
    setEntityId(entityId);
    setEditMode(editMode);
    setDroppedContent(droppedContent);
    open();
  };

  const closeModal = () => {
    close();
    setEntity(null);
  };

  return { open: openModal, close: closeModal, content };
};

useChooseImageModal.propTypes = propTypes;
useChooseImageModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  editMode: PropTypes.bool,
  onCloseHandler: PropTypes.func.isRequired,
  nested: PropTypes.bool,
  onImageChosen: PropTypes.func.isRequired,
  onlyFileUpload: PropTypes.bool,
  forceSquare: PropTypes.bool,
  existingImageProp: PropTypes.shape({
    url: PropTypes.string,
  }),
  entityName: PropTypes.string,
  entityType: PropTypes.string,
  entityId: PropTypes.string,
  droppedContent: PropTypes.object,
};

const defaultPropsModal = {
  onlyFileUpload: false,
  editMode: true,
  nested: false,
  forceSquare: true,
  existingImageProp: null,
  entityName: null,
  entityType: null,
  entityId: null,
  droppedContent: null,
};

const ChooseImageModal = ({
  show,
  editMode,
  onCloseHandler,
  nested,
  onImageChosen,
  onlyFileUpload,
  forceSquare,
  existingImageProp,
  entityName,
  entityType,
  entityId,
  droppedContent,
}) => {
  const { t } = useTranslation();
  const [existingImage, setExistingImage] = useState(existingImageProp);
  const [selectedImage, setSelectedImage] = useState(null);

  const onSelectImage = (url, meta, imageObject) => {
    setSelectedImage({ url, meta, imageObject });
  };

  const onUnselectImage = () => {
    setSelectedImage(null);
  };

  const onURLEntered = (url) => {
    const isLocal = url.startsWith("data:image");

    onSelectImage(
      url,
      { source: isLocal ? "Upload" : "URL", sourceUrl: url },
      null
    );
  };

  useEffect(() => {
    if (droppedContent) {
      setExistingImage(null);
      onHandleDroppedContent(droppedContent);
    }
  }, [droppedContent]);

  const onHandleDroppedContent = (item) => {
    // console.log({ item });

    if (item.files) {
      for (const file of item.files) {
        if (file.type && file.type.startsWith("image/")) {
          onSelectImage(URL.createObjectURL(file), { source: "Upload" }, null);
          return;
        }
      }

      toast.error(t("error.DROP_NOT_AN_IMAGE"));
    }

    if (item.html) {
      const { body } = new DOMParser().parseFromString(item.html, "text/html");
      const imageTag = body.querySelector("img");
      const imageSrc = imageTag ? imageTag.src : null;
      if (imageSrc) {
        onURLEntered(imageSrc);
      } else {
        toast.error(t("error.DROP_NOT_AN_IMAGE"));
      }
      return;
    }

    if (item.urls) {
      onURLEntered(item.urls[0]);
      return;
    }
  };

  let content = null;
  const searchContent = (
    <SearchImage
      onSelectImage={onSelectImage}
      onURLEntered={onURLEntered}
      onDropContent={onHandleDroppedContent}
      onCloseModal={onCloseHandler}
      onlyFileUpload={onlyFileUpload}
      hidden={existingImage != null || selectedImage != null}
      entityType={entityType}
      entityId={entityId}
    />
  );

  if (existingImage) {
    content = (
      <ViewImage
        image={existingImage}
        entityName={entityName}
        entityType={entityType}
        editMode={editMode}
        onReplaceImage={() => setExistingImage(null)}
        onRemoveImage={() => {
          onImageChosen(null);
          onCloseHandler();
        }}
        onCloseModal={onCloseHandler}
      />
    );
  } else if (selectedImage) {
    content = (
      <EditImage
        selectedImage={selectedImage}
        onChooseImage={(imageInput) => {
          onImageChosen(imageInput);
        }}
        onUnselectImage={onUnselectImage}
        forceSquare={forceSquare}
        onCloseModal={onCloseHandler}
      />
    );
  }

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler} nested={nested}>
      {searchContent}
      {content}
    </BaseModal>
  );
};

ChooseImageModal.propTypes = propTypesModal;
ChooseImageModal.defaultProps = defaultPropsModal;

export default useChooseImageModal;
