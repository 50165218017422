import { gql } from "@apollo/client";

const GET_CONVERSATION = gql`
  query GetConversation {
    conversation {
      id
      text
      createdAt
      fromUser {
        id
        name
        image {
          id
          url
        }
      }
      toUser {
        id
        name
        image {
          id
          url
        }
      }
    }
  }
`;

const GET_SPECIFIC_CONVERSATION = gql`
  query($userId: ID!) {
    conversationOf(userId: $userId) {
      id
      text
      createdAt
      fromUser {
        id
        name
        image {
          id
          url
        }
      }
      toUser {
        id
        name
        image {
          id
          url
        }
      }
    }
  }
`;

const GET_UNREAD_MESSAGES_COUNT = gql`
  query GetUnreadMessagesCount {
    unreadMessagesCount
  }
`;

const GET_UNREAD_MESSAGES_OF_USERS = gql`
  query {
    unreadMessagesOfUsers {
      id
      createdAt
      fromUser {
        id
        name
        image {
          id
          url
        }
      }
    }
  }
`;

const CREATE_MESSAGE = gql`
  mutation($input: CreateMessageInput!) {
    createMessage(input: $input) {
      code
      message
      createdMessage {
        id
        text
        createdAt
        fromUser {
          id
          name
          image {
            id
            url
          }
        }
        toUser {
          id
          name
          image {
            id
            url
          }
        }
      }
    }
  }
`;

const CREATE_MESSAGE_SPECIFIC = gql`
  mutation($input: CreateMessageToInput!) {
    createMessageTo(input: $input) {
      code
      message
      createdMessage {
        id
        text
        createdAt
        fromUser {
          id
          name
        }
        toUser {
          id
          name
        }
      }
    }
  }
`;

const MARK_MESSAGES_AS_READ = gql`
  mutation {
    markMessagesAsRead {
      code
      message
    }
  }
`;

const MARK_MESSAGES_FROM_USER_AS_READ = gql`
  mutation($userId: ID!) {
    markMessagesFromUserAsRead(userId: $userId) {
      code
      message
    }
  }
`;

const mergeConversationAfterUpdate = (cache, mutationResult) => {
  mergeConversationUpdates(
    cache,
    mutationResult.data.createMessage.createdMessage
  );
};

const mergeConversationUpdates = (cache, createdMessage) => {
  const cachedData = cache.readQuery({
    query: GET_CONVERSATION,
  });

  const cachedConversation = cachedData.conversation;

  // merge updates into to current data
  const updatedData = cachedConversation.concat([createdMessage]);

  cache.writeQuery({
    query: GET_CONVERSATION,
    data: {
      conversation: updatedData,
    },
  });
};

const mergeSpecificConversationAfterUpdate = (cache, mutationResult) => {
  mergeSpecificConversationUpdates(
    cache,
    mutationResult.data.createMessageTo.createdMessage
  );
};

const mergeSpecificConversationUpdates = (cache, createdMessage) => {
  const cachedData = cache.readQuery({
    query: GET_SPECIFIC_CONVERSATION,
    variables: {
      userId: createdMessage.toUser.id,
    },
  });

  const cachedConversation = cachedData.conversationOf;

  // merge updates into to current data
  const updatedData = cachedConversation.concat([createdMessage]);

  cache.writeQuery({
    query: GET_SPECIFIC_CONVERSATION,
    variables: {
      userId: createdMessage.toUser.id,
    },
    data: {
      conversationOf: updatedData,
    },
  });
};

const resetUnreadCountAfterUpdate = (cache, mutationResult) => {
  cache.writeQuery({
    query: GET_UNREAD_MESSAGES_COUNT,
    data: {
      unreadMessagesCount: 0,
    },
  });
};

const removeUnreadMessageFromUserAfterUpdate = (
  cache,
  mutationResult,
  userId
) => {
  try {
    const cachedData = cache.readQuery({
      query: GET_UNREAD_MESSAGES_OF_USERS,
    });

    const cachedMessages = cachedData.unreadMessagesOfUsers;
    const updatedData = cachedMessages.filter(
      (message) => message.fromUser.id !== userId
    );

    cache.writeQuery({
      query: GET_UNREAD_MESSAGES_OF_USERS,
      data: {
        unreadMessagesOfUsers: updatedData,
      },
    });
  } catch (e) {
    // cache does not exist, supress error
  }
};

export {
  GET_CONVERSATION,
  GET_SPECIFIC_CONVERSATION,
  GET_UNREAD_MESSAGES_COUNT,
  GET_UNREAD_MESSAGES_OF_USERS,
  CREATE_MESSAGE,
  CREATE_MESSAGE_SPECIFIC,
  MARK_MESSAGES_AS_READ,
  MARK_MESSAGES_FROM_USER_AS_READ,
  mergeConversationAfterUpdate,
  mergeConversationUpdates,
  mergeSpecificConversationAfterUpdate,
  mergeSpecificConversationUpdates,
  resetUnreadCountAfterUpdate,
  removeUnreadMessageFromUserAfterUpdate,
};
