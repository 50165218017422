import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { BeatLoader } from "react-spinners";

import styles from "./ImageSuggestions.module.scss";
import { GET_ENTRY_IMAGE_SUGGESTIONS } from "../../../../../GraphQl/searchImages";
import ImageSearchQueries from "./ImageSearchQueries/ImageSearchQueries";
import ImageSuggestionsGallery from "./ImageSuggestionsGallery/ImageSuggestionsGallery";

const propTypesImageSuggestions = {
  entityType: PropTypes.string,
  entityId: PropTypes.string,
  onSearchQuerySelected: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
};
const defaultPropsImageSuggestions = {
  entityType: null,
  entityId: null,
};

const ImageSuggestions = ({
  entityType,
  entityId,
  onSearchQuerySelected,
  onImageSelected,
}) => {
  const [hasSuggestions] = useState(
    entityId != null && (entityType === "entry" || entityType === "block")
  );

  const [
    getImageSuggestions,
    { data: imageSuggestionData, loading: imageSuggestionsLoading },
  ] = useLazyQuery(GET_ENTRY_IMAGE_SUGGESTIONS, {
    fetchPolicy: "cache-and-network",
  });

  const suggestions = imageSuggestionData
    ? imageSuggestionData.imageSuggestions
    : null;

  useEffect(() => {
    if (hasSuggestions) {
      getImageSuggestions({
        variables: {
          input: {
            entityType,
            entityId,
          },
        },
      });
    }
  }, [hasSuggestions]);

  let content = null;
  if (
    hasSuggestions &&
    (imageSuggestionsLoading ||
      (suggestions != null && suggestions.searchQueries.length > 0))
  ) {
    let loaderContent = null;

    if (suggestions) {
      let imageContent = null;
      if (suggestions.images && suggestions.images.length > 0) {
        imageContent = (
          <div className={styles.imageSuggestionsContainer}>
            <ImageSuggestionsGallery
              images={suggestions.images}
              onImageSelected={onImageSelected}
            />
          </div>
        );
      }
      content = (
        <>
          <div className={styles.searchQueriesContainer}>
            <ImageSearchQueries
              searchQueries={suggestions.searchQueries}
              onSearchQueryClicked={(searchQuery) =>
                onSearchQuerySelected(searchQuery)
              }
            />
          </div>
          {imageContent}
        </>
      );
    } else if (imageSuggestionsLoading) {
      loaderContent = (
        <div className={styles.searchLoadingWrapper}>
          <BeatLoader
            sizeUnit="px"
            size={10}
            color="#7A7A7A"
            loading={imageSuggestionsLoading}
          />
        </div>
      );
      content = loaderContent;
    }
  }

  return <div>{content}</div>;
};

ImageSuggestions.propTypes = propTypesImageSuggestions;
ImageSuggestions.defaultProps = defaultPropsImageSuggestions;

export default ImageSuggestions;
