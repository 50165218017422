import * as d3 from "d3";

class GraphCreation {
  constructor(graphConfig) {
    this.graphConfig = graphConfig;
  }

  createNewEmptyGraph(rootElement, width, height) {
    d3.select(rootElement)
      .selectAll("*")
      .remove();

    this.width = width;
    this.height = height;

    const svg = d3
      .select(rootElement)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const defs = svg.append("defs");
    const clipPathImageRect = defs.append("clipPath").attr("id", "imageClip");
    clipPathImageRect
      .append("rect")
      .attr("rx", this.graphConfig.nodeCornerRadius)
      .attr("ry", this.graphConfig.nodeCornerRadius)
      .attr("width", this.graphConfig.nodeHeight)
      .attr("height", this.graphConfig.nodeHeight);
    clipPathImageRect
      .append("rect")
      .attr("x", this.graphConfig.nodeHeight / 2)
      .attr("width", this.graphConfig.nodeHeight / 2)
      .attr("height", this.graphConfig.nodeHeight);

    const clipPathImageRound = defs
      .append("clipPath")
      .attr("id", "imageRoundClip");
    clipPathImageRound
      .append("circle")
      .attr("r", this.graphConfig.rootNodeImageSize / 2);

    const shadowFilter = defs.append("filter").attr("id", "nodeShadow");
    shadowFilter
      .append("feDropShadow")
      .attr("dx", this.graphConfig.nodeShadowOffsetX)
      .attr("dy", this.graphConfig.nodeShadowOffsetY)
      .attr("stdDeviation", this.graphConfig.nodeShadowStdDeviation)
      .attr("flood-opacity", this.graphConfig.nodeShadowOpacity);

    const shadowDraggedFilter = defs
      .append("filter")
      .attr("id", "nodeDraggedShadow");
    shadowDraggedFilter
      .append("feDropShadow")
      .attr("dx", this.graphConfig.nodeDraggedShadowOffsetX)
      .attr("dy", this.graphConfig.nodeDraggedShadowOffsetY)
      .attr("stdDeviation", this.graphConfig.nodeDraggedShadowStdDeviation)
      .attr("flood-opacity", this.graphConfig.nodeDraggedShadowOpacity);

    const shadowFilterLevelIndicator = defs
      .append("filter")
      .attr("id", "levelIndicatorShadow")
      .attr("x", "-20%")
      .attr("y", "-20%")
      .attr("height", "140%")
      .attr("width", "140%");
    shadowFilterLevelIndicator
      .append("feDropShadow")
      .attr("dx", this.graphConfig.nodeShadowOffsetX)
      .attr("dy", this.graphConfig.nodeShadowOffsetY)
      .attr("stdDeviation", this.graphConfig.nodeShadowStdDeviation)
      .attr("flood-opacity", this.graphConfig.nodeShadowOpacity);

    const greyScaleFilter = defs.append("filter").attr("id", "grayScale");
    greyScaleFilter
      .append("feColorMatrix")
      .attr("type", "matrix")
      .attr(
        "values",
        "0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0"
      );

    svg.append("g").attr("id", "rootGroup");
  }
}

export default GraphCreation;
