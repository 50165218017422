import { ApolloClient, ApolloLink, gql } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";

import Logger from "js-logger";
import { toast } from "react-toastify";
import i18n from "./i18n";
import { cache } from "./apollo-cache";
import DebounceLink from "../components/Utils/DebounceLink";
import { logout } from "../components/Utils/Logout";

// ==============================================
// apollo client init
// ==============================================
const DEFAULT_DEBOUNCE_TIMEOUT = 800;
const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
});

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(async (err) => {
      const errorCode = err.extensions.code;
      Logger.error(`[GraphQL error]: ${errorCode}`);
      Logger.error(err.message);
      toast.error(i18n.t(`error.${errorCode}`), { toastId: errorCode });

      if (errorCode === "UNAUTHENTICATED") {
        logout();
      }
    });
  }
  if (networkError) {
    Logger.error(`[Network error]: ${networkError}`);
    // no error toast for certain non essential requests
    if (
      operation.operationName === "GetUnreadMessagesCount" ||
      operation.operationName === "GetConversation" ||
      operation.operationName === "PostUsageTime" ||
      operation.operationName === "ViewBlock"
    )
      return;
    toast.error(i18n.t(`error.NETWORK_ERROR`), { toastId: "NETWORK_ERROR" });
  }
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, debounceLink, httpLink]),
  cache,
});

export default apolloClient;
