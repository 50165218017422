import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./CardButton.module.scss";

const propTypesCardButton = {
  linkTo: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
const defaultPropsCardButton = {};

const CardButton = ({ linkTo, text, icon }) => {
  return (
    <Link className={`box ${styles.container}`} to={linkTo}>
      <span className={`icon ${styles.image}`}>
        <i className={`fas fa-2x ${icon}`} />
      </span>

      <div>
        <p className={styles.text}>{text}</p>
      </div>
    </Link>
  );
};

CardButton.propTypes = propTypesCardButton;
CardButton.defaultProps = defaultPropsCardButton;

export default CardButton;
