/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./ReviewContent.module.scss";
import entryBrowserStyles from "../../../../EntryBrowser.module.scss";
import ContentQuillEditor from "../../../../../../UI/Editor/ContentQuillEditor";
import { TooltipDelayed } from "../../../../../../UI/Tooltip/Tooltip";

const propTypesReviewContent = {
  card: PropTypes.shape({
    id: PropTypes.string,
    frontText: PropTypes.object,
    frontTextFromParent: PropTypes.bool,
  }).isRequired,
  onUpdateCard: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
};
const defaultPropsReviewContent = {
  editMode: false,
};

const ReviewContent = ({ card, onUpdateCard, editMode }) => {
  const { t } = useTranslation();

  const showBlockTextContent = (
    <TooltipDelayed
      content={t("wisdomtree.entry_browser.tooltips.card_show_content_review")}
    >
      <label className={`checkbox ${styles.checkbox}`}>
        <input
          type="checkbox"
          disabled={editMode ? null : "disabled"}
          checked={card.frontTextFromParent}
          onChange={(event) => {
            onUpdateCard(
              card.id,
              {
                frontTextFromParent: event.target.checked,
                frontTitleFromParent: event.target.checked,
                frontImageFromParent: event.target.checked,
              },
              event.target.checked
            );
          }}
        />
        {t("wisdomtree.entry_browser.card_review_show_content")}
      </label>
    </TooltipDelayed>
  );

  let frontContentEditor = null;
  if (!card.frontTextFromParent) {
    frontContentEditor = (
      <div className={styles.cardEditor}>
        <ContentQuillEditor
          elementId={card.id}
          elementContent={card.frontText}
          hintText={t("wisdomtree.entry_browser.card_review_text_hint")}
          stylesClassName="blockContentEditor"
          editMode={editMode}
          focusInitially={false}
          onContentChanged={(content) => {
            onUpdateCard(card.id, { frontText: content }, true);
          }}
          tabDisabled={true}
        />
      </div>
    );
  }

  return (
    <div className={entryBrowserStyles.blockContentContainer}>
      {showBlockTextContent} {frontContentEditor}
    </div>
  );
};

ReviewContent.propTypes = propTypesReviewContent;
ReviewContent.defaultProps = defaultPropsReviewContent;

export default ReviewContent;
