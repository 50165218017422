/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./AddCardDropdown.module.css";
import { getIconForCardType } from "../../../../../Utils/Utils";
import Dropdown from "../../../../../UI/Dropdown/Dropdown";

const propTypes = {
  onCreateCard: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

const defaultProps = {
  isLoading: false
};

const AddCardDropdown = ({ onCreateCard, isLoading }) => {
  const { t } = useTranslation();

  // const cardTypes = ["REVIEW", "QUESTION", "CLOZE"];
  const cardTypes = ["REVIEW", "QUESTION"];
  const items = cardTypes.map(type => {
    return {
      key: type,
      icon: getIconForCardType(type),
      text: t(`wisdomtree.card.${type}`),
      tooltip: t(`wisdomtree.entry_browser.tooltips.card_type_${type}`)
    };
  });

  return (
    <div id={styles.dropdownContainer}>
      <Dropdown
        items={items}
        buttonText={t(`wisdomtree.entry_browser.block_add_card`)}
        buttonTooltip={t(
          "wisdomtree.entry_browser.tooltips.card_add_learning_card"
        )}
        buttonIcon="fa-plus"
        isLoading={isLoading}
        isUsingHover={false}
        showDropdownIcon={false}
        alignment="is-up"
        onItemClicked={item => onCreateCard(item.key)}
      />
    </div>
  );
};

AddCardDropdown.propTypes = propTypes;
AddCardDropdown.defaultProps = defaultProps;

export default AddCardDropdown;
