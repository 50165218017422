import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withSize } from "react-sizeme";

import styles from "../../MenuBar.module.scss";
import Breadcrumb from "./Breadcrumb/Breadcrumb";
import ContextActions from "./ContextActions/ContextActions";

const propTypes = {
  editMode: PropTypes.bool,
  sharingId: PropTypes.string,
  sharingRootEntryId: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  selectedEntry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    parentEntry: PropTypes.number,
    cardsActiveAmountRecursive: PropTypes.integer,
    cardsDueAmountRecursive: PropTypes.integer,
  }),
  selectedEntryDetails: PropTypes.shape({
    cardsActiveAmountRecursive: PropTypes.integer,
    cardsDueAmountRecursive: PropTypes.integer,
    bookmarked: PropTypes.bool,
  }),
  selectedEntryDetailsLoading: PropTypes.bool,
  onUpdateEntry: PropTypes.func.isRequired,
  onDeleteEntry: PropTypes.func.isRequired,
  onAddReference: PropTypes.func.isRequired,
  onSetCategory: PropTypes.func.isRequired,
  hasTags: PropTypes.bool.isRequired,
  onShowTags: PropTypes.func.isRequired,
  onDialogOpenedChanged: PropTypes.func.isRequired,
  size: PropTypes.shape({
    width: PropTypes.number,
  }),
};

const defaultProps = {
  editMode: false,
  sharingId: null,
  sharingRootEntryId: null,
  user: null,
  entries: [],
  selectedEntry: null,
  selectedEntryDetails: null,
  selectedEntryDetailsLoading: false,
  size: null,
};

const MenuBarSelectedEntry = ({
  selectedEntry,
  selectedEntryDetails,
  selectedEntryDetailsLoading,
  entries,
  editMode,
  sharingId,
  sharingRootEntryId,
  user,
  onUpdateEntry,
  onDeleteEntry,
  onAddReference,
  onSetCategory,
  hasTags,
  onShowTags,
  onDialogOpenedChanged,
  size,
}) => {
  let width = size.width;
  if (!size.width) {
    const contentContainer = document.getElementById("ContentContainer");
    if (contentContainer) width = contentContainer.clientWidth;
  }

  const targetWidthMenuBarLeft = width / 2;
  const targetWidthMenuBarRight = width / 2;

  let contentBreadcrumb = null;
  let contentContextActions = null;

  contentBreadcrumb = (
    <Breadcrumb
      entries={entries}
      user={user}
      selectedEntry={selectedEntry}
      sharingId={sharingId}
      sharingRootEntryId={sharingRootEntryId}
      onDialogOpenedChanged={onDialogOpenedChanged}
      targetWidth={targetWidthMenuBarLeft}
    />
  );

  contentContextActions = (
    <ContextActions
      selectedEntry={selectedEntry}
      selectedEntryDetails={selectedEntryDetails}
      selectedEntryDetailsLoading={selectedEntryDetailsLoading}
      editMode={editMode}
      sharingId={sharingId}
      user={user}
      onUpdateEntry={onUpdateEntry}
      onDeleteEntry={onDeleteEntry}
      onAddReference={onAddReference}
      onSetCategory={onSetCategory}
      hasTags={hasTags}
      onShowTags={onShowTags}
      onDialogOpenedChanged={onDialogOpenedChanged}
      targetWidth={targetWidthMenuBarRight}
    />
  );

  return (
    <div className={styles.menuBar}>
      <div className={styles.menuBarInnerContainer}>
        <div className={`${styles.menuBarLeft}`}>{contentBreadcrumb}</div>
        <div className={styles.menuBarRight}>{contentContextActions}</div>
      </div>
    </div>
  );
};

MenuBarSelectedEntry.propTypes = propTypes;
MenuBarSelectedEntry.defaultProps = defaultProps;

export default withSize({ noPlaceholder: true })(MenuBarSelectedEntry);
