import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

import styles from "./BlockAddBeforeButton.module.scss";
import AddBlockDropdown from "../../AddBlockDropdown/AddBlockDropdown";

const propTypesBlockAddBeforeButton = {
  blockPosition: PropTypes.number.isRequired,
  createBlockLoading: PropTypes.bool,
  onCreateBlock: PropTypes.func.isRequired,
};
const defaultPropsBlockAddBeforeButton = {
  createBlockLoading: false,
};

const BlockAddBeforeButton = ({
  blockPosition,
  onCreateBlock,
  createBlockLoading,
}) => {
  const [hovered, setHovered] = useState(false);
  const [focussed, setFocussed] = useState(false);
  const [active, setActive] = useState(false);
  const [clickedCreate, setClickedCreate] = useState(false);

  useEffect(() => {
    if (!createBlockLoading && clickedCreate) setClickedCreate(false);
  }, [createBlockLoading]);

  const buttonContent = (
    <div
      className={`${styles.buttonContainer} ${
        hovered || focussed || active || (createBlockLoading && clickedCreate)
          ? styles.visible
          : null
      }`}
      // ensure it shows in top blocks above blocks below
      style={{ zIndex: 900 - blockPosition }}
    >
      <AddBlockDropdown
        small
        isLoading={createBlockLoading}
        onCreateBlock={(blockType) => {
          setClickedCreate(true);
          onCreateBlock(blockType, blockPosition);
        }}
        onDropdownFocus={() => setActive(true)}
        onDropdownBlur={() => setActive(false)}
      />
    </div>
  );
  return (
    <div className={styles.container}>
      <div
        className={styles.triggerContainer}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        onFocus={() => {
          if (isMobile) setFocussed(true);
        }}
        onBlurCapture={() => {
          if (isMobile) setFocussed(false);
        }}
        tabIndex={-1}
      >
        {buttonContent}
      </div>
    </div>
  );
};

BlockAddBeforeButton.propTypes = propTypesBlockAddBeforeButton;
BlockAddBeforeButton.defaultProps = defaultPropsBlockAddBeforeButton;

export default BlockAddBeforeButton;
