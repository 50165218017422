/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player/youtube";
import Logger from "js-logger";

// eslint-disable-next-line no-unused-vars
import styles from "./TutorialModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import { useMutation } from "@apollo/client";
import {
  CONFIRM_WELCOME,
  mergeUserAfterConfirmWelcome,
} from "../../../GraphQl/user";
import DottedProgressIndicator from "../../DottedProgressIndicator/DottedProgressIndicator";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import FeatureVideo from "./ContentTypes/FeatureVideo/FeatureVideo";
import IntroVideo from "./ContentTypes/IntroVideo/IntroVideo";
import JobToBeDone from "./ContentTypes/JobToBeDone/JobToBeDone";
import NewQuest from "./ContentTypes/NewQuest/NewQuest";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useTutorialModal = ({ onOpen, onClose }) => {
  const { isOpened, open, close } = useBaseModal({ onOpen, onClose });
  const [tutorialConfig, setTutorialConfig] = useState(null);

  let content = null;
  if (isOpened && tutorialConfig) {
    content = (
      <TutorialModal
        show={isOpened}
        tutorialConfig={tutorialConfig}
        onCloseHandler={close}
      />
    );
  }

  const openModal = (config) => {
    setTutorialConfig(config);
    open();
  };

  const closeModal = () => {
    close();
    setTutorialConfig(null);
  };

  return { open: openModal, close: closeModal, content };
};

useTutorialModal.propTypes = propTypes;
useTutorialModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  tutorialConfig: PropTypes.shape({
    cancelable: PropTypes.bool,
    startPage: PropTypes.number,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        videoUrl: PropTypes.string,
        text: PropTypes.string,
        type: PropTypes.string.isRequired,
        nextText: PropTypes.string,
      })
    ),
    onFinish: PropTypes.func,
    onCancel: PropTypes.func,
  }).isRequired,
  onCloseHandler: PropTypes.func.isRequired,
};

const defaultPropsModal = {};

const TutorialModal = ({ show, tutorialConfig, onCloseHandler }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [animationRunning, setAnimationRunning] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!show) return;
      if (!tutorialConfig.cancelable) return;
      onCloseHandler();
    },
    dependsOn: [onCloseHandler, show, tutorialConfig],
  });

  useEffect(() => {
    setCurrentPage(tutorialConfig.startPage ? tutorialConfig.startPage : 0);
  }, [tutorialConfig]);

  const onSwitchPage = (page) => {
    if (animationRunning) return;
    setAnimationRunning(true);
    setNextPage(page);
    setTimeout(() => {
      setCurrentPage(page);
      setNextPage(null);
      setAnimationRunning(false);
    }, 250);
  };

  const onCancel = () => {
    if (!tutorialConfig.cancelable) return;
    if (tutorialConfig.onCancel != null) tutorialConfig.onCancel();
    onCloseHandler();
  };

  let content = null;
  const currentPageContent = tutorialConfig.content[currentPage];
  if (currentPageContent.type === "feature_video") {
    content = (
      <FeatureVideo
        videoUrl={currentPageContent.videoUrl}
        text={currentPageContent.text}
        paused={animationRunning}
      />
    );
  } else if (currentPageContent.type === "intro_video") {
    content = (
      <IntroVideo
        videoUrl={currentPageContent.videoUrl}
        text={currentPageContent.text}
        onHighestProgressUpdate={currentPageContent.onHighestProgressChanged}
      />
    );
  } else if (currentPageContent.type === "job_to_be_done") {
    content = (
      <JobToBeDone
        title={currentPageContent.title}
        defaultJobToBeDone={currentPageContent.selectedJobToBeDone}
        onSelectJobToBeDone={currentPageContent.onSelectJobToBeDone}
      />
    );
  } else if (currentPageContent.type === "new_quest") {
    content = (
      <NewQuest
        title={currentPageContent.title}
        subtitle={currentPageContent.subtitle}
      />
    );
  }

  let footerContent = null;

  const buttonPage = nextPage != null ? nextPage : currentPage;

  let backButton = null;
  if (buttonPage > 0) {
    backButton = (
      <button
        className={`button ${styles.backButton}`}
        type="button"
        data-id="button.tutorial-previous"
        onClick={() => {
          onSwitchPage(currentPage - 1);
        }}
      >
        <span className="icon is-small">
          <i className="fas fa-chevron-left"></i>
        </span>
        <span>{t("wisdomtree.modal_tutorial.previous")}</span>
      </button>
    );
  }

  const isOnLastPage = currentPage >= tutorialConfig.content.length - 1;
  let nextText = isOnLastPage
    ? t("wisdomtree.modal_tutorial.done")
    : t("wisdomtree.modal_tutorial.next");
  if (tutorialConfig.content[currentPage].nextText)
    nextText = tutorialConfig.content[currentPage].nextText;

  let nextButtonDisabled = false;
  if (tutorialConfig.content[currentPage].isNextEnabled)
    nextButtonDisabled = !tutorialConfig.content[currentPage].isNextEnabled();
  if (isLoading) nextButtonDisabled = true;

  let nextButton = (
    <button
      className={`button is-primary ${styles.nextButton} ${
        isLoading ? "is-loading" : null
      }`}
      type="button"
      data-id={
        !isOnLastPage ? "button.tutorial-next" : "button.tutorial-finish"
      }
      disabled={nextButtonDisabled}
      onClick={async () => {
        if (!isOnLastPage) {
          onSwitchPage(buttonPage + 1);
        } else {
          if (tutorialConfig.onFinish != null) {
            setLoading(true);
            await tutorialConfig.onFinish();
            setLoading(false);
          }
          onCloseHandler();
        }
      }}
    >
      <span>{nextText}</span>
      {!isOnLastPage ? (
        <span className="icon is-small">
          <i className="fas fa-chevron-right"></i>
        </span>
      ) : null}
    </button>
  );
  footerContent = (
    <>
      <div
        className={`${styles.footerItemContainer} ${styles.backButtonContainer}`}
      >
        {backButton}
      </div>
      <div
        className={`${styles.footerItemContainer} ${styles.progressContainer}`}
      >
        <DottedProgressIndicator
          progress={currentPage}
          animatingTo={animationRunning ? nextPage : null}
          max={tutorialConfig.content.length}
          onClickDot={(index) => {
            onSwitchPage(index);
          }}
        />
      </div>
      <div
        className={`${styles.footerItemContainer} ${styles.nextButtonContainer}`}
      >
        {nextButton}
      </div>
    </>
  );
  return (
    <BaseModal show={show} onCloseHandler={onCancel}>
      <div className={`modal-card ${styles.modalCard}`}>
        <section className={`modal-card-body ${styles.modalBody}`}>
          <div className={styles.closeContainer}>
            {tutorialConfig.cancelable ? (
              <ModalCloseButton onClick={onCancel} />
            ) : null}
          </div>
          <div
            className={`${styles.pageContainer} ${
              animationRunning ? styles.animated : null
            }`}
          >
            {content}
          </div>
        </section>

        <footer className="modal-card-foot">{footerContent}</footer>
      </div>
    </BaseModal>
  );
};

TutorialModal.propTypes = propTypesModal;
TutorialModal.defaultProps = defaultPropsModal;

export default useTutorialModal;
