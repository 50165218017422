/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./QuizResult.module.scss";
import quizStyles from "../Quiz.module.scss";
import { GET_AMOUNT_OF_DUE_CARDS } from "../../../GraphQl/quiz";
import Spinner from "../../../UI/Spinner/Spinner";
import NotificationSubscription from "../../../UI/NotificationSubscription/NotificationSubscription";
import { browserSupportsPush } from "../../../Utils/PushNotifications";
import RandomGif from "../../../UI/RandomGif/RandomGif";
import {
  useDesktop,
  useMobile,
  useMobileLarge,
} from "../../../Utils/Responsive";
import AnimatedEmoji from "../../../UI/AnimatedEmoji/AnimatedEmoji";
import {
  mergeUserAfterUpdate,
  UPDATE_CURRENT_USER,
} from "../../../GraphQl/user";
import Logger from "js-logger";
import { isMobileApp } from "../../../Utils/ReactNativeMessageUtils";
import { mobileNotificationPermissionStatus } from "../../../../config/apollo-cache";

const propTypesQuizResult = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      cardId: PropTypes.string,
      performanceRating: PropTypes.number,
    })
  ).isRequired,
  expEvent: PropTypes.shape({
    exp: PropTypes.number,
  }),
  rootEntryId: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  user: PropTypes.shape({
    quizReminder: PropTypes.bool,
  }),
};
const defaultPropsQuizResult = {
  rootEntryId: null,
  expEvent: null,
};

const QuizResult = ({ results, expEvent, rootEntryId, onNext, user }) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const isMobileLarge = useMobileLarge();
  const isDesktop = useDesktop();

  const [notificationsHandled, setNotificationsHandled] = useState(false);
  const mobileNotificationStatus = useReactiveVar(
    mobileNotificationPermissionStatus
  );

  const { loading: dueCardsAmountLoading, data: dueCardsAmountData } = useQuery(
    GET_AMOUNT_OF_DUE_CARDS,
    {
      variables: {
        entryId: rootEntryId,
      },
      fetchPolicy: "no-cache",
    }
  );

  const [updateUserQuizReminderSetting] = useMutation(UPDATE_CURRENT_USER, {
    update: (cache, mutationResult) => {
      mergeUserAfterUpdate(cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  let contentEnableNotifications = null;
  let contentButtons = null;
  if (dueCardsAmountLoading) {
    contentButtons = <Spinner />;
  } else {
    const dueCardsRemaining = dueCardsAmountData.dueCardsAmount > 0;

    const cancelTo = rootEntryId !== null ? `/entry/${rootEntryId}` : "/home";
    const contentButtonFinish = (
      <Link
        className={`button ${
          dueCardsRemaining ? "" : "is-primary is-outlined"
        } ${styles.button}`}
        to={cancelTo}
      >
        <span>{t("wisdomtree.quiz.finish")}</span>
      </Link>
    );

    let contentButtonNext = null;
    if (dueCardsRemaining) {
      contentButtonNext = (
        <button
          type="button"
          className={`button is-primary is-outlined ${styles.button}`}
          onClick={() => onNext()}
        >
          <span>{t("wisdomtree.quiz.next")}</span>
        </button>
      );
    }

    if (
      !dueCardsRemaining &&
      ((isMobileApp() && mobileNotificationStatus === "undetermined") ||
        (browserSupportsPush() && Notification.permission === "default")) &&
      !notificationsHandled
    ) {
      contentEnableNotifications = (
        <div className={`box ${styles.notificationContainer}`}>
          <p>{t("wisdomtree.quiz.notify_info")}</p>
          <div className={styles.notificationButtonContainer}>
            <NotificationSubscription
              text={t("wisdomtree.quiz.notify_button")}
              onPermissionHandled={() => {
                setNotificationsHandled(true);
              }}
              onPermissionChanged={(permission) => {
                if (permission === "granted") {
                  updateUserQuizReminderSetting({
                    variables: {
                      input: {
                        quizReminder: true,
                      },
                    },
                  });
                }
              }}
            />
          </div>
        </div>
      );
    }

    contentButtons = (
      <>
        {contentButtonFinish}
        {contentButtonNext}
      </>
    );
  }

  let expContent = null;
  if (expEvent && expEvent.exp > 0) {
    expContent = (
      <div className={`${styles.expContainer}`}>
        <span className={classNames(styles.expText)}>+{expEvent.exp}</span>
        <span className={classNames("icon", styles.expIcon)}>
          <i className="fas fa-seedling" />
        </span>
      </div>
    );
  }

  let emojiSize = 60;
  if (isMobile) {
    emojiSize = 60;
  } else if (isDesktop) {
    emojiSize = 100;
  } else {
    emojiSize = 80;
  }

  return (
    <div>
      <div className={`box ${styles.resultContainer}`}>
        <div className={`${styles.resultContent}`}>
          {/* <span className={classNames("icon", styles.icon)}>
            <i className="far fa-2x fa-smile-beam" />
          </span> */}
          <div className={styles.emojiContainer}>
            <AnimatedEmoji emoji="🎉" size={emojiSize} />
          </div>

          <p className={`${styles.title}`}>
            {t("wisdomtree.quiz.result_title")}
          </p>
          <p className={`${styles.subtitle}`}>
            {t("wisdomtree.quiz.result_subtitle", { count: results.length })}
          </p>
          {expContent}
        </div>
      </div>
      {contentEnableNotifications}
      <div className={quizStyles.buttonsContainer}>{contentButtons}</div>
    </div>
  );
};

QuizResult.propTypes = propTypesQuizResult;
QuizResult.defaultProps = defaultPropsQuizResult;

export default QuizResult;
