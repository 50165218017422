import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./CreateVariant.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";

const propTypesCreateVariant = {
  onCreateVariant: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
const defaultPropsCreateVariant = {
  placeholder: "Create new synonym ...",
};

const CreateVariant = ({ onCreateVariant, placeholder }) => {
  const [inputName, setInputName] = useState("");
  const [inputParentId, setInputParentId] = useState("");

  const onCreateVariantHandler = () => {
    onCreateVariant({ name: inputName, parentId: inputParentId });
    setInputName("");
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (inputName.length === 0) return;
      onCreateVariantHandler();
    },
    dependsOn: [onCreateVariantHandler, inputName],
  });

  const onChangeInputNameHandler = (event) => {
    setInputName(event.target.value);
  };

  const onChangeInputParentHandler = (event) => {
    setInputParentId(event.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.inputContainer} control`}>
        <input
          className={`input ${styles.inputName}`}
          type="text"
          value={inputName}
          onChange={onChangeInputNameHandler}
          placeholder={placeholder}
        />
      </div>
      <div className={`${styles.inputParentContainer} control`}>
        <input
          className={`input ${styles.inputParent}`}
          type="number"
          value={inputParentId}
          onChange={onChangeInputParentHandler}
          placeholder="Parent CE-ID"
        />
      </div>
      <button
        className={`button ${inputName.length > 0 ? "is-primary" : null} ${
          styles.button
        }`}
        type="button"
        disabled={inputName.length === 0}
        onClick={() => {
          onCreateVariantHandler();
          document.activeElement.blur();
        }}
      >
        <span className="icon is-small">
          <i className="fas fa-plus" />
        </span>
      </button>
    </div>
  );
};

CreateVariant.propTypes = propTypesCreateVariant;
CreateVariant.defaultProps = defaultPropsCreateVariant;

export default CreateVariant;
