import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./ImageSuggestionsGallery.module.scss";
import Image from "../../ImageGallery/Image/Image";

const propTypesImageSuggestionsGallery = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      source: PropTypes.string,
      author: PropTypes.string,
      authorUrl: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    })
  ).isRequired,
  onImageSelected: PropTypes.func.isRequired,
};
const defaultPropsImageSuggestionsGallery = {};

const ImageSuggestionsGallery = ({ images, onImageSelected }) => {
  const { t } = useTranslation();

  const imagesContent = images.map((image) => (
    <Image
      key={image.id}
      imageResult={{
        url: image.url,
        previewUrl: image.url,
        author: image.author,
        authorUrl: image.authorUrl,
        width: image.width,
        height: image.height,
      }}
      source={image.source}
      horizontalScroll
      onImageSelected={onImageSelected}
    />
  ));

  return (
    <div className={`box ${styles.container}`}>
      <div className={styles.textContainer}>
        <span className={styles.text}>
          {t("wisdomtree.modal_choose_image.search.suggestions")}
        </span>
      </div>
      <div className={styles.innerContainer}>{imagesContent}</div>
    </div>
  );
};

ImageSuggestionsGallery.propTypes = propTypesImageSuggestionsGallery;
ImageSuggestionsGallery.defaultProps = defaultPropsImageSuggestionsGallery;

export default ImageSuggestionsGallery;
