import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isMobile as isMobileDevice } from "react-device-detect";

import styles from "./BlockCardsButton.module.scss";
import { TooltipDelayed } from "../../../../../../UI/Tooltip/Tooltip";
import { hasQuestGoal } from "../../../../../../Utils/QuestHelper";
import QuestIcon from "../../../../../../UI/QuestIcon/QuestIcon";

const propTypesBlockCardsButton = {
  block: PropTypes.shape({
    id: PropTypes.string,
    cardsAmount: PropTypes.number,
    cardsActiveAmount: PropTypes.number,
  }).isRequired,
  user: PropTypes.shape({
    currentQuest: PropTypes.object,
  }),
  isHovered: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  buttonHidden: PropTypes.bool,
  onManageCards: PropTypes.func.isRequired,
  onAddCard: PropTypes.func.isRequired,
};
const defaultPropsBlockCardsButton = {
  buttonHidden: false,
  user: null,
};

const BlockCardsButton = ({
  block,
  user,
  isHovered,
  isFocused,
  buttonHidden,
  onManageCards,
  onAddCard,
}) => {
  const { t } = useTranslation();

  const hasCards = block.cardsAmount > 0;
  const hasActiveCards = block.cardsActiveAmount > 0;

  let shouldHide = true;
  if (
    !buttonHidden &&
    (isMobileDevice || hasActiveCards || isHovered || isFocused)
  ) {
    shouldHide = false;
  }

  const tooltip = hasCards
    ? t("wisdomtree.entry_browser.tooltips.block_manage_learning_cards")
    : t("wisdomtree.entry_browser.tooltips.block_add_learning_card");

  const handleOnClick = () => {
    if (hasCards) {
      onManageCards(block.id);
    } else {
      onAddCard(block.id);
    }
  };

  const hasQuest = user ? hasQuestGoal(user, "cards") : false;

  return (
    <TooltipDelayed content={tooltip}>
      <button
        type="button"
        className={`button is-light ${styles.cardsButton} ${
          shouldHide ? styles.hidden : ""
        }`}
        onClick={() => handleOnClick()}
      >
        {block.cardsAmount === 0 ? (
          <span className={styles.plus}>+</span>
        ) : null}
        <span className={`icon ${styles.gray}`}>
          <i className="fas fa-brain" />
        </span>
        {hasQuest && block.cardsAmount === 0 ? <QuestIcon /> : null}
        {/* {block.cardsActiveAmount > 0 ? (
          <span>{block.cardsActiveAmount}</span>
        ) : null} */}
      </button>
    </TooltipDelayed>
  );
};

BlockCardsButton.propTypes = propTypesBlockCardsButton;
BlockCardsButton.defaultProps = defaultPropsBlockCardsButton;

export default BlockCardsButton;
