import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./ShareButton.module.scss";
import { TooltipDelayed } from "../../../UI/Tooltip/Tooltip";

const propTypesShareButton = {
  entryId: PropTypes.string,
  entryName: PropTypes.string,
  dataId: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};
const defaultPropsShareButton = {
  entryId: null,
  entryName: null,
  dataId: null,
  active: false,
  onClick: () => {},
};

const ShareButton = ({ entryId, entryName, dataId, active, onClick }) => {
  const { t } = useTranslation();

  const contentButton = (
    <button
      id="shareButton"
      type="button"
      data-id={dataId}
      className={`button is-light ${styles.button} ${
        active ? styles.active : null
      }`}
      onClick={() => {
        onClick();
      }}
    >
      <span className={`icon ${styles.icon}`}>
        <i className="fas fa-share-alt" />
      </span>
    </button>
  );

  return (
    <TooltipDelayed
      content={
        entryId
          ? t("wisdomtree.entry_browser.tooltips.share_button_entry", {
              entry: entryName,
            })
          : t("wisdomtree.entry_browser.tooltips.share_button_home")
      }
    >
      {contentButton}
    </TooltipDelayed>
  );
};

ShareButton.propTypes = propTypesShareButton;
ShareButton.defaultProps = defaultPropsShareButton;

export default ShareButton;
