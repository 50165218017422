import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";

import styles from "./UsersList.module.scss";
import { GET_ALL_USERS } from "../../../GraphQl/user";
import User from "./User/User";
import Spinner from "../../../UI/Spinner/Spinner";
import Title from "../../UI/Title/Title";

const propTypesUsersList = {};
const defaultPropsUsersList = {};

const UsersList = () => {
  const {
    loading: getUsersLoading,
    data: getUsersData,
    refetch: refetchUsers,
  } = useQuery(GET_ALL_USERS, {
    // pollInterval: 10000,
  });
  const users = getUsersData ? getUsersData.users : [];

  useEffect(() => {
    refetchUsers();
  }, []);

  let content = null;
  if (getUsersLoading) {
    content = <Spinner />;
  } else {
    content = users.map((user) => {
      return <User key={user.id} user={user} />;
    });
  }

  const userText = getUsersLoading ? `Users` : `Users (${users.length})`;

  return (
    <div className={styles.container}>
      <Title text={userText} icon="fa-users" />
      {content}
    </div>
  );
};

UsersList.propTypes = propTypesUsersList;
UsersList.defaultProps = defaultPropsUsersList;

export default UsersList;
