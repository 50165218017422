import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./JobToBeDone.module.scss";
import tutorialStyles from "../../TutorialModal.module.scss";
import { useTranslation } from "react-i18next";
import JobCard from "./JobCard/JobCard";

const propTypesJobToBeDone = {
  title: PropTypes.string.isRequired,
};
const defaultPropsJobToBeDone = {};

const JobToBeDone = ({ title, defaultJobToBeDone, onSelectJobToBeDone }) => {
  const { t } = useTranslation();
  const [selectedJobToBeDone, setSelectedJobToBedone] = useState(
    defaultJobToBeDone
  );

  const selectJob = (job) => {
    setSelectedJobToBedone(job);
    onSelectJobToBeDone(job);
  };

  return (
    <div className={styles.container}>
      <span className={`${styles.title}`}>{title}</span>
      <div className={styles.jobsContainer}>
        <JobCard
          title={t("wisdomtree.modal_welcome.jtbd_title_personal")}
          subtitle={t("wisdomtree.modal_welcome.jtbd_description_personal")}
          icon="fa-seedling"
          selected={selectedJobToBeDone === "PERSONAL"}
          onClick={() => selectJob("PERSONAL")}
        />
        <JobCard
          title={t("wisdomtree.modal_welcome.jtbd_title_school")}
          subtitle={t("wisdomtree.modal_welcome.jtbd_description_school")}
          icon="fa-graduation-cap"
          selected={selectedJobToBeDone === "SCHOOL"}
          onClick={() => selectJob("SCHOOL")}
        />
        <JobCard
          title={t("wisdomtree.modal_welcome.jtbd_title_work")}
          subtitle={t("wisdomtree.modal_welcome.jtbd_description_work")}
          icon="fa-briefcase"
          selected={selectedJobToBeDone === "WORK"}
          onClick={() => selectJob("WORK")}
        />
      </div>
    </div>
  );
};

JobToBeDone.propTypes = propTypesJobToBeDone;
JobToBeDone.defaultProps = defaultPropsJobToBeDone;

export default JobToBeDone;
