import React from "react";
import PropTypes from "prop-types";

import styles from "./SeparatorWithText.module.scss";

const propTypesSeparatorWithText = {
  text: PropTypes.string.isRequired,
};
const defaultPropsSeparatorWithText = {};

const SeparatorWithText = ({ text }) => {
  return <div className={styles.separator}>{text}</div>;
};

SeparatorWithText.propTypes = propTypesSeparatorWithText;
SeparatorWithText.defaultProps = defaultPropsSeparatorWithText;

export default SeparatorWithText;
