/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from "react";
import PropTypes from "prop-types";

import styles from "./SearchResultEntry.module.scss";

const propTypesSearchResultEntry = {
  entry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    category: PropTypes.shape({
      color: PropTypes.shape({
        id: PropTypes.string,
        hex: PropTypes.string,
      }),
    }),
    parentEntry: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  selected: PropTypes.bool,
  duplicate: PropTypes.bool,
  home: PropTypes.bool,
  onClick: PropTypes.func,
};
const defaultPropsSearchResultEntry = {
  selected: false,
  duplicate: false,
  home: false,
  onClick: () => {},
};

const SearchResultEntry = ({ entry, selected, duplicate, home, onClick }) => {
  let entryNameContent = <span className={styles.entryName}>{entry.name}</span>;
  if (!home && duplicate && entry.parentEntry)
    entryNameContent = (
      <>
        <span className={styles.parentEntryName}>{entry.parentEntry.name}</span>
        <span className={styles.nameSeperator}>›</span>
        <span className={styles.entryName}>{entry.name}</span>
      </>
    );

  let entryImage = null;
  if (entry.image && !home) {
    entryImage = (
      <img
        src={entry.image.url}
        className={styles.image}
        alt="entry"
        key={entry.id}
      />
    );
  } else if (home) {
    entryImage = (
      <span className={`icon ${styles.image} ${styles.emptyImage}`}>
        <i className="fas fa-1x fa-home" />
      </span>
    );
  } else {
    entryImage = (
      <span className={`icon ${styles.image} ${styles.emptyImage}`}>
        <i className="fas fa-1x fa-image" />
      </span>
    );
  }

  let entryCategoryIndicator = null;
  if (entry.category && entry.category.color) {
    entryCategoryIndicator = (
      <div
        className={styles.categoryIndicator}
        style={{ backgroundColor: entry.category.color.hex }}
      />
    );
  }

  return (
    <div
      className={`box ${styles.container} ${
        selected ? styles.selected : null
      } ${onClick != null ? styles.clickable : null}`}
      onClick={onClick}
      role="button"
      tabIndex={0}
      data-search-id={entry.id}
      data-search-type="entry"
    >
      {entryImage}
      {entryCategoryIndicator}
      <div className="content">
        <p className={styles.name}>{entryNameContent}</p>
      </div>
    </div>
  );
};

SearchResultEntry.propTypes = propTypesSearchResultEntry;
SearchResultEntry.defaultProps = defaultPropsSearchResultEntry;

export default SearchResultEntry;
