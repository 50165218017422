/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
import styles from "./DeleteTagModal.module.css";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onDeleteCard: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  nested: PropTypes.bool,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  loading: false,
  nested: false,
};

const useDeleteTagModal = ({
  onOpen,
  onClose,
  onDeleteTag,
  loading,
  nested,
}) => {
  const { isOpened, open, close } = useBaseModal({ onOpen, onClose });
  const [tag, setTag] = useState(null);

  let content = null;
  if (isOpened) {
    content = (
      <DeleteTagModal
        show={isOpened}
        onCloseHandler={close}
        tag={tag}
        onDeleteHandler={onDeleteTag}
        deleteLoading={loading}
        nested={nested}
      />
    );
  }

  const openModal = (tagToDelete) => {
    setTag(tagToDelete);
    open();
  };

  const closeModal = () => {
    close();
    setTag(null);
  };

  return { open: openModal, close: closeModal, content };
};

useDeleteTagModal.propTypes = propTypes;
useDeleteTagModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  tag: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }).isRequired,
  deleteLoading: PropTypes.bool.isRequired,
  onDeleteHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  nested: PropTypes.bool,
};

const defaultPropsModal = {
  nested: false,
};

const DeleteTagModal = ({
  show,
  nested,
  tag,
  deleteLoading,
  onDeleteHandler,
  onCloseHandler,
}) => {
  const { t } = useTranslation();
  const text = t("wisdomtree.modal_delete_tag.text", { tag_name: tag.name });

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!show) return;
      onDeleteHandler(tag);
    },
    dependsOn: [onDeleteHandler, show],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!show) return;
      onCloseHandler();
    },
    dependsOn: [onCloseHandler, show],
  });

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler} nested={nested}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_delete_tag.title")}
          </p>
          <ModalCloseButton onClick={onCloseHandler} disabled={deleteLoading} />
        </header>
        <section className="modal-card-body">
          <p>{text}</p>
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-danger ${deleteLoading ? `is-loading` : ``}`}
            type="submit"
            onClick={() => {
              onDeleteHandler(tag);
            }}
          >
            {t("wisdomtree.modal_delete_tag.delete")}
          </button>
          <button
            className="button"
            type="button"
            onClick={onCloseHandler}
            disabled={deleteLoading}
          >
            {t("wisdomtree.modal_delete_tag.cancel")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

DeleteTagModal.propTypes = propTypesModal;
DeleteTagModal.defaultProps = defaultPropsModal;

export default useDeleteTagModal;
