import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import {
  GET_DAILY_QUOTE,
  GET_DAILY_QUOTE_TODAY_UNSEEN,
} from "../GraphQl/dailyQuotes";
import useDailyQuoteModal from "../UI/Modal/DailyQuoteModal/DailyQuoteModal";
import Logger from "js-logger";

const propTypesuseDailyQuoteDisplay = {
  onDialogOpenedChanged: PropTypes.func.isRequired,
};
const defaultPropsuseDailyQuoteDisplay = {};

const useDailyQuoteDisplay = ({ onDialogOpenedChanged }) => {
  const location = useLocation();
  const history = useHistory();

  const isHome = location.pathname === "/home";
  const dailyQuoteId = queryString.parse(location.search).dailyQuote;

  const [finishedLoading, setFinishedLoading] = useState(
    !isHome && !dailyQuoteId ? true : false
  );
  const [lookedForUnseenQuote, setLookedForUnseenQuote] = useState(false);

  const [
    getDailyQuoteTodayUnseen,
    { data: getDailyQuoteUnseenData },
  ] = useLazyQuery(GET_DAILY_QUOTE_TODAY_UNSEEN, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setLookedForUnseenQuote(true);
      setFinishedLoading(true);
    },
    onError: (error) => {
      Logger.error(error);
      setFinishedLoading(true);
    },
  });

  const [getDailyQuote, { data: getDailyQuoteData }] = useLazyQuery(
    GET_DAILY_QUOTE,
    {
      fetchPolicy: "network-only",
      onCompleted: () => {
        setLookedForUnseenQuote(true);
        setFinishedLoading(true);
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete("dailyQuote");
        history.replace({
          search: queryParams.toString(),
        });
      },
      onError: (error) => {
        Logger.error(error);
        setFinishedLoading(true);
      },
    }
  );

  const dailyQuoteToday = getDailyQuoteUnseenData
    ? getDailyQuoteUnseenData.dailyQuoteTodayUnseen
    : null;

  const dailyQuoteThroughId = getDailyQuoteData
    ? getDailyQuoteData.dailyQuote
    : null;

  const {
    open: openDailyQuoteModal,
    content: dailyQuoteModalContent,
  } = useDailyQuoteModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => {
      onDialogOpenedChanged(false);
    },
  });

  useEffect(() => {
    if (isHome && !dailyQuoteId && !lookedForUnseenQuote)
      getDailyQuoteTodayUnseen();
  }, [isHome]);

  useEffect(() => {
    if (dailyQuoteId) getDailyQuote({ variables: { id: dailyQuoteId } });
  }, [dailyQuoteId]);

  useEffect(() => {
    if (dailyQuoteToday != null && !lookedForUnseenQuote) {
      openDailyQuoteModal(dailyQuoteToday);
    }
  }, [dailyQuoteToday]);

  useEffect(() => {
    if (dailyQuoteThroughId != null && !lookedForUnseenQuote) {
      openDailyQuoteModal(dailyQuoteThroughId);
    }
  }, [dailyQuoteThroughId]);

  let dailyQuoteContent = <>{dailyQuoteModalContent}</>;

  return { dailyQuoteContent, finished: finishedLoading };
};

useDailyQuoteDisplay.propTypes = propTypesuseDailyQuoteDisplay;
useDailyQuoteDisplay.defaultProps = defaultPropsuseDailyQuoteDisplay;

export default useDailyQuoteDisplay;
