import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import validator from "email-validator";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Logger from "js-logger";
import { Helmet } from "react-helmet";

import Input from "../../UI/Input/Input";
import ErrorMessages from "../../Utils/ErrorMessages";
import styles from "./ForgotPassword.module.scss";
import authStyles from "../Auth.module.scss";

const propTypes = {};

const defaultProps = {};

const REQUEST_RESET_PASSWORD = gql`
  mutation($input: RequestResetPasswordInput!) {
    requestResetPassword(input: $input) {
      code
      message
    }
  }
`;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [
    requestResetPassword,
    { loading: requestResetPasswordLoading },
  ] = useMutation(REQUEST_RESET_PASSWORD, {
    onError: (error) => {
      const errorCode = ErrorMessages.getErrorCode(error);

      if (errorCode === "EMAIL_DOES_NOT_EXIST") {
        setEmailError(errorCode);
        return;
      }

      setUnspecificError(errorCode);
    },
    onCompleted: () => {
      toast.info(t(`auth.forgot_password_email_sent`, { email }));
      history.push("/auth/login");
    },
  });

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [unspecificError, setUnspecificError] = useState(null);

  const validateEmail = (emailToValidate) => {
    if (!emailToValidate || emailToValidate.length === 0) {
      setEmailError("FORGOT_PASSWORD_EMAIL_MISSING");
      return false;
    }

    if (!validator.validate(emailToValidate)) {
      setEmailError("FORGOT_PASSWORD_EMAIL_INVALID");
      return false;
    }
    return true;
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(null);
    if (unspecificError === "EMAIL_DOES_NOT_EXIST") setUnspecificError(null);
  };

  const clearAllErrors = () => {
    setEmailError(null);
    setUnspecificError(null);
  };

  const handleRequestResetPassword = (event) => {
    event.preventDefault();

    clearAllErrors();

    const emailValid = validateEmail(email);

    if (emailValid) {
      executeRecaptcha("requestResetPassword")
        .then((token) => {
          requestResetPassword({
            variables: { input: { email, captchaToken: token } },
          });
        })
        .catch((error) => {
          Logger.error(error);
          toast.error(t(`error.CAPTCHA_FAILED`));
        });
    }
  };

  let errorContent = null;
  if (unspecificError) {
    const errorMessage = t(`error.${unspecificError}`);
    errorContent = <p className={styles.Error}>{errorMessage}</p>;
  }

  const requestResetPasswordForm = (
    <>
      <Helmet>
        <title>WisdomTree - {t(`auth.forgot_password_title`)}</title>
      </Helmet>
      <section className={styles.container}>
        <h1 className={authStyles.authTitle}>
          {t("auth.forgot_password_title")}
        </h1>
        <p className={styles.resetPasswordText}>
          {t("auth.forgot_password_text")}
        </p>
        <form onSubmit={handleRequestResetPassword}>
          <Input
            type="text"
            name="email"
            placeholder={t("auth.forgot_password_email_input")}
            autoComplete="username"
            spellCheck="false"
            iconleft="fas fa-envelope"
            help={emailError ? t(`error.${emailError}`) : null}
            isDanger={emailError !== null}
            value={email}
            onChange={handleEmailChange}
          />

          <div className="field">
            <div className="control text-align-center">
              <button
                className={`button is-primary ${styles.button} ${
                  requestResetPasswordLoading ? "is-loading" : ""
                }`}
                type="submit"
                disabled={emailError}
              >
                {t("auth.forgot_password_button")}
              </button>
            </div>
          </div>
          {errorContent}
        </form>
      </section>
      <section className={styles.loginContainer}>
        <hr />
        <p className={styles.moreOptions}>
          <Trans i18nKey="auth.forgot_password_back_text">
            Go back to the
            <Link to={`/auth/login${location.search}`}>login screen</Link>
          </Trans>
        </p>
        <p className={styles.moreOptions}>
          <Trans i18nKey="auth.forgot_password_help_text">
            Need help? Contact
            <a href="mailto:simon@wisdomtree.app">simon@wisdomtree.app</a>
          </Trans>
        </p>
      </section>
    </>
  );

  return <div>{requestResetPasswordForm}</div>;
};

ForgotPassword.propTypes = propTypes;
ForgotPassword.defaultProps = defaultProps;

export default ForgotPassword;
