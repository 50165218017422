import { gql } from "@apollo/client";

import { FRAGMENT_BASIC_ENTRY } from "./fragments";

const GET_BOOKMARKED_ENTRIES = gql`
  query {
    bookmarks {
      ...BasicEntry
    }
  }
  ${FRAGMENT_BASIC_ENTRY}
`;

const GET_RECENT_ENTRIES = gql`
  query {
    recents {
      ...BasicEntry
    }
  }
  ${FRAGMENT_BASIC_ENTRY}
`;

export { GET_BOOKMARKED_ENTRIES, GET_RECENT_ENTRIES };
