import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "../../MenuBar.module.scss";

const propTypes = {
  selectedEntryId: PropTypes.string,
  newEntryDraft: PropTypes.shape({
    name: PropTypes.string,
  }),
  newEntryLoading: PropTypes.bool,
  onCreateEntry: PropTypes.func.isRequired,
};

const defaultProps = {
  selectedEntryId: null,
  newEntryDraft: null,
  newEntryLoading: false,
};

const MenuBarNewEntry = ({
  selectedEntryId,
  newEntryDraft,
  newEntryLoading,
  onCreateEntry,
}) => {
  const { t } = useTranslation();

  let contentCancelButton = null;
  let contentCreateButton = null;

  const cancelTo =
    selectedEntryId !== null ? `/entry/${selectedEntryId}` : "/home";

  contentCancelButton = (
    <div className={styles.menuBarItem}>
      <Link
        className="button is-light"
        to={cancelTo}
        disabled={newEntryLoading}
      >
        <span className={`icon ${styles.iconGray}`}>
          <i className="fas fa-times" />
        </span>
        <span>{t("wisdomtree.entry_browser.new_entry_cancel")}</span>
      </Link>
    </div>
  );

  const createButtonClasses = `button is-primary ${
    newEntryLoading ? "is-loading" : ""
  }`;

  contentCreateButton = (
    <div className={styles.menuBarItem}>
      <button
        data-id="button.create-entry"
        onClick={() => onCreateEntry()}
        disabled={
          !newEntryDraft ||
          !newEntryDraft.name ||
          newEntryDraft.name.length === 0
        }
        className={createButtonClasses}
        type="submit"
      >
        <span className="icon">
          <i className="fas fa-check" />
        </span>
        <span>{t("wisdomtree.entry_browser.new_entry_save")}</span>
      </button>
    </div>
  );

  return (
    <div className={styles.menuBar}>
      <div className={styles.menuBarInnerContainer}>
        <div className={styles.menuBarLeft}>{contentCancelButton}</div>
        <div className={styles.menuBarRight}>{contentCreateButton}</div>
      </div>
    </div>
  );
};

MenuBarNewEntry.propTypes = propTypes;
MenuBarNewEntry.defaultProps = defaultProps;

export default MenuBarNewEntry;
