import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import { useLazyQuery, useQuery } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
import styles from "./MoveBlockToEntryModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import SearchInput from "../../SearchInput/SearchInput";
import {
  SEARCH_ENTRIES,
  SEARCH_MOVE_BLOCK_SUGGESTIONS,
} from "../../../GraphQl/search";
import Spinner from "../../Spinner/Spinner";
import SearchResultEntry from "../../SearchResultEntry/SearchResultEntry";
import { useMobileOrTablet } from "../../../Utils/Responsive";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import SearchWithSuggestions from "../../SearchWithSuggestions/SearchWithSuggestions";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onMoveBlock: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  nested: PropTypes.bool,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  loading: false,
  nested: false,
};

const useMoveBlockToEntryModal = ({
  onOpen,
  onClose,
  onMoveBlock,
  loading,
  nested,
}) => {
  const { isOpened, open, close } = useBaseModal({ onOpen, onClose });
  const [block, setBlock] = useState(null);

  let content = null;
  if (isOpened) {
    content = (
      <MoveBlockToEntryModal
        show={isOpened}
        onCloseHandler={close}
        block={block}
        onMoveBlockHandler={onMoveBlock}
        moveBlockLoading={loading}
        nested={nested}
      />
    );
  }

  const openModal = (blockToMove) => {
    setBlock(blockToMove);
    open();
  };

  const closeModal = () => {
    close();
    setBlock(null);
  };

  return { open: openModal, close: closeModal, content };
};

useMoveBlockToEntryModal.propTypes = propTypes;
useMoveBlockToEntryModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  block: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    entryId: PropTypes.string,
  }).isRequired,
  moveBlockLoading: PropTypes.bool.isRequired,
  onMoveBlockHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
};

const MoveBlockToEntryModal = ({
  show,
  block,
  moveBlockLoading,
  onMoveBlockHandler,
  onCloseHandler,
}) => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState(null);

  const {
    loading: searchSuggestionsLoading,
    data: searchSuggestionsData,
  } = useQuery(SEARCH_MOVE_BLOCK_SUGGESTIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      blockId: block.id,
    },
    onError: (err) => {
      Logger.error(err);
    },
  });
  const searchSuggestionEntries = searchSuggestionsData
    ? searchSuggestionsData.searchSuggestionsMoveBlock.map((entry) => {
        return { entry };
      })
    : null;

  const [search, { loading: searchLoading, data: searchData }] = useLazyQuery(
    SEARCH_ENTRIES,
    {
      fetchPolicy: "cache-and-network",
      onError: (err) => {
        Logger.error(err);
      },
    }
  );
  let searchResults = searchData ? searchData.search.results : null;
  if (searchResults) {
    searchResults = searchResults.filter(
      (result) => result.entry.id !== block.entryId
    );
  }

  const content = (
    <SearchWithSuggestions
      results={searchResults}
      suggestions={searchSuggestionEntries}
      resultsLoading={searchLoading}
      suggestionsLoading={searchSuggestionsLoading}
      onSearch={(text) => {
        search({
          variables: {
            input: {
              query: text.toLowerCase(),
              searchEntries: true,
              searchBlocks: false,
            },
          },
          context: {
            debounceKey: `search`,
            debounceTimeout: 200,
          },
        });
      }}
      onSelect={(selection) => {
        setSelection(selection);
      }}
      onSubmit={(selection) => {
        onMoveBlockHandler(block, selection.entity.id);
      }}
      onCancel={() => {
        onCloseHandler();
      }}
      searchPlaceholder={t("wisdomtree.modal_move_block.search_placeholder")}
      noResultsMessage={t("wisdomtree.modal_move_block.search_no_results")}
    />
  );

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_move_block.title")}
          </p>
          <ModalCloseButton
            onClick={onCloseHandler}
            disabled={moveBlockLoading}
          />
        </header>
        <section className={`modal-card-body ${styles.body}`}>
          {content}
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-primary ${
              moveBlockLoading ? `is-loading` : ``
            }`}
            type="submit"
            disabled={selection == null}
            onClick={() => {
              onMoveBlockHandler(block, selection.entity.id);
            }}
          >
            {t("wisdomtree.modal_move_block.move")}
          </button>
          <button
            className="button"
            type="button"
            onClick={onCloseHandler}
            disabled={moveBlockLoading}
          >
            {t("wisdomtree.modal_move_block.cancel")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

MoveBlockToEntryModal.propTypes = propTypesModal;

export default useMoveBlockToEntryModal;
