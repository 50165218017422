import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./NotificationSubscription.module.scss";
import {
  browserSupportsPush,
  getPushSubscription,
} from "../../Utils/PushNotifications";
import {
  ADD_PUSH_SUBSCRIPTION,
  mergeUserAfterAddPushSubscription,
} from "../../GraphQl/user";
import { useMutation, useReactiveVar } from "@apollo/client";
import Logger from "js-logger";
import {
  isMobileApp,
  requestNotificationPermission,
} from "../../Utils/ReactNativeMessageUtils";
import useReactNativeEvents from "../../Utils/ReactNativeEventListener";
import { mobileNotificationPermissionStatus } from "../../../config/apollo-cache";

const propTypesNotificationSubscription = {
  text: PropTypes.string.isRequired,
  onButtonClicked: PropTypes.func,
  onPermissionChanged: PropTypes.func,
  onPermissionHandled: PropTypes.func,
};
const defaultPropsNotificationSubscription = {
  onButtonClicked: () => {},
  onPermissionChanged: () => {},
  onPermissionHandled: () => {},
};

const NotificationSubscription = ({
  text,
  onButtonClicked,
  onPermissionChanged,
  onPermissionHandled,
}) => {
  const [swRegistration, setSwRegistration] = useState(null);
  const [existingSubscription, setExistingSubscription] = useState(null);
  const mobileNotificationStatus = useReactiveVar(
    mobileNotificationPermissionStatus
  );

  const [
    addPushSubscription,
    { loading: addPushSubscriptionLoading },
  ] = useMutation(ADD_PUSH_SUBSCRIPTION, {
    update: mergeUserAfterAddPushSubscription,
    onError: (err) => {
      Logger.error(err);
    },
    onCompleted: () => {},
  });

  const handlePermissionChanged = (newStatus) => {
    onPermissionChanged(newStatus);
    if (
      (isMobileApp() && newStatus !== "undetermined") ||
      (!isMobileApp() && newStatus !== "default")
    )
      onPermissionHandled();
  };

  useReactNativeEvents({
    handleEvent: (event) => {
      if (event.type === "notificationPermissionsUpdated") {
        handlePermissionChanged(event.status);
        return;
      }
    },
  });

  useEffect(() => {
    if (!browserSupportsPush()) {
      return;
    }

    if (Notification.permission === "denied") {
      console.log("The user has blocked notifications.");
      return;
    }

    navigator.serviceWorker.ready.then(async (registration) => {
      setSwRegistration(registration);

      if (Notification.permission === "granted") {
        const existingSubscription = await registration.pushManager.getSubscription();
        setExistingSubscription(existingSubscription);
      }
    });
  }, []);

  const registerForPush = async (pushManager) => {
    try {
      const subscription = await getPushSubscription(pushManager);

      addPushSubscription({
        variables: {
          input: {
            subscription: subscription,
            deviceType: "WEB",
          },
        },
      });
      handlePermissionChanged(Notification.permission);
    } catch (error) {
      // handle brave case
      console.error(error);
      handlePermissionChanged(Notification.permission);
    }
  };

  let content = <div></div>;
  if (!isMobileApp() && swRegistration && !existingSubscription) {
    content = (
      <div>
        <button
          className={`button`}
          onClick={() => {
            registerForPush(swRegistration.pushManager);
            onButtonClicked();
          }}
        >
          <span className={`icon ${styles.icon}`}>
            <i className="fas fa-bell"></i>
          </span>
          <span>{text}</span>
        </button>
      </div>
    );
  } else if (isMobileApp() && mobileNotificationStatus === "undetermined") {
    content = (
      <div>
        <button
          className={`button`}
          data-id="button.enable-notifications"
          onClick={() => {
            requestNotificationPermission();
            onButtonClicked();
          }}
        >
          <span className={`icon ${styles.icon}`}>
            <i className="fas fa-bell"></i>
          </span>
          <span>{text}</span>
        </button>
      </div>
    );
  }

  return content;
};

NotificationSubscription.propTypes = propTypesNotificationSubscription;
NotificationSubscription.defaultProps = defaultPropsNotificationSubscription;

export default NotificationSubscription;
