/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import ReactSortable from "react-sortablejs";
import arrayMove from "array-move";
import Logger from "js-logger";
import shortid from "shortid";
import { scroller } from "react-scroll";
import { throttle } from "lodash";

import styles from "./SelectedEntryContent.module.css";
import stylesContentBlock from "./ContentBlock/ContentBlock.module.scss";
import {
  CREATE_BLOCK,
  UPDATE_BLOCK,
  DELETE_BLOCK,
  mergeBlocksAfterUpdate,
  mergeBlocksAfterCreate,
  mergeBlocksAfterDelete,
  getOptimisticResponseForMoveBlock,
} from "../../../../GraphQl/blocks";
import ContentBlock from "./ContentBlock/ContentBlock";
import Spinner from "../../../../UI/Spinner/Spinner";
import AddBlockDropdown from "./AddBlockDropdown/AddBlockDropdown";
import useManageCardsModal from "../../ManageCardsModal/ManageCardsModal";
import useChooseImageModal from "../../../../UI/Modal/ChooseImageModal/ChooseImageModal";
import useDeleteBlockModal from "../../../../UI/Modal/DeleteBlockModal/DeleteBlockModal";
import useMoveBlockToEntryModal from "../../../../UI/Modal/MoveBlockToEntryModal/MoveBlockToEntryModal";
import useAddCardModal from "../../AddCardModal/AddCardModal";
import useAddReferenceModal from "../../../../UI/Modal/AddReferenceModal/AddReferenceModal";
import usEditBlockWebModal from "../../../../UI/Modal/EditBlockWebModal/EditBlockWebModal";
import { setImageUpdatingForEntity } from "../../../../Utils/ApolloUtils";

const propTypes = {
  editMode: PropTypes.bool,
  sharingId: PropTypes.string,
  entry: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    currentQuest: PropTypes.object,
  }),
  selectedEntryDetails: PropTypes.object,
  selectedEntryDetailsLoading: PropTypes.bool,
  onDialogOpenedChanged: PropTypes.func.isRequired,
};

const defaultProps = {
  editMode: false,
  sharingId: null,
  user: null,
  selectedEntryDetails: null,
  selectedEntryDetailsLoading: false,
};

const SelectedEntryContent = ({
  editMode,
  sharingId,
  entry,
  user,
  selectedEntryDetails,
  selectedEntryDetailsLoading,
  onDialogOpenedChanged,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [isDragging, setIsDragging] = useState(false);
  const [blockRefs] = useState(new Map());
  const entryRef = useRef(entry);
  const [blockIdsDeleteLoading] = useState([]);
  const [blocksAmountToShow, setBlocksAmountToShow] = useState(3);
  const containerRef = useRef(null);
  const [scrollY, setScrollY] = useState(window.scrollY + window.innerHeight);
  const [delayPassed, setDelayPassed] = useState(false);

  useEffect(() => {
    entryRef.current = entry;
  }, [entry]);

  const entryId = entry.id;
  useEffect(() => {
    setBlocksAmountToShow(3);
    setDelayPassed(false);
    setTimeout(() => {
      setDelayPassed(true);
    }, 1000);
  }, [entryId]);

  const allBlocks = selectedEntryDetails ? selectedEntryDetails.blocks : [];
  const blocks = allBlocks.slice(
    0,
    allBlocks.length < blocksAmountToShow
      ? allBlocks.length
      : blocksAmountToShow
  );
  const hasMore = blocks.length < allBlocks.length;

  const handleWindowScroll = () => {
    setScrollY(window.scrollY + window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("scroll", throttle(handleWindowScroll, 500));
    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);

  useEffect(() => {
    if (
      !delayPassed ||
      !selectedEntryDetails ||
      blocksAmountToShow >= allBlocks.length
    )
      return;

    const container = containerRef.current;
    if (!container) return;

    const containerY = container.offsetTop + container.clientHeight;

    if (containerY - scrollY < window.innerHeight / 2.5) {
      setBlocksAmountToShow(blocksAmountToShow + 3);
    }
  }, [
    scrollY,
    allBlocks,
    blocksAmountToShow,
    selectedEntryDetails,
    delayPassed,
  ]);

  useEffect(() => {
    const blockId = queryString.parse(location.search).block;
    if (blockId != null && !selectedEntryDetailsLoading && blocks.length > 0) {
      const block = blocks.find((b) => b.id === blockId);
      if (block == null) {
        const blockIndexInAllBlocks = allBlocks.findIndex(
          (b) => b.id === blockId
        );
        if (blockIndexInAllBlocks < 0) return;

        setBlocksAmountToShow(
          Math.min(blockIndexInAllBlocks + 3, allBlocks.length)
        );
        return;
      }

      const targetId = `block_${blockId}`;

      const queryParams = new URLSearchParams(location.search);
      queryParams.delete("block");
      history.replace({
        search: queryParams.toString(),
      });

      setTimeout(() => {
        scroller.scrollTo(targetId, {
          duration: 400,
          offset: -180,
          smooth: true,
        });
      }, 600);
    }
  }, [selectedEntryDetailsLoading, allBlocks, blocks, location, entry.id]);

  const [updateBlock] = useMutation(UPDATE_BLOCK, {
    update: (cache, mutationResult) => {
      mergeBlocksAfterUpdate(entry.id, cache, mutationResult);
    },
    onCompleted: () => {},
  });

  const [updateBlockEntry, { loading: updateBlockEntryLoading }] = useMutation(
    UPDATE_BLOCK,
    {
      update: (cache, mutationResult) => {
        mergeBlocksAfterUpdate(entry.id, cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
    }
  );

  const onCreateBlockCompleted = (data) => {
    const { block } = data.createBlock;

    if (block.type === "IMAGE") {
      openChooseImageModal({
        entityToChooseImageFor: block,
        settingForceSquare: false,
      });
    } else if (block.type === "WEB") {
      openEditBlockWebModal(block);
    }

    if (block.type !== "IMAGE" && block.type !== "WEB") {
      const refToBlock = blockRefs.get(block.id);
      if (refToBlock) refToBlock.focus();
    }
  };
  const [createBlock, { loading: createBlockLoading }] = useMutation(
    CREATE_BLOCK,
    {
      update: (cache, mutationResult) => {
        mergeBlocksAfterCreate(entry.id, cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: onCreateBlockCompleted,
    }
  );

  const [
    createBlockInBetween,
    { loading: createBlockInBetweenLoading },
  ] = useMutation(CREATE_BLOCK, {
    update: (cache, mutationResult) => {
      mergeBlocksAfterCreate(entry.id, cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
    onCompleted: onCreateBlockCompleted,
  });

  const [deleteBlock, { loading: deleteBlockLoading }] = useMutation(
    DELETE_BLOCK,
    {
      update: (cache, mutationResult) => {
        mergeBlocksAfterDelete(entry.id, cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
        closeDeleteBlockModal();
      },
      onCompleted: () => {
        closeDeleteBlockModal();
      },
    }
  );

  const updateBlockPosition = (order, oldIndex, newIndex) => {
    const optimisticUpdate = JSON.parse(JSON.stringify(blocks));
    for (let i = 0; i < order.length; i += 1) {
      const block = optimisticUpdate.find((e) => e.id === order[i]);
      block.position = i;
    }

    updateBlock({
      variables: {
        id: blocks[oldIndex].id,
        input: {
          position: newIndex,
        },
      },
      context: {
        debounceKey: `${blocks[oldIndex].id}_pos`,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateBlock: {
          __typename: "UpdateBlockResponse",
          code: 200,
          success: true,
          message: "block updated",
          updatedBlocks: optimisticUpdate,
          updatedEntries: [],
          updatedUser: [],
          createdTags: [],
        },
      },
    });
  };

  const [sortableInstance, setSortableInstance] = useState(null);
  const moveBlock = (blockId, targetPosition) => {
    let order = sortableInstance.toArray();
    const blockIndex = order.findIndex((b) => b === blockId);
    updateBlockPosition(order, blockIndex, targetPosition);
    order = arrayMove(order, blockIndex, targetPosition);
    sortableInstance.sort(order);
  };

  const onInitiateDeleteBlock = (block) => {
    if (
      ((block.type === "NOTE" || block.type === "QUOTE") &&
        !block.title &&
        !block.content &&
        !block.image) ||
      (block.type === "IMAGE" && !block.title && !block.image) ||
      (block.type === "WEB" && !block.webLink)
    ) {
      onConfirmDeleteBlock(block);
    } else {
      openDeleteBlockModal(block);
    }
  };

  const onConfirmDeleteBlock = (block) => {
    blockIdsDeleteLoading.push(block.id);
    deleteBlock({
      variables: { id: block.id },
    });
  };

  const removeBlockImage = (block) => {
    const optimisticResponse = {
      __typename: "Mutation",
      updateBlock: {
        __typename: "UpdateBlockResponse",
        code: 200,
        success: true,
        message: "block updated",
        updatedBlocks: [{ ...block, image: null }],
        updatedEntries: [],
        updatedUser: [],
        createdTags: [],
      },
    };
    updateBlock({
      variables: {
        id: block.id,
        input: {
          imageInput: null,
        },
      },
      optimisticResponse,
    });
  };

  const onRemoveBlockImage = (block) => {
    removeBlockImage(block);
  };

  const {
    open: openChooseImageModal,
    content: chooseImageModalContent,
  } = useChooseImageModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
    onImageChosen: (block, imageInput) => {
      if (imageInput == null) {
        removeBlockImage(block);
      } else {
        setImageUpdatingForEntity({
          typename: "Block",
          id: block.id,
          isUpdating: true,
        });
        updateBlock({
          variables: {
            id: block.id,
            input: {
              imageInput: { ...imageInput },
            },
          },
          update: (cache, mutationResult) => {
            mergeBlocksAfterUpdate(entry.id, cache, mutationResult);
            setImageUpdatingForEntity({
              typename: "Block",
              id: block.id,
              isUpdating: false,
            });
          },
        }).catch((error) => {
          Logger.error(error);
          setImageUpdatingForEntity({
            typename: "Block",
            id: block.id,
            isUpdating: false,
          });
        });
      }
    },
  });

  const {
    open: openDeleteBlockModal,
    close: closeDeleteBlockModal,
    content: deleteBlockModalContent,
  } = useDeleteBlockModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
    onDeleteBlock: onConfirmDeleteBlock,
    loading: deleteBlockLoading,
    entry,
  });

  const {
    open: openMoveBlockModal,
    close: closeMoveBlockModal,
    content: moveBlockModalContent,
  } = useMoveBlockToEntryModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
    onMoveBlock: (block, entryId) => {
      const debounceKey = `${block.id}${entryId}`;
      updateBlockEntry({
        variables: {
          id: block.id,
          input: {
            entryId,
          },
        },
        context: {
          debounceKey,
        },
        optimisticResponse: getOptimisticResponseForMoveBlock(
          block.id,
          entryId
        ),
      });
      closeMoveBlockModal();
    },
    loading: updateBlockEntryLoading,
    entry,
  });

  const {
    open: openManageCardsModal,
    content: manageCardsModalContent,
  } = useManageCardsModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
  });

  const {
    open: openAddCardModal,
    content: addCardModalContent,
  } = useAddCardModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => {
      onDialogOpenedChanged(false);
    },
    onOpenManageCards: (blockId) => {
      openManageCardsModal(blockId);
    },
  });

  const {
    open: openAddReferenceModal,
    content: addReferenceModalContent,
  } = useAddReferenceModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => {
      onDialogOpenedChanged(false);
    },
  });

  const {
    open: openEditBlockWebModal,
    content: editBlockWebModalContent,
  } = usEditBlockWebModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => {
      onDialogOpenedChanged(false);
    },
  });

  let content = null;

  if (selectedEntryDetailsLoading) {
    content = (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  } else {
    const contentBlocks = blocks.map((block) => {
      return (
        <ContentBlock
          key={block.id}
          ref={(instance) => {
            if (instance) blockRefs.set(block.id, instance);
          }}
          block={block}
          user={user}
          deleteLoading={
            blockIdsDeleteLoading.find((id) => id === block.id) != null
          }
          createBlockLoading={createBlockInBetweenLoading}
          editMode={editMode}
          sharingId={sharingId}
          isDragging={isDragging}
          listSize={blocks.length}
          onUpdateBlock={(updatedBlock) => {
            let debounceNotifyLatestOnly;
            let debounceKey = updatedBlock.id;
            if ("title" in updatedBlock) debounceKey += "title";
            if ("content" in updatedBlock) {
              debounceKey += "content";
              debounceNotifyLatestOnly = true; // necessary to avoid getting an update for each character typed!
            }
            if ("source" in updatedBlock) debounceKey += "source";
            if ("position" in updatedBlock) debounceKey += "position";
            if ("tags" in updatedBlock) debounceKey += "tags";

            let optimisticResponse = null;
            if (updatedBlock.tags) {
              const optimisticUpdatedBlock = {
                ...block,
                __typename: "Block",
              };

              optimisticUpdatedBlock.tags = updatedBlock.tags.map(
                (tagString) => {
                  const existingTag = block.tags.find(
                    (tag) => tag.name === tagString
                  );

                  return {
                    id: existingTag ? existingTag.id : shortid.generate(),
                    name: tagString,
                    color: existingTag ? existingTag.color : null,
                    __typename: "Tag",
                  };
                }
              );

              optimisticResponse = {
                __typename: "Mutation",
                updateBlock: {
                  __typename: "UpdateBlockResponse",
                  code: 200,
                  success: true,
                  message: "block updated",
                  updatedBlocks: [optimisticUpdatedBlock],
                  updatedEntries: [],
                  updatedUser: [],
                  createdTags: [],
                  expEvent: null,
                },
              };
            }

            updateBlock({
              variables: {
                id: updatedBlock.id,
                input: {
                  title: updatedBlock.title,
                  content: updatedBlock.content,
                  source: updatedBlock.source,
                  position: updatedBlock.position,
                  tags: updatedBlock.tags,
                },
              },
              context: {
                debounceKey,
                debounceNotifyLatestOnly,
              },
              optimisticResponse,
            });
          }}
          onCreateBlock={(blockType, position) => {
            createBlockInBetween({
              variables: {
                input: {
                  entryId: entry.id,
                  type: blockType,
                  position: position,
                },
              },
            });
          }}
          onMoveToEntry={() => {
            // small timeout to solve autofocus in modal not working
            setTimeout(() => {
              openMoveBlockModal(block);
            }, 50);
          }}
          onMoveToTop={(blockId) => moveBlock(blockId, 0)}
          onMoveToBottom={(blockId) => moveBlock(blockId, blocks.length - 1)}
          onRemoveBockTitle={(blockToRemoveTitle) => {
            updateBlock({
              variables: {
                id: blockToRemoveTitle.id,
                input: {
                  title: null,
                },
              },
            });
          }}
          onRemoveBockText={(blockToRemoveText) => {
            updateBlock({
              variables: {
                id: blockToRemoveText.id,
                input: {
                  content: null,
                },
              },
            });
          }}
          onManageCards={(blockId) => {
            openManageCardsModal(blockId);
          }}
          onAddCard={(blockId) => {
            openAddCardModal(blockId);
          }}
          onAddReference={() => {
            openAddReferenceModal(block.id, "block");
          }}
          onDeleteBlock={() => onInitiateDeleteBlock(block)}
          onAddImage={(droppedContent) => {
            // small timeout to solve autofocus in modal not working
            setTimeout(() => {
              openChooseImageModal({
                entityToChooseImageFor: block,
                settingForceSquare: block.type !== "IMAGE",
                existingImageOfEntity: block.image,
                entityName: block.title || null,
                entityType: "block",
                entityId: block.id,
                editMode: editMode,
                droppedContent: droppedContent,
              });
            }, 50);
          }}
          onEditWebLink={() => {
            // small timeout to solve autofocus in modal not working
            setTimeout(() => {
              openEditBlockWebModal(block);
            }, 50);
          }}
          onRemoveImage={() => onRemoveBlockImage(block)}
          onToggleDailyQuote={(dailyQuoteEnabled) => {
            const optimisticResponse = {
              __typename: "Mutation",
              updateBlock: {
                __typename: "UpdateBlockResponse",
                code: 200,
                success: true,
                message: "block updated",
                updatedBlocks: [{ ...block, dailyQuote: dailyQuoteEnabled }],
                updatedEntries: [],
                updatedUser: [],
                createdTags: [],
              },
            };
            updateBlock({
              variables: {
                id: block.id,
                input: {
                  dailyQuote: dailyQuoteEnabled,
                },
              },
              context: {
                debounceKey: `${block.id}_dailyQuote`,
              },
              optimisticResponse,
            });
          }}
          onDialogOpenedChanged={onDialogOpenedChanged}
        />
      );
    });

    let contentAddButton = null;
    if (editMode && !hasMore) {
      contentAddButton = (
        <div className={styles.addBlockButtonContainer}>
          <AddBlockDropdown
            onCreateBlock={(blockType) => {
              createBlock({
                variables: {
                  input: {
                    entryId: entry.id,
                    type: blockType,
                  },
                },
              });
            }}
            isLoading={createBlockLoading}
            user={user}
          />
        </div>
      );
    }

    content = (
      <>
        <ReactSortable
          ref={(c) => {
            if (c) {
              setSortableInstance(c.sortable);
            }
          }}
          options={{
            disabled: !editMode,
            handle: `.${stylesContentBlock.dragHandle}`,
            animation: 250,
            easing: "cubic-bezier(1, 0, 0, 1)",
            scroll: true,
            scrollSensitivity: 60,
            delay: 400,
            delayOnTouchOnly: true,
            setData: (dataTransfer, dragEl) => {
              dataTransfer.setData("blockid", dragEl.getAttribute("data-id"));
              dataTransfer.setData("entryid", entryRef.current.id);
              // eslint-disable-next-line no-param-reassign
              dataTransfer.effectAllowed = "move";
            },
            onStart: () => setIsDragging(true),
            onEnd: () => setIsDragging(false),
          }}
          onChange={(order, sortable, evt) => {
            updateBlockPosition(order, evt.oldIndex, evt.newIndex);
          }}
        >
          {contentBlocks}
        </ReactSortable>

        {contentAddButton}

        {manageCardsModalContent}
        {addCardModalContent}
        {moveBlockModalContent}
        {deleteBlockModalContent}
        {chooseImageModalContent}
        {addReferenceModalContent}
        {editBlockWebModalContent}
      </>
    );
  }

  let contentHasMoreIndicator = null;
  if (hasMore) {
    const extraPadding = Math.min(
      (allBlocks.length - blocksAmountToShow) * 100,
      window.innerHeight / 2
    );
    contentHasMoreIndicator = (
      <div
        className={styles.hasMoreContainer}
        style={{ paddingBottom: extraPadding }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div id={styles.container} ref={containerRef}>
        {content}
      </div>
      {contentHasMoreIndicator}
    </>
  );
};

SelectedEntryContent.propTypes = propTypes;
SelectedEntryContent.defaultProps = defaultProps;

export default SelectedEntryContent;
