/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./UserProfile.module.scss";
import Spinner from "../../../../UI/Spinner/Spinner";
import ClickableImage from "../../../../UI/ClickableImage/ClickableImage";
import QuizButton from "../../QuizButton/QuizButton";
import useUserProfileModal from "../../../../UI/Modal/UserProfileModal/UserProfileModal";
import Level from "./Level/Level";
import { TooltipDelayed } from "../../../../UI/Tooltip/Tooltip";
import ShareButton from "../../ShareButton/ShareButton";
import useShareModal from "../../../../UI/Modal/ShareModal/ShareModal";
import { useMobile, useMobileSmall } from "../../../../Utils/Responsive";

const propTypesUserProfile = {
  user: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    isImageUpdating: PropTypes.bool,
    cardsActiveAmount: PropTypes.number,
    cardsDueAmount: PropTypes.number,
    experienceAmount: PropTypes.number,
    level: PropTypes.number,
    levelProgress: PropTypes.number,
  }),
  sharingId: PropTypes.string,
  onDialogOpenedChanged: PropTypes.func,
};
const defaultPropsUserProfile = {
  user: null,
  onDialogOpenedChanged: () => {},
  isImageUpdating: false,
  sharingId: null,
};

const UserProfile = ({ user, sharingId, onDialogOpenedChanged }) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const isMobileSmall = useMobileSmall();

  const {
    open: openUserProfileModal,
    content: userProfileModalContent,
  } = useUserProfileModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
  });

  const { open: openShareModal, content: shareModalContent } = useShareModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
  });

  let contentProfileBox = null;
  if (user === null) {
    contentProfileBox = <Spinner />;
  } else {
    const contentUserImage = (
      <ClickableImage
        editMode={false}
        imageUrl={user.image ? user.image.url : null}
        isLoading={user.isImageUpdating}
        emptyIcon="fa-user"
        height={64}
        width={64}
        altText="user"
        round
        onImageClicked={() => {}}
        preview={false}
        dropAllowed={false}
      />
    );

    let contentQuizButton = null;
    let contentShareButton = null;

    if (!sharingId) {
      if (user.cardsActiveAmount > 0) {
        contentQuizButton = (
          <QuizButton
            cardsActiveAmount={user.cardsActiveAmount}
            cardsDueAmount={user.cardsDueAmount}
            dataId="home.quiz"
            noText={isMobileSmall}
          />
        );
      }

      if (user.size > 0) {
        contentShareButton = (
          <ShareButton
            dataId="home.share"
            onClick={() => {
              openShareModal();
            }}
            active={user.shared}
          />
        );
      }
    }

    contentProfileBox = (
      <div className={`box ${styles.profileBoxContainer}`}>
        <div className={`${styles.profileContainer}`}>
          <TooltipDelayed
            content={
              sharingId
                ? t("wisdomtree.entry_browser.tooltips.view_other_user_profile")
                : t("wisdomtree.entry_browser.tooltips.view_user_profile")
            }
          >
            <div
              className={styles.profileInnerContainer}
              onClick={() => openUserProfileModal(sharingId ? user.id : null)}
              tabIndex="0"
              role="button"
            >
              {contentUserImage}
              <div className={styles.profileInfoContainer}>
                <span className={`${styles.username}`}>{user.name}</span>
                <div>
                  <Level level={user.level} noTitle={isMobile} />
                </div>
              </div>
            </div>
          </TooltipDelayed>
        </div>

        <div className={styles.quizButtonContainer}>{contentQuizButton}</div>
        <div className={styles.shareButtonContainer}>{contentShareButton}</div>
      </div>
    );
  }

  return (
    <>
      {contentProfileBox}
      {userProfileModalContent}
      {shareModalContent}
    </>
  );
};

UserProfile.propTypes = propTypesUserProfile;
UserProfile.defaultProps = defaultPropsUserProfile;

export default UserProfile;
