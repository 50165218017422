/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import styles from "./Input.module.css";

const propTypes = {
  label: PropTypes.string,
  help: PropTypes.string,
  iconleft: PropTypes.string,
  iconright: PropTypes.string,
  isDanger: PropTypes.bool,
  onclickiconright: PropTypes.func,
};

const defaultProps = {
  label: null,
  help: null,
  iconleft: null,
  iconright: null,
  isDanger: false,
  onclickiconright: null,
};

const Input = React.forwardRef((props, ref) => {
  const inputClasses = ["input"];

  if (props.isDanger) {
    inputClasses.push("is-danger");
  }

  const filteredProps = {
    ...props,
  };

  delete filteredProps.isDanger;
  delete filteredProps.iconright;
  delete filteredProps.onclickiconright;

  let labelContent = null;
  if (props.label) {
    labelContent = <label className="label">{props.label}</label>;
  }

  let iconLeftContent = null;
  if (props.iconleft) {
    iconLeftContent = (
      <span className="icon is-small is-left">
        <i className={props.iconleft} />
      </span>
    );
  }

  let iconRightContent = null;
  if (props.iconright) {
    iconRightContent = (
      <span
        className={`icon is-small is-right ${
          props.onclickiconright ? styles.clickable : null
        }`}
        tabIndex={-1}
        onClick={props.onclickiconright}
      >
        <i className={props.iconright}></i>
      </span>
    );
  }

  let helpContent = null;
  if (props.help) {
    helpContent = (
      <p className={`help ${props.isDanger ? "is-danger" : ""}`}>
        {props.help}
      </p>
    );
  }

  return (
    <div className="field">
      {labelContent}
      <div
        className={`control ${props.iconleft ? "has-icons-left" : ""} ${
          props.iconright ? "has-icons-right" : ""
        }`}
      >
        <input
          ref={ref}
          className={inputClasses.join(" ")}
          {...filteredProps}
        />
        {iconLeftContent}
        {iconRightContent}
      </div>
      {helpContent}
    </div>
  );
});

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
