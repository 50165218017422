import { gql } from "@apollo/client";
import {
  FRAGMENT_USER_UPDATE,
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
} from "./fragments";
import { mergeEntryUpdates } from "./entries";
import { mergeCardUpdates } from "./cards";
import { mergeUserUpdates, processExpEvent } from "./user";
import { mergeBlockUpdates } from "./blocks";

const GET_AMOUNT_OF_DUE_CARDS = gql`
  query($entryId: ID) {
    dueCardsAmount(entryId: $entryId)
  }
`;

const NEXT_CARDS = gql`
  query($entryId: ID, $maxAmount: Int!) {
    nextCardsForQuiz(entryId: $entryId, maxAmount: $maxAmount) {
      id
      type
      frontText
      frontTitle
      frontImage {
        id
        url
      }
      backText
      backTitle
      backImage {
        id
        url
      }
      block {
        entry {
          id
          name
          image {
            id
            url
          }
        }
      }
    }
  }
`;

const PUBLISH_RESULTS = gql`
  mutation($input: QuizResultInput) {
    publishQuizResults(input: $input) {
      message
      updatedEntries {
        id
        cardsActiveAmountRecursive
        cardsDueAmountRecursive
      }
      updatedBlocks {
        id
        dust
        dusted
      }
      updatedCards {
        id
        block {
          id
        }
        difficulty
        daysBetweenReviews
        lastReviewed
      }
      updatedUser {
        ...UserUpdate
        cardsDueAmount
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const mergeChangesAfterPublishResults = (cache, mutationResult) => {
  const { updatedEntries } = mutationResult.data.publishQuizResults;
  const { updatedBlocks } = mutationResult.data.publishQuizResults;
  const { updatedCards } = mutationResult.data.publishQuizResults;
  const { updatedUser } = mutationResult.data.publishQuizResults;
  const { expEvent } = mutationResult.data.publishQuizResults;

  // merge entry updates (needed because of cardsDue)
  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  // merge block updates (needed because of dust)
  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }

  // merge card updates (needed because of difficulty and review times)
  if (updatedCards.length > 0) {
    mergeCardUpdates(cache, updatedCards);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }
};

export {
  GET_AMOUNT_OF_DUE_CARDS,
  NEXT_CARDS,
  PUBLISH_RESULTS,
  mergeChangesAfterPublishResults,
};
