import React from "react";
import PropTypes from "prop-types";

import styles from "./EditReferenceWeb.module.scss";
import { useTranslation } from "react-i18next";

const propTypesEditReferenceWeb = {
  attemptedFetch: PropTypes.bool,
  webLink: PropTypes.shape({
    id: PropTypes.string,
    iconUrl: PropTypes.string,
  }),
  url: PropTypes.string,
  title: PropTypes.string,
  titlePlaceholder: PropTypes.string,
  description: PropTypes.string,
  fetchWebLinkLoading: PropTypes.bool,
  onChangeUrl: PropTypes.func.isRequired,
  onChangeTitle: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  hideDescription: PropTypes.bool,
};
const defaultPropsEditReferenceWeb = {
  attemptedFetch: false,
  webLink: null,
  title: "",
  titlePlaceholder: null,
  url: "",
  description: "",
  fetchWebLinkLoading: false,
  hideDescription: false,
};

const EditReferenceWeb = ({
  attemptedFetch,
  webLink,
  url,
  title,
  titlePlaceholder,
  description,
  fetchWebLinkLoading,
  onChangeUrl,
  onChangeTitle,
  onChangeDescription,
  hideDescription,
}) => {
  const { t } = useTranslation();

  const hasInvalidUrl =
    attemptedFetch && !webLink && !fetchWebLinkLoading && url.length > 0;

  return (
    <div>
      <div className={styles.inputContainer}>
        <div
          className={`control has-icons-left ${
            attemptedFetch && url.length > 0 ? "has-icons-right" : null
          } ${fetchWebLinkLoading ? "is-loading" : null}`}
        >
          <input
            className={`input ${styles.input} ${
              hasInvalidUrl ? "is-danger" : null
            }`}
            type="text"
            value={url}
            onChange={onChangeUrl}
            autoFocus
            placeholder={t(
              "wisdomtree.modal_add_reference.add_link_web.placeholder_url"
            )}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-globe"></i>
          </span>
          {attemptedFetch && !fetchWebLinkLoading && webLink ? (
            <span className="icon is-small is-right">
              <i className={`fas fa-check`}></i>
            </span>
          ) : null}
        </div>
        {hasInvalidUrl ? (
          <div className={styles.errorMessage}>
            <span>
              {t("wisdomtree.modal_add_reference.add_link_web.invalid_url")}
            </span>
          </div>
        ) : null}
      </div>

      <div className={styles.inputContainer}>
        <div
          className={`control has-icons-left ${
            webLink ? null : styles.invisible
          }`}
        >
          <input
            className={`input ${styles.input}`}
            type="text"
            value={title}
            onChange={onChangeTitle}
            maxLength={255}
            placeholder={
              titlePlaceholder
                ? titlePlaceholder
                : t(
                    "wisdomtree.modal_add_reference.add_link_web.placeholder_title"
                  )
            }
          />
          <span className="icon is-small is-left">
            {webLink && webLink.iconUrl ? (
              <img
                className={styles.icon}
                src={webLink.iconUrl}
                height="24"
                width="24"
              />
            ) : (
              <i className="fas fa-heading"></i>
            )}
          </span>
        </div>
      </div>

      {!hideDescription ? (
        <div className={styles.inputContainer}>
          <div
            className={`control has-icons-left ${
              webLink ? null : styles.invisible
            }`}
          >
            <input
              className={`input ${styles.input}`}
              type="text"
              value={description}
              onChange={onChangeDescription}
              maxLength={255}
              placeholder={t(
                "wisdomtree.modal_add_reference.add_link_web.placeholder_description"
              )}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-question-circle"></i>
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

EditReferenceWeb.propTypes = propTypesEditReferenceWeb;
EditReferenceWeb.defaultProps = defaultPropsEditReferenceWeb;

export default EditReferenceWeb;
