import { InMemoryCache, makeVar } from "@apollo/client";

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        themeSetting: {
          read() {
            return themeSetting();
          },
        },
        entries: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        tags: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        categories: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        colors: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        searchImages: {
          keyArgs: ({ input }) => {
            return `${input.source}_${input.query}`;
          },
          merge(existing = { results: [] }, incoming) {
            return {
              results: [...existing.results, ...incoming.results],
              currentPage: incoming.currentPage,
              total: incoming.total,
              totalPages: incoming.totalPages,
            };
          },
        },
        featureRequests: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
      },
    },
    Entry: {
      fields: {
        blocks: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        tags: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        childEntryIds: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        references: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        referencedBy: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        image: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        isImageUpdating: {
          read(existing) {
            return existing ? existing : false;
          },
        },
      },
    },
    Block: {
      fields: {
        cards: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        references: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        referencedBy: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
        isImageUpdating: {
          read(existing) {
            return existing ? existing : false;
          },
        },
      },
    },
    Card: {
      fields: {
        isFrontImageUpdating: {
          read(existing) {
            return existing ? existing : false;
          },
        },
        isBackImageUpdating: {
          read(existing) {
            return existing ? existing : false;
          },
        },
      },
    },
    User: {
      fields: {
        isImageUpdating: {
          read(existing) {
            return existing ? existing : false;
          },
        },
      },
    },
    ReferenceEndpoint: {
      keyFields: false,
    },
    FeatureRequest: {
      fields: {
        comments: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

export const isLoggedInVar = makeVar(!!localStorage.getItem("token"));
export const themeSetting = makeVar(
  localStorage.getItem("themeSetting")
    ? localStorage.getItem("themeSetting")
    : "SYSTEM"
);
export const mobileNotificationPermissionStatus = makeVar(null);
