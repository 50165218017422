import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./BlockActionsButton.module.scss";
import Dropdown from "../../../../../../UI/Dropdown/Dropdown";
import { useMobileOrTablet } from "../../../../../../Utils/Responsive";
import { hasQuestGoal } from "../../../../../../Utils/QuestHelper";

const propTypesBlockActionsButton = {
  block: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    position: PropTypes.number,
    cardsAmount: PropTypes.number,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({
    currentQuest: PropTypes.object,
  }),
  buttonHidden: PropTypes.bool,
  listSize: PropTypes.number.isRequired,
  hasTitle: PropTypes.bool.isRequired,
  hasText: PropTypes.bool.isRequired,
  hasTags: PropTypes.bool.isRequired,
  onEditWebsite: PropTypes.func.isRequired,
  onAddTitle: PropTypes.func.isRequired,
  onRemoveTitle: PropTypes.func.isRequired,
  onAddText: PropTypes.func.isRequired,
  onRemoveText: PropTypes.func.isRequired,
  onAddTags: PropTypes.func.isRequired,
  onAddReference: PropTypes.func.isRequired,
  onAddImage: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  onManageCards: PropTypes.func.isRequired,
  onAddCard: PropTypes.func.isRequired,
  onMoveToEntry: PropTypes.func.isRequired,
  onMoveToTop: PropTypes.func.isRequired,
  onMoveToBottom: PropTypes.func.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  onToggleDailyQuote: PropTypes.func.isRequired,
};
const defaultPropsBlockActionsButton = {
  buttonHidden: false,
  user: null,
};

const BlockActionsButton = ({
  block,
  user,
  buttonHidden,
  listSize,
  hasTitle,
  hasText,
  hasTags,
  onEditWebsite,
  onAddTitle,
  onRemoveTitle,
  onAddText,
  onRemoveText,
  onAddTags,
  onAddReference,
  onAddImage,
  onRemoveImage,
  onManageCards,
  onAddCard,
  onMoveToEntry,
  onMoveToTop,
  onMoveToBottom,
  onDeleteBlock,
  onToggleDailyQuote,
}) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMobileOrTablet();

  const actions = [];

  if (block.type === "WEB") {
    actions.push({
      key: "edit_web",
      icon: "fa-edit",
      text: t("wisdomtree.entry_browser.block_website_edit"),
      tooltip: t("wisdomtree.entry_browser.tooltips.block_website_edit"),
      handler: () => {
        onEditWebsite();
      },
    });
  }

  if (block.type !== "WEB") {
    if (!hasTitle) {
      actions.push({
        key: "add_title",
        icon: "fa-heading",
        text: t("wisdomtree.entry_browser.block_add_title"),
        tooltip: t("wisdomtree.entry_browser.tooltips.block_add_title"),
        handler: () => {
          onAddTitle();
        },
      });
    } else {
      actions.push({
        key: "remove_title",
        icon: "fa-heading",
        text: t("wisdomtree.entry_browser.block_remove_title"),
        tooltip: t("wisdomtree.entry_browser.tooltips.block_remove_title"),
        handler: () => {
          onRemoveTitle();
        },
      });
    }
  }

  if (block.type === "IMAGE") {
    if (!hasText) {
      actions.push({
        key: "add_text",
        icon: "fa-paragraph",
        text: t("wisdomtree.entry_browser.block_add_description"),
        tooltip: t("wisdomtree.entry_browser.tooltips.block_add_description"),
        handler: () => {
          onAddText();
        },
      });
    } else {
      actions.push({
        key: "remove_text",
        icon: "fa-paragraph",
        text: t("wisdomtree.entry_browser.block_remove_description"),
        tooltip: t(
          "wisdomtree.entry_browser.tooltips.block_remove_description"
        ),
        handler: () => {
          onRemoveText();
        },
      });
    }
  }

  if (
    block.type !== "IMAGE" &&
    block.type !== "QUOTE" &&
    block.type !== "WEB"
  ) {
    if (!block.image) {
      const hasQuest = user ? hasQuestGoal(user, "images") : false;
      actions.push({
        key: "add_image",
        icon: "fa-image",
        text: t("wisdomtree.entry_browser.block_add_image"),
        tooltip: t("wisdomtree.entry_browser.tooltips.block_add_image"),
        hasQuest: hasQuest,
        handler: () => {
          onAddImage();
        },
      });
    } else {
      actions.push({
        key: "remove_image",
        icon: "fa-image",
        text: t("wisdomtree.entry_browser.block_remove_image"),
        tooltip: t("wisdomtree.entry_browser.tooltips.block_remove_image"),
        handler: () => {
          onRemoveImage();
        },
      });
    }
  }

  if (!hasTags) {
    actions.push({
      key: "add_tags",
      icon: "fa-tags",
      text: t("wisdomtree.entry_browser.block_add_tags"),
      tooltip: t("wisdomtree.entry_browser.tooltips.block_add_tags"),
      handler: () => {
        onAddTags();
      },
    });
  }

  actions.push({
    key: "add_reference",
    icon: "fa-link",
    text: t("wisdomtree.entry_browser.block_add_reference"),
    tooltip: t("wisdomtree.entry_browser.tooltips.block_add_reference"),
    handler: () => {
      onAddReference();
    },
  });

  if (
    block.type !== "IMAGE" &&
    block.type !== "QUOTE" &&
    block.type !== "WEB"
  ) {
    if (block.cardsAmount > 0) {
      actions.push({
        key: "cards",
        icon: "fa-brain",
        text: t("wisdomtree.entry_browser.block_manage_cards"),
        tooltip: t(
          "wisdomtree.entry_browser.tooltips.block_manage_learning_cards"
        ),
        handler: () => {
          onManageCards(block.id);
        },
      });
    } else {
      const hasQuest = user ? hasQuestGoal(user, "cards") : false;
      actions.push({
        key: "cards",
        icon: "fa-brain",
        text: t("wisdomtree.entry_browser.block_add_card"),
        tooltip: t("wisdomtree.entry_browser.tooltips.block_add_learning_card"),
        hasQuest: hasQuest,
        handler: () => {
          onAddCard(block.id);
        },
      });
    }
  }

  if (block.type === "QUOTE") {
    actions.push({
      key: "dailyQuote",
      icon: "fa-quote-right",
      text: block.dailyQuote
        ? t("wisdomtree.entry_browser.block_daily_quote_disable")
        : t("wisdomtree.entry_browser.block_daily_quote_enable"),
      tooltip: block.dailyQuote
        ? t("wisdomtree.entry_browser.tooltips.block_daily_quote_disable")
        : t("wisdomtree.entry_browser.tooltips.block_daily_quote_enable"),
      handler: () => {
        onToggleDailyQuote(!block.dailyQuote);
      },
    });
  }

  actions.push({
    key: "move_block",
    icon: "fa-arrows-alt",
    text: t("wisdomtree.entry_browser.block_move_to_entry"),
    tooltip: t("wisdomtree.entry_browser.tooltips.block_move_to_entry"),
    handler: () => {
      onMoveToEntry();
    },
  });

  if (listSize !== 0) {
    if (block.position > 0) {
      actions.push({
        key: "move_up",
        icon: "fa-arrow-up",
        text: t("wisdomtree.entry_browser.block_move_to_top"),
        tooltip: t("wisdomtree.entry_browser.tooltips.block_move_top"),
        handler: () => {
          onMoveToTop(block.id);
        },
      });
    }

    if (block.position < listSize - 1) {
      actions.push({
        key: "move_down",
        icon: "fa-arrow-down",
        text: t("wisdomtree.entry_browser.block_move_to_bottom"),
        tooltip: t("wisdomtree.entry_browser.tooltips.block_move_bottom"),
        handler: () => {
          onMoveToBottom(block.id);
        },
      });
    }
  }

  actions.push({
    key: "remove",
    icon: "fa-trash",
    text: t("wisdomtree.entry_browser.block_delete"),
    tooltip: t("wisdomtree.entry_browser.tooltips.block_delete"),
    handler: onDeleteBlock,
  });

  return (
    <div className={styles.actionDropdown}>
      <Dropdown
        items={actions}
        buttonIcon="fa-ellipsis-h"
        showDropdownIcon={false}
        isUsingHover={false}
        buttonHidden={buttonHidden}
        onItemClicked={(item) => item.handler()}
        alignment={isTabletOrMobile ? "is-right" : "is-left"}
        buttonTooltip={t(
          "wisdomtree.entry_browser.tooltips.block_edit_dropdown"
        )}
      />
    </div>
  );
};

BlockActionsButton.propTypes = propTypesBlockActionsButton;
BlockActionsButton.defaultProps = defaultPropsBlockActionsButton;

export default BlockActionsButton;
