/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import * as d3 from "d3";
import i18n from "i18next";
import Logger from "js-logger";
import d3SaveSvg from "d3-save-svg";
import * as Constants from "../Constants";
import * as graphHelper from "./GraphHelper";
import GraphCreation from "./GraphCreation";
import GraphCamera from "./GraphCamera";
import GraphContent from "./GraphContent";
import EntryCreation from "./EntryCreation";
import EntrySelection from "./EntrySelection";
import EntryHoverEffect from "./EntryHoverEffect";
import EntryChangeParent from "./EntryChangeParent";
import EntryDropBlock from "./EntryDropBlock";
import EntryContextMenu from "./EntryContextMenu";
import EntryMove from "./EntryMove";

class Graph {
  constructor(props) {
    this.graphConfig = {
      nodeColor: props.darkMode ? "rgb(58, 58, 58)" : "rgb(255, 255, 255)",
      nodeOpacity: 1.0,
      nodeCornerRadius: 4,
      nodeStrokeColor: "rgb(112, 112, 112)",
      nodeStrokeColorHover: props.linkColor,
      nodeStrokeWidth: 0,
      nodeShadowOffsetX: 1,
      nodeShadowOffsetY: 1,
      nodeShadowStdDeviation: 1,
      nodeShadowOpacity: 0.3,
      nodeDraggedShadowOffsetX: 2,
      nodeDraggedShadowOffsetY: 2,
      nodeDraggedShadowStdDeviation: 1.5,
      nodeDraggedShadowOpacity: 0.25,
      nodeHeight: 45,
      nodeImageSize: 45,
      nodeImageBackgroundColor: props.darkMode
        ? "rgb(70, 70, 70)"
        : "rgb(245, 245, 245)",
      nodeColorIndicatorWidth: 7,
      nodeReferenceStrokeWidth: 3,
      nodeReferenceStrokeColor: props.darkMode
        ? props.primaryDarkColor
        : props.primaryColor,
      userImageUrl: props.userImageUrl,
      rootNodeRadius: 32,
      rootNodeImageSize: 64,
      rootNodeIconSize: 32,
      rootNodeIconBackgroundSize: 58,
      rootNodeColor: props.darkMode ? "rgb(58, 58, 58)" : "rgb(100, 100, 100)",
      rootNodeBackgroundColor: props.darkMode
        ? "rgb(58, 58, 58)"
        : "rgb(245, 245, 245)",
      rootNodeLevelRadius: 11,
      rootNodeLevelColor: props.darkMode ? "#383838" : props.secondaryColor,
      rootNodeLevelStroke: props.secondaryDarkColor,
      rootNodeLevelStrokeWidth: "1px",
      rootNodeLevelOffsetX: 24,
      rootNodeLevelOffsetY: 24,
      rootNodeLevelTextSize: "16px",
      rootNodeLevelTextColor: props.darkMode
        ? props.secondaryColor
        : "rgb(255, 255, 255, 1)",
      rootNodeLevelTextStroke: props.darkMode
        ? "transparent"
        : props.secondaryDarkColor,
      rootNodeLevelTextStrokeWidth: "2px",
      nodeTextSize: "20px",
      nodeTextPaddingLeft: 12,
      nodeTextPaddingRight: 12,
      nodeTextColor: props.darkMode ? "rgb(230, 230, 230)" : "rgb(10, 10, 10)",
      nodeSelectedColor: props.darkMode
        ? props.primaryDarkColor
        : props.primaryColor,
      nodeTextSelectColor: "rgb(245, 245, 245)",
      nodeTextSelectColorLight: "rgb(10, 10, 10)",
      newNodePlaceholderName: i18n.t("wisdomtree.graph.new_entry"),
      newNodeColor: props.darkMode ? props.thirdDarkColor : props.thirdColor,
      indicatorColor: props.darkMode ? "rgb(58, 58, 58)" : "rgb(255, 255, 255)",
      indicatorTextColor: props.darkMode
        ? "rgb(135, 135, 135)"
        : "rgb(180, 180, 180)",
      indicatorTextSize: "14px",
      indicatorHeight: 18,
      indicatorWidth: 30,
      indicatorHiddenChildrenOffset: 6,
      edgeStrokeWidth: props.darkMode ? 3.5 : 3,
      edgeColor: props.darkMode ? "rgb(58,58,58)" : "rgb(200,200,200)",
      edgeOpacity: props.darkMode ? 0.75 : 0.6,
      edgeReferenceOpacity: props.darkMode ? 0.75 : 0.6,
      edgeReferenceColor: props.darkMode
        ? props.primaryDarkColor
        : props.primaryColor,
      edgeReferenceStrokeWidth: props.darkMode ? 3.5 : 3,
      edgeReferenceStrokePattern: "8, 8",
      edgeInferredStrokePattern: "6, 3",
      durationFadeInNode: 240,
      durationFadeOutNode: 180,
      durationFadeInEdge: 300,
      durationFadeOutEdge: 140,
      opacityFadedOutNode: 0.3,
      opacityFadedOutEdge: 0.1,
      opacityHoverOverNode: 0.8,
      opacityHoverOverEdge: 0.2,
      cameraPanDuration: Constants.GRAPH_CAMERA_PAN_DURATION,
      defaultZoom: props.isTabletOrMobile ? 0.7 : 0.8,
      minZoom: 0.4,
      maxZoom: 1.6,
      zoomTargetMinZoom: props.isTabletOrMobile ? 0.6 : 0.5,
      zoomTargetMaxZoom: props.isTabletOrMobile ? 1.2 : 1.4,
      zoomTargetPaddingPercentage: props.isTabletOrMobile ? 0.8 : 0.5,
      zoomTargetIncludeParentThreshold: 600,
      changeParentEntryDelay: 160,
      changeParentDistance: 100,
      changeParentColor: props.darkMode
        ? props.thirdDarkColor
        : props.thirdColor,
      dropBlockColor: props.darkMode ? props.thirdDarkColor : props.thirdColor,
      dragNodeDelay: props.isTabletOrMobile ? 400 : 300,
      dragNodeRequireLongpress: props.isTabletOrMobile,
      dragNodeLongpressDistanceThreshold: 20,
      dragNodeLongpressVibrateMs: 40,
      scrollYScrollTopThreshold: Constants.defaultScrollY(
        props.isTabletOrMobile,
        window.innerHeight
      ),
      rootEntryId: props.rootEntryId,
    };

    this.graphConfig.textMeasurementCanvas = document.createElement("canvas");
    this.graphConfig.textMeasurementContext = this.graphConfig.textMeasurementCanvas.getContext(
      "2d"
    );
    this.graphConfig.textMeasurementContext.font = `${this.graphConfig.nodeTextSize} Roboto`;

    this.props = {
      onNewEntry: props.onNewEntry,
      onUpdateEntry: props.onUpdateEntry,
      onSelectEntry: props.onSelectEntry,
      onAttemptSelectEntry: props.onAttemptSelectEntry,
      onUpdateNewEntryDraft: props.onUpdateNewEntryDraft,
      onUpdateBlockEntry: props.onUpdateBlockEntry,
    };

    this.editMode = props.editMode;
    this.tabletOrMobile = props.isTabletOrMobile;

    this.graphCreation = new GraphCreation(this.graphConfig);
    this.graphContent = new GraphContent(
      this.graphConfig,
      this.isEditModeEnabled.bind(this),
      this.getSelectedEntryId.bind(this),
      this.onRootNodeMouseEnter.bind(this),
      this.onRootNodeMouseLeave.bind(this),
      this.onRootNodeClick.bind(this),
      this.onRootNodeStartDrag.bind(this),
      this.onRootNodeDrag.bind(this),
      this.onRootNodeEndDrag.bind(this),
      this.onNodeMouseEnter.bind(this),
      this.onNodeMouseLeave.bind(this),
      this.onNodeClick.bind(this),
      this.onNodeStartDrag.bind(this),
      this.onNodeDrag.bind(this),
      this.onNodeEndDrag.bind(this),
      this.onNodeDragEnter.bind(this),
      this.onNodeDragOver.bind(this),
      this.onNodeDragLeave.bind(this),
      this.onNodeDrop.bind(this),
      this.onNodeMouseDown.bind(this),
      this.onNodeMouseUp.bind(this),
      this.onNodeTouchStart.bind(this),
      this.onNodeTouchEnd.bind(this)
    );
    this.graphCamera = new GraphCamera(
      this.graphConfig,
      this.onZoomChanged.bind(this)
    );
    this.entryCreation = new EntryCreation(
      this.graphConfig,
      this.onUpdateNewEntryDraft.bind(this),
      this.getAllNodes.bind(this)
    );
    this.entryContextMenu = new EntryContextMenu(
      this.graphConfig,
      this.isTabletOrMobile.bind(this),
      this.props.onNewEntry,
      this.isEntryContextMenuEnabled.bind(this)
    );
    this.entrySelection = new EntrySelection(
      this.graphConfig,
      this.entryContextMenu
    );
    this.entryHoverEffect = new EntryHoverEffect(
      this.graphConfig,
      this.isEntryIdGrayedOut.bind(this),
      this.isEntryIdVisible.bind(this),
      this.isRecentlyUpdated.bind(this)
    );
    this.entryMove = new EntryMove(
      this.graphConfig,
      this.isEntryDraggingEnabled.bind(this)
    );
    this.entryChangeParent = new EntryChangeParent(
      this.graphConfig,
      this.getAllNodes.bind(this),
      this.props.onUpdateEntry
    );
    this.entryDropBlock = new EntryDropBlock(
      this.graphConfig,
      this.props.onUpdateBlockEntry
    );
  }

  create(rootElement, width, height) {
    Logger.info("[graphD3] create");
    this.graphCreation.createNewEmptyGraph(rootElement, width, height);
    this.graphContent.create(rootElement);
    this.graphCamera.initialize(rootElement, width, height);

    d3.select(rootElement).on(
      "touchstart",
      () => {
        this.isUserTouching = true;
      },
      true
    );

    d3.select(rootElement).on(
      "touchend",
      () => {
        this.isUserTouching = false;
      },
      true
    );
  }

  updateContent(rootElement, entries, references) {
    if (this.entryMove.isDragging()) {
      // ignore update when user is currently dragging nodes
      // this prevents an ugly glitch
      return;
    }
    Logger.info("[graphD3] update content");
    this.graphContent.updateData(rootElement, entries, references);
    this.entrySelection.select(rootElement, this.getSelectedEntryId(), entries);
  }

  updateContentAndSelectEntry(
    rootElement,
    entries,
    references,
    selectedEntryId
  ) {
    Logger.info("[graphD3] update content & select entry");
    this.graphContent.updateDataAndSelectedEntry(
      rootElement,
      entries,
      references,
      selectedEntryId
    );
    this.entryHoverEffect.resetSelectionEffect();
    this.entrySelection.select(rootElement, selectedEntryId, entries);
  }

  updateUser(rootElement, user) {
    Logger.info("[graphD3] update user");
    this.graphConfig.user = user;

    if (!this.graphConfig.rootEntryId) {
      this.graphContent.updateRootNodeImage(rootElement);
      this.graphContent.updateRootNodeLevel(rootElement);
      this.graphContent.updateRootNodeSize(rootElement);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  destroy(rootElement) {
    d3.select(rootElement)
      .selectAll("*")
      .remove();
  }

  enableQuizMode(rootElement) {
    this.quizMode = true;
    this.graphCamera.lock();
    this.graphCamera.zoomToCenterEntry(rootElement, this.getSelectedEntryId());
  }

  disableQuizMode(rootElement) {
    this.quizMode = false;
    this.graphCamera.unlock();
  }

  enableEditMode(rootElement) {
    this.editMode = true;
  }

  disableEditMode(rootElement) {
    this.editMode = false;
  }

  enableNewEntryMode(rootElement) {
    const newEntryDraft = this.entryCreation.enable(
      rootElement,
      this.entrySelection.getCurrentId()
    );

    this.graphCamera.zoomToFitNewEntryMode(
      rootElement,
      this.getNodeForEntryId(this.entrySelection.getCurrentId()),
      {
        data: {
          x: newEntryDraft.x,
          y: newEntryDraft.y,
          nodeHeight: newEntryDraft.height,
          nodeWidth: newEntryDraft.width,
        },
      }
    );

    this.entryContextMenu.hide();
  }

  setIsTabletOrMobile(rootElement, isTabletOrMobile) {
    this.tabletOrMobile = isTabletOrMobile;
  }

  isTabletOrMobile() {
    return this.tabletOrMobile;
  }

  addNewEntryNode(rootElement) {
    this.entryCreation.addNode(rootElement, this.entrySelection.getCurrentId());
  }

  updateNewEntryDraft(rootElement, newEntryDraft) {
    this.entryCreation.updateDraft(rootElement, newEntryDraft);
  }

  onUpdateNewEntryDraft(rootElement, newEntryDraft, updates) {
    this.props.onUpdateNewEntryDraft(updates);

    this.graphCamera.zoomToFitNewEntryMode(
      rootElement,
      this.getNodeForEntryId(this.entrySelection.getCurrentId()),
      {
        data: {
          x: newEntryDraft.x,
          y: newEntryDraft.y,
          nodeHeight: newEntryDraft.height,
          nodeWidth: newEntryDraft.width,
        },
      }
    );
  }

  disableNewEntryMode(rootElement) {
    this.entryCreation.disable(rootElement);

    this.graphCamera.zoomToEntry(
      rootElement,
      this.entrySelection.getCurrentId()
    );
  }

  onZoomChanged(targetTransform) {
    if (this.dragInfo && this.isTabletOrMobile())
      this.dragInfo.interruptedByZoomChange = true;
  }

  onRootNodeMouseEnter(source, rootElement, d, i) {
    // if (!this.isEntrySelectionEnabled()) return;
    // d3.select(source)
    //   .select("circle")
    //   .attr("stroke", this.graphConfig.nodeStrokeColorHover);
    // this.entryHoverEffect.onMouseEnterEntryId(rootElement, -1);
  }

  onRootNodeMouseLeave(source, rootElement, d, i) {
    // if (!this.isEntrySelectionEnabled()) return;
    // d3.select(source)
    //   .select("circle")
    //   .attr("stroke", this.graphConfig.nodeStrokeColor);
    // this.entryHoverEffect.onMouseLeaveEntryId(rootElement, -1);
  }

  onRootNodeClick(source, rootElement, d, i) {
    if (!this.isEntrySelectionEnabled()) {
      this.props.onAttemptSelectEntry(null);
      return;
    }

    // only zoom camera if already selected, except in new mode
    if (this.getSelectedEntryId() === null && !this.entryCreation.isEnabled()) {
      this.graphCamera.zoomToRootNode(rootElement);
    } else {
      this.entrySelection.preSelectRootNode(rootElement);
      setTimeout(() => {
        this.props.onSelectEntry(null);
      }, 0);
    }
  }

  onRootNodeStartDrag(source, rootElement, d, i) {
    this.onRootNodeClick(source, rootElement, d, i);
  }

  onRootNodeDrag(source, rootElement, d, i) {}

  onRootNodeEndDrag(source, rootElement, d, i) {}

  onNodeMouseEnter(source, rootElement, d, i) {
    // if (!this.isEntrySelectionEnabled()) return;
    // d3.select(source)
    //   .select(".nodeRect")
    //   .attr("stroke", this.graphConfig.nodeStrokeColorHover);
    //  this.entryHoverEffect.onMouseEnterEntryId(rootElement, d.data.id);
  }

  onNodeMouseLeave(source, rootElement, d, i) {
    // if (!this.isEntrySelectionEnabled()) return;
    // d3.select(source)
    //   .select(".nodeRect")
    //   .attr("stroke", this.graphConfig.nodeStrokeColor);
    // this.entryHoverEffect.onMouseLeaveEntryId(rootElement, d.data.id);
  }

  onNodeMouseDown(source, rootElement, d, i) {}

  onNodeMouseUp(source, rootElement, d, i) {}

  onNodeTouchStart(source, rootElement, d, i) {}

  onNodeTouchEnd(source, rootElement, d, i) {}

  onNodeClick(source, rootElement, d, i) {
    // prevent double node click (mainly cause of nodeDragEnd)
    if (this.lastNodeClick && Date.now() - this.lastNodeClick < 200) {
      return;
    }
    this.lastNodeClick = Date.now();

    if (!this.isEntrySelectionEnabled()) {
      this.props.onAttemptSelectEntry(d.data);
      return;
    }
    // console.time("onNodeClick");
    // only zoom camera if already selected, unless in new mode
    if (
      this.getSelectedEntryId() === d.data.id &&
      !this.entryCreation.isEnabled()
    ) {
      this.graphCamera.zoomToFitEntryNode(rootElement, d);
    } else {
      this.entrySelection.preSelect(
        rootElement,
        d.data.id,
        this.graphContent.getAllEntries()
      );
      setTimeout(() => {
        this.props.onSelectEntry(d.data);
      }, 0);
    }
    // console.timeEnd("onNodeClick");
  }

  onNodeStartDrag(source, rootElement, d, i) {
    this.entryMove.onNodeStartDrag(rootElement, d);
    this.entryChangeParent.onNodeStartDrag(d);
  }

  onNodeDrag(source, rootElement, d, i) {
    this.entryMove.onNodeDrag(rootElement, d);
    this.entryChangeParent.onNodeDrag(rootElement, d);
    this.entryContextMenu.hide();
  }

  onNodeEndDrag(source, rootElement, d, i) {
    // fix issue of not triggering click after a drag was initiated
    if (!this.entryMove.isLongPressInitiated() && !this.isTabletOrMobile()) {
      this.onNodeClick(source, rootElement, d, i);
    }

    let entryUpdate = this.entryMove.onNodeEndDrag(rootElement, d);

    if (entryUpdate && ("x" in entryUpdate || "y" in entryUpdate)) {
      if (d.data.id === this.getSelectedEntryId()) {
        this.graphCamera.zoomToFitEntryNode(rootElement, d);
      }
    }

    if (this.entryChangeParent.hasParentUpdate()) {
      entryUpdate = {
        ...entryUpdate,
        ...this.entryChangeParent.getParentUpdate(),
      };
    }

    if (Object.keys(entryUpdate).length >= 1) {
      this.props.onUpdateEntry({
        id: d.data.id,
        ...entryUpdate,
      });
    }

    this.entryChangeParent.onNodeEndDrag(rootElement);
  }

  onNodeDragEnter(source, rootElement, d, i) {
    this.entryDropBlock.onNodeDragEnter(rootElement, d);
  }

  onNodeDragOver(source, rootElement, d, i) {
    this.entryDropBlock.onNodeDragOver(rootElement, d);
  }

  onNodeDragLeave(source, rootElement, d, i) {
    this.entryDropBlock.onNodeDragLeave(rootElement, d);
  }

  onNodeDrop(source, rootElement, d, i) {
    this.entryDropBlock.onNodeDrop(rootElement, d);
  }

  isEditModeEnabled() {
    return this.editMode;
  }

  isEntrySelectionEnabled() {
    if (this.quizMode) {
      return false;
    }
    return true;
  }

  isEntryDraggingEnabled() {
    if (this.entryCreation.isEnabled() || !this.editMode || this.quizMode) {
      return false;
    }
    return true;
  }

  isEntryContextMenuEnabled() {
    if (
      this.entryCreation.isEnabled() ||
      !this.editMode ||
      this.quizMode ||
      this.entryChangeParent.isDragging()
    ) {
      return false;
    }
    return true;
  }

  saveGraphSvg() {
    const config = {
      filename: "customFileName",
    };
    d3SaveSvg.save(d3.select("svg").node(), config);
  }

  getAllNodes() {
    return this.graphContent.getAllNodes();
  }

  getNodeForEntryId(entryId) {
    return this.graphContent.getNodeForEntryId(entryId);
  }

  isEntryIdVisible(entryId) {
    return this.graphContent.isEntryIdVisible(entryId);
  }

  isEntryIdGrayedOut(entryId) {
    return this.graphContent.isEntryIdGrayedOut(entryId);
  }

  getSelectedEntryId() {
    return this.entrySelection.getCurrentId();
  }

  setRecentlyUpdated(recentlyUpdated) {
    this.recentlyUpdated = recentlyUpdated;
  }

  isRecentlyUpdated() {
    return this.recentlyUpdated;
  }
}

export default Graph;
