/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import { useQuery } from "@apollo/client";
import { sticky } from "tippy.js";
import { useTranslation } from "react-i18next";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/shift-away.css";

import styles from "./Feedback.module.scss";
import FeedbackContent from "./FeedbackContent/FeedbackContent";
import { useHotkey } from "../../Utils/Hotkeys";
import { GET_UNREAD_MESSAGES_COUNT } from "../../GraphQl/feedback";
import { TooltipDelayed } from "../../UI/Tooltip/Tooltip";

const propTypesFeedback = {
  onFeedbackOpenedChanged: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
};
const defaultPropsFeedback = {
  hideButton: false,
};

const Feedback = ({ onFeedbackOpenedChanged, hideButton }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [shown, setShown] = useState(false);

  const {
    data: getUnreadMessageCountData,
    startPolling,
    stopPolling,
  } = useQuery(GET_UNREAD_MESSAGES_COUNT, {
    pollInterval: 10000,
  });

  useEffect(() => {
    startPolling();

    return () => {
      stopPolling();
    };
  }, []);

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!active) return;
      setActive(false);
    },
    dependsOn: [active],
  });

  useEffect(() => {
    onFeedbackOpenedChanged(active);
  }, [active]);

  // do not show if tour is not completed
  if (hideButton) {
    return <div />;
  }

  let feedbackTooltipContent = <div />;
  if (shown) {
    feedbackTooltipContent = <FeedbackContent />;
  }

  const showNewMessages =
    !shown && getUnreadMessageCountData
      ? getUnreadMessageCountData.unreadMessagesCount > 0
      : false;

  const isDarkMode = document.documentElement.className.includes("theme-dark");
  const innerContent = (
    <div className={styles.buttonContainer}>
      <Tippy
        theme={isDarkMode ? "dark" : "light-border"}
        interactive
        visible={active}
        animation="shift-away"
        content={feedbackTooltipContent}
        plugins={[sticky]}
        onShow={() => {
          setShown(true);
        }}
        onHidden={() => {
          setShown(false);
        }}
        onClickOutside={() => {
          setActive(false);
        }}
        maxWidth="none"
        sticky
      >
        <button
          className={`button ${styles.feedbackButton} ${
            showNewMessages ? "is-primary" : null
          } ${showNewMessages ? styles.newMessage : null} ${
            active ? styles.active : styles.inactive
          }`}
          type="button"
          onClick={() => {
            setActive(!active);
            document.activeElement.blur();
          }}
        >
          <span className="icon is-small">
            <i className={`fas ${active ? "fa-times" : "fa-comments"}`} />
          </span>
        </button>
      </Tippy>
    </div>
  );

  let outerContent = null;
  if (showNewMessages) {
    outerContent = (
      <Tippy
        theme={isDarkMode ? "dark" : "light-border"}
        trigger="manual"
        hideOnClick={false}
        visible={showNewMessages}
        animation="shift-away"
        content={t("wisdomtree.feedback.new_message")}
        placement="top"
        plugins={[sticky]}
        sticky
      >
        {innerContent}
      </Tippy>
    );
  } else if (!shown) {
    outerContent = (
      <TooltipDelayed content={t("wisdomtree.feedback.tooltip_button")}>
        {innerContent}
      </TooltipDelayed>
    );
  } else {
    outerContent = <> {innerContent} </>;
  }

  return <div className={styles.container}>{outerContent}</div>;
};

Feedback.propTypes = propTypesFeedback;
Feedback.defaultProps = defaultPropsFeedback;

export default Feedback;
