import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./BackButton.module.scss";
import { useTranslation } from "react-i18next";

const propTypesBackButton = {
  linkTo: PropTypes.string.isRequired,
};
const defaultPropsBackButton = {};

const BackButton = ({ linkTo }) => {
  const { t } = useTranslation();

  return (
    <Link className={`${styles.backButton} button is-light`} to={linkTo}>
      <span className={`icon ${styles.gray}`}>
        <i className="fas fa-arrow-left" />
      </span>
      <span>{t("wisdomtree.roadmap.back_to_roadmap")}</span>
    </Link>
  );
};

BackButton.propTypes = propTypesBackButton;
BackButton.defaultProps = defaultPropsBackButton;

export default BackButton;
