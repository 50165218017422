import React from "react";
import PropTypes from "prop-types";

import styles from "./InfoIcon.module.scss";
import { TooltipClick } from "../Tooltip/Tooltip";

const propTypesInfoIcon = {
  content: PropTypes.node.isRequired,
};
const defaultPropsInfoIcon = {};

const InfoIcon = ({ content }) => {
  return (
    <div className={styles.container}>
      <TooltipClick content={<div>{content}</div>}>
        <span className={`icon ${styles.gray}`}>
          <i className="fas fa-question-circle" />
        </span>
      </TooltipClick>
    </div>
  );
};

InfoIcon.propTypes = propTypesInfoIcon;
InfoIcon.defaultProps = defaultPropsInfoIcon;

export default InfoIcon;
