/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isMobile as isMobileDevice } from "react-device-detect";

import styles from "./QuestionContent.module.scss";
import ContentQuillEditor from "../../../../../../UI/Editor/ContentQuillEditor";
import Dropdown from "../../../../../../UI/Dropdown/Dropdown";
import ClickableImage from "../../../../../../UI/ClickableImage/ClickableImage";
import { TooltipDelayed } from "../../../../../../UI/Tooltip/Tooltip";

const propTypesReviewContent = {
  card: PropTypes.shape({
    id: PropTypes.string,
    frontText: PropTypes.object,
    frontTextFromParent: PropTypes.bool,
    backText: PropTypes.object,
    backTextFromParent: PropTypes.bool,
    frontImage: PropTypes.shape({
      url: PropTypes.string,
    }),
    backImage: PropTypes.shape({
      url: PropTypes.string,
    }),
    isFrontImageUpdating: PropTypes.bool,
    isBackImageUpdating: PropTypes.bool,
    frontImageFromParent: PropTypes.bool,
    backImageFromParent: PropTypes.bool,
  }).isRequired,
  onAddImage: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  onUpdateCard: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  isFocused: PropTypes.bool,
  isHovered: PropTypes.bool,
};
const defaultPropsReviewContent = {
  editMode: false,
  isFocused: false,
  isHovered: false,
};

const QuestionContent = ({
  card,
  onAddImage,
  onRemoveImage,
  onUpdateCard,
  editMode,
  isFocused,
  isHovered,
}) => {
  const { t } = useTranslation();

  let frontContentEditor = null;
  frontContentEditor = (
    <div className={styles.cardEditor}>
      <ContentQuillEditor
        elementId={card.id}
        elementContent={card.frontText}
        hintText={t("wisdomtree.entry_browser.card_question_front_hint")}
        stylesClassName="blockContentEditor"
        editMode={editMode}
        focusInitially={false}
        onContentChanged={(content) => {
          onUpdateCard(card.id, { frontText: content }, false);
        }}
        tabDisabled={true}
      />
    </div>
  );

  let frontImageContent = null;
  if (card.isFrontImageUpdating || card.frontImage) {
    frontImageContent = (
      <div className={styles.imageContainer}>
        <ClickableImage
          altText="frontImage"
          imageUrl={card.frontImage ? card.frontImage.url : null}
          isLoading={card.isFrontImageUpdating}
          editMode={editMode}
          height={80}
          width={80}
          onImageClicked={() => {
            if (!card.isFrontImageUpdating)
              onAddImage("frontImage", "frontImageInput", null);
          }}
          onImageDropped={(drop) => {
            if (!card.isFrontImageUpdating)
              onAddImage("frontImage", "frontImageInput", drop);
          }}
          preview={!isMobileDevice}
        />
        <TooltipDelayed
          content={t("wisdomtree.entry_browser.tooltips.card_remove_image")}
        >
          <button
            type="button"
            className={`button is-light ${styles.deleteImageButton}`}
            onClick={() => {
              onRemoveImage("frontImageInput");
            }}
          >
            <span className={`icon ${styles.grayIcon}`}>
              <i className="fas fa-trash" />
            </span>
          </button>
        </TooltipDelayed>
      </div>
    );
  }

  const frontDropdownActions = [];
  if (!card.frontImage)
    frontDropdownActions.push({
      key: "image",
      icon: "fa-image",
      text: t("wisdomtree.entry_browser.card_add_image"),
      tooltip: t(`wisdomtree.entry_browser.tooltips.card_add_image`),
      handler: () => {
        onAddImage("frontImage", "frontImageInput");
      },
    });

  let frontDropdownActionsButton = null;
  if (editMode && frontDropdownActions.length > 0) {
    frontDropdownActionsButton = (
      <div id={styles.actionDropdown}>
        <Dropdown
          items={frontDropdownActions}
          buttonIcon="fa-plus"
          showDropdownIcon={false}
          isUsingHover={false}
          buttonHidden={!isMobileDevice && !isFocused && !isHovered}
          buttonTooltip={t(
            "wisdomtree.entry_browser.tooltips.card_add_dropdown_front"
          )}
          onItemClicked={(item) => item.handler()}
          alignment="is-right"
        />
      </div>
    );
  }

  const showBlockForBackContent = (
    <TooltipDelayed
      content={t(
        "wisdomtree.entry_browser.tooltips.card_show_content_question"
      )}
    >
      <label
        className={`${styles.checkbox} ${styles.centerVertically} checkbox`}
      >
        <input
          type="checkbox"
          disabled={editMode ? null : "disabled"}
          checked={card.backTextFromParent}
          onChange={(event) => {
            onUpdateCard(
              card.id,
              {
                backTextFromParent: event.target.checked,
                backTitleFromParent: event.target.checked,
                backImageFromParent: event.target.checked,
              },
              event.target.checked
            );
          }}
        />
        {t("wisdomtree.entry_browser.card_question_back_show_content")}
      </label>
    </TooltipDelayed>
  );

  let backContentEditor = null;
  if (!card.backTextFromParent) {
    backContentEditor = (
      <div className={styles.cardEditor}>
        <ContentQuillEditor
          elementId={card.id}
          elementContent={card.backText}
          hintText={t("wisdomtree.entry_browser.card_question_back_hint")}
          stylesClassName="blockContentEditor"
          editMode={editMode}
          focusInitially={false}
          onContentChanged={(content) => {
            onUpdateCard(card.id, { backText: content }, false);
          }}
          tabDisabled={true}
        />
      </div>
    );
  }

  let backImageContent = null;
  if (
    card.isBackImageUpdating ||
    (card.backImage && !card.backImageFromParent)
  ) {
    backImageContent = (
      <div className={styles.imageContainer}>
        <ClickableImage
          altText="backImage"
          imageUrl={card.backImage ? card.backImage.url : null}
          isLoading={card.isBackImageUpdating}
          editMode={editMode}
          height={80}
          width={80}
          onImageClicked={() => {
            if (!card.isBackImageUpdating)
              onAddImage("backImage", "backImageInput");
          }}
          onImageDropped={(drop) => {
            if (!card.isBackImageUpdating)
              onAddImage("backImage", "backImageInput", drop);
          }}
        />
        <TooltipDelayed
          content={t("wisdomtree.entry_browser.tooltips.card_remove_image")}
        >
          <button
            type="button"
            className={`button is-light ${styles.deleteImageButton}`}
            onClick={() => {
              onRemoveImage("backImageInput");
            }}
          >
            <span className={`icon ${styles.grayIcon}`}>
              <i className="fas fa-trash" />
            </span>
          </button>
        </TooltipDelayed>
      </div>
    );
  }

  const backDropdownActions = [];
  if (!card.backImage && !card.backImageFromParent)
    backDropdownActions.push({
      key: "image",
      icon: "fa-image",
      text: t("wisdomtree.entry_browser.card_add_image"),
      tooltip: t(`wisdomtree.entry_browser.tooltips.card_add_image`),
      handler: () => {
        onAddImage("backImage", "backImageInput");
      },
    });

  let backDropdownActionsButton = null;
  if (editMode && backDropdownActions.length > 0) {
    backDropdownActionsButton = (
      <div id={styles.actionDropdown}>
        <Dropdown
          items={backDropdownActions}
          buttonIcon="fa-plus"
          showDropdownIcon={false}
          isUsingHover={false}
          buttonHidden={!isMobileDevice && !isFocused && !isHovered}
          buttonTooltip={t(
            "wisdomtree.entry_browser.tooltips.card_add_dropdown_back"
          )}
          onItemClicked={(item) => item.handler()}
          alignment="is-right"
        />
      </div>
    );
  }

  return (
    <div className={`${styles.cardContentContainer}`}>
      <div className={`${styles.cardHeaderRow}`}>
        <div className={styles.cardHeaderLeft}>
          <div className={styles.cardHeaderTitle}>
            <p className={styles.cardSideTitle}>
              {t("wisdomtree.entry_browser.card_question_front")}
            </p>
          </div>
        </div>
        <div className={styles.cardHeaderButtons}>
          {frontDropdownActionsButton}
        </div>
      </div>

      {frontContentEditor}
      {frontImageContent}
      <hr className={styles.sidesDivider} />

      <div className={`${styles.cardHeaderRow}`}>
        <div className={styles.cardHeaderLeft}>
          <div className={styles.cardHeaderTitle}>
            <div className={styles.backTitleContainer}>
              <p
                className={`${styles.cardSideTitle} ${styles.centerVertically}`}
              >
                {t("wisdomtree.entry_browser.card_question_back")}
              </p>
              {showBlockForBackContent}
            </div>
          </div>
        </div>
        <div className={styles.cardHeaderButtons}>
          {backDropdownActionsButton}
        </div>
      </div>

      {backContentEditor}
      {backImageContent}
    </div>
  );
};

QuestionContent.propTypes = propTypesReviewContent;
QuestionContent.defaultProps = defaultPropsReviewContent;

export default QuestionContent;
