import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./NewEntry.module.css";
// import { useMobile } from "../../../../Utils/Responsive";
import TitleQuillEditor from "../../../../UI/Editor/TitleQuillEditor";
import CategoryQuickSelect from "../../../../UI/CategoryQuickSelect/CategoryQuickSelect";

const propTypes = {
  entryName: PropTypes.string,
  onUpdateEntryName: PropTypes.func.isRequired,
  onUpdateEntryCategory: PropTypes.func,
  onDialogOpenedChanged: PropTypes.func.isRequired,
};

const defaultProps = {
  entryName: "",
  onUpdateEntryCategory: () => {},
};

const NewEntry = ({
  entryName,
  onUpdateEntryName,
  onUpdateEntryCategory,
  onDialogOpenedChanged,
}) => {
  const { t } = useTranslation();
  // const isMobile = useMobile();

  return (
    <div className={styles.container}>
      <div data-scrollid="newEntry">
        <TitleQuillEditor
          elementId={null}
          elementTitle={entryName}
          limit={50}
          hintText={t("wisdomtree.entry_browser.new_entry_hint")}
          stylesClassName="newEntryEditor"
          editMode
          focusInitially
          // focusInitially={!isMobile}
          onTitleChanged={(name) => onUpdateEntryName(name)}
          titleCanBeUpdatedByProps
        />
      </div>
      <div className={styles.categoriesContainer}>
        <CategoryQuickSelect
          entityType="entry"
          onCategorySelected={(category) => onUpdateEntryCategory(category)}
          onDialogOpenedChanged={onDialogOpenedChanged}
        />
      </div>
    </div>
  );
};

NewEntry.propTypes = propTypes;
NewEntry.defaultProps = defaultProps;

export default NewEntry;
