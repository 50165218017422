import React from "react";
import PropTypes from "prop-types";

import styles from "./FeatureComments.module.scss";
import useAuthentication from "../../../Utils/UseAuthentication";
import { useTranslation } from "react-i18next";
import NewComment from "./NewComment/NewComment";
import { CREATE_FEATURE_REQUEST_COMMENT } from "../../../GraphQl/roadmap";
import { useMutation } from "@apollo/client";
import Logger from "js-logger";
import Comment from "./Comment/Comment";

const propTypesFeatureComments = {
  featureRequestId: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onUserClicked: PropTypes.func,
};
const defaultPropsFeatureComments = {
  comments: [],
  onUserClicked: () => {},
};

const FeatureComments = ({
  featureRequestId,
  comments,
  user,
  onUserClicked,
}) => {
  const { t } = useTranslation();
  const authenticated = useAuthentication();

  const [createComment, { loading: createCommentLoading }] = useMutation(
    CREATE_FEATURE_REQUEST_COMMENT,
    {
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {},
    }
  );

  let contentNewComment = null;
  if (authenticated) {
    contentNewComment = (
      <NewComment
        isLoading={createCommentLoading}
        onSendNewComment={(text) => {
          createComment({
            variables: {
              input: {
                featureRequestId,
                text,
              },
            },
          });
        }}
      />
    );
  }

  const contentCommentsList = (
    <div className={styles.commentsContainer}>
      {comments
        .slice()
        .sort((a, b) => {
          return b.createdAt - a.createdAt;
        })
        .map((c) => {
          const editable = user && user.id === c.author.id;
          const deleteable = user && (user.id === c.author.id || user.admin);
          return (
            <Comment
              key={c.id}
              editable={editable}
              deleteable={deleteable}
              comment={c}
              onUserClicked={onUserClicked}
            />
          );
        })}
    </div>
  );

  return (
    <div className={styles.container}>
      {contentNewComment}
      {contentCommentsList}
    </div>
  );
};

FeatureComments.propTypes = propTypesFeatureComments;
FeatureComments.defaultProps = defaultPropsFeatureComments;

export default FeatureComments;
