class PreventImagesModule {
  constructor(quill, options) {
    quill.on("text-change", function(delta, oldDelta, source) {
      if (source !== "user") return;

      if (
        delta.ops.find((d) => d.insert && (d.insert.image || d.insert.video))
      ) {
        quill.setContents(oldDelta);
      }
    });
  }
}

export default PreventImagesModule;
