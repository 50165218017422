import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import Logger from "js-logger";
import { toast } from "react-toastify";
import validator from "email-validator";
import queryString from "query-string";

import styles from "./RequestAccess.module.scss";
import authStyles from "../Auth.module.scss";
import { gql, useMutation } from "@apollo/client";
import Input from "../../UI/Input/Input";
import ErrorMessages from "../../Utils/ErrorMessages";

const propTypes = {};

const defaultProps = {};

const REQUEST_INVITE = gql`
  mutation($email: String!, $captchaToken: String) {
    requestInvite(input: { email: $email, captchaToken: $captchaToken }) {
      code
      message
    }
  }
`;

const RequestAccess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [fromInviteLink] = useState(queryString.parse(location.search).invite);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [unspecificError, setUnspecificError] = useState(null);

  const [successful, setSuccessful] = useState(false);

  const [requestInvite, { loading: requestInviteLoading }] = useMutation(
    REQUEST_INVITE,
    {
      onError: (error) => {
        Logger.error(error);
        const errorCode = ErrorMessages.getErrorCode(error);
        if (errorCode === "EMAIL_ALREADY_INVITED") {
          setEmailError(errorCode);
          return;
        }
        setUnspecificError(errorCode);
      },
      onCompleted: () => {
        setSuccessful(true);
      },
    }
  );

  useEffect(() => {
    if (fromInviteLink) {
      history.push({
        pathname: "/auth/register",
        search: `?invite=${fromInviteLink}`,
      });
    }
  }, [fromInviteLink]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(null);
  };

  const clearAllErrors = () => {
    setEmailError(null);
    setUnspecificError(null);
  };

  const validateEmail = (emailToValidate) => {
    if (!emailToValidate || emailToValidate.length === 0) {
      setEmailError("REGISTRATION_EMAIL_MISSING");
      return false;
    }

    if (!validator.validate(emailToValidate)) {
      setEmailError("REGISTRATION_EMAIL_INVALID");
      return false;
    }
    return true;
  };

  const handleRequestAccess = async (event) => {
    event.preventDefault();

    const emailValid = validateEmail(email);

    if (emailValid) {
      executeRecaptcha("requestInvite")
        .then((token) => {
          requestInvite({
            variables: {
              email,
              captchaToken: token,
            },
          });
          clearAllErrors();
        })
        .catch((error) => {
          Logger.error(error);
          toast.error(t(`error.CAPTCHA_FAILED`));
        });
    }
  };

  let errorContent = null;
  if (unspecificError) {
    const errorMessage = t(`error.${unspecificError}`);
    errorContent = <p className={styles.Error}>{errorMessage}</p>;
  }

  const requestResetPasswordForm = (
    <>
      <Helmet>
        <title>WisdomTree - {t(`auth.request_invite_title`)}</title>
      </Helmet>
      <section className={styles.container}>
        <h1 className={authStyles.authTitle}>
          {t("auth.request_invite_title")}
        </h1>
        <p className={styles.text}>{t("auth.request_invite_text")}</p>
      </section>
      <section className={styles.backContainer}>
        {successful ? (
          <p className={styles.success}>
            {t(`auth.request_invite_confirmation`)}
          </p>
        ) : (
          <form onSubmit={handleRequestAccess}>
            <Input
              type="text"
              name="email"
              placeholder={t("auth.signup_email_input")}
              autoComplete="email"
              spellCheck="false"
              iconleft="fas fa-envelope"
              help={emailError ? t(`error.${emailError}`) : null}
              isDanger={emailError !== null}
              value={email}
              onChange={handleEmailChange}
            />

            <div className="field">
              <div className="control text-align-center">
                <button
                  className={`button is-primary ${
                    requestInviteLoading ? "is-loading" : ""
                  }`}
                  type="submit"
                  disabled={emailError}
                >
                  {t("auth.request_invite_button")}
                </button>
              </div>
            </div>
            <p className={styles.privacyNote}>
              {t("auth.request_invite_privacy_note")}
            </p>

            {errorContent}
          </form>
        )}
      </section>
    </>
  );

  return <div>{requestResetPasswordForm}</div>;
};

RequestAccess.propTypes = propTypes;
RequestAccess.defaultProps = defaultProps;

export default RequestAccess;
