import React from "react";
import PropTypes from "prop-types";

import styles from "./RadioButtons.module.scss";

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  selectedValue: PropTypes.any,
  onChange: PropTypes.func,
};
const defaultProps = {
  onChange: () => {},
  selectedValue: null,
};

const RadioButtons = ({ name, options, selectedValue, onChange }) => {
  const optionsContent = options.map((option) => {
    return (
      <div
        className={`pretty p-default p-curve p-smooth ${styles.optionContainer}`}
        key={option.label}
      >
        <input
          type="radio"
          name={name}
          value={option.value}
          checked={selectedValue === option.value}
          onChange={onChange}
        />
        <div className={`state p-primary ${styles.labelContainer}`}>
          <label>{option.label}</label>
        </div>
      </div>
    );
  });

  return <div className={styles.container}>{optionsContent}</div>;
};

RadioButtons.propTypes = propTypes;
RadioButtons.defaultProps = defaultProps;

export default RadioButtons;
