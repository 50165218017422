/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import { useMutation } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
import styles from "./AddCardModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../../../UI/Modal/BaseModal/BaseModal";
import CardButton from "../../../UI/CardButton/CardButton";
import { CREATE_CARD, mergeCardsAfterCreate } from "../../../GraphQl/cards";
import ModalCloseButton from "../../../UI/Modal/ModalCloseButton/ModalCloseButton";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOpenManageCards: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  onOpenManageCards: () => {},
};

const useAddCardModal = ({ onOpen, onClose, onOpenManageCards }) => {
  const { t } = useTranslation();
  const [blockId, setBlockId] = useState(null);
  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose: (preventOnClose) => {
      if (!preventOnClose) onClose();
    },
  });

  useEffect(() => {
    if (isOpened) document.getElementById("buttonContainer").focus();
  }, [isOpened]);

  const [createReviewCard, { loading: createReviewCardLoading }] = useMutation(
    CREATE_CARD,
    {
      variables: {
        input: {
          blockId,
          type: "REVIEW",
        },
      },
      update: (cache, mutationResult) => {
        mergeCardsAfterCreate(blockId, cache, mutationResult);
      },
      onCompleted: () => {
        close();
      },
      onError: (err) => {
        Logger.error(err);
      },
    }
  );

  const [
    createQuestionCard,
    { loading: createQuestionCardLoading },
  ] = useMutation(CREATE_CARD, {
    variables: {
      input: {
        blockId,
        type: "QUESTION",
      },
    },
    update: (cache, mutationResult) => {
      mergeCardsAfterCreate(blockId, cache, mutationResult);
    },
    onCompleted: () => {
      close(true);
      onOpenManageCards(blockId);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!isOpened) return;
      close();
    },
    dependsOn: [close, isOpened],
  });

  const isLoading = createReviewCardLoading || createQuestionCardLoading;

  let content = null;
  if (isOpened) {
    const addReviewCardContent = (
      <div className={styles.reviewButtonContainer}>
        <CardButton
          title={t("wisdomtree.modal_add_card.title_review_card")}
          subtitle={t("wisdomtree.modal_add_card.subtitle_review_card")}
          icon="fa-eye"
          loading={createReviewCardLoading}
          onClick={() => {
            if (isLoading) return;
            document.activeElement.blur();
            createReviewCard();
          }}
        />
      </div>
    );
    const addQuestionCardContent = (
      <div className={styles.questionButtonContainer}>
        <CardButton
          title={t("wisdomtree.modal_add_card.title_question_card")}
          subtitle={t("wisdomtree.modal_add_card.subtitle_question_card")}
          icon="fa-question"
          loading={createQuestionCardLoading}
          onClick={() => {
            if (isLoading) return;
            document.activeElement.blur();
            createQuestionCard();
          }}
        />
      </div>
    );

    const innerContent = (
      <div id="buttonContainer" className={styles.container} tabIndex={-1}>
        {addReviewCardContent}
        {addQuestionCardContent}
      </div>
    );

    content = (
      <BaseModal show={isOpened} onCloseHandler={close}>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {t("wisdomtree.modal_add_card.title")}
            </p>
            <ModalCloseButton onClick={close} />
          </header>
          <section className="modal-card-body">{innerContent}</section>
          <footer className="modal-card-foot">
            <button className="button" type="button" onClick={close}>
              {t("wisdomtree.modal_add_card.cancel")}
            </button>
          </footer>
        </div>
      </BaseModal>
    );
  }

  const openModal = (blockIdToManageCardsFor) => {
    setBlockId(blockIdToManageCardsFor);
    open();
  };

  const closeModal = () => {
    close();
    setBlockId(null);
  };

  return { open: openModal, close: closeModal, content };
};

useAddCardModal.propTypes = propTypes;
useAddCardModal.defaultProps = defaultProps;

export default useAddCardModal;
