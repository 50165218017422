import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isMobile as isMobileDevice } from "react-device-detect";
import { animateScroll as scroll } from "react-scroll";

import styles from "./SharedWisdomTree.module.scss";
import useWindowSize from "../../Utils/UseWindowSize";
import Header from "../../Header/Header";
import Layout from "../Layout/Layout";
import useDarkMode from "../../Utils/UseDarkMode";
import useAuthentication from "../../Utils/UseAuthentication";
import {
  GET_SHARING_CONTENT,
  GET_SHARED_ENTRY_DETAILS,
} from "../../GraphQl/sharings";
import { useLazyQuery, useQuery } from "@apollo/client";
import Graph from "../Graph/GraphComponent";
import EntryBrowser from "../EntryBrowser/EntryBrowser";
import { getGraphReferencesFromEntryDetails } from "../../Utils/Utils";
import SharingInfo from "../SharingInfo/SharingInfo";
import ScrollDownButton from "../ScrollDownButton/ScrollDownButton";

const propTypesSharedWisdomTree = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    listen: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const defaultPropsSharedWisdomTree = {};

const SharedWisdomTree = ({ history, match, location }) => {
  const darkMode = useDarkMode();
  const authenticated = useAuthentication();

  const { windowWidth, windowHeight } = useWindowSize();
  const [dialogOpened, setDialogOpened] = useState(false);

  const [sharingId, setSharingId] = useState(match.params.sharingId);
  const [selectedEntry, setSelectedEntry] = useState(undefined);

  const onSelectEntry = (entry) => {
    if (entry == null || entry.id === sharingContent.rootEntryId) {
      history.push(`/shared/${sharingId}`);
      return;
    }

    history.push(`/shared/${sharingId}/entry/${entry.id}`);
  };

  const { loading: getContentLoading, data: getContentData } = useQuery(
    GET_SHARING_CONTENT,
    {
      variables: {
        sharingId: sharingId,
      },
    }
  );
  const sharingContent = getContentData ? getContentData.sharingContent : null;

  const [
    loadSharedEntryDetails,
    { loading: getSharedEntryDetailsLoading, data: getSharedEntryDetailsData },
  ] = useLazyQuery(GET_SHARED_ENTRY_DETAILS);
  let selectedEntryDetails = null;
  if (
    selectedEntry != null &&
    getSharedEntryDetailsData &&
    getSharedEntryDetailsData.sharedEntry.id === selectedEntry.id
  ) {
    selectedEntryDetails = getSharedEntryDetailsData.sharedEntry;
  }

  const graphReferences = getGraphReferencesFromEntryDetails(
    selectedEntryDetails
  );

  const updateEntrySelection = (content, paramsEntryId) => {
    if (!paramsEntryId) {
      if (content.rootEntryId) {
        const rootEntry = content.entries.find((entry) => {
          return entry.id === content.rootEntryId;
        });
        setSelectedEntry(rootEntry);
      } else {
        setSelectedEntry(null);
      }
    } else {
      const selEntry = content.entries.find((entry) => {
        return entry.id === paramsEntryId;
      });
      setSelectedEntry(selEntry);
    }
  };

  useEffect(() => {
    if (sharingContent) {
      updateEntrySelection(sharingContent, match.params.entryId);
    }
  }, [sharingContent]);

  useEffect(() => {
    if (sharingContent) {
      updateEntrySelection(sharingContent, match.params.entryId);
    }
  }, [match.params.entryId]);

  useEffect(() => {
    if (selectedEntry != null) {
      loadSharedEntryDetails({
        variables: {
          sharingId: sharingId,
          entryId: selectedEntry.id,
        },
      });
    }
  }, [selectedEntry, sharingId]);

  const loading = sharingContent == null || selectedEntry === undefined;

  const header = (
    <Header
      darkMode={darkMode}
      hasSearch={false}
      hasSettings={false}
      hasInviteFriends={false}
      isAdmin={false}
      hasLogout={false}
      hasBackToMyWisdomTree={authenticated}
      hasCreateAccount={!authenticated}
    />
  );

  let graph;
  let sharingInfo;
  let scrollDownButton;
  let entryBrowser;

  if (!loading) {
    graph = (
      <Graph
        key={`graph_${sharingId}_${darkMode ? "dark" : "light"}`}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        isTabletOrMobile={isMobileDevice}
        darkMode={darkMode}
        user={{
          image: sharingContent.author.image
            ? sharingContent.author.image.url
            : null,
          level: sharingContent.author.level,
          size: sharingContent.author.size,
        }}
        entries={sharingContent.entries}
        references={graphReferences}
        selectedEntryId={selectedEntry ? selectedEntry.id : null}
        sharingRootEntryId={sharingContent.rootEntryId}
        editMode={false}
        newMode={false}
        quizMode={false}
        newEntryDraft={{}}
        onSelectEntry={(entry) => onSelectEntry(entry)}
        onNewEntry={() => {}}
        onAttemptSelectEntry={() => {}}
        onUpdateEntry={() => {}}
        onUpdateNewEntryDraft={() => {}}
        onUpdateBlockEntry={() => {}}
      />
    );

    sharingInfo = (
      <SharingInfo
        user={sharingContent.author}
        onDialogOpenedChanged={(opened) => {
          setDialogOpened(opened);
        }}
      />
    );

    scrollDownButton = (
      <ScrollDownButton
        onScrollDownClicked={() => {
          scroll.scrollTo(windowHeight * 0.66 - 5, {
            smooth: true,
            duration: 400,
            ignoreCancelEvents: false,
          });
        }}
        quizMode={false}
        newMode={false}
      />
    );

    entryBrowser = (
      <EntryBrowser
        user={sharingContent.author}
        entries={sharingContent.entries}
        selectedEntry={selectedEntry}
        selectedEntryDetails={selectedEntryDetails}
        selectedEntryDetailsLoading={getSharedEntryDetailsLoading}
        sharingId={sharingId}
        sharingRootEntryId={sharingContent.rootEntryId}
        editMode={false}
        newMode={false}
        newEntryLoading={false}
        deleteEntryLoading={false}
        dialogOpened={dialogOpened}
        onDialogOpenedChanged={(opened) => {
          setDialogOpened(opened);
        }}
        graphContainerHeight={windowHeight * 0.66}
        onUpdateEntry={() => {}}
        newEntryDraft={null}
        onUpdateNewEntryDraft={() => {}}
        onCreateEntry={() => {}}
        onDeleteEntry={() => {}}
        onUpdateEntryImage={() => {}}
      />
    );
  }

  return (
    <Layout
      header={header}
      graph={graph}
      sharingInfo={sharingInfo}
      entryBrowser={entryBrowser}
      scrollDownButton={scrollDownButton}
      loading={loading}
    ></Layout>
  );
};

SharedWisdomTree.propTypes = propTypesSharedWisdomTree;
SharedWisdomTree.defaultProps = defaultPropsSharedWisdomTree;

export default SharedWisdomTree;
