import React from "react";
import PropTypes from "prop-types";

import styles from "./CommonEntryImages.module.scss";

const propTypesCommonEntryImages = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageSource: PropTypes.shape({
        url: PropTypes.string,
      }),
      userCount: PropTypes.integer,
      approved: PropTypes.bool,
      createdAt: PropTypes.string,
    })
  ).isRequired,
};
const defaultPropsCommonEntryImages = {};

const CommonEntryImages = ({ images }) => {
  const imageContent = images.map((image) => {
    return (
      <div className={styles.imageContainer}>
        <a href={image.imageSource.url}>
          <div className={styles.innerContainer}>
            <img
              className={styles.image}
              src={image.imageSource.url}
              alt={image.id}
            />
            <div className={styles.userCountContainer}>
              <span>{image.userCount}</span>
              <span className="icon">
                <i className="fas fa-users" />
              </span>
            </div>
          </div>
        </a>
      </div>
    );
  });

  return <div className={styles.imagesContainer}>{imageContent}</div>;
};

CommonEntryImages.propTypes = propTypesCommonEntryImages;
CommonEntryImages.defaultProps = defaultPropsCommonEntryImages;

export default CommonEntryImages;
