import { gql } from "@apollo/client";
import {
  FRAGMENT_COMPLETE_CATEGORY,
  FRAGMENT_COMPLETE_COLOR,
  FRAGMENT_COMPLETE_TAG,
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
  FRAGMENT_USER_UPDATE,
} from "./fragments";
import { mergeCategoryUpdates } from "./categories";
import { mergeTagUpdates } from "./tags";
import { mergeUserUpdates, processExpEvent } from "./user";
import { sortBy } from "lodash/collection";

const GET_COLORS = gql`
  query {
    colors {
      ...CompleteColor
    }
  }
  ${FRAGMENT_COMPLETE_COLOR}
`;

const UPDATE_COLOR = gql`
  mutation($id: ID!, $input: UpdateColorInput!) {
    updateColor(id: $id, input: $input) {
      code
      message
      updatedColor {
        ...CompleteColor
      }
    }
  }
  ${FRAGMENT_COMPLETE_COLOR}
`;

const CREATE_COLOR = gql`
  mutation($input: CreateColorInput!) {
    createColor(input: $input) {
      code
      message
      createdColor {
        ...CompleteColor
      }
      updatedUser {
        ...UserUpdate
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_COLOR}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const DELETE_COLOR = gql`
  mutation($id: ID!) {
    deleteColor(id: $id) {
      code
      message
      deletedColorIds
      updatedCategories {
        ...CompleteCategory
      }
      updatedTags {
        ...CompleteTag
      }
    }
  }
  ${FRAGMENT_COMPLETE_CATEGORY}
  ${FRAGMENT_COMPLETE_TAG}
`;

const mergeColorsAfterCreate = (cache, mutationResult) => {
  const { colors: currentData } = cache.readQuery({
    query: GET_COLORS,
  });
  const { createdColor } = mutationResult.data.createColor;
  const { updatedUser } = mutationResult.data.createColor;
  const { expEvent } = mutationResult.data.createColor;

  // add created entry to current data
  let updatedData = currentData.concat([createdColor]);
  updatedData = sortBy(updatedData, ["createdAt"]);

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }

  cache.writeQuery({
    query: GET_COLORS,
    data: { colors: updatedData },
  });
};

const mergeColorsAfterDelete = (cache, mutationResult) => {
  const { colors: currentData } = cache.readQuery({
    query: GET_COLORS,
  });

  const { deletedColorIds } = mutationResult.data.deleteColor;
  const { updatedCategories } = mutationResult.data.deleteColor;
  const { updatedTags } = mutationResult.data.deleteColor;

  // filter out deleted card ids
  const updatedData = currentData.filter(
    (e) => deletedColorIds.indexOf(e.id) === -1
  );

  // merge category updates
  if (updatedCategories.length > 0) {
    mergeCategoryUpdates(cache, updatedCategories);
  }

  // merge tag updates
  if (updatedTags.length > 0) {
    mergeTagUpdates(cache, updatedTags);
  }

  cache.writeQuery({
    query: GET_COLORS,
    data: { colors: updatedData },
  });
};

export {
  GET_COLORS,
  UPDATE_COLOR,
  CREATE_COLOR,
  DELETE_COLOR,
  mergeColorsAfterCreate,
  mergeColorsAfterDelete,
};
