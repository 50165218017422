import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

const propTypesuseDropZone = {
  accept: PropTypes.arrayOf(PropTypes.string),
  drop: PropTypes.func.isRequired,
};
const defaultPropsuseDropZone = {};

const useDropZone = ({ accept, drop }) => {
  const [element, setElement] = useState(null);

  const [dragCounter, setDragCounter] = useState(0);
  const [canDrop, setCanDrop] = useState(false);

  const handleElement = useCallback((node) => {
    setElement(node);
  }, []);

  const handleDragEnter = (e) => {
    setDragCounter((prevCount) => prevCount + 1);

    const acceptedTypes = [];
    for (const type of accept) {
      acceptedTypes.push(...getAcceptedTypes(type));
    }

    let canDrop = false;
    for (const type of e.dataTransfer.types) {
      canDrop = acceptedTypes.includes(type);
      if (canDrop) setCanDrop(canDrop);
    }

    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    setDragCounter((prevCount) => prevCount - 1);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    setDragCounter(0);
    setCanDrop(false);

    const item = {};
    let files = null;

    if (
      accept.includes(TYPE_FILE) &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length > 0
    ) {
      files = Array.from(e.dataTransfer.files);
    }

    let urls = [];
    for (const i of e.dataTransfer.items) {
      if (accept.includes(TYPE_URL)) {
        const acceptedTypes = getAcceptedTypes(TYPE_URL);
        const isURL = acceptedTypes.includes(i.type);

        if (isURL) {
          const data = e.dataTransfer.getData(i.type);
          const extractedUrls = data ? data.split("\n") : null;
          if (extractedUrls) urls.push(...extractedUrls);
        }
      }
    }

    let html = null;
    for (const i of e.dataTransfer.items) {
      if (accept.includes(TYPE_HTML)) {
        const acceptedTypes = getAcceptedTypes(TYPE_HTML);
        const isHTML = acceptedTypes.includes(i.type);

        if (isHTML) {
          const data = e.dataTransfer.getData(i.type);
          if (data) html = data;
        }
      }
    }

    item.files = files;
    if (urls.length > 0) item.urls = urls;
    if (html) item.html = html;

    if (item.files || item.urls || item.html) {
      drop(item);
    }

    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    element.addEventListener("dragenter", handleDragEnter);
    element.addEventListener("dragleave", handleDragLeave);
    element.addEventListener("dragover", handleDragOver);
    element.addEventListener("drop", handleDrop);

    return () => {
      element.removeEventListener("dragenter", handleDragEnter);
      element.removeEventListener("dragleave", handleDragLeave);
      element.removeEventListener("dragover", handleDragOver);
      element.removeEventListener("drop", handleDrop);
    };
  }, [element]);

  return [{ isOver: dragCounter > 0, canDrop }, handleElement];
};

useDropZone.propTypes = propTypesuseDropZone;
useDropZone.defaultProps = defaultPropsuseDropZone;

export default useDropZone;

export const TYPE_FILE = "__NATIVE_FILE__";
export const TYPE_URL = "__NATIVE_URL__";
export const TYPE_TEXT = "__NATIVE_TEXT__";
export const TYPE_HTML = "__NATIVE_HTML__";

export const getAcceptedTypes = (nativeType) => {
  switch (nativeType) {
    case TYPE_FILE:
      return ["Files"];
    case TYPE_URL:
      return ["Url", "text/uri-list"];
    case TYPE_TEXT:
      return ["Text", "text/plain"];
    case TYPE_HTML:
      return ["Html", "text/html"];
    default:
      return nativeType;
  }
};
