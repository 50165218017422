/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import ReactQuill, { Quill } from "react-quill";
import Delta from "quill-delta";
import { isEqual } from "lodash";

import OpenLinkModule from "./OpenLinkModule/OpenLinkModule";
import FixEnterNotWorkingModule from "./FixEnterNotWorkingModule/FixEnterNotWorkingModule";
import KeyBoardBindings from "./KeyboardBindings/KeyboardBindings";
import PreventImagesModule from "./PreventImagesModule/PreventImagesModule";
import RemoveTextColorOnCopy from "./RemoveTextColorOnCopy/RemoveTextColorOnCopy";

Quill.register("modules/openLink", OpenLinkModule);
Quill.register("modules/fixEnter", FixEnterNotWorkingModule);
Quill.register("modules/preventImages", PreventImagesModule);
Quill.register("modules/removeTextColor", RemoveTextColorOnCopy);

const propTypes = {
  editMode: PropTypes.bool,
  elementId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  elementContent: PropTypes.object,
  hintText: PropTypes.string,
  stylesClassName: PropTypes.string,
  focusInitially: PropTypes.bool,
  onContentChanged: PropTypes.func,
  tabDisabled: PropTypes.bool,
};

const defaultProps = {
  editMode: false,
  elementContent: null,
  stylesClassName: null,
  hintText: null,
  focusInitially: false,
  onContentChanged: () => {},
  tabDisabled: false,
};

class ContentQuillEditor extends React.Component {
  modules = {
    toolbar: [
      // [{ header: [1, 2, 3, false] }],
      [{ size: ["small", false, "large"] }],
      ["bold", "italic", "underline", { color: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "code-block", "formula"],
      ["clean"],
    ],
    keyboard: {
      bindings: {
        ...KeyBoardBindings,
        tab: {
          key: 9,
          handler: (range) => {
            // imitated default quill behavior to be able to turn tab intendation on and off
            if (this.props.tabDisabled) return true;

            this.quillRef.history.cutoff();
            let delta = new Delta()
              .retain(range.index)
              .delete(range.length)
              .insert("\t");
            this.quillRef.updateContents(delta, Quill.sources.USER);
            this.quillRef.history.cutoff();
            this.quillRef.setSelection(range.index + 1, Quill.sources.SILENT);
          },
        },
      },
    },
    openLink: true,
    fixEnter: true,
    preventImages: true,
    removeTextColor: true,
    syntax: true,
  };

  formats = [
    "bold",
    "color",
    "italic",
    "link",
    "size",
    "underline",
    "list",
    "code-block",
    "formula",
  ];

  constructor(props) {
    super(props);
    this.quillRef = null; // Quill instance
    this.handleChange = this.handleChange.bind(this);
    this.reactQuillRef = null;
    this.state = { loading: true };
  }

  componentDidMount() {
    this.attachQuillRefs();

    let initialContent = { ops: [] };
    if (this.props.elementContent && this.props.elementContent.ops) {
      initialContent = this.props.elementContent;
    }
    this.updatePlaceholderAccordingToEditMode();
    this.updateContents(initialContent);
    this.quillRef.setSelection(null);

    if (this.props.focusInitially) {
      setTimeout(() => {
        if (this.props.editMode) this.quillRef.focus();
      }, 25);
    }
  }

  componentDidUpdate(prevProps) {
    this.attachQuillRefs();

    if (prevProps.editMode !== this.props.editMode) {
      this.updatePlaceholderAccordingToEditMode();
    }

    if (prevProps.elementId !== this.props.elementId) {
      this.updateStateForElement();
    }
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  focusEditor = () => {
    this.quillRef.focus();
  };

  handleChange() {
    if (this.state.loading) return;

    const content = this.quillRef.getContents();

    // check if its empty
    if (
      content.ops.length === 1 &&
      this.quillRef.getText().trim().length === 0
    ) {
      // console.log('is empty');
      this.props.onContentChanged(null);
      return;
    }

    if (
      !isEqual(
        JSON.stringify(this.props.elementContent),
        JSON.stringify(content)
      )
    ) {
      this.props.onContentChanged(content);
      // console.log('onContentChanged');
    }
  }

  updatePlaceholderAccordingToEditMode() {
    this.quillRef.root.dataset.placeholder = this.props.editMode
      ? this.props.hintText
      : "";
  }

  updateStateForElement() {
    if (this.props.elementContent && this.props.elementContent.ops) {
      this.updateContents(this.props.elementContent);
    } else {
      this.updateContents({ ops: [] });
    }
    this.quillRef.setSelection(null);
  }

  updateContents(contents) {
    this.setState({ loading: true });
    setTimeout(this.finishUpdatingContents.bind(this, contents), 0);
  }

  finishUpdatingContents(contents) {
    // setting to empty array first to improve performance
    this.quillRef.setContents({ ops: [] });
    this.quillRef.setContents(contents);
    this.setState({ loading: false });
  }

  render() {
    const emptyDefault = { ops: [] };
    return (
      <div
        className={`${this.props.stylesClassName} ${
          this.props.editMode ? "editMode" : "readMode"
        } ${this.state.loading ? "hidden" : ""}`}
      >
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          theme="bubble"
          modules={this.modules}
          formats={this.formats}
          defaultValue={emptyDefault}
          onChange={this.handleChange}
          readOnly={!this.props.editMode}
        />
      </div>
    );
  }
}

ContentQuillEditor.propTypes = propTypes;
ContentQuillEditor.defaultProps = defaultProps;

export default ContentQuillEditor;
