import React from "react";
import PropTypes from "prop-types";

import styles from "./FeatureVideo.module.scss";
import tutorialStyles from "../../TutorialModal.module.scss";
import VideoPlayer from "../../../../VideoPlayer/VideoPlayer";

const propTypesFeatureVideo = {
  videoUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  paused: PropTypes.bool,
};
const defaultPropsFeatureVideo = {
  paused: false,
};

const FeatureVideo = ({ videoUrl, text, paused }) => {
  return (
    <div className={styles.container}>
      <div className={`box ${styles.videoContainer}`}>
        <VideoPlayer src={videoUrl} paused={paused} />
      </div>
      <span className={`${tutorialStyles.text}`}>{text}</span>
    </div>
  );
};

FeatureVideo.propTypes = propTypesFeatureVideo;
FeatureVideo.defaultProps = defaultPropsFeatureVideo;

export default FeatureVideo;
