import React, { useState } from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import "tippy.js/animations/shift-away-subtle.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/translucent.css";

import styles from "./DropdownTippy.module.scss";
import DropdownItems from "./DropdownItems/DropdownItems";
import useDarkMode from "../../Utils/UseDarkMode";

const propTypesDropdownTippy = {
  visible: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func,
  children: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.string,
      text: PropTypes.string,
      content: PropTypes.element,
      tooltip: PropTypes.string,
    })
  ).isRequired,
  onItemClicked: PropTypes.func.isRequired,
  flipItems: PropTypes.bool,
};
const defaultPropsDropdownTippy = {
  onClickOutside: () => {},
  flipItems: false,
};

const DropdownTippy = ({
  visible,
  onClickOutside,
  children,
  items,
  onItemClicked,
  flipItems,
}) => {
  const [flipped, setFlipped] = useState(false);
  const darkMode = useDarkMode();

  const itemsProcessedOrder = flipped ? [...items].reverse() : items;

  const menuContent = (
    <div className={styles.dropdownContainer}>
      <DropdownItems
        items={itemsProcessedOrder}
        onItemClicked={onItemClicked}
      />
    </div>
  );

  return (
    <Tippy
      theme={darkMode ? "translucent" : "light-border"}
      className="dropdown"
      interactive
      visible={visible}
      animation="shift-away-subtle"
      content={menuContent}
      appendTo="parent"
      maxWidth="none"
      arrow={false}
      placement="bottom-start"
      offset={[0, 4]}
      popperOptions={
        flipItems
          ? {
              modifiers: [
                {
                  name: "topLogger",
                  enabled: true,
                  phase: "main",
                  fn({ state }) {
                    if (state.placement === "top-start") {
                      setFlipped(true);
                    } else if (state.placement === "bottom-start") {
                      setFlipped(false);
                    }
                  },
                },
              ],
            }
          : {}
      }
      onClickOutside={onClickOutside}
    >
      {children}
    </Tippy>
  );
};

DropdownTippy.propTypes = propTypesDropdownTippy;
DropdownTippy.defaultProps = defaultPropsDropdownTippy;

export default DropdownTippy;
