import { gql } from "@apollo/client";

import {
  FRAGMENT_COMPLETE_COMMON_ENTRY_VARIANT,
  FRAGMENT_COMPLETE_COMMON_ENTRY_WITH_VARIANTS,
} from "./fragments";

const GET_COMMON_ENTRIES_WITH_VARIANTS = gql`
  query {
    commonEntries {
      ...CompleteCommonEntryWithVariants
    }
  }
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_WITH_VARIANTS}
`;

const GET_COMMON_ENTRIES_FILTERED_WITH_VARIANTS = gql`
  query($input: FilterCommonEntriesInput) {
    commonEntriesFiltered(input: $input) {
      ...CompleteCommonEntryWithVariants
    }
  }
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_WITH_VARIANTS}
`;

const GET_COMMON_ENTRY_WITH_VARIANTS = gql`
  query($id: ID!) {
    commonEntry(id: $id) {
      ...CompleteCommonEntryWithVariants
    }
  }
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_WITH_VARIANTS}
`;

const GET_ENTRY_NAME_SUGGESTIONS = gql`
  query($input: EntryNameSuggestionsInput!) {
    entryNameSuggestions(input: $input) {
      name
      userCount
    }
  }
`;

const CREATE_COMMON_ENTRY_VARIANT = gql`
  mutation($input: CreateCommonEntryVariantInput!) {
    createCommonEntryVariant(input: $input) {
      code
      message
      createdCommonEntryVariant {
        ...CompleteCommonEntryVariant
      }
      createdCommonEntry {
        ...CompleteCommonEntryWithVariants
      }
      updatedCommonEntry {
        ...CompleteCommonEntryWithVariants
      }
    }
  }
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_WITH_VARIANTS}
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_VARIANT}
`;

const UPDATE_COMMON_ENTRY_VARIANT = gql`
  mutation($id: ID!, $input: UpdateCommonEntryVariantInput!) {
    updateCommonEntryVariant(id: $id, input: $input) {
      code
      message
      updatedCommonEntryVariant {
        ...CompleteCommonEntryVariant
      }
      updatedCommonEntry {
        ...CompleteCommonEntryWithVariants
      }
    }
  }
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_WITH_VARIANTS}
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_VARIANT}
`;

const DELETE_COMMON_ENTRY_VARIANT = gql`
  mutation($id: ID!) {
    deleteCommonEntryVariant(id: $id) {
      code
      message
      deletedId
      deletedCommonEntryIds
      updatedCommonEntries {
        ...CompleteCommonEntryWithVariants
      }
    }
  }
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_WITH_VARIANTS}
`;

const MERGE_COMMON_ENTRIES = gql`
  mutation($commonEntryId1: ID!, $commonEntryId2: ID!) {
    mergeCommonEntries(
      commonEntryId1: $commonEntryId1
      commonEntryId2: $commonEntryId2
    ) {
      code
      message
      deletedCommonEntryId
      updatedCommonEntry {
        ...CompleteCommonEntryWithVariants
      }
      updatedCommonEntryVariants {
        ...CompleteCommonEntryVariant
      }
    }
  }
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_WITH_VARIANTS}
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_VARIANT}
`;

const UPDATE_COMMON_ENTRIES = gql`
  mutation {
    updateCommonEntries {
      code
      message
    }
  }
`;

const mergeCommonEntriesAfterCreate = (cache, mutationResult) => {
  const { commonEntries: currentData } = cache.readQuery({
    query: GET_COMMON_ENTRIES_WITH_VARIANTS,
  });

  const { createdCommonEntry } = mutationResult.data.createCommonEntryVariant;

  if (createdCommonEntry) {
    // add created entry to current data
    const updatedData = currentData.concat([createdCommonEntry]);
    cache.writeQuery({
      query: GET_COMMON_ENTRIES_WITH_VARIANTS,
      data: { commonEntries: updatedData },
    });
  }
};

const mergeCommonEntriesAfterDelete = (cache, mutationResult) => {
  const { commonEntries: currentData } = cache.readQuery({
    query: GET_COMMON_ENTRIES_WITH_VARIANTS,
  });

  const {
    deletedCommonEntryIds,
  } = mutationResult.data.deleteCommonEntryVariant;
  const { updatedCommonEntries } = mutationResult.data.deleteCommonEntryVariant;

  if (updatedCommonEntries) {
    const updatedData = currentData.map((ce) => {
      const updatedCE = updatedCommonEntries.find((uce) => uce.id === ce.id);
      return updatedCE || ce;
    });
    cache.writeQuery({
      query: GET_COMMON_ENTRIES_WITH_VARIANTS,
      data: { commonEntries: updatedData },
    });
  }

  if (deletedCommonEntryIds) {
    const updatedData = currentData.filter(
      (ce) => !deletedCommonEntryIds.includes(ce.id)
    );
    cache.writeQuery({
      query: GET_COMMON_ENTRIES_WITH_VARIANTS,
      data: { commonEntries: updatedData },
    });
  }
};

const mergeCommonEntriesAfterMerge = (cache, mutationResult) => {
  const { commonEntries: currentData } = cache.readQuery({
    query: GET_COMMON_ENTRIES_WITH_VARIANTS,
  });

  const { deletedCommonEntryId } = mutationResult.data.mergeCommonEntries;
  const { updatedCommonEntry } = mutationResult.data.mergeCommonEntries;

  if (updatedCommonEntry) {
    const updatedData = currentData.map((ce) => {
      return updatedCommonEntry.id === ce.id ? updatedCommonEntry : ce;
    });
    cache.writeQuery({
      query: GET_COMMON_ENTRIES_WITH_VARIANTS,
      data: { commonEntries: updatedData },
    });
  }

  if (deletedCommonEntryId) {
    const updatedData = currentData.filter(
      (ce) => deletedCommonEntryId !== ce.id
    );
    cache.writeQuery({
      query: GET_COMMON_ENTRIES_WITH_VARIANTS,
      data: { commonEntries: updatedData },
    });
  }
};

export {
  GET_COMMON_ENTRIES_WITH_VARIANTS,
  GET_COMMON_ENTRIES_FILTERED_WITH_VARIANTS,
  GET_COMMON_ENTRY_WITH_VARIANTS,
  GET_ENTRY_NAME_SUGGESTIONS,
  CREATE_COMMON_ENTRY_VARIANT,
  UPDATE_COMMON_ENTRY_VARIANT,
  DELETE_COMMON_ENTRY_VARIANT,
  MERGE_COMMON_ENTRIES,
  UPDATE_COMMON_ENTRIES,
  mergeCommonEntriesAfterCreate,
  mergeCommonEntriesAfterDelete,
  mergeCommonEntriesAfterMerge,
};
