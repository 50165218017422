import React from "react";
import PropTypes from "prop-types";

import styles from "./NewQuest.module.scss";
import tutorialStyles from "../../TutorialModal.module.scss";
import VideoPlayer from "../../../../VideoPlayer/VideoPlayer";
import { useTranslation } from "react-i18next";

const propTypesNewQuest = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};
const defaultPropsNewQuest = {};

const NewQuest = ({ title, subtitle }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.objectiveContainer}>
        <span className={`icon ${styles.objectiveIcon}`}>
          <i className={`fas fa-exclamation-circle`} />
        </span>
        <span className={styles.objective}>
          {t("wisdomtree.quests.objective")}
        </span>
      </div>

      <span className={`${styles.title}`}>{title}</span>
      <span className={`${styles.subtitle}`}>{subtitle}</span>
    </div>
  );
};

NewQuest.propTypes = propTypesNewQuest;
NewQuest.defaultProps = defaultPropsNewQuest;

export default NewQuest;
