/* eslint-disable no-return-assign */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./SearchResults.module.scss";
import SearchResultEntry from "./SearchResultEntry/SearchResultEntry";
import SearchResultBlock from "./SearchResultBlock/SearchResultBlock";
import Spinner from "../../../UI/Spinner/Spinner";
import { useHotkey } from "../../../Utils/Hotkeys";

const propTypesSearchResults = {
  searchResults: PropTypes.array,
  loading: PropTypes.bool,
  onClickResult: PropTypes.func,
};
const defaultPropsSearchResults = {
  searchResults: null,
  loading: true,
  onClickResult: () => {},
};

const SearchResults = ({ loading, searchResults, onClickResult }) => {
  const { t } = useTranslation();
  const itemsRef = useRef([]);

  useEffect(() => {
    if (searchResults)
      itemsRef.current = itemsRef.current.slice(0, searchResults.length);
  }, [searchResults]);

  useHotkey({
    keyNames: ["ArrowDown", "ArrowUp"],
    callback: (e) => {
      const isItemFocused = document.activeElement.className.includes(
        styles.SearchResultItem
      );

      if (isItemFocused) {
        const activeElementId = document.activeElement.id;

        const activeElementIndex = itemsRef.current.findIndex(
          (item) => item.id === activeElementId
        );

        let nextIndex;
        if (e.key === "ArrowDown") {
          nextIndex =
            activeElementIndex < itemsRef.current.length - 1
              ? activeElementIndex + 1
              : 0;
        } else {
          nextIndex =
            activeElementIndex > 0
              ? activeElementIndex - 1
              : itemsRef.current.length - 1;
        }

        itemsRef.current[nextIndex].focus();
      } else if (e.key === "ArrowDown") {
        itemsRef.current[0].focus();
      } else {
        itemsRef.current[itemsRef.current.length - 1].focus();
      }
      e.preventDefault();
    },
    dependsOn: [itemsRef.current],
  });

  let content = null;

  if (searchResults) {
    if (searchResults.length >= 1) {
      content = searchResults.map((result, i) => {
        if (result.entry) {
          const resultId = `entry_${result.entry.id}`;
          return (
            <SearchResultEntry
              key={resultId}
              resultId={resultId}
              ref={(el) => (itemsRef.current[i] = el)}
              entry={result.entry}
              onClickHandler={onClickResult}
            />
          );
        }
        if (result.block) {
          const resultId = `block_${result.block.id}`;
          return (
            <SearchResultBlock
              key={resultId}
              resultId={resultId}
              ref={(el) => (itemsRef.current[i] = el)}
              block={result.block}
              onClickHandler={onClickResult}
            />
          );
        }
        return null;
      });
    } else {
      content = (
        <p className={styles.TextNoResults}>
          {t("wisdomtree.header.search_no_results")}
        </p>
      );
    }
  } else if (loading) {
    content = (
      <div id={styles.SpinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  return content;
};

SearchResults.propTypes = propTypesSearchResults;
SearchResults.defaultProps = defaultPropsSearchResults;

export default SearchResults;
