/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
import styles from "./SetCategoryModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import {
  DELETE_CATEGORY,
  GET_CATEGORIES,
  mergeCategoriesAfterDelete,
} from "../../../GraphQl/categories";
import CategoryCard from "./CategoryCard/CategoryCard";
import Spinner from "../../Spinner/Spinner";
import {
  mergeEntriesAfterUpdate,
  UPDATE_ENTRY,
} from "../../../GraphQl/entries";
import useEditCategoryModal from "../EditCategoryModal/EditCategoryModal";
import useDeleteCategoryModal from "../DeleteCategoryModal/DeleteCategoryModal";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useSetCategoryModal = ({ onOpen, onClose }) => {
  const { t } = useTranslation();

  const [nestedDialogOpened, setNestedDialogOpened] = useState(false);
  const [entityId, setEntityId] = useState(null);
  const [entityType, setEntityType] = useState(null);
  const [loadingCategoryId, setLoadingCategoryId] = useState(null);
  const manageOnly = entityId == null;

  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose,
    hasNestedOpened: nestedDialogOpened,
  });

  const openModal = (entityId, entityType) => {
    setEntityId(entityId);
    setEntityType(entityType);
    open();
  };

  const closeModal = () => {
    close();
    setEntityId(null);
    setEntityType(null);
    setLoadingCategoryId(null);
  };

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!isOpened) return;
      if (nestedDialogOpened) return;
      close();
    },
    dependsOn: [close, nestedDialogOpened, isOpened],
  });

  const [
    loadCategories,
    { loading: getCategoriesLoading, data: getCategoriesData },
  ] = useLazyQuery(GET_CATEGORIES);
  const categories = getCategoriesData ? getCategoriesData.categories : null;

  useEffect(() => {
    if (isOpened) loadCategories();
  }, [isOpened]);

  const [updateEntry, { loading: updateEntryLoading }] = useMutation(
    UPDATE_ENTRY,
    {
      update: mergeEntriesAfterUpdate,
      onError: (err) => {
        Logger.error(err);
        setLoadingCategoryId(null);
      },
      onCompleted: () => {
        closeModal();
      },
    }
  );

  const [deleteCategory, { loading: deleteCategoryLoading }] = useMutation(
    DELETE_CATEGORY,
    {
      update: mergeCategoriesAfterDelete,
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        closeDeleteCategoryModal();
      },
    }
  );

  const {
    open: openDeleteCategoryModal,
    close: closeDeleteCategoryModal,
    content: deleteCategoryContent,
  } = useDeleteCategoryModal({
    onOpen: () => setNestedDialogOpened(true),
    onClose: () => setNestedDialogOpened(false),
    onDeleteCategory: (category) => {
      deleteCategory({
        variables: {
          id: category.id,
        },
      });
    },
    loading: deleteCategoryLoading,
    nested: true,
  });

  const {
    open: openEditCategoryModal,
    content: editCategoryModalContent,
  } = useEditCategoryModal({
    onOpen: () => setNestedDialogOpened(true),
    onClose: () => {
      setNestedDialogOpened(false);
    },
    nested: true,
  });

  const onCategorySelected = (category) => {
    let newCategoryId = category ? category.id : null;

    setLoadingCategoryId(newCategoryId ? newCategoryId : "None");

    if (entityType === "entry") {
      updateEntry({
        variables: {
          id: entityId,
          input: {
            categoryId: newCategoryId,
          },
        },
      });
    }
  };

  let content = null;
  if (isOpened) {
    let listContent = null;
    if (categories != null) {
      listContent = [
        ...categories
          .filter((category) => category.type.toLowerCase() === entityType)
          .map((category) => (
            <CategoryCard
              key={category.id}
              category={category}
              onCategorySelected={onCategorySelected}
              onEditCategory={() =>
                openEditCategoryModal(category.type.toLowerCase(), category)
              }
              onDeleteCategory={() => {
                openDeleteCategoryModal(category);
              }}
              isLoading={loadingCategoryId === category.id}
              selectable={!manageOnly}
            />
          )),
      ];
      if (!manageOnly) {
        listContent = [
          <CategoryCard
            key="None"
            category={null}
            onCategorySelected={onCategorySelected}
            isLoading={loadingCategoryId === "None"}
          />,
          ...listContent,
        ];
      }
    } else if (getCategoriesLoading) {
      listContent = (
        <div className={styles.loadingContainer}>
          <Spinner />
        </div>
      );
    }

    const newCategoryButton = (
      <div
        className={`${styles.newCategoryButton} button is-light`}
        data-id="button.new-category"
        onClick={() => {
          openEditCategoryModal(entityType, null);
        }}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          // enter key
          if (e.keyCode === 13) {
            openEditCategoryModal(entityType, null);
          }
        }}
      >
        <span className={`icon ${styles.iconGray}`}>
          <i className="fas fa-plus" />
        </span>
        <span>{t("wisdomtree.modal_set_category.new_category")}</span>
      </div>
    );

    let bodyContent = (
      <div className={styles.bodyContainer}>
        <div className={styles.listContainer}>{listContent}</div>
        {newCategoryButton}
      </div>
    );

    content = (
      <>
        <BaseModal show={isOpened} onCloseHandler={closeModal}>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                {manageOnly
                  ? t("wisdomtree.modal_set_category.title_manage")
                  : t("wisdomtree.modal_set_category.title")}
              </p>
              <ModalCloseButton onClick={closeModal} />
            </header>
            <section className="modal-card-body">{bodyContent}</section>
            <footer className="modal-card-foot">
              <button className="button" type="button" onClick={closeModal}>
                {manageOnly
                  ? t("wisdomtree.modal_set_category.done")
                  : t("wisdomtree.modal_set_category.cancel")}
              </button>
            </footer>
          </div>
        </BaseModal>
        {editCategoryModalContent}
        {deleteCategoryContent}
      </>
    );
  }

  return { open: openModal, close: closeModal, content };
};

useSetCategoryModal.propTypes = propTypes;
useSetCategoryModal.defaultProps = defaultProps;

export default useSetCategoryModal;
