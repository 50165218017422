import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
import styles from "./ChangeParentModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import SearchInput from "../../SearchInput/SearchInput";
import {
  SEARCH_ENTRIES,
  SEARCH_CHANGE_PARENT_SUGGESTIONS,
} from "../../../GraphQl/search";
import Spinner from "../../Spinner/Spinner";
import SearchResultEntry from "../../SearchResultEntry/SearchResultEntry";
import { useMobileOrTablet } from "../../../Utils/Responsive";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import SearchWithSuggestions from "../../SearchWithSuggestions/SearchWithSuggestions";
import {
  mergeEntriesAfterUpdate,
  UPDATE_ENTRY_POSITION_AND_PARENT,
} from "../../../GraphQl/entries";
import { clickOnEntryInGraph } from "../../../Utils/Utils";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  nested: PropTypes.bool,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  nested: false,
};

const useChangeParentModal = ({ onOpen, onClose, nested }) => {
  const { isOpened, open, close } = useBaseModal({ onOpen, onClose });
  const [entry, setEntry] = useState(null);

  let content = null;
  if (isOpened) {
    content = (
      <ChangeParentModal
        show={isOpened}
        onCloseHandler={close}
        entry={entry}
        nested={nested}
      />
    );
  }

  const openModal = (entryToChangeParent) => {
    setEntry(entryToChangeParent);
    open();
  };

  const closeModal = () => {
    close();
    setEntry(null);
  };

  return { open: openModal, close: closeModal, content };
};

useChangeParentModal.propTypes = propTypes;
useChangeParentModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  entry: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onCloseHandler: PropTypes.func.isRequired,
};

const ChangeParentModal = ({ show, entry, onCloseHandler }) => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState(null);

  const [updateEntry, { loading: updateEntryLoading }] = useMutation(
    UPDATE_ENTRY_POSITION_AND_PARENT,
    {
      update: mergeEntriesAfterUpdate,
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        onCloseHandler();
        clickOnEntryInGraph(entry.id, 50);
      },
    }
  );

  const onSubmit = () => {
    let parentEntryId = selection.entity.id;
    if (selection.entity.id === "-1") parentEntryId = null;

    updateEntry({
      variables: {
        id: entry.id,
        input: {
          parentEntryId,
        },
      },
    });
  };

  const {
    loading: searchSuggestionsLoading,
    data: searchSuggestionsData,
  } = useQuery(SEARCH_CHANGE_PARENT_SUGGESTIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      entryId: entry.id,
    },
    onError: (err) => {
      Logger.error(err);
    },
  });
  const searchSuggestionEntries = searchSuggestionsData
    ? searchSuggestionsData.searchSuggestionsChangeParent.map((entry) => {
        return { entry };
      })
    : null;

  if (searchSuggestionEntries && entry.parentEntryId !== null)
    searchSuggestionEntries.unshift({
      entry: { id: "-1", name: t("wisdomtree.home") },
    });

  const [search, { loading: searchLoading, data: searchData }] = useLazyQuery(
    SEARCH_ENTRIES,
    {
      fetchPolicy: "cache-and-network",
      onError: (err) => {
        Logger.error(err);
      },
    }
  );
  let searchResults = searchData ? searchData.search.results : null;
  if (searchResults) {
    searchResults = searchResults.filter(
      (result) =>
        result.entry.id !== entry.id &&
        (entry.parentEntryId == null || result.entry.id !== entry.parentEntryId)
    );
  }

  const content = (
    <SearchWithSuggestions
      results={searchResults}
      suggestions={searchSuggestionEntries}
      resultsLoading={searchLoading}
      suggestionsLoading={searchSuggestionsLoading}
      onSearch={(text) => {
        search({
          variables: {
            input: {
              query: text.toLowerCase(),
              searchEntries: true,
              searchBlocks: false,
              excludeChildrenOfEntryId: entry.id,
            },
          },
          context: {
            debounceKey: `search`,
            debounceTimeout: 200,
          },
        });
      }}
      onSelect={(selection) => {
        setSelection(selection);
      }}
      onSubmit={(selection) => {
        onSubmit();
      }}
      onCancel={() => {
        onCloseHandler();
      }}
      searchPlaceholder={t("wisdomtree.modal_change_parent.search_placeholder")}
      noResultsMessage={t("wisdomtree.modal_change_parent.search_no_results")}
    />
  );

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_change_parent.title")}
          </p>
          <ModalCloseButton
            onClick={onCloseHandler}
            disabled={updateEntryLoading}
          />
        </header>
        <section className={`modal-card-body ${styles.body}`}>
          {content}
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-primary ${
              updateEntryLoading ? `is-loading` : ``
            }`}
            type="submit"
            disabled={selection == null}
            onClick={() => {
              onSubmit();
            }}
          >
            {t("wisdomtree.modal_change_parent.move")}
          </button>
          <button
            className="button"
            type="button"
            onClick={onCloseHandler}
            disabled={updateEntryLoading}
          >
            {t("wisdomtree.modal_change_parent.cancel")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

ChangeParentModal.propTypes = propTypesModal;

export default useChangeParentModal;
