import { gql } from "@apollo/client";
import {
  FRAGMENT_COMPLETE_QUEST,
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
} from "./fragments";
import { mergeUserUpdates, processExpEvent } from "./user";

const START_NEXT_QUEST = gql`
  mutation {
    startNextQuest {
      code
      message
      updatedUser {
        id
        nextQuest
        currentQuest {
          ...CompleteQuest
        }
      }
    }
  }
  ${FRAGMENT_COMPLETE_QUEST}
`;

const COMPLETE_CURRENT_QUEST = gql`
  mutation {
    completeCurrentQuest {
      code
      message
      updatedUser {
        id
        nextQuest
        currentQuest {
          ...CompleteQuest
        }
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_QUEST}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const mergeUserAfterStartNextQuest = (cache, mutationResult) => {
  mergeUserUpdates(cache, mutationResult.data.startNextQuest.updatedUser);
};

const mergeUserAfterCompleteCurrentQuest = (cache, mutationResult) => {
  const { updatedUser } = mutationResult.data.completeCurrentQuest;
  const { expEvent } = mutationResult.data.completeCurrentQuest;

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }
};

export {
  START_NEXT_QUEST,
  COMPLETE_CURRENT_QUEST,
  mergeUserAfterStartNextQuest,
  mergeUserAfterCompleteCurrentQuest,
};
