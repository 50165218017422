import { useEffect, useState } from "react";

import AwesomeDebouncePromise from "awesome-debounce-promise";

const propTypesUseWindowSize = {};
const defaultPropsUseWindowSize = {};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const updateWindowSizeDebounced = AwesomeDebouncePromise(setWindowSize, 150);

  const handleWindowResized = () => {
    updateWindowSizeDebounced({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResized);
    return () => {
      window.removeEventListener("resize", handleWindowResized);
    };
  }, []);

  return { windowWidth: windowSize.width, windowHeight: windowSize.height };
};

useWindowSize.propTypes = propTypesUseWindowSize;
useWindowSize.defaultProps = defaultPropsUseWindowSize;

export default useWindowSize;
