class OpenLinkModule {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;
    this.container = document.querySelector(options.container);

    quill.container.addEventListener("click", (evt) => {
      if (evt.target.tagName === "A") {
        const format = quill.getFormat();
        if (format.link) {
          window.open(format.link, "_blank");
        }
      }
    });
  }
}

export default OpenLinkModule;
