import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import Logger from "js-logger";
import { Helmet } from "react-helmet";

import * as Constants from "../../Constants";
import { useHotkey } from "../../../Utils/Hotkeys";
import {
  UPDATE_BLOCK,
  mergeBlocksAfterUpdate,
  getOptimisticResponseForMoveBlock,
} from "../../../GraphQl/blocks";
import Entry from "../Entry/Entry";
import SelectedEntryHeader from "./SelectedEntryHeader/SelectedEntryHeader";
import entryBrowserStyles from "../EntryBrowser.module.scss";
import styles from "./EntryBrowserSelectedEntry.module.scss";
import SelectedEntryContent from "./SelectedEntryContent/SelectedEntryContent";
import NewEntryButton from "../NewEntryButton/NewEntryButton";
import MenuBarSelectedEntry from "./MenuBarSelectedEntry/MenuBarSelectedEntry";
import StickyHeader from "../StickyHeader/StickyHeader";
import useAddReferenceModal from "../../../UI/Modal/AddReferenceModal/AddReferenceModal";
import useChooseImageModal from "../../../UI/Modal/ChooseImageModal/ChooseImageModal";
import useSetCategoryModal from "../../../UI/Modal/SetCategoryModal/SetCategoryModal";
import useTimeTracking from "../../../Utils/UseTimeTracking";
import EntryGroup from "../../../UI/EntryGroup/EntryGroup";
import { useTranslation } from "react-i18next";

const propTypes = {
  editMode: PropTypes.bool,
  sharingId: PropTypes.string,
  sharingRootEntryId: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  selectedEntry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    content: PropTypes.object,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  selectedEntryDetails: PropTypes.shape({
    cardsActiveAmountRecursive: PropTypes.integer,
    cardsDueAmountRecursive: PropTypes.integer,
    references: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
        target: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
      })
    ),
    referencedBy: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
        target: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
      })
    ),
  }),
  selectedEntryDetailsLoading: PropTypes.bool,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  onDeleteEntry: PropTypes.func.isRequired,
  onUpdateEntry: PropTypes.func.isRequired,
  onUpdateEntryImage: PropTypes.func.isRequired,
  deleteEntryLoading: PropTypes.bool,
  dialogOpened: PropTypes.bool,
  onDialogOpenedChanged: PropTypes.func.isRequired,
  graphContainerHeight: PropTypes.number.isRequired,
};

const defaultProps = {
  editMode: false,
  selectedEntry: null,
  selectedEntryDetails: null,
  selectedEntryDetailsLoading: false,
  sharingId: null,
  sharingRootEntryId: null,
  user: null,
  entries: [],
  deleteEntryLoading: false,
  dialogOpened: false,
};

const EntryBrowserSelectedEntry = ({
  selectedEntry,
  selectedEntryDetails,
  selectedEntryDetailsLoading,
  entries,
  sharingId,
  sharingRootEntryId,
  user,
  editMode,
  onDeleteEntry,
  onUpdateEntry,
  onUpdateEntryImage,
  deleteEntryLoading,
  dialogOpened,
  onDialogOpenedChanged,
  graphContainerHeight,
}) => {
  const { t } = useTranslation();
  useTimeTracking({ active: !sharingId, entryId: selectedEntry.id });

  useHotkey({
    keyNames: ["Delete", "Backspace"],
    callback: () => {
      if (editMode && !dialogOpened) {
        onDeleteEntry(selectedEntry);
      }
    },
    skipIfInEditor: true,
    dependsOn: [editMode, dialogOpened, selectedEntry, entries, onDeleteEntry],
  });

  const [isHeaderSticky, setHeaderSticky] = useState(false);
  const [hasTags, setHasTags] = useState(false);
  const [references, setReferences] = useState([]);

  useEffect(() => {
    setHasTags(selectedEntry.tags ? selectedEntry.tags.length > 0 : false);
  }, [selectedEntry]);

  useEffect(() => {
    if (selectedEntryDetails == null) {
      setReferences([]);
    } else {
      let relevantRefs = [];
      relevantRefs = [
        ...relevantRefs,
        ...selectedEntryDetails.references.filter(
          (ref) => ref.source.entry && ref.source.entry.id === selectedEntry.id
        ),
        ...selectedEntryDetails.referencedBy.filter(
          (ref) => ref.target.entry && ref.target.entry.id === selectedEntry.id
        ),
      ];
      setReferences(relevantRefs);
    }
  }, [selectedEntryDetails]);

  const [updateBlockEntry] = useMutation(UPDATE_BLOCK, {
    update: (cache, mutationResult) => {
      mergeBlocksAfterUpdate(selectedEntry.id, cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
    onCompleted: () => {},
  });

  const {
    open: openAddReferenceModal,
    content: addReferenceModalContent,
  } = useAddReferenceModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => {
      onDialogOpenedChanged(false);
    },
  });

  const {
    open: openChooseImageModal,
    content: chooseImageModalContent,
  } = useChooseImageModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
    onImageChosen: onUpdateEntryImage,
  });

  const {
    open: openSetCategoryModal,
    content: setCategoryModalContent,
  } = useSetCategoryModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => {
      onDialogOpenedChanged(false);
    },
  });

  let content = null;
  let contentSelection = null;
  let contentList = null;

  const contentMenuBar = (
    <MenuBarSelectedEntry
      selectedEntry={selectedEntry}
      selectedEntryDetails={selectedEntryDetails}
      selectedEntryDetailsLoading={selectedEntryDetailsLoading}
      entries={entries}
      editMode={editMode}
      sharingId={sharingId}
      sharingRootEntryId={sharingRootEntryId}
      user={user}
      onUpdateEntry={(entryUpdates) => onUpdateEntry(entryUpdates)}
      onDeleteEntry={(entry) => onDeleteEntry(entry)}
      onAddReference={() => {
        openAddReferenceModal(selectedEntry.id, "entry");
      }}
      onSetCategory={() => openSetCategoryModal(selectedEntry.id, "entry")}
      deleteEntryLoading={deleteEntryLoading}
      hasTags={hasTags}
      onShowTags={() => {
        setHasTags(true);
      }}
      onDialogOpenedChanged={onDialogOpenedChanged}
    />
  );

  contentSelection = (
    <SelectedEntryHeader
      entry={selectedEntry}
      references={references}
      user={user}
      editMode={editMode}
      sharingId={sharingId}
      onUpdateEntry={(entryUpdates) => onUpdateEntry(entryUpdates)}
      onUpdateEntryImage={(entry, dropContent) =>
        openChooseImageModal({
          entityToChooseImageFor: entry,
          existingImageOfEntity: selectedEntryDetails
            ? selectedEntryDetails.image
            : entry.image,
          entityName: entry.name,
          entityType: "entry",
          entityId: entry.id,
          editMode: editMode,
          droppedContent: dropContent,
        })
      }
      onSetCategory={() => openSetCategoryModal(selectedEntry.id, "entry")}
      onDialogOpenedChanged={onDialogOpenedChanged}
      headerSticky={isHeaderSticky}
      hasTags={hasTags}
      onHideTags={() => {
        setHasTags(false);
      }}
    />
  );

  const childEntries = entries.filter((entry) => {
    return entry.parentEntryId === selectedEntry.id;
  });

  contentList = childEntries.map((entry) => {
    return (
      <div key={entry.id} className={entryBrowserStyles.entryContainer}>
        <Entry
          entry={entry}
          sharingId={sharingId}
          showSize
          onDropBlock={(blockId) => {
            const debounceKey = `${blockId}${entry.id}`;
            updateBlockEntry({
              variables: {
                id: blockId,
                input: {
                  entryId: entry.id,
                },
              },
              context: {
                debounceKey,
              },
              optimisticResponse: getOptimisticResponseForMoveBlock(
                blockId,
                entry.id
              ),
            });
          }}
        />
      </div>
    );
  });

  let contentNewButton = null;
  if (editMode) {
    contentNewButton = (
      <div className={entryBrowserStyles.newButtonWrapper}>
        <NewEntryButton selectedEntry={selectedEntry} user={user} />
      </div>
    );
  }

  let isEntryReferenced = false;
  if (selectedEntryDetails) {
    if (selectedEntryDetails.referencedBy.length > 0) {
      for (const reference of selectedEntryDetails.referencedBy) {
        if (
          reference.target.entry &&
          reference.target.entry.id === selectedEntry.id
        ) {
          isEntryReferenced = true;
          break;
        }
      }
    }
    if (!isEntryReferenced && selectedEntryDetails.references.length > 0) {
      for (const reference of selectedEntryDetails.references) {
        if (
          reference.source.entry &&
          reference.source.entry.id === selectedEntry.id
        ) {
          isEntryReferenced = true;
          break;
        }
      }
    }
  }

  let headerHeight = null;
  if (!isHeaderSticky) {
    if (selectedEntryDetails && isEntryReferenced) {
      headerHeight = "234px";
    } else {
      headerHeight = "174px";
    }
  }

  content = (
    <>
      <Helmet>
        <title>{selectedEntry.name} - WisdomTree</title>
      </Helmet>
      <StickyHeader
        className={entryBrowserStyles.stickyHeaderEntry}
        triggerStickyY={graphContainerHeight - 5}
        yOffset={Constants.HEADER_HEIGHT}
        onStickyChanged={(sticky) => setHeaderSticky(sticky)}
      >
        <div
          className={`${styles.entryHeader} ${
            entryBrowserStyles.selectionAndMenuWrapper
          } ${isHeaderSticky ? styles.stickyHeader : null}`}
          style={{ height: headerHeight }}
        >
          <div className={`box ${styles.entryHeaderInnerContainer}`}>
            <div className={styles.entryMenuBarContainer}>{contentMenuBar}</div>
            <hr />
            {contentSelection}
          </div>
        </div>
      </StickyHeader>

      <div className={styles.contentContainer}>
        <div
          className={`${entryBrowserStyles.selectedEntryContentWrapper} ${
            contentList.length > 0
              ? entryBrowserStyles.selectedEntryContentDistanced
              : null
          }`}
        >
          <SelectedEntryContent
            editMode={editMode}
            sharingId={sharingId}
            entry={selectedEntry}
            user={user}
            selectedEntryDetails={selectedEntryDetails}
            selectedEntryDetailsLoading={selectedEntryDetailsLoading}
            onDialogOpenedChanged={onDialogOpenedChanged}
          />
        </div>

        {contentList.length === 0 && editMode ? <hr /> : null}

        {contentList.length > 0 ? (
          <div className={entryBrowserStyles.entryListWrapper}>
            <EntryGroup title={t("wisdomtree.entry_browser.subnodes")}>
              {contentList}
              {contentNewButton}
            </EntryGroup>
          </div>
        ) : (
          <>{contentNewButton}</>
        )}
      </div>
      {addReferenceModalContent}
      {chooseImageModalContent}
      {setCategoryModalContent}
    </>
  );

  return content;
};

EntryBrowserSelectedEntry.propTypes = propTypes;
EntryBrowserSelectedEntry.defaultProps = defaultProps;

export default EntryBrowserSelectedEntry;
