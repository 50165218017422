import React, { useState } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";

import styles from "./AddWebLink.module.scss";
import modalStyles from "../AddReferenceModal.module.scss";
import { useTranslation } from "react-i18next";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_REFERENCE,
  mergeAfterCreate,
} from "../../../../GraphQl/references";
import { FETCH_WEB_LINK } from "../../../../GraphQl/webLinks";
import { useHotkey } from "../../../../Utils/Hotkeys";
import EditReferenceWeb from "../../../References/EditReferenceWeb/EditReferenceWeb";

const propTypesAddInternalLink = {
  onCloseModal: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
};
const defaultPropsAddInternalLink = {};

const AddWebLink = ({ onCloseModal, onBack, entityType, entityId }) => {
  const { t } = useTranslation();
  const [webLink, setWebLink] = useState(null);
  const [attemptedFetch, setAttemptedFetch] = useState(false);
  const [urlText, setUrlText] = useState("");
  const [titleText, setTitleText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");

  const onChangeUrlTextHandler = (event) => {
    const url = event.target.value;
    setUrlText(url);

    if (url && url.length > 0) {
      fetchWebLink({
        variables: {
          url: url,
        },
        context: {
          debounceKey: `fetchWebLink`,
          debounceTimeout: 600,
        },
      });
    }
  };

  const onChangeTitleTextHandler = (event) => {
    const title = event.target.value;
    setTitleText(title);
  };

  const onChangeDescriptionTextHandler = (event) => {
    const description = event.target.value;
    setDescriptionText(description);
  };

  const [fetchWebLink, { loading: fetchWebLinkLoading }] = useMutation(
    FETCH_WEB_LINK,
    {
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: (data) => {
        setAttemptedFetch(true);

        const newWebLink = data.fetchWebLink;
        if (
          titleText.length === 0 ||
          (webLink != null && webLink.title === titleText)
        ) {
          setTitleText(newWebLink && newWebLink.title ? newWebLink.title : "");
        }

        if (newWebLink) {
          setWebLink(newWebLink);
          document.getElementById("submitButton").focus();
        } else {
          setWebLink(null);
        }
      },
    }
  );

  const [createReference, { loading: createReferenceLoading }] = useMutation(
    CREATE_REFERENCE,
    {
      update: (cache, mutationResult) => {
        mergeAfterCreate(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        onCloseModal();
      },
    }
  );

  const onSubmit = () => {
    createReference({
      variables: {
        input: {
          type: "LINK_WEB",
          sourceType: entityType,
          sourceId: entityId,
          title: titleText,
          webLinkId: webLink.id,
          descriptionTo: descriptionText.length > 0 ? descriptionText : null,
        },
      },
    });
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!webLink || fetchWebLinkLoading || createReferenceLoading) return;
      onSubmit();
    },
    dependsOn: [onSubmit, fetchWebLinkLoading, webLink, createReferenceLoading],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onBack();
    },
    dependsOn: [onBack],
  });

  const content = (
    <EditReferenceWeb
      attemptedFetch={attemptedFetch}
      webLink={webLink}
      url={urlText}
      title={titleText}
      description={descriptionText}
      fetchWebLinkLoading={fetchWebLinkLoading}
      onChangeUrl={onChangeUrlTextHandler}
      onChangeTitle={onChangeTitleTextHandler}
      onChangeDescription={onChangeDescriptionTextHandler}
    />
  );

  return (
    <div className={`modal-card ${modalStyles.modalCard}`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.modal_add_reference.add_link_web.title")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${styles.body}`}>{content}</section>
      <footer className="modal-card-foot">
        <button
          id="submitButton"
          className={`button is-primary ${
            createReferenceLoading ? `is-loading` : ``
          }`}
          type="submit"
          disabled={fetchWebLinkLoading || !webLink}
          onClick={() => {
            onSubmit();
          }}
        >
          {t("wisdomtree.modal_add_reference.add_link_web.add")}
        </button>
        <button className="button" type="button" onClick={onBack}>
          {t("wisdomtree.modal_add_reference.add_link_web.back")}
        </button>
      </footer>
    </div>
  );
};

AddWebLink.propTypes = propTypesAddInternalLink;
AddWebLink.defaultProps = defaultPropsAddInternalLink;

export default AddWebLink;
