import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Line } from "rc-progress";

import stylesVariables from "../../../../variables.scss";
import styles from "./MenuBarQuiz.module.scss";
import menuBarStyles from "../../EntryBrowser/MenuBar.module.scss";

const propTypes = {
  currentCardIndex: PropTypes.number.isRequired,
  maxCards: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  onAttemptCancelQuiz: PropTypes.func.isRequired,
};

const defaultProps = {
  loading: false,
};

const MenuBarQuiz = ({
  currentCardIndex,
  maxCards,
  loading,
  onAttemptCancelQuiz,
}) => {
  const { t } = useTranslation();

  let contentCancelButton = null;
  contentCancelButton = (
    <div className={menuBarStyles.menuBarItem}>
      <button
        type="button"
        className="button is-light"
        onClick={() => onAttemptCancelQuiz()}
      >
        <span className={`icon ${menuBarStyles.iconGray}`}>
          <i className="fas fa-times" />
        </span>
        <span>{t("wisdomtree.quiz.cancel")}</span>
      </button>
    </div>
  );

  let contentQuizProgress = null;
  if (!loading) {
    const progressPercent =
      currentCardIndex !== 0 ? (currentCardIndex / maxCards) * 100 : 0;

    contentQuizProgress = (
      <>
        <p
          className={menuBarStyles.menuBarItem}
        >{`${currentCardIndex}/${maxCards}`}</p>
        <Line
          className={`${styles.progressBar} ${menuBarStyles.menuBarItem}`}
          percent={progressPercent}
          strokeLinecap="square"
          strokeWidth="8"
          trailWidth="8"
          trailColor={stylesVariables.grayLight}
          strokeColor={stylesVariables.secondaryColor}
        />
      </>
    );
  }
  return useMemo(
    () => (
      <div className={menuBarStyles.menuBar}>
        <div className={menuBarStyles.menuBarInnerContainer}>
          <div className={menuBarStyles.menuBarLeft}>
            {contentCancelButton} <p>{t("wisdomtree.quiz.title")}</p>
          </div>
          <div className={menuBarStyles.menuBarRight}>
            {contentQuizProgress}
          </div>
        </div>
      </div>
    ),
    [currentCardIndex, loading, maxCards]
  );
};

MenuBarQuiz.propTypes = propTypes;
MenuBarQuiz.defaultProps = defaultProps;

export default MenuBarQuiz;
