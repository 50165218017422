import React, { useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./Overview.module.scss";
import Title from "../UI/Title/Title";
import { GET_FEATURE_REQUESTS } from "../../GraphQl/roadmap";
import { useLazyQuery, useQuery } from "@apollo/client";
import Spinner from "../../UI/Spinner/Spinner";
import useAuthentication from "../../Utils/UseAuthentication";
import { GET_CURRENT_USER } from "../../GraphQl/user";
import FeatureGroup from "./FeatureGroup/FeatureGroup";
import { useTranslation } from "react-i18next";

const propTypesOverview = {};
const defaultPropsOverview = {};

const Overview = () => {
  const { t } = useTranslation();
  const authenticated = useAuthentication();

  const [
    loadUser,
    { loading: getUserLoading, data: getUserData },
  ] = useLazyQuery(GET_CURRENT_USER);
  const user = getUserData ? getUserData.me : null;

  const {
    loading: getFeatureRequestsLoading,
    data: getFeatureRequestsData,
  } = useQuery(GET_FEATURE_REQUESTS, {
    pollInterval: 30000,
  });
  const featureRequests = getFeatureRequestsData
    ? getFeatureRequestsData.featureRequests
    : [];

  useEffect(() => {
    if (authenticated) loadUser();
  }, [authenticated]);

  let content = null;
  if (getFeatureRequestsLoading || getUserLoading) {
    content = <Spinner />;
  } else {
    let featureGroupInProgress;
    const featuresInProgress = featureRequests.filter(
      (feature) => feature.status === "IN_PROGRESS"
    );
    if (featuresInProgress.length > 0) {
      featureGroupInProgress = (
        <FeatureGroup
          title={t("wisdomtree.roadmap.state_in_progress")}
          color="#ffc800"
          features={featuresInProgress}
        />
      );
    }

    let featureGroupPlanned;
    const featuresPlanned = featureRequests.filter(
      (feature) => feature.status === "PLANNED"
    );
    if (featuresPlanned.length > 0) {
      featureGroupPlanned = (
        <FeatureGroup
          title={t("wisdomtree.roadmap.state_planned")}
          features={featuresPlanned}
        />
      );
    }

    const featuresIdea = featureRequests.filter(
      (feature) => feature.status === "IDEA"
    );
    const featureGroupIdea = (
      <div className={styles.ideasContainer}>
        <FeatureGroup
          title={t("wisdomtree.roadmap.state_idea")}
          color={null}
          features={featuresIdea}
          hasSubmitButton={authenticated}
        />
      </div>
    );

    content = (
      <div className={styles.container}>
        <Title text={t("wisdomtree.roadmap.title")} icon="fa-map-signs" />
        <div className={styles.featureGroupsContainer}>
          {featureGroupInProgress}
          {featureGroupPlanned}
          {featureGroupIdea}
        </div>
      </div>
    );
  }

  return content;
};

Overview.propTypes = propTypesOverview;
Overview.defaultProps = defaultPropsOverview;

export default Overview;
