/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import Tippy from "@tippyjs/react";
import { followCursor } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale.css";

export function Tooltip(props) {
  const isDarkMode = document.documentElement.className.includes("theme-dark");
  return (
    <Tippy
      theme={isDarkMode ? "dark" : "light-border"}
      trigger="mouseenter"
      animation="scale"
      {...props}
    />
  );
}

export function TooltipDelayed(props) {
  const isDarkMode = document.documentElement.className.includes("theme-dark");
  return (
    <Tippy
      theme={isDarkMode ? "dark" : "light-border"}
      trigger="mouseenter"
      animation="scale"
      touch={["hold", 500]}
      delay={[500, null]}
      {...props}
    />
  );
}

export function TooltipDelayedOnTop(props) {
  const isDarkMode = document.documentElement.className.includes("theme-dark");
  return (
    <Tippy
      theme={isDarkMode ? "dark" : "light-border"}
      trigger="mouseenter"
      animation="scale"
      touch={["hold", 500]}
      delay={[500, null]}
      plugins={[followCursor]}
      followCursor
      arrow={false}
      {...props}
    />
  );
}

export function TooltipClick(props) {
  const isDarkMode = document.documentElement.className.includes("theme-dark");
  return (
    <Tippy
      theme={isDarkMode ? "dark" : "light-border"}
      trigger="mouseenter click"
      animation="scale"
      {...props}
    />
  );
}

export function TooltipClickOnly(props) {
  const isDarkMode = document.documentElement.className.includes("theme-dark");
  return (
    <Tippy
      theme={isDarkMode ? "dark" : "light-border"}
      trigger="click"
      animation="scale"
      {...props}
    />
  );
}

export function TooltipImage(props) {
  const { imageUrl } = props;
  const propsWithoutImageUrl = {
    ...props,
  };
  delete propsWithoutImageUrl.imageUrl;

  return (
    <Tippy
      className="image-preview"
      arrow={false}
      trigger="mouseenter"
      animation="scale"
      delay={[500, null]}
      touch={["hold", 500]}
      content={<img src={imageUrl} alt="entry" />}
      {...propsWithoutImageUrl}
    />
  );
}
