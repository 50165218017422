import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./NewComment.module.scss";
import { useHotkey } from "../../../../Utils/Hotkeys";

const propTypesNewComment = {
  onSendNewComment: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
const defaultPropsNewComment = {
  isLoading: false,
};

const NewComment = ({ onSendNewComment, isLoading }) => {
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState("");

  const onSendNewCommentHandler = () => {
    onSendNewComment(newComment);
    setNewComment("");
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (newComment.length === 0) return;
      onSendNewCommentHandler();
      document.activeElement.blur();
    },
    dependsOn: [onSendNewCommentHandler, newComment],
  });

  const onChangeNewCommentHandler = (event) => {
    setNewComment(event.target.value);
  };

  return (
    <div className={styles.sendMessageContainer}>
      <div className={`${styles.messageInputContainer} control`}>
        <input
          className={`input ${styles.messageInput}`}
          type="text"
          value={newComment}
          onChange={onChangeNewCommentHandler}
          placeholder={t("wisdomtree.roadmap.new_comment_hint")}
        />
      </div>
      <button
        className={`button ${newComment.length > 0 ? "is-primary" : null} ${
          styles.sendButton
        } ${isLoading ? "is-loading" : null}`}
        type="button"
        disabled={newComment.length === 0}
        onClick={() => {
          onSendNewCommentHandler();
          document.activeElement.blur();
        }}
      >
        <span className="icon is-small">
          <i className="fas fa-comment" />
        </span>
      </button>
    </div>
  );
};

NewComment.propTypes = propTypesNewComment;
NewComment.defaultProps = defaultPropsNewComment;

export default NewComment;
