import React from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";

import styles from "./TooltipCategory.module.scss";

const propTypesTooltipCategory = {
  category: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.shape({
      id: PropTypes.string,
      hex: PropTypes.string,
    }),
    description: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};
const defaultPropsTooltipCategory = {
  disabled: false,
};

const TooltipCategory = ({ category, children, disabled }) => {
  const isDarkMode = document.documentElement.className.includes("theme-dark");

  let content = (
    <div className={styles.container}>
      <div className={styles.nameContainer}>
        <div
          className={styles.colorRect}
          style={{ backgroundColor: category.color.hex }}
        />
        <p className={styles.name}>{category.name}</p>
      </div>
      {category.description ? (
        <p className={styles.description}>{category.description}</p>
      ) : null}
    </div>
  );

  return (
    <Tippy
      className="category-preview"
      theme={isDarkMode ? "dark" : "light-border"}
      trigger="mouseenter"
      animation="scale"
      delay={[500, null]}
      touch={["hold", 500]}
      disabled={disabled}
      content={content}
      children={children}
    />
  );
};

TooltipCategory.propTypes = propTypesTooltipCategory;
TooltipCategory.defaultProps = defaultPropsTooltipCategory;

export default TooltipCategory;
