import React from "react";

import styles from "./Overview.module.scss";

import Inbox from "./Inbox/Inbox";
import CardButton from "../UI/CardButton/CardButton";
import Title from "../UI/Title/Title";

const propTypesOverview = {};
const defaultPropsOverview = {};

const Overview = () => {
  return (
    <div className={styles.container}>
      <Title text="Admin" icon="fa-tools" />

      <div className={styles.pageSelectionContainer}>
        <CardButton text="Users" icon="fa-users" linkTo="/admin/users" />
        <CardButton
          text="Common Entries"
          icon="fa-feather-alt"
          linkTo="/admin/commonEntries"
        />
        <CardButton
          text="Change Notes"
          icon="fa-newspaper"
          linkTo="/admin/changeNotes"
        />
        <CardButton text="Roadmap" icon="fa-map-signs" linkTo="/roadmap" />
      </div>

      <Inbox />
    </div>
  );
};

Overview.propTypes = propTypesOverview;
Overview.defaultProps = defaultPropsOverview;

export default Overview;
