import React from "react";
import PropTypes from "prop-types";

import styles from "./Entry.module.scss";

const propTypesEntry = {
  entry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
};
const defaultPropsEntry = {};

const Entry = ({ entry }) => {
  return (
    <div className={styles.entry}>
      {entry.image ? (
        <img
          src={entry.image.url}
          key={entry.id}
          className={styles.entryImage}
          alt="entry"
        />
      ) : (
        <span className={`icon ${styles.entryImage} ${styles.entryEmptyImage}`}>
          <i className="fas fa-1x fa-image" />
        </span>
      )}
      <div className="content">
        <p className={styles.entryName}>{entry.name}</p>
      </div>
    </div>
  );
};

Entry.propTypes = propTypesEntry;
Entry.defaultProps = defaultPropsEntry;

export default Entry;
