import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { throttle } from "lodash";

import styles from "./QuestProgress.module.scss";
import {
  COMPLETE_CURRENT_QUEST,
  mergeUserAfterCompleteCurrentQuest,
} from "../../GraphQl/quests";
import Logger from "js-logger";
import { useMutation } from "@apollo/client";
import { getContentForQuest } from "../../Utils/QuestContent";
import useTutorialModal from "../../UI/Modal/TutorialModal/TutorialModal";

const propTypesQuestProgress = {
  quest: PropTypes.shape({
    type: PropTypes.string,
    progress: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    jobToBeDone: PropTypes.string,
  }).isRequired,
  onDialogOpenedChanged: PropTypes.func.isRequired,
};
const defaultPropsQuestProgress = {};

const QuestProgress = ({ quest, user, onDialogOpenedChanged }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const [progressCompleted, setProgressCompleted] = useState(false);
  const visibleRef = useRef(visible);

  const [completeQuest] = useMutation(COMPLETE_CURRENT_QUEST, {
    update: (cache, mutationResult) => {
      mergeUserAfterCompleteCurrentQuest(cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  const {
    open: openTutorialModal,
    content: tutorialModalContent,
  } = useTutorialModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
  });

  useEffect(() => {
    window.addEventListener("scroll", throttle(handleWindowScroll, 10));
    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);

  useEffect(() => {
    visibleRef.current = visible;
  }, [visible]);

  const handleWindowScroll = () => {
    const graphContainer = document.getElementById("graphContainer");
    const scrollYMax = graphContainer ? graphContainer.clientHeight - 240 : 0;

    if (window.scrollY > scrollYMax) {
      if (visibleRef.current) {
        setVisible(false);
      }
    }

    if (window.scrollY < scrollYMax) {
      if (!visibleRef.current) {
        setVisible(true);
      }
    }
  };

  const handleClicked = () => {
    if (progressCompleted) {
      completeQuest();
    } else {
      openTutorialModal({
        startPage: 2,
        cancelable: true,
        content: getContentForQuest(quest.type, user.jobToBeDone, false),
        onFinish: async () => {},
      });
    }
  };

  let progressContent = null;
  let progressPercentage;

  if (!progressCompleted) {
    const progressGoals = [];
    let currentTotal = 0;
    let targetTotal = 0;
    for (const progressKey in quest.progress) {
      let text = t(`wisdomtree.quests.goals.${progressKey}`);

      const finished =
        quest.progress[progressKey].current >=
        quest.progress[progressKey].target;

      currentTotal += quest.progress[progressKey].current;
      targetTotal += quest.progress[progressKey].target;

      progressGoals.unshift(
        <span
          key={progressKey}
          className={`${styles.goal} ${finished ? styles.finished : null}`}
        >
          <span className={`${styles.text}`}>{text}</span>
          <span className={styles.counter}>
            <span className={styles.counterCurrent}>
              {quest.progress[progressKey].current}
            </span>
            /
            <span className={styles.counterTarget}>
              {quest.progress[progressKey].target}
            </span>
          </span>
        </span>
      );
    }

    progressContent = (
      <div className={styles.progressContainer}>{progressGoals}</div>
    );

    progressPercentage = (currentTotal / targetTotal) * 100;

    if (progressPercentage >= 100 && !progressCompleted) {
      setProgressCompleted(true);
    }
  } else {
    progressContent = (
      <div className={styles.progressContainer}>
        <span>{t("wisdomtree.quests.complete")}</span>
      </div>
    );
    progressPercentage = 100;
  }

  const progressBarContent = (
    <div
      className={styles.progressBar}
      style={{ width: `${Math.max(5, progressPercentage)}%` }}
    />
  );

  return (
    <>
      <div
        id="questProgressContainer"
        className={`${styles.container} ${
          visible ? styles.visible : styles.invisible
        } ${progressCompleted ? styles.completed : null}`}
      >
        <div
          className={`box ${styles.innerContainer}`}
          data-id="button.quest-progress"
          onClick={handleClicked}
        >
          {progressBarContent}
          <div className={styles.title}>
            <span className={styles.text}>
              {t("wisdomtree.quests.progress")}
            </span>
            <span className={`icon ${styles.icon}`}>
              <i className={`fas fa-exclamation-circle`} />
            </span>
          </div>
          {progressContent}
        </div>
      </div>
      {tutorialModalContent}
    </>
  );
};

QuestProgress.propTypes = propTypesQuestProgress;
QuestProgress.defaultProps = defaultPropsQuestProgress;

export default QuestProgress;
