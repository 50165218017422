import { gql } from "@apollo/client";
import { FRAGMENT_COMPLETE_WEB_LINK } from "./fragments";

const FETCH_WEB_LINK = gql`
  mutation($url: String!) {
    fetchWebLink(url: $url) {
      ...CompleteWebLink
    }
  }
  ${FRAGMENT_COMPLETE_WEB_LINK}
`;

export { FETCH_WEB_LINK };
