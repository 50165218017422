import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { throttle } from "lodash";
import { animateScroll as scroll } from "react-scroll";

import styles from "./GraphParallaxAnim.module.scss";
import { setTransformProperyAsStyles } from "../../Utils/Utils";

const propTypesGraphParallaxAnim = {
  children: PropTypes.node.isRequired,
  quizMode: PropTypes.bool,
  onFinishQuizAnimation: PropTypes.func,
};
const defaultPropsGraphParallaxAnim = {
  quizMode: false,
};

const GraphParallaxAnim = ({ children, quizMode }) => {
  const quizModeRef = React.useRef(quizMode);

  const graphContainerInnerRef = useRef(null);

  useEffect(() => {
    quizModeRef.current = quizMode;
  }, [quizMode]);

  useEffect(() => {
    // var style = document.createElement("style");
    // style.id = "test123";
    // document.head.appendChild(style);

    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);

  // const updateStyleTransforms = (value) => {
  //   const stylesString = `-webkit-transform: ${value}; -moz-transform: ${value}; -o-transform: ${value} -ms-transform: ${value}; transform: ${value}`;
  //   const doc = document.getElementById("test123");
  //   if (doc) doc.textContent = ".graphTest {" + stylesString + "}";
  // };

  const handleWindowScroll = () => {
    if (quizModeRef.current) {
      return;
    }

    if (graphContainerInnerRef.current) {
      const maxScrollHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );

      const remainingScrollY =
        maxScrollHeight - window.innerHeight - window.scrollY;

      // adjust graph
      if (remainingScrollY > 2) {
        const scrollYCapped = Math.min(
          window.scrollY,
          graphContainerInnerRef.current.clientHeight
        );

        const paddingTop = Math.round(scrollYCapped / 2);

        setTransformProperyAsStyles(
          graphContainerInnerRef.current,
          `translate3d(0, ${paddingTop}px, 0)`
        );
        // updateStyleTransforms(`translate3d(0, ${paddingTop}px, 0)`);
      }
    }
  };

  useEffect(() => {
    if (graphContainerInnerRef.current) {
      const transitionString =
        "transform 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955)";
      graphContainerInnerRef.current.style.webkitTransition = transitionString;
      graphContainerInnerRef.current.style.MozTransition = transitionString;
      graphContainerInnerRef.current.style.msTransition = transitionString;
      graphContainerInnerRef.current.style.OTransition = transitionString;
      graphContainerInnerRef.current.style.transition = transitionString;

      if (quizMode) {
        const transfromString = `translate3d(0px, 0px, 0px)`;
        graphContainerInnerRef.current.style.webkitTransform = transfromString;
        graphContainerInnerRef.current.style.MozTransform = transfromString;
        graphContainerInnerRef.current.style.msTransform = transfromString;
        graphContainerInnerRef.current.style.OTransform = transfromString;
        graphContainerInnerRef.current.style.transform = transfromString;

        scroll.scrollTo(0, {
          duration: 600,
          smooth: "easeInOutQuad",
          ignoreCancelEvents: true,
        });
      } else {
        setTimeout(() => {
          graphContainerInnerRef.current.style.webkitTransition = null;
          graphContainerInnerRef.current.style.MozTransition = null;
          graphContainerInnerRef.current.style.msTransition = null;
          graphContainerInnerRef.current.style.OTransition = null;
          graphContainerInnerRef.current.style.transition = null;
        }, 600);
      }
    }
  }, [quizMode]);

  return (
    <div
      className={`${styles.container} graphTest`}
      ref={graphContainerInnerRef}
      data-id="graph.containerInner"
    >
      {children}
    </div>
  );
};

GraphParallaxAnim.propTypes = propTypesGraphParallaxAnim;
GraphParallaxAnim.defaultProps = defaultPropsGraphParallaxAnim;

export default GraphParallaxAnim;
