/* eslint-disable class-methods-use-this */
import * as d3 from "d3";

class EntryDropBlock {
  constructor(graphConfig, onUpdateBlockEntry) {
    this.graphConfig = graphConfig;
    this.onUpdateBlockEntry = onUpdateBlockEntry;
  }

  onNodeDragEnter(rootElement, node) {
    const hasBlock = d3.event.dataTransfer.types.includes("blockid");
    const hasEntry = d3.event.dataTransfer.types.includes("entryid");

    if (hasBlock && hasEntry) {
      this.addDragIndicator(rootElement, node);
      d3.event.preventDefault();
      d3.event.dataTransfer.dropEffect = "move";
    }
  }

  onNodeDragOver() {
    const hasBlock = d3.event.dataTransfer.types.includes("blockid");
    const hasEntry = d3.event.dataTransfer.types.includes("entryid");
    if (hasBlock && hasEntry) {
      d3.event.preventDefault();
      d3.event.dataTransfer.dropEffect = "move";
    }
  }

  onNodeDragLeave(rootElement, node) {
    this.removeDragIndicator(rootElement, node);
  }

  onNodeDrop(rootElement, node) {
    const blockId = d3.event.dataTransfer.getData("blockId");
    const entryId = d3.event.dataTransfer.getData("entryId");

    this.removeDragIndicator(rootElement, node);
    this.onUpdateBlockEntry(blockId, entryId, node.id);
  }

  addDragIndicator(rootElement, node) {
    d3.select(rootElement)
      .select(`#entryId_${node.id}`)
      .select(".nodeRect")
      .attr("stroke", this.graphConfig.dropBlockColor)
      .attr("stroke-width", 4);
  }

  removeDragIndicator(rootElement, node) {
    d3.select(rootElement)
      .select(`#entryId_${node.id}`)
      .select(".nodeRect")
      .attr("stroke", this.graphConfig.nodeStrokeColor)
      .attr("stroke-width", this.graphConfig.nodeStrokeWidth);
  }
}

export default EntryDropBlock;
