/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import Logger from "js-logger";
import { useLazyQuery } from "@apollo/client";
import { Collapse } from "react-collapse";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import styles from "./SearchBar.module.scss";
import SearchResults from "./SearchResults/SearchResults";
import { SEARCH_GLOBAL } from "../../GraphQl/search";
import { hasClassOnParent } from "../../Utils/Utils";
import { useHotkey } from "../../Utils/Hotkeys";

const propTypesSearchBar = {
  closeMenu: PropTypes.func,
};

const defaultPropsSearchBar = {
  closeMenu: null,
};

const SearchBar = ({ closeMenu }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [isFocused, setFocused] = useState(false);
  const searchInputRef = useRef(null);

  const [search, { loading: searchLoading, data: searchData }] = useLazyQuery(
    SEARCH_GLOBAL,
    {
      fetchPolicy: "cache-and-network",
      onError: (err) => {
        Logger.error(err);
      },
    }
  );
  const searchResults = searchData ? searchData.search.results : null;

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (isFocused) {
        setSearchText("");
        searchInputRef.current.focus();
      }
    },
    dependsOn: [isFocused],
  });

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (searchResults && searchResults.length > 0 && searchText.length > 0) {
        const firstElement = searchResults[0];
        if (firstElement.entry) {
          history.push(`/entry/${firstElement.entry.id}`);
        } else if (firstElement.block) {
          history.push(`/entry/${firstElement.block.entry.id}`);
        }
        document.activeElement.blur();
        onClickResult();
      }
    },
    requiresFocusIn: styles.searchBarInput,
    dependsOn: [searchResults, searchText, history],
  });

  const onClickResult = () => {
    setFocused(false);
    setSearchText("");
    closeMenu();
  };

  const onClear = () => {
    setSearchText("");
    searchInputRef.current.focus();
  };

  const handleSearchTextChange = (event) => {
    const text = event.target.value;
    setSearchText(text);

    if (text.length >= 1) {
      search({
        variables: { input: { query: text.toLowerCase() } },
        context: {
          debounceKey: `search`,
          debounceTimeout: 200,
        },
      });
    }
  };

  const showResults =
    (searchLoading || !!searchResults) && searchText.length > 0 && isFocused;

  return (
    <div
      id={styles.SearchBarWrapper}
      className="searchBarFocusIndicator"
      onFocus={(e) => {
        // make sure the "x" is clickable
        if (e.target && e.target.id === styles.cancelSearch) {
          return;
        }

        setFocused(true);
      }}
      onBlur={(e) => {
        // dont loose focus when something in the results is clicked
        if (hasClassOnParent(e.relatedTarget, "searchBarFocusIndicator"))
          return;

        setFocused(false);
      }}
    >
      <div className="control has-icons-left has-icons-right">
        <input
          data-id="search.input"
          ref={searchInputRef}
          className={`input ${styles.searchBarInput} ${
            isFocused ? styles.searchBarInputExpanded : ""
          }`}
          type="text"
          placeholder={t("wisdomtree.header.search_hint")}
          value={searchText}
          onChange={handleSearchTextChange}
          spellCheck={false}
          autoComplete="off"
        />
        <span className={`${styles.icon} icon is-small is-left`}>
          <i className="fas fa-search" />
        </span>
        {searchText.length > 0 ? (
          <span
            id={styles.cancelSearch}
            className="icon is-small is-right"
            tabIndex={-1}
            onClick={onClear}
          >
            <i className="fas fa-times" />
          </span>
        ) : null}
      </div>
      <div
        id={styles.SearchBarResultsWrapper}
        className={`${styles.searchResultsBox} ${
          showResults ? styles.searchResultsShadow : ""
        }`}
        tabIndex="-1"
      >
        {isFocused && (
          <Collapse isOpened={showResults}>
            <div id={styles.SearchResultsWrapper}>
              <SearchResults
                loading={searchLoading}
                searchResults={searchResults}
                onClickResult={onClickResult}
              />
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
};

SearchBar.propTypes = propTypesSearchBar;
SearchBar.defaultProps = defaultPropsSearchBar;

export default SearchBar;
