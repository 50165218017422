import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useMutation, useReactiveVar } from "@apollo/client";
import {
  ADD_PUSH_SUBSCRIPTION,
  mergeUserAfterAddPushSubscription,
} from "../GraphQl/user";
import {
  isMobileApp,
  requestDevicePushToken,
  requestNotificationPermissionStatus,
} from "./ReactNativeMessageUtils";
import { getPushSubscription } from "./PushNotifications";
import Logger from "js-logger";
import useReactNativeEvents from "./ReactNativeEventListener";
import { mobileNotificationPermissionStatus } from "../../config/apollo-cache";
import { toast } from "react-toastify";

const propTypesusePushSubscription = {};
const defaultPropsusePushSubscription = {};

const usePushSubscription = () => {
  const [
    addPushSubscription,
    { loading: addPushSubscriptionLoading },
  ] = useMutation(ADD_PUSH_SUBSCRIPTION, {
    update: mergeUserAfterAddPushSubscription,
    onError: (err) => {
      Logger.error(err);
    },
    onCompleted: () => {},
  });

  useReactNativeEvents({
    handleEvent: (event) => {
      if (event.type === "devicePushToken") {
        let deviceType;
        if (event.platform === "android") deviceType = "ANDROID";
        if (event.platform === "ios") deviceType = "IOS";

        addPushSubscription({
          variables: {
            input: {
              subscription: { token: event.token },
              deviceType,
            },
          },
        });
        return;
      }

      if (event.type === "notificationPermissionsUpdated") {
        mobileNotificationPermissionStatus(event.status);
        if (event.status === "granted") {
          requestDevicePushToken();
        }
        return;
      }
    },
  });

  useEffect(() => {
    if (isMobileApp()) {
      requestNotificationPermissionStatus();
    } else {
      if (!("serviceWorker" in navigator)) {
        return;
      }

      if (!("PushManager" in window)) {
        return;
      }

      if (Notification.permission === "granted") {
        navigator.serviceWorker.ready.then(async (registration) => {
          const subscription = await getPushSubscription(
            registration.pushManager
          );
          addPushSubscription({
            variables: {
              input: {
                subscription: subscription,
                deviceType: "WEB",
              },
            },
          });
        });
      }
    }
  }, []);
};

usePushSubscription.propTypes = propTypesusePushSubscription;
usePushSubscription.defaultProps = defaultPropsusePushSubscription;

export default usePushSubscription;
