import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import * as Constants from "../../Constants";
import Entry from "../Entry/Entry";
import styles from "./EntryBrowserHome.module.scss";
import entryBrowserStyles from "../EntryBrowser.module.scss";
import NewEntryButton from "../NewEntryButton/NewEntryButton";
import UserProfile from "./UserProfile/UserProfile";
import StickyHeader from "../StickyHeader/StickyHeader";
import QuestInfo from "./QuestInfo/QuestInfo";
import useTimeTracking from "../../../Utils/UseTimeTracking";
import Bookmarks from "./Bookmarks/Bookmarks";
import Recents from "./Recents/Recents";
import EntryGroup from "../../../UI/EntryGroup/EntryGroup";
import { useTranslation } from "react-i18next";

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  editMode: PropTypes.bool,
  sharingId: PropTypes.string,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  graphContainerHeight: PropTypes.number.isRequired,
  onDialogOpenedChanged: PropTypes.func.isRequired,
};

const defaultProps = {
  user: null,
  editMode: false,
  entries: [],
  sharingId: null,
};

const EntryBrowserHome = ({
  user,
  entries,
  sharingId,
  editMode,
  graphContainerHeight,
  onDialogOpenedChanged,
}) => {
  const { t } = useTranslation();
  useTimeTracking({ active: !sharingId, entryId: null });
  const [isHeaderSticky, setHeaderSticky] = useState(false);

  let contentList = null;
  let contentBookmarks = null;
  let contentRecents = null;
  let contentNewButton = null;
  let contentQuestInfo = null;

  if (entries) {
    // entries are loaded
    const topLevelEntries = entries.filter((entry) => {
      return entry.parentEntryId == null;
    });

    const contentEntriesList = topLevelEntries.map((entry) => {
      return (
        <div key={entry.id} className={entryBrowserStyles.entryContainer}>
          <Entry entry={entry} sharingId={sharingId} showSize />
        </div>
      );
    });

    if (editMode) {
      contentNewButton = (
        <div className={entryBrowserStyles.newButtonWrapper}>
          <NewEntryButton user={user} />
        </div>
      );
    }

    if (topLevelEntries.length > 0) {
      contentList = (
        <div className={entryBrowserStyles.entryListWrapper}>
          <EntryGroup title={t("wisdomtree.entry_browser.top_level_nodes")}>
            {contentEntriesList}
            {contentNewButton}
          </EntryGroup>
        </div>
      );
    } else {
      contentList = <>{contentNewButton}</>;
    }
  }

  if (user && user.hasBookmarks) {
    contentBookmarks = <Bookmarks />;
  }

  // if (user && user.hasBookmarks) {
  //   contentRecents = <Recents />;
  // }

  if (user && user.nextQuest) {
    contentQuestInfo = (
      <QuestInfo user={user} onDialogOpenedChanged={onDialogOpenedChanged} />
    );
  }

  return (
    <div>
      <Helmet>
        <title>WisdomTree</title>
      </Helmet>
      <StickyHeader
        className={`${entryBrowserStyles.stickyHeaderEntry} ${styles.header}`}
        triggerStickyY={graphContainerHeight - 5}
        yOffset={Constants.HEADER_HEIGHT}
        onStickyChanged={(sticky) => setHeaderSticky(sticky)}
      >
        <UserProfile
          user={user}
          sharingId={sharingId}
          onDialogOpenedChanged={onDialogOpenedChanged}
        />
      </StickyHeader>
      {contentQuestInfo}
      {contentBookmarks}
      {contentRecents}
      {contentList}
    </div>
  );
};

EntryBrowserHome.propTypes = propTypes;
EntryBrowserHome.defaultProps = defaultProps;

export default EntryBrowserHome;
