import shortid from "shortid";
import tinycolor from "tinycolor2";

/* eslint-disable func-names */
export function truncate(string, n, useWordBoundary) {
  if (string.length <= n) {
    return string;
  }
  const subString = string.substr(0, n - 1);
  return `${
    useWordBoundary && subString.includes(" ")
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString
  }...`;
}

export const isSetsEqual = (a, b) => {
  return a.size === b.size && [...a].every((value) => b.has(value));
};

export const getIconForBlockType = (blockType) => {
  switch (blockType) {
    case "NOTE":
      return "fa-sticky-note";
    case "IMAGE":
      return "fa-image";
    case "QUOTE":
      return "fa-quote-right";
    case "PRINCIPLE":
      return "fa-balance-scale";
    case "WEB":
      return "fa-globe";
    default:
      return "fa-question";
  }
};

export const getIconForCardType = (cardType) => {
  switch (cardType) {
    case "REVIEW":
      return "fa-eye";
    case "QUESTION":
      return "fa-question";
    case "CLOZE":
      return "fa-window-minimize";
    default:
      return "fa-question";
  }
};

export const getIconForJobToBeDone = (jobToBeDone) => {
  switch (jobToBeDone) {
    case "PERSONAL":
      return "fa-seedling";
    case "SCHOOL":
      return "fa-graduation-cap";
    case "WORK":
      return "fa-briefcase";
    default:
      return "fa-question";
  }
};

// Returns true if it is a DOM node
export const isDOMNode = (o) => {
  return typeof Node === "object"
    ? o instanceof Node
    : o &&
        typeof o === "object" &&
        typeof o.nodeType === "number" &&
        typeof o.nodeName === "string";
};

// Returns true if it is a DOM element
export const isDOMElement = (o) => {
  return typeof HTMLElement === "object"
    ? o instanceof HTMLElement // DOM2
    : o &&
        typeof o === "object" &&
        o !== null &&
        o.nodeType === 1 &&
        typeof o.nodeName === "string";
};

export const recursivelyUpdateEntryPositions = (
  entries,
  entryId,
  diffX,
  diffY
) => {
  const entry = entries.find((e) => e.id === entryId);
  let updatedEntries = [entry];
  entry.x += diffX;
  entry.y += diffY;

  entry.childEntryIds.forEach((childId) => {
    updatedEntries = updatedEntries.concat(
      recursivelyUpdateEntryPositions(entries, childId, diffX, diffY)
    );
  });

  return updatedEntries;
};

export const setTransformProperyAsStyles = (element, value) => {
  const stylesString = `-webkit-transform: ${value}; -moz-transform: ${value}; -o-transform: ${value} -ms-transform: ${value}; transform: ${value}`;
  element.setAttribute("style", stylesString);
};

export const hasClassOnElement = (domElement, className) => {
  return (
    domElement &&
    domElement.className &&
    typeof domElement.className === "string" &&
    domElement.className.includes(className)
  );
};

export const hasClassOnParent = (domElement, className) => {
  let tmp = domElement;
  if (tmp) {
    while (tmp.parentNode) {
      if (isDOMElement(tmp.parentNode)) {
        const cname = tmp.parentNode.getAttribute("class");
        if (cname && cname.includes(className)) return true;
      }
      tmp = tmp.parentNode;
    }
  }
  return false;
};

export const hasClassInHierarchy = (domElement, className) => {
  let tmp = domElement;
  if (tmp) {
    while (tmp) {
      if (isDOMElement(tmp)) {
        const cname = tmp.getAttribute("class");
        if (cname && cname.includes(className)) return true;
      }
      tmp = tmp.parentNode;
    }
  }
  return false;
};

export const hasScrollIdInHierarchy = (domElement, scrollId) => {
  let tmp = domElement;
  if (tmp) {
    while (tmp) {
      if (isDOMElement(tmp)) {
        const cname = tmp.getAttribute("data-scrollId");
        if (cname && cname.includes(scrollId)) return true;
      }
      tmp = tmp.parentNode;
    }
  }
  return false;
};

export const getTagTextColor = (brightness) => {
  return isBrightnessLight(brightness) ? "#363636" : "#f5f5f5";
};

export const isBrightnessLight = (brightness) => {
  return brightness >= 160;
};

export const isColorLight = (color) => {
  const brightness = tinycolor(color).getBrightness();
  return brightness >= 160;
};

export const getDefaultTagColor = (isDarkMode) => {
  return isDarkMode ? "#333333" : "#f5f5f5";
};

export const getDefaultTagColorSelected = (isDarkMode) => {
  return isDarkMode ? "#383838" : "#f0f0f0";
};

export const getGraphReferencesFromEntryDetails = (selectedEntryDetails) => {
  let graphReferences = [];
  if (selectedEntryDetails != null) {
    if (
      selectedEntryDetails.references != null &&
      selectedEntryDetails.references.length > 0
    ) {
    }
    graphReferences = [
      ...graphReferences,
      ...selectedEntryDetails.references
        .filter((reference) => reference.type === "LINK_INTERNAL")
        .map((reference) => {
          let sourceId = null;
          if (reference.source.entry) {
            sourceId = reference.source.entry.id;
          } else if (reference.source.block) {
            sourceId = reference.source.block.entry.id;
          }

          let targetId = null;
          if (reference.target.entry) {
            targetId = reference.target.entry.id;
          } else if (reference.target.block) {
            targetId = reference.target.block.entry.id;
          }

          return { sourceId, targetId };
        }),
    ];

    if (
      selectedEntryDetails.referencedBy != null &&
      selectedEntryDetails.referencedBy.length > 0
    ) {
    }
    graphReferences = [
      ...graphReferences,
      ...selectedEntryDetails.referencedBy
        .filter((reference) => reference.type === "LINK_INTERNAL")
        .map((reference) => {
          let sourceId = null;
          if (reference.source.entry) {
            sourceId = reference.source.entry.id;
          } else if (reference.source.block) {
            sourceId = reference.source.block.entry.id;
          }

          let targetId = null;
          if (reference.target.entry) {
            targetId = reference.target.entry.id;
          } else if (reference.target.block) {
            targetId = reference.target.block.entry.id;
          }

          return { sourceId, targetId };
        }),
    ];
  }

  // remove duplicates
  graphReferences = graphReferences.filter(
    (ref, index, self) =>
      index ===
      self.findIndex(
        (ref2) =>
          (ref2.sourceId === ref.sourceId && ref2.targetId === ref.targetId) ||
          (ref2.targetId === ref.sourceId && ref2.sourceId === ref.targetId)
      )
  );

  return graphReferences;
};

export const getOptimisticResponseAndVariablesForUpdateEntry = (
  entries,
  entryUpdates
) => {
  const entry = entries.find((e) => e.id === entryUpdates.id);
  const input = {};

  // let optimisticUpdatedEntry = { ...entry };
  let optimisticUpdatedEntry = { id: entry.id, __typename: entry.__typename };
  const optimisticUpdatedEntries = [];

  if ("name" in entryUpdates) {
    input.name = entryUpdates.name;
    optimisticUpdatedEntry.name = entryUpdates.name;
  }

  if ("tags" in entryUpdates) {
    input.tags = entryUpdates.tags;
    optimisticUpdatedEntry.tags = entryUpdates.tags.map((tagString) => {
      const existingTag = entry.tags.find((tag) => tag.name === tagString);

      return {
        id: existingTag ? existingTag.id : shortid.generate(),
        name: tagString,
        color: existingTag ? existingTag.color : null,
        __typename: "Tag",
      };
    });
  }

  if ("bookmarked" in entryUpdates) {
    input.bookmarked = entryUpdates.bookmarked;
    optimisticUpdatedEntry.bookmarked = entryUpdates.bookmarked;
  }

  if ("parentEntryId" in entryUpdates) {
    input.parentEntryId = entryUpdates.parentEntryId;
    optimisticUpdatedEntry.parentEntryId = entryUpdates.parentEntryId;

    // update old parent
    if (entry.parentEntryId) {
      const oldParentEntry = entries.find((e) => e.id === entry.parentEntryId);
      optimisticUpdatedEntries.push({
        id: oldParentEntry.id,
        __typename: oldParentEntry.__typename,
        childEntryIds: oldParentEntry.childEntryIds.filter((eId) => {
          return eId !== entry.id;
        }),
      });
    }

    // update new parent if not root entry
    if (entryUpdates.parentEntryId != null) {
      const newParentEntry = entries.find(
        (e) => e.id === entryUpdates.parentEntryId
      );
      optimisticUpdatedEntries.push({
        id: newParentEntry.id,
        __typename: newParentEntry.__typename,
        childEntryIds: [...newParentEntry.childEntryIds, entryUpdates.id],
      });
    }
  }

  if ("x" in entryUpdates) input.x = entryUpdates.x;
  if ("y" in entryUpdates) input.y = entryUpdates.y;

  if (input.x || input.y) {
    const diffX = input.x - entry.x;
    const diffY = input.y - entry.y;

    const updatedEntryPositions = recursivelyUpdateEntryPositions(
      JSON.parse(JSON.stringify(entries)),
      entryUpdates.id,
      diffX,
      diffY
    );

    updatedEntryPositions.forEach((e) => {
      if (e.id !== entry.id) {
        // add updated child entries to array
        optimisticUpdatedEntries.push({
          id: e.id,
          __typename: e.__typename,
          x: e.x,
          y: e.y,
        });
      } else {
        // update optmisticUpdatedEntry with new position
        optimisticUpdatedEntry = {
          ...optimisticUpdatedEntry,
          x: e.x,
          y: e.y,
        };
      }
    });
  }

  optimisticUpdatedEntries.push(optimisticUpdatedEntry);

  const variables = {
    id: entryUpdates.id,
    input: {
      ...input,
    },
  };

  const optimisticResponse = {
    __typename: "Mutation",
    updateEntry: {
      __typename: "UpdateEntryResponse",
      code: 200,
      success: true,
      message: "entries updated",
      updatedEntries: optimisticUpdatedEntries,
      createdTags: [],
      updatedUser: null,
      expEvent: null,
    },
  };

  return { variables, optimisticResponse };
};

export const clickOnEntryInGraph = (entryId, delay) => {
  let newlySelectedNode;
  if (entryId) {
    // eslint-disable-next-line prefer-destructuring
    newlySelectedNode = document.getElementById(`entryId_${entryId}`)
      .childNodes[0];
  } else {
    newlySelectedNode = document.getElementById(`entryId_-1`);
  }

  setTimeout(() => {
    newlySelectedNode.dispatchEvent(new Event("click"));
  }, delay);
};

export const isFocusOnColorSwatch = (activeElement) => {
  if (!activeElement) return false;

  let isColorSwatchFocussed = false;
  let isNewColorFocussed = false;

  if (activeElement.className && typeof activeElement.className === "string") {
    isColorSwatchFocussed = activeElement.className.includes("color-swatch");
    isNewColorFocussed = activeElement.className.includes("new-color");
  }

  return isColorSwatchFocussed || isNewColorFocussed;
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};
