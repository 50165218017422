import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import searchResultStyles from "../SearchResults.module.scss";
import styles from "./SearchResultBlock.module.scss";
import { getIconForBlockType, truncate } from "../../../../Utils/Utils";
import BlockIdentifier from "../../../../UI/BlockIdentifier/BlockIdentifier";

const propTypesSearchResultBlock = {
  block: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    entry: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.name,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      category: PropTypes.shape({
        color: PropTypes.shape({
          id: PropTypes.string,
          hex: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  onClickHandler: PropTypes.func,
  resultId: PropTypes.string,
};
const defaultPropsSearchResultBlock = {
  onClickHandler: () => {},
  resultId: null,
};

const SearchResultBlock = React.forwardRef(
  ({ block, onClickHandler, resultId }, ref) => {
    const contentEntryImage = block.entry.image ? (
      <img
        src={block.entry.image.url}
        className={searchResultStyles.SearchResultItemImage}
        alt="entry"
      />
    ) : (
      <span
        className={`icon ${searchResultStyles.SearchResultItemImage} ${searchResultStyles.SearchResultItemEmptyImage}`}
      >
        <i className="fas fa-image" />
      </span>
    );

    let entryCategoryIndicator = null;
    if (block.entry.category && block.entry.category.color) {
      entryCategoryIndicator = (
        <div
          className={searchResultStyles.categoryIndicator}
          style={{ backgroundColor: block.entry.category.color.hex }}
        />
      );
    }

    return (
      <Link
        id={resultId}
        ref={ref}
        className={`${searchResultStyles.SearchResultItem}`}
        to={`/entry/${block.entry.id}?block=${block.id}`}
        onClick={onClickHandler}
      >
        {contentEntryImage}
        {entryCategoryIndicator}
        <div className={`content ${styles.container}`}>
          <BlockIdentifier block={block} />
        </div>
      </Link>
    );
  }
);

SearchResultBlock.propTypes = propTypesSearchResultBlock;
SearchResultBlock.defaultProps = defaultPropsSearchResultBlock;

export default SearchResultBlock;
