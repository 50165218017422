import { useEffect } from "react";

const useHeapAnalytics = () => {
  const SCRIPT_ID_CUSTOM = "reactjs-heap-custom";
  const SCRIPT_ID_HEAP = "reactjs-heap";

  const getHeapScript = (id) => `
  window.heap = window.heap || []

  heap.load = function (appId, heapConfig) {
      window.heap.appid = appId;
      window.heap.config = heapConfig || {};
  
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.id = "${SCRIPT_ID_HEAP}";
      script.src = "https://cdn.heapanalytics.com/js/heap-" + appId + ".js";
      
      const firstScript = document.getElementsByTagName("script")[0];
      firstScript.parentNode.insertBefore(script, firstScript);
      
      const cloneArray = (arrayLike) => Array.prototype.slice.call(arrayLike, 0);
  
      const createMethod = function (method) {
          return function () {
              heap.push([
                  method,
                  ...cloneArray(arguments)
              ]);
          }
      };
  
      const methods = [
          'addEventProperties',
          'addUserProperties',
          'clearEventProperties',
          'identify',
          'resetIdentity',
          'removeEventProperty',
          'setEventProperties',
          'track',
          'unsetEventProperty',
      ];
      
      for (let method of methods) {
          heap[method] = createMethod(method)
      }
  };
  heap.load("${id}");
  `;

  useEffect(() => {
    const hasScript = !!document.getElementById(SCRIPT_ID_CUSTOM);

    if (hasScript) {
      if (window.heap) {
        // console.log("set loaded to true");
        window.heap.loaded = true;
      }
    } else {
      const script = document.createElement("script");
      script.id = SCRIPT_ID_CUSTOM;
      script.async = true;
      script.innerHTML = getHeapScript(process.env.REACT_APP_HEAP_APP_ID);

      // console.log("add heap");
      document.body.appendChild(script);
    }

    return () => {
      if (window.heap) {
        // console.log("set loaded to false");
        window.heap.loaded = false;
      }
    };
  }, []);
};
export default useHeapAnalytics;
