import React from "react";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import styles from "./Roadmap.module.scss";
import Overview from "./Overview/Overview";
import FeatureDetail from "./FeatureDetail/FeatureDetail";
import Header from "../Header/Header";
import useAuthentication from "../Utils/UseAuthentication";
import useDarkMode from "../Utils/UseDarkMode";

const propTypesRoadmap = {};
const defaultPropsRoadmap = {};

const Roadmap = () => {
  const authenticated = useAuthentication();
  const darkMode = useDarkMode();

  const content = (
    <Switch>
      <Route
        path="/roadmap/feature/:featureRequestId"
        component={FeatureDetail}
      />
      <Route path="/roadmap" component={Overview} />
    </Switch>
  );

  return (
    <>
      <Helmet>
        <title>Roadmap - WisdomTree</title>
      </Helmet>
      <div className={styles.container}>
        <Header
          darkMode={darkMode}
          hasInviteFriends={false}
          hasSearch={false}
          hasSettings={false}
          hasBackToMyWisdomTree={authenticated}
          hasCreateAccount={!authenticated}
          hasLogout={authenticated}
        />

        <div className={styles.contentContainer}>{content}</div>
      </div>
    </>
  );
};

Roadmap.propTypes = propTypesRoadmap;
Roadmap.defaultProps = defaultPropsRoadmap;

export default Roadmap;
