import Logger from "js-logger";

// ==============================================
// logger configuration
// ==============================================
if (process.env.NODE_ENV === "production") {
  Logger.setLevel(Logger.OFF);
} else {
  Logger.useDefaults();
}
