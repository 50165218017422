/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import { useMutation } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
import styles from "./EditReferenceModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import EditText from "./EditText/EditText";
import EditWeb from "./EditWeb/EditWeb";
import EditLink from "./EditLink/EditLink";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useEditReferenceModal = ({ onOpen, onClose }) => {
  const [reference, setReference] = useState(null);
  const [entityType, setEntityType] = useState(null);
  const [entityId, setEntityId] = useState(null);
  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose: (preventOnClose) => {
      if (!preventOnClose) onClose();
    },
  });

  const openModal = (existingReference, povType, povId) => {
    setReference(existingReference);
    setEntityType(povType);
    setEntityId(povId);
    open();
  };

  const closeModal = () => {
    close();
    setReference(null);
    setEntityType(null);
    setEntityId(null);
  };

  let content = null;
  if (isOpened) {
    let innerContent = null;

    if (reference.type === "LINK_INTERNAL") {
      innerContent = (
        <EditLink
          onCloseModal={closeModal}
          reference={reference}
          entityType={entityType}
          entityId={entityId}
        />
      );
    } else if (reference.type === "LINK_WEB") {
      innerContent = (
        <EditWeb onCloseModal={closeModal} reference={reference} />
      );
    } else if (reference.type === "TEXT") {
      innerContent = (
        <EditText onCloseModal={closeModal} reference={reference} />
      );
    }

    content = (
      <BaseModal show={isOpened} onCloseHandler={closeModal}>
        {innerContent}
      </BaseModal>
    );
  }

  return { open: openModal, close: closeModal, content };
};

useEditReferenceModal.propTypes = propTypes;
useEditReferenceModal.defaultProps = defaultProps;

export default useEditReferenceModal;
