import React from "react";
import PropTypes from "prop-types";

import styles from "./CommonEntryHeader.module.scss";
import CommonEntryImages from "./CommonEntryImages/CommonEntryImages";
import CommonEntryImageSearchQueries from "./CommonEntryImageSearchQueries/CommonEntryImageSearchQueries";

const propTypesCommonEntryHeader = {
  commonEntry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.name,
    userCount: PropTypes.userCount,
    createdAt: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        imageSource: PropTypes.shape({
          url: PropTypes.string,
        }),
        userCount: PropTypes.integer,
        approved: PropTypes.bool,
        createdAt: PropTypes.string,
      })
    ),
    imageSearchQueries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        query: PropTypes.string,
        userCount: PropTypes.integer,
        approved: PropTypes.bool,
        createdAt: PropTypes.string,
      })
    ),
  }).isRequired,
  onMergeCommonEntries: PropTypes.func.isRequired,
};
const defaultPropsCommonEntryHeader = {};

const CommonEntryHeader = ({ commonEntry, onMergeCommonEntries }) => {
  return (
    <div className={`box ${styles.container}`}>
      <div className={styles.topContainer}>
        <div className={styles.titleContainer}>
          <span className={styles.id}>CE-{commonEntry.id}</span>
          <span className={styles.title}>{commonEntry.name}</span>
        </div>
        <button
          type="button"
          className={`button is-light ${styles.mergeButton}`}
          onClick={() => {
            // eslint-disable-next-line no-alert
            const input = window.prompt(
              "Enter the CE-ID this CE should be merged into"
            );

            if (input != null && input.length > 0) {
              onMergeCommonEntries(input, commonEntry.id);
            }
          }}
        >
          <span className={`icon ${styles.gray}`}>
            <i className="fas fa-compress-arrows-alt" />
          </span>
        </button>
      </div>

      <div className={styles.userCountContainer}>
        <span className={`icon ${styles.gray}`}>
          <i className="fas fa-users" />
        </span>
        <span>{commonEntry.userCount}</span>
      </div>

      {commonEntry.images.length > 0 ? (
        <div className={styles.imagesContainer}>
          <CommonEntryImages images={commonEntry.images} />
        </div>
      ) : null}
      {commonEntry.imageSearchQueries.length > 0 ? (
        <div className={styles.imageSearchQueriesContainer}>
          <CommonEntryImageSearchQueries
            imageSearchQueries={commonEntry.imageSearchQueries}
          />
        </div>
      ) : null}
    </div>
  );
};

CommonEntryHeader.propTypes = propTypesCommonEntryHeader;
CommonEntryHeader.defaultProps = defaultPropsCommonEntryHeader;

export default CommonEntryHeader;
