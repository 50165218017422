import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./RandomGif.module.scss";

const propTypesRandomGif = {
  gifs: PropTypes.arrayOf(PropTypes.string).isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};
const defaultPropsRandomGif = {};

const RandomGif = ({ gifs, height, width }) => {
  const getRandomIndex = () => {
    return Math.floor(Math.random() * gifs.length);
  };

  const [chosenGif] = useState(gifs[getRandomIndex()]);

  return (
    <img
      className={styles.gif}
      height={height}
      width={width}
      src={chosenGif}
      style={{ maxHeight: height, maxWidth: width }}
      alt="motivational gif"
    />
  );
};

RandomGif.propTypes = propTypesRandomGif;
RandomGif.defaultProps = defaultPropsRandomGif;
export default RandomGif;
