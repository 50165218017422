import React from "react";
import PropTypes from "prop-types";

import styles from "./IndicatorCircle.module.scss";

const propTypesIndicatorCircle = {
  number: PropTypes.number.isRequired,
  radius: PropTypes.number,
  fontSize: PropTypes.number,
};
const defaultPropsIndicatorCircle = {
  radius: 22,
  fontSize: 26,
};

const IndicatorCircle = ({ number, radius, fontSize }) => {
  return (
    <div className={styles.container}>
      <svg
        height={`${radius * 2}px`}
        width={`${radius * 2}px`}
        style={{ fontSize }}
      >
        <circle r={`${radius - 4}px`} cx={`${radius}px`} cy={`${radius}px`} />
        <text
          className={styles.backgroundText}
          x={`${radius}px`}
          y={`${radius}px`}
          dominantBaseline="central"
        >
          {number}
        </text>
        <text
          className={styles.foregroundText}
          x={`${radius}px`}
          y={`${radius}px`}
          dominantBaseline="central"
        >
          {number}
        </text>
      </svg>
    </div>
  );
};

IndicatorCircle.propTypes = propTypesIndicatorCircle;
IndicatorCircle.defaultProps = defaultPropsIndicatorCircle;

export default IndicatorCircle;
