/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
import * as d3 from "d3";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import * as graphHelper from "./GraphHelper";

class EntryChangeParent {
  constructor(graphConfig, getAllNodes, onUpdateEntry) {
    this.graphConfig = graphConfig;
    this.getAllNodes = getAllNodes;
    this.onUpdateEntry = onUpdateEntry;

    this.updateDebounced = AwesomeDebouncePromise(
      this.update.bind(this),
      this.graphConfig.changeParentEntryDelay
    );
  }

  onNodeStartDrag(draggedNode) {
    this.draggedNode = draggedNode;
    this.newParent = null;
    this.oldParent = draggedNode.parent;
  }

  onNodeDrag(rootElement, draggedNode) {
    if (this.newParent) {
      this.updateIndicateNewParent(rootElement, draggedNode);
    }

    this.updateDebounced(rootElement, draggedNode);
  }

  onNodeEndDrag(rootElement) {
    this.removeIndicateEffect(rootElement);
    this.newParent = null;
    this.oldParent = null;
    this.draggedNode = null;
  }

  isDragging() {
    return this.draggedNode != null;
  }

  hasParentUpdate() {
    return !!this.newParent;
  }

  getParentUpdate() {
    if (this.newParent) {
      return {
        parentEntryId: this.newParent.id !== "-1" ? this.newParent.id : null,
      };
    }
    return null;
  }

  update(rootElement, draggedNode) {
    if (this.draggedNode == null) {
      // already finished dragging
      return;
    }

    const allNodes = this.getAllNodes();
    const rootNode = allNodes.find((node) => node.id === "-1");
    rootNode.data.nodeWidth = this.graphConfig.rootNodeRadius * 2;
    rootNode.data.nodeHeight = this.graphConfig.rootNodeRadius * 2;

    let collisions = [];
    allNodes.forEach((node) => {
      if (node.id === draggedNode.id) return;

      const nodesCollide = graphHelper.doNodesCollide(draggedNode, node);

      if (nodesCollide) {
        collisions.push(node);
      }
    });

    if (collisions.length === 0) {
      // if it was already dragged into another node before, check if it is now too far away
      if (this.newParent) {
        const posDiffToNewParent = graphHelper.distanceBetweenNodes(
          draggedNode,
          this.newParent
        );
        const diffSumX =
          posDiffToNewParent.diffX -
          draggedNode.data.nodeWidth / 2 -
          this.newParent.data.nodeWidth / 2;
        const diffSumY =
          posDiffToNewParent.diffY -
          draggedNode.data.nodeHeight / 2 -
          this.newParent.data.nodeHeight / 2;
        if (
          diffSumX > this.graphConfig.changeParentDistance ||
          diffSumY > this.graphConfig.changeParentDistance
        ) {
          this.revertIndicateNewParent(rootElement, draggedNode);
          this.newParent = null;
        }
      }
      return;
    }

    // cancel change parent when dragging into the old parent
    if (
      this.newParent &&
      this.oldParent &&
      collisions.some((node) => node.id === this.oldParent.id)
    ) {
      this.revertIndicateNewParent(rootElement, draggedNode);
      this.newParent = null;
      return;
    }

    // remove old parent from collisions
    if (this.oldParent) {
      collisions = collisions.filter((node) => this.oldParent.id !== node.id);
      if (collisions.length === 0) return;
    }

    // select the closest node if there are multiple collisions
    let chosenNode;
    if (collisions.length > 1) {
      let closestNode = null;
      let smallestDiff;
      collisions.forEach((collidedNode) => {
        const posDiff = graphHelper.distanceBetweenNodes(
          draggedNode,
          collidedNode
        );
        const diffSum = posDiff.diffX + posDiff.diffY;

        if (!closestNode || diffSum < smallestDiff) {
          closestNode = collidedNode;
          smallestDiff = diffSum;
        }
      });
      chosenNode = closestNode;
    } else {
      chosenNode = collisions[0];
    }

    if (this.newParent !== chosenNode) {
      const existingIndicator = this.newParent != null;
      this.newParent = chosenNode;
      this.indicateNewParent(
        rootElement,
        draggedNode,
        chosenNode,
        existingIndicator
      );
    }
  }

  indicateNewParent(rootElement, draggedNode, newParent, existingIndicator) {
    if (!existingIndicator) {
      d3.select(rootElement)
        .select(`#edgeId_${draggedNode.parent.id}_${draggedNode.id}`)
        .attr("opacity", this.graphConfig.edgeOpacity)
        .transition()
        .duration(240)
        .attr("opacity", 0);
    }

    d3.select(rootElement)
      .select("#edgeChangeParent")
      .remove();
    d3.select(rootElement)
      .select("#rootGroup")
      .insert("line", ":first-child")
      .attr("id", "edgeChangeParent")
      .attr("x1", newParent.data.x)
      .attr("y1", newParent.data.y)
      .attr("x2", draggedNode.data.x)
      .attr("y2", draggedNode.data.y)
      .attr("fill", "none")
      .attr("stroke-width", this.graphConfig.edgeStrokeWidth)
      .attr("stroke", this.graphConfig.changeParentColor)
      .attr("opacity", 0.0)
      .transition()
      .duration(240)
      .attr("opacity", 1);

    // give border
    d3.select(rootElement)
      .select(`#entryId_${draggedNode.id}`)
      .select(".nodeRect")
      .attr("stroke", this.graphConfig.changeParentColor)
      .attr("stroke-width", 4);
  }

  updateIndicateNewParent(rootElement, draggedNode) {
    d3.select(rootElement)
      .select("#rootGroup")
      .select("#edgeChangeParent")
      .attr("x2", draggedNode.data.x)
      .attr("y2", draggedNode.data.y);
  }

  revertIndicateNewParent(rootElement, draggedNode) {
    d3.select(rootElement)
      .select(`#edgeId_${draggedNode.parent.id}_${draggedNode.id}`)
      .attr("opacity", 0)
      .transition()
      .duration(180)
      .attr("opacity", this.graphConfig.edgeOpacity);

    d3.select(rootElement)
      .select("#edgeChangeParent")
      .attr("opacity", 1)
      .transition()
      .duration(180)
      .attr("opacity", 0);

    window.setTimeout(() => {
      d3.select(rootElement)
        .select("#edgeChangeParent")
        .remove();
    }, 200);

    d3.select(rootElement)
      .select(`#entryId_${draggedNode.id}`)
      .select(".nodeRect")
      .attr("stroke", (d) => {
        if (d.data.isReference) {
          return this.graphConfig.nodeReferenceStrokeColor;
        } else {
          return this.graphConfig.nodeStrokeColor;
        }
      })
      .attr("stroke-width", (d) => {
        if (d.data.isReference) {
          return this.graphConfig.nodeReferenceStrokeWidth;
        } else {
          return this.graphConfig.nodeStrokeWidth;
        }
      });
  }

  removeIndicateEffect(rootElement) {
    d3.select(rootElement)
      .select("#edgeChangeParent")
      .remove();
  }
}

export default EntryChangeParent;
