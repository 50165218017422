import React, { useState } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";

import styles from "./EditText.module.scss";
import { useTranslation } from "react-i18next";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import { useMutation } from "@apollo/client";
import {
  UPDATE_REFERENCE,
  mergeAfterUpdate,
} from "../../../../GraphQl/references";
import { useHotkey } from "../../../../Utils/Hotkeys";
import EditReferenceText from "../../../References/EditReferenceText/EditReferenceText";

const propTypesAddInternalLink = {
  onCloseModal: PropTypes.func.isRequired,
  reference: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    descriptionTo: PropTypes.string,
  }).isRequired,
};
const defaultPropsAddInternalLink = {};

const EditText = ({ onCloseModal, reference }) => {
  const { t } = useTranslation();
  const [titleText, setTitleText] = useState(reference.title);
  const [descriptionText, setDescriptionText] = useState(
    reference.descriptionTo ? reference.descriptionTo : ""
  );

  const onChangeTitleTextHandler = (event) => {
    const title = event.target.value;
    setTitleText(title);
  };

  const onChangeDescriptionTextHandler = (event) => {
    const description = event.target.value;
    setDescriptionText(description);
  };

  const [updateReference, { loading: updateReferenceLoading }] = useMutation(
    UPDATE_REFERENCE,
    {
      update: (cache, mutationResult) => {
        mergeAfterUpdate(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        onCloseModal();
      },
    }
  );

  const descriptionChanged =
    !(reference.descriptionTo == null && descriptionText.length === 0) &&
    descriptionText !== reference.descriptionTo;

  const titleChanged = titleText.length > 0 && titleText !== reference.title;

  const canUpdate =
    !updateReferenceLoading && (titleChanged || descriptionChanged);

  const onSubmit = () => {
    updateReference({
      variables: {
        id: reference.id,
        input: {
          title: titleText,
          descriptionTo: descriptionText.length > 0 ? descriptionText : null,
        },
      },
    });
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!canUpdate || updateReferenceLoading) return;
      onSubmit();
    },
    dependsOn: [onSubmit, canUpdate, updateReferenceLoading],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onCloseModal();
    },
    dependsOn: [onCloseModal],
  });

  const content = (
    <EditReferenceText
      title={titleText}
      description={descriptionText}
      onChangeTitle={onChangeTitleTextHandler}
      onChangeDescription={onChangeDescriptionTextHandler}
    />
  );

  return (
    <div className={`modal-card`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.modal_edit_reference.edit_text.title")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${styles.body}`}>{content}</section>
      <footer className="modal-card-foot">
        <button
          id="submitButton"
          className={`button is-primary ${
            updateReferenceLoading ? `is-loading` : ``
          }`}
          type="submit"
          disabled={!canUpdate}
          onClick={() => {
            onSubmit();
          }}
        >
          {t("wisdomtree.modal_edit_reference.edit_text.edit")}
        </button>
        <button className="button" type="button" onClick={onCloseModal}>
          {t("wisdomtree.modal_edit_reference.edit_text.cancel")}
        </button>
      </footer>
    </div>
  );
};

EditText.propTypes = propTypesAddInternalLink;
EditText.defaultProps = defaultPropsAddInternalLink;

export default EditText;
