import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./Bookmarks.module.scss";
import { useQuery } from "@apollo/client";
import { GET_BOOKMARKED_ENTRIES } from "../../../../GraphQl/dashboard";
import Entry from "../../Entry/Entry";
import Spinner from "../../../../UI/Spinner/Spinner";
import EntryGroup from "../../../../UI/EntryGroup/EntryGroup";

const propTypesBookmarks = {};
const defaultPropsBookmarks = {};

const Bookmarks = () => {
  const { t } = useTranslation();

  const {
    loading: getBookmarkedEntriesLoading,
    data: getBookmarkedEntriesData,
  } = useQuery(GET_BOOKMARKED_ENTRIES, {
    fetchPolicy: "cache-and-network",
  });
  const entries = getBookmarkedEntriesData
    ? getBookmarkedEntriesData.bookmarks
    : null;

  let contentEntries = null;

  if (entries) {
    contentEntries = (
      <div className={styles.listContainer}>
        {entries.map((entry) => (
          <div key={entry.id} className={styles.entryContainer}>
            <Entry entry={entry} showSize showTimeTracked />
          </div>
        ))}
      </div>
    );
  } else if (getBookmarkedEntriesLoading) {
    contentEntries = <Spinner />;
  }

  return (
    <EntryGroup
      icon="fas fa-bookmark"
      title={t("wisdomtree.entry_browser.bookmarks_list_title")}
    >
      {contentEntries}
    </EntryGroup>
  );
};

Bookmarks.propTypes = propTypesBookmarks;
Bookmarks.defaultProps = defaultPropsBookmarks;

export default Bookmarks;
