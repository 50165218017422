import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./ColorEditPopup.module.scss";
import Tippy from "@tippyjs/react";

import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale.css";
import ColorPickerCustom from "../../ColorPickerCustom/ColorPickerCustom";
import { useMutation } from "@apollo/client";
import Logger from "js-logger";
import { UPDATE_COLOR } from "../../../GraphQl/colors";
import Spinner from "../../Spinner/Spinner";

const propTypesColorEditPopup = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.shape({
    id: PropTypes.string,
    hex: PropTypes.string,
  }).isRequired,
  openInEdit: PropTypes.bool,
  hasDelete: PropTypes.bool,
  onDeleteColor: PropTypes.func,
  deleteLoading: PropTypes.bool,
  onPopupShownChanged: PropTypes.func,
};
const defaultPropsColorEditPopup = {
  disabled: false,
  openInEdit: false,
  hasDelete: true,
  onDeleteColor: () => {},
  deleteLoading: false,
  onPopupShownChanged: () => {},
};

const ColorEditPopup = ({
  color,
  disabled,
  children,
  openInEdit,
  hasDelete,
  onDeleteColor,
  deleteLoading,
  onPopupShownChanged,
}) => {
  const [shown, setShown] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedHex, setSelectedHex] = useState(color.hex);
  const [firstOpenHappened, setFirstOpenHappened] = useState(false);

  const [updateColor, { loading: updateColorLoading }] = useMutation(
    UPDATE_COLOR,
    {
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {},
    }
  );

  useEffect(() => {
    onPopupShownChanged(shown);

    if (shown) {
      if (openInEdit && !firstOpenHappened) {
        setShowColorPicker(true);
      } else {
        setShowColorPicker(false);
      }

      if (!firstOpenHappened) setFirstOpenHappened(true);
    }
  }, [shown]);

  const handleColorUpdate = (updatedColor) => {
    setSelectedHex(updatedColor.hex);

    updateColor({
      variables: {
        id: color.id,
        input: {
          hex: updatedColor.hex,
        },
      },
      context: {
        debounceKey: `color${color.id}`,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateColor: {
          __typename: "UpdateColorResponse",
          code: 200,
          success: true,
          message: "color updated",
          updatedColor: { ...color, hex: updatedColor.hex },
        },
      },
    });
  };

  const isDarkMode = document.documentElement.className.includes("theme-dark");

  let content = null;

  if (showColorPicker) {
    content = (
      <div className={styles.container}>
        <ColorPickerCustom
          color={selectedHex}
          onChange={(updatedColor) => {
            handleColorUpdate(updatedColor);
          }}
        />
      </div>
    );
  } else if (deleteLoading) {
    content = (
      <div className={styles.container}>
        <div className={styles.loadingContainer}>
          <Spinner />
        </div>
      </div>
    );
  } else {
    content = (
      <div className={styles.container}>
        <button
          className="button is-light"
          type="button"
          onClick={() => {
            setShowColorPicker(true);
          }}
        >
          <span className={`icon ${styles.grayIcon}`}>
            <i className="fas fa-pen" />
          </span>
        </button>
        {hasDelete ? (
          <button
            className="button is-light"
            type="button"
            onClick={() => {
              onDeleteColor(color);
            }}
          >
            <span className={`icon ${styles.grayIcon}`}>
              <i className="fas fa-trash" />
            </span>
          </button>
        ) : null}
      </div>
    );
  }

  return (
    <Tippy
      className="color-edit"
      theme={isDarkMode ? "dark" : "light-border"}
      appendTo={() => document.body}
      trigger="click"
      animation="scale"
      interactive={true}
      disabled={disabled}
      content={content}
      children={children}
      showOnCreate={openInEdit}
      onShow={() => {
        setShown(true);
      }}
      onHidden={() => {
        setShown(false);
      }}
    />
  );
};

ColorEditPopup.propTypes = propTypesColorEditPopup;
ColorEditPopup.defaultProps = defaultPropsColorEditPopup;

export default ColorEditPopup;
