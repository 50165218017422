/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import { toast } from "react-toastify";
import Logger from "js-logger";
import { Helmet } from "react-helmet";

import Input from "../../UI/Input/Input";
import ErrorMessages from "../../Utils/ErrorMessages";
import styles from "./ResetPassword.module.scss";
import authStyles from "../Auth.module.scss";

const propTypes = {
  location: PropTypes.object.isRequired,
};

const defaultProps = {};

const RESET_PASSWORD = gql`
  mutation($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      message
      code
    }
  }
`;

const ResetPassword = ({ location }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [resetPassword, { loading: resetPasswordLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onError: (error) => {
        Logger.error(error);
        const errorCode = ErrorMessages.getErrorCode(error);

        if (
          errorCode === "RESET_TOKEN_INVALID" ||
          errorCode === "RESET_TOKEN_EXPIRED"
        ) {
          toast.error(t(`error.${errorCode}`));
          history.push("/auth/forgot-password");
          return;
        }

        setUnspecificError(errorCode);
      },
      onCompleted: () => {
        toast.success(t(`auth.reset_password_success`));
        history.push("/auth/login");
      },
    }
  );

  const [token] = useState(queryString.parse(location.search).token);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [unspecificError, setUnspecificError] = useState(null);

  const validatePassword = (passwordToValidate) => {
    if (!passwordToValidate || passwordToValidate.length === 0) {
      setPasswordError("RESET_PASSWORD_MISSING");
      return false;
    }

    if (passwordToValidate.length < 8) {
      setPasswordError("RESET_PASSWORD_TOO_SHORT");
      return false;
    }
    return true;
  };

  const handlePasswordChanged = (event) => {
    setPassword(event.target.value);
    setPasswordError(null);
  };

  const clearAllErrors = () => {
    setPasswordError(null);
    setUnspecificError(null);
  };

  const handleResetPassword = (event) => {
    event.preventDefault();

    clearAllErrors();

    const passwordValid = validatePassword(password);

    if (passwordValid) {
      resetPassword({
        variables: { token, password },
      });
    }
  };

  let errorContent = null;
  if (unspecificError) {
    const errorMessage = t(`error.${unspecificError}`);
    errorContent = <p className={styles.Error}>{errorMessage}</p>;
  }

  const resetPasswordForm = (
    <>
      <Helmet>
        <title>WisdomTree - {t(`auth.forgot_password_title`)}</title>
      </Helmet>
      <section className={styles.container}>
        <h1 className={authStyles.authTitle}>
          {t("auth.forgot_password_title")}
        </h1>
        <form onSubmit={handleResetPassword}>
          <Input
            type="password"
            name="password"
            placeholder={t("auth.reset_password_input")}
            autoComplete="new-password"
            spellCheck="false"
            iconleft="fas fa-lock"
            help={passwordError ? t(`error.${passwordError}`) : null}
            isDanger={passwordError !== null}
            value={password}
            onChange={handlePasswordChanged}
          />

          <div className="field">
            <div className="control text-align-center">
              <button
                className={`button is-primary ${styles.resetButton} ${
                  resetPasswordLoading ? "is-loading" : ""
                }`}
                type="submit"
                disabled={passwordError}
              >
                {t("auth.reset_password_button")}
              </button>
            </div>
          </div>
          {errorContent}
        </form>
      </section>
    </>
  );

  return <div>{resetPasswordForm}</div>;
};

ResetPassword.propTypes = propTypes;
ResetPassword.defaultProps = defaultProps;

export default withRouter(ResetPassword);
