export const browserSupportsPush = () => {
  return "serviceWorker" in navigator && "PushManager" in window;
};

export const getPushSubscription = async (pushManager) => {
  const existingSubscription = await pushManager.getSubscription();
  if (existingSubscription) return existingSubscription;

  const subscription = await pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: process.env.REACT_APP_PUSH_KEY,
  });

  return subscription;
};
