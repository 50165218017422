import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./SearchResultEntry.module.scss";
import searchResultStyles from "../SearchResults.module.scss";
import { truncate } from "../../../../Utils/Utils";

const propTypesSearchResultEntry = {
  entry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    category: PropTypes.shape({
      color: PropTypes.shape({
        id: PropTypes.string,
        hex: PropTypes.string,
      }),
    }),
  }).isRequired,
  onClickHandler: PropTypes.func,
  resultId: PropTypes.string,
};
const defaultPropsSearchResultEntry = {
  onClickHandler: () => {},
  resultId: null,
};

const SearchResultEntry = React.forwardRef(
  ({ entry, onClickHandler, resultId }, ref) => {
    let entryCategoryIndicator = null;
    if (entry.category && entry.category.color) {
      entryCategoryIndicator = (
        <div
          className={searchResultStyles.categoryIndicator}
          style={{ backgroundColor: entry.category.color.hex }}
        />
      );
    }

    return (
      <Link
        id={resultId}
        ref={ref}
        className={`${searchResultStyles.SearchResultItem}`}
        to={`/entry/${entry.id}`}
        onClick={onClickHandler}
      >
        {entry.image ? (
          <img
            src={entry.image.url}
            className={searchResultStyles.SearchResultItemImage}
            alt="entry"
          />
        ) : (
          <span
            className={`icon ${searchResultStyles.SearchResultItemImage} ${searchResultStyles.SearchResultItemEmptyImage}`}
          >
            <i className="fas fa-image" />
          </span>
        )}
        {entryCategoryIndicator}
        <div className="content">
          <p className={searchResultStyles.SearchResultItemText}>
            {truncate(entry.name, 44, true)}
          </p>
        </div>
      </Link>
    );
  }
);

SearchResultEntry.propTypes = propTypesSearchResultEntry;
SearchResultEntry.defaultProps = defaultPropsSearchResultEntry;

export default SearchResultEntry;
