import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./EditBlockWeb.module.scss";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import { useHotkey } from "../../../../Utils/Hotkeys";
import { useMutation } from "@apollo/client";
import { FETCH_WEB_LINK } from "../../../../GraphQl/webLinks";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import {
  mergeBlocksAfterUpdate,
  UPDATE_BLOCK,
} from "../../../../GraphQl/blocks";
import EditReferenceWeb from "../../../References/EditReferenceWeb/EditReferenceWeb";

const propTypesEditBlockWeb = {
  block: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};
const defaultPropsEditBlockWeb = {};

const EditBlockWeb = ({ block, onCloseModal }) => {
  const { t } = useTranslation();

  const [webLink, setWebLink] = useState(block.webLink);
  const [attemptedFetch, setAttemptedFetch] = useState(block.webLink != null);
  const [urlText, setUrlText] = useState(
    block.webLink ? block.webLink.url : ""
  );
  const [titleText, setTitleText] = useState(block.title ? block.title : "");

  const onChangeUrlTextHandler = (event) => {
    const url = event.target.value;
    setUrlText(url);

    if (url && url.length > 0) {
      fetchWebLink({
        variables: {
          url: url,
        },
        context: {
          debounceKey: `fetchWebLink`,
          debounceTimeout: 600,
        },
      });
    }
  };

  const onChangeTitleTextHandler = (event) => {
    const title = event.target.value;
    setTitleText(title);
  };

  const [fetchWebLink, { loading: fetchWebLinkLoading }] = useMutation(
    FETCH_WEB_LINK,
    {
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: (data) => {
        setAttemptedFetch(true);

        const newWebLink = data.fetchWebLink;
        if (
          titleText.length === 0 ||
          (webLink != null && webLink.title === titleText)
        ) {
          setTitleText(newWebLink && newWebLink.title ? newWebLink.title : "");
        }

        if (newWebLink) {
          setWebLink(newWebLink);
          document.getElementById("submitButton").focus();
        } else {
          setWebLink(null);
        }
      },
    }
  );

  const [updateBlock, { loading: updateBlockLoading }] = useMutation(
    UPDATE_BLOCK,
    {
      update: (cache, mutationResult) => {
        mergeBlocksAfterUpdate(block.entryId, cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        onCloseModal();
      },
    }
  );

  const urlChanged =
    urlText &&
    urlText.length > 0 &&
    block.webLink &&
    urlText !== block.webLink.url;
  const titleChanged =
    titleText && titleText.length > 0 && titleText !== block.title;

  const canUpdate =
    !updateBlockLoading &&
    !fetchWebLinkLoading &&
    webLink != null &&
    (urlChanged || titleChanged);

  const onSubmit = () => {
    updateBlock({
      variables: {
        id: block.id,
        input: {
          title: titleText,
          webLinkId: webLink.id,
        },
      },
    });
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!webLink || !canUpdate || updateBlockLoading) return;
      onSubmit();
    },
    dependsOn: [onSubmit, canUpdate, webLink, updateBlockLoading],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onCloseModal();
    },
    dependsOn: [onCloseModal],
  });

  const content = (
    <EditReferenceWeb
      attemptedFetch={attemptedFetch}
      webLink={webLink}
      url={urlText}
      title={titleText}
      titlePlaceholder={t("wisdomtree.modal_edit_block_web.placeholder_title")}
      description=""
      hideDescription={true}
      fetchWebLinkLoading={fetchWebLinkLoading}
      onChangeUrl={onChangeUrlTextHandler}
      onChangeTitle={onChangeTitleTextHandler}
      onChangeDescription={() => {}}
    />
  );

  return (
    <div className={`modal-card`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {block.webLink
            ? t("wisdomtree.modal_edit_block_web.title_edit")
            : t("wisdomtree.modal_edit_block_web.title_choose")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${styles.body}`}>{content}</section>
      <footer className="modal-card-foot">
        <button
          id="submitButton"
          className={`button is-primary ${
            updateBlockLoading ? `is-loading` : ``
          }`}
          type="submit"
          disabled={!canUpdate}
          onClick={() => {
            onSubmit();
          }}
        >
          {block.webLink
            ? t("wisdomtree.modal_edit_block_web.confirm_edit")
            : t("wisdomtree.modal_edit_block_web.confirm_choose")}
        </button>
        <button className="button" type="button" onClick={onCloseModal}>
          {t("wisdomtree.modal_edit_block_web.cancel")}
        </button>
      </footer>
    </div>
  );
};

EditBlockWeb.propTypes = propTypesEditBlockWeb;
EditBlockWeb.defaultProps = defaultPropsEditBlockWeb;

export default EditBlockWeb;
