/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isMobile as isMobileDevice } from "react-device-detect";

import styles from "./SelectedEntryHeader.module.scss";
import TitleQuillEditor from "../../../../UI/Editor/TitleQuillEditor";
import ClickableImage from "../../../../UI/ClickableImage/ClickableImage";
import Tags from "../../../../UI/Tags/Tags";
import { useMobileOrTablet } from "../../../../Utils/Responsive";
import { hasClassOnParent } from "../../../../Utils/Utils";
import References from "../../../../UI/References/References";
import { hasQuestGoal } from "../../../../Utils/QuestHelper";
import TooltipCategory from "../../../../UI/TooltipCategory/TooltipCategory";

const propTypes = {
  editMode: PropTypes.bool,
  entry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    content: PropTypes.object,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    category: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      color: PropTypes.shape({
        id: PropTypes.string,
        hex: PropTypes.string,
      }),
    }),
  }).isRequired,
  references: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.shape({
        entry: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
        block: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
      target: PropTypes.shape({
        entry: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
        block: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
    })
  ),
  user: PropTypes.shape({
    currentQuest: PropTypes.object,
  }),
  onUpdateEntry: PropTypes.func.isRequired,
  onUpdateEntryImage: PropTypes.func.isRequired,
  onSetCategory: PropTypes.func.isRequired,
  headerSticky: PropTypes.bool,
  hasTags: PropTypes.bool,
  onHideTags: PropTypes.func,
  onDialogOpenedChanged: PropTypes.func,
  sharingId: PropTypes.string,
};

const defaultProps = {
  editMode: false,
  headerSticky: false,
  hasTags: false,
  references: null,
  user: null,
  onHideTags: () => {},
  onDialogOpenedChanged: () => {},
  sharingId: null,
};

const SelectedEntryHeader = ({
  editMode,
  entry,
  references,
  user,
  onUpdateEntry,
  onUpdateEntryImage,
  onSetCategory,
  headerSticky,
  hasTags,
  onHideTags,
  onDialogOpenedChanged,
  sharingId,
}) => {
  const { t } = useTranslation();
  const hoverDivRef = useRef();
  const [isHovered, setIsHovered] = useState(false);

  if (
    isHovered &&
    hoverDivRef.current &&
    hoverDivRef.current.matches(":hover") === false
  )
    setIsHovered(false);

  const [activeElement, setActiveElement] = useState(document.activeElement);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const isInEditor = hasClassOnParent(activeElement, "entryNameEditor");
    const isInTags = hasClassOnParent(activeElement, "entryTags");
    const isInAutoComplete = hasClassOnParent(activeElement, "autocomplete");

    const hasTagsOpened = hasTags && entry.tags.length === 0;

    const collapse =
      headerSticky &&
      !isInEditor &&
      !isInTags &&
      !isInAutoComplete &&
      !hasTagsOpened;
    setIsCollapsed(collapse);
  }, [headerSticky, activeElement, hasTags]);

  const onUpdateEntryName = (entryName) => {
    if (!editMode) return;

    onUpdateEntry({
      id: entry.id,
      name: entryName,
    });
  };

  const onUpdateEntryTags = (tags) => {
    if (!editMode) return;

    onUpdateEntry({
      id: entry.id,
      tags,
    });
  };

  const onAttemptUpdateEntryImage = () => {
    if ((editMode || entry.image) && !entry.isImageUpdating)
      onUpdateEntryImage(entry, null);
  };

  const onAttemptImageDrop = (dropContent) => {
    if (editMode && !entry.isImageUpdating)
      onUpdateEntryImage(entry, dropContent);
  };

  let imageContent = null;
  const imageSize = isCollapsed ? 60 : 128;

  const hasQuest = user ? hasQuestGoal(user, "images") : false;
  imageContent = (
    <ClickableImage
      editMode={editMode || entry.image != null}
      dropAllowed={editMode && !entry.isImageUpdating}
      imageUrl={entry.image ? entry.image.url : null}
      isLoading={entry.isImageUpdating}
      height={imageSize}
      width={imageSize}
      onImageClicked={onAttemptUpdateEntryImage}
      onImageDropped={onAttemptImageDrop}
      altText="entry"
      borderRadius={{
        bottomLeft:
          references != null && references.length > 0 && !isCollapsed ? 0 : 6,
      }}
      tooltip={
        editMode ? t("wisdomtree.entry_browser.tooltips.entry_add_image") : null
      }
      preview={!isMobileDevice}
      hasQuest={hasQuest && entry.image == null}
    />
  );

  let tagsContent = null;
  if (hasTags) {
    tagsContent = (
      <div className={`entryTags ${styles.entryTagsContainer}`}>
        <Tags
          editMode={editMode}
          tags={entry.tags}
          onUpdateTags={onUpdateEntryTags}
          onNewTagBlur={() => {
            if (entry.tags.length === 0) onHideTags();
          }}
          showNewButton={isMobileDevice || isHovered}
          onDialogOpenedChanged={onDialogOpenedChanged}
          tooltipAddNew={t("wisdomtree.entry_browser.tooltips.tag_add_entry")}
          tooltipRemove={t(
            "wisdomtree.entry_browser.tooltips.tag_remove_entry"
          )}
          tooltipDeleteEverywhere={t(
            "wisdomtree.entry_browser.tooltips.tag_delete_everywhere"
          )}
        />
      </div>
    );
  }

  let referencesContent = null;
  if (references != null && references.length > 0) {
    referencesContent = (
      <div className={styles.referencesContainer}>
        <References
          editMode={editMode}
          sharingId={sharingId}
          references={references}
          povId={entry.id}
          povType="entry"
          onDialogOpenedChanged={onDialogOpenedChanged}
        />
      </div>
    );
  }

  let categoryContent = null;
  let categoryIndicator = null;
  if (entry.category && entry.category.color) {
    categoryIndicator = (
      <div
        className={styles.categoryIndicator}
        style={{ backgroundColor: entry.category.color.hex }}
      />
    );

    categoryContent = (
      <TooltipCategory category={entry.category}>
        <div
          className={`${styles.categoryContainer} ${
            editMode ? styles.editable : null
          }`}
          type="button"
          onClick={() => {
            if (!editMode) return;
            onSetCategory();
          }}
        >
          <div
            className={styles.categoryColorRect}
            style={{ backgroundColor: entry.category.color.hex }}
          />
          <p className={styles.categoryName}>{entry.category.name}</p>
        </div>
      </TooltipCategory>
    );
  }

  return (
    <div
      className={`${styles.container} ${isCollapsed ? styles.sticky : ""}`}
      onFocus={() => {
        setActiveElement(document.activeElement);
      }}
      onBlur={(e) => {
        if (e.relatedTarget) {
          setActiveElement(e.relatedTarget);
        } else {
          setActiveElement(null);
        }
      }}
    >
      <div className={styles.entryHeaderContainer}>
        <div className={styles.imageContainer}>{imageContent}</div>
        {categoryIndicator}
        <div
          className={styles.entryInfoContainer}
          ref={hoverDivRef}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          {categoryContent}
          <div className={`${styles.entryNameContainer}`}>
            <TitleQuillEditor
              elementId={entry.id}
              elementTitle={entry.name}
              limit={50}
              editMode={editMode}
              hintText={t("wisdomtree.entry_browser.new_entry_hint")}
              focusInitially={false}
              stylesClassName="entryNameEditor"
              onTitleChanged={(entryName) => {
                if (entryName) onUpdateEntryName(entryName);
              }}
            />
          </div>
          {tagsContent}
        </div>
      </div>
      {referencesContent != null && !isCollapsed ? <hr /> : null}
      {referencesContent}
    </div>
  );
};

SelectedEntryHeader.propTypes = propTypes;
SelectedEntryHeader.defaultProps = defaultProps;

export default SelectedEntryHeader;
