import * as d3 from "d3";
import * as graphHelper from "./GraphHelper";

class EntryCreation {
  constructor(graphConfig, onUpdateNewEntryDraft, getAllNodes) {
    this.graphConfig = graphConfig;
    this.onUpdateNewEntryDraft = onUpdateNewEntryDraft;
    this.getAllNodes = getAllNodes;
  }

  enable(rootElement, selectedEntryId) {
    let parentNode;
    let parentData;
    let parentAbsoluteX;
    let parentAbsoluteY;

    if (selectedEntryId) {
      parentNode = d3.select(rootElement).select(`#entryId_${selectedEntryId}`);
      // eslint-disable-next-line prefer-destructuring
      parentData = parentNode.data()[0];
      parentAbsoluteX = parentData.data.x;
      parentAbsoluteY = parentData.data.y;
    } else {
      parentNode = d3.select(rootElement).select("#rootGroup");
      parentAbsoluteX = 0;
      parentAbsoluteY = 0;
    }

    const randomAbsPos = this.calculateNewEntryPosition(
      parentData,
      parentAbsoluteX,
      parentAbsoluteY
    );

    const newEntryX = randomAbsPos.x;
    const newEntryY = randomAbsPos.y;

    this.newEntryMode = true;
    this.newEntryDraft = {
      name: this.graphConfig.newNodePlaceholderName,
      x: newEntryX,
      y: newEntryY,
      height: this.graphConfig.nodeHeight,
      width: graphHelper.getEntryNodeWidth(
        this.graphConfig.newNodePlaceholderName,
        null,
        this.graphConfig
      ),
      parentAbsoluteX,
      parentAbsoluteY,
    };

    this.onUpdateNewEntryDraft(rootElement, this.newEntryDraft, {
      x: this.newEntryDraft.x,
      y: this.newEntryDraft.y,
    });

    this.addNode(rootElement);

    return this.newEntryDraft;
  }

  addNode(rootElement) {
    const entryCreationInstance = this;

    const alreadyAdded = !!d3
      .select(rootElement)
      .select("#nodeNew")
      .node();
    if (alreadyAdded) return;

    d3.select(rootElement)
      .select("#rootGroup")
      .insert("line", ":first-child")
      .attr("id", "edgeNew")
      .attr("x1", entryCreationInstance.newEntryDraft.parentAbsoluteX)
      .attr("y1", entryCreationInstance.newEntryDraft.parentAbsoluteY)
      .attr("x2", entryCreationInstance.newEntryDraft.x)
      .attr("y2", entryCreationInstance.newEntryDraft.y)
      .attr("fill", "none")
      .attr("stroke-width", this.graphConfig.edgeStrokeWidth)
      .attr("stroke", this.graphConfig.newNodeColor)
      .attr("opacity", 0.0)
      .transition()
      .duration(340)
      .attr("opacity", 1);

    let newNodeSelector = d3
      .select(rootElement)
      .select("#rootGroup")
      .append("g")
      .attr("id", "nodeNew")
      .attr("transform", () => {
        return `translate(${entryCreationInstance.newEntryDraft.x},${entryCreationInstance.newEntryDraft.y})`;
      })
      .call(
        d3
          .drag()
          .on("start", () => {
            entryCreationInstance.dragStartX =
              entryCreationInstance.newEntryDraft.x;
            entryCreationInstance.dragStartY =
              entryCreationInstance.newEntryDraft.y;

            this.applyDragEffect(rootElement);
          })
          .on("drag", () => {
            entryCreationInstance.newEntryDraft.x += d3.event.dx;
            entryCreationInstance.newEntryDraft.y += d3.event.dy;
            d3.select("#rootGroup")
              .select("#nodeNew")
              .attr(
                "transform",
                `translate(${entryCreationInstance.newEntryDraft.x},${entryCreationInstance.newEntryDraft.y})`
              );

            d3.select(rootElement)
              .select("#rootGroup")
              .select("#edgeNew")
              .attr("x2", entryCreationInstance.newEntryDraft.x)
              .attr("y2", entryCreationInstance.newEntryDraft.y);
          })
          .on("end", () => {
            entryCreationInstance.onUpdateNewEntryDraft(
              rootElement,
              entryCreationInstance.newEntryDraft,
              {
                x: Math.round(entryCreationInstance.newEntryDraft.x),
                y: Math.round(entryCreationInstance.newEntryDraft.y),
              }
            );
            this.revertDragEffect(rootElement);
          })
      );

    newNodeSelector = newNodeSelector
      .append("g")
      .attr("transform", () => {
        return `translate(${-entryCreationInstance.newEntryDraft.width /
          2},${-entryCreationInstance.newEntryDraft.height / 2})`;
      })
      .on("mouseover", () => {
        // d3.select(this).select(".nodeRect").attr("stroke", entryCreationInstance.nodeStrokeColorHover);
      })
      .on("mouseout", () => {
        // d3.select(this).select(".nodeRect").attr("stroke", entryCreationInstance.nodeStrokeColor);
      });

    newNodeSelector
      .append("rect")
      .attr("class", "nodeRect")
      .attr("filter", "url(#nodeShadow)")
      .attr("height", () => {
        return entryCreationInstance.newEntryDraft.height;
      })
      .attr("width", () => {
        return entryCreationInstance.newEntryDraft.width;
      })
      .attr("fill", this.graphConfig.newNodeColor)
      .attr("rx", this.graphConfig.nodeCornerRadius)
      .attr("ry", this.graphConfig.nodeCornerRadius)
      .attr("stroke", this.graphConfig.nodeStrokeColor)
      .attr("stroke-width", 0)
      .attr("opacity", 0.0)
      .transition()
      .duration(240)
      .attr("opacity", 1);

    newNodeSelector
      .append("text")
      .attr("class", "nodeText")
      .attr("x", this.graphConfig.nodeTextPaddingLeft)
      .attr("y", () => {
        return entryCreationInstance.newEntryDraft.height / 2;
      })
      .attr("dominant-baseline", "central")
      .text(() => {
        const draftName = entryCreationInstance.newEntryDraft.name
          ? entryCreationInstance.newEntryDraft.name
          : entryCreationInstance.graphConfig.newNodePlaceholderName;

        return draftName;
      })
      .attr("font-size", this.graphConfig.nodeTextSize)
      .attr("fill", "rgb(255, 255, 255)")
      .attr("opacity", 0.0)
      .transition()
      .duration(240)
      .attr("opacity", 1);
  }

  // eslint-disable-next-line class-methods-use-this
  applyDragEffect(rootElement) {
    // scale node
    const selectorNode = d3
      .select(rootElement)
      .select(`#nodeNew`)
      .select("g");

    graphHelper.scaleNodeUpForDragEffect(selectorNode);

    // change shadow
    const selectorNodeDragged = d3
      .select(rootElement)
      .select(`#nodeNew`)
      .select(".nodeRect");

    selectorNodeDragged.attr("filter", `url(#nodeDraggedShadow)`);
  }

  // eslint-disable-next-line class-methods-use-this
  revertDragEffect(rootElement) {
    const selector = d3
      .select(rootElement)
      .select(`#nodeNew`)
      .select("g");

    graphHelper.scaleNodeDownForDragEffect(selector);

    const selectorNodeDragged = d3
      .select(rootElement)
      .select(`#nodeNew`)
      .select(".nodeRect");

    selectorNodeDragged.attr("filter", `url(#nodeShadow)`);
  }

  calculateNewEntryPosition(parentNodeData, parentAbsoluteX, parentAbsoluteY) {
    let radius = 140;
    const minDistance = 140;
    let relevantOtherPositions = [];

    if (parentNodeData) {
      radius = Math.max(radius, Math.min(180, parentNodeData.data.nodeWidth));

      if (parentNodeData.parent) {
        relevantOtherPositions.push({
          x: parentNodeData.parent.data.x,
          y: parentNodeData.parent.data.y,
        });

        if (parentNodeData.parent.children) {
          relevantOtherPositions = relevantOtherPositions.concat(
            graphHelper.getArrayOfChildPositions(parentNodeData.parent)
          );
        }
      }

      if (parentNodeData.children) {
        relevantOtherPositions = relevantOtherPositions.concat(
          graphHelper.getArrayOfChildPositions(parentNodeData)
        );
      }
    } else {
      const rootNode = this.getAllNodes().find((node) => {
        return node.data.id === -1;
      });

      if (rootNode.children) {
        relevantOtherPositions = relevantOtherPositions.concat(
          graphHelper.getArrayOfChildPositions(rootNode)
        );
      }
    }

    let randomDegree = Math.floor(Math.random() * (360 - 0 + 1)) + 0;
    let randomX;
    let randomY;

    let i;
    for (i = 0; i < 36; i += 1) {
      randomDegree += 10;
      if (randomDegree >= 360) randomDegree -= 360;

      randomX = parentAbsoluteX + radius * Math.cos(randomDegree);
      randomY = parentAbsoluteY + radius * Math.sin(randomDegree);

      let tooClose = false;
      let j;
      for (j = 0; j < relevantOtherPositions.length; j += 1) {
        const distance = Math.hypot(
          relevantOtherPositions[j].x - randomX,
          relevantOtherPositions[j].y - randomY
        );
        if (distance < minDistance) {
          tooClose = true;
          break;
        }
      }
      if (!tooClose) {
        // pos found!
        break;
      }
    }
    return {
      x: Math.round(randomX),
      y: Math.round(randomY),
    };
  }

  updateDraft(rootElement, newEntryDraft) {
    if (newEntryDraft.name !== this.newEntryDraft.name) {
      const draftName = newEntryDraft.name
        ? newEntryDraft.name
        : this.graphConfig.newNodePlaceholderName;

      this.newEntryDraft = {
        ...this.newEntryDraft,
        name: newEntryDraft.name,
        width: graphHelper.getEntryNodeWidth(draftName, null, this.graphConfig),
      };

      d3.select(rootElement)
        .select("#nodeNew")
        .select(".nodeRect")
        .attr("width", this.newEntryDraft.width);

      d3.select(rootElement)
        .select("#nodeNew")
        .select("g")
        .attr(
          "transform",
          `translate(${-this.newEntryDraft.width / 2},${-this.newEntryDraft
            .height / 2})`
        );

      d3.select(rootElement)
        .select("#nodeNew")
        .select(".nodeText")
        .text(draftName);
    }
  }

  disable(rootElement) {
    // console.log("disableNewEntryMode");
    this.newEntryMode = false;

    d3.select(rootElement)
      .select("#nodeNew")
      .attr("opacity", 1)
      .transition()
      .duration(240)
      .attr("opacity", 0);

    d3.select(rootElement)
      .select("#edgeNew")
      .attr("opacity", 1)
      .transition()
      .duration(120)
      .attr("opacity", 0);

    window.setTimeout(() => {
      this.newEntryMode = false;

      d3.select(rootElement)
        .select("#nodeNew")
        .remove();

      d3.select(rootElement)
        .select("#edgeNew")
        .remove();
      this.newEntryDraft = {};
    }, 240);
  }

  isEnabled() {
    return this.newEntryMode;
  }
}

export default EntryCreation;
