import React from "react";
import PropTypes from "prop-types";

import styles from "./SelectedBlockHeader.module.css";
import entryBrowserStyles from "../../../EntryBrowser.module.scss";
import BlockType from "../../../BlockType/BlockType";
import TitleQuillEditor from "../../../../../UI/Editor/TitleQuillEditor";
import ContentQuillEditor from "../../../../../UI/Editor/ContentQuillEditor";

const propTypesSelectedBlockHeader = {
  editMode: PropTypes.bool,
  block: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.object,
  }).isRequired,
};
const defaultPropsSelectedBlockHeader = {
  editMode: false,
};

const SelectedBlockHeader = ({ editMode, block }) => {
  const blockType = (
    <div id={entryBrowserStyles.blockType}>
      <BlockType blockType={block.type} />
    </div>
  );

  let blockTitleEditor = null;
  if (block.title) {
    blockTitleEditor = (
      <div className={entryBrowserStyles.blockTitleContainer}>
        <TitleQuillEditor
          elementId={block.id}
          elementTitle={block.title}
          stylesClassName="blockTitleEditor"
          editMode={false}
          focusInitially={false}
        />
      </div>
    );
  }

  let blockContentEditor = null;
  if (block.content) {
    blockContentEditor = (
      <div className={entryBrowserStyles.blockContentContainer}>
        <ContentQuillEditor
          elementId={block.id}
          elementContent={block.content}
          stylesClassName="blockContentEditor"
          editMode={false}
          focusInitially={false}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div
        className={`${
          blockTitleEditor ? styles.blockHeaderWithTitle : styles.blockHeader
        } ${entryBrowserStyles.blockHeader}`}
      >
        <div className={entryBrowserStyles.blockHeaderFirstRow}>
          <div className={entryBrowserStyles.blockHeaderLeft}>
            <div className={entryBrowserStyles.blockHeaderTypeAndTitle}>
              {blockType}
              {blockTitleEditor}
            </div>
          </div>
        </div>
      </div>
      {blockContentEditor}
    </div>
  );
};

SelectedBlockHeader.propTypes = propTypesSelectedBlockHeader;
SelectedBlockHeader.defaultProps = defaultPropsSelectedBlockHeader;

export default SelectedBlockHeader;
