/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Portal } from "react-portal";

// eslint-disable-next-line no-unused-vars
import styles from "./BaseModal.module.scss";

export const useBaseModal = ({
  onOpen = () => {},
  onClose = () => {},
  isNested = false,
  hasNestedOpened = false,
}) => {
  const history = useHistory();
  const [isOpened, setOpened] = useState(false);
  const unblockHandle = useRef(null);

  useEffect(() => {
    if (isOpened && !hasNestedOpened) {
      // small timeout to fix overlappin block with nested modal
      setTimeout(() => {
        // console.log("block");
        unblockHandle.current = history.block((location, action) => {
          // console.log("close due to block");

          if (action === "POP") {
            close();
            return "block";
          }

          return null;
        });
      }, 25);
    } else if (unblockHandle && unblockHandle.current) {
      // console.log("unblock");
      unblockHandle.current();
    }
  }, [isOpened, hasNestedOpened, isNested]);

  const open = () => {
    setOpened(true);
    onOpen();
  };
  const close = (preventOnClose = false) => {
    setOpened(false);
    onClose(preventOnClose);
  };

  return { isOpened, open, close };
};

const propTypes = {
  show: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  children: PropTypes.node,
  nested: PropTypes.bool,
};

const defaultProps = {
  children: null,
  nested: false,
};

const Modal = ({ children, show, onCloseHandler, nested }) => {
  useEffect(() => {
    if (!nested) {
      document.documentElement.style.overflowY = "hidden";
      document.body.scroll = "no";
    }

    return () => {
      if (!nested) {
        document.documentElement.style.overflowY = "scroll";
        document.body.scroll = "yes";
      }
    };
  });

  if (!show) return null;

  return (
    <Portal>
      <div className={`modal ${styles.modal} is-active`}>
        <div className="modal-background" onClick={onCloseHandler} />
        {children}
      </div>
    </Portal>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
