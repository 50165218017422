import { gql } from "@apollo/client";
import { sortBy } from "lodash/collection";
import { mergeEntryUpdates } from "./entries";

import {
  FRAGMENT_COMPLETE_CATEGORY,
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
  FRAGMENT_USER_UPDATE,
} from "./fragments";
import { mergeUserUpdates, processExpEvent } from "./user";

const GET_CATEGORIES = gql`
  query {
    categories {
      ...CompleteCategory
    }
  }
  ${FRAGMENT_COMPLETE_CATEGORY}
`;

const CREATE_CATEGORY = gql`
  mutation($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      code
      message
      createdCategory {
        ...CompleteCategory
      }
      updatedUser {
        ...UserUpdate
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_CATEGORY}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const UPDATE_CATEGORY = gql`
  mutation($id: ID!, $input: UpdateCategoryInput!) {
    updateCategory(id: $id, input: $input) {
      code
      message
      updatedCategory {
        ...CompleteCategory
      }
    }
  }
  ${FRAGMENT_COMPLETE_CATEGORY}
`;

const DELETE_CATEGORY = gql`
  mutation($id: ID!) {
    deleteCategory(id: $id) {
      code
      message
      deletedCategoryIds
      updatedEntries {
        id
        category {
          ...CompleteCategory
        }
      }
    }
  }
  ${FRAGMENT_COMPLETE_CATEGORY}
`;

const mergeCategoriesAfterCreate = (cache, mutationResult) => {
  const { categories: currentData } = cache.readQuery({
    query: GET_CATEGORIES,
  });
  const { createdCategory } = mutationResult.data.createCategory;
  const { updatedUser } = mutationResult.data.createCategory;
  const { expEvent } = mutationResult.data.createCategory;

  // add created entry to current data
  let updatedData = currentData.concat([createdCategory]);
  updatedData = sortBy(updatedData, ["name"]);

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }

  cache.writeQuery({
    query: GET_CATEGORIES,
    data: { categories: updatedData },
  });
};

const mergeCategoriesAfterDelete = (cache, mutationResult) => {
  const { categories: currentData } = cache.readQuery({
    query: GET_CATEGORIES,
  });

  const { deletedCategoryIds } = mutationResult.data.deleteCategory;
  const { updatedEntries } = mutationResult.data.deleteCategory;

  // filter out deleted card ids
  const updatedData = currentData.filter(
    (e) => deletedCategoryIds.indexOf(e.id) === -1
  );

  // merge entry updates
  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  cache.writeQuery({
    query: GET_CATEGORIES,
    data: { categories: updatedData },
  });
};

const mergeCategoryUpdates = (cache, updatedCategories) => {
  try {
    const { categories: currentData } = cache.readQuery({
      query: GET_CATEGORIES,
    });

    const updatedData = currentData.map((e) => {
      const updatedCategory = updatedCategories.find((ue) => e.id === ue.id);
      if (updatedCategory !== null) {
        return {
          ...e,
          ...updatedCategory,
        };
      }
      return e;
    });

    cache.writeQuery({
      query: GET_CATEGORIES,
      data: { categories: updatedData },
    });
  } catch (error) {
    // categories not cached
  }
};

export {
  GET_CATEGORIES,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  mergeCategoriesAfterCreate,
  mergeCategoriesAfterDelete,
  mergeCategoryUpdates,
};
