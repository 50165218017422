import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import Logger from "js-logger";

import styles from "./UserFeedback.module.scss";
import {
  GET_SPECIFIC_CONVERSATION,
  CREATE_MESSAGE_SPECIFIC,
  mergeSpecificConversationAfterUpdate,
  MARK_MESSAGES_FROM_USER_AS_READ,
  removeUnreadMessageFromUserAfterUpdate,
} from "../../../GraphQl/feedback";

import MessageList from "../../../UI/Chat/MessageList/MessageList";
import NewMessage from "../../../UI/Chat/NewMessage/NewMessage";

const propTypesUserFeedback = {
  userId: PropTypes.string.isRequired,
};
const defaultPropsUserFeedback = {};

const UserFeedback = ({ userId }) => {
  const { data: getConversationData, refetch: refetchConversation } = useQuery(
    GET_SPECIFIC_CONVERSATION,
    {
      variables: {
        userId,
      },
      pollInterval: 4000,
    }
  );

  const [createMessage] = useMutation(CREATE_MESSAGE_SPECIFIC, {
    variables: {
      toUserId: userId,
    },
    update: mergeSpecificConversationAfterUpdate,
    onError: (err) => {
      Logger.error(err);
    },
  });

  const [markMessagesAsRead] = useMutation(MARK_MESSAGES_FROM_USER_AS_READ, {
    variables: {
      userId,
    },
    update: (cache, mutationResult) =>
      removeUnreadMessageFromUserAfterUpdate(cache, mutationResult, userId),
    onError: (err) => {
      Logger.error(err);
    },
  });

  useEffect(() => {
    refetchConversation();
  }, []);

  useEffect(() => {
    if (getConversationData && getConversationData.conversationOf)
      markMessagesAsRead();
  }, [getConversationData]);

  const sendNewMessageHandler = (message) => {
    createMessage({
      variables: {
        input: {
          text: message,
          toUserId: userId,
        },
      },
    });
  };

  let messageContent = null;
  if (getConversationData) {
    messageContent = (
      <MessageList
        messages={getConversationData.conversationOf}
        isUserChat={false}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <span className={`icon ${styles.icon}`}>
          <i className="fas fa-comments" />
        </span>
        <p className={styles.title}>Chat</p>
      </div>
      <div className={`${styles.feedbackContainer} box`}>
        {messageContent}
        <NewMessage
          onSendNewMessage={sendNewMessageHandler}
          focusInitially={false}
        />
      </div>
    </div>
  );
};

UserFeedback.propTypes = propTypesUserFeedback;
UserFeedback.defaultProps = defaultPropsUserFeedback;

export default UserFeedback;
