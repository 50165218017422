import { gql } from "@apollo/client";

const POST_USAGE_TIME = gql`
  mutation PostUsageTime($input: UsageTimeInput!) {
    postUsageTime(input: $input) {
      code
      message
    }
  }
`;

export { POST_USAGE_TIME };
