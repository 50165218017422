import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import styles from "./CopyUrl.module.scss";

const propTypesCopyUrl = {
  text: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  externalRef: PropTypes.bool,
};
const defaultPropsCopyUrl = {
  text: "",
  loading: false,
  disabled: false,
  externalRef: false,
};

const CopyUrl = React.forwardRef(
  ({ text, loading, disabled, externalRef }, ref) => {
    const inputRef = useRef(null);
    const { t } = useTranslation();

    const onCopyClickHandler = () => {
      if (externalRef) {
        ref.current.select();
      } else {
        inputRef.current.select();
      }
      document.execCommand("copy");
      toast.success(t(`wisdomtree.other.copied`), {
        autoClose: 2000,
      });
    };

    return (
      <div
        className={`${styles.container} ${disabled ? styles.disabled : null}`}
      >
        <div
          className={`${styles.text} control ${loading ? "is-loading" : ""}`}
        >
          <input
            className={`input ${!disabled ? "is-primary" : null} ${
              styles.input
            }`}
            type="text"
            value={text}
            readOnly
            ref={externalRef ? ref : inputRef}
          />
        </div>
        {document.queryCommandSupported("copy") && (
          <button
            className={`button ${!disabled ? "is-primary" : null} ${
              styles.copyButton
            }`}
            type="button"
            onClick={onCopyClickHandler}
            disabled={disabled}
          >
            <span className="icon is-small">
              <i className="fas fa-copy" />
            </span>
          </button>
        )}
      </div>
    );
  }
);

CopyUrl.propTypes = propTypesCopyUrl;
CopyUrl.defaultProps = defaultPropsCopyUrl;

export default CopyUrl;
