/* eslint-disable no-underscore-dangle */
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import Logger from "js-logger";

import styles from "./ImageGallery.module.scss";
import { SEARCH_IMAGES } from "../../../../../GraphQl/searchImages";
import Image from "./Image/Image";

const propTypesImageGallery = {
  preview: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  scrollValues: PropTypes.shape({
    scrollTop: PropTypes.number,
    offsetHeight: PropTypes.number,
    scrollHeight: PropTypes.number,
  }),
  onImageSelected: PropTypes.func.isRequired,
  onShowMoreClicked: PropTypes.func,
};
const defaultPropsImageGallery = {
  scrollValues: null,
  onShowMoreClicked: () => {},
};

const ImageGallery = ({
  preview,
  query,
  source,
  scrollValues,
  onImageSelected,
  onShowMoreClicked,
}) => {
  const { t } = useTranslation();
  const galleryRef = useRef(null);

  const [
    searchImages,
    {
      loading: searchImagesLoading,
      data: searchImageData,
      fetchMore: fetchMoreImages,
    },
  ] = useLazyQuery(SEARCH_IMAGES, {
    onError: (err) => {
      Logger.error(err);
    },
    // fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const searchResult = searchImageData ? searchImageData.searchImages : null;
  const currentPage =
    searchResult && searchResult.currentPage != null
      ? searchResult.currentPage
      : 1;

  useEffect(() => {
    searchImages({
      variables: { input: { query, source, page: 1 } },
    });
  }, [query, source]);

  useEffect(() => {
    if (!scrollValues) return;
    if (preview) return;

    if (
      searchImagesLoading ||
      (searchResult && currentPage >= searchResult.totalPages)
    )
      return;

    checkIfReloadNeeded();
  }, [scrollValues]);

  const onLoadMore = async (page) => {
    // console.log("onLoadMore page:" + page);

    try {
      await fetchMoreImages({
        variables: {
          input: { query, source, page },
        },
      });
    } catch (error) {
      Logger.error(error);
    }
  };

  const checkIfReloadNeeded = () => {
    if (
      scrollValues.scrollTop + scrollValues.offsetHeight >=
      scrollValues.scrollHeight - window.innerHeight / 2.5
    ) {
      // console.log("require reload");
      onLoadMore(currentPage + 1);
    }
  };

  let imageGallery = null;
  let noResultsContent = null;
  let showMoreContent = null;

  if (searchResult && searchResult.results.length > 0) {
    const imageContent = searchResult.results.map((imageResult, index) => {
      if (preview && index > 9) return null;
      return (
        <Image
          imageResult={imageResult}
          source={source}
          onImageSelected={(imageElement) => {
            onImageSelected({
              ...imageElement,
              source,
            });
          }}
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-${imageResult.authorUrl}-${imageResult.url}`}
          horizontalScroll={preview}
        />
      );
    });
    let showMoreTile = null;
    if (preview) {
      showMoreTile = (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className={styles.previewMoreTileContainer}
          onClick={() => {
            const percentageScrolled =
              (galleryRef.current.scrollLeft + galleryRef.current.clientWidth) /
              galleryRef.current.scrollWidth;
            onShowMoreClicked(percentageScrolled);
          }}
          tabIndex={0}
          role="button"
        >
          <span className={`icon is-small ${styles.previewMoreTileIcon}`}>
            <i className="fas fa-search" />
          </span>
          <span className={styles.previewMoreTileText}>{`${t(
            "wisdomtree.modal_choose_image.search.show_more",
            {
              source,
            }
          )}`}</span>
        </div>
      );
    }

    imageGallery = (
      <div
        className={preview ? styles.galleryHorizontal : styles.galleryVertical}
        ref={galleryRef}
      >
        {imageContent}
        {showMoreTile}
      </div>
    );
    showMoreContent = (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={styles.previewMoreContainer}
        onClick={() => {
          // const percentageScrolled =
          //   galleryRef.current.scrollLeft / galleryRef.current.scrollWidth;
          onShowMoreClicked(0);
        }}
        tabIndex={0}
        role="button"
      >
        <span className={`icon is-small ${styles.previewMoreIcon}`}>
          <i className="fas fa-search" />
        </span>
        <span className={styles.previewMoreText}>{`${t(
          "wisdomtree.modal_choose_image.search.show_more",
          {
            source,
          }
        )}`}</span>
      </div>
    );
  } else {
    noResultsContent = (
      <div className={styles.searchLoadingWrapper}>
        <p className={styles.noResults}>
          {t("wisdomtree.modal_choose_image.search.no_results", { source })}
        </p>
      </div>
    );
  }

  let loaderContent = null;
  if (searchImagesLoading) {
    noResultsContent = null;

    let loadingTextContent = null;
    if (preview) {
      loadingTextContent = (
        <p>
          {t("wisdomtree.modal_choose_image.search.search_loading", {
            source,
          })}
        </p>
      );
    }

    loaderContent = (
      <div className={styles.searchLoadingWrapper}>
        {loadingTextContent}
        <BeatLoader
          sizeUnit="px"
          size={10}
          color="#7A7A7A"
          loading={searchImagesLoading}
        />
      </div>
    );
  }

  if (searchImagesLoading) showMoreContent = null;

  if (preview) {
    if (searchImagesLoading && searchResult != null) loaderContent = null;

    return (
      <div className={`box ${styles.previewContainer}`}>
        {imageGallery}
        {noResultsContent}
        {loaderContent}
        {showMoreContent}
      </div>
    );
  }

  if (
    loaderContent == null &&
    searchResult &&
    currentPage < searchResult.totalPages
  ) {
    loaderContent = <div style={{ marginTop: 72 }} />;
  }

  return (
    <div>
      {imageGallery}
      {noResultsContent}
      {loaderContent}
    </div>
  );
};

ImageGallery.propTypes = propTypesImageGallery;
ImageGallery.defaultProps = defaultPropsImageGallery;

export default ImageGallery;
