import React from "react";
import PropTypes from "prop-types";

import styles from "./ImageSearchQueries.module.scss";

const propTypesImageSearchQueries = {
  searchQueries: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSearchQueryClicked: PropTypes.func.isRequired,
};
const defaultPropsImageSearchQueries = {};

const ImageSearchQueries = ({ searchQueries, onSearchQueryClicked }) => {
  const content = searchQueries.map((sq) => {
    return (
      <button
        className="button is-small"
        type="button"
        onClick={() => onSearchQueryClicked(sq)}
        key={sq}
      >
        <span className="icon is-small">
          <i className="fas fa-search" />
        </span>
        <span>{sq}</span>
      </button>
    );
  });
  return <div className={`buttons ${styles.container}`}>{content}</div>;
};

ImageSearchQueries.propTypes = propTypesImageSearchQueries;
ImageSearchQueries.defaultProps = defaultPropsImageSearchQueries;

export default ImageSearchQueries;
