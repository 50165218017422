/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-alert */
import React from "react";
import PropTypes from "prop-types";

import styles from "./CommonEntryVariant.module.scss";

const propTypesCommonEntryVariant = {
  commonEntryVariant: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    approved: PropTypes.bool,
    language: PropTypes.string,
    userCount: PropTypes.integer,
    createdAt: PropTypes.string,
    parentCommonEntry: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  onDeleteVariant: PropTypes.func,
  onApproveVariant: PropTypes.func,
  onDisapproveVariant: PropTypes.func,
  onUpdateVariantLanguage: PropTypes.func,
};
const defaultPropsCommonEntryVariant = {
  onDeleteVariant: () => {},
  onApproveVariant: () => {},
  onDisapproveVariant: () => {},
  onUpdateVariantLanguage: () => {},
};

const CommonEntryVariant = ({
  commonEntryVariant,
  onDeleteVariant,
  onApproveVariant,
  onDisapproveVariant,
  onUpdateVariantLanguage,
}) => {
  let parentContent = null;

  if (commonEntryVariant.parentCommonEntry) {
    parentContent = (
      <>
        <a
          href={`/admin/commonEntry/${commonEntryVariant.parentCommonEntry.id}`}
          className={styles.id}
        >
          CE-{commonEntryVariant.parentCommonEntry.id}
        </a>
        <span className={styles.name}>
          {commonEntryVariant.parentCommonEntry.name}
        </span>
      </>
    );
  } else {
    parentContent = <span className={styles.rootName}>ROOT</span>;
  }

  const deleteButtonContent = (
    <button
      type="button"
      className={`button is-light ${styles.deleteButton}`}
      onClick={() => {
        if (window.confirm("Delete this variant?")) {
          onDeleteVariant(commonEntryVariant.id);
        }
      }}
    >
      <span className={`icon ${styles.gray}`}>
        <i className="fas fa-trash" />
      </span>
    </button>
  );

  let approvedColor;
  let approveButtonsContent = null;
  if (commonEntryVariant.approved == null) {
    approvedColor = "yellow";
    approveButtonsContent = (
      <div className={styles.approveButtonsContainer}>
        <button
          type="button"
          className={`button is-light ${styles.approveButton}`}
          onClick={() => {
            onApproveVariant();
          }}
        >
          <span className={`icon ${styles.gray}`}>
            <i className="fas fa-check" />
          </span>
        </button>
        <button
          type="button"
          className={`button is-light ${styles.disapproveButton}`}
          onClick={() => {
            onDisapproveVariant();
          }}
        >
          <span className={`icon ${styles.gray}`}>
            <i className="fas fa-times" />
          </span>
        </button>
      </div>
    );
  } else if (commonEntryVariant.approved === true) {
    approvedColor = "green";
    approveButtonsContent = (
      <div className={styles.approveButtonsContainer}>
        <button
          type="button"
          className={`button is-light ${styles.disapproveButton}`}
          onClick={() => {
            onDisapproveVariant();
          }}
        >
          <span className={`icon ${styles.gray}`}>
            <i className="fas fa-times" />
          </span>
        </button>
      </div>
    );
  } else {
    approvedColor = "red";
    approveButtonsContent = (
      <div className={styles.approveButtonsContainer}>
        <button
          type="button"
          className={`button is-light ${styles.approveButton}`}
          onClick={() => {
            onApproveVariant();
          }}
        >
          <span className={`icon ${styles.gray}`}>
            <i className="fas fa-check" />
          </span>
        </button>
      </div>
    );
  }

  return (
    <div className={`box ${styles.container}`}>
      <div
        className={styles.approvedIndicator}
        style={{ backgroundColor: approvedColor }}
      />
      <div className={`${styles.outerContainer}`}>
        <div className={styles.vaiantTitleContainer}>
          <span className={`${styles.variantId}`}>
            Variant CEV-{commonEntryVariant.id}
          </span>
          <div className={styles.actionsContainer}>
            {approveButtonsContent}
            {deleteButtonContent}
          </div>
        </div>

        <div className={`${styles.innerContainer}`}>
          <div className={styles.parentVariantContainer}>{parentContent}</div>
          <span className={styles.parentSeperator}>-&gt;</span>
          <div className={styles.variantContainer}>
            <span className={styles.name}>{commonEntryVariant.name}</span>
          </div>
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.userCountContainer}>
            <span className={`icon ${styles.gray}`}>
              <i className="fas fa-users" />
            </span>
            <span>{commonEntryVariant.userCount}</span>
          </div>

          <div
            className={styles.languageContainer}
            onClick={() => {
              const input = window.prompt(
                "Enter a language string (e.g. de or en)"
              );
              onUpdateVariantLanguage(input);
            }}
          >
            <span className={`icon ${styles.gray}`}>
              <i className="fas fa-language" />
            </span>
            <span>
              {commonEntryVariant.language
                ? commonEntryVariant.language
                : "not set"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

CommonEntryVariant.propTypes = propTypesCommonEntryVariant;
CommonEntryVariant.defaultProps = defaultPropsCommonEntryVariant;

export default CommonEntryVariant;
