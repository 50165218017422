import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isMobile as isMobileDevice } from "react-device-detect";

import styles from "./BlockDailyQuoteButton.module.scss";
import { TooltipDelayed } from "../../../../../../UI/Tooltip/Tooltip";
import { hasQuestGoal } from "../../../../../../Utils/QuestHelper";
import QuestIcon from "../../../../../../UI/QuestIcon/QuestIcon";

const propTypesBlockDailyQuoteButton = {
  block: PropTypes.shape({
    id: PropTypes.string,
    cardsAmount: PropTypes.number,
    cardsActiveAmount: PropTypes.number,
  }).isRequired,
  user: PropTypes.shape({
    currentQuest: PropTypes.object,
  }),
  isHovered: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  buttonHidden: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};
const defaultPropsBlockDailyQuoteButton = {
  buttonHidden: false,
  user: null,
};

const BlockDailyQuoteButton = ({
  block,
  isHovered,
  isFocused,
  buttonHidden,
  onToggle,
}) => {
  const { t } = useTranslation();

  const dailyQuoteEnabled = block.dailyQuote === true;

  let shouldHide = true;
  if (!buttonHidden && (isMobileDevice || isHovered || isFocused)) {
    shouldHide = false;
  }

  const tooltip = dailyQuoteEnabled
    ? t("wisdomtree.entry_browser.tooltips.block_daily_quote_disable")
    : t("wisdomtree.entry_browser.tooltips.block_daily_quote_enable");

  const handleOnClick = () => {
    onToggle(dailyQuoteEnabled ? false : true);
    document.activeElement.blur();
  };

  return (
    <TooltipDelayed content={tooltip}>
      <button
        type="button"
        className={`button is-light ${styles.button} ${
          shouldHide ? styles.hidden : ""
        }`}
        data-id="button.daily-quote"
        onClick={() => handleOnClick()}
      >
        <span className={`icon ${styles.gray}`}>
          <i
            className={`far ${
              dailyQuoteEnabled ? "fa-check-square" : "fa-square"
            }`}
          />
        </span>
      </button>
    </TooltipDelayed>
  );
};

BlockDailyQuoteButton.propTypes = propTypesBlockDailyQuoteButton;
BlockDailyQuoteButton.defaultProps = defaultPropsBlockDailyQuoteButton;

export default BlockDailyQuoteButton;
