import { gql } from "@apollo/client";
import { FRAGMENT_COMPLETE_QUEST } from "./fragments";

const GET_CURRENT_USER = gql`
  query {
    me {
      id
      name
      email
      admin
      image {
        id
        url
      }
      isImageUpdating @client
      language
      theme
      sharesEntryNames
      dailyQuotesEnabled
      createdAt
      cardsActiveAmount
      cardsDueAmount
      welcomeConfirmed
      experienceAmount
      level
      levelProgress
      experienceMissingForNextLevel
      nextQuest
      currentQuest {
        ...CompleteQuest
      }
      jobToBeDone
      size
      shared
      notificationSettings {
        type
        active
        hourOfDay
      }
      hasBookmarks
      hasPushSubscriptions
    }
  }
  ${FRAGMENT_COMPLETE_QUEST}
`;

const GET_USER_PROFILE = gql`
  query($id: ID!) {
    userProfile(id: $id) {
      id
      name
      image {
        id
        url
      }
      level
      size
    }
  }
`;

const GET_ALL_USERS = gql`
  query {
    users {
      id
      name
      email
      image {
        id
        url
      }
      language
      theme
      sharesEntryNames
      createdAt
      lastLoginAt
      welcomeConfirmed
      experienceThisWeek
      level
      size
      jobToBeDone
    }
  }
`;

const GET_USER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      name
      email
      image {
        id
        url
      }
      language
      theme
      sharesEntryNames
      dailyQuotesEnabled
      createdAt
      lastLoginAt
      welcomeConfirmed
      level
      size
      jobToBeDone
      verified
      notificationSettings {
        type
        active
        hourOfDay
      }
    }
  }
`;

const GET_INVITE_URL = gql`
  query {
    me {
      id
      inviteUrl
    }
  }
`;

const GET_INVITED_BY = gql`
  query($inviteCode: String!) {
    invitedBy(inviteCode: $inviteCode)
  }
`;

const UPDATE_CURRENT_USER = gql`
  mutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      code
      message
      updatedUser {
        id
        image {
          id
          url
        }
        language
        theme
        sharesEntryNames
        dailyQuotesEnabled
        welcomeConfirmed
      }
    }
  }
`;

const UPDATE_NOTIFICATION_SETTING = gql`
  mutation($input: UpdateNotificationSettingInput!) {
    updateNotificationSetting(input: $input) {
      code
      message
      updatedUser {
        id
        notificationSettings {
          type
          active
          hourOfDay
        }
      }
    }
  }
`;

const CONFIRM_WELCOME = gql`
  mutation($input: ConfirmWelcomeInput!) {
    confirmWelcome(input: $input) {
      code
      message
      updatedUser {
        id
        welcomeConfirmed
        jobToBeDone
      }
    }
  }
`;

const UNSUBSCRIBE_USER_FROM_EMAILS = gql`
  mutation($token: String!) {
    unsubscribeUserFromEmails(token: $token) {
      code
      message
      emailType
    }
  }
`;

const ADD_PUSH_SUBSCRIPTION = gql`
  mutation($input: AddPushSubscriptionInput!) {
    addPushSubscription(input: $input) {
      code
      message
      updatedUser {
        id
        hasPushSubscriptions
      }
    }
  }
`;

const REMOVE_PUSH_SUBSCRIPTION = gql`
  mutation($input: RemovePushSubscriptionInput!) {
    removePushSubscription(input: $input) {
      code
      message
      updatedUser {
        id
        hasPushSubscriptions
      }
    }
  }
`;

const mergeUserAfterConfirmWelcome = (cache, mutationResult) => {
  mergeUserUpdates(cache, mutationResult.data.confirmWelcome.updatedUser);
};

const mergeUserAfterAddPushSubscription = (cache, mutationResult) => {
  mergeUserUpdates(cache, mutationResult.data.addPushSubscription.updatedUser);
};

const mergeUserAfterRemovePushSubscription = (cache, mutationResult) => {
  mergeUserUpdates(
    cache,
    mutationResult.data.removePushSubscription.updatedUser
  );
};

const mergeUserAfterUpdateNotificationSetting = (cache, mutationResult) => {
  mergeUserUpdates(
    cache,
    mutationResult.data.updateNotificationSetting.updatedUser
  );
};

const mergeUserAfterUpdate = (cache, mutationResult) => {
  mergeUserUpdates(cache, mutationResult.data.updateUser.updatedUser);
};

const mergeUserUpdates = (cache, updatedUser) => {
  const cachedData = cache.readQuery({
    query: GET_CURRENT_USER,
  });

  const cachedUser = cachedData.me;

  // merge card updates into to current data
  const updatedData = {
    ...cachedUser,
    ...updatedUser,
  };

  cache.writeQuery({
    query: GET_CURRENT_USER,
    data: {
      me: {
        ...updatedData,
      },
    },
  });
};

const processExpEvent = (userExpEvent) => {
  const event = new CustomEvent("expEvent", {
    detail: {
      exp: userExpEvent.exp,
      levelUp: userExpEvent.levelUp,
    },
  });
  window.dispatchEvent(event);
};

export {
  GET_CURRENT_USER,
  GET_USER_PROFILE,
  GET_USER,
  GET_ALL_USERS,
  GET_INVITE_URL,
  GET_INVITED_BY,
  UPDATE_CURRENT_USER,
  CONFIRM_WELCOME,
  UNSUBSCRIBE_USER_FROM_EMAILS,
  ADD_PUSH_SUBSCRIPTION,
  REMOVE_PUSH_SUBSCRIPTION,
  UPDATE_NOTIFICATION_SETTING,
  mergeUserAfterUpdate,
  mergeUserAfterConfirmWelcome,
  mergeUserUpdates,
  mergeUserAfterAddPushSubscription,
  mergeUserAfterRemovePushSubscription,
  mergeUserAfterUpdateNotificationSetting,
  processExpEvent,
};
