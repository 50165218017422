import i18n from "../../config/i18n";

export const getContentForQuest = (questType, jobToBeDone, firstTime) => {
  const t = i18n.t.bind(i18n);

  switch (questType) {
    case "QUEST_1":
      return [
        {
          type: "feature_video",
          videoUrl: t(
            `wisdomtree.quests.QUEST_1.intro_nodes.videoUrl.${jobToBeDone}`
          ),
          text: t(`wisdomtree.quests.QUEST_1.intro_nodes.text.${jobToBeDone}`),
        },
        {
          type: "feature_video",
          videoUrl: t(
            `wisdomtree.quests.QUEST_1.intro_blocks.videoUrl.${jobToBeDone}`
          ),
          text: t(`wisdomtree.quests.QUEST_1.intro_blocks.text.${jobToBeDone}`),
        },
        {
          type: "new_quest",
          title: t(`wisdomtree.quests.QUEST_1.objective.title.${jobToBeDone}`),
          subtitle: t(
            `wisdomtree.quests.QUEST_1.objective.subtitle.${jobToBeDone}`
          ),
          nextText: firstTime
            ? t("wisdomtree.quests.start")
            : t("wisdomtree.quests.resume"),
        },
      ];
    case "QUEST_2":
      return [
        {
          type: "feature_video",
          videoUrl: t(
            `wisdomtree.quests.QUEST_2.intro_images.videoUrl.${jobToBeDone}`
          ),
          text: t(`wisdomtree.quests.QUEST_2.intro_images.text.${jobToBeDone}`),
        },
        {
          type: "feature_video",
          videoUrl: t(
            `wisdomtree.quests.QUEST_2.intro_cards.videoUrl.${jobToBeDone}`
          ),
          text: t(`wisdomtree.quests.QUEST_2.intro_cards.text.${jobToBeDone}`),
        },
        {
          type: "new_quest",
          title: t(`wisdomtree.quests.QUEST_2.objective.title.${jobToBeDone}`),
          subtitle: t(
            `wisdomtree.quests.QUEST_2.objective.subtitle.${jobToBeDone}`
          ),
          nextText: firstTime
            ? t("wisdomtree.quests.start")
            : t("wisdomtree.quests.resume"),
        },
      ];
  }
};
