import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import PropTypes from "prop-types";
import i18next from "../../../config/i18n";

const propTypesReCaptchaWrapper = {
  children: PropTypes.node.isRequired,
};
const defaultPropsReCaptchaWrapper = {};

const ReCaptchaWrapper = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      language={i18next.language}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

ReCaptchaWrapper.propTypes = propTypesReCaptchaWrapper;
ReCaptchaWrapper.defaultProps = defaultPropsReCaptchaWrapper;

export default ReCaptchaWrapper;
