import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import styles from "./UnsubscribeFromEmails.module.scss";
import { useMutation } from "@apollo/client";
import { UNSUBSCRIBE_USER_FROM_EMAILS } from "../../GraphQl/user";
import Logger from "js-logger";
import Spinner from "../../UI/Spinner/Spinner";

const propTypesUnsubscribeFromEmails = {};
const defaultPropsUnsubscribeFromEmails = {};

const UnsubscribeFromEmails = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [token] = useState(queryString.parse(location.search).token);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const [unsubscribe, { loading: unsubscribeLoading }] = useMutation(
    UNSUBSCRIBE_USER_FROM_EMAILS,
    {
      onError: (error) => {
        Logger.error(error);
        setError(error);
      },
      onCompleted: (data) => {
        setResponse(data.unsubscribeUserFromEmails);
      },
    }
  );

  useEffect(() => {
    if (token) {
      unsubscribe({
        variables: {
          token,
        },
      });
    }
  }, [token]);

  let content = null;

  if (unsubscribeLoading) {
    content = (
      <div className={styles.innerContainer}>
        <div className={styles.loadingSpinner}>
          <Spinner />
        </div>

        <div className={styles.subtitle}>
          <p>{t("wisdomtree.unsubscribe.loading")}</p>
        </div>
      </div>
    );
  } else if (response) {
    if (response.code === "200") {
      let emailTypeName = null;

      switch (response.emailType) {
        case "USAGE_REVIEW":
          emailTypeName = t("wisdomtree.unsubscribe.types.USAGE_REVIEW");
          break;
        default:
          emailTypeName = "this type of email";
      }

      content = (
        <div className={styles.innerContainer}>
          <p className={styles.title}>
            {t("wisdomtree.unsubscribe.success_title")}
          </p>
          <div className={styles.subtitle}>
            <p>
              {t("wisdomtree.unsubscribe.success_subtitle", {
                type: emailTypeName,
              })}
            </p>
          </div>
          <a
            className={`button is-primary ${styles.button}`}
            href={process.env.REACT_APP_HOST}
          >
            {t("wisdomtree.unsubscribe.button")}
          </a>
        </div>
      );
    }
  } else if (error) {
    content = (
      <div className={styles.innerContainer}>
        <p className={styles.title}>
          {t("wisdomtree.unsubscribe.failure_title")}
        </p>
        <div className={styles.subtitle}>
          <p>{t("wisdomtree.unsubscribe.failure_subtitle1")}</p>
          <p>{t("wisdomtree.unsubscribe.failure_subtitle2")}</p>
        </div>
        <a
          className={`button is-primary ${styles.button}`}
          href={process.env.REACT_APP_HOST}
        >
          {t("wisdomtree.unsubscribe.button")}
        </a>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <span className={`icon ${styles.icon}`}>
        <i className="far fa-3x fa-envelope" />
      </span>
      {content}
    </div>
  );
};

UnsubscribeFromEmails.propTypes = propTypesUnsubscribeFromEmails;
UnsubscribeFromEmails.defaultProps = defaultPropsUnsubscribeFromEmails;

export default UnsubscribeFromEmails;
