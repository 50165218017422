import React, { useState } from "react";
import PropTypes from "prop-types";
import tinycolor from "tinycolor2";

import styles from "./Category.module.scss";
import {
  getDefaultTagColor,
  getTagTextColor,
  isColorLight,
} from "../../../Utils/Utils";
import TooltipCategory from "../../TooltipCategory/TooltipCategory";

const propTypesCategory = {
  category: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.shape({
      id: PropTypes.string,
      hex: PropTypes.string,
    }),
  }),
  onSelectCategory: PropTypes.func,
  isSelected: PropTypes.bool,
};
const defaultPropsCategory = {
  onSelectCategory: () => {},
  isSelected: false,
};

const Category = ({ category, onSelectCategory, isSelected }) => {
  const brightness = tinycolor(category.color.hex).getBrightness();
  const foregroundColor = getTagTextColor(brightness);

  return (
    <TooltipCategory category={category}>
      <div
        className={`tag is-light is-normal category ${styles.container}`}
        type="button"
        onClick={() => {
          onSelectCategory(category);
        }}
        style={isSelected ? { backgroundColor: category.color.hex } : null}
        tabIndex="0"
        onKeyDown={(e) => {
          // enter
          if (e.keyCode === 13) {
            onSelectCategory(category);
          }
        }}
      >
        <p
          className={styles.name}
          style={isSelected ? { color: foregroundColor } : null}
        >
          {category.name}
        </p>
      </div>
    </TooltipCategory>
  );
};

Category.propTypes = propTypesCategory;
Category.defaultProps = defaultPropsCategory;

export default Category;
