import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

import styles from "./Layout.module.scss";
import * as Constants from "../Constants";
import Spinner from "../../UI/Spinner/Spinner";
import GraphParallaxAnim from "../GraphParallaxAnim/GraphParallaxAnim";
import { useMobileOrTablet } from "../../Utils/Responsive";

const propTypesLayout = {
  header: PropTypes.node.isRequired,
  graph: PropTypes.node,
  scrollDownButton: PropTypes.node,
  info: PropTypes.node,
  entryBrowser: PropTypes.node,
  quiz: PropTypes.node,
  loading: PropTypes.bool,
  quizMode: PropTypes.bool,
  newMode: PropTypes.bool,
};
const defaultPropsLayout = {
  graph: null,
  scrollDownButton: null,
  info: null,
  entryBrowser: null,
  quiz: null,
  loading: false,
  quizMode: false,
  newMode: false,
};

const Layout = ({
  header,
  graph,
  scrollDownButton,
  info,
  entryBrowser,
  quiz,
  loading,
  quizMode,
  newMode,
  children,
}) => {
  const location = useLocation();
  const isTabletOrMobile = useMobileOrTablet();

  useEffect(() => {
    const captcha = document.getElementsByClassName("grecaptcha-badge")[0];
    if (captcha) {
      captcha.style.visibility = "hidden";
    }
  }, []);

  useEffect(() => {
    if (newMode) {
      scroll.scrollTo(window.innerHeight * 0.15, {
        duration: 400,
        ignoreCancelEvents: true,
      });
    }
  }, [newMode]);

  useEffect(() => {
    const quizLocation = location.pathname === "/quiz";

    if (quizLocation) return;

    if (newMode) {
      return;
    }

    const scrollYScrollTopThreshold = Constants.defaultScrollY(
      isTabletOrMobile,
      window.innerHeight
    );

    if (window.scrollY > scrollYScrollTopThreshold) {
      setTimeout(() => {
        scroll.scrollTo(scrollYScrollTopThreshold, {
          duration: 400,
        });
      }, 10);
    }
  }, [location]);

  const getGraphClasses = () => {
    if (quizMode) {
      return styles.graphContainerQuizMode;
    }

    return styles.graphContainerEntryBrowser;
  };

  let content = null;
  if (loading) {
    content = (
      <div className={styles.loadingSpinner}>
        <Spinner />
      </div>
    );
  } else {
    const contentTop = (
      <div
        id="graphContainer"
        className={`${styles.graphContainer} ${getGraphClasses()}`}
        data-id="graph.container"
      >
        <GraphParallaxAnim quizMode={quizMode}>{graph}</GraphParallaxAnim>
        {info}
        {scrollDownButton}
      </div>
    );

    let contentBottom = null;
    if (quizMode) {
      contentBottom = quiz;
    } else {
      contentBottom = entryBrowser;
    }

    content = (
      <>
        {contentTop}
        {contentBottom}
      </>
    );
  }

  return (
    <div className={styles.wisdomTreeContainer}>
      {header}
      {content}
      {children}
    </div>
  );
};

Layout.propTypes = propTypesLayout;
Layout.defaultProps = defaultPropsLayout;

export default Layout;
