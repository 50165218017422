import { gql } from "@apollo/client";

import { FRAGMENT_COMPLETE_CHANGE_NOTE } from "./fragments";

const GET_CHANGE_NOTES = gql`
  query {
    changeNotes {
      ...CompleteChangeNote
    }
  }
  ${FRAGMENT_COMPLETE_CHANGE_NOTE}
`;

const CREATE_CHANGE_NOTE = gql`
  mutation($input: CreateChangeNoteInput!) {
    createChangeNote(input: $input) {
      code
      message
      createdChangeNote {
        ...CompleteChangeNote
      }
    }
  }
  ${FRAGMENT_COMPLETE_CHANGE_NOTE}
`;

const mergeChangeNotesAfterCreate = (cache, mutationResult) => {
  const { changeNotes: currentData } = cache.readQuery({
    query: GET_CHANGE_NOTES,
  });

  const { createdChangeNote } = mutationResult.data.createChangeNote;

  if (createdChangeNote) {
    // add created entry to current data
    const updatedData = [createdChangeNote, ...currentData];
    cache.writeQuery({
      query: GET_CHANGE_NOTES,
      data: { changeNotes: updatedData },
    });
  }
};

export { GET_CHANGE_NOTES, CREATE_CHANGE_NOTE, mergeChangeNotesAfterCreate };
