import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Suggestion.module.scss";

const propTypesSuggestion = {
  suggestion: PropTypes.shape({
    name: PropTypes.string,
    userCount: PropTypes.number,
  }).isRequired,
  onSelectSuggestion: PropTypes.func.isRequired,
  showUserCount: PropTypes.bool,
};
const defaultPropsSuggestion = {
  showUserCount: true,
};

const Suggestion = ({ suggestion, onSelectSuggestion, showUserCount }) => {
  const nameContent = suggestion.name;

  let usersContent = null;
  if (showUserCount) {
    usersContent = (
      <div className={styles.userCountContainer}>
        <span className={styles.userCount}>{suggestion.userCount}</span>
        <span className={`icon ${styles.usersIcon}`}>
          <i className="fas fa-users" />
        </span>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.container, "suggestion")}
      onClick={() => onSelectSuggestion(suggestion)}
      onKeyDown={(e) => {
        // enter key
        if (e.keyCode === 13) {
          onSelectSuggestion(suggestion);
        }
      }}
      role="button"
      tabIndex={0}
      data-id="new-entry-suggestion"
    >
      <span className={styles.name}>{nameContent}</span>
      {usersContent}
    </div>
  );
};

Suggestion.propTypes = propTypesSuggestion;
Suggestion.defaultProps = defaultPropsSuggestion;

export default Suggestion;
