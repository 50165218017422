import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import Logger from "js-logger";
import classNames from "classnames";

// eslint-disable-next-line no-unused-vars
import styles from "../UserProfileModal.module.scss";
import BaseModal from "../../BaseModal/BaseModal";
import {
  GET_CURRENT_USER,
  mergeUserAfterUpdate,
  UPDATE_CURRENT_USER,
} from "../../../../GraphQl/user";
import Spinner from "../../../Spinner/Spinner";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import ClickableImage from "../../../ClickableImage/ClickableImage";
import useChooseImageModal from "../../ChooseImageModal/ChooseImageModal";
import IndicatorCircle from "../../../UserLevel/IndicatorCircle/IndicatorCircle";
import ExpProgress from "../../../UserLevel/ExpProgress/ExpProgress";
import { setImageUpdatingForEntity } from "../../../../Utils/ApolloUtils";

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  onDialogOpenedChanged: PropTypes.func,
};

const defaultPropsModal = {
  onDialogOpenedChanged: () => {},
};

const CurrentUserModal = ({ show, onCloseHandler, onDialogOpenedChanged }) => {
  const { t } = useTranslation();

  const {
    loading: getCurrentUserModalLoading,
    data: getCurrentUserModalData,
  } = useQuery(GET_CURRENT_USER);
  const user = getCurrentUserModalData ? getCurrentUserModalData.me : null;

  const [updateUser] = useMutation(UPDATE_CURRENT_USER, {
    update: (cache, mutationResult) => {
      mergeUserAfterUpdate(cache, mutationResult);
    },
  });

  const removeUserImage = () => {
    const optimisticResponse = {
      __typename: "Mutation",
      updateUser: {
        __typename: "UpdateUserResponse",
        code: 200,
        message: "user updated",
        updatedUser: { ...user, image: null },
      },
    };
    updateUser({
      variables: {
        input: {
          imageInput: null,
        },
      },
      optimisticResponse,
    });
  };

  const {
    open: openChooseImageModal,
    content: chooseImageModalContent,
  } = useChooseImageModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
    onlyFileUpload: true,
    nested: true,
    onImageChosen: (imageInput) => {
      if (imageInput == null) {
        removeUserImage();
      } else {
        setImageUpdatingForEntity({
          typename: "User",
          id: user.id,
          isUpdating: true,
        });
        updateUser({
          variables: {
            input: {
              imageInput: imageInput ? { ...imageInput } : null,
            },
          },
          update: (cache, mutationResult) => {
            mergeUserAfterUpdate(cache, mutationResult);
            setImageUpdatingForEntity({
              typename: "User",
              id: user.id,
              isUpdating: false,
            });
          },
        }).catch((error) => {
          Logger.error(error);
          setImageUpdatingForEntity({
            typename: "User",
            id: user.id,
            isUpdating: false,
          });
        });
      }
    },
  });

  let bodyContent = null;
  if (getCurrentUserModalLoading) {
    bodyContent = <Spinner />;
  } else {
    const contentUserImage = (
      <div className={styles.imageContainer}>
        <ClickableImage
          editMode
          imageUrl={user.image ? user.image.url : null}
          isLoading={user.isImageUpdating}
          emptyIcon="fa-user"
          height={96}
          width={96}
          onImageClicked={() => {
            if (!user.isImageUpdating) {
              openChooseImageModal({
                existingImageOfEntity: user.image,
                entityType: "user",
              });
            }
          }}
          onImageDropped={(drop) => {
            if (!user.isImageUpdating) {
              openChooseImageModal({
                existingImageOfEntity: user.image,
                entityType: "user",
                droppedContent: drop,
              });
            }
          }}
          altText="user"
          round
          preview={false}
          tooltip={t("wisdomtree.entry_browser.tooltips.user_add_image")}
          dropAllowed={true}
        />
        <div className={styles.indicatorContainer}>
          <IndicatorCircle number={user.level} />
        </div>
      </div>
    );

    const contentUserName = (
      <span className={styles.userName}>{user.name}</span>
    );

    const contentUserTitle = (
      <span className={styles.userTitle}>
        {t(`wisdomtree.gamification.titles.${Math.min(user.level, 10)}`)}
      </span>
    );

    const contentProgress =
      user.level < 99 ? (
        <div className={styles.progressContainer}>
          <ExpProgress levelProgress={user.levelProgress} />
          <div className={styles.expMissingContainer}>
            <span>{user.experienceMissingForNextLevel}</span>
            <span className={classNames("icon", styles.expIcon)}>
              <i className="fas fa-seedling" />
            </span>
            <span>{t("wisdomtree.modal_user_profile.exp_needed")}</span>
          </div>
        </div>
      ) : (
        <div className={styles.progressContainer}>
          <span className={styles.textMaxLevel}>
            {t("wisdomtree.modal_user_profile.max_level")}
          </span>
        </div>
      );

    bodyContent = (
      <div className={styles.container}>
        {contentUserImage}
        {contentUserName}
        {contentUserTitle}
        {contentProgress}
      </div>
    );
  }

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_user_profile.title_current_user")}
          </p>
          <ModalCloseButton onClick={onCloseHandler} />
        </header>
        <section className="modal-card-body">{bodyContent}</section>
        <footer className="modal-card-foot">
          <button className="button" type="button" onClick={onCloseHandler}>
            {t("wisdomtree.modal_user_profile.confirm")}
          </button>
        </footer>
      </div>
      {chooseImageModalContent}
    </BaseModal>
  );
};

CurrentUserModal.propTypes = propTypesModal;
CurrentUserModal.defaultProps = defaultPropsModal;

export default CurrentUserModal;
