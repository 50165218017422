import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./CommonEntry.module.scss";

const propTypesCommonEntry = {
  commonEntry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    userCount: PropTypes.number,
    createdAt: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        language: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};
const defaultPropsCommonEntry = {};

const CommonEntry = ({ commonEntry }) => {
  const hasPendingApproval =
    commonEntry.variants.find((v) => v.approved == null) != null;

  return (
    <Link
      className={`box ${styles.container}`}
      to={`/admin/commonEntry/${commonEntry.id}`}
    >
      <div className={styles.innerContainer}>
        <div
          className={styles.approvedIndicator}
          style={{
            backgroundColor: hasPendingApproval ? "yellow" : "transparent",
          }}
        />
        <div className={styles.infoContainer}>
          <span className={styles.id}>{commonEntry.id}</span>
          <span className={styles.name}>{commonEntry.name}</span>
          <div className={styles.extraInfoContainer}>
            <div className={styles.variantCountContainer}>
              <span className={`icon ${styles.gray}`}>
                <i className="fas fa-shapes" />
              </span>
              <span>{commonEntry.variants.length}</span>
            </div>
            <div className={styles.userCountContainer}>
              <span className={`icon ${styles.gray}`}>
                <i className="fas fa-users" />
              </span>
              <span>{commonEntry.userCount}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

CommonEntry.propTypes = propTypesCommonEntry;
CommonEntry.defaultProps = defaultPropsCommonEntry;

export default CommonEntry;
