/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import styles from "./FeatureRequestModal.module.scss";
import EditBlockWeb from "./SubmitFeatureRequest/SubmitFeatureRequest";
import SubmitFeatureRequest from "./SubmitFeatureRequest/SubmitFeatureRequest";
import BaseModal, { useBaseModal } from "../../../UI/Modal/BaseModal/BaseModal";
import EditFeatureRequest from "./EditFeatureRequest/EditFeatureRequest";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  onCreate: () => {},
};

const useFeatureRequestModal = ({ onOpen, onClose, onCreate }) => {
  const [featureRequest, setFeatureRequest] = useState(null);
  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose: () => {
      onClose();
    },
  });

  const openModal = (existingFeatureRequest) => {
    setFeatureRequest(existingFeatureRequest);
    open();
  };

  const closeModal = () => {
    close();
    setFeatureRequest(null);
  };

  let content = null;
  if (isOpened) {
    let innerContent = null;
    if (featureRequest == null) {
      innerContent = (
        <SubmitFeatureRequest onCloseModal={closeModal} onCreate={onCreate} />
      );
    } else {
      innerContent = (
        <EditFeatureRequest
          featureRequest={featureRequest}
          onCloseModal={closeModal}
        />
      );
    }

    content = (
      <BaseModal show={isOpened} onCloseHandler={closeModal}>
        {innerContent}
      </BaseModal>
    );
  }

  return { open: openModal, close: closeModal, content };
};

useFeatureRequestModal.propTypes = propTypes;
useFeatureRequestModal.defaultProps = defaultProps;

export default useFeatureRequestModal;
