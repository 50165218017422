import React from "react";
import PropTypes from "prop-types";

import styles from "./ReadBlockProgress.module.scss";
import { TooltipDelayed } from "../Tooltip/Tooltip";
import { useTranslation } from "react-i18next";

const propTypesReadBlockProgress = { percent: PropTypes.number };
const defaultPropsReadBlockProgress = {
  percent: 0,
};

const ReadBlockProgress = ({ percent }) => {
  const { t } = useTranslation();
  const finished = percent >= 100;

  return (
    <TooltipDelayed content={t("wisdomtree.entry_browser.tooltips.dusted")}>
      <div
        className={`${styles.container} tag ${
          finished ? styles.animateOut : null
        }`}
      >
        <span className={styles.text}>
          {t("wisdomtree.entry_browser.dusted")}
        </span>
        <div className={styles.fill} style={{ width: `${percent}%` }} />
        <span
          className={`icon ${styles.icon} ${finished ? styles.visible : null}`}
        >
          <i className="fas fa-check" />
        </span>
      </div>
    </TooltipDelayed>
  );
};

ReadBlockProgress.propTypes = propTypesReadBlockProgress;
ReadBlockProgress.defaultProps = defaultPropsReadBlockProgress;

export default ReadBlockProgress;
