/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
import styles from "./CancelQuizModal.module.css";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useCancelQuizModal = ({ onOpen, onClose, onConfirm }) => {
  const { isOpened, open, close } = useBaseModal({ onOpen, onClose });
  const [stateEntryId, setStateEntryId] = useState(null);

  let content = null;
  if (isOpened) {
    content = (
      <CancelQuizModal
        show={isOpened}
        onCloseHandler={close}
        entryId={stateEntryId}
        onConfirmHandler={onConfirm}
      />
    );
  }

  const openModal = (entryId) => {
    setStateEntryId(entryId);
    open();
  };

  const closeModal = () => {
    setStateEntryId(null);
    close();
  };

  return { open: openModal, close: closeModal, content };
};

useCancelQuizModal.propTypes = propTypes;
useCancelQuizModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  entryId: PropTypes.string,
  onConfirmHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
};

const defaultPropsModal = {
  entryId: null,
};

const CancelQuizModal = ({
  show,
  entryId,
  onConfirmHandler,
  onCloseHandler,
}) => {
  const { t } = useTranslation();

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      onConfirmHandler(entryId);
    },
    dependsOn: [onConfirmHandler],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!show) return;
      onCloseHandler();
    },
    dependsOn: [onCloseHandler, show],
  });

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_cancel_quiz.title")}
          </p>
          <ModalCloseButton onClick={onCloseHandler} />
        </header>
        <section className="modal-card-body">
          <p>{t("wisdomtree.modal_cancel_quiz.text")}</p>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-primary"
            type="submit"
            onClick={() => {
              onConfirmHandler(entryId);
            }}
          >
            {t("wisdomtree.modal_cancel_quiz.confirm")}
          </button>
          <button className="button" type="button" onClick={onCloseHandler}>
            {t("wisdomtree.modal_cancel_quiz.cancel")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

CancelQuizModal.propTypes = propTypesModal;
CancelQuizModal.defaultProps = defaultPropsModal;

export default useCancelQuizModal;
