import React from "react";
import PropTypes from "prop-types";

import styles from "./EditReferenceText.module.scss";
import { useTranslation } from "react-i18next";

const propTypesEditReferenceText = {
  title: PropTypes.string,
  description: PropTypes.string,
  onChangeTitle: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
};
const defaultPropsEditReferenceText = {
  title: "",
  description: "",
};

const EditReferenceText = ({
  title,
  description,
  onChangeTitle,
  onChangeDescription,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.inputContainer}>
        <div className={`control has-icons-left`}>
          <input
            className={`input ${styles.input}`}
            type="text"
            value={title}
            onChange={onChangeTitle}
            autoFocus
            maxLength={255}
            placeholder={t(
              "wisdomtree.modal_add_reference.add_text.placeholder_title"
            )}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-edit"></i>
          </span>
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={`control has-icons-left`}>
          <input
            className={`input ${styles.input}`}
            type="text"
            value={description}
            onChange={onChangeDescription}
            maxLength={255}
            placeholder={t(
              "wisdomtree.modal_add_reference.add_text.placeholder_description"
            )}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-question-circle"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

EditReferenceText.propTypes = propTypesEditReferenceText;
EditReferenceText.defaultProps = defaultPropsEditReferenceText;

export default EditReferenceText;
