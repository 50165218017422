import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import Logger from "js-logger";
import { Helmet } from "react-helmet";

import styles from "./Quiz.module.scss";
import { useHotkey } from "../../Utils/Hotkeys";
import MenuBarQuiz from "./MenuBarQuiz/MenuBarQuiz";
import {
  NEXT_CARDS,
  PUBLISH_RESULTS,
  mergeChangesAfterPublishResults,
} from "../../GraphQl/quiz";
import Spinner from "../../UI/Spinner/Spinner";
import QuizCard from "./QuizCard/QuizCard";
import QuizResult from "./QuizResult/QuizResult";
import useTimeTracking from "../../Utils/UseTimeTracking";

const propTypesQuiz = {
  rootEntryId: PropTypes.string,
  onSelectEntry: PropTypes.func.isRequired,
  onShowResult: PropTypes.func.isRequired,
  onAttemptCancelQuiz: PropTypes.func.isRequired,
  selectedEntryId: PropTypes.string,
  dialogOpened: PropTypes.bool,
  user: PropTypes.shape({
    quizReminder: PropTypes.bool,
  }),
};
const defaultPropsQuiz = {
  rootEntryId: undefined,
  selectedEntryId: null,
  dialogOpened: false,
};

const Quiz = ({
  user,
  rootEntryId,
  onSelectEntry,
  onShowResult,
  selectedEntryId,
  onAttemptCancelQuiz,
  dialogOpened,
}) => {
  useTimeTracking({ entryId: selectedEntryId });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!dialogOpened) {
        onAttemptCancelQuiz(rootEntryId);
      }
    },
    dependsOn: [rootEntryId, dialogOpened],
  });

  const {
    loading: nextCardsLoading,
    data: nextCards,
    refetch,
    networkStatus,
  } = useQuery(NEXT_CARDS, {
    variables: {
      entryId: rootEntryId,
      maxAmount: 10,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  const cards = nextCards ? nextCards.nextCardsForQuiz : null;

  const [publishResults, { loading: publishResultsLoading }] = useMutation(
    PUBLISH_RESULTS,
    {
      update: (cache, mutationResult) => {
        const { expEvent } = mutationResult.data.publishQuizResults;
        setExpEvent(expEvent);
        mergeChangesAfterPublishResults(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
    }
  );

  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [currentCard, setCurrentCard] = useState(null);
  const [initalDelayRunning, setInitialDelayRunning] = useState(true);
  const [results, setResults] = useState([]);
  const [finished, setFinished] = useState(false);
  const [expEvent, setExpEvent] = useState(null);

  useEffect(() => {
    if (cards !== null && currentCardIndex < cards.length) {
      const nextCard = cards[currentCardIndex];
      setCurrentCard(nextCard);

      if (currentCardIndex === 0) {
        setInitialDelayRunning(true);
        setTimeout(() => {
          onSelectEntry(nextCard.block.entry);
          setInitialDelayRunning(false);
        }, 600);
      } else {
        onSelectEntry(nextCard.block.entry);
      }
    }
  }, [cards, currentCardIndex]);

  const onCardFinishedHandler = (card, rating) => {
    results.push({
      cardId: card.id,
      performanceRating: rating,
    });

    if (currentCardIndex === cards.length - 1) {
      setFinished(true);
      setCurrentCardIndex(currentCardIndex + 1);
      onShowResult();
      publishResults({
        variables: {
          input: {
            entryId: rootEntryId,
            quizResults: results,
          },
        },
      });
    } else {
      setCurrentCardIndex(currentCardIndex + 1);
    }
  };

  const onNextCardsHandler = () => {
    setResults([]);
    setFinished(false);
    setCurrentCardIndex(0);
    setCurrentCard(null);
    refetch();
  };

  let content = null;
  let contentMenuBar = null;
  let quizContent = null;

  const isLoading =
    nextCardsLoading || initalDelayRunning || networkStatus === 4;

  contentMenuBar = (
    <MenuBarQuiz
      loading={isLoading}
      currentCardIndex={currentCardIndex}
      maxCards={cards ? cards.length : 0}
      onAttemptCancelQuiz={() => onAttemptCancelQuiz(rootEntryId)}
    />
  );

  if (isLoading) {
    quizContent = <Spinner />;
  } else if (currentCard !== null) {
    if (finished) {
      if (publishResultsLoading) {
        quizContent = <Spinner />;
      } else {
        quizContent = (
          <QuizResult
            user={user}
            results={results}
            expEvent={expEvent}
            rootEntryId={rootEntryId}
            onNext={onNextCardsHandler}
          />
        );
      }
    } else {
      quizContent = (
        <QuizCard card={currentCard} onCardFinished={onCardFinishedHandler} />
      );
    }
  }

  content = (
    <>
      <div className={`box ${styles.quizHeader}`}>{contentMenuBar}</div>
      {quizContent}
    </>
  );

  return (
    <>
      <Helmet>
        <title>Quiz - WisdomTree</title>
      </Helmet>
      <div
        className={`columns is-mobile ${styles.quiz} ${
          initalDelayRunning ? styles.quizInitial : styles.quizNoScroll
        }`}
      >
        <div className="column" />
        <div className="column is-11-mobile is-two-thirds-tablet is-half-widescreen">
          {content}
        </div>
        <div className="column" />
      </div>
    </>
  );
};

Quiz.propTypes = propTypesQuiz;
Quiz.defaultProps = defaultPropsQuiz;

export default Quiz;
