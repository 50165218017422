import React from "react";
import PropTypes from "prop-types";

import styles from "./CardButton.module.scss";
import Spinner from "../Spinner/Spinner";

const propTypesCardButton = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
const defaultPropsCardButton = {
  subtitle: null,
  loading: false,
  icon: null,
};

const CardButton = ({ title, subtitle, icon, loading, onClick }) => {
  let iconContent = null;
  if (icon || loading) {
    iconContent = loading ? (
      <Spinner size={6} />
    ) : (
      <span className={`icon ${styles.icon}`}>
        <i className={`fas ${icon}`} />
      </span>
    );
  }

  return (
    <div
      className={`box ${styles.container}`}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        // enter key
        if (e.keyCode === 13) {
          onClick();
        }
      }}
    >
      <div className={styles.iconContainer}>{iconContent}</div>
      <div className={styles.textContainer}>
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
    </div>
  );
};

CardButton.propTypes = propTypesCardButton;
CardButton.defaultProps = defaultPropsCardButton;

export default CardButton;
