import React, { useState } from "react";
import PropTypes from "prop-types";
import tinycolor from "tinycolor2";

import styles from "./ColorSwatch.module.scss";
import { isBrightnessLight } from "../../../Utils/Utils";

const propTypesColorSwatch = {
  color: PropTypes.shape({
    id: PropTypes.string,
    hex: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  onSelectColor: PropTypes.func,
};
const defaultPropsColorSwatch = {
  color: null,
  isSelected: false,
  onSelectColor: () => {},
};

const ColorSwatch = ({ color, isSelected, onSelectColor }) => {
  const [hovered, setHovered] = useState(false);
  const [focussed, setFocussed] = useState(false);

  const hexValue = color ? color.hex : "#fff";

  const brightness = tinycolor(hexValue).getBrightness();
  const isLight = isBrightnessLight(brightness);

  let colorToDisplay = hexValue;
  if (hovered || focussed) {
    colorToDisplay = isLight
      ? tinycolor(colorToDisplay).darken(4)
      : tinycolor(colorToDisplay).lighten(4);
  }

  return (
    <div
      className={`${styles.container} color-swatch`}
      style={{ backgroundColor: colorToDisplay }}
      onClick={() => {
        onSelectColor(color);
      }}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        // enter key
        if (e.keyCode === 13) {
          onSelectColor(color);
        }
      }}
      onMouseOver={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onFocus={() => {
        setFocussed(true);
      }}
      onBlur={() => {
        setFocussed(false);
      }}
    >
      {isSelected ? (
        <span
          className={`icon ${styles.icon} ${isLight ? styles.light : null}`}
        >
          <i className="fas fa-2x fa-check" />
        </span>
      ) : null}
    </div>
  );
};

ColorSwatch.propTypes = propTypesColorSwatch;
ColorSwatch.defaultProps = defaultPropsColorSwatch;

export default ColorSwatch;
