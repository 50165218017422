import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
import styles from "../UserProfileModal.module.scss";
import BaseModal from "../../BaseModal/BaseModal";
import { GET_USER_PROFILE } from "../../../../GraphQl/user";
import Spinner from "../../../Spinner/Spinner";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import ClickableImage from "../../../ClickableImage/ClickableImage";
import IndicatorCircle from "../../../UserLevel/IndicatorCircle/IndicatorCircle";

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  onCloseHandler: PropTypes.func.isRequired,
};

const defaultPropsModal = {
  userId: null,
};

const OtherUserModal = ({ show, userId, onCloseHandler }) => {
  const { t } = useTranslation();

  const {
    loading: getOtherUserModalLoading,
    data: getOtherUserModalData,
  } = useQuery(GET_USER_PROFILE, { variables: { id: userId } });

  const user = getOtherUserModalData ? getOtherUserModalData.userProfile : null;

  let bodyContent = null;
  if (getOtherUserModalLoading) {
    bodyContent = <Spinner />;
  } else {
    const contentUserImage = (
      <div className={styles.imageContainer}>
        <ClickableImage
          editMode={false}
          imageUrl={user.image ? user.image.url : null}
          emptyIcon="fa-user"
          height={96}
          width={96}
          onImageClicked={() => {}}
          altText="user"
          round
          preview={false}
          dropAllowed={false}
        />
        <div className={styles.indicatorContainer}>
          <IndicatorCircle number={user.level} />
        </div>
      </div>
    );

    const contentUserName = (
      <span className={styles.userName}>{user.name}</span>
    );

    const contentUserTitle = (
      <span className={styles.userTitle}>
        {t(`wisdomtree.gamification.titles.${Math.min(user.level, 10)}`)}
      </span>
    );

    bodyContent = (
      <div className={styles.container}>
        {contentUserImage}
        {contentUserName}
        {contentUserTitle}
      </div>
    );
  }

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_user_profile.title_other_user")}
          </p>
          <ModalCloseButton onClick={onCloseHandler} />
        </header>
        <section className="modal-card-body">{bodyContent}</section>
        <footer className="modal-card-foot">
          <button className="button" type="button" onClick={onCloseHandler}>
            {t("wisdomtree.modal_user_profile.confirm")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

OtherUserModal.propTypes = propTypesModal;
OtherUserModal.defaultProps = defaultPropsModal;

export default OtherUserModal;
