import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";
import { useQuery, useMutation } from "@apollo/client";

import styles from "../../EntryBrowser.module.scss";
import { GET_BLOCK_WITH_CARDS } from "../../../../GraphQl/blocks";
import {
  CREATE_CARD,
  DELETE_CARD,
  mergeCardsAfterCreate,
  mergeCardsAfterUpdate,
  mergeCardsAfterDelete,
  UPDATE_CARD,
} from "../../../../GraphQl/cards";
import Spinner from "../../../../UI/Spinner/Spinner";
import SelectedBlockHeader from "./SelectedBlockHeader/SelectedBlockHeader";
import AddCardDropdown from "./AddCardDropdown/AddCardDropdown";
import Card from "./Card/Card";
import useDeleteCardModal from "../../../../UI/Modal/DeleteCardModal/DeleteCardModal";
import useChooseImageModal from "../../../../UI/Modal/ChooseImageModal/ChooseImageModal";
import { setImageUpdatingForEntity } from "../../../../Utils/ApolloUtils";

const propTypes = {
  editMode: PropTypes.bool,
  selectedBlockId: PropTypes.string.isRequired,
  onChangeNestedDialogOpened: PropTypes.func.isRequired,
};

const defaultProps = {
  editMode: false,
  selectedEntry: null,
  selectedBlockId: null,
};

const ManageCardsContent = ({
  editMode,
  selectedBlockId,
  onChangeNestedDialogOpened,
}) => {
  const addCardContainerRef = useRef(null);

  const { loading: getBlockLoading, data: getBlock } = useQuery(
    GET_BLOCK_WITH_CARDS,
    {
      variables: { id: selectedBlockId },
      onError: (err) => {
        Logger.error(err);
      },
    }
  );

  const block = getBlock ? getBlock.block : null;
  const cards = getBlock ? getBlock.block.cards : [];

  useEffect(() => {
    if (addCardContainerRef.current && !getBlockLoading) {
      setTimeout(() => {
        addCardContainerRef.current.scrollIntoView();
      }, 20);
    }
  }, [addCardContainerRef, getBlockLoading]);

  const [createCard, { loading: createCardLoading }] = useMutation(
    CREATE_CARD,
    {
      update: (cache, mutationResult) => {
        mergeCardsAfterCreate(block.id, cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
    }
  );

  const [updateCard] = useMutation(UPDATE_CARD, {
    update: (cache, mutationResult) => {
      mergeCardsAfterUpdate(block.id, cache, mutationResult);
    },
  });

  const [deleteCard, { loading: deleteCardLoading }] = useMutation(
    DELETE_CARD,
    {
      update: (cache, mutationResult) => {
        mergeCardsAfterDelete(block.id, cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
        closeDeleteCardModal();
      },
      onCompleted: () => {
        closeDeleteCardModal();
      },
    }
  );

  const removeCard = (card, imageInputField) => {
    let optimisticUpdatedCard = { ...card };

    let imageFieldName = null;
    if (imageInputField === "frontImageInput") {
      imageFieldName = "frontImage";
    } else if (imageInputField === "backImageInput") {
      imageFieldName = "backImage";
    }
    optimisticUpdatedCard[imageFieldName] = null;

    const optimisticResponse = {
      __typename: "Mutation",
      updateCard: {
        __typename: "UpdateCardResponse",
        code: 200,
        success: true,
        message: "card updated",
        updatedCard: optimisticUpdatedCard,
        updatedEntries: [],
        updatedBlocks: [],
        updatedUser: null,
        expEvent: null,
      },
    };

    updateCard({
      variables: {
        id: card.id,
        input: {
          [imageInputField]: null,
        },
      },
      optimisticResponse,
    });
  };

  const onRemoveCardImage = (card, imageField) => {
    removeCard(card, imageField);
  };

  const onConfirmDeleteCard = (card) => {
    deleteCard({
      variables: { id: card.id },
    });
  };

  const {
    open: openDeleteCardModal,
    close: closeDeleteCardModal,
    content: deleteCardModalContent,
  } = useDeleteCardModal({
    onOpen: () => onChangeNestedDialogOpened(true),
    onClose: () => onChangeNestedDialogOpened(false),
    onDeleteCard: onConfirmDeleteCard,
    loading: deleteCardLoading,
    nested: true,
  });

  const {
    open: openChooseImageModal,
    content: chooseImageModalContent,
  } = useChooseImageModal({
    onOpen: () => onChangeNestedDialogOpened(true),
    onClose: () => onChangeNestedDialogOpened(false),
    nested: true,
    onImageChosen: ({ card, imageInputField }, imageInput) => {
      if (imageInput == null) {
        removeCard(card, imageInputField);
      } else {
        let fieldName = null;
        if (imageInputField === "frontImageInput") {
          fieldName = "isFrontImageUpdating";
        } else if (imageInputField === "backImageInput") {
          fieldName = "isBackImageUpdating";
        }
        setImageUpdatingForEntity({
          typename: "Card",
          id: card.id,
          fieldName,
          isUpdating: true,
        });
        updateCard({
          variables: {
            id: card.id,
            input: {
              [imageInputField]: { ...imageInput },
            },
          },
          update: (cache, mutationResult) => {
            mergeCardsAfterUpdate(block.id, cache, mutationResult);
            setImageUpdatingForEntity({
              typename: "Card",
              id: card.id,
              fieldName,
              isUpdating: false,
            });
          },
        }).catch((error) => {
          Logger.error(error);
          setImageUpdatingForEntity({
            typename: "Card",
            id: card.id,
            fieldName,
            isUpdating: false,
          });
        });
      }
    },
  });

  let content = null;
  let contentBlockHeader = null;
  let contentList = null;
  let contentAddCard = null;

  if (getBlockLoading) {
    contentList = <Spinner />;
  } else {
    contentBlockHeader = (
      <div
        className={`box ${styles.selectionAndMenuWrapper} ${styles.overflowHidden}`}
      >
        <SelectedBlockHeader editMode={editMode} block={block} />
      </div>
    );
    contentList = cards.map((card) => {
      return (
        <Card
          key={card.id}
          card={card}
          editMode={editMode}
          onAddImage={(imageField, imageInputField, droppedContent) => {
            setTimeout(() => {
              openChooseImageModal({
                entityToChooseImageFor: { card, imageInputField },
                existingImageOfEntity: card[imageField],
                entityType: "card",
                droppedContent: droppedContent,
              });
            }, 50);
          }}
          onRemoveImage={(imageField) => onRemoveCardImage(card, imageField)}
          onDeleteCard={() => {
            openDeleteCardModal(card);
          }}
          onUpdateCard={(id, updates, optimistic) => {
            let debounceKey = id;
            if (updates.frontTextFromParent != null)
              debounceKey += "frontTextFromParent";
            if (updates.frontText) debounceKey += "frontText";
            if (updates.backTextFromParent != null)
              debounceKey += "backTextFromParent";
            if (updates.backText) debounceKey += "backText";

            let optimisticResponse = null;
            if (optimistic) {
              const cardToUpdate = cards.find((e) => e.id === id);
              const optimisticUpdate = {
                ...cardToUpdate,
                ...updates,
              };

              optimisticResponse = {
                __typename: "Mutation",
                updateCard: {
                  __typename: "UpdateCardResponse",
                  code: 200,
                  success: true,
                  message: "card updated",
                  updatedCard: optimisticUpdate,
                  updatedEntries: [],
                  updatedBlocks: [],
                  updatedUser: null,
                  expEvent: null,
                },
              };
            }

            updateCard({
              variables: {
                id,
                input: {
                  ...updates,
                },
              },
              optimisticResponse,
              context: {
                debounceKey,
              },
            });
          }}
        />
      );
    });

    if (editMode) {
      contentAddCard = (
        <div ref={addCardContainerRef} style={{ alignSelf: "center" }}>
          <AddCardDropdown
            onCreateCard={(cardType) => {
              createCard({
                variables: {
                  input: {
                    blockId: block.id,
                    type: cardType,
                  },
                },
              });
            }}
            isLoading={createCardLoading}
          />
        </div>
      );
    }
  }

  content = (
    <>
      {contentBlockHeader}

      <div className={styles.entryListWrapper}>
        {contentList}
        {contentAddCard}
      </div>

      {deleteCardModalContent}
      {chooseImageModalContent}
    </>
  );

  return content;
};

ManageCardsContent.propTypes = propTypes;
ManageCardsContent.defaultProps = defaultProps;

export default ManageCardsContent;
