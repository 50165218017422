import React from "react";
import PropTypes from "prop-types";

// mport styles from "./BlockHeader.module.scss";
import contentBlockStyles from "../ContentBlock.module.scss";
import entryBrowserStyles from "../../../../EntryBrowser.module.scss";

const propTypesBlockHeader = {
  editMode: PropTypes.bool,
  noDivider: PropTypes.bool,
  blockTypeContent: PropTypes.node,
  blockActionsButtonContent: PropTypes.node,
  blockTitleEditorContent: PropTypes.node,
  blockCardsButtonContent: PropTypes.node,
  blockDailyQuoteButtonContent: PropTypes.node,
  blockImageContent: PropTypes.node,
};
const defaultPropsBlockHeader = {
  editMode: true,
  noDivider: false,
  blockTypeContent: null,
  blockActionsButtonContent: null,
  blockTitleEditorContent: null,
  blockCardsButtonContent: null,
  blockDailyQuoteButtonContent: null,
  blockImageContent: null,
};

const BlockHeader = ({
  editMode,
  noDivider,
  blockTypeContent,
  blockActionsButtonContent,
  blockTitleEditorContent,
  blockCardsButtonContent,
  blockDailyQuoteButtonContent,
  blockImageContent,
}) => {
  return (
    <div className={entryBrowserStyles.blockHeader}>
      <div
        className={`${entryBrowserStyles.blockHeaderFirstRow} ${
          editMode ? contentBlockStyles.dragHandle : ""
        }`}
      >
        <div className={entryBrowserStyles.blockHeaderLeft}>
          {blockImageContent}
          <div
            className={`${entryBrowserStyles.blockHeaderTypeAndTitle} ${
              blockImageContent ? entryBrowserStyles.extraMarginLeft : ""
            }`}
          >
            {blockTypeContent}
            {blockTitleEditorContent}
          </div>
        </div>
        <div className={entryBrowserStyles.blockHeaderButtons}>
          {blockActionsButtonContent}
          {blockDailyQuoteButtonContent}
          {blockCardsButtonContent}
        </div>
      </div>

      {!noDivider && (blockTitleEditorContent || blockImageContent) ? (
        <hr />
      ) : null}
    </div>
  );
};

BlockHeader.propTypes = propTypesBlockHeader;
BlockHeader.defaultProps = defaultPropsBlockHeader;

export default BlockHeader;
