import React from "react";

import styles from "./ChangeNotes.module.scss";
import BackButton from "../UI/BackButton/BackButton";
import ChangeNotesList from "./ChangeNotesList/ChangeNotesList";

const propTypesChangeNotes = {};
const defaultPropsChangeNotes = {};

const ChangeNotes = () => {
  return (
    <div className={styles.container}>
      <BackButton linkTo="/admin" />
      <ChangeNotesList />
    </div>
  );
};

ChangeNotes.propTypes = propTypesChangeNotes;
ChangeNotes.defaultProps = defaultPropsChangeNotes;

export default ChangeNotes;
