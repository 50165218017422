import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./SubmitFeatureRequest.module.scss";
import { useHotkey } from "../../../../Utils/Hotkeys";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import ModalCloseButton from "../../../../UI/Modal/ModalCloseButton/ModalCloseButton";
import {
  CREATE_FEATURE_REQUEST,
  mergeFeatureRequestsAfterCreate,
} from "../../../../GraphQl/roadmap";

const propTypesSubmitFeatureRequest = {
  onCloseModal: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
};
const defaultPropsSubmitFeatureRequest = {
  onCreate: () => {},
};

const SubmitFeatureRequest = ({ onCloseModal, onCreate }) => {
  const { t } = useTranslation();

  const [titleText, setTitleText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const readyToSubmit = titleText.length > 0 && descriptionText.length > 0;

  const [
    createFeatureRequest,
    { loading: createFeatureRequestLoading },
  ] = useMutation(CREATE_FEATURE_REQUEST, {
    update: (cache, mutationResult) => {
      mergeFeatureRequestsAfterCreate(cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
    onCompleted: (response) => {
      onCreate(response.createFeatureRequest.createdFeatureRequest);
      onCloseModal();
    },
  });

  const onChangeTitleTextHandler = (event) => {
    const title = event.target.value;
    setTitleText(title);
  };

  const onChangeDescriptionTextHandler = (event) => {
    const description = event.target.value;
    setDescriptionText(description);
  };

  const onSubmit = () => {
    createFeatureRequest({
      variables: {
        input: {
          title: titleText,
          description: descriptionText,
        },
      },
    });
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!readyToSubmit) return;
      onSubmit();
    },
    dependsOn: [onSubmit, readyToSubmit],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onCloseModal();
    },
    dependsOn: [onCloseModal],
  });

  const content = (
    <div className={styles.inputContainer}>
      <input
        className={`input ${styles.input}`}
        type="text"
        value={titleText}
        onChange={onChangeTitleTextHandler}
        autoFocus
        placeholder={t(
          "wisdomtree.roadmap.modal_feature_request.title_placeholder"
        )}
      />
      <input
        className={`input ${styles.input}`}
        type="text"
        value={descriptionText}
        onChange={onChangeDescriptionTextHandler}
        placeholder={t(
          "wisdomtree.roadmap.modal_feature_request.description_placeholder"
        )}
      />
    </div>
  );

  return (
    <div className={`modal-card`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.roadmap.modal_feature_request.title_submit")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${styles.body}`}>{content}</section>
      <footer className="modal-card-foot">
        <button
          id="submitButton"
          className={`button is-primary ${
            createFeatureRequestLoading ? `is-loading` : ``
          }`}
          type="submit"
          disabled={!readyToSubmit}
          onClick={() => {
            onSubmit();
          }}
        >
          {t("wisdomtree.roadmap.modal_feature_request.done_submit")}
        </button>
        <button className="button" type="button" onClick={onCloseModal}>
          {t("wisdomtree.roadmap.modal_feature_request.cancel")}
        </button>
      </footer>
    </div>
  );
};

SubmitFeatureRequest.propTypes = propTypesSubmitFeatureRequest;
SubmitFeatureRequest.defaultProps = defaultPropsSubmitFeatureRequest;

export default SubmitFeatureRequest;
