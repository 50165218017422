import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isMobile as isMobileDevice } from "react-device-detect";

import styles from "./Card.module.scss";
import {
  TooltipClick,
  TooltipDelayed,
} from "../../../../../UI/Tooltip/Tooltip";
import entryBrowserStyles from "../../../EntryBrowser.module.scss";
import CardType from "./CardType/CardType";
import Dropdown from "../../../../../UI/Dropdown/Dropdown";
import ReviewContent from "./ReviewContent/ReviewContent";
import QuestionContent from "./QuestionContent/QuestionContent";

const propTypesCard = {
  card: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    active: PropTypes.bool,
    valid: PropTypes.bool,
    difficulty: PropTypes.number,
    lastReviewed: PropTypes.string,
    daysBetweenReviews: PropTypes.number,
  }).isRequired,
  onAddImage: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  onUpdateCard: PropTypes.func.isRequired,
  onDeleteCard: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
};
const defaultPropsCard = {
  editMode: false,
};

const Card = ({
  card,
  onAddImage,
  onRemoveImage,
  onDeleteCard,
  onUpdateCard,
  editMode,
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverDisabled] = useState(false);

  const cardType = (
    <div id={entryBrowserStyles.blockType}>
      <CardType cardType={card.type} />
    </div>
  );

  const actions = [];
  actions.push({
    key: "remove",
    icon: "fa-trash",
    text: t("wisdomtree.entry_browser.card_delete"),
    tooltip: t(`wisdomtree.entry_browser.tooltips.card_delete`),
    handler: onDeleteCard,
  });

  let blockActionsButton = null;
  if (editMode) {
    blockActionsButton = (
      <div id={styles.actionDropdown}>
        <Dropdown
          items={actions}
          buttonIcon="fa-ellipsis-h"
          buttonTooltip={t(
            "wisdomtree.entry_browser.tooltips.card_edit_dropdown"
          )}
          showDropdownIcon={false}
          isUsingHover={false}
          buttonHidden={!isMobileDevice && !isFocused && !isHovered}
          onItemClicked={(item) => item.handler()}
          alignment="is-right"
        />
      </div>
    );
  }

  let activeButtonContent = null;

  if (card.valid) {
    activeButtonContent = (
      <TooltipDelayed
        content={
          card.active
            ? t("wisdomtree.entry_browser.tooltips.card_deactivate")
            : t("wisdomtree.entry_browser.tooltips.card_activate")
        }
      >
        <button
          type="button"
          className={`button is-light ${styles.activeButton}`}
          onClick={() => {
            onUpdateCard(card.id, { active: !card.active }, true);
            document.activeElement.blur();
          }}
        >
          <span className={`icon ${styles.gray}`}>
            <i
              className={`${
                card.active ? "far fa-check-square" : "far fa-square"
              }`}
            />
          </span>
        </button>
      </TooltipDelayed>
    );
  }

  let cardContent = null;
  if (card.type === "REVIEW") {
    cardContent = (
      <ReviewContent
        card={card}
        onUpdateCard={onUpdateCard}
        editMode={editMode}
      />
    );
  } else if (card.type === "QUESTION") {
    cardContent = (
      <QuestionContent
        card={card}
        onAddImage={onAddImage}
        onRemoveImage={onRemoveImage}
        onUpdateCard={onUpdateCard}
        editMode={editMode}
        isFocused={isFocused}
        isHovered={isHovered}
      />
    );
  }

  const difficultyToPercent = Math.round(
    (Math.round(card.difficulty * 100) / 100) * 100
  );

  let lastReviewed;
  if (card.lastReviewed) {
    lastReviewed = moment(card.lastReviewed, "x").fromNow();
  } else {
    lastReviewed = t("wisdomtree.entry_browser.card_stats_last_reviewed_never");
  }

  let nextReviewDue;
  const momentNextReviewDue = moment(card.lastReviewed, "x").add(
    card.daysBetweenReviews * 1440,
    "minutes"
  );

  if (card.lastReviewed && moment().isBefore(momentNextReviewDue)) {
    nextReviewDue = momentNextReviewDue.fromNow();
  } else {
    nextReviewDue = t(
      "wisdomtree.entry_browser.card_stats_next_review_due_now"
    );
  }

  const contentCardStats = (
    <div className={styles.statsContainer}>
      <TooltipClick
        content={
          <div>
            <p>
              {t("wisdomtree.entry_browser.card_stats_difficulty")}:{" "}
              {difficultyToPercent}%
            </p>
            <p>
              {t("wisdomtree.entry_browser.card_stats_last_reviewed")}:{" "}
              {lastReviewed}
            </p>
            <p>
              {t("wisdomtree.entry_browser.card_stats_next_review_due")}:{" "}
              {nextReviewDue}
            </p>
          </div>
        }
      >
        <span className={`icon ${styles.gray}`}>
          <i className="fas fa-chart-bar" />
        </span>
      </TooltipClick>
    </div>
  );

  return (
    <div
      className={`box ${styles.container}`}
      onMouseOver={() => {
        if (isHoverDisabled) return;
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      tabIndex={-1}
    >
      <div className={`${entryBrowserStyles.blockHeaderFirstRow}`}>
        <div className={entryBrowserStyles.blockHeaderLeft}>
          <div className={entryBrowserStyles.blockHeaderTypeAndTitle}>
            {cardType}
          </div>
        </div>
        <div className={entryBrowserStyles.blockHeaderButtons}>
          {blockActionsButton}
          {activeButtonContent}
        </div>
      </div>
      {cardContent}
      {contentCardStats}
    </div>
  );
};

Card.propTypes = propTypesCard;
Card.defaultProps = defaultPropsCard;

export default Card;
