/* eslint-disable import/no-cycle */
import { gql } from "@apollo/client";

import {
  FRAGMENT_BASIC_ENTRY,
  FRAGMENT_DETAILED_ENTRY,
  FRAGMENT_COMPLETE_ENTRY,
  FRAGMENT_COMPLETE_TAG,
  FRAGMENT_USER_UPDATE,
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
  FRAGMENT_COMPLETE_BLOCK,
} from "./fragments";
import { mergeUserUpdates, processExpEvent } from "./user";
import { mergeCreatedTagsUpdates } from "./tags";
import { mergeBlockUpdates } from "./blocks";

const GET_ENTRIES = gql`
  query {
    entries {
      ...BasicEntry
    }
  }
  ${FRAGMENT_BASIC_ENTRY}
`;

const GET_ENTRY_DETAILS = gql`
  query($id: ID!) {
    entry(id: $id) {
      ...DetailedEntry
    }
  }
  ${FRAGMENT_DETAILED_ENTRY}
`;

const CREATE_ENTRY = gql`
  mutation($input: CreateEntryInput!) {
    createEntry(input: $input) {
      code
      message
      createdEntry {
        ...BasicEntry
      }
      updatedEntries {
        ...BasicEntry
      }
      updatedUser {
        ...UserUpdate
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_BASIC_ENTRY}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const UPDATE_ENTRY = gql`
  mutation($id: ID!, $input: UpdateEntryInput!) {
    updateEntry(id: $id, input: $input) {
      code
      message
      updatedEntries {
        ...CompleteEntry
      }
      createdTags {
        ...CompleteTag
      }
      updatedUser {
        ...UserUpdate
        hasBookmarks
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_ENTRY}
  ${FRAGMENT_COMPLETE_TAG}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const UPDATE_ENTRY_POSITION = gql`
  mutation($id: ID!, $input: UpdateEntryInput!) {
    updateEntry(id: $id, input: $input) {
      code
      message
      updatedEntries {
        id
        x
        y
      }
    }
  }
`;

const UPDATE_ENTRY_POSITION_AND_PARENT = gql`
  mutation($id: ID!, $input: UpdateEntryInput!) {
    updateEntry(id: $id, input: $input) {
      code
      message
      updatedEntries {
        id
        x
        y
        parentEntryId
        childEntryIds
        size
        cardsActiveAmountRecursive
        cardsDueAmountRecursive
        shared
      }
    }
  }
`;

const DELETE_ENTRY = gql`
  mutation($id: ID!, $input: DeleteEntryInput!) {
    deleteEntry(id: $id, input: $input) {
      code
      message
      newEntrySelectionId
      deletedEntryIds
      updatedEntries {
        ...CompleteEntry
      }
      updatedBlocks {
        ...CompleteBlock
      }
      updatedUser {
        ...UserUpdate
        cardsActiveAmount
        cardsDueAmount
      }
    }
  }
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_BLOCK}
  ${FRAGMENT_COMPLETE_ENTRY}
`;

const mergeEntriesAfterCreate = (cache, mutationResult) => {
  const { entries: currentData } = cache.readQuery({
    query: GET_ENTRIES,
  });
  const { createdEntry } = mutationResult.data.createEntry;
  const { updatedEntries } = mutationResult.data.createEntry;
  const { updatedUser } = mutationResult.data.createEntry;
  const { expEvent } = mutationResult.data.createEntry;

  // replace updated entries in current data
  let updatedData = currentData.map(
    (e) => updatedEntries.find((ue) => e.id === ue.od) || e
  );

  // add created entry to current data
  updatedData = updatedData.concat([createdEntry]);

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }

  cache.writeQuery({
    query: GET_ENTRIES,
    data: { entries: updatedData },
  });
};

const mergeEntriesAfterDelete = (cache, mutationResult) => {
  const { entries: currentData } = cache.readQuery({
    query: GET_ENTRIES,
  });
  const { deletedEntryIds } = mutationResult.data.deleteEntry;
  const { updatedEntries } = mutationResult.data.deleteEntry;
  const { updatedBlocks } = mutationResult.data.deleteEntry;
  const { updatedUser } = mutationResult.data.deleteEntry;

  // filter out deleted entry ids
  let updatedData = currentData.filter(
    (e) => deletedEntryIds.indexOf(e.id) === -1
  );

  // replace updated entries in current data
  updatedData = updatedData.map(
    (e) => updatedEntries.find((ue) => e.id === ue.id) || e
  );

  // merge block updates (needed because of possible references)
  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  cache.writeQuery({
    query: GET_ENTRIES,
    data: { entries: updatedData },
  });
};

const mergeEntriesAfterUpdate = (cache, mutationResult) => {
  const { createdTags } = mutationResult.data.updateEntry;
  const { updatedUser } = mutationResult.data.updateEntry;
  const { expEvent } = mutationResult.data.updateEntry;

  if (createdTags && createdTags.length > 0) {
    mergeCreatedTagsUpdates(cache, createdTags);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }
};

const mergeEntryUpdates = (cache, updatedEntries) => {
  const { entries: currentData } = cache.readQuery({
    query: GET_ENTRIES,
  });

  const updatedData = currentData.map((e) => {
    const updatedEntry = updatedEntries.find((ue) => e.id === ue.id);
    if (updatedEntry !== null) {
      return {
        ...e,
        ...updatedEntry,
      };
    }
    return e;
  });

  cache.writeQuery({
    query: GET_ENTRIES,
    data: { entries: updatedData },
  });
};

export {
  GET_ENTRIES,
  GET_ENTRY_DETAILS,
  CREATE_ENTRY,
  UPDATE_ENTRY,
  UPDATE_ENTRY_POSITION,
  UPDATE_ENTRY_POSITION_AND_PARENT,
  DELETE_ENTRY,
  mergeEntriesAfterCreate,
  mergeEntriesAfterUpdate,
  mergeEntriesAfterDelete,
  mergeEntryUpdates,
};
