import { gql } from "@apollo/client";
// eslint-disable-next-line import/no-cycle
import {
  FRAGMENT_COMPLETE_CARD,
  FRAGMENT_USER_UPDATE,
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
} from "./fragments";
import { GET_BLOCK_WITH_CARDS, mergeBlockUpdates } from "./blocks";
import { mergeEntryUpdates } from "./entries";
import { mergeUserUpdates, processExpEvent } from "./user";

const UPDATE_CARD = gql`
  mutation($id: ID!, $input: UpdateCardInput!) {
    updateCard(id: $id, input: $input) {
      code
      message
      updatedCard {
        ...CompleteCard
      }
      updatedEntries {
        id
        cardsActiveAmountRecursive
        cardsDueAmountRecursive
      }
      updatedBlocks {
        id
        entry {
          id
        }
        cardsAmount
        cardsActiveAmount
      }
      updatedUser {
        ...UserUpdate
        cardsActiveAmount
        cardsDueAmount
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_CARD}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const CREATE_CARD = gql`
  mutation($input: CreateCardInput!) {
    createCard(input: $input) {
      code
      message
      card {
        ...CompleteCard
      }
      updatedEntries {
        id
        cardsActiveAmountRecursive
        cardsDueAmountRecursive
      }
      updatedBlocks {
        id
        entry {
          id
        }
        cardsAmount
        cardsActiveAmount
      }
      updatedUser {
        ...UserUpdate
        cardsActiveAmount
        cardsDueAmount
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_CARD}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const DELETE_CARD = gql`
  mutation($id: ID!) {
    deleteCard(id: $id) {
      code
      message
      deletedCardIds
      updatedEntries {
        id
        cardsActiveAmountRecursive
        cardsDueAmountRecursive
      }
      updatedBlocks {
        id
        entry {
          id
        }
        cardsAmount
        cardsActiveAmount
      }
      updatedUser {
        cardsActiveAmount
        cardsDueAmount
      }
    }
  }
`;

const mergeCardsAfterCreate = (blockId, cache, mutationResult) => {
  let cachedQuery = null;
  try {
    cachedQuery = cache.readQuery({
      query: GET_BLOCK_WITH_CARDS,
      variables: {
        id: blockId,
      },
    });
  } catch (err) {
    // cached data does not exist
  }

  const { card } = mutationResult.data.createCard;
  const { updatedEntries } = mutationResult.data.createCard;
  const { updatedBlocks } = mutationResult.data.createCard;
  const { updatedUser } = mutationResult.data.createCard;
  const { expEvent } = mutationResult.data.createCard;

  // merge entry updates (needed because of cardsDue)
  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  // merge block updates (needed because of activecards)
  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }

  if (cachedQuery) {
    const { block } = cachedQuery;
    // add created card to current data
    const updatedData = {
      ...block,
      cards: block.cards.concat([card]),
    };

    cache.writeQuery({
      query: GET_BLOCK_WITH_CARDS,
      variables: {
        id: blockId,
      },
      data: {
        block: {
          ...updatedData,
        },
      },
    });
  }
};

const mergeCardsAfterUpdate = (blockId, cache, mutationResult) => {
  const cachedQuery = cache.readQuery({
    query: GET_BLOCK_WITH_CARDS,
    variables: {
      id: blockId,
    },
  });

  const { block } = cachedQuery;
  const { updatedCard } = mutationResult.data.updateCard;
  const { updatedEntries } = mutationResult.data.updateCard;
  const { updatedBlocks } = mutationResult.data.updateCard;
  const { updatedUser } = mutationResult.data.updateCard;
  const { expEvent } = mutationResult.data.updateCard;

  // merge card updates into to current data
  const updatedData = {
    ...block,
    cards: block.cards.map((c) => {
      if (c.id === updatedCard.id) {
        return {
          ...c,
          ...updatedCard,
        };
      }
      return c;
    }),
  };

  // merge entry updates (needed because of cardsDue)
  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  // merge entry updates (needed because of activecards)
  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }

  cache.writeQuery({
    query: GET_BLOCK_WITH_CARDS,
    variables: {
      id: blockId,
    },
    data: {
      block: {
        ...updatedData,
      },
    },
  });
};

const mergeCardsAfterDelete = (blockId, cache, mutationResult) => {
  const cachedQuery = cache.readQuery({
    query: GET_BLOCK_WITH_CARDS,
    variables: {
      id: blockId,
    },
  });
  const { block } = cachedQuery;

  const { deletedCardIds } = mutationResult.data.deleteCard;
  const { updatedEntries } = mutationResult.data.deleteCard;
  const { updatedBlocks } = mutationResult.data.deleteCard;
  const { updatedUser } = mutationResult.data.deleteCard;

  // filter out deleted card ids
  const updatedData = {
    ...block,
    cards: block.cards.filter((e) => deletedCardIds.indexOf(e.id) === -1),
  };

  // merge entry updates (needed because of cardsDue)
  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  // merge entry updates (needed because of activecards)
  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  cache.writeQuery({
    query: GET_BLOCK_WITH_CARDS,
    variables: {
      id: blockId,
    },
    data: {
      block: {
        ...updatedData,
      },
    },
  });
};

const mergeCardUpdates = (cache, updatedCards) => {
  updatedCards.forEach((updatedCard) => {
    try {
      const cachedQuery = cache.readQuery({
        query: GET_BLOCK_WITH_CARDS,
        variables: {
          id: updatedCard.block.id,
        },
      });
      const currentData = cachedQuery.block.cards;

      const updatedData = currentData.map((c) => {
        if (c.id === updatedCard.id) {
          return {
            ...c,
            ...updatedCard,
          };
        }
        return c;
      });

      cache.writeQuery({
        query: GET_BLOCK_WITH_CARDS,
        variables: {
          id: updatedCard.block.id,
        },
        data: {
          block: {
            __typename: "Block",
            ...cachedQuery.block,
            cards: updatedData,
          },
        },
      });
    } catch (err) {
      // block not cached
    }
  });
};

export {
  UPDATE_CARD,
  CREATE_CARD,
  DELETE_CARD,
  mergeCardsAfterCreate,
  mergeCardsAfterUpdate,
  mergeCardsAfterDelete,
  mergeCardUpdates,
};
