/* eslint-disable jsx-a11y/no-autofocus */
import React from "react";
import PropTypes from "prop-types";

import styles from "./RatingButton.module.css";

const propTypesRatingButton = {
  rating: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  cssclass: PropTypes.string.isRequired,
  autofocus: PropTypes.bool,
  onClickHandler: PropTypes.func.isRequired,
};
const defaultPropsRatingButton = {
  autofocus: false,
};

const RatingButton = ({
  rating,
  text,
  cssclass,
  autofocus,
  onClickHandler,
}) => {
  const contentButton = (
    <button
      type="button"
      className={`button ${cssclass} is-outlined ${styles.ratingButton}`}
      autoFocus={autofocus}
      onClick={() => onClickHandler(rating)}
    >
      <span>{text}</span>
    </button>
  );

  return contentButton;
};

RatingButton.propTypes = propTypesRatingButton;
RatingButton.defaultProps = defaultPropsRatingButton;

export default RatingButton;
