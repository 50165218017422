/* eslint-disable import/no-cycle */
import { gql } from "@apollo/client";
import { mergeBlockUpdates } from "./blocks";
import { mergeEntryUpdates } from "./entries";

import {
  FRAGMENT_COMPLETE_ENTRY,
  FRAGMENT_COMPLETE_BLOCK,
  FRAGMENT_USER_UPDATE,
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
} from "./fragments";
import { mergeUserUpdates, processExpEvent } from "./user";

const CREATE_REFERENCE = gql`
  mutation($input: CreateReferenceInput!) {
    createReference(input: $input) {
      code
      message
      updatedEntries {
        ...CompleteEntry
      }
      updatedBlocks {
        ...CompleteBlock
      }
      updatedUser {
        ...UserUpdate
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_BLOCK}
  ${FRAGMENT_COMPLETE_ENTRY}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const UPDATE_REFERENCE = gql`
  mutation($id: ID!, $input: UpdateReferenceInput!) {
    updateReference(id: $id, input: $input) {
      code
      message
      updatedEntries {
        ...CompleteEntry
      }
      updatedBlocks {
        ...CompleteBlock
      }
    }
  }
  ${FRAGMENT_COMPLETE_BLOCK}
  ${FRAGMENT_COMPLETE_ENTRY}
`;

const DELETE_REFERENCE = gql`
  mutation($id: ID!) {
    deleteReference(id: $id) {
      code
      message
      deletedReferenceId
      updatedEntries {
        ...CompleteEntry
      }
      updatedBlocks {
        ...CompleteBlock
      }
    }
  }
  ${FRAGMENT_COMPLETE_BLOCK}
  ${FRAGMENT_COMPLETE_ENTRY}
`;

const mergeAfterCreate = (cache, mutationResult) => {
  const { updatedEntries } = mutationResult.data.createReference;
  const { updatedBlocks } = mutationResult.data.createReference;
  const { updatedUser } = mutationResult.data.createReference;
  const { expEvent } = mutationResult.data.createReference;

  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }
};

const mergeAfterUpdate = (cache, mutationResult) => {
  const { updatedEntries } = mutationResult.data.updateReference;
  const { updatedBlocks } = mutationResult.data.updateReference;

  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }
};

const mergeAfterDelete = (cache, mutationResult) => {
  const { updatedEntries } = mutationResult.data.deleteReference;
  const { updatedBlocks } = mutationResult.data.deleteReference;

  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }
};

export {
  CREATE_REFERENCE,
  UPDATE_REFERENCE,
  DELETE_REFERENCE,
  mergeAfterCreate,
  mergeAfterUpdate,
  mergeAfterDelete,
};
