import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { throttle } from "lodash";
import useEventListener from "../../../Utils/UseEventListener";
import styles from "./StickyHeader.module.scss";

const propTypesStickyHeader = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  triggerStickyY: PropTypes.number,
  yOffset: PropTypes.number,
  onStickyChanged: PropTypes.func,
};
const defaultPropsStickyHeader = {
  className: "",
  triggerStickyY: 0,
  yOffset: 0,
  onStickyChanged: () => {},
};

const StickyHeader = ({
  className,
  children,
  triggerStickyY,
  yOffset,
  onStickyChanged,
}) => {
  const [isSticky, setSticky] = useState(false);
  const isStickyStateRef = useRef(isSticky);
  const triggerStickyYRef = useRef(triggerStickyY);

  const containerRef = useRef(null);
  const placeholderRef = useRef(null);
  const stickyRef = useRef(null);
  const handleWindowScroll = () => {
    updateStickyState(triggerStickyYRef.current, window.scrollY);
  };
  useEventListener("scroll", throttle(handleWindowScroll, 10));

  const updateStickyState = (stickyY, scrollY) => {
    if (!isStickyStateRef.current && scrollY >= stickyY + 1) {
      setSticky(true);
      isStickyStateRef.current = true;
      return;
    }
    if (isStickyStateRef.current && scrollY <= stickyY) {
      setSticky(false);
      isStickyStateRef.current = false;
    }
  };

  const handleWindowResized = () => {
    if (isStickyStateRef.current) {
      stickyRef.current.style.width = `${containerRef.current.clientWidth}px`;
    }
  };
  useEventListener("resize", handleWindowResized);

  useEffect(() => {
    updateStickyState(triggerStickyY, window.scrollY);
  }, [triggerStickyY]);

  useLayoutEffect(() => {
    if (isSticky) {
      placeholderRef.current.style.height = `${stickyRef.current.clientHeight}px`;
      stickyRef.current.setAttribute(
        "style",
        `position: fixed; width: ${containerRef.current.clientWidth}px; top: ${yOffset}px; z-index: 1000;`
      );
    } else {
      placeholderRef.current.setAttribute("style", "");
      stickyRef.current.setAttribute("style", "");
    }
    onStickyChanged(isSticky);
  }, [isSticky]);

  useEffect(() => {
    triggerStickyYRef.current = triggerStickyY;
  }, [triggerStickyY]);

  return (
    <div className={className} ref={containerRef}>
      <div className={styles.header} ref={stickyRef}>
        {children}
      </div>
      <div className={styles.placeholder} ref={placeholderRef} />
    </div>
  );
};

StickyHeader.propTypes = propTypesStickyHeader;
StickyHeader.defaultProps = defaultPropsStickyHeader;

export default StickyHeader;
