/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

const SEARCH_IMAGES = gql`
  query($input: ImageSearchInput!) {
    searchImages(input: $input) {
      results {
        url
        previewUrl
        author
        authorUrl
        sourceId
        width
        height
      }
      currentPage
      totalPages
      total
    }
  }
`;

const GET_ENTRY_IMAGE_SUGGESTIONS = gql`
  query($input: ImageSuggestionsInput!) {
    imageSuggestions(input: $input) {
      searchQueries
      images {
        id
        url
        author
        authorUrl
        source
        width
        height
      }
    }
  }
`;

export { SEARCH_IMAGES, GET_ENTRY_IMAGE_SUGGESTIONS };
