/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";

// eslint-disable-next-line no-unused-vars
import styles from "./DailyQuoteModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import {
  MARK_DAILY_QUOTE_AS_VIEWED,
  mergeAfterViewDailyQuote,
} from "../../../GraphQl/dailyQuotes";
import ContentQuillEditor from "../../Editor/ContentQuillEditor";
import ClickableImage from "../../ClickableImage/ClickableImage";
import {
  useMobile,
  useMobileOrTablet,
  useMobileSmall,
} from "../../../Utils/Responsive";
import Dropdown from "../../Dropdown/Dropdown";
import NotificationSubscription from "../../NotificationSubscription/NotificationSubscription";
import { mergeBlocksAfterUpdate, UPDATE_BLOCK } from "../../../GraphQl/blocks";
import {
  mergeUserAfterUpdate,
  UPDATE_CURRENT_USER,
} from "../../../GraphQl/user";
import { isMobileApp } from "../../../Utils/ReactNativeMessageUtils";
import { browserSupportsPush } from "../../../Utils/PushNotifications";
import { mobileNotificationPermissionStatus } from "../../../../config/apollo-cache";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useDailyQuoteModal = ({ onOpen, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const isTabletOrMobile = useMobileOrTablet();
  const isMobile = useMobile();

  const [nestedDialogOpened, setNestedDialogOpened] = useState(false);
  const [dailyQuote, setDailyQuote] = useState(null);
  const [notificationsHandled, setNotificationsHandled] = useState(false);

  const mobileNotificationStatus = useReactiveVar(
    mobileNotificationPermissionStatus
  );

  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose,
    hasNestedOpened: nestedDialogOpened,
  });

  const openModal = (dailyQuote) => {
    setDailyQuote(dailyQuote);
    open();
  };

  const closeModal = () => {
    markAsViewed({
      variables: {
        id: dailyQuote.id,
      },
    });
    close();
    setDailyQuote(null);
  };

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!isOpened) return;
      if (nestedDialogOpened) return;
      close();
    },
    dependsOn: [close, nestedDialogOpened, isOpened],
  });

  const [markAsViewed, { loading: markAsViewedLoading }] = useMutation(
    MARK_DAILY_QUOTE_AS_VIEWED,
    {
      update: mergeAfterViewDailyQuote,
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {},
    }
  );

  const [updateBlock] = useMutation(UPDATE_BLOCK, {
    update: (cache, mutationResult) => {
      mergeBlocksAfterUpdate(dailyQuote.block.entryId, cache, mutationResult);
    },
    onCompleted: () => {},
  });

  const [updateUser] = useMutation(UPDATE_CURRENT_USER, {
    update: (cache, mutationResult) => {
      mergeUserAfterUpdate(cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  const disableBlockDailyQuote = () => {
    const newValue = !dailyQuote.block.dailyQuote;
    updateBlock({
      variables: {
        id: dailyQuote.block.id,
        input: {
          dailyQuote: newValue,
        },
      },
      context: {
        debounceKey: `${dailyQuote.block.id}_dailyQuote`,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateBlock: {
          __typename: "UpdateBlockResponse",
          code: 200,
          success: true,
          message: "block updated",
          updatedBlocks: [
            {
              id: dailyQuote.block.id,
              dailyQuote: newValue,
              __typename: "Block",
            },
          ],
          updatedEntries: [],
          updatedUser: [],
          createdTags: [],
        },
      },
    });
  };

  const disableDailyQuotesFeature = () => {
    updateUser({
      variables: {
        input: {
          dailyQuotesEnabled: false,
        },
      },
    });
  };

  let content = null;
  if (isOpened) {
    let blockImageContent = null;
    if (dailyQuote.block.image) {
      blockImageContent = (
        <div className={styles.blockImageContainer}>
          <ClickableImage
            editMode={false}
            imageUrl={dailyQuote.block.image.url}
            round
            emptyIcon="fa-user"
            height={isMobile ? 42 : 48}
            width={isMobile ? 42 : 48}
            altText="author"
            preview={false}
          />
        </div>
      );
    }

    let blockTitleContent = null;
    if (dailyQuote.block.title) {
      blockTitleContent = (
        <p className={styles.blockTitle}>{dailyQuote.block.title}</p>
      );
    }

    const bodyContent = (
      <div className={styles.bodyContainer}>
        <div className={styles.quoteContainer}>
          <div className={styles.contentContainer}>
            {blockTitleContent}
            <ContentQuillEditor
              elementId={dailyQuote.id}
              elementContent={dailyQuote.block.content}
              stylesClassName="dailyQuoteTextEditor"
              editMode={false}
              focusInitially={false}
            />
          </div>
          <div className={styles.sourceContainer}>
            <span className={styles.dash}>—</span>
            {blockImageContent}
            <p className={styles.sourceName}>
              {dailyQuote.block.source
                ? dailyQuote.block.source
                : t("wisdomtree.entry_browser.block_quote_unknown")}
            </p>
          </div>
        </div>
      </div>
    );

    const actions = [];

    actions.push({
      key: "view_block",
      icon: "fa-eye",
      text: t("wisdomtree.modal_daily_quote.action_show_block"),
      tooltip: t("wisdomtree.modal_daily_quote.action_tooltip_show_block"),
      handler: () => {
        history.push(
          `/entry/${dailyQuote.block.entryId}?block=${dailyQuote.block.id}`
        );
        closeModal();
      },
    });

    if (dailyQuote.block.dailyQuote) {
      actions.push({
        key: "remove_from",
        icon: "fa-minus",
        text: t("wisdomtree.modal_daily_quote.action_remove_from_daily_quote"),
        tooltip: t(
          "wisdomtree.modal_daily_quote.action_tooltip_remove_from_daily_quote"
        ),
        handler: () => {
          disableBlockDailyQuote();
          closeModal();
        },
      });
    }

    actions.push({
      key: "disable",
      icon: "fa-ban",
      text: t("wisdomtree.modal_daily_quote.action_disable_daily_quote"),
      tooltip: t(
        "wisdomtree.modal_daily_quote.action_tooltip_disable_daily_quote"
      ),
      handler: () => {
        disableDailyQuotesFeature();
        closeModal();
      },
    });

    const contextButton = (
      <div className={styles.actionDropdown}>
        <Dropdown
          items={actions}
          buttonIcon="fa-ellipsis-h"
          showDropdownIcon={false}
          isUsingHover={false}
          onItemClicked={(item) => item.handler()}
          buttonStyle=""
          alignment={isTabletOrMobile ? "is-left" : "is-right"}
          buttonTooltip={t(
            "wisdomtree.entry_browser.tooltips.block_edit_dropdown"
          )}
        />
      </div>
    );

    let enableNotificationsButton = null;

    const showNotifyButton =
      ((isMobileApp() && mobileNotificationStatus === "undetermined") ||
        (browserSupportsPush() && Notification.permission === "default")) &&
      !notificationsHandled;

    if (showNotifyButton)
      enableNotificationsButton = (
        <NotificationSubscription
          text={t("wisdomtree.modal_daily_quote.notify")}
          onPermissionHandled={() => {
            setNotificationsHandled(true);
          }}
        />
      );

    const buttonsLeft = (
      <div className={styles.buttonsContainerLeft}>
        {contextButton}
        {enableNotificationsButton}
      </div>
    );
    const buttonsRight = (
      <div className={styles.buttonsContainerRight}>
        <button className="button" type="button" onClick={closeModal}>
          {t("wisdomtree.modal_daily_quote.done")}
        </button>
      </div>
    );

    content = (
      <>
        <BaseModal show={isOpened} onCloseHandler={closeModal}>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                {t("wisdomtree.modal_daily_quote.title")}
              </p>
              <ModalCloseButton onClick={closeModal} />
            </header>
            <section className="modal-card-body">{bodyContent}</section>
            <footer className={`${styles.footer} modal-card-foot`}>
              {buttonsLeft}
              {buttonsRight}
            </footer>
          </div>
        </BaseModal>
      </>
    );
  }

  return { open: openModal, close: closeModal, content };
};

useDailyQuoteModal.propTypes = propTypes;
useDailyQuoteModal.defaultProps = defaultProps;

export default useDailyQuoteModal;
