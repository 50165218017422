import React from "react";
import PropTypes from "prop-types";

import styles from "./UserImage.module.scss";

const propTypesUserImage = {
  user: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string
    })
  }).isRequired
};
const defaultPropsUserImage = {};

const UserImage = ({ user }) => {
  return (
    <figure className={`image ${styles.emptyImage} ${styles.container}`}>
      {user.image ? (
        <img src={user.image.url} alt="user" />
      ) : (
        <span className={`icon is-large ${styles.empty}`}>
          <i className="fas fa-1x fa-user" />
        </span>
      )}
    </figure>
  );
};

UserImage.propTypes = propTypesUserImage;
UserImage.defaultProps = defaultPropsUserImage;

export default UserImage;
