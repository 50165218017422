import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./ContextActions.module.scss";
import menuBarStyles from "../../../MenuBar.module.scss";
import { useMobileOrTablet } from "../../../../../Utils/Responsive";
import Dropdown from "../../../../../UI/Dropdown/Dropdown";
import QuizButton from "../../../QuizButton/QuizButton";
import Spinner from "../../../../../UI/Spinner/Spinner";
import ShareButton from "../../../ShareButton/ShareButton";
import useShareModal from "../../../../../UI/Modal/ShareModal/ShareModal";
import useChangeParentModal from "../../../../../UI/Modal/ChangeParentModal/ChangeParentModal";
import { hasQuestGoal } from "../../../../../Utils/QuestHelper";
import BookmarkButton from "../../../BookmarkButton/BookmarkButton";

const propTypesContextActions = {
  editMode: PropTypes.bool,
  sharingId: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  selectedEntry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    parentEntry: PropTypes.number,
    cardsActiveAmountRecursive: PropTypes.integer,
    cardsDueAmountRecursive: PropTypes.integer,
  }),
  selectedEntryDetails: PropTypes.shape({
    cardsActiveAmountRecursive: PropTypes.integer,
    cardsDueAmountRecursive: PropTypes.integer,
    bookmarked: PropTypes.bool,
  }),
  selectedEntryDetailsLoading: PropTypes.bool,
  onUpdateEntry: PropTypes.func.isRequired,
  onDeleteEntry: PropTypes.func.isRequired,
  onAddReference: PropTypes.func.isRequired,
  onSetCategory: PropTypes.func.isRequired,
  hasTags: PropTypes.bool.isRequired,
  onShowTags: PropTypes.func.isRequired,
  onDialogOpenedChanged: PropTypes.func.isRequired,
  targetWidth: PropTypes.number,
};
const defaultPropsContextActions = {
  editMode: false,
  sharingId: null,
  user: null,
  selectedEntry: null,
  selectedEntryDetails: null,
  selectedEntryDetailsLoading: false,
  targetWidth: 0,
};

const ContextActions = ({
  selectedEntry,
  selectedEntryDetails,
  selectedEntryDetailsLoading,
  editMode,
  sharingId,
  user,
  onUpdateEntry,
  onDeleteEntry,
  onAddReference,
  onSetCategory,
  hasTags,
  onShowTags,
  onDialogOpenedChanged,
  targetWidth,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isTabletOrMobile = useMobileOrTablet();
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const RESIZE_LEVEL_NONE = 0;
  const RESIZE_LEVEL_SOME = 1;
  const RESIZE_LEVEL_HIGH = 2;
  const RESIZE_LEVEL_MAX = 3;

  const [resizeLevelNeeded, setResizeLevelNeeded] = useState(RESIZE_LEVEL_NONE);

  const guessResizeLevelForTargetWidth = (targetWidth) => {
    if (targetWidth < 180) return RESIZE_LEVEL_MAX;
    if (targetWidth < 220) return RESIZE_LEVEL_HIGH;
    if (targetWidth < 400) return RESIZE_LEVEL_SOME;
    return RESIZE_LEVEL_NONE;
  };

  useEffect(() => {
    setResizeLevelNeeded(guessResizeLevelForTargetWidth(targetWidth));
  }, [targetWidth]);

  useEffect(() => {
    if (selectedEntryDetails == null)
      setShouldAnimate(selectedEntryDetailsLoading);
  }, [selectedEntry, selectedEntryDetailsLoading]);

  const { open: openShareModal, content: shareModalContent } = useShareModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
  });

  const {
    open: openChangeParentModal,
    content: changeParentModalContent,
  } = useChangeParentModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
  });

  let contentActionsButton = null;
  let contentLearnButton = null;
  let contentShareButton = null;
  let contentBookmarkButton = null;

  if (selectedEntryDetails && !sharingId) {
    if (selectedEntryDetails.cardsActiveAmountRecursive > 0) {
      contentLearnButton = (
        <div
          className={`${menuBarStyles.menuBarItem} ${
            shouldAnimate ? menuBarStyles.fadeIn : null
          }`}
        >
          <QuizButton
            dataId="entry.quiz"
            entryId={selectedEntry.id}
            entryName={selectedEntry.name}
            cardsActiveAmount={selectedEntryDetails.cardsActiveAmountRecursive}
            cardsDueAmount={selectedEntryDetails.cardsDueAmountRecursive}
            noText={resizeLevelNeeded >= RESIZE_LEVEL_SOME}
          />
        </div>
      );
    }
  }

  const showBookmarkButton = resizeLevelNeeded < RESIZE_LEVEL_MAX;
  if (showBookmarkButton && selectedEntryDetails && !sharingId) {
    contentBookmarkButton = (
      <div
        className={`${menuBarStyles.menuBarItem} ${
          shouldAnimate ? menuBarStyles.fadeIn : null
        }`}
      >
        <BookmarkButton
          dataId="entry.bookmark"
          entryName={selectedEntry.name}
          active={selectedEntryDetails.bookmarked}
          onClick={() => {
            onUpdateEntry({
              id: selectedEntry.id,
              bookmarked: !selectedEntryDetails.bookmarked,
            });
            document.activeElement.blur();
          }}
        />
      </div>
    );
  }

  const showShareButton = resizeLevelNeeded < RESIZE_LEVEL_HIGH;
  if (showShareButton && selectedEntryDetails && !sharingId) {
    contentShareButton = (
      <div
        className={`${menuBarStyles.menuBarItem} ${
          shouldAnimate ? menuBarStyles.fadeIn : null
        }`}
      >
        <ShareButton
          dataId="entry.share"
          entryId={selectedEntry.id}
          entryName={selectedEntry.name}
          active={selectedEntryDetails.shared}
          onClick={() => {
            openShareModal(selectedEntry.id, selectedEntry.name);
            document.activeElement.blur();
          }}
        />
      </div>
    );
  }

  const actions = [];
  if (editMode) {
    const hasEntryQuest = user ? hasQuestGoal(user, "entries") : false;
    actions.push({
      key: "new",
      icon: "fa-plus",
      text: t("wisdomtree.entry_browser.new_entry"),
      tooltip: t("wisdomtree.entry_browser.tooltips.entry_new"),
      hasQuest: hasEntryQuest,
      handler: () => {
        history.push(`/entry/${selectedEntry.id}?new=true`);
      },
    });

    if (!showBookmarkButton && selectedEntryDetails) {
      actions.push({
        key: "bookmark",
        icon: `${
          selectedEntryDetails.bookmarked
            ? "fas fa-bookmark"
            : "far fa-bookmark"
        }`,
        text: selectedEntryDetails.bookmarked
          ? t("wisdomtree.entry_browser.entry_bookmark_remove")
          : t("wisdomtree.entry_browser.entry_bookmark_create"),
        tooltip: selectedEntryDetails.bookmarked
          ? t("wisdomtree.entry_browser.tooltips.bookmark_entry_remove")
          : t("wisdomtree.entry_browser.tooltips.bookmark_entry_create", {
              entry: selectedEntry.name,
            }),
        handler: () => {
          onUpdateEntry({
            id: selectedEntry.id,
            bookmarked: !selectedEntryDetails.bookmarked,
          });
        },
      });
    }

    if (!showShareButton && selectedEntryDetails) {
      actions.push({
        key: "share",
        icon: `fas fa-share-alt`,
        text: t("wisdomtree.entry_browser.entry_share"),
        tooltip: t("wisdomtree.entry_browser.tooltips.share_button_entry", {
          entry: selectedEntry.name,
        }),
        iconActive: selectedEntryDetails.shared,
        handler: () => {
          openShareModal(selectedEntry.id, selectedEntry.name);
        },
      });
    }

    actions.push({
      key: "set_category",
      icon: "fa-palette",
      text: t("wisdomtree.entry_browser.entry_set_category"),
      tooltip: t("wisdomtree.entry_browser.tooltips.entry_set_category"),
      handler: () => {
        onSetCategory();
      },
    });

    if (!hasTags) {
      actions.push({
        key: "add_tags",
        icon: "fa-tags",
        text: t("wisdomtree.entry_browser.entry_add_tags"),
        tooltip: t("wisdomtree.entry_browser.tooltips.entry_add_tags"),
        handler: () => {
          setTimeout(() => {
            onShowTags();
          }, 50);
        },
      });
    }

    actions.push({
      key: "add_reference",
      icon: "fa-link",
      text: t("wisdomtree.entry_browser.entry_add_reference"),
      tooltip: t("wisdomtree.entry_browser.tooltips.entry_add_reference"),
      handler: () => {
        onAddReference();
      },
    });

    actions.push({
      key: "change_parent",
      icon: "fa-arrows-alt",
      text: t("wisdomtree.entry_browser.entry_change_parent"),
      tooltip: t("wisdomtree.entry_browser.tooltips.entry_change_parent"),
      handler: () => {
        openChangeParentModal(selectedEntry);
      },
    });

    actions.push({
      key: "remove",
      icon: "fa-trash",
      text: t("wisdomtree.entry_browser.entry_delete"),
      tooltip: t("wisdomtree.entry_browser.tooltips.entry_delete"),
      handler: () => {
        onDeleteEntry(selectedEntry);
      },
    });
  }

  if (actions.length > 0) {
    contentActionsButton = (
      <div id={menuBarStyles.actionDropdown}>
        <Dropdown
          items={actions}
          buttonIcon="fa-ellipsis-h"
          showDropdownIcon={false}
          isUsingHover={false}
          onItemClicked={(item) => item.handler()}
          alignment={isTabletOrMobile ? "is-right" : "is-left"}
          buttonTooltip={t(
            "wisdomtree.entry_browser.tooltips.entry_edit_dropdown"
          )}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {contentLearnButton}
      {contentBookmarkButton}
      {contentShareButton}
      {contentActionsButton}
      {shareModalContent}
      {changeParentModalContent}
    </div>
  );
};

ContextActions.propTypes = propTypesContextActions;
ContextActions.defaultProps = defaultPropsContextActions;

export default ContextActions;
