import React from "react";
import PropTypes from "prop-types";

import styles from "./Title.module.scss";

const propTypesTitle = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
const defaultPropsTitle = {};

const Title = ({ text, icon }) => {
  return (
    <div className={styles.container}>
      <span className={`icon ${styles.icon}`}>
        <i className={`fas ${icon}`} />
      </span>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

Title.propTypes = propTypesTitle;
Title.defaultProps = defaultPropsTitle;

export default Title;
