/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Logger from "js-logger";

import styles from "./ChangeNotesList.module.scss";
import Spinner from "../../../UI/Spinner/Spinner";
import ChangeNote from "./ChangeNote/ChangeNote";
import Title from "../../UI/Title/Title";
import {
  GET_CHANGE_NOTES,
  CREATE_CHANGE_NOTE,
  mergeChangeNotesAfterCreate,
} from "../../../GraphQl/changeNotes";
import CreateChangeNote from "./CreateChangeNote/CreateChangeNote";

const propTypesChangeNotesList = {};
const defaultPropsChangeNotesList = {};

const ChangeNotesList = () => {
  const {
    loading: getChangeNotesLoading,
    data: getChangeNotesData,
    refetch: refetchChangeNotes,
  } = useQuery(GET_CHANGE_NOTES, {
    pollInterval: 10000,
  });
  const changeNotes = getChangeNotesData ? getChangeNotesData.changeNotes : [];

  const [createChangeNote] = useMutation(CREATE_CHANGE_NOTE, {
    update: mergeChangeNotesAfterCreate,
    onError: (err) => {
      Logger.error(err);
    },
  });

  useEffect(() => {
    refetchChangeNotes();
  }, []);

  let content = null;
  if (getChangeNotesLoading) {
    content = <Spinner />;
  } else {
    content = changeNotes.map((changeNote) => {
      return <ChangeNote key={changeNote.id} changeNote={changeNote} />;
    });
  }

  return (
    <div className={styles.container}>
      <Title text="Change Notes" icon="fa-newspaper" />
      <CreateChangeNote
        onCreateChangeNote={({ text_en, text_de }) => {
          createChangeNote({
            variables: {
              input: {
                text_en,
                text_de,
              },
            },
          });
        }}
      />
      {content}
    </div>
  );
};

ChangeNotesList.propTypes = propTypesChangeNotesList;
ChangeNotesList.defaultProps = defaultPropsChangeNotesList;

export default ChangeNotesList;
