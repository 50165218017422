import React from "react";
import PropTypes from "prop-types";

import styles from "./CardQuestion.module.css";
import ContentQuillEditor from "../../../../UI/Editor/ContentQuillEditor";
import TitleNameQuillEditor from "../../../../UI/Editor/TitleQuillEditor";

const propTypesCardReview = {
  card: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    frontText: PropTypes.object,
    backText: PropTypes.object,
  }).isRequired,
  isRevealed: PropTypes.bool.isRequired,
};
const defaultPropsCardReview = {};

const CardQuestion = ({ card, isRevealed }) => {
  let imageFront = null;
  if (card.frontImage) {
    imageFront = (
      <div className={styles.image}>
        <img
          src={card.frontImage.url}
          key={card.frontImage.url}
          alt="cardFront"
          width="160"
          height="160"
        />
      </div>
    );
  }

  let titleFront = null;
  if (card.frontTitle) {
    titleFront = (
      <div className={styles.title}>
        <TitleNameQuillEditor
          elementId={card.id}
          elementTitle={card.frontTitle}
          stylesClassName="quizTitleEditor"
          editMode={false}
          limit={120}
          focusInitially={false}
        />
      </div>
    );
  }

  const contentFront = (
    <div className={styles.containerSide}>
      {imageFront}
      <div className={styles.textTitleContainer}>
        {titleFront}
        <div className={styles.text}>
          <ContentQuillEditor
            elementId={card.id}
            elementContent={card.frontText}
            stylesClassName="quizTextEditor"
            editMode={false}
            focusInitially={false}
          />
        </div>
      </div>
    </div>
  );

  let imageBack = null;
  if (card.backImage) {
    imageBack = (
      <div className={styles.image}>
        <img
          src={card.backImage.url}
          key={card.backImage.url}
          alt="cardBack"
          width="160"
          height="160"
        />
      </div>
    );
  }

  let titleBack = null;
  if (card.backTitle) {
    titleBack = (
      <div className={styles.title}>
        <TitleNameQuillEditor
          elementId={card.id}
          elementTitle={card.backTitle}
          stylesClassName="quizTitleEditor"
          limit={120}
          editMode={false}
          focusInitially={false}
        />
      </div>
    );
  }

  let contentBack = null;
  if (isRevealed) {
    contentBack = (
      <div className={styles.containerSide}>
        {imageBack}
        <div className={styles.textTitleContainer}>
          {titleBack}
          <div className={styles.text}>
            <ContentQuillEditor
              elementId={card.id}
              elementContent={card.backText}
              stylesClassName="quizTextEditor"
              editMode={false}
              focusInitially={false}
            />
          </div>
        </div>
      </div>
    );
  }

  const content = (
    <div className={styles.container}>
      {contentFront}
      {isRevealed ? <hr className={styles.divider} /> : null}
      {contentBack}
    </div>
  );

  return content;
};

CardQuestion.propTypes = propTypesCardReview;
CardQuestion.defaultProps = defaultPropsCardReview;

export default CardQuestion;
