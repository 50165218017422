import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./Entry.module.scss";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

const propTypes = {
  entry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.String,
    }),
    category: PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.shape({
        id: PropTypes.string,
        hex: PropTypes.string,
      }),
    }),
    size: PropTypes.number,
    timeTrackedRecursive: PropTypes.number,
  }).isRequired,
  sharingId: PropTypes.string,
  onDropBlock: PropTypes.func,
  showSize: PropTypes.bool,
  showTimeTracked: PropTypes.bool,
};

const defaultProps = {
  onDropBlock: () => {},
  sharingId: null,
  showSize: false,
  showTimeTracked: false,
};

const Entry = ({
  entry: { id, name, image, category, size, timeTrackedRecursive },
  onDropBlock,
  sharingId,
  showSize,
  showTimeTracked,
}) => {
  const [dropHappening, setDropHappening] = useState(false);

  const handleDragEnter = (event) => {
    if (isValidDrop(event)) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.dropEffect = "move";
      setDropHappening(true);
    }
  };

  const handleDragOver = (event) => {
    if (isValidDrop(event)) {
      event.preventDefault();
    }
  };

  const isValidDrop = (event) => {
    const hasBlock = event.dataTransfer.types.includes("blockid");
    const hasEntry = event.dataTransfer.types.includes("entryid");
    return hasBlock && hasEntry;
  };

  const handleDragLeave = () => {
    setDropHappening(false);
  };

  const handleDrop = (event) => {
    const blockId = event.dataTransfer.getData("blockid");
    onDropBlock(blockId);
    setDropHappening(false);
  };

  let linkTo = `/entry/${id}`;
  if (sharingId) linkTo = linkTo = `/shared/${sharingId}${linkTo}`;

  let contentDetailsRight = null;
  let contentNextToName = null;
  if (showSize || showTimeTracked) {
    let contentTimeTracked = null;
    if (showTimeTracked && timeTrackedRecursive > 60) {
      momentDurationFormatSetup(moment);

      const timeText = moment
        .duration(timeTrackedRecursive, "seconds")
        .format("m [min]");

      contentTimeTracked = (
        <div className={styles.timeContainer}>
          <span className={`icon ${styles.icon}`}>
            <i className="fas fa-clock" />
          </span>
          <p className={styles.timeText}>{timeText}</p>
        </div>
      );
    }

    contentDetailsRight = (
      <div className={styles.detailsContainer}>{contentTimeTracked}</div>
    );

    contentNextToName = showSize ? (
      <div className={`${styles.sizeContainer} box`}>
        <p>{size}</p>
      </div>
    ) : null;
  }

  return (
    <Link
      className={`box ${styles.Entry} ${dropHappening ? styles.drop : null}`}
      to={linkTo}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {image ? (
        <img src={image.url} className={styles.entryImage} alt="entry" />
      ) : (
        <span className={`icon ${styles.entryImage} ${styles.entryEmptyImage}`}>
          <i className="fas fa-2x fa-image" />
        </span>
      )}
      {category && category.color ? (
        <div
          className={styles.categoryIndicator}
          style={{ backgroundColor: category.color.hex }}
        />
      ) : null}
      <div className={styles.nameContainer}>
        <p
          className={`${styles.entryName} ${
            name.length > 20 ? styles.medium : null
          } ${name.length > 30 ? styles.long : null}`}
        >
          {name}
        </p>
        {contentNextToName}
      </div>
      {contentDetailsRight}
    </Link>
  );
};

Entry.propTypes = propTypes;
Entry.defaultProps = defaultProps;

export default Entry;
