import React from "react";
import PropTypes from "prop-types";

import styles from "./BlockWeb.module.scss";
import BlockHeader from "../BlockHeader/BlockHeader";
import { useTranslation } from "react-i18next";

const propTypesBlockWeb = {
  block: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  blockTypeContent: PropTypes.node,
  blockActionsButtonContent: PropTypes.node,
  blockTagsContent: PropTypes.node,
  blockReferencesContent: PropTypes.node,
  onEditWebLink: PropTypes.func.isRequired,
};
const defaultPropsBlockWeb = {
  editMode: true,
  blockActionsButtonContent: null,
  blockTypeContent: null,
  blockTagsContent: null,
  blockReferencesContent: null,
};

const BlockWeb = ({
  block,
  editMode,
  blockTypeContent,
  blockActionsButtonContent,
  blockTagsContent,
  blockReferencesContent,
  onEditWebLink,
}) => {
  const { t } = useTranslation();

  let webLinkContent = null;

  if (block.webLink) {
    const iconContent = block.webLink.iconUrl ? (
      <img className={styles.webLinkIcon} src={block.webLink.iconUrl} />
    ) : null;

    const imageContent = block.webLink.imageUrl ? (
      <img className={styles.webLinkImage} src={block.webLink.imageUrl} />
    ) : (
      <div className={styles.webLinkEmptyImage}>
        {" "}
        <span className={`icon is-large ${styles.emptyIcon}`}>
          <i className="fas fa-3x fa-globe" />
        </span>
      </div>
    );

    webLinkContent = (
      <div className={styles.container}>
        <a href={block.webLink.url} target="_blank">
          <div
            className={styles.webLinkContainer}
            onClick={() => {}}
            role="button"
            tabIndex={0}
          >
            <div className={styles.webLinkImageContainer}>{imageContent}</div>
            <div className={styles.webLinkTextContainer}>
              <span className={styles.webLinkTitle}>{block.title}</span>
              <div className={styles.webLinkSourceContainer}>
                {iconContent}
                <span className={styles.webLinkSource}>
                  {block.webLink.source}
                </span>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  } else if (editMode) {
    webLinkContent = (
      <div className={styles.container}>
        <div
          className={styles.emptyContainer}
          onClick={() => {
            onEditWebLink();
          }}
          role="button"
          tabIndex={0}
        >
          <span
            className={`icon is-large ${styles.emptyIcon} ${
              editMode ? styles.emptyIconClickable : ""
            }`}
          >
            <i className="fas fa-3x fa-globe" />
          </span>
          <p>{t("wisdomtree.entry_browser.block_website_choose_hint")}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <BlockHeader
        editMode={editMode}
        blockTypeContent={blockTypeContent}
        blockActionsButtonContent={blockActionsButtonContent}
      />
      {webLinkContent}
      {blockTagsContent}
      {blockReferencesContent}
    </>
  );
};

BlockWeb.propTypes = propTypesBlockWeb;
BlockWeb.defaultProps = defaultPropsBlockWeb;

export default BlockWeb;
