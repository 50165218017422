/* eslint-disable no-loop-func */
/* eslint-disable func-names */
/* eslint-disable class-methods-use-this */
import * as d3 from "d3";
import * as graphHelper from "./GraphHelper";
import userGrayPng from "./user_gray.png";

class GraphContent {
  constructor(
    graphConfig,
    isEditModeEnabled,
    getSelectedEntryId,
    onRootNodeMouseEnter,
    onRootNodeMouseLeave,
    onRootNodeClick,
    onRootNodeStartDrag,
    onRootNodeDrag,
    onRootNodeEndDrag,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onNodeClick,
    onNodeStartDrag,
    onNodeDrag,
    onNodeEndDrag,
    onNodeDragEnter,
    onNodeDragOver,
    onNodeDragLeave,
    onNodeDrop,
    onNodeMouseDown,
    onNodeMouseUp,
    onNodeTouchStart,
    onNodeTouchEnd
  ) {
    this.graphConfig = graphConfig;
    this.isEditModeEnabled = isEditModeEnabled;
    this.getSelectedEntryId = getSelectedEntryId;
    this.onRootNodeMouseEnter = onRootNodeMouseEnter;
    this.onRootNodeMouseLeave = onRootNodeMouseLeave;
    this.onRootNodeClick = onRootNodeClick;
    this.onRootNodeStartDrag = onRootNodeStartDrag;
    this.onRootNodeDrag = onRootNodeDrag;
    this.onRootNodeEndDrag = onRootNodeEndDrag;
    this.onNodeMouseEnter = onNodeMouseEnter;
    this.onNodeMouseLeave = onNodeMouseLeave;
    this.onNodeClick = onNodeClick;
    this.onNodeStartDrag = onNodeStartDrag;
    this.onNodeMouseDown = onNodeMouseDown;
    this.onNodeMouseUp = onNodeMouseUp;
    this.onNodeDrag = onNodeDrag;
    this.onNodeEndDrag = onNodeEndDrag;
    this.onNodeDragEnter = onNodeDragEnter;
    this.onNodeDragOver = onNodeDragOver;
    this.onNodeDragLeave = onNodeDragLeave;
    this.onNodeDrop = onNodeDrop;
    this.onNodeTouchStart = onNodeTouchStart;
    this.onNodeTouchEnd = onNodeTouchEnd;

    this.visibleEntryIds = new Set();
    this.grayedOutEntryIds = new Set();
  }

  getNodeForEntry(entry) {
    return {
      ...entry,
      nodeWidth: graphHelper.getEntryNodeWidth(
        entry.name,
        entry.image,
        this.graphConfig
      ),

      // nodeWidth: nodeTextPaddingLeft + (element.name.length * 12) + nodeTextPaddingRight,
      nodeHeight: this.graphConfig.nodeHeight,
      parentEntry:
        entry.parentEntryId || this.graphConfig.rootEntryId
          ? entry.parentEntryId
          : -1,
    };
  }

  initNodesFromHierarchicalEntries(entries) {
    const nodes = [];
    entries.forEach((entry) => {
      nodes.push({
        ...this.getNodeForEntry(entry),
      });
    });

    this.assignIndicatorNumbers(nodes);

    return nodes;
  }

  initNodesFromReferencedEntries(entries, referencedEntriesToShow) {
    const nodes = [];
    referencedEntriesToShow.forEach((entry) => {
      const nodeForEntry = this.getNodeForEntry(entry);

      const nextVisibleParentEntry = this.determineVisibleParentIdForEntry(
        entries,
        entry
      );
      nodes.push({
        ...nodeForEntry,
        isReference: true,
        inferredParent: nodeForEntry.parentEntry !== nextVisibleParentEntry,
        parentEntry: nextVisibleParentEntry,
      });
    });

    this.assignIndicatorNumbers(nodes);

    return nodes;
  }

  determineVisibleParentIdForEntry(entries, entry) {
    let visibleParentId = -1;

    let nextEntry = entry;
    while (nextEntry.parentEntryId) {
      if (this.visibleEntryIds.has(nextEntry.parentEntryId)) {
        visibleParentId = nextEntry.parentEntryId;
        break;
      }

      const parentEntry = this.findEntryForId(entries, nextEntry.parentEntryId);
      nextEntry = parentEntry;
    }

    return visibleParentId;
  }

  assignIndicatorNumbers(nodes) {
    const parentsOfSelectedEntry = this.lastSelectedEntryId
      ? this.getAllParentIdsForEntryId(this.entries, this.lastSelectedEntryId)
      : new Set();
    // const silblingsOfSelectedEntry = this.lastSelectedEntryId
    //   ? this.getAllSilblingsIdsForEntryId(
    //       this.entries,
    //       this.lastSelectedEntryId
    //     )
    //   : new Set();
    nodes.forEach((node) => {
      // eslint-disable-next-line no-param-reassign
      node.indicatorNumber = this.determineIndicatorNumberRecursive(
        this.entries,
        parentsOfSelectedEntry,
        // silblingsOfSelectedEntry,
        node.id
      );

      let hasHiddenChildren = false;
      for (const childId of node.childEntryIds) {
        if (!this.visibleEntryIds.has(childId)) {
          hasHiddenChildren = true;
          break;
        }
      }

      node.hasHiddenChildren = hasHiddenChildren;
    });
  }

  determineIndicatorNumberRecursive(
    entries,
    parentsOfSelectedEntry,
    // silblingsOfSelectedEntry,
    entryId
  ) {
    const entry = this.findEntryForId(entries, entryId);

    return entry.size;

    // if (!entry.childEntryIds || this.lastSelectedEntryId === entry.id) return 0;

    // let number = 0;
    // // eslint-disable-next-line consistent-return
    // entry.childEntryIds.forEach((childEntryId) => {
    //   if (
    //     this.visibleEntryIds.has(childEntryId)
    //     // this.lastSelectedEntryId === childEntryId ||
    //     // parentsOfSelectedEntry.has(childEntryId)
    //     // || silblingsOfSelectedEntry.has(childEntryId)
    //   ) {
    //     return 0;
    //   }

    //   number += this.determineIndicatorNumberRecursive(
    //     entries,
    //     parentsOfSelectedEntry,
    //     // silblingsOfSelectedEntry,
    //     childEntryId
    //   );
    //   number += 1;
    // });

    // return number;
  }

  updateData(rootElement, entries, references) {
    this.entries = entries;
    this.references = references;

    this.update(
      rootElement,
      this.entries,
      this.references,
      this.getSelectedEntryId()
    );
  }

  updateDataAndSelectedEntry(
    rootElement,
    entries,
    references,
    selectedEntryId
  ) {
    this.entries = entries;
    this.references = references;
    this.lastSelectedEntryId = selectedEntryId;
    this.update(rootElement, this.entries, this.references, selectedEntryId);
  }

  updateSelectedEntry(rootElement, selectedEntryId) {
    if (this.lastSelectedEntryId === selectedEntryId) return;
    this.lastSelectedEntryId = selectedEntryId;

    this.update(rootElement, this.entries, this.references, selectedEntryId);
  }

  update(rootElement, entries, references, selectedEntryId) {
    const hierarchicalEntryIdsToShow = this.determineHierarchicalEntriesToShow(
      entries,
      selectedEntryId
    );
    const referencedEntryIdsToShow = this.determineReferencedEntriesToShow(
      references,
      selectedEntryId
    );

    const allVisibleEntryIds = new Set([
      ...hierarchicalEntryIdsToShow.visibleEntryIds,
      ...referencedEntryIdsToShow.visibleEntryIds,
    ]);
    const allGrayedOutEntryIds = new Set([
      ...hierarchicalEntryIdsToShow.grayedOutEntryIds,
      ...referencedEntryIdsToShow.grayedOutEntryIds,
    ]);

    this.visibleEntryIds = allVisibleEntryIds;
    this.grayedOutEntryIds = allGrayedOutEntryIds;

    // home is always visible
    this.visibleEntryIds.add(-1);

    const hierarchicalEntriesToShow = [];
    hierarchicalEntryIdsToShow.entryIds.forEach((entryId) => {
      hierarchicalEntriesToShow.push(this.findEntryForId(entries, entryId));
    });

    const referenceEntriesToShow = [];
    referencedEntryIdsToShow.entryIds.forEach((entryId) => {
      referenceEntriesToShow.push(this.findEntryForId(entries, entryId));
    });

    this.updateNodesAndEdges(
      rootElement,
      entries,
      references,
      hierarchicalEntriesToShow,
      referenceEntriesToShow
    );
  }

  determineHierarchicalEntriesToShow(entries, selectedEntryId) {
    let visibleEntryIds = new Set();
    // eslint-disable-next-line prefer-const
    let grayedOutEntryIds = new Set();

    if (!entries) {
      return {
        entryIds: [],
        visibleEntryIds: [],
        grayedOutEntryIds: [],
      };
    }

    if (selectedEntryId != null) {
      visibleEntryIds.add(selectedEntryId);

      // add all parents & all direct children
      visibleEntryIds = new Set([
        ...visibleEntryIds,
        ...this.getAllParentIdsForEntryId(entries, selectedEntryId),
        ...this.getAllDirectChildrenIdsForEntryId(entries, selectedEntryId),
        // ...this.getAllSilblingsIdsForEntryId(entries, selectedEntryId),
      ]);
    } else {
      // nothing selected
      const firstLevelEntryIds = new Set();
      entries.forEach((entry) => {
        if (!entry.parentEntryId) {
          firstLevelEntryIds.add(entry.id);
          visibleEntryIds.add(entry.id);
        }
      });

      // firstLevelEntryIds.forEach((entryId) => {
      //   visibleEntryIds = new Set([
      //     ...visibleEntryIds,
      //     ...this.getAllDirectChildrenIdsForEntryId(entries, entryId),
      //   ]);
      // });
    }

    // visibleEntryIds.forEach(entryId => {
    //   grayedOutEntryIds = new Set([
    //     ...grayedOutEntryIds,
    //     ...this.getAllParentIdsForEntryId(entries, entryId),
    //     ...this.getAllDirectChildrenIdsForEntryId(entries, entryId)
    //   ]);
    // });

    // always add top level entries to grayedOut
    // entries.forEach(entry => {
    //   if (!entry.parentEntryId) {
    //     grayedOutEntryIds.add(entry.id);
    //   }
    // });

    // always add top level entries to visible
    // entries.forEach((entry) => {
    //   if (!entry.parentEntryId) {
    //     visibleEntryIds.add(entry.id);
    //   }
    // });

    visibleEntryIds.forEach((entryId) => {
      grayedOutEntryIds.delete(entryId);
    });

    const mergedIds = new Set([...visibleEntryIds, ...grayedOutEntryIds]);

    return {
      entryIds: mergedIds,
      visibleEntryIds,
      grayedOutEntryIds,
    };
  }

  determineReferencedEntriesToShow(references, selectedEntryId) {
    let visibleEntryIds = new Set();
    // eslint-disable-next-line prefer-const
    let grayedOutEntryIds = new Set();

    if (!references || references.length === 0 || selectedEntryId == null) {
      return {
        entryIds: [],
        visibleEntryIds: [],
        grayedOutEntryIds: [],
      };
    }

    references.forEach((reference) => {
      if (reference.sourceId === selectedEntryId) {
        visibleEntryIds.add(reference.targetId);
      }

      if (reference.targetId === selectedEntryId) {
        visibleEntryIds.add(reference.sourceId);
      }
    });

    const mergedIds = new Set([...visibleEntryIds, ...grayedOutEntryIds]);

    return {
      entryIds: mergedIds,
      visibleEntryIds,
      grayedOutEntryIds,
    };
  }

  getAllParentIdsForEntryId(entries, entryId) {
    const allParentIds = new Set();

    const entry = this.findEntryForId(entries, entryId);
    let nextEntry = entry;
    while (nextEntry.parentEntryId) {
      const parentEntry = this.findEntryForId(entries, nextEntry.parentEntryId);
      allParentIds.add(parentEntry.id);

      nextEntry = parentEntry;
    }

    return allParentIds;
  }

  getAllDirectChildrenIdsForEntryId(entries, entryId) {
    const allChildIds = new Set();

    const entry = this.findEntryForId(entries, entryId);
    if (entry.childEntryIds) {
      entry.childEntryIds.forEach((childEntryId) => {
        allChildIds.add(childEntryId);
      });
    }
    return allChildIds;
  }

  getAllDirectChildrenAndTheirChildrenIdsForEntryId(entries, entryId) {
    let all = new Set();

    const directChildrenIds = this.getAllDirectChildrenIdsForEntryId(
      entries,
      entryId
    );
    // eslint-disable-next-line no-restricted-syntax
    for (const childId of directChildrenIds) {
      all = new Set([
        ...all,
        childId,
        ...this.getAllDirectChildrenIdsForEntryId(entries, childId),
      ]);
    }

    return all;
  }

  getAllSilblingsIdsForEntryId(entries, entryId) {
    const allSilblings = new Set();

    const entry = this.findEntryForId(entries, entryId);
    if (!entry.parentEntryId) {
      // show top level entries as silblings
      const topLevelEntries = entries.filter((e) => e.parentEntryId == null);
      topLevelEntries.forEach((e) => {
        allSilblings.add(e.id);
      });
      return allSilblings;
    }

    const parent = this.findEntryForId(entries, entry.parentEntryId);

    if (parent.childEntryIds) {
      parent.childEntryIds.forEach((childEntryId) => {
        if (childEntryId !== entryId) allSilblings.add(childEntryId);
      });
    }
    return allSilblings;
  }

  getChildEntriesIdsRecursive(entries, entryId) {
    let childIds = [];

    const entry = this.findEntryForId(entries, entryId);

    if (!entry.childEntryIds) return childIds;

    entry.childEntryIds.forEach((childEntryId) => {
      childIds = childIds.concat(
        this.getChildEntriesIdsRecursive(entries, childEntryId)
      );
      childIds.push(childEntryId);
    });

    return childIds;
  }

  findEntryForId(entries, id) {
    const entry = entries.find((entry) => {
      return entry.id === id;
    });
    return entry;
  }

  create(rootElement) {
    const selector = d3.select(rootElement).select("#rootGroup");

    selector.append("g").attr("id", `edges`);
    if (!this.graphConfig.rootEntryId) this.createRootNode(rootElement);
    selector.append("g").attr("id", `nodes`);
  }

  createRootNode(rootElement) {
    const graphContent = this;
    const rootNodeSelector = d3
      .select(rootElement)
      .select("#rootGroup")
      .append("g")
      .attr("class", "rootNode")
      .attr("id", `entryId_-1`)
      .call(
        d3
          .drag()
          .on("start", function(d, i) {
            graphContent.onRootNodeStartDrag(this, rootElement, d, i);
          })
          .on("drag", function(d, i) {
            graphContent.onRootNodeDrag(this, rootElement, d, i);
          })
          .on("end", function(d, i) {
            graphContent.onRootNodeEndDrag(this, rootElement, d, i);
          })
      )
      .on("mouseenter", function(d, i) {
        graphContent.onRootNodeMouseEnter(this, rootElement, d, i);
      })
      .on("mouseleave", function(d, i) {
        graphContent.onRootNodeMouseLeave(this, rootElement, d, i);
      })
      .on("click", function(d, i) {
        graphContent.onRootNodeClick(this, rootElement, d, i);
      });

    rootNodeSelector
      .append("circle")
      .attr("filter", "url(#nodeShadow)")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", this.graphConfig.rootNodeRadius)
      .attr("fill", this.graphConfig.nodeColor)
      .attr("stroke", this.graphConfig.nodeStrokeColor)
      .attr("stroke-width", this.graphConfig.nodeStrokeWidth);

    rootNodeSelector
      .append("circle")
      .attr("id", "rootNodeColoredBackground")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", this.graphConfig.rootNodeIconBackgroundSize / 2)
      .attr("fill", this.graphConfig.rootNodeBackgroundColor);

    rootNodeSelector.append("image").attr("id", "rootNodeImage");

    const rootNodeLevelSelector = rootNodeSelector
      .append("g")
      .attr("class", "rootNodeLevel")
      .attr(
        "transform",
        `translate(${this.graphConfig.rootNodeLevelOffsetX},${this.graphConfig.rootNodeLevelOffsetY})`
      );

    rootNodeLevelSelector
      .append("circle")
      .attr("filter", "url(#levelIndicatorShadow)")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", this.graphConfig.rootNodeLevelRadius)
      .attr("fill", this.graphConfig.rootNodeLevelColor)
      .attr("stroke", this.graphConfig.rootNodeLevelStroke)
      .attr("stroke-width", this.graphConfig.rootNodeLevelStrokeWidth);

    rootNodeLevelSelector
      .append("text")
      .attr("id", "levelTextShadow")
      .attr("font-size", this.graphConfig.rootNodeLevelTextSize)
      // .attr("fill", this.graphConfig.rootNodeLevelTextColor)
      .attr("x", 0)
      .attr("y", 0)
      .attr("pointer-events", "none")
      .attr("class", "nodeIndicatorText")
      .attr("dominant-baseline", "central")
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .attr("font-family", `Noto Sans JP", sans-serif;`)
      .attr("stroke", this.graphConfig.rootNodeLevelTextStroke)
      .attr("stroke-width", this.graphConfig.rootNodeLevelTextStrokeWidth);

    rootNodeLevelSelector
      .append("text")
      .attr("id", "levelText")
      .attr("font-size", this.graphConfig.rootNodeLevelTextSize)
      .attr("fill", this.graphConfig.rootNodeLevelTextColor)
      .attr("x", 0)
      .attr("y", 0)
      .attr("pointer-events", "none")
      .attr("class", "nodeIndicatorText")
      .attr("dominant-baseline", "central")
      .attr("text-anchor", "middle")
      .attr("font-family", `Noto Sans JP", sans-serif;`)
      .attr("font-weight", "bold");

    const rootNodeSizeSelector = rootNodeSelector
      .append("g")
      .attr("class", "rootNodeSize")
      .attr(
        "transform",
        `translate(${-this.graphConfig.rootNodeLevelOffsetX},${
          this.graphConfig.rootNodeLevelOffsetY
        })`
      );

    rootNodeSizeSelector
      .append("rect")
      .attr("class", "nodeIndicatorHiddenChildrenRect")
      .attr("filter", "url(#nodeShadow)")
      .attr("height", () => {
        return this.graphConfig.indicatorHeight;
      })
      .attr("width", () => {
        return this.graphConfig.indicatorWidth;
      })
      .attr("x", (d) => {
        return (
          -this.graphConfig.indicatorWidth / 2 +
          this.graphConfig.indicatorHiddenChildrenOffset
        );
      })
      .attr("y", (d) => {
        return -this.graphConfig.indicatorHeight / 2;
      })
      .attr("fill", this.graphConfig.indicatorColor)
      .attr("fill-opacity", 1.0)
      .attr("rx", this.graphConfig.nodeCornerRadius)
      .attr("ry", this.graphConfig.nodeCornerRadius)
      .attr("stroke", this.graphConfig.nodeStrokeColor)
      .attr("stroke-width", this.graphConfig.nodeStrokeWidth)
      .attr("display", "none");

    rootNodeSizeSelector
      .append("rect")
      .attr("class", "nodeIndicatorRect")
      .attr("filter", "url(#nodeShadow)")
      .attr("height", () => {
        return this.graphConfig.indicatorHeight;
      })
      .attr("width", () => {
        return this.graphConfig.indicatorWidth;
      })
      .attr("x", (d) => {
        return -this.graphConfig.indicatorWidth / 2;
      })
      .attr("y", (d) => {
        return -this.graphConfig.indicatorHeight / 2;
      })
      .attr("fill", this.graphConfig.indicatorColor)
      .attr("fill-opacity", 1.0)
      .attr("rx", this.graphConfig.nodeCornerRadius)
      .attr("ry", this.graphConfig.nodeCornerRadius)
      .attr("stroke", this.graphConfig.nodeStrokeColor)
      .attr("stroke-width", this.graphConfig.nodeStrokeWidth);

    rootNodeSizeSelector
      .append("text")
      .attr("id", "indicatorText")
      .attr("pointer-events", "none")
      .attr("class", "nodeIndicatorText")
      .attr("dominant-baseline", "central")
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .attr("x", (d) => {
        //return d.data.nodeWidth - 39;
        return 0;
      })
      .attr("y", (d) => {
        //return d.data.nodeHeight / 1.3;
        return 0;
      })
      .attr("font-size", this.graphConfig.indicatorTextSize)
      .attr("fill", this.graphConfig.indicatorTextColor);

    this.updateRootNodeImage(rootElement);
  }

  updateRootNodeImage(rootElement) {
    const hasUserImage =
      this.graphConfig.user && this.graphConfig.user.image !== null;
    const imageSize = hasUserImage
      ? this.graphConfig.rootNodeImageSize
      : this.graphConfig.rootNodeIconSize;
    let imageHref = hasUserImage ? this.graphConfig.user.image : userGrayPng;

    d3.select(rootElement)
      .select("#entryId_-1")
      .select("image")
      .attr("clip-path", () => (hasUserImage ? "url(#imageRoundClip)" : ""))
      .attr("xlink:href", () => imageHref)
      .attr("x", () => -imageSize / 2)
      .attr("y", () => -imageSize / 2)
      .attr("width", () => imageSize)
      .attr("height", () => imageSize);
  }

  updateRootNodeLevel(rootElement) {
    const hasUserLevel =
      this.graphConfig.user && this.graphConfig.user.level !== null;

    const levelText = hasUserLevel ? this.graphConfig.user.level : "?";

    d3.select(rootElement)
      .select("#entryId_-1")
      .select("#levelText")
      .text(levelText);

    d3.select(rootElement)
      .select("#entryId_-1")
      .select("#levelTextShadow")
      .text(levelText);
  }

  updateRootNodeSize(rootElement) {
    const sizeText = this.graphConfig.user.size
      ? this.graphConfig.user.size
      : 0;

    const indicatorWidth = this.getIndicatorWidthForSize(
      this.graphConfig.user.size
    );

    d3.select(rootElement)
      .select("#entryId_-1")
      .select("#indicatorText")
      .attr("display", (d) => {
        return sizeText ? "inline" : "none";
      })
      .text(sizeText);

    d3.select(rootElement)
      .select("#entryId_-1")
      .select(".nodeIndicatorRect")
      .attr("display", (d) => {
        return sizeText ? "inline" : "none";
      })
      .attr("width", indicatorWidth)
      .attr("x", (d) => {
        return -indicatorWidth / 2;
      });

    d3.select(rootElement)
      .select("#entryId_-1")
      .select(".nodeIndicatorHiddenChildrenRect")
      .attr("width", indicatorWidth)
      .attr("x", (d) => {
        return (
          -indicatorWidth / 2 + this.graphConfig.indicatorHiddenChildrenOffset
        );
      });
  }

  updateNodesAndEdges(
    rootElement,
    entries,
    references,
    hierarchicalEntriesToShow,
    referencedEntriesToShow
  ) {
    // console.time("updateNodesAndEdges");
    // init nodes
    const entryNodes = [];

    entryNodes.push(
      ...this.initNodesFromHierarchicalEntries(hierarchicalEntriesToShow)
    );

    const nodesFromReferences = this.initNodesFromReferencedEntries(
      entries,
      referencedEntriesToShow
    );

    nodesFromReferences.forEach((nodeFromReference) => {
      const alreadyExistingNode = entryNodes.find(
        (node) => node.id === nodeFromReference.id
      );

      if (alreadyExistingNode == null) {
        entryNodes.push(nodeFromReference);
      } else {
        alreadyExistingNode.isReference = true;
      }
    });

    if (!entryNodes) {
      return;
    }

    if (!this.graphConfig.rootEntryId) {
      entryNodes.push({
        id: -1,
        x: 0,
        y: 0,
        nodeWidth: this.graphConfig.rootNodeRadius * 2,
        nodeHeight: this.graphConfig.rootNodeRadius * 2,
      });
    }

    const stratify = d3
      .stratify()
      .id((d) => {
        return d.id;
      })
      .parentId((d) => {
        return d.parentEntry;
      });

    const hierarchy = stratify(entryNodes);
    this.allNodes = hierarchy.descendants();
    const hierarchyEdges = hierarchy.links();

    hierarchyEdges.forEach((edge) => {
      if (edge.target.data.inferredParent) edge.isInferred = true;
    });

    const referenceEdges = [];
    references.forEach((reference) => {
      const sourceNode = this.allNodes.find(
        (node) => node.id === reference.sourceId
      );
      const targetNode = this.allNodes.find(
        (node) => node.id === reference.targetId
      );

      if (sourceNode != null && targetNode != null) {
        referenceEdges.push({
          source: sourceNode,
          target: targetNode,
          isReference: true,
        });
      }
    });

    this.allEdges = [...hierarchyEdges, ...referenceEdges];
    // console.log(hierarchy.descendants());
    // console.log(hierarchy.links());

    this.allNodes.forEach((node) => {
      if (node.data.parentEntry === -1) {
        // eslint-disable-next-line no-param-reassign
        delete node.data.parentEntry;
      }
    });

    // console.log({ allNodes: this.allNodes });
    // console.log({ allEdges: this.allEdges });

    const graphContent = this;
    const rootGroup = d3.select(rootElement).select("#rootGroup");
    let edgeSelector = rootGroup
      .select("#edges")
      .selectAll(".edge")
      .data(this.allEdges, function(d) {
        return `${d.source.data.id}_${d.target.data.id}`;
      });

    // UPDATE

    edgeSelector
      .transition()
      .duration((edge) => {
        const becomesGrayedOut =
          graphContent.grayedOutEntryIds.has(edge.source.data.id) ||
          graphContent.grayedOutEntryIds.has(edge.target.data.id);
        return becomesGrayedOut
          ? graphContent.graphConfig.durationFadeOutEdge
          : graphContent.graphConfig.durationFadeInEdge;
      })
      .attr("opacity", (edge) => {
        return graphContent.grayedOutEntryIds.has(edge.source.data.id) ||
          graphContent.grayedOutEntryIds.has(edge.target.data.id)
          ? graphContent.graphConfig.opacityFadedOutEdge
          : graphContent.graphConfig.edgeOpacity;
      });
    // EXIT

    edgeSelector
      .exit()
      .transition()
      .duration(this.graphConfig.durationFadeOutEdge)
      .attr("opacity", 0);

    edgeSelector
      .exit()
      .attr("class", "edgeExiting")
      .transition()
      .delay(this.graphConfig.durationFadeOutEdge)
      .duration(this.graphConfig.durationFadeOutEdge)
      .attr("opacity", 0)
      .remove();

    // ENTER

    const edgeEnter = edgeSelector
      .enter()
      .append("line")
      .attr("class", "edge")
      .attr("id", (d) => {
        return `edgeId_${d.source.id}_${d.target.id}`;
      });

    edgeEnter
      .transition()
      .duration(this.graphConfig.durationFadeInEdge)
      .attr("opacity", (edge) => {
        if (
          graphContent.grayedOutEntryIds.has(edge.source.data.id) ||
          graphContent.grayedOutEntryIds.has(edge.target.data.id)
        ) {
          return graphContent.graphConfig.opacityFadedOutEdge;
        } else if (edge.isReference) {
          return graphContent.graphConfig.edgeReferenceOpacity;
        } else {
          return graphContent.graphConfig.edgeOpacity;
        }
      });

    // ENTER + UPDATE

    edgeSelector = edgeEnter.merge(edgeSelector);

    edgeSelector
      .attr("x1", function(edge) {
        d3.select(this).attr("y1", edge.source.data.y);
        return edge.source.data.x;
      })
      .attr("x2", function(edge) {
        d3.select(this).attr("y2", edge.target.data.y);
        return edge.target.data.x;
      })
      .attr("fill", "none")
      .attr("stroke-width", (d) => {
        if (d.isReference) {
          return this.graphConfig.edgeReferenceStrokeWidth;
        } else {
          return this.graphConfig.edgeStrokeWidth;
        }
      })
      .attr("stroke", (d) => {
        if (d.isReference) {
          return this.graphConfig.edgeReferenceColor;
        } else {
          return this.graphConfig.edgeColor;
        }
      })
      .style("stroke-dasharray", (d) => {
        if (d.isInferred) {
          return this.graphConfig.edgeInferredStrokePattern;
        } else if (d.isReference) {
          return this.graphConfig.edgeReferenceStrokePattern;
        } else {
          return null;
        }
      });

    const allNodesWithoutRootNode = this.allNodes.filter((node) => {
      return node.data.id !== -1;
    });

    const nodeSelector = d3
      .select(rootElement)
      .select("#rootGroup")
      .select("#nodes")
      .selectAll(".node")
      .data(allNodesWithoutRootNode, function(d) {
        return `entryId_${d ? d.data.id : d}`;
      });

    // UPDATE

    nodeSelector
      .select(".singleNode")
      .transition()
      .duration((d) => {
        const becomesGrayedOut = graphContent.grayedOutEntryIds.has(d.data.id);
        return becomesGrayedOut
          ? this.graphConfig.durationFadeOutNode
          : this.graphConfig.durationFadeInNode;
      })
      .attr("opacity", (d) => {
        return graphContent.grayedOutEntryIds.has(d.data.id)
          ? graphContent.graphConfig.opacityFadedOutNode
          : graphContent.graphConfig.nodeOpacity;
      });

    nodeSelector
      .select(".nodeImage")
      .transition()
      .delay((d) => {
        const becomesGrayedOut = graphContent.grayedOutEntryIds.has(d.data.id);
        return becomesGrayedOut ? this.graphConfig.durationFadeOutNode : 0;
      })
      .attr("filter", (d) => {
        if (!d) return "";
        return graphContent.grayedOutEntryIds.has(d.data.id)
          ? "url(#grayScale)"
          : "";
      });

    // EXIT

    nodeSelector
      .exit()
      .select(".singleNode")
      .transition()
      .duration(this.graphConfig.durationFadeOutNode)
      .attr("opacity", 0);

    nodeSelector
      .exit()
      .attr("class", "singleNodeExiting")
      .transition()
      .delay(this.graphConfig.durationFadeOutNode)
      .remove();

    // ENTER

    const nodeEnter = nodeSelector
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("id", (d) => {
        return `entryId_${d.id}`;
      })
      .call(
        d3
          .drag()
          .on("start", function(d, i) {
            graphContent.onNodeStartDrag(this, rootElement, d, i);
          })
          .on("drag", function(d, i) {
            graphContent.onNodeDrag(this, rootElement, d, i);
          })
          .on("end", function(d, i) {
            graphContent.onNodeEndDrag(this, rootElement, d, i);
          })
      );

    const innerNodeEnter = nodeEnter
      .append("g")
      .attr("class", "singleNode")
      .on("mouseenter", function(d, i) {
        graphContent.onNodeMouseEnter(this, rootElement, d, i);
      })
      .on("mouseleave", function(d, i) {
        graphContent.onNodeMouseLeave(this, rootElement, d, i);
      })
      .on("mousedown", function(d, i) {
        graphContent.onNodeMouseDown(this, rootElement, d, i);
      })
      .on("mouseup", function(d, i) {
        graphContent.onNodeMouseUp(this, rootElement, d, i);
      })
      .on(
        "touchstart",
        function(d, i) {
          graphContent.onNodeTouchStart(this, rootElement, d, i);
        },
        true
      )
      .on(
        "touchend",
        function(d, i) {
          graphContent.onNodeTouchEnd(this, rootElement, d, i);
        },
        true
      )
      .on("dragenter", function(d, i) {
        graphContent.onNodeDragEnter(this, rootElement, d, i);
      })
      .on("dragleave", function(d, i) {
        graphContent.onNodeDragLeave(this, rootElement, d, i);
      })
      .on("dragover", function(d, i) {
        graphContent.onNodeDragOver(this, rootElement, d, i);
      })
      .on("drop", function(d, i) {
        graphContent.onNodeDrop(this, rootElement, d, i);
      })
      .on("click", function(d, i) {
        graphContent.onNodeClick(this, rootElement, d, i);
      });

    innerNodeEnter
      .transition()
      .duration(this.graphConfig.durationFadeInNode)
      .attr("opacity", (d) => {
        return graphContent.grayedOutEntryIds.has(d.data.id)
          ? graphContent.graphConfig.opacityFadedOutNode
          : graphContent.graphConfig.nodeOpacity;
      });

    innerNodeEnter.append("rect").attr("class", "nodeRect");

    innerNodeEnter
      .append("rect")
      .attr("class", "nodeImageBackground")
      .attr("pointer-events", "none");

    innerNodeEnter
      .append("rect")
      .attr("class", "nodeCategoryIndicator")
      .attr("pointer-events", "none");

    innerNodeEnter
      .append("image")
      .attr("class", "nodeImage")
      .attr("pointer-events", "none")
      .attr("x", 0)
      .attr("y", 0)
      .attr("filter", (d) => {
        if (!d) return "";
        return graphContent.grayedOutEntryIds.has(d.data.id)
          ? "url(#grayScale)"
          : "";
      });

    innerNodeEnter
      .append("text")
      .attr("class", "nodeText")
      .attr("pointer-events", "none")
      .attr("dominant-baseline", "central");

    const innerNodeIndicatorEnter = innerNodeEnter
      .append("g")
      .attr("class", "nodeIndicator");

    innerNodeIndicatorEnter
      .append("rect")
      .attr("class", "nodeIndicatorHiddenChildrenRect");

    innerNodeIndicatorEnter.append("rect").attr("class", "nodeIndicatorRect");

    innerNodeIndicatorEnter
      .append("text")
      .attr("pointer-events", "none")
      .attr("class", "nodeIndicatorText")
      .attr("dominant-baseline", "central")
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold");

    // ENTER + UPDATE

    nodeEnter.merge(nodeSelector).attr("transform", (d) => {
      return `translate(${d.data.x},${d.data.y})`;
    });

    nodeEnter
      .merge(nodeSelector)
      .select(".singleNode")
      .attr("transform", (d) => {
        return `translate(${-d.data.nodeWidth / 2},${-d.data.nodeHeight / 2})`;
      });

    nodeEnter
      .merge(nodeSelector)
      .select(".nodeRect")
      .attr("filter", "url(#nodeShadow)")
      .attr("height", (d) => {
        return d.data.nodeHeight;
      })
      .attr("width", (d) => {
        return d.data.nodeWidth;
      })
      .attr("fill", this.graphConfig.nodeColor)
      .attr("fill-opacity", 1.0)
      .attr("rx", this.graphConfig.nodeCornerRadius)
      .attr("ry", this.graphConfig.nodeCornerRadius)
      .attr("stroke", (d) => {
        if (d.data.isReference) {
          if (d.data.category && d.data.category.color) {
            return d.data.category.color.hex;
          } else {
            return this.graphConfig.nodeReferenceStrokeColor;
          }
        } else {
          return this.graphConfig.nodeStrokeColor;
        }
      })
      .attr("stroke-width", (d) => {
        if (d.data.isReference) {
          return this.graphConfig.nodeReferenceStrokeWidth;
        } else {
          return this.graphConfig.nodeStrokeWidth;
        }
      });

    nodeEnter
      .merge(nodeSelector)
      .select(".nodeImage")
      .attr("clip-path", "url(#imageClip)")
      .attr("xlink:href", (d) => {
        return d.data.image ? d.data.image.url : null;
      })
      .attr("width", this.graphConfig.nodeImageSize)
      .attr("height", this.graphConfig.nodeImageSize);

    nodeEnter
      .merge(nodeSelector)
      .select(".nodeImageBackground")
      .attr("clip-path", "url(#imageClip)")
      .attr("fill", this.graphConfig.nodeImageBackgroundColor)
      .attr("width", (d) => {
        return d.data.image ? this.graphConfig.nodeImageSize : 0;
      })
      .attr("height", (d) => {
        return d.data.image ? this.graphConfig.nodeImageSize : 0;
      });

    nodeEnter
      .merge(nodeSelector)
      .select(".nodeCategoryIndicator")
      .attr("clip-path", (d) => {
        return d.data.image ? null : "url(#imageClip)";
      })
      .attr("x", (d) => {
        return d.data.image ? this.graphConfig.nodeImageSize : 0;
      })
      .attr("y", 0)
      .attr("fill", (d) => {
        if (d.data.category && d.data.category.color) {
          return d.data.category.color.hex;
        } else {
          return null;
        }
      })
      .attr("width", (d) => {
        return d.data.category && d.data.category.color
          ? this.graphConfig.nodeColorIndicatorWidth
          : 0;
      })
      .attr("height", (d) => {
        return d.data.category && d.data.category.color
          ? this.graphConfig.nodeHeight
          : 0;
      });

    nodeEnter
      .merge(nodeSelector)
      .select(".nodeText")
      .attr("x", (d) => {
        return (
          (d.data.image ? this.graphConfig.nodeImageSize : 0) +
          this.graphConfig.nodeTextPaddingLeft
        );
      })
      .attr("y", (d) => {
        return d.data.nodeHeight / 2;
      })
      .text(function(d) {
        return d.data.name;
      })
      .attr("font-size", this.graphConfig.nodeTextSize)
      .attr("fill", this.graphConfig.nodeTextColor);

    nodeEnter
      .merge(nodeSelector)
      .select(".nodeIndicator")
      .attr("display", (d) => {
        return d.data.indicatorNumber ? "inline" : "none";
      });

    nodeEnter
      .merge(nodeSelector)
      .select(".nodeIndicatorHiddenChildrenRect")
      .attr("filter", "url(#nodeShadow)")
      .attr("height", () => {
        return this.graphConfig.indicatorHeight;
      })
      .attr("width", (d) => {
        return this.getIndicatorWidthForSize(d.data.size);
      })
      .attr("x", (d) => {
        return (
          this.getIndicatorXForDynamicWidths(
            d.data.nodeWidth,
            this.getIndicatorWidthForSize(d.data.size)
          ) + this.graphConfig.indicatorHiddenChildrenOffset
        );
      })
      .attr("y", (d) => {
        return d.data.nodeHeight / 1.3;
      })
      .attr("fill", this.graphConfig.indicatorColor)
      .attr("fill-opacity", 1.0)
      .attr("rx", this.graphConfig.nodeCornerRadius)
      .attr("ry", this.graphConfig.nodeCornerRadius)
      .attr("stroke", this.graphConfig.nodeStrokeColor)
      .attr("stroke-width", this.graphConfig.nodeStrokeWidth)
      .attr("display", (d) => {
        return d.data.hasHiddenChildren ? "inline" : "none";
      });

    nodeEnter
      .merge(nodeSelector)
      .select(".nodeIndicatorRect")
      .attr("filter", "url(#nodeShadow)")
      .attr("height", () => {
        return this.graphConfig.indicatorHeight;
      })
      .attr("width", (d) => {
        return this.getIndicatorWidthForSize(d.data.size);
      })
      .attr("x", (d) => {
        return this.getIndicatorXForDynamicWidths(
          d.data.nodeWidth,
          this.getIndicatorWidthForSize(d.data.size)
        );
      })
      .attr("y", (d) => {
        return d.data.nodeHeight / 1.3;
      })
      .attr("fill", this.graphConfig.indicatorColor)
      .attr("fill-opacity", 1.0)
      .attr("rx", this.graphConfig.nodeCornerRadius)
      .attr("ry", this.graphConfig.nodeCornerRadius)
      .attr("stroke", this.graphConfig.nodeStrokeColor)
      .attr("stroke-width", this.graphConfig.nodeStrokeWidth);

    nodeEnter
      .merge(nodeSelector)
      .select(".nodeIndicatorText")
      .attr("x", (d) => {
        return this.getIndicatorTextXForDynamicWidths(
          d.data.nodeWidth,
          this.getIndicatorWidthForSize(d.data.size)
        );
      })
      .attr("y", (d) => {
        return d.data.nodeHeight / 1.3 + 9;
      })
      .text(function(d) {
        return `${d.data.indicatorNumber}`;
      })
      .attr("font-size", this.graphConfig.indicatorTextSize)
      .attr("fill", this.graphConfig.indicatorTextColor);

    // console.timeEnd("updateNodesAndEdges");
  }

  getIndicatorWidthForSize(size) {
    if (size < 10) return this.graphConfig.indicatorWidth * 0.6;
    if (size >= 100) return this.graphConfig.indicatorWidth * 1.2;
    return this.graphConfig.indicatorWidth;
  }

  getIndicatorXForDynamicWidths(nodeWidth, indicatorWidth) {
    if (nodeWidth < 75) {
      return nodeWidth - nodeWidth / 2.25 - indicatorWidth / 2;
    }

    return nodeWidth - 45;
  }

  getIndicatorTextXForDynamicWidths(nodeWidth, indicatorWidth) {
    if (nodeWidth < 75) {
      return nodeWidth - nodeWidth / 2.25;
    }

    return nodeWidth - 45 + indicatorWidth / 2;
  }

  isEntryIdVisible(entryId) {
    return this.visibleEntryIds.has(entryId);
  }

  isEntryIdGrayedOut(entryId) {
    return this.grayedOutEntryIds.has(entryId);
  }

  getAllNodes() {
    return this.allNodes;
  }

  getNodeForEntryId(entryId) {
    return this.allNodes.find((node) => node.id === entryId);
  }

  getAllEntries() {
    return this.entries;
  }
}

export default GraphContent;
