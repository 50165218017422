import React from "react";

import styles from "./UserManagement.module.scss";
import UsersList from "./UsersList/UsersList";
import BackButton from "../UI/BackButton/BackButton";

const propTypesUserManagement = {};
const defaultPropsUserManagement = {};

const UserManagement = () => {
  return (
    <div className={styles.container}>
      <BackButton linkTo="/admin" />
      <UsersList />
    </div>
  );
};

UserManagement.propTypes = propTypesUserManagement;
UserManagement.defaultProps = defaultPropsUserManagement;

export default UserManagement;
