exports.getErrorCode = graphQlErrorObject => {
  if (graphQlErrorObject.networkError) {
    return "NETWORK_ERROR";
  }

  if (graphQlErrorObject.graphQLErrors.length >= 1) {
    return graphQlErrorObject.graphQLErrors[0].extensions.code;
  }

  return "UNKNOWN_ERROR";
};

exports.getErrorTranslationKey = graphQlErrorObject => {
  const errorCode = this.getErrorCode(graphQlErrorObject);
  return `error.${errorCode}`;
};
