/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import apolloClient from "./config/apollo";
import axios from "./config/axios";
import i18n from "./config/i18n";
import logging from "./config/logging";

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        // console.log({ message });
        if (message === "block") {
          // console.log("blocking route");
          callback(false);
        } else {
          callback(true);
        }
      }}
    >
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);
