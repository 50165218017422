/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

// eslint-disable-next-line no-unused-vars
import styles from "./ShareModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import {
  FETCH_SHARING,
  mergeSharingAfterUpdate,
  UPDATE_SHARING,
} from "../../../GraphQl/sharings";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import CopyUrl from "../../CopyUrl/CopyUrl";
import Spinner from "../../Spinner/Spinner";
import Checkbox from "../../Checkbox/Checkbox";
import Logger from "js-logger";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useShareModal = ({ onOpen, onClose }) => {
  const { isOpened, open, close } = useBaseModal({ onOpen, onClose });
  const [entryId, setEntryId] = useState(null);
  const [entryName, setEntryName] = useState(null);

  const openModal = (entryIdToShare, entryNameToShare) => {
    setEntryId(entryIdToShare);
    setEntryName(entryNameToShare);
    open();
  };

  const closeModal = () => {
    close();
    setEntryId(null);
    setEntryName(null);
  };

  let content = null;
  if (isOpened) {
    content = (
      <ShareModal
        entryId={entryId}
        entryName={entryName}
        show={isOpened}
        onCloseHandler={close}
      />
    );
  }

  return { open: openModal, close: closeModal, content };
};

useShareModal.propTypes = propTypes;
useShareModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  entryId: PropTypes.string,
  entryName: PropTypes.string,
};

const defaultPropsModal = {
  entryId: null,
  entryName: null,
};

const ShareModal = ({ entryId, entryName, show, onCloseHandler }) => {
  const { t } = useTranslation();
  const checkboxPublicRef = useRef(null);

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!show) return;
      onCloseHandler();
    },
    dependsOn: [onCloseHandler, show],
  });

  const { loading: getSharingLoading, data: getSharingData } = useQuery(
    FETCH_SHARING,
    {
      variables: { entryId },
      fetchPolicy: "cache-and-network",
    }
  );
  const sharing = getSharingData ? getSharingData.fetchSharing : null;

  const [updateSharing, { loading: updateSharingLoading }] = useMutation(
    UPDATE_SHARING,
    {
      update: mergeSharingAfterUpdate,
      onError: (err) => {
        Logger.error(err);
      },
    }
  );

  let bodyContent = null;
  if (sharing) {
    bodyContent = (
      <div>
        <div className={styles.sharingContainer}>
          <div className={styles.sharingCheckboxContainer}>
            <Checkbox
              checked={sharing.public}
              onClick={() => {
                if (!sharing.public) {
                  checkboxPublicRef.current.select();
                  document.execCommand("copy");
                  toast.success(t(`wisdomtree.other.copied`), {
                    autoClose: 2000,
                  });
                }
              }}
              onChange={(event) => {
                const sharedPublic = event.target.checked;
                updateSharing({
                  variables: {
                    id: sharing.id,
                    input: {
                      public: sharedPublic,
                    },
                  },
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateSharing: {
                      __typename: "UpdateSharingResponse",
                      code: 200,
                      success: true,
                      message: "sharing updated",
                      updatedSharing: {
                        ...sharing,
                        public: sharedPublic,
                      },
                    },
                  },
                });
              }}
              text={t("wisdomtree.modal_share.checkbox_public")}
            />
          </div>
          <CopyUrl
            ref={checkboxPublicRef}
            externalRef
            text={sharing.url}
            loading={getSharingLoading}
            disabled={!sharing.public}
          />
        </div>
        {sharing.coveredByParent ? (
          <div className={`box ${styles.hintContainer}`}>
            <div className={styles.hint}>
              <span className={`icon ${styles.icon}`}>
                <i className={`fas fa-info-circle`} />
              </span>
              <span>{t("wisdomtree.modal_share.covered_hint")}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  } else {
    bodyContent = <Spinner />;
  }

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {entryId
              ? t("wisdomtree.modal_share.title_entry", {
                  entry_name: entryName,
                })
              : t("wisdomtree.modal_share.title_home")}
          </p>
          <ModalCloseButton onClick={onCloseHandler} />
        </header>
        <section className="modal-card-body">{bodyContent}</section>
        <footer className="modal-card-foot">
          <button className="button" type="button" onClick={onCloseHandler}>
            {t("wisdomtree.modal_share.confirm")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

ShareModal.propTypes = propTypesModal;
ShareModal.defaultProps = defaultPropsModal;

export default useShareModal;
