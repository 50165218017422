import React from "react";
import PropTypes from "prop-types";

import styles from "./EditReferenceLink.module.scss";
import { useTranslation } from "react-i18next";
import SearchWithSuggestions from "../../SearchWithSuggestions/SearchWithSuggestions";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  SEARCH_ADD_REFERENCE_SUGGESTIONS,
  SEARCH_ADD_REFERENCE,
} from "../../../GraphQl/search";
import Logger from "js-logger";

const propTypesEditReferenceLink = {
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  descriptionTo: PropTypes.string,
  descriptionFrom: PropTypes.string,
  onChangeDescriptionTo: PropTypes.func.isRequired,
  onChangeDescriptionFrom: PropTypes.func.isRequired,
  showDescription: PropTypes.bool,
  onChangeShowDescription: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showEntitySelection: PropTypes.bool,
};
const defaultPropsEditReferenceLink = {
  descriptionTo: null,
  descriptionFrom: null,
  showDescription: false,
  showEntitySelection: true,
};

const EditReferenceLink = ({
  entityType,
  entityId,
  descriptionTo,
  descriptionFrom,
  onChangeDescriptionTo,
  onChangeDescriptionFrom,
  showDescription,
  onChangeShowDescription,
  onSelect,
  onSubmit,
  onCancel,
  showEntitySelection,
}) => {
  const { t } = useTranslation();

  const {
    loading: searchSuggestionsLoading,
    data: searchSuggestionsData,
  } = useQuery(SEARCH_ADD_REFERENCE_SUGGESTIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      entityId,
      entityType,
    },
    onError: (err) => {
      Logger.error(err);
    },
  });
  const searchSuggestions = searchSuggestionsData
    ? searchSuggestionsData.searchSuggestionsAddReference.results
    : null;

  const [search, { loading: searchLoading, data: searchData }] = useLazyQuery(
    SEARCH_ADD_REFERENCE,
    {
      fetchPolicy: "cache-and-network",
      onError: (err) => {
        Logger.error(err);
      },
    }
  );
  let searchResults = searchData ? searchData.search.results : null;

  if (searchResults) {
    searchResults = searchResults.filter((result) => {
      if (result.entry && entityType === "entry") {
        return result.entry.id !== entityId;
      } else if (result.block && entityType === "block") {
        return result.block.id !== entityId;
      } else {
        return true;
      }
    });
  }

  const descriptionContent = (
    <>
      {showEntitySelection ? <hr /> : null}
      {showDescription ? (
        <div className={styles.descriptionContainer}>
          <div className={styles.inputContainer}>
            <div className={`control has-icons-left`}>
              <input
                className={`input ${styles.input}`}
                type="text"
                value={descriptionTo}
                onChange={onChangeDescriptionTo}
                placeholder={t(
                  "wisdomtree.modal_add_reference.add_link.placeholder_description_to"
                )}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-arrow-circle-right"></i>
              </span>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={`control has-icons-left`}>
              <input
                className={`input ${styles.input}`}
                type="text"
                value={descriptionFrom}
                onChange={onChangeDescriptionFrom}
                placeholder={t(
                  "wisdomtree.modal_add_reference.add_link.placeholder_description_from"
                )}
              />
              <span className={`icon is-small is-left`}>
                <i className="fas fa-arrow-circle-left"></i>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <button
          className={`button ${styles.showDescriptionButton}`}
          type="button"
          onClick={() => {
            onChangeShowDescription(true);
          }}
        >
          <span className={`icon ${styles.buttonIcon}`}>
            <i className="fas fa-question-circle" />
          </span>
          <span>
            {t("wisdomtree.modal_add_reference.add_link.show_description")}
          </span>
        </button>
      )}
    </>
  );

  return (
    <div className={styles.container}>
      {showEntitySelection ? (
        <div className={styles.searchContainer}>
          <SearchWithSuggestions
            results={searchResults}
            suggestions={searchSuggestions}
            resultsLoading={searchLoading}
            suggestionsLoading={searchSuggestionsLoading}
            onSearch={(text) => {
              search({
                variables: {
                  input: {
                    query: text.toLowerCase(),
                    searchEntries: true,
                    searchBlocks: true,
                  },
                },
                context: {
                  debounceKey: `search`,
                  debounceTimeout: 200,
                },
              });
            }}
            onSelect={onSelect}
            onSubmit={onSubmit}
            onCancel={onCancel}
            searchPlaceholder={t(
              "wisdomtree.modal_add_reference.add_link.search_placeholder"
            )}
            noResultsMessage={t(
              "wisdomtree.modal_add_reference.add_link.search_no_results"
            )}
          />
        </div>
      ) : null}
      {descriptionContent}
    </div>
  );
};

EditReferenceLink.propTypes = propTypesEditReferenceLink;
EditReferenceLink.defaultProps = defaultPropsEditReferenceLink;

export default EditReferenceLink;
