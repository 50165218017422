import { useEffect } from "react";
import PropTypes from "prop-types";

const propTypes = { handleEvent: PropTypes.func.isRequired };
const defaultProps = {};
function useReactNativeEvents({ handleEvent }) {
  const handleMessage = (event) => {
    let parsedEvent = null;
    try {
      parsedEvent = JSON.parse(event.data);
    } catch (e) {
      return;
    }

    handleEvent(parsedEvent);
  };

  useEffect(() => {
    document.addEventListener("message", handleMessage);
    window.addEventListener("message", handleMessage);

    return () => {
      document.removeEventListener("message", handleMessage);
      window.removeEventListener("message", handleMessage);
    };
  }, []);
}

useReactNativeEvents.propTypes = propTypes;
useReactNativeEvents.defaultProps = defaultProps;

export default useReactNativeEvents;
