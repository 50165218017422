import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "./ChangeNote.module.scss";

const propTypesChangeNote = {
  changeNote: PropTypes.shape({
    id: PropTypes.string,
    text_en: PropTypes.string,
    text_de: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
};
const defaultPropsChangeNote = {};

const ChangeNote = ({ changeNote }) => {
  return (
    <div className={`box ${styles.container}`}>
      <div className={styles.textContainer}>
        <div className={styles.languageContainer}>
          <span className={styles.language}>EN:</span>
          <span>{changeNote.text_en}</span>
        </div>
        <div className={styles.languageContainer}>
          <span className={styles.language}>DE:</span>
          <span>{changeNote.text_de}</span>
        </div>
      </div>
      <div className={styles.dateContainer}>
        {moment(changeNote.createdAt, "x").format("LL")}
      </div>
    </div>
  );
};

ChangeNote.propTypes = propTypesChangeNote;
ChangeNote.defaultProps = defaultPropsChangeNote;

export default ChangeNote;
