import React from "react";
import PropTypes from "prop-types";

import styles from "./CardReview.module.css";
import ContentQuillEditor from "../../../../UI/Editor/ContentQuillEditor";
import TitleNameQuillEditor from "../../../../UI/Editor/TitleQuillEditor";

const propTypesCardReview = {
  card: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    frontText: PropTypes.object,
    frontTitle: PropTypes.string,
    frontImage: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
};
const defaultPropsCardReview = {};

const CardReview = ({ card }) => {
  const quillEditorText = (
    <div className={styles.text}>
      <ContentQuillEditor
        elementId={card.id}
        elementContent={card.frontText}
        stylesClassName="quizTextEditor"
        editMode={false}
        focusInitially={false}
      />
    </div>
  );

  let quillEditorTitle = null;
  if (card.frontTitle) {
    quillEditorTitle = (
      <div className={styles.title}>
        <TitleNameQuillEditor
          elementId={card.id}
          elementTitle={card.frontTitle}
          stylesClassName="quizTitleEditor"
          limit={120}
          editMode={false}
          focusInitially={false}
        />
      </div>
    );
  }

  let image = null;
  if (card.frontImage) {
    image = (
      <div className={styles.image}>
        <img
          src={card.frontImage.url}
          key={card.frontImage.url}
          alt="card"
          width="160"
          height="160"
        />
      </div>
    );
  }

  return (
    <div className={`${styles.container}`}>
      {image}
      <div className={styles.textTitleContainer}>
        {quillEditorTitle}
        {quillEditorText}
      </div>
    </div>
  );
};

CardReview.propTypes = propTypesCardReview;
CardReview.defaultProps = defaultPropsCardReview;

export default CardReview;
