import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./FeatureHeader.module.scss";
import VoteButton from "../../UI/VoteButton/VoteButton";
import { useTranslation } from "react-i18next";
import useAuthentication from "../../../Utils/UseAuthentication";
import { TooltipClickOnly } from "../../../UI/Tooltip/Tooltip";
import ClickableImage from "../../../UI/ClickableImage/ClickableImage";
import moment from "moment";

const propTypesFeatureHeader = {
  feature: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    votes: PropTypes.number,
    hasVoted: PropTypes.bool,
  }),
  onUserClicked: PropTypes.func,
};
const defaultPropsFeatureHeader = {
  onUserClicked: () => {},
};

const FeatureHeader = ({ feature, onUserClicked }) => {
  const { t } = useTranslation();
  const authenticated = useAuthentication();

  const [lastRefreshTimestamp, setLastRefreshTimestamp] = useState(new Date()); // gets updated every x seconds so the UI updates the time info

  useEffect(() => {
    const interval = setInterval(() => {
      setLastRefreshTimestamp(new Date());
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  let contentFeatureStatus = null;
  if (feature.status === "PLANNED" || feature.status === "IN_PROGRESS") {
    contentFeatureStatus = (
      <div className={styles.featureStatusContainer}>
        <span
          className={styles.statusColor}
          style={{
            backgroundColor:
              feature.status === "IN_PROGRESS" ? "#ffc800" : "#009f4b",
          }}
        ></span>
        <p className={styles.statusText}>
          {t(`wisdomtree.roadmap.status.${feature.status}`)}
        </p>
      </div>
    );
  }

  let contentVoteButton = (
    <div>
      <VoteButton
        featureId={feature.id}
        votes={feature.votes}
        hasVoted={feature.hasVoted}
      />
    </div>
  );

  if (!authenticated) {
    contentVoteButton = (
      <TooltipClickOnly content={t("wisdomtree.roadmap.hint_sign_in")}>
        {contentVoteButton}
      </TooltipClickOnly>
    );
  }

  return (
    <div className={`${styles.container} box`}>
      <div className={styles.topRow}>
        {contentVoteButton}
        <div className={styles.titleContainer}>
          {contentFeatureStatus}
          <p className={styles.title}>{feature.title}</p>
        </div>
      </div>
      <div className={styles.secondRow}>
        <div
          className={styles.authorContainer}
          onClick={() => {
            onUserClicked(feature.author.id);
          }}
        >
          <ClickableImage
            editMode={false}
            imageUrl={feature.author.image ? feature.author.image.url : null}
            isLoading={false}
            emptyIcon="fa-user"
            height={24}
            width={24}
            altText="user"
            round
            onImageClicked={() => {}}
            preview={false}
            dropAllowed={false}
          />
          <p className={styles.authorName}>{feature.author.name}</p>
        </div>
        <p className={styles.description}>{feature.description}</p>
        <p className={styles.timeAgo}>
          {moment(feature.createdAt, "x").fromNow()}
        </p>
      </div>
    </div>
  );
};

FeatureHeader.propTypes = propTypesFeatureHeader;
FeatureHeader.defaultProps = defaultPropsFeatureHeader;

export default FeatureHeader;
