import { gql } from "@apollo/client";

const SEARCH_GLOBAL = gql`
  query($input: SearchInput!) {
    search(input: $input) {
      results {
        entry {
          id
          name
          image {
            id
            url
          }
          category {
            color {
              id
              hex
            }
          }
        }
        block {
          id
          title
          type
          entry {
            id
            name
            image {
              id
              url
            }
            category {
              color {
                id
                hex
              }
            }
          }
        }
        foundIn
      }
    }
  }
`;

const SEARCH_ADD_REFERENCE = gql`
  query($input: SearchInput!) {
    search(input: $input) {
      results {
        entry {
          id
          name
          image {
            id
            url
          }
          category {
            color {
              id
              hex
            }
          }
          parentEntry {
            id
            name
          }
        }
        block {
          id
          title
          type
          entry {
            id
            name
            image {
              id
              url
            }
            parentEntry {
              id
              name
            }
            category {
              color {
                id
                hex
              }
            }
          }
        }
        foundIn
      }
    }
  }
`;

const SEARCH_ENTRIES = gql`
  query($input: SearchInput!) {
    search(input: $input) {
      results {
        entry {
          id
          name
          image {
            id
            url
          }
          category {
            color {
              id
              hex
            }
          }
          parentEntry {
            id
            name
          }
        }
        foundIn
      }
    }
  }
`;

const SEARCH_MOVE_BLOCK_SUGGESTIONS = gql`
  query($blockId: ID!) {
    searchSuggestionsMoveBlock(blockId: $blockId) {
      id
      name
      image {
        id
        url
      }
      category {
        color {
          id
          hex
        }
      }
      parentEntry {
        id
        name
      }
    }
  }
`;

const SEARCH_CHANGE_PARENT_SUGGESTIONS = gql`
  query($entryId: ID!) {
    searchSuggestionsChangeParent(entryId: $entryId) {
      id
      name
      image {
        id
        url
      }
      category {
        color {
          id
          hex
        }
      }
      parentEntry {
        id
        name
      }
    }
  }
`;

const SEARCH_ADD_REFERENCE_SUGGESTIONS = gql`
  query($entityId: ID!, $entityType: String!) {
    searchSuggestionsAddReference(
      entityId: $entityId
      entityType: $entityType
    ) {
      results {
        entry {
          id
          name
          image {
            id
            url
          }
          category {
            color {
              id
              hex
            }
          }
          parentEntry {
            id
            name
          }
        }
        block {
          id
          title
          type
          entry {
            id
            name
            image {
              id
              url
            }
            parentEntry {
              id
              name
            }
            category {
              color {
                id
                hex
              }
            }
          }
        }
      }
    }
  }
`;

export {
  SEARCH_GLOBAL,
  SEARCH_ENTRIES,
  SEARCH_MOVE_BLOCK_SUGGESTIONS,
  SEARCH_CHANGE_PARENT_SUGGESTIONS,
  SEARCH_ADD_REFERENCE,
  SEARCH_ADD_REFERENCE_SUGGESTIONS,
};
