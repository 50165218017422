import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { orderBy } from "lodash/collection";
import { useTranslation } from "react-i18next";
import { isMobile as isMobileDevice } from "react-device-detect";

import styles from "./CategoryQuickSelect.module.scss";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES } from "../../GraphQl/categories";
import Category from "./Category/Category";
import Spinner from "../Spinner/Spinner";
import { TooltipDelayed } from "../Tooltip/Tooltip";
import useSetCategoryModal from "../Modal/SetCategoryModal/SetCategoryModal";

const propTypesCategoryQuickSelect = {
  entityType: PropTypes.string.isRequired,
  onCategorySelected: PropTypes.func,
  onDialogOpenedChanged: PropTypes.func,
};
const defaultPropsCategoryQuickSelect = {
  onCategorySelected: () => {},
  onDialogOpenedChanged: () => {},
};

const CategoryQuickSelect = ({
  entityType,
  onCategorySelected,
  onDialogOpenedChanged,
}) => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const {
    open: openSetCategoryModal,
    content: setCategoryModalContent,
  } = useSetCategoryModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => {
      onDialogOpenedChanged(false);
    },
  });

  useEffect(() => {
    onCategorySelected(selectedCategory);
  }, [selectedCategory]);

  const { loading: getCategoriesLoading, data: getCategoriesData } = useQuery(
    GET_CATEGORIES
  );

  let categoriesListContent = null;
  let categoriesForEntityType = [];

  if (getCategoriesData) {
    categoriesForEntityType = getCategoriesData
      ? getCategoriesData.categories.filter(
          (category) => category.type.toLowerCase() === entityType
        )
      : null;
    categoriesForEntityType = orderBy(
      categoriesForEntityType,
      ["usageCount"],
      ["desc"]
    );

    let showAllContent = null;
    let categoriesToShow = categoriesForEntityType;
    const tooManyCategories = categoriesForEntityType.length > 5;
    if (!showAll && tooManyCategories) {
      categoriesToShow = categoriesForEntityType.slice(0, 5);

      showAllContent = (
        <div
          className={`${styles.showAll}`}
          type="button"
          onClick={() => {
            setShowAll(true);
            document.activeElement.blur();
          }}
          tabIndex="0"
          onKeyDown={(e) => {
            // enter
            if (e.keyCode === 13) {
              setShowAll(true);
              document.activeElement.blur();
            }
          }}
        >
          <p>
            {t("wisdomtree.entry_browser.category_show_all", {
              count: categoriesForEntityType.length - 5,
            })}
          </p>
        </div>
      );
    }

    categoriesListContent = categoriesToShow.map((category) => (
      <Category
        key={category.id}
        category={category}
        isSelected={selectedCategory && selectedCategory.id === category.id}
        onSelectCategory={(category) => {
          if (selectedCategory != category) {
            setSelectedCategory(category);
          } else {
            setSelectedCategory(null);
          }
        }}
      />
    ));

    let manageCategoriesButtonContent = null;

    if (!tooManyCategories || showAll) {
      const showNewButton = isMobileDevice || isHovered || isFocused;
      manageCategoriesButtonContent = (
        <TooltipDelayed
          content={t("wisdomtree.entry_browser.tooltips.category_manage")}
        >
          <span
            onClick={() => {
              openSetCategoryModal(null, entityType);
            }}
            onKeyDown={(e) => {
              // enter key
              if (e.keyCode === 13) {
                openSetCategoryModal(null, entityType);
              }
            }}
            role="button"
            tabIndex="0"
            className={`tag "is-light" is-normal new-color ${
              styles.plusButton
            } ${!showNewButton ? styles.hidden : null}`}
          >
            <span className={`icon ${styles.grayIcon}`}>
              <i className="fas fa-pen" />
            </span>
          </span>
        </TooltipDelayed>
      );
    }

    categoriesListContent = (
      <div className={styles.listContainer}>
        {categoriesListContent}
        {manageCategoriesButtonContent}
        {showAllContent}
      </div>
    );
  } else if (getCategoriesLoading) {
    categoriesListContent = (
      <div className={styles.loadingContainer}>
        <Spinner size={8} />
      </div>
    );
  }

  return (
    <>
      {categoriesForEntityType.length > 0 ? (
        <div
          className={styles.container}
          onMouseOver={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        >
          <p className={styles.categoryTitle}>
            {t("wisdomtree.entry_browser.category")}
          </p>
          {categoriesListContent}
        </div>
      ) : (
        <div />
      )}
      {setCategoryModalContent}
    </>
  );
};

CategoryQuickSelect.propTypes = propTypesCategoryQuickSelect;
CategoryQuickSelect.defaultProps = defaultPropsCategoryQuickSelect;

export default CategoryQuickSelect;
