import { useMediaQuery } from "react-responsive";

// export const MAX_WIDTH_MOBILE = 768;
export const MAX_WIDTH_MOBILE_SMALL = 380;
export const MAX_WIDTH_MOBILE = 481;
// 768 is what bulma uses to make columns full width
export const MAX_WIDTH_MOBILE_LARGE = 768;
export const MAX_WIDTH_MOBILE_AND_TABLET = 1023;
export const MIN_WIDTH_DESKTOP = 1024;
export const MIN_WIDTH_WIDESCREEN = 1216;
export const MIN_WIDTH_FULL_HD = 1408;

export const useMobileSmall = () => {
  return useMediaQuery({ maxWidth: MAX_WIDTH_MOBILE_SMALL });
};

export const useMobile = () => {
  return useMediaQuery({ maxWidth: MAX_WIDTH_MOBILE });
};

export const useMobileLarge = () => {
  return useMediaQuery({ maxWidth: MAX_WIDTH_MOBILE_LARGE });
};

export const useMobileOrTablet = () => {
  return useMediaQuery({ maxWidth: MAX_WIDTH_MOBILE_AND_TABLET });
};

export const useDesktop = () => {
  return useMediaQuery({ minWidth: MIN_WIDTH_DESKTOP });
};

export const useWidescreen = () => {
  return useMediaQuery({ minWidth: MIN_WIDTH_WIDESCREEN });
};

export const useFullHd = () => {
  return useMediaQuery({ minWidth: MIN_WIDTH_FULL_HD });
};
