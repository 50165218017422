/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
import styles from "./ChooseColorModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ColorPickerCustom from "../../ColorPickerCustom/ColorPickerCustom";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import ColorPicker from "../../ColorPicker/ColorPicker";
import { isFocusOnColorSwatch } from "../../../Utils/Utils";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onColorChosen: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  nested: PropTypes.bool,
  initalColor: PropTypes.func,
  chooseLoading: PropTypes.bool,
  resetLoading: PropTypes.bool,
  hasReset: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  nested: false,
  chooseLoading: false,
  resetLoading: false,
  hasReset: () => {
    return false;
  },
  initalColor: () => {
    return null;
  },
  onResetHandler: () => {},
};

const useChooseColorModal = ({
  onOpen,
  onClose,
  nested,
  initialColor,
  hasReset,
  onColorChosen,
  onReset,
  chooseLoading,
  resetLoading,
}) => {
  const { isOpened, open, close } = useBaseModal({ onOpen, onClose });
  const [entity, setEntity] = useState(null);

  const onColorChosenHandler = (color) => {
    if (entity) {
      onColorChosen(entity, color);
    } else {
      onColorChosen(color);
    }
  };

  let content = null;
  if (isOpened) {
    content = (
      <ChooseColorModal
        show={isOpened}
        onCloseHandler={close}
        hasReset={hasReset(entity)}
        initialColor={initialColor(entity)}
        onResetHandler={onReset}
        onChooseHandler={onColorChosenHandler}
        chooseLoading={chooseLoading}
        resetLoading={resetLoading}
        nested={nested}
      />
    );
  }

  const openModal = (entityToChooseColorFor = null) => {
    setEntity(entityToChooseColorFor);
    open();
  };

  const closeModal = () => {
    close();
    setEntity(null);
  };

  return { open: openModal, close: closeModal, content, entity };
};

useChooseColorModal.propTypes = propTypes;
useChooseColorModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  initialColor: PropTypes.object,
  chooseLoading: PropTypes.bool,
  resetLoading: PropTypes.bool,
  hasReset: PropTypes.bool,
  onChooseHandler: PropTypes.func.isRequired,
  onResetHandler: PropTypes.func,
  onCloseHandler: PropTypes.func.isRequired,
  nested: PropTypes.bool,
};

const defaultPropsModal = {
  nested: false,
  chooseLoading: false,
  resetLoading: false,
  hasReset: false,
  initialColor: null,
  onResetHandler: () => {},
};

const ChooseColorModal = ({
  show,
  nested,
  initialColor,
  hasReset,
  chooseLoading,
  resetLoading,
  onChooseHandler,
  onCloseHandler,
  onResetHandler,
}) => {
  const { t } = useTranslation();
  const [color, setColor] = useState(initialColor);

  const handleChange = (updatedColor) => setColor(updatedColor);

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!show) return;
      if (isFocusOnColorSwatch(document.activeElement)) return;
      onChooseHandler(color);
    },
    dependsOn: [onChooseHandler, show, document.activeElement],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!show) return;
      onCloseHandler();
    },
    dependsOn: [onCloseHandler, show],
  });

  let resetButtonContent = null;
  if (hasReset) {
    resetButtonContent = (
      <button
        className={`button ${resetLoading ? `is-loading` : ``}`}
        type="submit"
        onClick={() => {
          onResetHandler();
        }}
      >
        {t("wisdomtree.modal_choose_color.reset")}
      </button>
    );
  }

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler} nested={nested}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_choose_color.title")}
          </p>
          <ModalCloseButton
            onClick={onCloseHandler}
            disabled={chooseLoading || resetLoading}
          />
        </header>
        <section className="modal-card-body">
          <ColorPicker selectedColor={color} onColorSelected={handleChange} />
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-primary ${chooseLoading ? `is-loading` : ``}`}
            type="submit"
            onClick={() => {
              onChooseHandler(color);
            }}
          >
            {t("wisdomtree.modal_choose_color.choose")}
          </button>
          {resetButtonContent}
          <button
            className="button"
            type="button"
            onClick={onCloseHandler}
            disabled={resetLoading || chooseLoading}
          >
            {t("wisdomtree.modal_choose_color.cancel")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

ChooseColorModal.propTypes = propTypesModal;
ChooseColorModal.defaultProps = defaultPropsModal;

export default useChooseColorModal;
