/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";

import styles from "./AppErrorBoundry.module.scss";

class AppErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={styles.container}>
          <span className={`icon ${styles.icon}`}>
            <i className="far fa-3x fa-frown-open" />
          </span>
          <p className={styles.title}>Oops, something went wrong!</p>
          <div className={styles.subtitle}>
            <p>
              If the error persists, please report to{" "}
              <a href={"mailto:simon@wisdomtree.app"}>simon@wisdomtree.app</a>.
            </p>
          </div>
          <button
            className={`button is-primary ${styles.button}`}
            onClick={() => {
              window.location.reload();
              return false;
            }}
          >
            Reload Page
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default AppErrorBoundry;
