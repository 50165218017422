/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import { useMutation } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
import styles from "./AddReferenceModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ChooseReferenceType from "./ChooseReferenceType/ChooseReferenceType";
import AddLink from "./AddLink/AddLink";
import AddWebLink from "./AddWebLink/AddWebLink";
import AddText from "./AddText/AddText";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useAddReferenceModal = ({ onOpen, onClose }) => {
  const [entityId, setEntityId] = useState(null);
  const [entityType, setEntityType] = useState(null);
  const [chosenType, setChosenType] = useState(null);
  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose: (preventOnClose) => {
      if (!preventOnClose) onClose();
    },
  });

  const openModal = (entityId, entityType) => {
    setEntityId(entityId);
    setEntityType(entityType);
    open();
  };

  const closeModal = () => {
    close();
    setEntityId(null);
    setEntityType(null);
    setChosenType(null);
  };

  let content = null;
  if (isOpened) {
    let innerContent = null;

    if (chosenType == null) {
      innerContent = (
        <ChooseReferenceType
          onCloseModal={closeModal}
          onChooseType={(type) => setChosenType(type)}
        />
      );
    } else if (chosenType === "link") {
      innerContent = (
        <AddLink
          onCloseModal={closeModal}
          onBack={() => setChosenType(null)}
          entityType={entityType}
          entityId={entityId}
        />
      );
    } else if (chosenType === "link_web") {
      innerContent = (
        <AddWebLink
          onCloseModal={closeModal}
          onBack={() => setChosenType(null)}
          entityType={entityType}
          entityId={entityId}
        />
      );
    } else if (chosenType === "text") {
      innerContent = (
        <AddText
          onCloseModal={closeModal}
          onBack={() => setChosenType(null)}
          entityType={entityType}
          entityId={entityId}
        />
      );
    }

    content = (
      <BaseModal show={isOpened} onCloseHandler={closeModal}>
        {innerContent}
      </BaseModal>
    );
  }

  return { open: openModal, close: closeModal, content };
};

useAddReferenceModal.propTypes = propTypes;
useAddReferenceModal.defaultProps = defaultProps;

export default useAddReferenceModal;
