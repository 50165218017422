import { gql } from "@apollo/client";

import { FRAGMENT_BASIC_ENTRY, FRAGMENT_DETAILED_ENTRY } from "./fragments";
import { mergeEntryUpdates } from "./entries";
import { mergeUserUpdates } from "./user";

const FETCH_SHARING = gql`
  query($entryId: ID) {
    fetchSharing(entryId: $entryId) {
      id
      url
      public
      discoverable
      coveredByParent
    }
  }
`;

const GET_SHARING_CONTENT = gql`
  query($sharingId: ID!) {
    sharingContent(sharingId: $sharingId) {
      sharingId
      author {
        id
        name
        image {
          id
          url
        }
        level
        size
      }
      rootEntryId
      entries {
        ...BasicEntry
      }
    }
  }
  ${FRAGMENT_BASIC_ENTRY}
`;

const GET_SHARED_ENTRY_DETAILS = gql`
  query($sharingId: ID!, $entryId: ID!) {
    sharedEntry(sharingId: $sharingId, entryId: $entryId) {
      ...DetailedEntry
    }
  }
  ${FRAGMENT_DETAILED_ENTRY}
`;

const UPDATE_SHARING = gql`
  mutation($id: ID!, $input: UpdateSharingInput!) {
    updateSharing(id: $id, input: $input) {
      code
      message
      updatedSharing {
        id
        url
        public
        discoverable
        coveredByParent
      }
      updatedEntries {
        id
        shared
      }
      updatedUser {
        id
        shared
      }
    }
  }
`;

const mergeSharingAfterUpdate = (cache, mutationResult) => {
  const { updatedEntries } = mutationResult.data.updateSharing;
  const { updatedUser } = mutationResult.data.updateSharing;

  if (updatedEntries) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }
};

export {
  FETCH_SHARING,
  GET_SHARING_CONTENT,
  GET_SHARED_ENTRY_DETAILS,
  UPDATE_SHARING,
  mergeSharingAfterUpdate,
};
