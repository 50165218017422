import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { throttle } from "lodash";

import styles from "./SharingInfo.module.scss";
import useAuthentication from "../../Utils/UseAuthentication";
import ClickableImage from "../../UI/ClickableImage/ClickableImage";
import useUserProfileModal from "../../UI/Modal/UserProfileModal/UserProfileModal";

const propTypesSharingInfo = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  onDialogOpenedChanged: PropTypes.func.isRequired,
};
const defaultPropsSharingInfo = {};

const SharingInfo = ({ user, onDialogOpenedChanged }) => {
  const { t } = useTranslation();
  const isAuthenticated = useAuthentication();
  const [visible, setVisible] = useState(true);
  const visibleRef = useRef(visible);

  useEffect(() => {
    window.addEventListener("scroll", throttle(handleWindowScroll, 10));
    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);

  useEffect(() => {
    visibleRef.current = visible;
  }, [visible]);

  const {
    open: openUserProfileModal,
    content: userProfileModalContent,
  } = useUserProfileModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
  });

  const handleWindowScroll = () => {
    if (window.scrollY > 50) {
      if (visibleRef.current) {
        setVisible(false);
      }
    }

    if (window.scrollY < 50) {
      if (!visibleRef.current) {
        setVisible(true);
      }
    }
  };

  const userContent = (
    <div className={styles.userInfo}>
      <ClickableImage
        imageUrl={user.image ? user.image.url : null}
        emptyIcon="fa-user"
        altText="user"
        height={24}
        width={24}
        round
        preview={false}
        editMode={false}
        onImageClicked={() => {}}
        dropAllowed={false}
      />
      <span>{user.name}</span>
    </div>
  );

  return (
    <>
      <div
        className={`${styles.container} ${
          visible ? styles.visible : styles.invisible
        }`}
      >
        <div
          className={`box ${styles.innerContainer}`}
          onClick={() => openUserProfileModal(user.id)}
        >
          <div className={styles.sharedInfo}>
            <span className={styles.sharedBy}>
              {t("wisdomtree.entry_browser.shared_by")}
            </span>
            {userContent}
          </div>
        </div>
      </div>
      {userProfileModalContent}
    </>
  );
};

SharingInfo.propTypes = propTypesSharingInfo;
SharingInfo.defaultProps = defaultPropsSharingInfo;

export default SharingInfo;
