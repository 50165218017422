/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
import styles from "./InviteFriendsModal.module.css";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import { GET_INVITE_URL } from "../../../GraphQl/user";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import CopyUrl from "../../CopyUrl/CopyUrl";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useInviteFriendsModal = ({ onOpen, onClose }) => {
  const { isOpened, open, close } = useBaseModal({ onOpen, onClose });

  let content = null;
  if (isOpened) {
    content = <InviteFriendsModal show={isOpened} onCloseHandler={close} />;
  }

  return { open, close, content };
};

useInviteFriendsModal.propTypes = propTypes;
useInviteFriendsModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
};

const defaultPropsModal = {};

const InviteFriendsModal = ({ show, onCloseHandler }) => {
  const { t } = useTranslation();

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!show) return;
      onCloseHandler();
    },
    dependsOn: [onCloseHandler, show],
  });

  const { loading: getInviteUrlLoading, data: getInviteUrl } = useQuery(
    GET_INVITE_URL
  );
  const inviteUrl = getInviteUrl ? getInviteUrl.me.inviteUrl : null;

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_invite_friends.title")}
          </p>
          <ModalCloseButton onClick={onCloseHandler} />
        </header>
        <section className="modal-card-body">
          <p className={styles.text}>
            {t("wisdomtree.modal_invite_friends.text")}
          </p>
          <CopyUrl
            loading={getInviteUrlLoading}
            text={inviteUrl || t("wisdomtree.modal_invite_friends.loading")}
          />
        </section>
        <footer className="modal-card-foot">
          <button className="button" type="button" onClick={onCloseHandler}>
            {t("wisdomtree.modal_invite_friends.confirm")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

InviteFriendsModal.propTypes = propTypesModal;
InviteFriendsModal.defaultProps = defaultPropsModal;

export default useInviteFriendsModal;
