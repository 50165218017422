import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

import "./App.scss";
import Auth from "./components/Auth/Auth";
import Logout from "./components/Auth/Logout/Logout";
import WisdomTree from "./components/WisdomTree/WisdomTree";
import Admin from "./components/Admin/Admin";
import AppErrorBoundry from "./components/Utils/AppErrorBoundry/AppErrorBoundry";
import SharedWisdomTree from "./components/WisdomTree/SharedWisdomTree/SharedWisdomTree";
import useDarkMode from "./components/Utils/UseDarkMode";
import useAuthentication from "./components/Utils/UseAuthentication";
import UnsubscribeFromEmails from "./components/Utils/UnsubscribeFromEmails/UnsubscribeFromEmails";
import VerifyEmail from "./components/Utils/VerifyEmail/VerifyEmail";
import { themeChanged } from "./components/Utils/ReactNativeMessageUtils";
import Roadmap from "./components/Roadmap/Roadmap";

toast.configure();

const App = () => {
  const darkMode = useDarkMode();
  const authenticated = useAuthentication();

  useEffect(() => {
    if (darkMode) {
      document.documentElement.className = "theme-dark";
      document.getElementById(
        "manifest"
      ).href = `${process.env.PUBLIC_URL}/manifest-dark.json`;
    } else {
      document.documentElement.className = "theme-default";
      document.getElementById(
        "manifest"
      ).href = `${process.env.PUBLIC_URL}/manifest.json`;
    }
    themeChanged(darkMode);
  }, [darkMode]);

  let content = null;

  if (authenticated) {
    content = (
      <Switch>
        <Route
          path="/shared/:sharingId/entry/:entryId"
          component={SharedWisdomTree}
        />
        <Route path="/shared/:sharingId" component={SharedWisdomTree} />
        <Route path="/logout" component={Logout} />
        <Route path="/home" component={WisdomTree} />
        <Route path="/entry/:entryId" component={WisdomTree} />
        <Route path="/quiz" component={WisdomTree} />
        <Route
          path="/admin"
          component={(props) => <Admin darkMode={darkMode} {...props} />}
        />
        <Route path="/roadmap" component={Roadmap} />
        <Route path="/unsubscribe" component={UnsubscribeFromEmails} />
        <Route path="/verify" component={VerifyEmail} />
        <Redirect from="/" to="/home" />
      </Switch>
    );
  } else {
    content = (
      <Switch>
        <Route
          path="/shared/:sharingId/entry/:entryId"
          component={SharedWisdomTree}
        />
        <Route path="/shared/:sharingId" component={SharedWisdomTree} />
        <Route path="/auth" component={Auth} />
        <Route path="/roadmap" component={Roadmap} />
        <Route path="/unsubscribe" component={UnsubscribeFromEmails} />
        <Route path="/verify" component={VerifyEmail} />
        <Redirect from="/" to="/auth" />
      </Switch>
    );
  }

  return (
    <>
      <AppErrorBoundry>
        <Helmet>
          <meta name="color-scheme" content={darkMode ? "dark" : "light"} />
        </Helmet>
        <div className={`App`}>{content}</div>
        <ToastContainer />
      </AppErrorBoundry>
    </>
  );
};

export default App;
