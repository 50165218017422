export const postMessage = (type, payload = {}) => {
  if (!window.ReactNativeWebView) return;

  const messageString = JSON.stringify({ ...payload, type });
  window.ReactNativeWebView.postMessage(messageString);
};

export const vibrate = (duration) => {
  postMessage("vibrate", { duration });
};

export const googleLoginClicked = () => {
  postMessage("googleLoginClicked");
};

export const isMobileApp = () => {
  return window.isRNWebView != null && window.isRNWebView;
};

export const requestDevicePushToken = () => {
  postMessage(`requestDevicePushToken`);
};

export const requestNotificationPermissionStatus = () => {
  postMessage(`requestNotificationPermissionStatus`);
};

export const requestNotificationPermission = () => {
  postMessage(`requestNotificationPermission`);
};

export const themeChanged = (darkMode) => {
  postMessage(`themeChanged`, { darkMode });
};
