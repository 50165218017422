import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./Comment.module.scss";
import ClickableImage from "../../../../UI/ClickableImage/ClickableImage";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { UPDATE_FEATURE_REQUEST_COMMENT } from "../../../../GraphQl/roadmap";
import Logger from "js-logger";
import { useHotkey } from "../../../../Utils/Hotkeys";
import useDeleteFeatureRequestCommentModal from "../../../UI/DeleteFeatureRequestCommentModal/DeleteFeatureRequestCommentModal";

const propTypesComment = {
  comment: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    author: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
  editable: PropTypes.bool,
  deleteable: PropTypes.bool,
  onUserClicked: PropTypes.func,
};
const defaultPropsComment = {
  editable: false,
  deleteable: false,
  onUserClicked: () => {},
};

const Comment = ({ comment, editable, deleteable, onUserClicked }) => {
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState(comment.text);
  const [lastRefreshTimestamp, setLastRefreshTimestamp] = useState(new Date()); // gets updated every x seconds so the UI updates the time info

  const [updateComment, { loading: updateCommentLoading }] = useMutation(
    UPDATE_FEATURE_REQUEST_COMMENT,
    {
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        setEditMode(false);
      },
    }
  );

  const {
    open: openDeleteFeatureRequestCommentModal,
    content: deleteFeatureRequestCommentModalContent,
  } = useDeleteFeatureRequestCommentModal({
    onOpen: () => {},
    onClose: () => {},
    onDelete: () => {},
  });

  const onChangeTextHandler = (event) => {
    setText(event.target.value);
  };

  const canSave = editMode && !updateCommentLoading && text.length > 0;
  const wasUpdated = comment.createdAt !== comment.updatedAt;

  const onSaveEdit = () => {
    updateComment({
      variables: {
        id: comment.id,
        input: {
          text: text,
        },
      },
    });
  };
  const onCancelEdit = () => {
    setEditMode(false);
  };

  const onHandleDelete = () => {
    openDeleteFeatureRequestCommentModal(comment);
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!canSave) return;
      onSaveEdit();
    },
    dependsOn: [onSaveEdit, canSave],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!editMode) return;
      onCancelEdit();
    },
    dependsOn: [editMode, onCancelEdit],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setLastRefreshTimestamp(new Date());
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  let textContent = (
    <p className={styles.text}>
      {comment.text}
      {wasUpdated ? (
        <span className={styles.updatedIndicator}>
          {t("wisdomtree.roadmap.comment_edited")}
        </span>
      ) : null}
    </p>
  );
  let editContent = null;
  if ((editable || deleteable) && !editMode) {
    editContent = (
      <div className={styles.editOptionsContainer}>
        {editable ? (
          <a onClick={() => setEditMode(true)}>
            {t("wisdomtree.roadmap.comment_edit")}
          </a>
        ) : null}
        {deleteable ? (
          <a onClick={onHandleDelete}>
            {t("wisdomtree.roadmap.comment_delete")}
          </a>
        ) : null}
      </div>
    );
  } else if (editable && editMode) {
    textContent = (
      <div className={styles.editTextContainer}>
        <input
          className={`input ${styles.input}`}
          type="text"
          value={text}
          onChange={onChangeTextHandler}
          placeholder={t("wisdomtree.roadmap.new_comment_hint")}
        />
        <div className={styles.editTextButtonsContainer}>
          <button
            type="submit"
            className={`button is-primary ${
              updateCommentLoading ? "is-loading" : null
            } ${styles.button}`}
            disabled={updateCommentLoading || text.length === 0}
            onClick={() => {
              onSaveEdit();
            }}
          >
            {t("wisdomtree.roadmap.comment_edit_save")}
          </button>
          <button
            className={`button ${styles.button}`}
            onClick={() => {
              onCancelEdit();
            }}
            disabled={updateCommentLoading}
          >
            {t("wisdomtree.roadmap.comment_edit_cancel")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.container} box`}>
      <div
        className={styles.authorContainer}
        onClick={() => {
          onUserClicked(comment.author.id);
        }}
      >
        <ClickableImage
          editMode={false}
          imageUrl={comment.author.image ? comment.author.image.url : null}
          isLoading={false}
          emptyIcon="fa-user"
          height={24}
          width={24}
          altText="user"
          round
          onImageClicked={() => {}}
          preview={false}
          dropAllowed={false}
        />
        <p className={styles.authorName}>{comment.author.name}</p>
      </div>
      {textContent}
      <div className={styles.bottomContainer}>
        <p className={styles.timeAgo}>
          {moment(comment.createdAt, "x").fromNow()}
        </p>
        {editContent}
      </div>
      {deleteFeatureRequestCommentModalContent}
    </div>
  );
};

Comment.propTypes = propTypesComment;
Comment.defaultProps = defaultPropsComment;

export default Comment;
