/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import quizStyles from "../Quiz.module.scss";
import styles from "./QuizCard.module.scss";
import CardReview from "./CardReview/CardReview";
import CardQuestion from "./CardQuestion/CardQuestion";
import { useHotkey } from "../../../Utils/Hotkeys";
import RatingButton from "./RatingButton/RatingButton";
import Entry from "./Entry/Entry";

const propTypesCard = {
  card: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    block: PropTypes.shape({
      entry: PropTypes.object,
    }),
  }).isRequired,
  onCardFinished: PropTypes.func.isRequired,
};
const defaultPropsCard = {};

const QuizCard = ({ card, onCardFinished }) => {
  const { t } = useTranslation();
  const [revealState, setRevealState] = useState({
    finished: false,
    running: true,
    items: null,
  });

  useEffect(() => {
    if (card.type === "REVIEW") {
      // for now, revleaing is deactivated

      // setRevealState({
      //   finished: false,
      //   running: true,
      //   items: null,
      //   currentIndex: 0
      // });
      setRevealState({
        running: false,
        finished: true,
      });
    } else if (card.type === "QUESTION") {
      setRevealState({
        running: false,
        finished: false,
      });
    }
  }, [card]);

  useEffect(() => {
    if (revealState.running) {
      // check if reinit of revealing state is needed
      if (revealState.items == null && revealState.currentIndex === 0) {
        setTimeout(() => {
          const children = document.querySelectorAll(
            ".quizTextEditor .ql-editor p"
          );

          if (children.length <= 1) {
            setRevealState({
              finished: true,
              running: false,
              items: null,
              currentIndex: 0,
            });
          } else {
            children.forEach((item) => {
              item.setAttribute("class", "reveal hidden");
            });

            setRevealState({
              finished: false,
              running: true,
              items: children,
              currentIndex: 0,
            });
            const item = children[0];
            item.setAttribute("class", "reveal");
          }
        }, 0);
      }
    }
  }, [revealState]);

  const revealNextItem = () => {
    const nextIndex = revealState.currentIndex + 1;

    const item = revealState.items[nextIndex];
    item.setAttribute("class", "reveal");

    if (nextIndex >= revealState.items.length - 1) {
      setRevealState({
        finished: true,
        items: null,
        currentIndex: 0,
        running: false,
      });
    } else {
      setRevealState({
        ...revealState,
        currentIndex: nextIndex,
      });
    }
  };

  const nextStep = () => {
    if (revealState.running) {
      revealNextItem();
    } else {
      setRevealState({
        ...revealState,
        finished: true,
      });
    }
  };

  useHotkey({
    keyNames: ["Space"],
    callback: () => {
      nextStep();
    },
    dependsOn: [revealState],
  });

  const ratingButtonHandler = (rating) => {
    onCardFinished(card, rating);
  };

  let contentCard = null;
  let ratingButtons = null;
  if (card.type === "REVIEW") {
    contentCard = <CardReview card={card} />;
    ratingButtons = [
      {
        rating: 0,
        text: t("wisdomtree.quiz.rating_review_hard"),
        cssclass: "",
      },
      {
        rating: 0.8,
        text: t("wisdomtree.quiz.rating_review_regular"),
        cssclass: "is-primary",
      },
      {
        rating: 1,
        text: t("wisdomtree.quiz.rating_review_easy"),
        cssclass: "is-primary",
      },
    ];
  } else if (card.type === "QUESTION") {
    contentCard = (
      <CardQuestion card={card} isRevealed={revealState.finished} />
    );
    ratingButtons = [
      {
        rating: 0,
        text: t("wisdomtree.quiz.rating_again"),
        cssclass: "is-danger",
      },
      {
        rating: 0.6,
        text: t("wisdomtree.quiz.rating_hard"),
        cssclass: "is-primary",
      },
      {
        rating: 0.8,
        text: t("wisdomtree.quiz.rating_good"),
        cssclass: "is-primary",
      },
      {
        rating: 1,
        text: t("wisdomtree.quiz.rating_easy"),
        cssclass: "is-primary",
      },
    ];
  }

  let contentButtons = null;

  if (revealState.finished) {
    contentButtons = ratingButtons.map((ratingButton) => (
      <RatingButton
        key={`${card.id}${ratingButton.text}`}
        rating={ratingButton.rating}
        text={ratingButton.text}
        cssclass={ratingButton.cssclass}
        // autofocus={ratingButton.autofocus}
        autofocus={false}
        onClickHandler={ratingButtonHandler}
      />
    ));
  } else if (revealState.running) {
    if (revealState.items != null) {
      contentButtons = (
        <button
          type="button"
          className="button"
          onClick={() => revealNextItem()}
        >
          <span>{t("wisdomtree.quiz.continue")}</span>
        </button>
      );
    }
  } else {
    contentButtons = (
      <button
        type="button"
        className="button is-primary is-outlined"
        onClick={() => {
          setRevealState({
            ...revealState,
            finished: true,
          });
        }}
      >
        <span>{t("wisdomtree.quiz.show_answer")}</span>
      </button>
    );
  }

  return (
    <div>
      <div
        className={`box ${quizStyles.cardContainer} ${styles.revealArea}`}
        role="button"
        tabIndex="-1"
        onClick={() => nextStep()}
      >
        <Entry entry={card.block.entry} />
        <div className={quizStyles.cardContentContainer}>{contentCard}</div>
      </div>
      <div className={quizStyles.buttonsContainer}>{contentButtons}</div>
    </div>
  );
};

QuizCard.propTypes = propTypesCard;
QuizCard.defaultProps = defaultPropsCard;

export default QuizCard;
