/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import { CustomPicker } from "react-color";
import tinycolor from "tinycolor2";
import {
  EditableInput,
  Hue,
  Saturation,
} from "react-color/lib/components/common";

import styles from "./ColorPickerCustom.module.scss";
import { getTagTextColor } from "../../Utils/Utils";

const propTypesColorPickerCustom = {};
const defaultPropsColorPickerCustom = {};

const ColorPickerCustom = (props) => {
  const handleChange = (color) => {
    props.onChange(color);
  };

  const inputContent = (
    <EditableInput value={props.hex} onChange={handleChange} />
  );

  const hueContent = (
    <Hue {...props} onChange={handleChange} direction="horizontal" />
  );

  const saturationContent = <Saturation {...props} onChange={handleChange} />;

  const brightness = tinycolor(props.hex).getBrightness();
  const previewIconColor = getTagTextColor(brightness);

  return (
    <div>
      <div className={styles.saturationContainer}>{saturationContent}</div>
      <div className={styles.hueContainer}>{hueContent}</div>

      <div className={styles.previewContainer}>
        <div className={styles.previewRectContainer}>
          <div
            style={{ backgroundColor: props.hex }}
            className={styles.preview}
          />
          <span
            className={`icon ${styles.previewIcon}`}
            style={{ color: previewIconColor }}
          >
            <i className="fas fa-2x fa-palette" aria-hidden="true" />
          </span>
        </div>
        <div className={styles.inputContainer}> {inputContent}</div>
      </div>
    </div>
  );
};

ColorPickerCustom.propTypes = propTypesColorPickerCustom;
ColorPickerCustom.defaultProps = defaultPropsColorPickerCustom;

export default CustomPicker(ColorPickerCustom);
