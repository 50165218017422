import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "./UserInfo.module.scss";

const propTypesUserInfo = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    lastLoginAt: PropTypes.string,
    jobToBeDone: PropTypes.string,
    level: PropTypes.number,
    size: PropTypes.number,
    sharesEntryNames: PropTypes.bool,
    dailyQuotesEnabled: PropTypes.bool,
    verified: PropTypes.bool,
    welcomeConfirmed: PropTypes.bool,
  }).isRequired,
};
const defaultPropsUserInfo = {};

const UserInfo = ({ user }) => {
  const createdAtString = moment(user.createdAt, "x").format("LLL");
  let lastLoginAtString = "never";
  if (user.lastLoginAt) {
    lastLoginAtString = moment(user.lastLoginAt, "x").format("LLL");
  }

  return (
    <>
      <div className={`box ${styles.primaryContainer}`}>
        {user.image ? (
          <img src={user.image.url} className={styles.userImage} alt="entry" />
        ) : (
          <span className={`icon ${styles.userImage} ${styles.userEmptyImage}`}>
            <i className="fas fa-4x fa-user" />
          </span>
        )}

        <div className={styles.userInfo}>
          <p className={styles.userName}>{user.name}</p>
          <p className={styles.userEmail}>{user.email}</p>
          <p>Signed up: {createdAtString}</p>
          <p>Last login: {lastLoginAtString}</p>
        </div>
      </div>
      <div className={`box ${styles.secondaryContainer}`}>
        <div className={styles.userInfo}>
          <p>Level: {user.level}</p>
          <p>Size: {user.size}</p>
          <p>AccountVerified: {user.verified ? "TRUE" : "FALSE"}</p>
          <p>WelcomeConfirmed: {user.welcomeConfirmed ? "TRUE" : "FALSE"}</p>
          <p>JobToBeDone: {user.jobToBeDone}</p>
          <p>
            DailyQuotesEnabled: {user.dailyQuotesEnabled ? "TRUE" : "FALSE"}
          </p>
          <p>SharesEntryNames: {user.sharesEntryNames ? "TRUE" : "FALSE"}</p>
        </div>
      </div>
    </>
  );
};

UserInfo.propTypes = propTypesUserInfo;
UserInfo.defaultProps = defaultPropsUserInfo;

export default UserInfo;
