/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import "tippy.js/animations/shift-away-subtle.css";
import stringSimilarity from "string-similarity";

import styles from "./Autocomplete.module.scss";
import { hasClassOnParent } from "../../Utils/Utils";

const propTypesAutocomplete = {
  inputStyle: PropTypes.string,
  suggestionItemStyles: PropTypes.object,
  hintText: PropTypes.string,
  maxLength: PropTypes.number,
  suggestions: PropTypes.instanceOf(Array),
  showSuggestionsWhenEmpty: PropTypes.bool,
  defaultSuggestion: PropTypes.bool,
  defaultSuggestionPostfix: PropTypes.string,
  shouldShowDefaultSuggestion: PropTypes.func,
  autoFocus: PropTypes.bool,
  popupMinWidth: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onAccept: PropTypes.func.isRequired,
  clearAfterAccept: PropTypes.bool,
};
const defaultPropsAutocomplete = {
  inputStyle: "input",
  suggestionItemStyles: null,
  hintText: "Enter text...",
  maxLength: null,
  suggestions: [],
  showSuggestionsWhenEmpty: false,
  autoFocus: false,
  defaultSuggestion: false,
  defaultSuggestionPostfix: "",
  shouldShowDefaultSuggestion: () => {
    return true;
  },
  popupMinWidth: 0,
  onFocus: () => {},
  onBlur: () => {},
  clearAfterAccept: true,
};

const Autocomplete = ({
  inputStyle,
  suggestionItemStyles,
  hintText,
  maxLength,
  suggestions,
  showSuggestionsWhenEmpty,
  shouldShowDefaultSuggestion,
  autoFocus,
  onAccept,
  defaultSuggestion,
  defaultSuggestionPostfix,
  popupMinWidth,
  onFocus,
  onBlur,
  clearAfterAccept,
}) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [flipped, setFlipped] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    const filtered = filterSuggestions(userInput);
    setFilteredSuggestions(filtered);
  }, [suggestions]);

  useEffect(() => {
    setShowSuggestions(filteredSuggestions.length > 0);
  }, [filteredSuggestions]);

  useEffect(() => {
    if (showSuggestions) {
      scrollToSuggestion(
        filteredSuggestions[getActiveSuggestionFlipDependant()].display
      );
    }
  }, [flipped]);

  useEffect(() => {
    if (showSuggestions) {
      scrollToSuggestion(
        filteredSuggestions[getActiveSuggestionFlipDependant()].display
      );
    }
  }, [activeSuggestion]);

  const scrollToSuggestion = (suggestion) => {
    const container = document.getElementById("suggestions");
    const activeSuggestionElement = document.getElementById(suggestion);

    const maxHeightContainer = parseInt(
      window.getComputedStyle(container).maxHeight
    );
    const heightElement = parseInt(
      window.getComputedStyle(activeSuggestionElement).height
    );

    const offsetTopElement = activeSuggestionElement.offsetTop;

    const isOutsideScrollBottom =
      offsetTopElement + heightElement >
      maxHeightContainer + container.scrollTop;

    const isOutsideScrollTop = offsetTopElement < container.scrollTop;

    if (isOutsideScrollBottom) {
      container.scrollTop =
        offsetTopElement + heightElement - maxHeightContainer;
    } else if (isOutsideScrollTop) {
      container.scrollTop = offsetTopElement;
    }
  };

  const getActiveSuggestionFlipDependant = () => {
    return flipped
      ? filteredSuggestions.length - 1 - activeSuggestion
      : activeSuggestion;
  };

  const filterSuggestions = (text) => {
    if (!showSuggestionsWhenEmpty && text.length === 0) return [];

    const filtered = suggestions.filter(
      (suggestion) => suggestion.toLowerCase().indexOf(text.toLowerCase()) > -1
    );

    // sort remaining results by similarity algorithm
    filtered.sort(
      (a, b) =>
        stringSimilarity.compareTwoStrings(
          text.toUpperCase(),
          b.toUpperCase()
        ) -
        stringSimilarity.compareTwoStrings(text.toUpperCase(), a.toUpperCase())
    );

    const filteredSuggestionObjects = filtered.map((suggestion) => {
      return { value: suggestion, display: suggestion };
    });

    let defaultSuggestionObject;
    if (
      defaultSuggestion &&
      text.length > 0 &&
      shouldShowDefaultSuggestion(text)
    ) {
      let defaultIsIncluded = false;
      filtered.forEach((suggestion) => {
        if (suggestion.toUpperCase() === text.toUpperCase()) {
          defaultIsIncluded = true;
        }
      });

      if (!defaultIsIncluded) {
        defaultSuggestionObject = {
          value: text,
          display: `${text}${defaultSuggestionPostfix}`,
        };
      }
    }

    const all = [];
    if (defaultSuggestionObject) all.push(defaultSuggestionObject);
    return [...filteredSuggestionObjects, ...all];
  };

  const onInputChange = (e) => {
    const text = e.currentTarget.value;
    onTextChange(text);
  };

  const onTextChange = (text) => {
    const filtered = filterSuggestions(text);
    setActiveSuggestion(0);
    setUserInput(text);

    if (filtered.length > 0) {
      setFilteredSuggestions(filtered);
    } else {
      setShowSuggestions(false);
      setTimeout(() => {
        setFilteredSuggestions([]);
      }, 100);
    }
  };

  const closeSuggestionsAndReset = () => {
    onTextChange("");
  };

  const onKeyDown = (e) => {
    // enter key
    if (e.keyCode === 13) {
      const selectedSuggestion = filteredSuggestions[activeSuggestion];
      if (selectedSuggestion) {
        if (clearAfterAccept) {
          closeSuggestionsAndReset();
        } else {
          setUserInput(selectedSuggestion.value);
        }
        onAccept(selectedSuggestion.value);
      } else {
        onAccept(userInput);
      }
    }

    // esc key
    else if (e.keyCode === 27) {
      if (userInput.length > 0) {
        closeSuggestionsAndReset();
      } else {
        document.activeElement.blur();
      }
    }

    // User pressed shift+tab, decrement the index
    else if (e.shiftKey && e.keyCode === 9) {
      onUpPressed();
      e.preventDefault();
    }

    // User pressed tab, increment the index
    else if (e.keyCode === 9) {
      onDownPressed();
      e.preventDefault();
    }

    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (!flipped) {
        onUpPressed();
      } else {
        onDownPressed();
      }
      e.preventDefault();
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (!flipped) {
        onDownPressed();
      } else {
        onUpPressed();
      }
      e.preventDefault();
    }
  };

  const onUpPressed = () => {
    if (activeSuggestion === 0) {
      return;
    }

    setActiveSuggestion(activeSuggestion - 1);
  };

  const onDownPressed = () => {
    if (activeSuggestion + 1 >= filteredSuggestions.length) {
      return;
    }
    setActiveSuggestion(activeSuggestion + 1);
  };

  const onSuggestionClick = (suggestion) => {
    if (clearAfterAccept) {
      closeSuggestionsAndReset();
    } else {
      setUserInput(suggestion.value);
    }
    onAccept(suggestion.value);
    inputRef.current.focus();
  };

  const orderedSuggestions = !flipped
    ? filteredSuggestions
    : filteredSuggestions.reverse();
  let suggestionsContent = orderedSuggestions.map((suggestion, index) => {
    const isActive =
      (!flipped && index === activeSuggestion) ||
      (flipped && orderedSuggestions.length - 1 - index === activeSuggestion);
    return (
      <span
        key={suggestion.display}
        id={suggestion.display}
        style={{ ...suggestionItemStyles }}
        className={`${styles.suggestionItem} ${
          isActive ? styles.activeSuggestionItem : null
        }`}
        onClick={() => onSuggestionClick(suggestion)}
        onMouseEnter={() =>
          setActiveSuggestion(
            flipped ? orderedSuggestions.length - 1 - index : index
          )
        }
        role="button"
        tabIndex="-1"
      >
        {suggestion.display}
      </span>
    );
  });

  suggestionsContent = (
    <div
      id="suggestions"
      style={{
        width: Math.max(
          inputRef.current ? inputRef.current.clientWidth : 0,
          popupMinWidth
        ),
      }}
      className={styles.suggestionsContainer}
    >
      {suggestionsContent}
    </div>
  );

  const onInputFocus = () => {
    setShowSuggestions(filteredSuggestions.length > 0);
    setHasFocus(true);
    onFocus();
  };

  const onInputBlur = (e) => {
    if (hasClassOnParent(e.relatedTarget, "autocomplete")) return;
    setHasFocus(false);
    onBlur();
  };

  let content = null;
  const inputContent = (
    <input
      ref={inputRef}
      className={inputStyle}
      type="text"
      placeholder={hintText}
      maxLength={maxLength}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      value={userInput}
      onChange={onInputChange}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      onKeyDown={onKeyDown}
    />
  );

  if (filteredSuggestions.length > 0) {
    const isDarkMode = document.documentElement.className.includes(
      "theme-dark"
    );

    content = (
      <Tippy
        theme={isDarkMode ? "dark" : "light-border"}
        className="autocomplete"
        interactive
        appendTo={() => document.body}
        visible={showSuggestions && hasFocus}
        animation="shift-away-subtle"
        content={suggestionsContent}
        maxWidth="none"
        arrow={false}
        placement="bottom"
        offset={[0, 5]}
        popperOptions={{
          modifiers: [
            {
              name: "topLogger",
              enabled: true,
              phase: "main",
              fn({ state }) {
                if (state.placement === "top") {
                  setFlipped(true);
                } else if (state.placement === "bottom") {
                  setFlipped(false);
                }
              },
            },
          ],
        }}
        onClickOutside={() => setShowSuggestions(false)}
      >
        {inputContent}
      </Tippy>
    );
  } else {
    content = inputContent;
  }

  return content;
};

Autocomplete.propTypes = propTypesAutocomplete;
Autocomplete.defaultProps = defaultPropsAutocomplete;

export default Autocomplete;
