/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./Header.module.scss";
import SearchBar from "./SearchBar/SearchBar";
import logoWhite from "../../logo_white.svg";
import logo from "../../logo_regular_white.svg";
import { hasClassInHierarchy } from "../Utils/Utils";
import { isMobileApp } from "../Utils/ReactNativeMessageUtils";

const propTypesNavbarBurger = {
  active: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
};

const defaultPropsNavbarBurger = {
  active: false,
};

const NavbarBurger = ({ active, toggleMenu }) => (
  <a
    onClick={toggleMenu}
    className={`${styles.navbarBurger} navbar-burger aria-label="menu" ${
      active ? "is-active" : ""
    }`}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0}
  >
    <span />
    <span />
    <span />
  </a>
);

NavbarBurger.propTypes = propTypesNavbarBurger;
NavbarBurger.defaultProps = defaultPropsNavbarBurger;

const propTypesHeader = {
  editMode: PropTypes.bool,
  darkMode: PropTypes.bool,
  onInviteFriendsClicked: PropTypes.func,
  onUserSettingsClicked: PropTypes.func,
  isAdmin: PropTypes.bool,
  hasSearch: PropTypes.bool,
  hasSettings: PropTypes.bool,
  hasInviteFriends: PropTypes.bool,
  hasLogout: PropTypes.bool,
  hasBackToMyWisdomTree: PropTypes.bool,
  hasCreateAccount: PropTypes.bool,
  hasHelp: PropTypes.bool,
};

const defaultPropsHeader = {
  editMode: false,
  darkMode: false,
  isAdmin: false,
  hasSearch: true,
  hasSettings: true,
  onInviteFriendsClicked: null,
  onUserSettingsClicked: null,
  hasInviteFriends: true,
  hasLogout: true,
  hasBackToMyWisdomTree: false,
  hasCreateAccount: false,
  hasHelp: true,
};

const Header = ({
  editMode,
  darkMode,
  onInviteFriendsClicked,
  onUserSettingsClicked,
  isAdmin,
  hasSearch,
  hasSettings,
  hasInviteFriends,
  hasLogout,
  hasBackToMyWisdomTree,
  hasCreateAccount,
  hasHelp,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [menuActive, setMenuActive] = useState(false);
  const navbarMenuRef = useRef(null);
  const unblockHandle = useRef(null);

  const handleWindowResized = () => {
    if (window.innerWidth > 1021) {
      setMenuActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResized);
    return () => window.removeEventListener("resize", handleWindowResized);
  }, []);

  useEffect(() => {
    if (menuActive) {
      if (navbarMenuRef.current) navbarMenuRef.current.focus();

      unblockHandle.current = history.block((location, action) => {
        closeMenu();
        if (action === "POP") {
          return "block";
        }

        if (unblockHandle && unblockHandle.current) unblockHandle.current();
        return null;
      });
    } else if (unblockHandle && unblockHandle.current) {
      unblockHandle.current();
    }
  }, [menuActive]);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  let searchContent = null;
  if (hasSearch) {
    searchContent = (
      <div className="navbar-item">
        <SearchBar closeMenu={closeMenu} />
      </div>
    );
  }

  let inviteFriendsContent = null;
  if (hasInviteFriends) {
    inviteFriendsContent = (
      <a
        className={`navbar-item ${
          !menuActive ? styles.menuItem : styles.menuItemInBurger
        }`}
        onClick={() => {
          closeMenu();
          onInviteFriendsClicked();
          document.activeElement.blur();
        }}
        data-id="button.invite-friends"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            closeMenu();
            onInviteFriendsClicked();
            document.activeElement.blur();
          }
        }}
      >
        <span className={`${styles.menuItemIcon} icon`}>
          <i className="fas fa-bullhorn" />
        </span>
        <span>{t("wisdomtree.header.invite_friends")}</span>
      </a>
    );
  }

  let userSettingsContent = null;
  if (hasSettings) {
    userSettingsContent = (
      <a
        className={`navbar-item ${
          !menuActive ? styles.menuItem : styles.menuItemInBurger
        }`}
        onClick={() => {
          closeMenu();
          onUserSettingsClicked();
          document.activeElement.blur();
        }}
        data-id="button.user-settings"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            closeMenu();
            onUserSettingsClicked();
            document.activeElement.blur();
          }
        }}
      >
        <span className={`${styles.menuItemIcon} icon`}>
          <i className="fas fa-cog" />
        </span>
        <span>{t("wisdomtree.header.user_settings")}</span>
      </a>
    );
  }

  let helpContent = null;
  if (hasHelp) {
    helpContent = (
      <a
        className={`navbar-item ${
          !menuActive ? styles.menuItem : styles.menuItemInBurger
        }`}
        onClick={() => {
          closeMenu();
          window.open(process.env.REACT_APP_LINK_HOW_IT_WORKS, "_blank");
          document.activeElement.blur();
        }}
        data-id="button.how-it-works"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            closeMenu();
            window.open(process.env.REACT_APP_LINK_HOW_IT_WORKS, "_blank");
            document.activeElement.blur();
          }
        }}
      >
        <span className={`${styles.menuItemIcon} icon`}>
          <i className="fas fa-question-circle" />
        </span>
        <span>{t("wisdomtree.header.help")}</span>
      </a>
    );
  }

  let logoutContent = null;
  if (hasLogout) {
    logoutContent = (
      <Link
        className={`navbar-item ${
          !menuActive ? styles.menuItem : styles.menuItemInBurger
        }`}
        to="/logout"
        data-id="button.logout"
      >
        <span className={`${styles.menuItemIcon} icon`}>
          <i className="fas fa-sign-out-alt" />
        </span>
        <span>{t("wisdomtree.header.logout")}</span>
      </Link>
    );
  }

  let backToMyWisdomTreeContent = null;
  if (hasBackToMyWisdomTree) {
    backToMyWisdomTreeContent = (
      <Link
        className={`navbar-item ${
          !menuActive ? styles.menuItem : styles.menuItemInBurger
        }`}
        to="/home"
      >
        <span className={`${styles.menuItemIcon} icon`}>
          <i className="fas fa-home" />
        </span>
        <span>{t("wisdomtree.header.back_to_my_wisdomtree")}</span>
      </Link>
    );
  }

  let createAccountContent = null;
  if (hasCreateAccount) {
    createAccountContent = (
      <Link
        className={`navbar-item ${
          !menuActive ? styles.menuItem : styles.menuItemInBurger
        }`}
        to="/auth/register"
      >
        <span className={`${styles.menuItemIcon} icon`}>
          <i className="fas fa-sign-in-alt" />
        </span>
        <span>{t("wisdomtree.header.create_account")}</span>
      </Link>
    );
  }

  return (
    <nav
      className={`navbar ${styles.container} ${
        isMobileApp() ? styles.mobileApp : null
      } is-fixed-top is-primary`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className={`container ${styles.innerContainer}`}>
        <div className="navbar-brand">
          <div className={`navbar-item ${styles.logoContainer}`}>
            <Link className={styles.logoLink} to="/home">
              <img
                className={styles.logo}
                src={darkMode ? logo : logoWhite}
                alt="logo"
              />
            </Link>
          </div>
          <NavbarBurger active={menuActive} toggleMenu={() => toggleMenu()} />
        </div>
        <div
          id={styles.navMenu}
          className={`navbar-menu ${menuActive ? "is-active" : ""}`}
          tabIndex={-1}
          ref={navbarMenuRef}
          onBlur={(e) => {
            if (
              hasClassInHierarchy(e.relatedTarget, "navbar-menu") ||
              hasClassInHierarchy(e.relatedTarget, "navbar-burger")
            )
              return;

            closeMenu();
          }}
        >
          <div className="navbar-start">{searchContent}</div>
          <div className="navbar-end">
            {isAdmin ? (
              <Link
                className={`navbar-item ${
                  !menuActive ? styles.menuItem : styles.menuItemInBurger
                }`}
                to="/admin"
              >
                <span className={`${styles.menuItemIcon} icon`}>
                  <i className="fas fa-tools" />
                </span>
                <span>{t("wisdomtree.header.admin")}</span>
              </Link>
            ) : null}
            {inviteFriendsContent}
            {userSettingsContent}
            {helpContent}
            {backToMyWisdomTreeContent}
            {createAccountContent}
            {logoutContent}
          </div>
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = propTypesHeader;
Header.defaultProps = defaultPropsHeader;

export default Header;
