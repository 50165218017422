import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

import styles from "./User.module.scss";
import { getIconForJobToBeDone } from "../../../../Utils/Utils";
import { useMobile } from "../../../../Utils/Responsive";

const propTypesUser = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    lastLoginAt: PropTypes.string,
    jobToBeDone: PropTypes.string,
    level: PropTypes.number,
    size: PropTypes.number,
  }).isRequired,
};
const defaultPropsUser = {};

const User = ({ user }) => {
  const isMobile = useMobile();

  let lastLoginAgoString = "";
  if (user.lastLoginAt) {
    lastLoginAgoString = moment(user.lastLoginAt, "x").fromNow();
  }

  let growthStatsContent = null;

  if (user.experienceThisWeek > 0) {
    growthStatsContent = (
      <div
        title="growth points earned in last 7 days"
        className={styles.userGrowthStats}
      >
        <p className={styles.userGrowthNumber}>{user.experienceThisWeek}</p>
        <span className={`icon ${styles.growthIcon}`}>
          <i className="fas fa-1x fa-seedling" />
        </span>
      </div>
    );
  }

  let jobIcon = user.jobToBeDone
    ? getIconForJobToBeDone(user.jobToBeDone)
    : null;

  return (
    <Link className={`box ${styles.User}`} to={`/admin/user/${user.id}`}>
      {user.image ? (
        <img src={user.image.url} className={styles.userImage} alt="entry" />
      ) : (
        <span className={`icon ${styles.userImage} ${styles.userEmptyImage}`}>
          <i className="fas fa-2x fa-user" />
        </span>
      )}

      <div className={styles.userInfo}>
        <p className={styles.userName}>{user.name}</p>
        <p className={styles.secondaryInfo}>
          <span className={styles.userLevel}>{user.level}</span>
          {jobIcon ? (
            <span className={`icon ${styles.jobToBeDoneIcon}`}>
              <i className={`fas ${jobIcon}`} />
            </span>
          ) : null}
          {!isMobile ? (
            <span className={styles.userEmail}>{user.email}</span>
          ) : null}
        </p>
      </div>
      <div className={styles.userDetailsContainer}>
        <p title="last time active" className={styles.userLastLogin}>
          {lastLoginAgoString}
        </p>
        {growthStatsContent}
      </div>
    </Link>
  );
};

User.propTypes = propTypesUser;
User.defaultProps = defaultPropsUser;

export default User;
