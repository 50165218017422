import { useMutation } from "@apollo/client";
import Logger from "js-logger";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { mergeAfterViewBlock, VIEW_BLOCK } from "../GraphQl/blocks";
import { useFullHd } from "./Responsive";
import useEventListener from "./UseEventListener";

const propTypesuseReadBlockTracking = {
  active: PropTypes.bool,
  block: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  blockRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};
const defaultPropsuseReadBlockTracking = {
  active: true,
  blockRef: null,
};

const useReadBlockTracking = ({ active, block, blockRef }) => {
  const [inProgress, setInProgress] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [documentHidden, setDocumentHidden] = useState(false);
  const [inViewRef, inView] = useInView({
    skip: isFinished,
  });
  const [highestRatioOfBlockSeen, setHighestRatioOfBlockSeen] = useState(0);
  const [timeSecsRequired, setTimeSecsRequired] = useState(null);
  const [currentTimeSecs, setCurrentTimeSecs] = useState(null);
  const [existingInterval, setExsitingInterval] = useState(null);
  const existingIntervalRef = useRef(null);

  const handleVisibilityChanged = () => {
    setDocumentHidden(document.hidden);
  };
  useEventListener("visibilitychange", handleVisibilityChanged);

  const [postBlockViewed, { loading: postBlockViewedLoading }] = useMutation(
    VIEW_BLOCK,
    {
      update: (cache, mutationResult) => {
        mergeAfterViewBlock(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {},
    }
  );

  const getReadingTimeForBlock = (block) => {
    const MIN_TIME = 4;
    const CHAR_PER_SEC = 30;

    let charsTotal = 0;

    let titleToMeasure = block.title;
    if (titleToMeasure) charsTotal += block.title.length;

    let contentToMeasure = block.content;
    if (contentToMeasure && contentToMeasure.ops) {
      contentToMeasure.ops.forEach((element) => {
        if (element.insert && typeof element.insert === "string") {
          charsTotal += element.insert.length;
        }
      });
    }

    let readingTimeRequired = charsTotal / CHAR_PER_SEC;

    // only a fraction of time required if not dusted yet
    const DUSTED_THRESHOLD = 90;
    const MAX_BONUS = 0.5;

    if (block.dust < DUSTED_THRESHOLD) {
      const bonusFactor =
        ((DUSTED_THRESHOLD - block.dust) / DUSTED_THRESHOLD) * MAX_BONUS;
      readingTimeRequired = readingTimeRequired * bonusFactor;
    }

    // console.log({ readingTimeRequired });
    return Math.max(MIN_TIME, Math.round(readingTimeRequired));
  };

  useEffect(() => {
    return () => {
      clearInterval(existingIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (active && blockRef != null && blockRef.current)
      setTimeout(() => inViewRef(blockRef.current), 500);
  }, [blockRef]);

  useEffect(() => {
    if (block) {
      getReadingTimeForBlock(block);
    }
  }, [block]);

  useEffect(() => {
    // console.log(`inView: ${inView} for block id ${block.id}`);
    if (inView > 0 && !inProgress) {
      setTimeSecsRequired(getReadingTimeForBlock(block));
      setInProgress(true);
    } else {
      setInProgress(false);
    }
  }, [inView]);

  const calculateHighestRatioSeen = () => {
    if (blockRef.current) {
      const bounds = blockRef.current.getBoundingClientRect();

      let highestRatioSeen = 0;
      let scrollBottom = window.innerHeight;

      const missing = bounds.bottom - scrollBottom;

      if (missing <= 0) {
        highestRatioSeen = 1;
      } else {
        highestRatioSeen = 1 - missing / bounds.height;
      }

      setHighestRatioOfBlockSeen((current) =>
        Math.max(highestRatioSeen, current)
      );
    }
  };

  const progressInterval = () => {
    if (documentHidden) return;

    const secondsPassed = currentTimeSecs + 1;
    const maxProgress = Math.round(timeSecsRequired * highestRatioOfBlockSeen);
    const newProgress = Math.min(secondsPassed, maxProgress);
    setCurrentTimeSecs(newProgress);

    calculateHighestRatioSeen();

    // console.log({ newProgress, maxProgress, highestRatioOfBlockSeen });
    if (newProgress >= timeSecsRequired) {
      setIsFinished(true);
      setInProgress(false);
      inViewRef(null); // disable the interaction observer, together with the "skip" property

      postBlockViewed({
        variables: {
          id: block.id,
        },
      });
    }
  };
  const progressIntervalRef = useRef(progressInterval);
  useEffect(() => {
    progressIntervalRef.current = progressInterval;
  }, [
    block,
    currentTimeSecs,
    highestRatioOfBlockSeen,
    timeSecsRequired,
    documentHidden,
  ]);

  useEffect(() => {
    if (inProgress) {
      const interval = setInterval(() => progressIntervalRef.current(), 1000);
      setExsitingInterval(interval);
      existingIntervalRef.current = interval;
      calculateHighestRatioSeen();
    } else if (existingIntervalRef.current) {
      clearInterval(existingIntervalRef.current);
    }
  }, [inProgress]);

  return {
    progress: timeSecsRequired ? currentTimeSecs / timeSecsRequired : 0,
  };
};

useReadBlockTracking.propTypes = propTypesuseReadBlockTracking;
useReadBlockTracking.defaultProps = defaultPropsuseReadBlockTracking;

export default useReadBlockTracking;
