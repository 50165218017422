import React, { useEffect, useState, useRef } from "react";
import { throttle } from "lodash";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./LevelProgress.module.scss";
import { useMobileLarge } from "../../Utils/Responsive";

const propTypesLevelProgress = {
  dialogOpened: PropTypes.bool,
  onClickExpEvent: PropTypes.func,
};
const defaultPropsLevelProgress = {
  dialogOpened: false,
  onClickExpEvent: () => {},
};

const LevelProgress = ({ dialogOpened, onClickExpEvent }) => {
  const DISPLAY_TIME_EXP = 2000;
  const DISPLAY_TIME_LEVEL_UP = 4000;

  const { t } = useTranslation();
  const isMobileLarge = useMobileLarge();
  const containerRef = useRef(null);
  const [isVisible, setVisible] = useState(true);
  const [currentExpEvent, setCurrentExpEvent] = useState(null);
  const [expEventQueue, setExpEventQueue] = useState([]);
  const [topOffset, setTopOffset] = useState(0);

  const [currentFadeOutTimeout, setCurrentFadeOutTimeout] = useState(null);
  const [currentFadeOutAnimTimeout, setCurrentFadeOutAnimTimeout] = useState(
    null
  );

  useEffect(() => {
    window.addEventListener("scroll", throttle(handleWindowScrolled, 250), {
      passive: true,
    });
    return () => {
      window.removeEventListener("scroll", handleWindowScrolled);
    };
  }, [isVisible, isMobileLarge]);

  useEffect(() => {
    window.addEventListener("expEvent", handleExpEvent, {
      passive: true,
    });
    return () => {
      window.removeEventListener("expEvent", handleExpEvent);
    };
  }, [currentExpEvent, isVisible, dialogOpened, currentFadeOutAnimTimeout]);

  useEffect(() => {
    if (currentExpEvent) {
      if (currentFadeOutTimeout) {
        clearTimeout(currentFadeOutTimeout);
        setCurrentFadeOutTimeout(null);
      }
      if (currentFadeOutAnimTimeout) {
        clearTimeout(currentFadeOutAnimTimeout);
        setCurrentFadeOutAnimTimeout(null);
      }
      const timeout = setTimeout(
        () => {
          const animTimeout = setTimeout(() => {
            setCurrentExpEvent(null);
          }, 300);
          setCurrentFadeOutAnimTimeout(animTimeout);
        },
        currentExpEvent.levelUp ? DISPLAY_TIME_LEVEL_UP : DISPLAY_TIME_EXP
      );
      setCurrentFadeOutTimeout(timeout);
    }
  }, [currentExpEvent]);

  useEffect(() => {
    if (isVisible && currentExpEvent) {
      setCurrentExpEvent(null);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && !dialogOpened && expEventQueue.length > 0) {
      const combinedExpEvent = { exp: 0, levelUp: false };
      expEventQueue.forEach((expEvent) => {
        combinedExpEvent.exp += expEvent.exp;
        combinedExpEvent.levelUp = expEvent.levelUp
          ? expEvent.levelUp
          : combinedExpEvent.levelUp;
      });

      setExpEventQueue([]);
      setCurrentExpEvent(combinedExpEvent);
    }
  }, [isVisible, dialogOpened]);

  useEffect(() => {
    const questProgressContainer = document.getElementById(
      "questProgressContainer"
    );

    if (
      questProgressContainer &&
      (!questProgressContainer.className ||
        !questProgressContainer.className.includes("invisible"))
    ) {
      setTopOffset(questProgressContainer.clientHeight + 15);
    } else {
      setTopOffset(0);
    }
  }, [document, currentExpEvent]);

  const handleWindowScrolled = () => {
    let isInCorrectScrollY = true;
    if (isMobileLarge) {
      const graphContainer = document.getElementById("graphContainer");
      const scrollYMax = graphContainer ? graphContainer.clientHeight - 50 : 0;
      isInCorrectScrollY = window.scrollY <= scrollYMax;
    }

    if (isVisible && !isInCorrectScrollY) {
      setVisible(false);
    } else if (!isVisible && isInCorrectScrollY) {
      setVisible(true);
    }
  };

  const handleExpEvent = (e) => {
    if (isVisible && !dialogOpened) {
      const combinedExpEvent = { exp: e.detail.exp, levelUp: e.detail.levelUp };
      if (currentExpEvent && !currentFadeOutAnimTimeout) {
        combinedExpEvent.exp = currentExpEvent.exp + combinedExpEvent.exp;
        combinedExpEvent.levelUp =
          currentExpEvent.levelUp || combinedExpEvent.levelUp;
      }
      setCurrentExpEvent(combinedExpEvent);
    } else {
      expEventQueue.push(e.detail);
    }
  };

  let expEventContent = null;
  if (currentExpEvent) {
    expEventContent = (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        ref={containerRef}
        className={classNames(
          styles.expEventContainer,
          currentFadeOutAnimTimeout ? styles.fadeOut : styles.fadeIn
        )}
        onClick={() => onClickExpEvent(currentExpEvent)}
        tabIndex={-1}
        role="button"
      >
        <span className={classNames(styles.text)}>
          {currentExpEvent.levelUp
            ? t("wisdomtree.gamification.level_up")
            : `+${currentExpEvent.exp}`}
        </span>
        <span
          className={classNames(
            "icon",
            currentExpEvent.levelUp ? styles.iconLevelUp : styles.iconExp
          )}
        >
          <i
            className={`fas ${
              currentExpEvent.levelUp ? "fa-star" : "fa-seedling"
            }`}
          />
        </span>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles.container,
        isVisible ? styles.visible : styles.invisible,
        isVisible ? styles.fadeIn : styles.fadeOut
      )}
      style={{ marginTop: topOffset }}
    >
      {expEventContent}
    </div>
  );
};

LevelProgress.propTypes = propTypesLevelProgress;
LevelProgress.defaultProps = defaultPropsLevelProgress;

export default LevelProgress;
