import React from "react";
import PropTypes from "prop-types";

import styles from "./Feature.module.scss";
import VoteButton from "../../../UI/VoteButton/VoteButton";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  UNDO_VOTE_FOR_FEATURE_REQUEST,
  VOTE_FOR_FEATURE_REQUEST,
} from "../../../../GraphQl/roadmap";
import useAuthentication from "../../../../Utils/UseAuthentication";
import { TooltipClickOnly } from "../../../../UI/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";

const propTypesFeature = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  votes: PropTypes.number.isRequired,
  hasVoted: PropTypes.bool.isRequired,
  commentsAmount: PropTypes.number.isRequired,
};
const defaultPropsFeature = {};

const Feature = ({ id, title, votes, hasVoted, commentsAmount }) => {
  const { t } = useTranslation();
  const authenticated = useAuthentication();

  let voteButton = (
    <div className={styles.buttonContainer}>
      <VoteButton featureId={id} votes={votes} hasVoted={hasVoted} />
    </div>
  );

  if (!authenticated) {
    voteButton = (
      <TooltipClickOnly content={t("wisdomtree.roadmap.hint_sign_in")}>
        {voteButton}
      </TooltipClickOnly>
    );
  }

  return (
    <Link className={`${styles.container} box`} to={`/roadmap/feature/${id}`}>
      {voteButton}
      <p className={styles.title}>{title}</p>
      {commentsAmount > 0 ? (
        <div className={styles.comments}>
          <span className={`${styles.icon} icon is-small`}>
            <i className="far fa-comment" />
          </span>
          <p className={styles.commentsAmount}>{commentsAmount}</p>
        </div>
      ) : null}
    </Link>
  );
};

Feature.propTypes = propTypesFeature;
Feature.defaultProps = defaultPropsFeature;

export default Feature;
