import { gql } from "@apollo/client";
import {
  FRAGMENT_BASIC_FEATURE_REQUEST,
  FRAGMENT_COMPLETE_FEATURE_REQUEST,
  FRAGMENT_COMPLETE_FEATURE_REQUEST_COMMENT,
} from "./fragments";

const GET_FEATURE_REQUESTS = gql`
  query {
    featureRequests {
      ...BasicFeatureRequest
    }
  }
  ${FRAGMENT_BASIC_FEATURE_REQUEST}
`;

const GET_FEATURE_REQUEST_DETAILS = gql`
  query($id: ID!) {
    featureRequest(id: $id) {
      ...CompleteFeatureRequest
    }
  }
  ${FRAGMENT_COMPLETE_FEATURE_REQUEST}
`;

const VOTE_FOR_FEATURE_REQUEST = gql`
  mutation($id: ID!) {
    voteForFeatureRequest(id: $id) {
      code
      message
      updatedFeatureRequest {
        ...CompleteFeatureRequest
      }
    }
  }
  ${FRAGMENT_COMPLETE_FEATURE_REQUEST}
`;

const UNDO_VOTE_FOR_FEATURE_REQUEST = gql`
  mutation($id: ID!) {
    undoVoteForFeatureRequest(id: $id) {
      code
      message
      updatedFeatureRequest {
        ...CompleteFeatureRequest
      }
    }
  }
  ${FRAGMENT_COMPLETE_FEATURE_REQUEST}
`;

const CREATE_FEATURE_REQUEST = gql`
  mutation($input: CreateFeatureRequestInput!) {
    createFeatureRequest(input: $input) {
      code
      message
      createdFeatureRequest {
        ...CompleteFeatureRequest
      }
    }
  }
  ${FRAGMENT_COMPLETE_FEATURE_REQUEST}
`;

const UPDATE_FEATURE_REQUEST = gql`
  mutation($id: ID!, $input: UpdateFeatureRequestInput!) {
    updateFeatureRequest(id: $id, input: $input) {
      code
      message
      updatedFeatureRequest {
        ...CompleteFeatureRequest
      }
    }
  }
  ${FRAGMENT_COMPLETE_FEATURE_REQUEST}
`;

const DELETE_FEATURE_REQUEST = gql`
  mutation($id: ID!) {
    deleteFeatureRequest(id: $id) {
      code
      message
      deletedFeatureRequestId
    }
  }
`;

const CREATE_FEATURE_REQUEST_COMMENT = gql`
  mutation($input: CreateFeatureRequestCommentInput!) {
    createFeatureRequestComment(input: $input) {
      code
      message
      createdFeatureRequestComment {
        ...CompleteFeatureRequestComment
      }
      updatedFeatureRequest {
        ...CompleteFeatureRequest
      }
    }
  }
  ${FRAGMENT_COMPLETE_FEATURE_REQUEST_COMMENT}
  ${FRAGMENT_COMPLETE_FEATURE_REQUEST}
`;

const UPDATE_FEATURE_REQUEST_COMMENT = gql`
  mutation($id: ID!, $input: UpdateFeatureRequestCommentInput!) {
    updateFeatureRequestComment(id: $id, input: $input) {
      code
      message
      updatedFeatureRequestComment {
        ...CompleteFeatureRequestComment
      }
    }
  }
  ${FRAGMENT_COMPLETE_FEATURE_REQUEST_COMMENT}
`;

const DELETE_FEATURE_REQUEST_COMMENT = gql`
  mutation($id: ID!) {
    deleteFeatureRequestComment(id: $id) {
      code
      message
      deletedFeatureRequestCommentId
      updatedFeatureRequest {
        ...CompleteFeatureRequest
      }
    }
  }
  ${FRAGMENT_COMPLETE_FEATURE_REQUEST}
`;

const mergeFeatureRequestsAfterCreate = (cache, mutationResult) => {
  const { featureRequests: currentData } = cache.readQuery({
    query: GET_FEATURE_REQUESTS,
  });
  const { createdFeatureRequest } = mutationResult.data.createFeatureRequest;

  // add created data to current data
  const updatedData = currentData.concat([createdFeatureRequest]);

  cache.writeQuery({
    query: GET_FEATURE_REQUESTS,
    data: { featureRequests: updatedData },
  });
};

const mergeFeatureRequestsAfterDelete = (cache, mutationResult) => {
  const { featureRequests: currentData } = cache.readQuery({
    query: GET_FEATURE_REQUESTS,
  });
  const { deletedFeatureRequestId } = mutationResult.data.deleteFeatureRequest;

  // filter out deleted entry ids
  let updatedData = currentData.filter((e) => e.id !== deletedFeatureRequestId);

  cache.writeQuery({
    query: GET_FEATURE_REQUESTS,
    data: { featureRequests: updatedData },
  });
};

export {
  GET_FEATURE_REQUESTS,
  GET_FEATURE_REQUEST_DETAILS,
  VOTE_FOR_FEATURE_REQUEST,
  UNDO_VOTE_FOR_FEATURE_REQUEST,
  CREATE_FEATURE_REQUEST,
  UPDATE_FEATURE_REQUEST,
  DELETE_FEATURE_REQUEST,
  CREATE_FEATURE_REQUEST_COMMENT,
  UPDATE_FEATURE_REQUEST_COMMENT,
  DELETE_FEATURE_REQUEST_COMMENT,
  mergeFeatureRequestsAfterCreate,
  mergeFeatureRequestsAfterDelete,
};
