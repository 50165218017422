/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import hash from "object-hash";
import Logger from "js-logger";
import { animateScroll as scroll } from "react-scroll";
import i18n from "../../config/i18n";
import { isMobile as isMobileDevice } from "react-device-detect";
import moment from "moment";

// eslint-disable-next-line no-unused-vars
import {
  GET_ENTRIES,
  GET_ENTRY_DETAILS,
  CREATE_ENTRY,
  UPDATE_ENTRY,
  DELETE_ENTRY,
  mergeEntriesAfterCreate,
  mergeEntriesAfterUpdate,
  mergeEntriesAfterDelete,
  UPDATE_ENTRY_POSITION,
  UPDATE_ENTRY_POSITION_AND_PARENT,
} from "../GraphQl/entries";
import { GET_CURRENT_USER } from "../GraphQl/user";
import {
  UPDATE_BLOCK,
  mergeBlocksAfterUpdate,
  getOptimisticResponseForMoveBlock,
} from "../GraphQl/blocks";
import Header from "../Header/Header";
import { useMobileLarge } from "../Utils/Responsive";
import EntryBrowser from "./EntryBrowser/EntryBrowser";
import Graph from "./Graph/GraphComponent";
import {
  clickOnEntryInGraph,
  getGraphReferencesFromEntryDetails,
  getOptimisticResponseAndVariablesForUpdateEntry,
  hasScrollIdInHierarchy,
} from "../Utils/Utils";
import Quiz from "./Quiz/Quiz";
import Feedback from "./Feedback/Feedback";
import useUserSettingsModal from "../UI/Modal/UserSettingsModal/UserSettingsModal";
import useCancelQuizModal from "../UI/Modal/CancelQuizModal/CancelQuizModal";
import useInviteFriendsModal from "../UI/Modal/InviteFriendsModal/InviteFriendsModal";
import useDeleteEntryModal from "../UI/Modal/DeleteEntryModal/DeleteEntryModal";
import useUserProfileModal from "../UI/Modal/UserProfileModal/UserProfileModal";
import useReactNativeEvents from "../Utils/ReactNativeEventListener";
import LevelProgress from "./LevelProgress/LevelProgress";
import { setImageUpdatingForEntity } from "../Utils/ApolloUtils";
import useHeapAnalytics from "../Utils/HeapAnalytics";
import { themeSetting } from "../../config/apollo-cache";
import Layout from "./Layout/Layout";
import useWindowSize from "../Utils/UseWindowSize";
import useDarkMode from "../Utils/UseDarkMode";
import WelcomeTutorial from "../UI/Modal/TutorialModal/WelcomeTutorial/WelcomeTutorial";
import NewQuest from "./NewQuest/NewQuest";
import QuestProgress from "./QuestProgress/QuestProgress";
import usePushSubscription from "../Utils/UsePushSubscription";
import useDailyQuoteDisplay from "../Utils/UseDailyQuoteDisplay";
import ScrollDownButton from "./ScrollDownButton/ScrollDownButton";

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    listen: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  darkMode: PropTypes.bool,
};

const defaultProps = {
  darkMode: false,
};

const WisdomTree = ({ history, match, location }) => {
  const isMobileLarge = useMobileLarge();
  const darkMode = useDarkMode();
  useHeapAnalytics();
  usePushSubscription();
  const {
    dailyQuoteContent,
    finished: finishedDailyQuote,
  } = useDailyQuoteDisplay({
    onDialogOpenedChanged: (opened) => {
      setDialogOpened(opened);
    },
  });

  const { windowWidth, windowHeight } = useWindowSize();

  const [selectedEntry, setSelectedEntry] = useState(undefined);
  const selectedEntryRef = useRef(selectedEntry);

  const newMode = queryString.parse(location.search).new === "true";

  const quizMode = location.pathname === "/quiz";
  const [quizModeState, setQuizModeState] = useState(quizMode);
  const prevQuizModeRef = useRef(false);

  const quizRootEntryId = quizMode
    ? queryString.parse(location.search).rootId
    : undefined;

  const [newEntryDraft, setNewEntryDraft] = useState({});
  const [dialogOpened, setDialogOpened] = useState(false);
  const [feedbackOpened, setFeedbackOpened] = useState(false);

  const editMode = true;

  const [
    loadUser,
    { loading: getUserLoading, data: getUserData },
  ] = useLazyQuery(GET_CURRENT_USER, {
    onCompleted: (data) => {
      window.heap.identify(data.me.email);
      i18n.changeLanguage(data.me.language);

      localStorage.setItem("themeSetting", data.me.theme);
      themeSetting(data.me.theme);
    },
  });
  const user = getUserData ? getUserData.me : null;

  const [
    loadEntries,
    { loading: getEntriesLoading, data: getEntriesData },
  ] = useLazyQuery(GET_ENTRIES);
  const getEntriesLoadingConsideringCacheIssue =
    getEntriesLoading || getEntriesData == undefined;
  const entries = getEntriesData ? getEntriesData.entries : [];

  useEffect(() => {
    if (finishedDailyQuote) {
      loadUser();
      loadEntries();
    }
  }, [finishedDailyQuote]);

  const [
    loadEntryDetails,
    { loading: getEntryDetailsLoading, data: getEntryDetailsData },
  ] = useLazyQuery(GET_ENTRY_DETAILS);
  let selectedEntryDetails = null;
  if (
    selectedEntry != null &&
    getEntryDetailsData &&
    getEntryDetailsData.entry.id === selectedEntry.id
  ) {
    selectedEntryDetails = getEntryDetailsData.entry;
  }

  const graphReferences = getGraphReferencesFromEntryDetails(
    selectedEntryDetails
  );

  const [createEntry, { loading: createEntryLoading }] = useMutation(
    CREATE_ENTRY,
    {
      update: mergeEntriesAfterCreate,
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: (data) => {
        history.push(`/entry/${data.createEntry.createdEntry.id}`);
      },
    }
  );

  const [updateEntry] = useMutation(UPDATE_ENTRY, {
    update: mergeEntriesAfterUpdate,
    onCompleted: () => {},
  });

  const [updateEntryPosition] = useMutation(UPDATE_ENTRY_POSITION, {
    update: mergeEntriesAfterUpdate,
    onCompleted: () => {},
  });

  const [updateEntryPositionAndParent] = useMutation(
    UPDATE_ENTRY_POSITION_AND_PARENT,
    {
      update: mergeEntriesAfterUpdate,
      onCompleted: () => {},
    }
  );

  const [deleteEntry, { loading: deleteEntryLoading }] = useMutation(
    DELETE_ENTRY,
    {
      update: (cache, mutationResult) => {
        const { newEntrySelectionId } = mutationResult.data.deleteEntry;

        if (newEntrySelectionId) {
          history.push(`/entry/${newEntrySelectionId}`);
        } else {
          history.push("/home");
        }

        mergeEntriesAfterDelete(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
        closeDeleteEntryModal();
      },
      onCompleted: (data) => {
        closeDeleteEntryModal();

        // this is needed to solve camera issue after delete
        const { newEntrySelectionId } = data.deleteEntry;
        clickOnEntryInGraph(newEntrySelectionId, 0);
      },
    }
  );

  const [updateBlockEntry] = useMutation(UPDATE_BLOCK, {
    onError: (err) => {
      Logger.error(err);
    },
    onCompleted: () => {},
  });

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`, {
        scope: "/",
      });
    }
  }, []);

  useEffect(() => {
    if (quizMode) {
      if (selectedEntryRef.current === undefined) {
        setSelectedEntry(null);
      }
      return;
    }

    if (match.params.entryId) {
      if (!getEntriesLoading) {
        const entryId = String(match.params.entryId);
        const entry = entries.find((e) => {
          return e.id === entryId;
        });

        if (entry) {
          setSelectedEntry(entry);
        } else {
          Logger.error(`entry with ID ${entryId} not found`);
          setSelectedEntry(null);
        }
      }
    } else {
      setSelectedEntry(null);
    }
  }, [match.params.entryId, quizMode, entries]);

  useEffect(() => {
    selectedEntryRef.current = selectedEntry;
    if (selectedEntry != null) {
      loadEntryDetails({
        variables: {
          id: selectedEntry.id,
        },
      });
    }
  }, [selectedEntry]);

  const setQuizModeAndRef = (quizMode) => {
    setQuizModeState(quizMode);
    prevQuizModeRef.current = quizMode;
  };

  useEffect(() => {
    const quizLocation = location.pathname === "/quiz";
    const wasQuizBefore = prevQuizModeRef.current;
    setQuizModeAndRef(quizLocation);

    if (quizLocation) return;

    if (wasQuizBefore && !quizLocation) {
      // Quiz ended
      setTimeout(() => {
        clickOnEntryInGraph(
          selectedEntryRef.current ? selectedEntryRef.current.id : null,
          0
        );
      }, 600);
    }
  }, [location]);

  useEffect(() => {
    if (!newMode) {
      setNewEntryDraft({});
    }
  }, [newMode]);

  const performScrollForMobile = () => {
    if (hasScrollIdInHierarchy(document.activeElement, "newEntry")) {
      const graphElement = document.getElementById("graphContainer");
      const graphHeight = graphElement.clientHeight;
      const scrollY = graphHeight * 0.3;

      scroll.scrollTo(scrollY, {
        smooth: true,
        duration: 80,
        ignoreCancelEvents: true,
      });
      setTimeout(() => {
        scroll.scrollTo(scrollY, {
          smooth: true,
          duration: 600,
          ignoreCancelEvents: true,
        });
      }, 80);
    }
  };

  useReactNativeEvents({
    handleEvent: (event) => {
      if (event.type === "keyboardOpened") {
        performScrollForMobile();
      }
    },
  });

  const onSelectEntry = (entry) => {
    if (entry == null) {
      history.push(`/home`);
      return;
    }
    history.push(`/entry/${entry.id}`);
  };

  const onAttemptCreateEntry = () => {
    if (!newEntryDraft.name) return;
    createEntry({
      variables: {
        input: {
          name: newEntryDraft.name,
          parentEntryId: selectedEntry ? selectedEntry.id : null,
          x: newEntryDraft.x,
          y: newEntryDraft.y,
          categoryId: newEntryDraft.categoryId,
        },
      },
    });
  };

  const onUpdateNewEntryDraft = (updatedDraft) => {
    setNewEntryDraft((prevState) => {
      return {
        ...prevState,
        ...updatedDraft,
      };
    });
  };

  const onAttemptDeleteEntry = (entry, withChildren) => {
    deleteEntry({
      variables: { id: entry.id, input: { deleteChildren: withChildren } },
    });
  };

  const onAttemptUpdateEntry = (entryUpdates, debounced) => {
    const {
      variables,
      optimisticResponse,
    } = getOptimisticResponseAndVariablesForUpdateEntry(entries, entryUpdates);

    // choose the right mutation to improve performance
    let optimalUpdateCall = updateEntry;
    if (
      Object.keys(entryUpdates).length === 3 &&
      entryUpdates.hasOwnProperty("x") &&
      entryUpdates.hasOwnProperty("y")
    ) {
      optimalUpdateCall = updateEntryPosition;
    } else if (
      Object.keys(entryUpdates).length === 4 &&
      entryUpdates.hasOwnProperty("x") &&
      entryUpdates.hasOwnProperty("y") &&
      entryUpdates.hasOwnProperty("parentEntryId")
    ) {
      optimalUpdateCall = updateEntryPositionAndParent;
    }

    if (debounced) {
      optimalUpdateCall({
        variables,
        context: {
          debounceKey: `${variables.id}_${hash.keys(variables.input)}`,
        },
        optimisticResponse,
      });
    } else {
      optimalUpdateCall({ variables, optimisticResponse });
    }
  };

  const onAttemptUpdateEntryImage = (entry, imageInput) => {
    let optimisticResponse = null;
    if (imageInput == null) {
      optimisticResponse = {
        __typename: "Mutation",
        updateEntry: {
          __typename: "UpdateEntryResponse",
          code: 200,
          success: true,
          message: "entries updated",
          updatedEntries: [{ ...entry, image: null }],
          createdTags: [],
          updatedUser: null,
          expEvent: null,
        },
      };
    } else {
      setImageUpdatingForEntity({
        typename: "Entry",
        id: entry.id,
        isUpdating: true,
      });
    }

    updateEntry({
      variables: {
        id: entry.id,
        input: {
          imageInput: imageInput ? { ...imageInput } : null,
        },
      },
      optimisticResponse,
      update: (cache, mutationResult) => {
        mergeEntriesAfterUpdate(cache, mutationResult);
        setImageUpdatingForEntity({
          typename: "Entry",
          id: entry.id,
          isUpdating: false,
        });
      },
    }).catch((error) => {
      Logger.error(error);
      setImageUpdatingForEntity({
        typename: "Entry",
        id: entry.id,
        isUpdating: false,
      });
    });
  };

  const onCancelQuizHandler = (entryId) => {
    const cancelTo = entryId != null ? `/entry/${entryId}` : "/home";
    history.push(cancelTo);
    clickOnEntryInGraph(entryId, 600);
  };

  const {
    open: openDeleteEntryModal,
    close: closeDeleteEntryModal,
    content: deleteEntryModalContent,
  } = useDeleteEntryModal({
    onOpen: () => setDialogOpened(true),
    onClose: () => setDialogOpened(false),
    onDeleteEntry: onAttemptDeleteEntry,
    loading: deleteEntryLoading,
  });

  const {
    open: openCancelQuizModal,
    close: closeCancelQuizModal,
    content: cancelQuizModalContent,
  } = useCancelQuizModal({
    onOpen: () => setDialogOpened(true),
    onClose: () => setDialogOpened(false),
    onConfirm: (entryId) => {
      onCancelQuizHandler(entryId);
      closeCancelQuizModal();
    },
  });

  const {
    open: openInviteFriendsModal,
    content: inviteFriendsModalContent,
  } = useInviteFriendsModal({
    onOpen: () => setDialogOpened(true),
    onClose: () => setDialogOpened(false),
  });

  const {
    open: openUserSettingsModal,
    content: userSettingsModalContent,
  } = useUserSettingsModal({
    onOpen: () => setDialogOpened(true),
    onClose: () => setDialogOpened(false),
  });

  const {
    open: openUserProfileModal,
    content: userProfileModalContent,
  } = useUserProfileModal({
    onOpen: () => setDialogOpened(true),
    onClose: () => setDialogOpened(false),
  });

  const loading =
    getEntriesLoadingConsideringCacheIssue ||
    getUserLoading ||
    user == null ||
    selectedEntry === undefined;

  const header = (
    <Header
      editMode={editMode}
      darkMode={darkMode}
      onInviteFriendsClicked={() => openInviteFriendsModal()}
      onUserSettingsClicked={() => openUserSettingsModal()}
      isAdmin={user ? user.admin : false}
      hasSettings={user != null}
      hasInviteFriends={user != null}
    />
  );

  let graph;
  let scrollDownButton;
  let quiz;
  let entryBrowser;

  if (!loading) {
    graph = (
      <Graph
        key={`graph_${darkMode ? "dark" : "light"}`}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        isTabletOrMobile={isMobileDevice}
        darkMode={darkMode}
        user={{
          image: user && user.image ? user.image.url : null,
          level: user.level,
          size: user.size,
        }}
        entries={entries}
        references={graphReferences}
        selectedEntryId={selectedEntry ? selectedEntry.id : null}
        editMode={editMode}
        newMode={newMode}
        quizMode={quizMode}
        newEntryDraft={newEntryDraft}
        onSelectEntry={(entry) => onSelectEntry(entry)}
        onNewEntry={(entryId) => {
          const newButtonTo = entryId
            ? `/entry/${entryId}?new=true`
            : `/home?new=true`;

          history.push(newButtonTo);
        }}
        onAttemptSelectEntry={(entry) => {
          if (quizMode) {
            openCancelQuizModal(entry ? entry.id : null);
          }
        }}
        onUpdateEntry={(entryUpdates) =>
          onAttemptUpdateEntry(entryUpdates, true)
        }
        onUpdateNewEntryDraft={(updatedDraft) =>
          onUpdateNewEntryDraft(updatedDraft)
        }
        onUpdateBlockEntry={(blockId, entryId, newEntryId) => {
          if (entryId === newEntryId) return;

          const debounceKey = `${blockId}${entryId}`;
          updateBlockEntry({
            variables: {
              id: blockId,
              input: {
                entryId: newEntryId,
              },
            },
            context: {
              debounceKey,
            },
            optimisticResponse: getOptimisticResponseForMoveBlock(
              blockId,
              newEntryId
            ),
            update: (cache, mutationResult) => {
              mergeBlocksAfterUpdate(entryId, cache, mutationResult);
            },
          });
        }}
      />
    );

    scrollDownButton = (
      <ScrollDownButton
        onScrollDownClicked={() => {
          scroll.scrollTo(windowHeight * 0.66 - 5, {
            smooth: true,
            duration: 400,
            ignoreCancelEvents: false,
          });
        }}
        quizMode={quizModeState}
        newMode={newMode}
      />
    );

    quiz = (
      <Quiz
        user={user}
        rootEntryId={quizRootEntryId}
        selectedEntryId={selectedEntry ? selectedEntry.id : null}
        onSelectEntry={(entry) => {
          setSelectedEntry(entry);
        }}
        onShowResult={() => {
          const entry = entries.find((e) => {
            return e.id === quizRootEntryId;
          });
          setSelectedEntry(entry || null);
        }}
        onAttemptCancelQuiz={(entryId) => openCancelQuizModal(entryId)}
        dialogOpened={dialogOpened || feedbackOpened}
      />
    );

    entryBrowser = (
      <EntryBrowser
        user={user}
        entries={entries}
        selectedEntry={selectedEntry}
        selectedEntryDetails={selectedEntryDetails}
        selectedEntryDetailsLoading={getEntryDetailsLoading}
        editMode={editMode}
        newMode={newMode}
        newEntryLoading={createEntryLoading}
        deleteEntryLoading={deleteEntryLoading}
        dialogOpened={dialogOpened || feedbackOpened}
        onDialogOpenedChanged={(opened) => {
          setDialogOpened(opened);
        }}
        graphContainerHeight={windowHeight * 0.66}
        onUpdateEntry={(entryUpdates) =>
          onAttemptUpdateEntry(entryUpdates, true)
        }
        newEntryDraft={newEntryDraft}
        onUpdateNewEntryDraft={(updatedDraft) =>
          onUpdateNewEntryDraft(updatedDraft)
        }
        onCreateEntry={() => onAttemptCreateEntry()}
        onDeleteEntry={(entry) => openDeleteEntryModal(entry)}
        onUpdateEntryImage={(entry, imageInput) =>
          onAttemptUpdateEntryImage(entry, imageInput)
        }
      />
    );
  }

  let guidedTour = null;
  let feedbackContent = null;
  let howItWorksContent = null;
  let newQuestContent = null;
  let questProgressContent = null;
  let welcomeContent = null;

  if (user) {
    // guidedTour = <GuidedTour user={user} location={location} />;
    feedbackContent = (
      <Feedback
        onFeedbackOpenedChanged={(opened) => setFeedbackOpened(opened)}
        hideButton={
          !user.welcomeConfirmed ||
          (isMobileLarge && quizMode) ||
          (isMobileLarge && dialogOpened)
        }
      />
    );

    if (!user.welcomeConfirmed || !user.jobToBeDone) {
      welcomeContent = (
        <WelcomeTutorial user={user} setDialogOpened={setDialogOpened} />
      );
    }

    if (user.nextQuest != null && !quizMode) {
      newQuestContent = (
        <NewQuest
          user={user}
          onDialogOpenedChanged={(opened) => {
            setDialogOpened(opened);
          }}
        />
      );
    }

    if (user.currentQuest != null && !quizMode) {
      questProgressContent = (
        <QuestProgress
          quest={user.currentQuest}
          user={user}
          onDialogOpenedChanged={(opened) => {
            setDialogOpened(opened);
          }}
        />
      );
    }
  }

  const levelProgressContent = (
    <LevelProgress
      dialogOpened={dialogOpened}
      onClickExpEvent={() => openUserProfileModal()}
    />
  );

  return (
    <Layout
      header={header}
      graph={graph}
      scrollDownButton={scrollDownButton}
      entryBrowser={entryBrowser}
      quiz={quiz}
      loading={loading}
      quizMode={quizMode}
      newMode={newMode}
      info={newQuestContent}
    >
      {guidedTour}
      {feedbackContent}
      {levelProgressContent}
      {deleteEntryModalContent}
      {cancelQuizModalContent}
      {welcomeContent}
      {howItWorksContent}
      {questProgressContent}
      {inviteFriendsModalContent}
      {userSettingsModalContent}
      {userProfileModalContent}
      {dailyQuoteContent}
    </Layout>
  );
};

WisdomTree.propTypes = propTypes;
WisdomTree.defaultProps = defaultProps;

export default WisdomTree;
