import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

import styles from "./UnreadMessage.module.scss";

const propTypesUnreadMessage = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  timestamp: PropTypes.string.isRequired
};
const defaultPropsUnreadMessage = {};

const UnreadMessage = ({ user, timestamp }) => {
  const [timeString, setTimeString] = useState(
    moment(timestamp, "x").fromNow()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeString(moment(timestamp, "x").fromNow());
    }, 4000);

    return () => clearInterval(interval);
  });

  return (
    <Link className={`box ${styles.container}`} to={`/admin/user/${user.id}`}>
      <span className={`icon ${styles.image}`}>
        <i className="fas fa-2x fa-envelope" />
      </span>

      <div className={styles.info}>
        <p className={styles.userName}>Message from {user.name}</p>
        <p className={styles.timestamp}>{timeString}</p>
      </div>
    </Link>
  );
};

UnreadMessage.propTypes = propTypesUnreadMessage;
UnreadMessage.defaultProps = defaultPropsUnreadMessage;

export default UnreadMessage;
