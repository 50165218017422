import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Logger from "js-logger";

import styles from "./References.module.scss";
import ReferenceLink from "./ReferenceLink/ReferenceLink";
import { useMutation } from "@apollo/client";
import { DELETE_REFERENCE, mergeAfterDelete } from "../../GraphQl/references";
import ReferenceWeb from "./ReferenceWeb/ReferenceWeb";
import ReferenceText from "./ReferenceText/ReferenceText";
import useEditReferenceModal from "../Modal/EditReferenceModal/EditReferenceModal";

const propTypesReferences = {
  povType: PropTypes.string.isRequired,
  povId: PropTypes.string.isRequired,
  editMode: PropTypes.bool,
  sharingId: PropTypes.string,
  references: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      source: PropTypes.shape({
        entry: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          image: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        block: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          image: PropTypes.shape({
            url: PropTypes.string,
          }),
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            image: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }),
      }),
      target: PropTypes.shape({
        entry: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          image: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        block: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          image: PropTypes.shape({
            url: PropTypes.string,
          }),
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            image: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }),
      }),
    })
  ).isRequired,
  onDialogOpenedChanged: PropTypes.func.isRequired,
};
const defaultPropsReferences = {
  editMode: true,
  sharingId: null,
};

const References = ({
  references,
  editMode,
  sharingId,
  povType,
  povId,
  onDialogOpenedChanged,
}) => {
  const history = useHistory();
  const [referenceIdsDeleteLoading] = useState([]);

  const [deleteReference, { loading: deleteReferenceLoading }] = useMutation(
    DELETE_REFERENCE,
    {
      update: (cache, mutationResult) => {
        mergeAfterDelete(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
    }
  );

  const {
    open: openEditReferenceModal,
    content: editReferenceModalContent,
  } = useEditReferenceModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => {
      onDialogOpenedChanged(false);
    },
  });

  const sortedReferences = references.sort((a, b) => {
    return a.createdAt - b.createdAt;
  });

  let referencesContent = sortedReferences.map((ref) => {
    let referenceContent = null;

    const deleteLoading =
      referenceIdsDeleteLoading.find((id) => id === ref.id) != null;
    const onRemove = () => {
      referenceIdsDeleteLoading.push(ref.id);
      deleteReference({
        variables: {
          id: ref.id,
        },
      });
    };

    if (ref.type === "LINK_INTERNAL") {
      referenceContent = (
        <ReferenceLink
          key={ref.id}
          reference={ref}
          povId={povId}
          povType={povType}
          deleteLoading={deleteLoading}
          editMode={editMode}
          onGoTo={(referencedType, referencedEntryId, referencedBlockId) => {
            let targetUrl;

            if (referencedType === "entry") {
              targetUrl = `/entry/${referencedEntryId}`;
              if (sharingId) targetUrl = `/shared/${sharingId}${targetUrl}`;
            } else if (referencedType === "block") {
              targetUrl = `/entry/${referencedEntryId}?block=${referencedBlockId}`;
              if (sharingId) targetUrl = `/shared/${sharingId}${targetUrl}`;
            }

            history.push(targetUrl);
          }}
          onEdit={() => {
            openEditReferenceModal(ref, povType, povId);
          }}
          onRemove={onRemove}
        />
      );
    } else if (ref.type === "LINK_WEB") {
      referenceContent = (
        <ReferenceWeb
          key={ref.id}
          reference={ref}
          povId={povId}
          povType={povType}
          deleteLoading={deleteLoading}
          editMode={editMode}
          onGoTo={(url) => {
            const win = window.open(url, "_blank");
            win.focus();
          }}
          onEdit={() => {
            openEditReferenceModal(ref);
          }}
          onRemove={onRemove}
        />
      );
    } else if (ref.type === "TEXT") {
      referenceContent = (
        <ReferenceText
          key={ref.id}
          reference={ref}
          povId={povId}
          povType={povType}
          deleteLoading={deleteLoading}
          editMode={editMode}
          onEdit={() => {
            openEditReferenceModal(ref);
          }}
          onRemove={onRemove}
        />
      );
    }

    return (
      <div className={styles.reference} key={ref.id}>
        {referenceContent}
      </div>
    );
  });
  return (
    <>
      <div className={`${styles.container} tags`}>{referencesContent}</div>
      {editReferenceModalContent}
    </>
  );
};

References.propTypes = propTypesReferences;
References.defaultProps = defaultPropsReferences;

export default References;
