import React from "react";
import PropTypes from "prop-types";

import styles from "./EntryGroup.module.scss";

const propTypesEntryGroup = {
  children: PropTypes.node,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
};
const defaultPropsEntryGroup = {
  children: null,
  icon: null,
};

const EntryGroup = ({ children, icon, title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {icon ? (
          <span className={`icon ${styles.icon}`}>
            <i className={icon} />
          </span>
        ) : null}
        <p className={styles.titleText}>{title}</p>
      </div>
      {children}
    </div>
  );
};

EntryGroup.propTypes = propTypesEntryGroup;
EntryGroup.defaultProps = defaultPropsEntryGroup;

export default EntryGroup;
