import * as d3 from "d3";

class EntryHoverEffect {
  constructor(
    graphConfig,
    isEntryIdGrayedOut,
    isEntryIdVisible,
    isRecentlyUpdated
  ) {
    this.graphConfig = graphConfig;
    this.isEntryIdGrayedOut = isEntryIdGrayedOut;
    this.isEntryIdVisible = isEntryIdVisible;
    this.isRecentlyUpdated = isRecentlyUpdated;
    this.hoveredOverEntryIds = new Set();
  }

  onMouseEnterEntryId(rootElement, entryId) {
    if (!this.hoveredOverEntryIds.has(entryId)) {
      this.hoveredOverEntryIds.add(entryId);
      this.updateSelectionEffect(rootElement);
    }
  }

  onMouseLeaveEntryId(rootElement, entryId) {
    if (this.hoveredOverEntryIds.has(entryId)) {
      this.hoveredOverEntryIds.delete(entryId);
      this.updateSelectionEffect(rootElement);
    }
  }

  updateSelectionEffect(rootElement) {
    if (this.lastHoveredOverEntryIds === this.hoveredOverEntryIds) {
      return;
    }

    const highlightedNodes = new Set();

    if (this.hoveredOverEntryIds.size > 0) {
      this.hoveredOverEntryIds.forEach(entryId => {
        highlightedNodes.add(parseInt(entryId));
      });
    }

    d3.select(rootElement)
      .selectAll(".node")
      .select(".singleNode")
      .transition()
      .duration(d => {
        if (this.isRecentlyUpdated()) return 0;
        return highlightedNodes.has(d.data.id)
          ? this.graphConfig.durationFadeInNode
          : this.graphConfig.durationFadeOutNode;
      })
      .attr("opacity", d => {
        if (!d || this.isEntryIdVisible(d.data.id))
          return this.graphConfig.nodeOpacity;
        return highlightedNodes.has(d.data.id)
          ? this.graphConfig.opacityHoverOverNode
          : this.graphConfig.opacityFadedOutNode;
      });

    d3.select(rootElement)
      .selectAll(".node")
      .select(".singleNode")
      .select("image")
      .transition()
      .delay(d => {
        if (this.isRecentlyUpdated()) return 0;
        return highlightedNodes.has(d.data.id)
          ? 0
          : this.graphConfig.durationFadeOutNode;
      })
      .attr("filter", d => {
        if (!d || this.isEntryIdVisible(d.data.id)) return "";
        return highlightedNodes.has(d.data.id) ? "" : "url(#grayScale)";
      });

    d3.select(rootElement)
      .selectAll(".edge")
      .transition()
      .duration(d => {
        if (!d) return 0;
        if (this.isRecentlyUpdated()) return 0;
        return highlightedNodes.has(d.source.data.id) &&
          highlightedNodes.has(d.target.data.id)
          ? this.graphConfig.durationFadeInEdge
          : this.graphConfig.durationFadeOutEdge;
      })
      .attr("opacity", d => {
        if (
          !d ||
          (this.isEntryIdVisible(d.source.data.id) &&
            this.isEntryIdVisible(d.target.data.id))
        )
          return this.graphConfig.edgeOpacity;
        const connectedToHighlightedNode = highlightedNodes.has(
          d.target.data.id
        );
        return connectedToHighlightedNode
          ? this.graphConfig.opacityHoverOverEdge
          : this.graphConfig.opacityFadedOutEdge;
      });

    this.lastHoveredOverEntryIds = new Set([...this.hoveredOverEntryIds]);
  }

  resetSelectionEffect() {
    this.hoveredOverEntryIds = new Set();
  }
}

export default EntryHoverEffect;
