/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from "react";

import queryString from "query-string";
import { Link, useHistory, useLocation } from "react-router-dom";
import { gql } from "@apollo/client";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useTranslation, Trans } from "react-i18next";
import validator from "email-validator";
import { toast } from "react-toastify";
import bcp47 from "bcp-47";
import Logger from "js-logger";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";

import Input from "../../UI/Input/Input";
import ErrorMessages from "../../Utils/ErrorMessages";
import styles from "./Register.module.scss";
import authStyles from "../Auth.module.scss";
import { GET_INVITED_BY } from "../../GraphQl/user";
import SeparatorWithText from "../../UI/SeparatorWithText/SeparatorWithText";
import GoogleLoginWrapper from "../GoogleLoginWrapper/GoogleLoginWrapper";
import { isLoggedInVar } from "../../../config/apollo-cache";

const propTypes = {};

const defaultProps = {};

const REGISTER_USER = gql`
  mutation(
    $email: String!
    $name: String!
    $password: String!
    $inviteCode: String
    $language: String
    $captchaToken: String
    $timeZoneOffset: Int
  ) {
    registerUser(
      input: {
        email: $email
        name: $name
        password: $password
        inviteCode: $inviteCode
        language: $language
        captchaToken: $captchaToken
        timeZoneOffset: $timeZoneOffset
      }
    ) {
      code
      message
      token
    }
  }
`;

const Register = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [registerUser, { loading: registerUserLoading }] = useMutation(
    REGISTER_USER,
    {
      onError: (error) => {
        Logger.error(error);
        const errorCode = ErrorMessages.getErrorCode(error);
        if (errorCode === "EMAIL_ALREADY_EXISTS") {
          setEmailError(errorCode);
          return;
        }
        if (errorCode === "INVALID_INVITE_CODE") {
          setInviteCodeError(errorCode);
          return;
        }
        setUnspecificError(errorCode);
      },
      onCompleted: (data) => {
        history.push("/auth/login");
        toast.dismiss();
        localStorage.setItem("token", data.registerUser.token);
        isLoggedInVar(true);
      },
    }
  );

  const [
    getInvitedByUsername,
    { data: getInvitedByUsernameData },
  ] = useLazyQuery(GET_INVITED_BY);

  const [fromInviteLink] = useState(queryString.parse(location.search).invite);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [inviteCode, setInviteCode] = useState(
    queryString.parse(location.search).invite
  );

  const passwordInputRef = useRef(null);
  const captchaRef = useRef(null);

  const [usernameError, setUsernameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [inviteCodeError, setInviteCodeError] = useState(null);
  const [unspecificError, setUnspecificError] = useState(null);

  useEffect(() => {
    if (fromInviteLink) {
      getInvitedByUsername({
        variables: {
          inviteCode: fromInviteLink,
        },
      });
    }
  }, [fromInviteLink]);

  const validateUsername = (usernameToValidate) => {
    if (!usernameToValidate || usernameToValidate.length === 0) {
      setUsernameError("REGISTRATION_NAME_MISSING");
      return false;
    }

    if (usernameToValidate.length < 3) {
      setUsernameError("REGISTRATION_NAME_TOO_SHORT");
      return false;
    }
    return true;
  };

  const validateEmail = (emailToValidate) => {
    if (!emailToValidate || emailToValidate.length === 0) {
      setEmailError("REGISTRATION_EMAIL_MISSING");
      return false;
    }

    if (!validator.validate(emailToValidate)) {
      setEmailError("REGISTRATION_EMAIL_INVALID");
      return false;
    }
    return true;
  };

  const validatePassword = (passwordToValidate) => {
    if (!passwordToValidate || passwordToValidate.length === 0) {
      setPasswordError("REGISTRATION_PASSWORD_MISSING");
      return false;
    }

    if (passwordToValidate.length < 8) {
      setPasswordError("REGISTRATION_PASSWORD_TOO_SHORT");
      return false;
    }
    return true;
  };

  const validateInviteCode = (inviteCodeToValidate) => {
    if (!inviteCodeToValidate || inviteCodeToValidate.length === 0) {
      setInviteCodeError("REGISTRATION_INVITE_CODE_MISSING");
      return false;
    }

    return true;
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setUsernameError(null);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(null);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(null);
  };

  const handleInviteCodeChange = (event) => {
    setInviteCode(event.target.value);
    setInviteCodeError(null);
  };

  const clearAllErrors = () => {
    setUsernameError(null);
    setEmailError(null);
    setPasswordError(null);
    setUnspecificError(null);
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const usernameValid = validateUsername(username);
    const emailValid = validateEmail(email);
    const passwordValid = validatePassword(password);

    if (usernameValid && emailValid && passwordValid) {
      const { language } = bcp47.parse(
        navigator.language || navigator.userLanguage
      );

      executeRecaptcha("register")
        .then((token) => {
          const timeZoneOffset = -new Date().getTimezoneOffset() / 60;
          registerUser({
            variables: {
              name: username,
              email,
              password,
              inviteCode: inviteCode ? inviteCode : null,
              language,
              captchaToken: token,
              timeZoneOffset,
            },
          });
          clearAllErrors();
        })
        .catch((error) => {
          Logger.error(error);
          toast.error(t(`error.CAPTCHA_FAILED`));
        });
    }
  };

  let errorContent = null;
  if (unspecificError) {
    const errorMessage = t(`error.${unspecificError}`);
    errorContent = <p className={styles.Error}>{errorMessage}</p>;
  }

  const registerForm = (
    <>
      <Helmet>
        <title>WisdomTree - {t(`auth.signup_title`)}</title>
      </Helmet>
      <section className={styles.registerContainer}>
        <h1 className={authStyles.authTitle}>{t("auth.signup_title_long")}</h1>
        {fromInviteLink ? (
          <p
            className={styles.invitedByText}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: getInvitedByUsernameData
                ? t("auth.signup_invited_by_user", {
                    username: getInvitedByUsernameData.invitedBy,
                    interpolation: { escapeValue: false },
                  })
                : t("auth.signup_invited_by_unknown"),
            }}
          />
        ) : null}

        <form onSubmit={handleRegister}>
          <Input
            type="text"
            name="name"
            placeholder={t("auth.signup_username_input")}
            autoComplete="name"
            spellCheck="false"
            iconleft="fas fa-user"
            help={usernameError ? t(`error.${usernameError}`) : null}
            isDanger={usernameError !== null}
            value={username}
            onChange={handleUsernameChange}
          />
          <Input
            type="text"
            name="email"
            placeholder={t("auth.signup_email_input")}
            autoComplete="email"
            spellCheck="false"
            iconleft="fas fa-envelope"
            help={emailError ? t(`error.${emailError}`) : null}
            isDanger={emailError !== null}
            value={email}
            onChange={handleEmailChange}
          />

          <Input
            ref={passwordInputRef}
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder={t("auth.signup_password_input")}
            autoComplete="new-password"
            spellCheck="false"
            iconleft="fas fa-lock"
            iconright={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
            onclickiconright={() => {
              passwordInputRef.current.focus();
              setShowPassword(!showPassword);
            }}
            help={passwordError ? t(`error.${passwordError}`) : null}
            isDanger={passwordError !== null}
            value={password}
            onChange={handlePasswordChange}
          />

          <p className={styles.notice}>
            <Trans i18nKey="auth.signup_notice">
              By signing up, you confirm that you've read and accepted our
              <a href={process.env.REACT_APP_LINK_PRIVACY}>Privacy Policy</a>.
            </Trans>
          </p>

          <div className="field">
            <div className="control text-align-center">
              <button
                className={`button is-primary ${styles.registerButton} ${
                  registerUserLoading ? "is-loading" : ""
                }`}
                type="submit"
                disabled={usernameError || emailError || passwordError}
              >
                {t("auth.signup_button")}
              </button>
            </div>
          </div>

          {errorContent}
        </form>

        <div className={styles.separatorContainer}>
          <SeparatorWithText text={t("auth.signup_social_separator")} />
        </div>
        <GoogleLoginWrapper text={t("auth.signup_social_google")} />
      </section>

      <section className={styles.loginContainer}>
        <hr />
        <p className={styles.moreOptions}>
          <Trans i18nKey="auth.signup_already_have_account">
            Already signed up?
            <Link to={`/auth/login${location.search}`}>Log in</Link>
          </Trans>
        </p>
      </section>
    </>
  );

  return <div>{registerForm}</div>;
};

Register.propTypes = propTypes;
Register.defaultProps = defaultProps;

export default Register;
