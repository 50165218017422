import React, { useState } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";

import styles from "./EditLink.module.scss";
import { useTranslation } from "react-i18next";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import { useMutation } from "@apollo/client";
import {
  UPDATE_REFERENCE,
  mergeAfterUpdate,
} from "../../../../GraphQl/references";
import { useHotkey } from "../../../../Utils/Hotkeys";
import EditReferenceText from "../../../References/EditReferenceText/EditReferenceText";
import EditReferenceLink from "../../../References/EditReferenceLink/EditReferenceLink";

const propTypesAddInternalLink = {
  onCloseModal: PropTypes.func.isRequired,
  reference: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    descriptionTo: PropTypes.string,
  }).isRequired,
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
};
const defaultPropsAddInternalLink = {};

const EditLink = ({ onCloseModal, reference, entityType, entityId }) => {
  const { t } = useTranslation();

  const [selection, setSelection] = useState(null);
  const [showDescription, setShowDescription] = useState(
    reference.descriptionTo != null || reference.descriptionFrom != null
  );
  const [descriptionToText, setDescriptionToText] = useState(
    reference.descriptionTo ? reference.descriptionTo : ""
  );
  const [descriptionFromText, setDescriptionFromText] = useState(
    reference.descriptionFrom ? reference.descriptionFrom : ""
  );

  const onChangeDescriptionToTextHandler = (event) => {
    const description = event.target.value;
    setDescriptionToText(description);
  };

  const onChangeDescriptionFromTextHandler = (event) => {
    const description = event.target.value;
    setDescriptionFromText(description);
  };

  const [updateReference, { loading: updateReferenceLoading }] = useMutation(
    UPDATE_REFERENCE,
    {
      update: (cache, mutationResult) => {
        mergeAfterUpdate(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        onCloseModal();
      },
    }
  );

  const inversePerspective =
    (reference.source.entry &&
      (entityType !== "entry" || entityId !== reference.source.entry.id)) ||
    (reference.source.block &&
      (entityType !== "block" || entityId !== reference.source.block.id));

  const descriptionToChanged =
    !(reference.descriptionTo == null && descriptionToText.length === 0) &&
    descriptionToText !== reference.descriptionTo;

  const descriptionFromChanged =
    !(reference.descriptionFrom == null && descriptionFromText.length === 0) &&
    descriptionFromText !== reference.descriptionFrom;

  const canUpdate =
    !updateReferenceLoading && (descriptionToChanged || descriptionFromChanged);

  const onSubmit = () => {
    updateReference({
      variables: {
        id: reference.id,
        input: {
          descriptionFrom:
            descriptionFromText.length > 0 ? descriptionFromText : null,
          descriptionTo:
            descriptionToText.length > 0 ? descriptionToText : null,
        },
      },
    });
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!canUpdate || updateReferenceLoading) return;
      onSubmit();
    },
    dependsOn: [onSubmit, canUpdate, updateReferenceLoading],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onCloseModal();
    },
    dependsOn: [onCloseModal],
  });

  const content = (
    <EditReferenceLink
      entityType={entityType}
      entityId={entityId}
      descriptionTo={
        inversePerspective ? descriptionFromText : descriptionToText
      }
      descriptionFrom={
        inversePerspective ? descriptionToText : descriptionFromText
      }
      onChangeDescriptionTo={
        inversePerspective
          ? onChangeDescriptionFromTextHandler
          : onChangeDescriptionToTextHandler
      }
      onChangeDescriptionFrom={
        inversePerspective
          ? onChangeDescriptionToTextHandler
          : onChangeDescriptionFromTextHandler
      }
      showDescription={showDescription}
      onChangeShowDescription={setShowDescription}
      onSelect={(selection) => {
        setSelection(selection);
      }}
      onSubmit={(selection) => {
        onSubmit(selection);
      }}
      onCancel={() => {
        onCloseModal();
      }}
      showEntitySelection={false}
    />
  );

  return (
    <div className={`modal-card`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.modal_edit_reference.edit_link.title")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${styles.body}`}>{content}</section>
      <footer className="modal-card-foot">
        <button
          id="submitButton"
          className={`button is-primary ${
            updateReferenceLoading ? `is-loading` : ``
          }`}
          type="submit"
          disabled={!canUpdate}
          onClick={() => {
            onSubmit();
          }}
        >
          {t("wisdomtree.modal_edit_reference.edit_link.edit")}
        </button>
        <button className="button" type="button" onClick={onCloseModal}>
          {t("wisdomtree.modal_edit_reference.edit_link.cancel")}
        </button>
      </footer>
    </div>
  );
};

EditLink.propTypes = propTypesAddInternalLink;
EditLink.defaultProps = defaultPropsAddInternalLink;

export default EditLink;
