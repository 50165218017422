import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./EditFeatureRequest.module.scss";
import { useHotkey } from "../../../../Utils/Hotkeys";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import ModalCloseButton from "../../../../UI/Modal/ModalCloseButton/ModalCloseButton";
import { UPDATE_FEATURE_REQUEST } from "../../../../GraphQl/roadmap";

const propTypesEditFeatureRequest = {
  featureRequest: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  onCloseModal: PropTypes.func.isRequired,
};
const defaultPropsEditFeatureRequest = {};

const EditFeatureRequest = ({ featureRequest, onCloseModal }) => {
  const { t } = useTranslation();

  const [titleText, setTitleText] = useState(featureRequest.title);
  const [descriptionText, setDescriptionText] = useState(
    featureRequest.description
  );
  const readyToSubmit =
    titleText.length > 0 &&
    descriptionText.length > 0 &&
    (titleText !== featureRequest.title ||
      descriptionText !== featureRequest.description);

  const [
    updateFeatureRequest,
    { loading: updateFeatureRequestLoading },
  ] = useMutation(UPDATE_FEATURE_REQUEST, {
    onError: (err) => {
      Logger.error(err);
    },
    onCompleted: () => {
      onCloseModal();
    },
  });

  const onChangeTitleTextHandler = (event) => {
    const title = event.target.value;
    setTitleText(title);
  };

  const onChangeDescriptionTextHandler = (event) => {
    const description = event.target.value;
    setDescriptionText(description);
  };

  const onSubmit = () => {
    updateFeatureRequest({
      variables: {
        id: featureRequest.id,
        input: {
          title: titleText,
          description: descriptionText,
        },
      },
    });
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!readyToSubmit) return;
      onSubmit();
    },
    dependsOn: [onSubmit, readyToSubmit],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onCloseModal();
    },
    dependsOn: [onCloseModal],
  });

  const content = (
    <div className={styles.inputContainer}>
      <input
        className={`input ${styles.input}`}
        type="text"
        value={titleText}
        onChange={onChangeTitleTextHandler}
        placeholder={t(
          "wisdomtree.roadmap.modal_feature_request.title_placeholder"
        )}
      />
      <input
        className={`input ${styles.input}`}
        type="text"
        value={descriptionText}
        onChange={onChangeDescriptionTextHandler}
        placeholder={t(
          "wisdomtree.roadmap.modal_feature_request.description_placeholder"
        )}
      />
    </div>
  );

  return (
    <div className={`modal-card`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.roadmap.modal_feature_request.title_edit")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${styles.body}`}>{content}</section>
      <footer className="modal-card-foot">
        <button
          id="submitButton"
          className={`button is-primary ${
            updateFeatureRequestLoading ? `is-loading` : ``
          }`}
          type="submit"
          disabled={!readyToSubmit}
          onClick={() => {
            onSubmit();
          }}
        >
          {t("wisdomtree.roadmap.modal_feature_request.done_edit")}
        </button>
        <button className="button" type="button" onClick={onCloseModal}>
          {t("wisdomtree.roadmap.modal_feature_request.cancel")}
        </button>
      </footer>
    </div>
  );
};

EditFeatureRequest.propTypes = propTypesEditFeatureRequest;
EditFeatureRequest.defaultProps = defaultPropsEditFeatureRequest;

export default EditFeatureRequest;
