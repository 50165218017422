import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDoubleTap } from "use-double-tap";

import styles from "./ReferenceText.module.scss";
import { truncate } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import Reference from "../Reference/Reference";
import { toast } from "react-toastify";

const propTypesReferenceText = {
  povType: PropTypes.string.isRequired,
  povId: PropTypes.string.isRequired,
  deleteLoading: PropTypes.bool,
  editMode: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  reference: PropTypes.shape({
    title: PropTypes.string.isRequired,
    descriptionTo: PropTypes.string,
    descriptionFrom: PropTypes.string,
    source: PropTypes.shape({
      entry: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      block: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
        entry: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          image: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }).isRequired,
};
const defaultPropsReferenceText = {
  deleteLoading: false,
  editMode: true,
};

const ReferenceText = ({
  reference,
  deleteLoading,
  editMode,
  onEdit,
  onRemove,
}) => {
  const { t } = useTranslation();

  let text = reference.title;

  const onItemClicked = (item) => {
    if (item.key === "copy") {
      const el = document.createElement("textarea");
      el.value = reference.title;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      toast.success(t(`wisdomtree.entry_browser.reference_copied_text`), {
        autoClose: 2000,
      });
    }
    if (item.key === "edit") {
      onEdit();
    }
    if (item.key === "remove") {
      onRemove();
    }
  };

  const dropdownItems = [
    {
      key: "copy",
      text: t("wisdomtree.entry_browser.reference_copy_text"),
      icon: "fa-copy",
      tooltip: t("wisdomtree.entry_browser.tooltips.reference_copy_text"),
    },
  ];

  if (editMode) {
    dropdownItems.push({
      key: "edit",
      text: t("wisdomtree.entry_browser.reference_edit"),
      icon: "fa-pen",
      tooltip: t("wisdomtree.entry_browser.tooltips.reference_edit"),
    });
    dropdownItems.push({
      key: "remove",
      text: t("wisdomtree.entry_browser.reference_remove"),
      icon: "fa-unlink",
      tooltip: t("wisdomtree.entry_browser.tooltips.reference_remove"),
    });
  }

  const titleNode = <p className={styles.text}>{truncate(text, 34, true)}</p>;

  return (
    <Reference
      titleNode={titleNode}
      description={
        reference.descriptionTo
          ? truncate(reference.descriptionTo, 16, true)
          : null
      }
      dropdownItems={dropdownItems}
      onDropdownItemClicked={onItemClicked}
      deleteLoading={deleteLoading}
      previewImage={false}
    />
  );
};

ReferenceText.propTypes = propTypesReferenceText;
ReferenceText.defaultProps = defaultPropsReferenceText;

export default ReferenceText;
