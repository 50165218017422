import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./CreateChangeNote.module.scss";
import { useHotkey } from "../../../../Utils/Hotkeys";

const propTypesCreateChangeNote = {
  onCreateChangeNote: PropTypes.func.isRequired,
};
const defaultPropsCreateChangeNote = {};

const CreateChangeNote = ({ onCreateChangeNote }) => {
  const [inputTextEn, setInputTextEn] = useState("");
  const [inputTextDe, setInputTextDe] = useState("");

  const onCreateChangeNoteHandler = () => {
    onCreateChangeNote({ text_en: inputTextEn, text_de: inputTextDe });
    setInputTextEn("");
    setInputTextDe("");
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (inputTextEn.length === 0 || inputTextDe.length === 0) return;
      onCreateChangeNoteHandler();
    },
    dependsOn: [onCreateChangeNoteHandler, inputTextEn, inputTextDe],
  });

  const onChangeInputTextEnHandler = (event) => {
    setInputTextEn(event.target.value);
  };

  const onChangeInputTextDeHandler = (event) => {
    setInputTextDe(event.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputsContainer}>
        <div className={`${styles.inputContainer} control`}>
          <input
            className={`input ${styles.input}`}
            type="text"
            value={inputTextEn}
            onChange={onChangeInputTextEnHandler}
            placeholder="What's new in English?"
          />
        </div>
        <div className={`${styles.inputContainer} control`}>
          <input
            className={`input ${styles.input}`}
            type="text"
            value={inputTextDe}
            onChange={onChangeInputTextDeHandler}
            placeholder="Was gibt's Neues in Deutsch?"
          />
        </div>
      </div>
      <button
        className={`button ${
          inputTextEn.length > 0 && inputTextDe.length > 0 ? "is-primary" : null
        } ${styles.button}`}
        type="button"
        disabled={inputTextEn.length === 0 || inputTextDe.length === 0}
        onClick={() => {
          onCreateChangeNoteHandler();
          document.activeElement.blur();
        }}
      >
        <span className="icon is-small">
          <i className="fas fa-plus" />
        </span>
      </button>
    </div>
  );
};

CreateChangeNote.propTypes = propTypesCreateChangeNote;
CreateChangeNote.defaultProps = defaultPropsCreateChangeNote;

export default CreateChangeNote;
