import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./NewMessage.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";

const propTypesNewMessage = {
  onSendNewMessage: PropTypes.func.isRequired,
  focusInitially: PropTypes.bool,
  saveDraft: PropTypes.bool,
};
const defaultPropsNewMessage = {
  focusInitially: true,
  saveDraft: false,
};

const NewMessage = ({ onSendNewMessage, focusInitially, saveDraft }) => {
  const { t } = useTranslation();
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    const existingDraft = localStorage.getItem("feedbackMessageDraft");
    if (existingDraft) setNewMessage(existingDraft);
  }, []);

  const onSendNewMessageHandler = () => {
    onSendNewMessage(newMessage);
    setNewMessage("");
    localStorage.removeItem("feedbackMessageDraft");
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (newMessage.length === 0) return;
      onSendNewMessageHandler();
    },
    dependsOn: [onSendNewMessageHandler, newMessage],
  });

  const onChangeNewMessageHandler = (event) => {
    setNewMessage(event.target.value);
    localStorage.setItem("feedbackMessageDraft", event.target.value);
  };

  return (
    <div className={styles.sendMessageContainer}>
      <div className={`${styles.messageInputContainer} control`}>
        <input
          className={`input ${styles.messageInput}`}
          type="text"
          value={newMessage}
          onChange={onChangeNewMessageHandler}
          placeholder={t("wisdomtree.feedback.hint")}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={focusInitially}
        />
      </div>
      <button
        className={`button ${newMessage.length > 0 ? "is-primary" : null} ${
          styles.sendButton
        }`}
        type="button"
        disabled={newMessage.length === 0}
        onClick={() => {
          onSendNewMessageHandler();
          document.activeElement.blur();
        }}
      >
        <span className="icon is-small">
          <i className="fas fa-envelope" />
        </span>
      </button>
    </div>
  );
};

NewMessage.propTypes = propTypesNewMessage;
NewMessage.defaultProps = defaultPropsNewMessage;

export default NewMessage;
