/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./Graph.module.scss";
import stylesVariables from "../../../variables.scss";
import Graph from "./Graph";

const propTypes = {
  newMode: PropTypes.bool,
  editMode: PropTypes.bool,
  isTabletOrMobile: PropTypes.bool,
  quizMode: PropTypes.bool,
  selectedEntryId: PropTypes.string,
  sharingRootEntryId: PropTypes.string,
  user: PropTypes.shape({
    image: PropTypes.string,
    level: PropTypes.number,
  }),
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.String,
      }),
    })
  ),
  references: PropTypes.arrayOf(
    PropTypes.shape({
      sourceId: PropTypes.string,
      targetId: PropTypes.string,
    })
  ),
  newEntryDraft: PropTypes.shape({
    name: PropTypes.string,
  }),
  windowWidth: PropTypes.number.isRequired,
  windowHeight: PropTypes.number.isRequired,
  onNewEntry: PropTypes.func.isRequired,
  onUpdateNewEntryDraft: PropTypes.func.isRequired,
  onUpdateEntry: PropTypes.func.isRequired,
  onSelectEntry: PropTypes.func.isRequired,
  onAttemptSelectEntry: PropTypes.func.isRequired,
  onUpdateBlockEntry: PropTypes.func.isRequired,
};

const defaultProps = {
  newMode: false,
  editMode: false,
  isTabletOrMobile: false,
  quizMode: false,
  selectedEntryId: null,
  sharingRootEntryId: null,
  user: null,
  entries: [],
  references: [],
  newEntryDraft: null,
};

class GraphComponent extends Component {
  constructor(props) {
    super(props);
    this.d3Container = React.createRef();
    this.graphD3 = new Graph({
      darkMode: props.darkMode,
      primaryColor: stylesVariables.primaryColor,
      primaryLightColor: stylesVariables.primaryLightColor,
      primaryDarkColor: stylesVariables.primaryDarkColor,
      secondaryColor: stylesVariables.secondaryColor,
      secondaryDarkColor: stylesVariables.secondaryDarkColor,
      thirdColor: stylesVariables.thirdColor,
      thirdDarkColor: stylesVariables.thirdDarkColor,
      linkColor: stylesVariables.linkColor,
      editMode: props.editMode,
      isTabletOrMobile: props.isTabletOrMobile,
      user: props.user,
      rootEntryId: props.sharingRootEntryId,
      onNewEntry: (entryId) => {
        this.props.onNewEntry(entryId);
      },
      onUpdateEntry: (entry) => {
        this.props.onUpdateEntry(entry);
      },
      onSelectEntry: (entry) => {
        this.props.onSelectEntry(entry);
      },
      onUpdateNewEntryDraft: (updatedDraft) => {
        this.props.onUpdateNewEntryDraft(updatedDraft);
      },
      onAttemptSelectEntry: (entry) => {
        this.props.onAttemptSelectEntry(entry);
      },
      onUpdateBlockEntry: (blockId, entryId, newEntryId) => {
        this.props.onUpdateBlockEntry(blockId, entryId, newEntryId);
      },
    });
  }

  componentDidMount() {
    this.createNewGraph();
    this.componentDidUpdate({});
  }

  componentDidUpdate(prevProps) {
    // console.log({ prev: prevProps, props: this.props });
    // const darkModeChanged = prevProps.darkMode !== this.props.darkMode;
    //console.log({ darkModeChanged });
    const windowWidthChanged = prevProps.windowWidth !== this.props.windowWidth;
    const windowHeightChanged =
      prevProps.windowHeight !== this.props.windowHeight;
    const userChanged =
      JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user);
    const entriesChanged = prevProps.entries !== this.props.entries;
    const referencesChanged =
      JSON.stringify(prevProps.references) !==
      JSON.stringify(this.props.references);
    const entrySelectionChanged =
      prevProps.selectedEntryId !== this.props.selectedEntryId;
    const editModeChanged = prevProps.editMode !== this.props.editMode;
    const isTabletOrMobileChanged =
      prevProps.isTabletOrMobile !== this.props.isTabletOrMobile;
    const newModeChanged = prevProps.newMode !== this.props.newMode;
    const quizModeChanged = prevProps.quizMode !== this.props.quizMode;
    const newEntryDraftChanged =
      prevProps.newEntryDraft !== this.props.newEntryDraft;

    // console.log({
    //   userChanged,
    //   entriesChanged,
    //   referencesChanged,
    //   entrySelectionChanged,
    // });

    let requireNewGraph = false;
    let requireContentUpdate = false;
    let requireUserUpdate = false;
    let requireEntrySelection = false;
    let requireZoomToEntry = false;
    let requireAddNewEntryNode = false;

    if (windowWidthChanged) {
      requireNewGraph = true;
      requireUserUpdate = true;
      requireEntrySelection = true;
      requireZoomToEntry = true;
      requireAddNewEntryNode = true;
    }

    if (userChanged) {
      requireUserUpdate = true;
    }

    if (entriesChanged && !entrySelectionChanged) {
      this.setRecentlyUpdated(true);
      requireContentUpdate = true;
    }

    // if the entry selection changed, the content will be updated anyways!
    if (referencesChanged && !entrySelectionChanged) {
      this.setRecentlyUpdated(true);
      requireEntrySelection = true;
    }

    if (entrySelectionChanged) {
      requireEntrySelection = true;
      requireZoomToEntry = true;
    }

    if (requireNewGraph) {
      this.createNewGraph();
    }

    if (requireUserUpdate) {
      this.updateUser();
    }

    if (editModeChanged) {
      if (this.props.editMode) {
        this.enableEditMode();
      } else {
        this.disableEditMode();
      }
    }

    if (isTabletOrMobileChanged) {
      this.setIsTabletOrMobile(this.props.isTabletOrMobile);
    }

    if (quizModeChanged) {
      if (this.props.quizMode) {
        this.enableQuizMode();
      } else {
        this.disableQuizMode();
      }
    }

    if (requireContentUpdate && !requireEntrySelection) {
      this.updateContent();
    }

    if (requireEntrySelection) {
      this.updateContentAndSelectEntry(this.props.selectedEntryId);
    }
    if (requireZoomToEntry) {
      this.zoomToEntry(this.props.selectedEntryId);
    }

    if (newModeChanged) {
      if (this.props.newMode) {
        this.enableNewMode();
      } else {
        this.disableNewMode();
      }
    }

    if (newEntryDraftChanged) {
      if (this.props.newMode) {
        this.updateNewDraft(this.props.newEntryDraft);
      }
    }

    if (requireAddNewEntryNode && this.props.newMode) {
      this.addNewEntryNode();
    }

    this.setRecentlyUpdated(false);
  }

  setRecentlyUpdated(recentlyUpdated) {
    this.graphD3.setRecentlyUpdated(recentlyUpdated);
  }

  createNewGraph() {
    this.graphD3.create(
      this.d3Container.current,
      this.props.windowWidth,
      this.d3Container.current.clientHeight
    );
  }

  updateContent() {
    this.graphD3.updateContent(
      this.d3Container.current,
      this.props.entries,
      this.props.references
    );
  }

  updateContentAndSelectEntry(entryId) {
    this.graphD3.updateContentAndSelectEntry(
      this.d3Container.current,
      this.props.entries,
      this.props.references,
      entryId
    );
  }

  updateUser() {
    this.graphD3.updateUser(this.d3Container.current, this.props.user);
  }

  zoomToEntry(entryId) {
    if (this.props.quizMode) {
      this.graphD3.graphCamera.zoomToCenterEntry(
        this.d3Container.current,
        entryId
      );
    } else {
      this.graphD3.graphCamera.zoomToEntry(this.d3Container.current, entryId);
    }
  }

  enableQuizMode() {
    this.graphD3.enableQuizMode(this.d3Container.current);
  }

  disableQuizMode() {
    this.graphD3.disableQuizMode(this.d3Container.current);
  }

  enableEditMode() {
    this.graphD3.enableEditMode(this.d3Container.current);
  }

  disableEditMode() {
    this.graphD3.disableEditMode(this.d3Container.current);
  }

  setIsTabletOrMobile(isTabletOrMobile) {
    this.graphD3.setIsTabletOrMobile(
      this.d3Container.current,
      isTabletOrMobile
    );
  }

  enableNewMode() {
    this.graphD3.enableNewEntryMode(this.d3Container.current);
  }

  addNewEntryNode() {
    this.graphD3.addNewEntryNode(this.d3Container.current);
  }

  disableNewMode() {
    this.graphD3.disableNewEntryMode(this.d3Container.current);
  }

  updateNewDraft(newEntryDraft) {
    this.graphD3.updateNewEntryDraft(this.d3Container.current, newEntryDraft);
  }

  render() {
    return (
      <>
        <div className={`${styles.GraphContainer}`} ref={this.d3Container} />
        <div id="template" style={{ display: "none" }}>
          <button
            type="button"
            className={`button ${styles.graphButton}`}
            data-id="graph.newEntry"
          >
            <span className={`icon ${styles.buttonIcon}`}>
              <i className="fas fa-plus" />
            </span>
          </button>
        </div>
      </>
    );
  }
}

GraphComponent.propTypes = propTypes;
GraphComponent.defaultProps = defaultProps;

export default GraphComponent;
