import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import styles from "./CommonEntryDetail.module.scss";
import {
  GET_COMMON_ENTRY_WITH_VARIANTS,
  mergeCommonEntriesAfterCreate,
  CREATE_COMMON_ENTRY_VARIANT,
  DELETE_COMMON_ENTRY_VARIANT,
  mergeCommonEntriesAfterDelete,
  UPDATE_COMMON_ENTRY_VARIANT,
  MERGE_COMMON_ENTRIES,
  mergeCommonEntriesAfterMerge,
} from "../../GraphQl/commonEntries";
import Spinner from "../../UI/Spinner/Spinner";
import BackButton from "../UI/BackButton/BackButton";
import CommonEntryHeader from "./CommonEntryHeader/CommonEntryHeader";
import CommonEntryVariant from "./CommonEntryVariant/CommonEntryVariant";
import CreateVariant from "../CommonEntries/CreateVariant/CreateVariant";

const propTypesCommonEntryDetail = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};
const defaultPropsCommonEntryDetail = {};

const CommonEntryDetail = ({ match }) => {
  const history = useHistory();
  const { commonEntryId } = match.params;

  const {
    loading: getCommonEntryLoading,
    data: getCommonEntryData,
    refetch: refetchCommonEntry,
  } = useQuery(GET_COMMON_ENTRY_WITH_VARIANTS, {
    variables: {
      id: commonEntryId,
    },
  });
  const commonEntry = getCommonEntryData
    ? getCommonEntryData.commonEntry
    : null;

  const [createCommonEntry] = useMutation(CREATE_COMMON_ENTRY_VARIANT, {
    update: mergeCommonEntriesAfterCreate,
    onError: (err) => {
      Logger.error(err);
    },
  });

  const [updateCommonEntryVariant] = useMutation(UPDATE_COMMON_ENTRY_VARIANT, {
    onError: (err) => {
      Logger.error(err);
    },
  });

  const [mergeCommonEntries] = useMutation(MERGE_COMMON_ENTRIES, {
    update: (cache, mutationResult) => {
      const { updatedCommonEntry } = mutationResult.data.mergeCommonEntries;

      if (updatedCommonEntry) {
        history.push(`/admin/commonEntry/${updatedCommonEntry.id}`);
      }
      mergeCommonEntriesAfterMerge(cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  const [deleteCommonEntryVariant] = useMutation(DELETE_COMMON_ENTRY_VARIANT, {
    update: (cache, mutationResult) => {
      const {
        deletedCommonEntryIds,
      } = mutationResult.data.deleteCommonEntryVariant;

      if (
        deletedCommonEntryIds &&
        deletedCommonEntryIds.includes(commonEntryId)
      ) {
        history.push("/admin/commonEntries");
      }
      mergeCommonEntriesAfterDelete(cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  useEffect(() => {
    refetchCommonEntry();
  }, []);

  const handleDeleteVariant = (variantId) => {
    deleteCommonEntryVariant({
      variables: {
        id: variantId,
      },
    });
  };

  let content = null;
  if (getCommonEntryLoading) {
    content = <Spinner />;
  } else {
    const variantsContent = commonEntry.variants.map((variant) => {
      return (
        <div key={variant.id} className={styles.variantContainer}>
          <CommonEntryVariant
            commonEntryVariant={variant}
            onDeleteVariant={handleDeleteVariant}
            onApproveVariant={() => {
              updateCommonEntryVariant({
                variables: {
                  id: variant.id,
                  input: {
                    approved: true,
                  },
                },
              });
            }}
            onDisapproveVariant={() => {
              updateCommonEntryVariant({
                variables: {
                  id: variant.id,
                  input: {
                    approved: false,
                  },
                },
              });
            }}
            onUpdateVariantLanguage={(lang) => {
              updateCommonEntryVariant({
                variables: {
                  id: variant.id,
                  input: {
                    language: lang,
                  },
                },
              });
            }}
          />
        </div>
      );
    });

    content = (
      <div className={styles.container}>
        <CommonEntryHeader
          commonEntry={commonEntry}
          onMergeCommonEntries={(commonEntryId1, commonEntryId2) => {
            mergeCommonEntries({
              variables: {
                commonEntryId1,
                commonEntryId2,
              },
            });
          }}
        />
        <div className={styles.createContainer}>
          <CreateVariant
            placeholder="Create new variant ..."
            onCreateVariant={({ name, parentId }) => {
              createCommonEntry({
                variables: {
                  input: {
                    name,
                    parentCommonEntryId: parentId.length > 0 ? parentId : null,
                    commonEntryId,
                    approved: true,
                  },
                },
              });
            }}
          />
        </div>

        <div className={styles.variantsContainer}>{variantsContent}</div>
      </div>
    );
  }

  return (
    <div>
      <BackButton linkTo="/admin/commonEntries" />

      {content}
    </div>
  );
};

CommonEntryDetail.propTypes = propTypesCommonEntryDetail;
CommonEntryDetail.defaultProps = defaultPropsCommonEntryDetail;

export default CommonEntryDetail;
