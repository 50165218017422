/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import Logger from "js-logger";
import moment from "moment";

// eslint-disable-next-line no-unused-vars
import styles from "./UserSettingsModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import {
  GET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  mergeUserAfterUpdate,
  UPDATE_NOTIFICATION_SETTING,
  mergeUserAfterUpdateNotificationSetting,
} from "../../../GraphQl/user";
import Spinner from "../../Spinner/Spinner";
import i18n from "../../../../config/i18n";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import {
  mobileNotificationPermissionStatus,
  themeSetting,
} from "../../../../config/apollo-cache";
import Checkbox from "../../Checkbox/Checkbox";
import RadioButtons from "../../RadioButtons/RadioButtons";
import InfoIcon from "../../InfoIcon/InfoIcon";
import DataExport from "./DataExport/DataExport";
import { browserSupportsPush } from "../../../Utils/PushNotifications";
import NotificationSubscription from "../../NotificationSubscription/NotificationSubscription";
import { isMobileApp } from "../../../Utils/ReactNativeMessageUtils";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useUserSettingsModal = ({ onOpen, onClose }) => {
  const { isOpened, open, close } = useBaseModal({ onOpen, onClose });

  let content = null;
  if (isOpened) {
    content = <UserSettingsModal show={isOpened} onCloseHandler={close} />;
  }

  return { open, close, content };
};

useUserSettingsModal.propTypes = propTypes;
useUserSettingsModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
};

const defaultPropsModal = {};

const UserSettingsModal = ({ show, onCloseHandler }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("general");
  const [notificationsHandled, setNotificationsHandled] = useState(false);
  const mobileNotificationStatus = useReactiveVar(
    mobileNotificationPermissionStatus
  );

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!show) return;
      onCloseHandler();
    },
    dependsOn: [onCloseHandler, show],
  });

  const { loading: getUserLoading, data: getUserData } = useQuery(
    GET_CURRENT_USER
  );
  const user = getUserData ? getUserData.me : null;

  const [
    updateUserLanguage,
    { loading: updateUserLanguageLoading },
  ] = useMutation(UPDATE_CURRENT_USER, {
    update: (cache, mutationResult) => {
      mergeUserAfterUpdate(cache, mutationResult);
      i18n.changeLanguage(mutationResult.data.updateUser.updatedUser.language);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  const handleLanguageSettingChange = (event) => {
    updateUserLanguage({
      variables: {
        input: {
          language: event.target.value,
        },
      },
    });
  };

  const [updateUserTheme, { loading: updateUserThemeLoading }] = useMutation(
    UPDATE_CURRENT_USER,
    {
      update: (cache, mutationResult) => {
        mergeUserAfterUpdate(cache, mutationResult);
        const theme = mutationResult.data.updateUser.updatedUser.theme;
        localStorage.setItem("themeSetting", theme);
        themeSetting(theme);
      },
      onError: (err) => {
        Logger.error(err);
      },
    }
  );

  const handleThemeSettingChange = (event) => {
    updateUserTheme({
      variables: {
        input: {
          theme: event.target.value,
        },
      },
    });
  };

  const [updateUser] = useMutation(UPDATE_CURRENT_USER, {
    update: (cache, mutationResult) => {
      mergeUserAfterUpdate(cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  const handleShareEntryNamesSettingChange = (event) => {
    updateUser({
      variables: {
        input: {
          sharesEntryNames: event.target.checked,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateUser: {
          __typename: "UpdateUserResponse",
          code: 200,
          success: true,
          message: "user updated",
          updatedUser: { ...user, sharesEntryNames: event.target.checked },
        },
      },
    });
  };

  const handleEnableDailyQuote = () => {
    updateUser({
      variables: {
        input: {
          dailyQuotesEnabled: true,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateUser: {
          __typename: "UpdateUserResponse",
          code: 200,
          success: true,
          message: "user updated",
          updatedUser: { ...user, dailyQuotesEnabled: true },
        },
      },
    });
  };

  const [updateUserNotificationSettings] = useMutation(
    UPDATE_NOTIFICATION_SETTING,
    {
      update: (cache, mutationResult) => {
        mergeUserAfterUpdateNotificationSetting(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
    }
  );

  const handleUserNotificationSettings = (type, active, hourOfDay = null) => {
    let input = { type };
    if (active != null) input.active = active === "true";
    if (hourOfDay != null) input.hourOfDay = hourOfDay;

    let updatedNotificationSettings = user.notificationSettings.map((s) =>
      s.type === type
        ? {
            type,
            active: active != null ? active === "true" : s.active,
            hourOfDay: hourOfDay != null ? hourOfDay : s.hourOfDay,
          }
        : s
    );

    updateUserNotificationSettings({
      variables: {
        input,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNotificationSetting: {
          __typename: "UpdateNotificationSettingsResponse",
          code: 200,
          success: true,
          message: "notification settings updated",
          updatedUser: {
            ...user,
            notificationSettings: updatedNotificationSettings,
          },
        },
      },
    });
  };

  const getNotificationSetting = (type) => {
    return user.notificationSettings.find((s) => s.type === type);
  };

  const languageSettingContent = (
    <div className={`${styles.settingContainer}`}>
      <span className={`${styles.settingTitle}`}>
        {t("wisdomtree.modal_user_settings.setting_language")}
      </span>
      <div
        className={`select ${updateUserLanguageLoading ? "is-loading" : null}`}
      >
        <select
          value={user ? user.language : null}
          onChange={handleLanguageSettingChange}
          disabled={updateUserLanguageLoading}
        >
          <option value="en">
            {t("wisdomtree.modal_user_settings.setting_language_english")}
          </option>
          <option value="de">
            {t("wisdomtree.modal_user_settings.setting_language_german")}
          </option>
        </select>
      </div>
    </div>
  );

  const themeSettingContent = (
    <div className={`${styles.settingContainer}`}>
      <span className={`${styles.settingTitle}`}>
        {t("wisdomtree.modal_user_settings.setting_theme")}
      </span>
      <div className={`select ${updateUserThemeLoading ? "is-loading" : null}`}>
        <select
          value={user ? user.theme : null}
          onChange={handleThemeSettingChange}
          disabled={updateUserThemeLoading}
        >
          <option value="SYSTEM">
            {t("wisdomtree.modal_user_settings.setting_theme_system")}
          </option>
          <option value="LIGHT">
            {t("wisdomtree.modal_user_settings.setting_theme_light")}
          </option>
          <option value="DARK">
            {t("wisdomtree.modal_user_settings.setting_theme_dark")}
          </option>
        </select>
      </div>
    </div>
  );

  const shareEntryNamesSettingContent = (
    <div
      className={`${styles.settingContainer} ${styles.shareEntryNamesContainer}`}
    >
      <Checkbox
        checked={user ? user.sharesEntryNames : null}
        onChange={handleShareEntryNamesSettingChange}
        text={t("wisdomtree.modal_user_settings.setting_share_entry_names")}
      />
    </div>
  );

  let enableDailyQuotesContent = null;
  if (user && !user.dailyQuotesEnabled) {
    enableDailyQuotesContent = (
      <div className={`${styles.settingContainer}`}>
        <Checkbox
          checked={user ? user.dailyQuotesEnabled : null}
          onChange={handleEnableDailyQuote}
          text={t("wisdomtree.modal_user_settings.setting_enable_daily_quotes")}
        />
      </div>
    );
  }

  let pushSettingsContent = null;
  const showNotifyButton =
    ((isMobileApp() && mobileNotificationStatus === "undetermined") ||
      (browserSupportsPush() && Notification.permission === "default")) &&
    !notificationsHandled;

  let notifyButtonContent = null;
  if (showNotifyButton) {
    notifyButtonContent = (
      <div className={styles.notificationButtonContainer}>
        <NotificationSubscription
          text={
            isMobileApp()
              ? t("wisdomtree.modal_user_settings.notifications.button_mobile")
              : t("wisdomtree.modal_user_settings.notifications.button_web")
          }
          onPermissionHandled={() => {
            setNotificationsHandled(true);
          }}
        />
      </div>
    );
  }

  const hoursOfDay = [];
  const momentObj = moment().minute(0);
  for (let i = 0; i < 24; i++) {
    momentObj.hour(i);
    let format =
      user.language == "de"
        ? momentObj.format("HH:mm")
        : momentObj.format("h A");
    hoursOfDay.push({ display: format, value: i });
  }

  const quizReminderDisabled = !getNotificationSetting("QUIZ_REMINDER").active;
  const quizReminderHourOfDayContent = (
    <div
      className={`select ${styles.hourOfDayPickerContainer} ${
        quizReminderDisabled ? styles.disabled : null
      }`}
    >
      <select
        value={getNotificationSetting("QUIZ_REMINDER").hourOfDay}
        onChange={(event) => {
          handleUserNotificationSettings(
            "QUIZ_REMINDER",
            null,
            parseInt(event.target.value)
          );
        }}
        disabled={quizReminderDisabled}
      >
        {hoursOfDay.map((o) => (
          <option key={o.value} value={o.value}>
            {o.display}
          </option>
        ))}
      </select>
    </div>
  );

  const dailyQuoteDisabled = !getNotificationSetting("DAILY_QUOTE").active;
  const DailyQuoteDayHourOfDayContent = (
    <div
      className={`select ${styles.hourOfDayPickerContainer} ${
        dailyQuoteDisabled ? styles.disabled : null
      }`}
    >
      <select
        value={getNotificationSetting("DAILY_QUOTE").hourOfDay}
        onChange={(event) => {
          handleUserNotificationSettings(
            "DAILY_QUOTE",
            null,
            parseInt(event.target.value)
          );
        }}
        disabled={dailyQuoteDisabled}
      >
        {hoursOfDay.map((o) => (
          <option key={o.value} value={o.value}>
            {o.display}
          </option>
        ))}
      </select>
    </div>
  );

  const pushSettingsDetailContent = (
    <div
      className={`${styles.settingContainer} ${styles.emailSettingsContainer}`}
    >
      <div className={styles.emailSettingContainer}>
        <div
          className={`${styles.settingTitle} ${styles.emailSettingTitleContainer}`}
        >
          <span className={`${styles.emailSettingTitleText}`}>
            {t(
              "wisdomtree.modal_user_settings.notifications.quiz_reminder.title"
            )}
          </span>
          <InfoIcon
            content={
              <p>
                {t(
                  "wisdomtree.modal_user_settings.notifications.quiz_reminder.description"
                )}
              </p>
            }
          />
        </div>

        <div className={styles.emailSettingControlsContainer}>
          <RadioButtons
            name={"quizReminder"}
            selectedValue={getNotificationSetting("QUIZ_REMINDER").active}
            options={[
              {
                label: t("wisdomtree.modal_user_settings.notifications.off"),
                value: false,
              },
              {
                label: t("wisdomtree.modal_user_settings.notifications.on"),
                value: true,
              },
            ]}
            onChange={(event) => {
              handleUserNotificationSettings(
                "QUIZ_REMINDER",
                event.target.value
              );
            }}
          />
          {quizReminderHourOfDayContent}
        </div>
      </div>
      <div className={styles.emailSettingContainer}>
        <div
          className={`${styles.settingTitle} ${styles.emailSettingTitleContainer}`}
        >
          <span className={`${styles.emailSettingTitleText}`}>
            {t(
              "wisdomtree.modal_user_settings.notifications.daily_quotes.title"
            )}
          </span>
          <InfoIcon
            content={
              <p>
                {t(
                  "wisdomtree.modal_user_settings.notifications.daily_quotes.description"
                )}
              </p>
            }
          />
        </div>
        <div className={styles.emailSettingControlsContainer}>
          <RadioButtons
            name={"dailyQuotes"}
            selectedValue={getNotificationSetting("DAILY_QUOTE").active}
            options={[
              {
                label: t("wisdomtree.modal_user_settings.notifications.off"),
                value: false,
              },
              {
                label: t("wisdomtree.modal_user_settings.notifications.on"),
                value: true,
              },
            ]}
            onChange={(event) => {
              handleUserNotificationSettings("DAILY_QUOTE", event.target.value);
            }}
          />
          {DailyQuoteDayHourOfDayContent}
        </div>
      </div>
    </div>
  );

  pushSettingsContent = (
    <div className={styles.settingsGroup}>
      <span className={styles.groupTitle}>
        {t("wisdomtree.modal_user_settings.notifications.title")}
      </span>
      {notifyButtonContent}
      {pushSettingsDetailContent}
    </div>
  );

  const emailSettingsContent = (
    <div
      className={`${styles.settingContainer} ${styles.emailSettingsContainer}`}
    >
      <div className={styles.emailSettingContainer}>
        <div
          className={`${styles.settingTitle} ${styles.emailSettingTitleContainer}`}
        >
          <span className={`${styles.emailSettingTitleText}`}>
            {t("wisdomtree.modal_user_settings.email.newsletter.title")}
          </span>
          <InfoIcon
            content={
              <p>
                {t(
                  "wisdomtree.modal_user_settings.email.newsletter.description"
                )}
              </p>
            }
          />
        </div>

        <RadioButtons
          name={"newsletter"}
          selectedValue={getNotificationSetting("NEWSLETTER").active}
          options={[
            {
              label: t("wisdomtree.modal_user_settings.email.off"),
              value: false,
            },
            {
              label: t("wisdomtree.modal_user_settings.email.on"),
              value: true,
            },
          ]}
          onChange={(event) => {
            handleUserNotificationSettings("NEWSLETTER", event.target.value);
          }}
        />
      </div>
      <div className={styles.emailSettingContainer}>
        <div
          className={`${styles.settingTitle} ${styles.emailSettingTitleContainer}`}
        >
          <span className={`${styles.emailSettingTitleText}`}>
            {t("wisdomtree.modal_user_settings.email.usage_tips.title")}
          </span>
          <InfoIcon
            content={
              <p>
                {t(
                  "wisdomtree.modal_user_settings.email.usage_tips.description"
                )}
              </p>
            }
          />
        </div>
        <RadioButtons
          name={"usageTips"}
          selectedValue={getNotificationSetting("USAGE_TIP").active}
          options={[
            {
              label: t("wisdomtree.modal_user_settings.email.off"),
              value: false,
            },
            {
              label: t("wisdomtree.modal_user_settings.email.on"),
              value: true,
            },
          ]}
          onChange={(event) => {
            handleUserNotificationSettings("USAGE_TIP", event.target.value);
          }}
        />
      </div>
      <div className={styles.emailSettingContainer}>
        <div
          className={`${styles.settingTitle} ${styles.emailSettingTitleContainer}`}
        >
          <span className={`${styles.emailSettingTitleText}`}>
            {t("wisdomtree.modal_user_settings.email.usage_review.title")}
          </span>
          <InfoIcon
            content={
              <p>
                {t(
                  "wisdomtree.modal_user_settings.email.usage_review.description"
                )}
              </p>
            }
          />
        </div>
        <RadioButtons
          name={"usageReview"}
          selectedValue={getNotificationSetting("USAGE_REVIEW").active}
          options={[
            {
              label: t("wisdomtree.modal_user_settings.email.off"),
              value: false,
            },
            {
              label: t("wisdomtree.modal_user_settings.email.on"),
              value: true,
            },
          ]}
          onChange={(event) => {
            handleUserNotificationSettings("USAGE_REVIEW", event.target.value);
          }}
        />
      </div>
    </div>
  );

  const exportContent = (
    <div className={`${styles.settingContainer} ${styles.exportContainer}`}>
      <DataExport />
    </div>
  );

  let bodyContent = null;
  if (getUserLoading) {
    bodyContent = <Spinner />;
  } else {
    let activeTabContent = null;
    if (activeTab === "general") {
      activeTabContent = (
        <>
          <div className={styles.settingsGroup}>
            {languageSettingContent}
            {themeSettingContent}
            {shareEntryNamesSettingContent}
            {enableDailyQuotesContent}
          </div>
          <div className={styles.settingsGroup}>
            <div className={styles.titleContainer}>
              <span className={`${styles.groupTitle} ${styles.exportTitle}`}>
                {t("wisdomtree.modal_user_settings.export.title")}
              </span>
              <InfoIcon
                content={
                  <p>
                    {t("wisdomtree.modal_user_settings.export.description")}
                  </p>
                }
              />
            </div>
            {exportContent}
          </div>
        </>
      );
    } else if (activeTab === "notifications") {
      activeTabContent = (
        <>
          {pushSettingsContent}
          <div className={styles.settingsGroup}>
            <span className={styles.groupTitle}>
              {t("wisdomtree.modal_user_settings.email.title")}
            </span>
            {emailSettingsContent}
          </div>
        </>
      );
    }

    bodyContent = (
      <div>
        <div className="tabs">
          <ul>
            <li className={`${activeTab === "general" ? "is-active" : null}`}>
              <a
                onClick={() => setActiveTab("general")}
                data-id="settings.general"
              >
                {t("wisdomtree.modal_user_settings.tab_general")}
              </a>
            </li>
            <li
              className={`${
                activeTab === "notifications" ? "is-active" : null
              }`}
            >
              <a
                onClick={() => setActiveTab("notifications")}
                data-id="settings.notifications"
              >
                {t("wisdomtree.modal_user_settings.tab_notifications")}
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.activeTabContainer}>{activeTabContent}</div>
      </div>
    );
  }

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_user_settings.title")}
          </p>
          <ModalCloseButton onClick={onCloseHandler} />
        </header>
        <section className="modal-card-body">{bodyContent}</section>
        <footer className="modal-card-foot">
          <button className="button" type="button" onClick={onCloseHandler}>
            {t("wisdomtree.modal_user_settings.confirm")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

UserSettingsModal.propTypes = propTypesModal;
UserSettingsModal.defaultProps = defaultPropsModal;

export default useUserSettingsModal;
