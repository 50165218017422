import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../config/apollo-cache";

const propTypesuseAuthentication = {};
const defaultPropsuseAuthentication = {};

const useAuthentication = () => {
  const loggedIn = useReactiveVar(isLoggedInVar);

  return loggedIn;
};

useAuthentication.propTypes = propTypesuseAuthentication;
useAuthentication.defaultProps = defaultPropsuseAuthentication;

export default useAuthentication;
