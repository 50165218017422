import React from "react";
import PropTypes from "prop-types";

import styles from "./ImageGradient.module.scss";

const propTypesImageGradient = {};
const defaultPropsImageGradient = {};

const ImageGradient = () => {
  return <div className={styles.container} />;
};

ImageGradient.propTypes = propTypesImageGradient;
ImageGradient.defaultProps = defaultPropsImageGradient;

export default ImageGradient;
