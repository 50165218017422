/* eslint-disable import/no-cycle */
import { gql } from "@apollo/client";

import {
  FRAGMENT_COMPLETE_TAG,
  FRAGMENT_USER_UPDATE,
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
} from "./fragments";
import { mergeEntryUpdates } from "./entries";
import { mergeBlockUpdates } from "./blocks";
import { mergeUserUpdates, processExpEvent } from "./user";

const GET_TAGS = gql`
  query {
    tags {
      ...CompleteTag
    }
  }
  ${FRAGMENT_COMPLETE_TAG}
`;

const UPDATE_TAG = gql`
  mutation($name: String!, $input: UpdateTagInput!) {
    updateTag(name: $name, input: $input) {
      code
      message
      updatedTag {
        ...CompleteTag
      }
      updatedEntries {
        id
        tags {
          ...CompleteTag
        }
      }
      updatedBlocks {
        id
        tags {
          ...CompleteTag
        }
        entry {
          id
        }
      }
      updatedUser {
        ...UserUpdate
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_TAG}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const DELETE_TAG = gql`
  mutation($name: String!) {
    deleteTag(name: $name) {
      code
      message
      tags {
        ...CompleteTag
      }
      updatedEntries {
        id
        tags {
          ...CompleteTag
        }
      }
      updatedBlocks {
        id
        tags {
          ...CompleteTag
        }
        entry {
          id
        }
      }
    }
  }
  ${FRAGMENT_COMPLETE_TAG}
`;

const mergeCreatedTagsUpdates = (cache, createdTags) => {
  const cachedData = cache.readQuery({
    query: GET_TAGS,
  });

  const cachedTags = cachedData.tags;

  // merge updates into to current data
  const updatedData = cachedTags.concat(createdTags);

  cache.writeQuery({
    query: GET_TAGS,
    data: {
      tags: updatedData,
    },
  });
};

const mergeTagsAfterUpdate = (cache, mutationResult) => {
  const { updatedEntries } = mutationResult.data.updateTag;
  const { updatedBlocks } = mutationResult.data.updateTag;
  const { updatedUser } = mutationResult.data.updateTag;
  const { expEvent } = mutationResult.data.updateTag;

  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }
};

const mergeTagsAfterDelete = (cache, mutationResult) => {
  const { tags } = mutationResult.data.deleteTag;
  const { updatedEntries } = mutationResult.data.deleteTag;
  const { updatedBlocks } = mutationResult.data.deleteTag;

  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }

  cache.writeQuery({
    query: GET_TAGS,
    data: {
      tags,
    },
  });
};

const mergeTagUpdates = (cache, updatedTags) => {
  try {
    const { tags: currentData } = cache.readQuery({
      query: GET_TAGS,
    });

    const updatedData = currentData.map((e) => {
      const updatedTag = updatedTags.find((ue) => e.id === ue.id);
      if (updatedTag !== null) {
        return {
          ...e,
          ...updatedTag,
        };
      }
      return e;
    });

    cache.writeQuery({
      query: GET_TAGS,
      data: { tags: updatedData },
    });
  } catch (error) {
    // not cached
  }
};

export {
  FRAGMENT_COMPLETE_TAG,
  GET_TAGS,
  UPDATE_TAG,
  DELETE_TAG,
  mergeCreatedTagsUpdates,
  mergeTagsAfterDelete,
  mergeTagsAfterUpdate,
  mergeTagUpdates,
};
