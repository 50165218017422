/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "./Message.module.scss";
import UserImage from "./UserImage/UserImage";
import { TooltipDelayedOnTop } from "../../../Tooltip/Tooltip";

const propTypesMessage = {
  message: PropTypes.shape({
    text: PropTypes.string,
    createdAt: PropTypes.string,
    fromUser: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }).isRequired,
  fromCurrentUser: PropTypes.bool.isRequired,
  lastMessage: PropTypes.bool,
  useImage: PropTypes.bool,
};
const defaultPropsMessage = {
  lastMessage: true,
  useImage: true,
};

const Message = ({ message, fromCurrentUser, lastMessage, useImage }) => {
  const userImage = (
    <TooltipDelayedOnTop content={`${message.fromUser.name}`}>
      <div>
        <UserImage user={message.fromUser} />
      </div>
    </TooltipDelayedOnTop>
  );

  const multilineText = message.text.split("\\n").map((text, i) => {
    let textNode = text;

    const regexFindLinks = /\[(.*?)\]\((.+?)\)/g;
    const linkMatches = text.match(regexFindLinks);

    if (linkMatches) {
      let textToProcess = text;
      textNode = [];

      const regexFindLinkDetails = /\[(.*?)\]\((.+?)\)/;
      for (const l of linkMatches) {
        const linkDetails = l.match(regexFindLinkDetails);
        const [full, urlText, url] = linkDetails;
        const index = textToProcess.indexOf(full);

        const linkNode = (
          <a
            key={`${i}${index}`}
            className={styles.linkText}
            href={url}
            target="_blank"
          >
            {urlText}
          </a>
        );
        textNode.push(textToProcess.substring(0, index));
        textNode.push(linkNode);
        textToProcess = textToProcess.slice(
          index + full.length,
          textToProcess.length
        );
      }

      if (textToProcess.length > 0) {
        textNode.push(textToProcess);
      }
    }

    return i ? [<br key={i} />, textNode] : textNode;
  });

  return (
    <div
      className={`${styles.container} ${
        !lastMessage && useImage ? styles.noImage : null
      } ${fromCurrentUser ? styles.fromSelf : styles.fromOther} ${
        lastMessage ? styles.lastMessage : null
      }`}
    >
      {useImage && lastMessage && !fromCurrentUser ? (
        <div className={`${styles.image} ${styles.imageOther}`}>
          {userImage}
        </div>
      ) : null}
      <TooltipDelayedOnTop
        content={moment(message.createdAt, "x").format("LLL")}
      >
        <div
          className={`${styles.messageContainer} ${
            fromCurrentUser ? styles.messageSelf : styles.messageOther
          }`}
        >
          {multilineText}
        </div>
      </TooltipDelayedOnTop>
      {useImage && lastMessage && fromCurrentUser ? (
        <div className={`${styles.image} ${styles.imageSelf}`}>{userImage}</div>
      ) : null}
    </div>
  );
};

Message.propTypes = propTypesMessage;
Message.defaultProps = defaultPropsMessage;

export default Message;
