import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./Level.module.scss";

const propTypesLevel = {
  level: PropTypes.number.isRequired,
  noTitle: PropTypes.bool,
};
const defaultPropsLevel = {
  noTitle: false,
};

const Level = ({ level, noTitle }) => {
  const { t } = useTranslation();

  const levelText = t("wisdomtree.gamification.level", {
    level,
  });
  return (
    <div className={styles.container}>
      <svg width="68" height="22">
        <rect width="64" height="20" rx="10" ry="10" x="1" y="1" />
        <text
          className={styles.backgroundText}
          x="33"
          y="10"
          dominantBaseline="central"
        >
          {levelText}
        </text>
        <text
          className={styles.foregroundText}
          x="33"
          y="10"
          dominantBaseline="central"
        >
          {levelText}
        </text>
      </svg>

      {!noTitle ? (
        <span className={styles.titleText}>
          {t(`wisdomtree.gamification.titles.${Math.min(level, 10)}`)}
        </span>
      ) : null}
    </div>
  );
};

Level.propTypes = propTypesLevel;
Level.defaultProps = defaultPropsLevel;

export default Level;
