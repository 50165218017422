import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import styles from "./WelcomeTutorial.module.scss";
import useTutorialModal from "../TutorialModal";
import { useMutation } from "@apollo/client";
import {
  CONFIRM_WELCOME,
  mergeUserAfterConfirmWelcome,
} from "../../../../GraphQl/user";
import Logger from "js-logger";
import { useTranslation } from "react-i18next";

const propTypesWelcomeTutorial = {
  setDialogOpened: PropTypes.func,
  user: PropTypes.shape({
    name: PropTypes.string,
    welcomeConfirmed: PropTypes.bool,
  }).isRequired,
};
const defaultPropsWelcomeTutorial = {
  setDialogOpened: () => {},
};

const WelcomeTutorial = ({ user, setDialogOpened }) => {
  const { t } = useTranslation();
  const [introVideoHighestProgress, setIntroVideoHighestProgress] = useState(0);
  const [selectedJobToBeDone, setSelectedJobToBedone] = useState(null);

  const introVideoHighestProgressRef = useRef(introVideoHighestProgress);
  const selectedJobToBeDoneRef = useRef(selectedJobToBeDone);

  const [confirmWelcome] = useMutation(CONFIRM_WELCOME, {
    update: (cache, mutationResult) => {
      mergeUserAfterConfirmWelcome(cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  const {
    open: openTutorialModal,
    content: tutorialModalContent,
  } = useTutorialModal({
    onOpen: () => setDialogOpened(true),
    onClose: () => setDialogOpened(false),
  });

  useEffect(() => {
    setTimeout(() => {
      const firstName = user.name.split(" ")[0];
      const greeting = user.welcomeConfirmed
        ? t("wisdomtree.modal_welcome.greeting_second", {
            first_name: firstName,
          })
        : t("wisdomtree.modal_welcome.greeting", {
            first_name: firstName,
          });

      openTutorialModal({
        cancelable: false,
        content: [
          {
            type: "intro_video",
            videoUrl: "https://www.youtube.com/watch?v=OvBAb44J_1s",
            text: greeting,
            onHighestProgressChanged: (progress) => {
              setIntroVideoHighestProgress(progress);
              introVideoHighestProgressRef.current = progress;
            },
          },
          {
            type: "job_to_be_done",
            title: t("wisdomtree.modal_welcome.jtbd_prompt"),
            selectedJobToBeDone: () => selectedJobToBeDoneRef.current,
            onSelectJobToBeDone: (job) => {
              setSelectedJobToBedone(job);
              selectedJobToBeDoneRef.current = job;
            },
            isNextEnabled: () => {
              return selectedJobToBeDoneRef.current != null;
            },
            nextText: t("wisdomtree.modal_welcome.confirm"),
          },
        ],
        onFinish: async () => {
          await confirmWelcome({
            variables: {
              input: {
                videoProgressPercent: introVideoHighestProgressRef.current,
                jobToBeDone: selectedJobToBeDoneRef.current,
              },
            },
          });
        },
      });
    }, 50);
  }, []);

  return tutorialModalContent;
};

WelcomeTutorial.propTypes = propTypesWelcomeTutorial;
WelcomeTutorial.defaultProps = defaultPropsWelcomeTutorial;

export default WelcomeTutorial;
