import tippy, { sticky } from "tippy.js";

class EntryContextMenu {
  constructor(
    graphConfig,
    isTabletOrMobile,
    onNewEntry,
    isEntryContextMenuEnabled
  ) {
    this.graphConfig = graphConfig;
    this.isTabletOrMobile = isTabletOrMobile;
    this.onNewEntry = onNewEntry;
    this.isEntryContextMenuEnabled = isEntryContextMenuEnabled;
  }

  showOn(selector, entryId) {
    if (this.tippy) this.tippy.destroy();

    const template = document.getElementById("template");
    const copy = template.cloneNode(true);
    copy.style.display = "block";

    const button = copy.getElementsByClassName("button");
    button[0].addEventListener("click", () => {
      this.onNewEntry(entryId);
      this.tippy.hide();
    });

    this.tippy = tippy(selector, {
      plugins: [sticky],
      theme: "graph",
      arrow: false,
      content: copy,
      allowHTML: true,
      interactive: true,
      interactiveBorder: 30,
      offset: [0, 0],
      zIndex: 20,
      trigger: this.isTabletOrMobile() ? "click" : "mouseenter focus",
      hideOnClick: this.isTabletOrMobile(),
      appendTo: () => document.body,
      placement: entryId ? "right" : "bottom",
      sticky: true,
      onShow: () => {
        if (!this.isEntryContextMenuEnabled()) return false;
        return true;
      },
    });
  }

  hide() {
    if (this.tippy) this.tippy.hide();
  }
}

export default EntryContextMenu;
