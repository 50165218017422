/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";

import styles from "./ModalCloseButton.module.scss";

const propTypesModalCloseButton = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
const defaultPropsModalCloseButton = {
  disabled: false,
};

const ModalCloseButton = ({ onClick, disabled }) => {
  const onClickHandler = () => {
    if (disabled) return;
    onClick();
  };

  return (
    <div
      className={styles.container}
      onClick={onClickHandler}
      role="button"
      tabIndex={-1}
    >
      <button
        className="delete"
        type="button"
        aria-label="close"
        onClick={onClickHandler}
        disabled={disabled}
      />
    </div>
  );
};

ModalCloseButton.propTypes = propTypesModalCloseButton;
ModalCloseButton.defaultProps = defaultPropsModalCloseButton;

export default ModalCloseButton;
