/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import styles from "./DeleteFeatureRequestCommentModal.module.scss";
import BaseModal, { useBaseModal } from "../../../UI/Modal/BaseModal/BaseModal";
import ModalCloseButton from "../../../UI/Modal/ModalCloseButton/ModalCloseButton";
import { useTranslation } from "react-i18next";
import { DELETE_FEATURE_REQUEST_COMMENT } from "../../../GraphQl/roadmap";
import { useMutation } from "@apollo/client";
import Logger from "js-logger";
import { useHotkey } from "../../../Utils/Hotkeys";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  onDelete: () => {},
};

const useDeleteFeatureRequestCommentModal = ({ onOpen, onClose, onDelete }) => {
  const { t } = useTranslation();
  const [featureRequestComment, setFeatureRequestComment] = useState(null);
  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose: () => {
      onClose();
    },
  });

  const openModal = (existingFeatureRequestComment) => {
    setFeatureRequestComment(existingFeatureRequestComment);
    open();
  };

  const closeModal = () => {
    close();
    setFeatureRequestComment(null);
  };

  const [
    deleteFeatureRequestComment,
    { loading: deleteFeatureRequestCommentLoading },
  ] = useMutation(DELETE_FEATURE_REQUEST_COMMENT, {
    update: (cache, mutationResult) => {},
    onError: (err) => {
      Logger.error(err);
    },
    onCompleted: () => {
      onDelete();
      closeModal();
    },
  });

  const onSubmit = () => {
    deleteFeatureRequestComment({
      variables: {
        id: featureRequestComment.id,
      },
    });
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!isOpened) return;
      onSubmit();
    },
    dependsOn: [onSubmit, isOpened],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!isOpened) return;
      closeModal();
    },
    dependsOn: [closeModal, isOpened],
  });

  let content = null;
  if (isOpened) {
    let innerContent = null;

    innerContent = (
      <div className={`modal-card`}>
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.roadmap.modal_delete_feature_request_comment.title")}
          </p>
          <ModalCloseButton onClick={closeModal} />
        </header>
        <section className={`modal-card-body ${styles.body}`}>
          <p>
            {t(
              "wisdomtree.roadmap.modal_delete_feature_request_comment.warning"
            )}
          </p>
        </section>
        <footer className="modal-card-foot">
          <button
            id="submitButton"
            className={`button is-danger ${
              deleteFeatureRequestCommentLoading ? `is-loading` : ``
            }`}
            type="submit"
            onClick={() => {
              onSubmit();
            }}
          >
            {t("wisdomtree.roadmap.modal_delete_feature_request_comment.done")}
          </button>
          <button className="button" type="button" onClick={closeModal}>
            {t(
              "wisdomtree.roadmap.modal_delete_feature_request_comment.cancel"
            )}
          </button>
        </footer>
      </div>
    );

    content = (
      <BaseModal show={isOpened} onCloseHandler={closeModal}>
        {innerContent}
      </BaseModal>
    );
  }

  return { open: openModal, close: closeModal, content };
};

useDeleteFeatureRequestCommentModal.propTypes = propTypes;
useDeleteFeatureRequestCommentModal.defaultProps = defaultProps;

export default useDeleteFeatureRequestCommentModal;
