import { useQuery, gql, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { themeSetting as themeSettingVar } from "../../config/apollo-cache";

const propTypesuseDarkMode = {};
const defaultPropsuseDarkMode = {};

const useDarkMode = () => {
  const location = useLocation();

  const [osThemeDark, setOsThemeDark] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  const themeSetting = useReactiveVar(themeSettingVar);

  const darkMode =
    themeSetting &&
    (themeSetting === "DARK" || (themeSetting === "SYSTEM" && osThemeDark)) &&
    !location.pathname.includes("auth");

  useEffect(() => {
    const listener = function(e) {
      setOsThemeDark(e.matches);
    };

    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    matchMedia.addListener(listener);

    return () => {
      matchMedia.removeListener(listener);
    };
  }, []);

  return darkMode;
};

useDarkMode.propTypes = propTypesuseDarkMode;
useDarkMode.defaultProps = defaultPropsuseDarkMode;

export default useDarkMode;
