/* eslint-disable import/no-cycle */
import { gql } from "@apollo/client";
import { sortBy } from "lodash/collection";

import {
  FRAGMENT_DETAILED_ENTRY,
  FRAGMENT_COMPLETE_BLOCK,
  FRAGMENT_COMPLETE_CARD,
  FRAGMENT_COMPLETE_TAG,
  FRAGMENT_USER_UPDATE,
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
} from "./fragments";
import { GET_ENTRY_DETAILS, mergeEntryUpdates } from "./entries";
import { mergeUserUpdates, processExpEvent } from "./user";
import { mergeCreatedTagsUpdates } from "./tags";

const GET_BLOCK = gql`
  query($id: ID!) {
    block(id: $id) {
      ...CompleteBlock
    }
  }
  ${FRAGMENT_COMPLETE_BLOCK}
`;

const GET_BLOCK_CONTENT = gql`
  query($id: ID!) {
    block(id: $id) {
      id
      content
    }
  }
`;

const GET_BLOCK_WITH_CARDS = gql`
  query($id: ID!) {
    block(id: $id) {
      ...CompleteBlock
      cards {
        ...CompleteCard
      }
    }
  }
  ${FRAGMENT_COMPLETE_BLOCK}
  ${FRAGMENT_COMPLETE_CARD}
`;

const UPDATE_BLOCK = gql`
  mutation($id: ID!, $input: UpdateBlockInput!) {
    updateBlock(id: $id, input: $input) {
      code
      message
      updatedBlocks {
        ...CompleteBlock
      }
      updatedEntries {
        id
        size
        cardsActiveAmountRecursive
        cardsDueAmountRecursive
      }
      updatedUser {
        ...UserUpdate
        cardsActiveAmount
        cardsDueAmount
      }
      createdTags {
        ...CompleteTag
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_BLOCK}
  ${FRAGMENT_COMPLETE_TAG}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const CREATE_BLOCK = gql`
  mutation($input: CreateBlockInput!) {
    createBlock(input: $input) {
      code
      message
      block {
        ...CompleteBlock
      }
      updatedEntries {
        id
        size
      }
      updatedBlocks {
        ...CompleteBlock
      }
      updatedUser {
        ...UserUpdate
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_BLOCK}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const DELETE_BLOCK = gql`
  mutation($id: ID!) {
    deleteBlock(id: $id) {
      code
      message
      deletedBlockIds
      updatedBlocks {
        ...CompleteBlock
      }
      updatedEntries {
        id
        size
        cardsActiveAmountRecursive
        cardsDueAmountRecursive
        ...DetailedEntry
      }
      updatedUser {
        ...UserUpdate
        cardsActiveAmount
        cardsDueAmount
      }
    }
  }
  ${FRAGMENT_COMPLETE_BLOCK}
  ${FRAGMENT_DETAILED_ENTRY}
  ${FRAGMENT_USER_UPDATE}
`;

const VIEW_BLOCK = gql`
  mutation ViewBlock($id: ID!) {
    viewBlock(id: $id) {
      code
      message
      updatedBlocks {
        id
        dust
        dusted
      }
      updatedEntries {
        ...DetailedEntry
      }
      updatedUser {
        ...UserUpdate
      }
      expEvent {
        ...CompleteUserExpEvent
      }
    }
  }
  ${FRAGMENT_COMPLETE_BLOCK}
  ${FRAGMENT_DETAILED_ENTRY}
  ${FRAGMENT_USER_UPDATE}
  ${FRAGMENT_COMPLETE_USER_EXP_EVENT}
`;

const mergeBlocksAfterUpdate = (entryId, cache, mutationResult) => {
  let cachedQuery = null;
  try {
    cachedQuery = cache.readQuery({
      query: GET_ENTRY_DETAILS,
      variables: {
        id: entryId,
      },
    });
  } catch (err) {
    // cached data does not exist
  }
  if (!cachedQuery) return;

  const {
    entry: { blocks: currentData },
  } = cachedQuery;

  const { updatedBlocks } = mutationResult.data.updateBlock;
  const { updatedEntries } = mutationResult.data.updateBlock;
  const { updatedUser } = mutationResult.data.updateBlock;
  const { expEvent } = mutationResult.data.updateBlock;
  const { createdTags } = mutationResult.data.updateBlock;

  // replace updated blocks in current data
  let updatedData = currentData.map((e) => {
    const match = updatedBlocks.find((ue) => e.id === ue.id);
    if (match) {
      return {
        ...e,
        ...match,
      };
    }
    return e;
  });

  const movedBlocks = updatedData.filter((block) => block.entryId !== entryId);
  if (movedBlocks.length > 0) {
    updatedData = updatedData.filter(
      (block) => movedBlocks.find((b) => b.id === block.id) == null
    );

    movedBlocks.forEach((movedBlock) => {
      insertBlockIntoEntryCache(cache, movedBlock.entryId, movedBlock);
    });
  }

  updatedData = sortBy(updatedData, ["position"]);

  // merge entry updates (needed because of cardsDue)
  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }

  if (createdTags.length > 0) {
    mergeCreatedTagsUpdates(cache, createdTags);
  }

  cache.writeQuery({
    query: GET_ENTRY_DETAILS,
    variables: {
      id: entryId,
    },
    data: {
      entry: {
        __typename: "Entry",
        id: entryId,
        blocks: updatedData,
      },
    },
  });
};

const insertBlockIntoEntryCache = (cache, entryId, block) => {
  let cachedQuery = null;
  try {
    cachedQuery = cache.readQuery({
      query: GET_ENTRY_DETAILS,
      variables: {
        id: entryId,
      },
    });
  } catch (err) {
    // cached data does not exist
  }

  if (!cachedQuery) return;

  const {
    entry: { blocks: currentDataOtherEntry },
  } = cachedQuery;

  let updatedDataOtherEntry = [...currentDataOtherEntry, block];
  updatedDataOtherEntry = sortBy(updatedDataOtherEntry, ["position"]);

  cache.writeQuery({
    query: GET_ENTRY_DETAILS,
    variables: {
      id: entryId,
    },
    data: {
      entry: {
        __typename: "Entry",
        id: entryId,
        blocks: updatedDataOtherEntry,
      },
    },
  });
};

const mergeBlocksAfterCreate = (entryId, cache, mutationResult) => {
  const {
    entry: { blocks: currentData },
  } = cache.readQuery({
    query: GET_ENTRY_DETAILS,
    variables: {
      id: entryId,
    },
  });
  const { block } = mutationResult.data.createBlock;
  const { updatedEntries } = mutationResult.data.createBlock;
  const { updatedBlocks } = mutationResult.data.createBlock;
  const { updatedUser } = mutationResult.data.createBlock;
  const { expEvent } = mutationResult.data.createBlock;

  // add created block to current data
  let updatedData = currentData.concat([block]);

  // replace updated blocks in current data
  updatedData = updatedData.map(
    (e) => updatedBlocks.find((ue) => e.id === ue.id) || e
  );

  updatedData = sortBy(updatedData, ["position"]);

  // merge entry updates (needed because of size)
  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }

  cache.writeQuery({
    query: GET_ENTRY_DETAILS,
    variables: {
      id: entryId,
    },
    data: {
      entry: {
        __typename: "Entry",
        id: entryId,
        blocks: updatedData,
      },
    },
  });
};

const mergeBlocksAfterDelete = (entryId, cache, mutationResult) => {
  const {
    entry: { blocks: currentData },
  } = cache.readQuery({
    query: GET_ENTRY_DETAILS,
    variables: {
      id: entryId,
    },
  });
  const { deletedBlockIds } = mutationResult.data.deleteBlock;
  const { updatedBlocks } = mutationResult.data.deleteBlock;
  const { updatedEntries } = mutationResult.data.deleteBlock;
  const { updatedUser } = mutationResult.data.deleteBlock;

  // filter out deleted block ids
  let updatedData = currentData.filter(
    (e) => deletedBlockIds.indexOf(e.id) === -1
  );

  // replace updated blocks in current data
  updatedData = updatedData.map(
    (e) => updatedBlocks.find((ue) => e.id === ue.id) || e
  );

  // merge entry updates (needed because of cardsDue, and references)
  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  cache.writeQuery({
    query: GET_ENTRY_DETAILS,
    variables: {
      id: entryId,
    },
    data: {
      entry: {
        __typename: "Entry",
        id: entryId,
        blocks: updatedData,
      },
    },
  });
};

const mergeAfterViewBlock = (cache, mutationResult) => {
  const { updatedBlocks } = mutationResult.data.viewBlock;
  const { updatedEntries } = mutationResult.data.viewBlock;
  const { updatedUser } = mutationResult.data.viewBlock;
  const { expEvent } = mutationResult.data.viewBlock;

  if (updatedBlocks.length > 0) {
    mergeBlockUpdates(cache, updatedBlocks);
  }

  if (updatedEntries.length > 0) {
    mergeEntryUpdates(cache, updatedEntries);
  }

  if (updatedUser) {
    mergeUserUpdates(cache, updatedUser);
  }

  if (expEvent) {
    processExpEvent(expEvent);
  }
};

const mergeBlockUpdates = (cache, updatedBlocks) => {
  updatedBlocks.forEach((updatedBlock) => {
    try {
      const {
        entry: { blocks: currentData },
      } = cache.readQuery({
        query: GET_ENTRY_DETAILS,
        variables: {
          id: updatedBlock.entry.id,
        },
      });

      const updatedData = currentData.map((b) => {
        if (b.id === updatedBlock.id) {
          return {
            ...b,
            ...updatedBlock,
          };
        }
        return b;
      });

      cache.writeQuery({
        query: GET_ENTRY_DETAILS,
        variables: {
          id: updatedBlock.entry.id,
        },
        data: {
          entry: {
            __typename: "Entry",
            id: updatedBlock.entry.id,
            blocks: updatedData,
          },
        },
      });
    } catch (err) {
      // block not cached
      // console.error(err);
    }
  });
};

const getOptimisticResponseForMoveBlock = (blockId, newEntryId) => {
  const optimisticUpdate = [
    { __typename: "Block", id: blockId, entryId: newEntryId, position: 9999 },
  ];

  return {
    __typename: "Mutation",
    updateBlock: {
      __typename: "UpdateBlockResponse",
      code: 200,
      success: true,
      message: "block updated",
      updatedBlocks: optimisticUpdate,
      updatedEntries: [],
      updatedUser: [],
      createdTags: [],
    },
  };
};

export {
  GET_BLOCK_WITH_CARDS,
  GET_BLOCK,
  GET_BLOCK_CONTENT,
  CREATE_BLOCK,
  UPDATE_BLOCK,
  DELETE_BLOCK,
  VIEW_BLOCK,
  mergeBlocksAfterCreate,
  mergeBlocksAfterUpdate,
  mergeBlocksAfterDelete,
  mergeAfterViewBlock,
  mergeBlockUpdates,
  getOptimisticResponseForMoveBlock,
};
