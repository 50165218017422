import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { throttle } from "lodash";

import styles from "./ScrollDownButton.module.scss";

const propTypesScrollDownButton = {
  quizMode: PropTypes.bool,
  newMode: PropTypes.bool,
  onScrollDownClicked: PropTypes.func.isRequired,
};
const defaultPropsScrollDownButton = {
  quizMode: false,
  quizMode: false,
  newMode: false,
};

const ScrollDownButton = ({ quizMode, newMode, onScrollDownClicked }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const visibleRef = useRef(visible);

  useEffect(() => {
    window.addEventListener("scroll", throttle(handleWindowScroll, 10));
    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);

  useEffect(() => {
    visibleRef.current = visible;
  }, [visible]);

  const handleWindowScroll = () => {
    if (window.scrollY > 50) {
      if (visibleRef.current) {
        setVisible(false);
      }
    }

    if (window.scrollY < 50) {
      if (!visibleRef.current) {
        setVisible(true);
      }
    }
  };

  const getScrollDownButtonClasses = () => {
    let visibilityStyle;
    if (quizMode) {
      visibilityStyle = styles.invisible;
    } else if (newMode) {
      visibilityStyle = styles.invisible;
    } else {
      visibilityStyle = visible ? styles.visible : styles.invisible;
    }

    let position;
    if (quizMode) {
      position = styles.quizPosition;
    } else {
      position = styles.defaultPosition;
    }

    return `${styles.container} ${visibilityStyle} ${position}`;
  };

  return (
    <div className={getScrollDownButtonClasses()}>
      <button
        type="button"
        className={`button ${styles.button}`}
        onClick={() => {
          onScrollDownClicked();
          document.activeElement.blur();
        }}
        data-id="graph.scrollDownButton"
      >
        <span className={`icon ${styles.icon}`}>
          <i className={`fas fa-angle-double-down`} />
        </span>
      </button>
    </div>
  );
};

ScrollDownButton.propTypes = propTypesScrollDownButton;
ScrollDownButton.defaultProps = defaultPropsScrollDownButton;

export default ScrollDownButton;
