/* eslint-disable func-names */
/* eslint-disable prefer-destructuring */
export default {
  spaceToLink: {
    collapsed: true,
    key: " ",
    prefix: /https?:\/\/[^\s]+/,
    handler: (() => {
      let prevOffset = 0;
      return function(range) {
        let url;
        const regex = /https?:\/\/[^\s]+/g;
        const text = this.quill.getText(prevOffset, range.index);
        const match = text.match(regex);

        if (match === null) {
          prevOffset = range.index;
          return true;
        }
        if (match.length > 1) {
          url = match[match.length - 1];
        } else {
          url = match[0];
        }
        const ops = [];
        // only retain if non-zero. link is in the beginning
        if (range.index - url.length) {
          ops.push({ retain: range.index - url.length });
        }
        ops.push({ delete: url.length });
        ops.push({ insert: url, attributes: { link: url } });
        this.quill.updateContents({ ops });
        prevOffset = range.index;
        return true;
      };
    })()
  }
};
