import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import BrowserInteractionTime from "browser-interaction-time";
import moment from "moment";
import { POST_USAGE_TIME } from "../GraphQl/usageTimes";
import { useMutation } from "@apollo/client";
import Logger from "js-logger";
import useEventListener from "./UseEventListener";

const propTypesuseTimeTracking = {
  entryId: PropTypes.string,
  active: PropTypes.bool,
};
const defaultPropsuseTimeTracking = {
  entryId: null,
  active: true,
};

const useTimeTracking = ({ active, entryId }) => {
  const SEND_INTERVAL = 30;
  const SEND_MINIMUM = 5;

  const [timeTrackerApi] = useState(
    new BrowserInteractionTime({
      timeIntervalEllapsedCallbacks: [],
      absoluteTimeEllapsedCallbacks: [],
      browserTabInactiveCallbacks: [],
      browserTabActiveCallbacks: [],
      idleTimeoutMs: 15000,
      checkCallbacksIntervalMs: 1000,
      stopTimerOnTabchange: false,
    })
  );

  const [currentEntryId, setCurrentEntryId] = useState(undefined);
  const [startedAt, setStartedAt] = useState(null);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const [lastSecondsSentToBackend, setLastSecondsSentToBackend] = useState(0);
  const [documentHidden, setDocumentHidden] = useState(false);
  const sendTimeToBackendRef = useRef(null);

  const handleVisibilityChanged = () => {
    // console.log({ hidden: document.hidden });
    setDocumentHidden(document.hidden);
  };
  useEventListener("visibilitychange", handleVisibilityChanged);

  const currentSecondsRef = useRef(currentSeconds);

  const [postUsageTime, { loading: postUsageTimeLoading }] = useMutation(
    POST_USAGE_TIME,
    {
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {},
    }
  );

  const sendTimeToBackend = () => {
    if (!active) return;
    if (currentSeconds < SEND_MINIMUM) return;
    if (currentSeconds == lastSecondsSentToBackend) return;
    // console.log(
    //   `SEND TO BACKEND: ${currentSeconds} for entry ${currentEntryId}`
    // );

    let input = {
      startedAt: startedAt,
      seconds: currentSeconds,
    };
    if (currentEntryId != null) input.entryId = currentEntryId;

    postUsageTime({
      variables: {
        input,
      },
    });
    setLastSecondsSentToBackend(currentSeconds);
  };
  sendTimeToBackendRef.current = sendTimeToBackend;

  useEffect(() => {
    if (currentSeconds - lastSecondsSentToBackend >= SEND_INTERVAL) {
      sendTimeToBackend();
    }

    currentSecondsRef.current = currentSeconds;
    // console.log({ currentSeconds });
  }, [currentSeconds]);

  useEffect(() => {
    if (documentHidden) {
      timeTrackerApi.stopTimer();
      sendTimeToBackend();
    } else {
      timeTrackerApi.startTimer();
    }
  }, [documentHidden]);

  useEffect(() => {
    // console.log(`init`);

    if (!active) return;
    timeTrackerApi.addTimeIntervalEllapsedCallback({
      multiplier: (time) => {
        return time + 5000;
      },
      timeInMilliseconds: 5000,
      callback: (time) => {
        setCurrentSeconds(Math.round(time / 1000));
      },
    });

    return () => {
      // console.log(`destroy`);
      sendTimeToBackendRef.current();
      timeTrackerApi.destroy();
    };
  }, []);

  useEffect(() => {
    if (!active) return;

    if (timeTrackerApi.isRunning()) {
      timeTrackerApi.stopTimer();
      sendTimeToBackend();
      // console.log(`end (${currentEntryId})`);
    }

    // console.log(`start (${entryId})`);

    timeTrackerApi.reset();
    timeTrackerApi.timeIntervalEllapsedCallbacks[0].timeInMilliseconds = 5000;

    setCurrentEntryId(entryId);
    setStartedAt(moment().toISOString());
    setCurrentSeconds(0);
    setLastSecondsSentToBackend(0);

    timeTrackerApi.startTimer();
  }, [entryId]);
};

useTimeTracking.propTypes = propTypesuseTimeTracking;
useTimeTracking.defaultProps = defaultPropsuseTimeTracking;

export default useTimeTracking;
