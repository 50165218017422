/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import styles from "./SearchInput.module.scss";

const propTypesSearchInput = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired
};
const defaultPropsSearchInput = {
  placeholder: "Search...",
  autoFocus: false
};

const SearchInput = ({ value, placeholder, autoFocus, onChange, onClear }) => {
  const inputRef = useRef(null);

  return (
    <div className="control has-icons-left has-icons-right">
      <input
        data-id="search.input"
        className={`input ${styles.input}`}
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        spellCheck={false}
        autoComplete="off"
        autoFocus={autoFocus}
      />
      <span className="icon is-small is-left">
        <i className="fas fa-search" />
      </span>
      {value.length > 0 ? (
        <span
          id={styles.cancelSearch}
          className="icon is-small is-right"
          tabIndex={-1}
          onClick={() => {
            inputRef.current.focus();
            onClear();
          }}
        >
          <i className="fas fa-times" />
        </span>
      ) : null}
    </div>
  );
};

SearchInput.propTypes = propTypesSearchInput;
SearchInput.defaultProps = defaultPropsSearchInput;

export default SearchInput;
