import axios from "axios";

// ==============================================
// axios configuration
// ==============================================
axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}/api`;

axios.interceptors.request.use(
  requestConfig => {
    // console.log(requestConfig);
    // edit request config
    return requestConfig;
  },
  error => {
    // console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    // console.log(response);
    // edit response
    return response;
  },
  error => {
    // console.log(error);
    return Promise.reject(error);
  }
);
