import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Breadcrumb.module.scss";
import { truncate } from "../../../../../Utils/Utils";
import ClickableImage from "../../../../../UI/ClickableImage/ClickableImage";
import useUserProfileModal from "../../../../../UI/Modal/UserProfileModal/UserProfileModal";

const propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  selectedEntry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    parentEntry: PropTypes.number,
  }),
  sharingId: PropTypes.string,
  sharingRootEntryId: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  onDialogOpenedChanged: PropTypes.func.isRequired,
  targetWidth: PropTypes.number,
};

const defaultProps = {
  entries: [],
  selectedEntry: null,
  sharingId: null,
  sharingRootEntryId: null,
  user: null,
  targetWidth: 0,
};

const Breadcrumb = ({
  entries,
  selectedEntry,
  sharingId,
  sharingRootEntryId,
  user,
  onDialogOpenedChanged,
  targetWidth,
}) => {
  const { t } = useTranslation();

  const RESIZE_LEVEL_NONE = 0;
  const RESIZE_LEVEL_SOME = 1;
  const RESIZE_LEVEL_HIGH = 2;
  const RESIZE_LEVEL_MAX = 3;

  const guessResizeLevelForTargetWidth = (targetWidth) => {
    if (targetWidth < 160) return RESIZE_LEVEL_MAX;
    if (targetWidth < 280) return RESIZE_LEVEL_HIGH;
    if (targetWidth < 360) return RESIZE_LEVEL_SOME;
    return RESIZE_LEVEL_NONE;
  };

  const [resizeLevelNeeded, setResizeLevelNeeded] = useState(
    guessResizeLevelForTargetWidth(targetWidth)
  );

  useEffect(() => {
    setResizeLevelNeeded(guessResizeLevelForTargetWidth(targetWidth));
  }, [targetWidth]);

  const {
    open: openUserProfileModal,
    content: userProfileModalContent,
  } = useUserProfileModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
  });

  let contentBreadcrumb = [];
  let entry = selectedEntry;

  let truncateLength = 20;
  if (resizeLevelNeeded === RESIZE_LEVEL_SOME) truncateLength = 18;
  if (resizeLevelNeeded === RESIZE_LEVEL_HIGH) truncateLength = 12;
  if (resizeLevelNeeded === RESIZE_LEVEL_MAX) truncateLength = 6;

  while (entry.parentEntryId) {
    // eslint-disable-next-line no-loop-func
    entry = entries.find((e) => {
      return e.id === entry.parentEntryId;
    });

    let linkTo = `/entry/${entry.id}`;
    if (sharingId) linkTo = `/shared/${sharingId}${linkTo}`;
    const text = truncate(entry.name, truncateLength, true);

    contentBreadcrumb.push(
      <div
        className={`${styles.menuBarItem} ${styles.breadcrumb}`}
        key={entry.id}
      >
        <Link className="button is-light" to={linkTo}>
          <span className={styles.text}>{text}</span>
        </Link>
      </div>
    );
  }

  let rootBreadcrumContent = null;

  if (sharingId) {
    const userImage = (
      <ClickableImage
        imageUrl={user.image ? user.image.url : null}
        emptyIcon="fa-user"
        altText="user"
        height={26}
        width={26}
        round
        preview={false}
        editMode={false}
        onImageClicked={() => {}}
        dropAllowed={false}
      />
    );

    const userText =
      contentBreadcrumb.length === 0 ? (
        <span className={styles.userName}>{user.name}</span>
      ) : null;

    if (!sharingRootEntryId) {
      let linkTo = `/shared/${sharingId}`;

      rootBreadcrumContent = (
        <Link className="button is-light" to={linkTo}>
          {userImage}
          {userText}
        </Link>
      );
    } else {
      rootBreadcrumContent = (
        <button
          className="button is-light"
          onClick={() => openUserProfileModal(user.id)}
        >
          {userImage}
          {userText}
        </button>
      );
    }
  } else {
    let linkTo = "/home";
    const text =
      (resizeLevelNeeded >= RESIZE_LEVEL_SOME &&
        contentBreadcrumb.length > 0) ||
      resizeLevelNeeded >= RESIZE_LEVEL_MAX
        ? null
        : t("wisdomtree.home");

    rootBreadcrumContent = (
      <Link className="button is-light" to={linkTo}>
        <span className={`icon ${styles.iconGray}`}>
          <i className="fas fa-home" />
        </span>
        {text ? <span className={styles.text}>{text}</span> : null}
      </Link>
    );
  }
  contentBreadcrumb.push(
    <div className={`${styles.menuBarItem} ${styles.breadcrumb}`} key="-1">
      {rootBreadcrumContent}
    </div>
  );

  contentBreadcrumb.reverse();

  if (resizeLevelNeeded >= RESIZE_LEVEL_HIGH) {
    if (contentBreadcrumb.length > 2) {
      contentBreadcrumb = [
        contentBreadcrumb[0],
        <div key="-2" className={`${styles.breadcrumb}`}>
          <span className={styles.dots}>...</span>
        </div>,
        contentBreadcrumb[contentBreadcrumb.length - 1],
      ];
    }
  } else if (contentBreadcrumb.length > 3) {
    contentBreadcrumb = [
      contentBreadcrumb[0],
      contentBreadcrumb[1],
      <div key="-2" className={`${styles.breadcrumb}`}>
        <span className={styles.dots}>...</span>
      </div>,
      contentBreadcrumb[contentBreadcrumb.length - 1],
    ];
  }

  return (
    <div className={styles.breadcrumbContainer}>
      {contentBreadcrumb}
      {userProfileModalContent}
    </div>
  );
};

Breadcrumb.propTypes = propTypes;
Breadcrumb.defaultProps = defaultProps;

export default Breadcrumb;
