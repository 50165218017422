import React, { useState } from "react";
import PropTypes from "prop-types";
import { useGoogleLogin } from "react-google-login";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import styles from "./GoogleLoginWrapper.module.scss";
import GoogleIcon from "./google-icon.svg";
import { gql, useMutation } from "@apollo/client";
import Logger from "js-logger";
import { toast } from "react-toastify";
import { isLoggedInVar } from "../../../config/apollo-cache";
import { useTranslation } from "react-i18next";
import {
  googleLoginClicked,
  isMobileApp,
} from "../../Utils/ReactNativeMessageUtils";
import useReactNativeEvents from "../../Utils/ReactNativeEventListener";

const LOGIN_USER_GOOGLE = gql`
  mutation($input: LoginUserSocialInput!) {
    loginUserSocial(input: $input) {
      code
      message
      token
    }
  }
`;

const propTypesGoogleLoginWrapper = {
  text: PropTypes.string.isRequired,
};
const defaultPropsGoogleLoginWrapper = {};

const GoogleLoginWrapper = ({ text }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const [inviteCode, setInviteCode] = useState(
    queryString.parse(location.search).invite
  );

  const handleLogin = async (googleData) => {
    const timeZoneOffset = -new Date().getTimezoneOffset() / 60;
    loginUserGoogle({
      variables: {
        input: {
          providerKey: googleData.tokenId,
          providerType: "GOOGLE",
          timeZoneOffset,
          inviteCode,
        },
      },
    });
  };

  const handleLoginFailed = async (details) => {
    Logger.error(details.error);
    if (details.error === "idpiframe_initialization_failed") {
      toast.error(t("error.GOOGLE_LOGIN_INIT_FAILED"));
    } else if (details.error === "access_denied") {
      toast.error(t("error.GOOGLE_LOGIN_ACCESS_DENIED"));
    }
  };

  const { signIn } = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
    cookiePolicy: "single_host_origin",
    onSuccess: handleLogin,
    onFailure: handleLoginFailed,
  });

  const [loginUserGoogle, { loading: loginUserGoogleLoading }] = useMutation(
    LOGIN_USER_GOOGLE,
    {
      onError: (error) => {
        Logger.error(error);
      },
      onCompleted: (data) => {
        toast.dismiss();
        localStorage.setItem("token", data.loginUserSocial.token);
        isLoggedInVar(true);
      },
    }
  );

  const handleLoginClicked = () => {
    if (isMobileApp()) {
      // send message to react native to handle social login there
      googleLoginClicked();
    } else {
      // proceed in web
      signIn();
    }
  };

  useReactNativeEvents({
    handleEvent: (event) => {
      if (event.type === "googleLoginError") {
        toast.error(t("error.GOOGLE_LOGIN_FAILED"));
        return;
      }

      if (event.type === "googleLogin") {
        handleLogin({ tokenId: event.idToken });
      }
    },
  });

  return (
    <div className={styles.container}>
      <button
        className={`button ${loginUserGoogleLoading ? "is-loading" : null}`}
        onClick={handleLoginClicked}
      >
        <span className="icon">
          <img
            className={styles.googleLogo}
            src={GoogleIcon}
            alt="Google Logo"
          />
        </span>
        <span>{text}</span>
      </button>
    </div>
  );
};

GoogleLoginWrapper.propTypes = propTypesGoogleLoginWrapper;
GoogleLoginWrapper.defaultProps = defaultPropsGoogleLoginWrapper;

export default GoogleLoginWrapper;
