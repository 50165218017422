import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./BookmarkButton.module.scss";
import { TooltipDelayed } from "../../../UI/Tooltip/Tooltip";

const propTypesBookmarkButton = {
  entryName: PropTypes.string,
  dataId: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};
const defaultPropsBookmarkButton = {
  entryName: null,
  dataId: null,
  active: false,
  onClick: () => {},
};

const BookmarkButton = ({ entryName, dataId, active, onClick }) => {
  const { t } = useTranslation();

  const contentButton = (
    <button
      id="BookmarkButton"
      type="button"
      data-id={dataId}
      className={`button is-light ${styles.button} ${
        active ? styles.active : null
      }`}
      onClick={() => {
        onClick();
      }}
    >
      <span className={`icon ${styles.icon}`}>
        <i className={`${active ? "fas fa-bookmark" : "far fa-bookmark"}`} />
      </span>
    </button>
  );

  return (
    <TooltipDelayed
      content={
        active
          ? t("wisdomtree.entry_browser.tooltips.bookmark_entry_remove", {
              entry: entryName,
            })
          : t("wisdomtree.entry_browser.tooltips.bookmark_entry_create", {
              entry: entryName,
            })
      }
    >
      {contentButton}
    </TooltipDelayed>
  );
};

BookmarkButton.propTypes = propTypesBookmarkButton;
BookmarkButton.defaultProps = defaultPropsBookmarkButton;

export default BookmarkButton;
