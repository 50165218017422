/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Logger from "js-logger";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
import styles from "./EditCategoryModal.module.scss";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import Spinner from "../../Spinner/Spinner";
import {
  CREATE_CATEGORY,
  mergeCategoriesAfterCreate,
  UPDATE_CATEGORY,
} from "../../../GraphQl/categories";
import ColorPickerCustom from "../../ColorPickerCustom/ColorPickerCustom";
import ColorPicker from "../../ColorPicker/ColorPicker";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  nested: PropTypes.bool,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  nested: false,
};

const useEditCategoryModal = ({ onOpen, onClose, nested }) => {
  const { t } = useTranslation();

  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [categoryColor, setCategoryColor] = useState(null);
  const [categoryType, setCategoryType] = useState(null);

  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose,
    isNested: nested,
  });

  const openModal = (categoryType, categoryToEdit) => {
    setCategoryType(categoryType);
    setCategoryToEdit(categoryToEdit);
    open();
  };

  const closeModal = () => {
    close();
    setCategoryType(null);
    setCategoryToEdit(null);
  };

  useEffect(() => {
    if (isOpened && categoryToEdit) {
      setCategoryName(categoryToEdit.name);
      setCategoryDescription(
        categoryToEdit.description ? categoryToEdit.description : ""
      );
      setCategoryColor(categoryToEdit.color);
    } else if (!isOpened) {
      setCategoryName("");
      setCategoryDescription("");
      setCategoryColor(null);
    }
  }, [categoryToEdit, isOpened]);

  const [createCategory, { loading: createCategoryLoading }] = useMutation(
    CREATE_CATEGORY,
    {
      update: mergeCategoriesAfterCreate,
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        closeModal();
      },
    }
  );

  const [updateCategory, { loading: updateCategoryLoading }] = useMutation(
    UPDATE_CATEGORY,
    {
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        closeModal();
      },
    }
  );

  const onSubmitHandler = () => {
    if (categoryToEdit) {
      updateCategory({
        variables: {
          id: categoryToEdit.id,
          input: {
            name: categoryName,
            description: categoryDescription,
            colorId: categoryColor.id,
          },
        },
      });
    } else {
      createCategory({
        variables: {
          input: {
            type: categoryType.toUpperCase(),
            name: categoryName,
            description: categoryDescription,
            colorId: categoryColor.id,
          },
        },
      });
    }
  };

  const hasUpdate =
    categoryToEdit &&
    (categoryToEdit.name !== categoryName ||
      categoryToEdit.description !== categoryDescription ||
      categoryToEdit.color.id !== categoryColor.id);
  const canSubmit = !(
    categoryName.length === 0 ||
    (categoryToEdit && !hasUpdate)
  );

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!isOpened) return;
      close();
    },
    dependsOn: [close, isOpened],
  });

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!isOpened) return;
      if (!canSubmit) return;
      onSubmitHandler();
    },
    dependsOn: [onSubmitHandler, canSubmit, isOpened],
  });

  let content = null;
  if (isOpened) {
    let nameInput = (
      <div className={styles.inputContainer}>
        <div className={`control`}>
          <input
            className={`input ${styles.input}`}
            type="text"
            value={categoryName}
            onChange={(event) => {
              const name = event.target.value;
              setCategoryName(name);
            }}
            autoFocus
            maxLength={255}
            placeholder={t(
              "wisdomtree.modal_edit_category.new_name_placeholder"
            )}
          />
        </div>
      </div>
    );

    let descriptionInput = (
      <div className={styles.inputContainer}>
        <div className={`control`}>
          <input
            className={`input ${styles.input}`}
            type="text"
            value={categoryDescription}
            onChange={(event) => {
              const description = event.target.value;
              setCategoryDescription(description);
            }}
            maxLength={255}
            placeholder={t(
              "wisdomtree.modal_edit_category.new_description_placeholder"
            )}
          />
        </div>
      </div>
    );

    const colorPickerContent = (
      <div className={styles.colorPickerContainer}>
        <ColorPicker
          selectedColor={categoryColor}
          onColorSelected={(color) => {
            setCategoryColor(color);
          }}
          autoSelect={true}
        />
      </div>
    );

    let bodyContent = (
      <div className={styles.bodyContainer}>
        {nameInput}
        {descriptionInput}
        {colorPickerContent}
      </div>
    );

    content = (
      <BaseModal show={isOpened} onCloseHandler={closeModal}>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {categoryToEdit
                ? t("wisdomtree.modal_edit_category.edit_title")
                : t("wisdomtree.modal_edit_category.new_title")}
            </p>
            <ModalCloseButton onClick={closeModal} />
          </header>
          <section className="modal-card-body">{bodyContent}</section>
          <footer className="modal-card-foot">
            <button
              className={`button is-primary ${
                createCategoryLoading || updateCategoryLoading
                  ? `is-loading`
                  : ``
              }`}
              type="submit"
              disabled={
                categoryName.length === 0 || (categoryToEdit && !hasUpdate)
              }
              onClick={() => {
                onSubmitHandler();
              }}
            >
              {categoryToEdit
                ? t("wisdomtree.modal_edit_category.edit_submit")
                : t("wisdomtree.modal_edit_category.new_submit")}
            </button>
            <button className="button" type="button" onClick={closeModal}>
              {t("wisdomtree.modal_set_category.cancel")}
            </button>
          </footer>
        </div>
      </BaseModal>
    );
  }

  return { open: openModal, close: closeModal, content };
};

useEditCategoryModal.propTypes = propTypes;
useEditCategoryModal.defaultProps = defaultProps;

export default useEditCategoryModal;
