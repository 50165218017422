import React from "react";
import PropTypes from "prop-types";

import styles from "./VoteButton.module.scss";
import { useMutation } from "@apollo/client";
import {
  UNDO_VOTE_FOR_FEATURE_REQUEST,
  VOTE_FOR_FEATURE_REQUEST,
} from "../../../GraphQl/roadmap";
import useAuthentication from "../../../Utils/UseAuthentication";

const propTypesVoteButton = {
  featureId: PropTypes.string.isRequired,
  votes: PropTypes.number,
  hasVoted: PropTypes.bool,
  onClick: PropTypes.func,
};
const defaultPropsVoteButton = {
  votes: 0,
  hasVoted: false,
  onClick: () => {},
};

const VoteButton = ({ featureId, votes, hasVoted }) => {
  const authenticated = useAuthentication();

  const [voteForFeature] = useMutation(VOTE_FOR_FEATURE_REQUEST, {
    update: (cache, mutationResult) => {},
    onCompleted: () => {},
  });

  const [undoVoteForFeature] = useMutation(UNDO_VOTE_FOR_FEATURE_REQUEST, {
    update: (cache, mutationResult) => {},
    onCompleted: () => {},
  });

  const handleClick = () => {
    if (!authenticated) return;

    if (!hasVoted) {
      let optimisticResponse = {
        __typename: "Mutation",
        voteForFeatureRequest: {
          __typename: "VoteForFeatureRequestResponse",
          code: 200,
          success: true,
          message: "featureRequest updated",
          updatedFeatureRequest: {
            id: featureId,
            __typename: "FeatureRequest",
          },
        },
      };

      optimisticResponse.voteForFeatureRequest.updatedFeatureRequest["votes"] =
        votes + 1;
      optimisticResponse.voteForFeatureRequest.updatedFeatureRequest[
        "hasVoted"
      ] = true;
      voteForFeature({
        variables: {
          id: featureId,
        },
        optimisticResponse,
      });
    } else {
      let optimisticResponse = {
        __typename: "Mutation",
        undoVoteForFeatureRequest: {
          __typename: "UndoVoteForFeatureRequestResponse",
          code: 200,
          success: true,
          message: "featureRequest updated",
          updatedFeatureRequest: {
            id: featureId,
            __typename: "FeatureRequest",
          },
        },
      };

      optimisticResponse.undoVoteForFeatureRequest.updatedFeatureRequest[
        "votes"
      ] = votes - 1;
      optimisticResponse.undoVoteForFeatureRequest.updatedFeatureRequest[
        "hasVoted"
      ] = false;
      undoVoteForFeature({
        variables: {
          id: featureId,
        },
        optimisticResponse,
      });
    }
  };

  return (
    <div
      className={`${styles.container} ${
        hasVoted ? styles.hasVoted : null
      } button`}
      onClick={(event) => {
        event.preventDefault();
        handleClick();
      }}
    >
      <span className={`icon ${styles.icon}`}>
        <i className="fas fa-caret-up" />
      </span>
      <span className={styles.number}>{votes}</span>
    </div>
  );
};

VoteButton.propTypes = propTypesVoteButton;
VoteButton.defaultProps = defaultPropsVoteButton;

export default VoteButton;
