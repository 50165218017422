import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { isMobile as isMobileDevice } from "react-device-detect";

import * as Constants from "../../Constants";
import { useHotkey } from "../../../Utils/Hotkeys";
import styles from "./EntryBrowserNewEntry.module.scss";
import entryBrowserStyles from "../EntryBrowser.module.scss";
import NewEntry from "./NewEntry/NewEntry";
import MenuBarNewEntry from "./MenuBarNewEntry/MenuBarNewEntry";
import { GET_ENTRY_NAME_SUGGESTIONS } from "../../../GraphQl/commonEntries";
import Spinner from "../../../UI/Spinner/Spinner";
import Suggestion from "./Suggestion/Suggestion";
import StickyHeader from "../StickyHeader/StickyHeader";
import { hasClassOnElement } from "../../../Utils/Utils";
import Quill from "quill";
import useTimeTracking from "../../../Utils/UseTimeTracking";

const propTypes = {
  selectedEntryId: PropTypes.string,
  onCreateEntry: PropTypes.func.isRequired,
  newEntryDraft: PropTypes.shape({
    name: PropTypes.string,
  }),
  onUpdateNewEntryDraft: PropTypes.func.isRequired,
  newEntryLoading: PropTypes.bool,
  graphContainerHeight: PropTypes.number.isRequired,
  onDialogOpenedChanged: PropTypes.func.isRequired,
  dialogOpened: PropTypes.bool,
};

const defaultProps = {
  selectedEntryId: null,
  newEntryDraft: null,
  newEntryLoading: false,
  dialogOpened: false,
};

const EntryBrowserNewEntry = ({
  selectedEntryId,
  newEntryDraft,
  onCreateEntry,
  onUpdateNewEntryDraft,
  newEntryLoading,
  graphContainerHeight,
  onDialogOpenedChanged,
  dialogOpened,
}) => {
  useTimeTracking({ entryId: null });

  const history = useHistory();
  const [newEntryName, setNewEntryName] = useState(newEntryDraft.name);
  const [newEntryCategoryId, setNewEntryCategoryId] = useState(null);
  const [hasSelectedSuggestion, setHasSelectedSuggestion] = useState(false);
  const [isHeaderSticky, setHeaderSticky] = useState(false);

  useEffect(() => {
    onUpdateNewEntryDraft({
      name: newEntryName,
      categoryId: newEntryCategoryId,
    });
  }, [newEntryName, newEntryCategoryId]);

  const focusEditor = () => {
    const newEntryNameInputSelection = document.getElementsByClassName(
      "newEntryEditor"
    );
    const newEntryNameInput = newEntryNameInputSelection
      ? newEntryNameInputSelection[0]
      : null;

    if (newEntryNameInput) {
      const quill = Quill.find(
        newEntryNameInput.childNodes[0].childNodes[0],
        true
      );
      if (quill) {
        if (
          hasSelectedSuggestion &&
          (!quill.getSelection() || quill.getSelection().index === 0)
        ) {
          quill.setSelection(quill.getLength());
        } else {
          quill.focus();
        }
      }
    }
  };

  useEffect(() => {
    if (!isMobileDevice) {
      setTimeout(() => {
        focusEditor();
      }, 1);
    }
  }, [newEntryDraft]);

  const { loading: getSuggestionsLoading, data: getSuggestionsData } = useQuery(
    GET_ENTRY_NAME_SUGGESTIONS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        input: {
          text: newEntryDraft.name,
          parentEntryId: selectedEntryId,
        },
      },
    }
  );
  let suggestions = getSuggestionsData
    ? getSuggestionsData.entryNameSuggestions
    : [];

  if (suggestions.length > 10) {
    suggestions = suggestions.slice(0, 10);
  }

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (dialogOpened) return;

      const cancelTo =
        selectedEntryId !== null ? `/entry/${selectedEntryId}` : "/home";
      history.push(cancelTo);
    },
    dependsOn: [selectedEntryId, dialogOpened],
  });

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (dialogOpened) return;
      if (newEntryLoading) return;

      if (hasClassOnElement(document.activeElement, "suggestion")) return;
      if (hasClassOnElement(document.activeElement, "category")) {
        return;
      }

      onCreateEntry();
    },
    dependsOn: [
      onCreateEntry,
      newEntryLoading,
      document.activeElement,
      dialogOpened,
    ],
  });

  let content = null;
  let contentSelection = null;

  const contentMenuBar = (
    <MenuBarNewEntry
      selectedEntryId={selectedEntryId}
      newEntryDraft={newEntryDraft}
      onCreateEntry={() => onCreateEntry()}
      newEntryLoading={newEntryLoading}
    />
  );
  contentSelection = (
    <NewEntry
      entryName={newEntryName}
      onUpdateEntryName={(updatedName) => {
        setNewEntryName(updatedName);
        setHasSelectedSuggestion(false);
      }}
      onUpdateEntryCategory={(category) => {
        setNewEntryCategoryId(category ? category.id : null);
      }}
      onDialogOpenedChanged={onDialogOpenedChanged}
    />
  );

  let suggestionsContent = null;

  suggestionsContent = suggestions.map((suggestion) => (
    <Suggestion
      key={suggestion.name}
      suggestion={suggestion}
      onSelectSuggestion={(selectedSuggestion) => {
        setNewEntryName(selectedSuggestion.name);
        setHasSelectedSuggestion(true);
      }}
      // showUserCount={suggestion.userCount >= 2}
      showUserCount={false}
    />
  ));

  let suggestionsListContent = null;
  if (!hasSelectedSuggestion) {
    suggestionsListContent = (
      <div
        className={`${styles.suggestionsListContainer} ${
          getSuggestionsLoading ? styles.loading : null
        }`}
      >
        {suggestions.length < 0 ? (
          <p className={styles.suggestionsTitle}>Suggestions</p>
        ) : null}
        {suggestionsContent}
      </div>
    );
  }

  content = (
    <div className={styles.container}>
      <StickyHeader
        className={styles.stickyHeaderNewEntry}
        triggerStickyY={graphContainerHeight - 5}
        yOffset={Constants.HEADER_HEIGHT}
        onStickyChanged={(sticky) => setHeaderSticky(sticky)}
      >
        <div className={`box ${entryBrowserStyles.selectionAndMenuWrapper}`}>
          {contentMenuBar}
          <hr />
          {contentSelection}
        </div>
      </StickyHeader>

      <div className={styles.suggestionsContainer}>
        <div
          className={`${styles.spinnerContainer} ${
            getSuggestionsLoading ? styles.show : null
          }`}
        >
          <Spinner />
        </div>
        {suggestionsListContent}
      </div>
    </div>
  );

  return content;
};

EntryBrowserNewEntry.propTypes = propTypes;
EntryBrowserNewEntry.defaultProps = defaultProps;

export default EntryBrowserNewEntry;
