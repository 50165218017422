/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./SearchInput.module.scss";
import { useHotkey } from "../../../../../Utils/Hotkeys";

const propTypesSearchInput = {
  onSearch: PropTypes.func.isRequired,
  focusInitially: PropTypes.bool,
  initialQuery: PropTypes.string,
  onClear: PropTypes.func.isRequired,
};
const defaultPropsSearchInput = {
  focusInitially: true,
  initialQuery: null,
};

const SearchInput = ({ onSearch, focusInitially, initialQuery, onClear }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [previousQuery, setPreviousQuery] = useState(null);
  const [inputFocussed, setInputFocussed] = useState(false);

  useEffect(() => {
    if (initialQuery) {
      setSearchQuery(initialQuery);
    }
  }, [initialQuery]);

  const onSearchHandler = () => {
    onSearch(searchQuery);
    setPreviousQuery(searchQuery);
    document.activeElement.blur();
  };

  const onClearHandler = () => {
    setSearchQuery("");
    onClear();
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (searchQuery.length === 0) return;
      onSearchHandler();
    },
    dependsOn: [onSearchHandler, searchQuery],
  });

  const onChangeSearchInputHandler = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className={styles.searchContainer}>
      <div className={`${styles.searchInputContainer} control has-icons-right`}>
        <input
          className={`input ${styles.searchInput}`}
          type="text"
          value={searchQuery}
          onChange={onChangeSearchInputHandler}
          placeholder={t("wisdomtree.modal_choose_image.search.search_hint")}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={focusInitially}
          onFocus={() => setInputFocussed(true)}
          onBlur={() => setInputFocussed(false)}
        />
        {searchQuery.length > 0 ? (
          <span
            id={styles.cancelSearch}
            className="icon is-small is-right"
            tabIndex={-1}
            onClick={onClearHandler}
          >
            <i className="fas fa-times" />
          </span>
        ) : null}
      </div>
      <button
        className={`button ${
          searchQuery.length > 0 &&
          inputFocussed &&
          searchQuery !== previousQuery
            ? "is-primary"
            : null
        } ${styles.searchButton}`}
        type="button"
        disabled={searchQuery.length === 0}
        onClick={() => {
          onSearchHandler();
        }}
      >
        <span className="icon is-small">
          <i className="fas fa-search" />
        </span>
      </button>
    </div>
  );
};

SearchInput.propTypes = propTypesSearchInput;
SearchInput.defaultProps = defaultPropsSearchInput;

export default SearchInput;
