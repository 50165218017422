import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withResizeDetector } from "react-resize-detector";
import useResizeObserver from "use-resize-observer";

import styles from "./Image.module.scss";
import { useMobile } from "../../../../../../Utils/Responsive";
import ImageSourceInfo from "./ImageSourceInfo/ImageSourceInfo";
import ImageGradient from "./ImageGradient/ImageGradient";

const propTypesImage = {
  imageResult: PropTypes.shape({
    url: PropTypes.string,
    previewUrl: PropTypes.string,
    author: PropTypes.string,
    authorUrl: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  source: PropTypes.string.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  width: PropTypes.number,
  horizontalScroll: PropTypes.bool,
};
const defaultPropsImage = {
  width: undefined,
  horizontalScroll: false,
};

const Image = ({
  imageResult,
  source,
  onImageSelected,
  width,
  horizontalScroll,
}) => {
  const isMobile = useMobile();
  const [imageOffsetLeft, setImageOffsetLeft] = useState(0);
  const [error, setError] = useState(null);

  const { ref: imageRef, width: imageWidth = 0 } = useResizeObserver();

  const imageWrapperRef = useRef(null);

  useEffect(() => {
    if (width != null && imageRef && imageRef.current) {
      setImageOffsetLeft((width - imageRef.current.clientWidth) / 2);
    }
  }, [width]);

  let imageStyle = {};

  if (isMobile && !horizontalScroll) {
    let calcWidth = window.innerWidth;
    let calcHeight = (imageResult.height / imageResult.width) * calcWidth;

    if (calcHeight > 300) {
      calcWidth /= calcHeight / 300;
      calcHeight = 300;
    }
    imageStyle = { height: calcHeight, width: calcWidth };
  }

  return (
    // eslint-disable-next-line react/no-array-index-key
    <div
      className={`${styles.imageContainer} ${
        horizontalScroll ? styles.horizontal : styles.vertical
      } ${error ? styles.hidden : null}`}
    >
      <div
        className={styles.imageWrapper}
        role="button"
        tabIndex={0}
        onClick={() => {
          onImageSelected(imageResult);
        }}
        onKeyDown={(e) => {
          // enter key
          if (e.keyCode === 13) {
            onImageSelected(imageResult);
          }
        }}
        ref={imageWrapperRef}
      >
        <img
          ref={imageRef}
          className={styles.image}
          src={imageResult.previewUrl}
          alt={`taken by ${imageResult.author}`}
          // crossOrigin="anonymous"
          onError={(err) => setError(err)}
          width={imageResult.width}
          height={imageResult.height}
          style={imageStyle}
        />
        <div
          className={styles.gradient}
          style={{ width: imageWidth, left: imageOffsetLeft }}
        >
          <ImageGradient />
        </div>
        <div className={styles.info} style={{ left: imageOffsetLeft + 6 }}>
          <ImageSourceInfo
            source={source}
            author={imageResult.author}
            authorUrl={imageResult.authorUrl}
          />
        </div>
      </div>
    </div>
  );
};

Image.propTypes = propTypesImage;
Image.defaultProps = defaultPropsImage;

export default withResizeDetector(Image);
