class RemoveTextColorOnCopy {
  constructor(quill, options) {
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, function(node, delta) {
      delta.forEach((e) => {
        if (e.attributes) {
          e.attributes.color = "";
          e.attributes.background = "";
        }
      });
      return delta;
    });
  }
}

export default RemoveTextColorOnCopy;
