/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import ReactCrop from "react-image-crop";
import { toast } from "react-toastify";
import Logger from "js-logger";

import modalStyles from "../ChooseImageModal.module.scss";
import styles from "./EditImage.module.scss";
import { useHotkey } from "../../../../Utils/Hotkeys";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";

const propTypesEditImage = {
  selectedImage: PropTypes.shape({
    url: PropTypes.string,
    meta: PropTypes.object,
    imageObject: PropTypes.object,
  }).isRequired,
  forceSquare: PropTypes.bool,
  onChooseImage: PropTypes.func.isRequired,
  onUnselectImage: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};
const defaultPropsEditImage = {
  forceSquare: false,
};

const EditImage = ({
  selectedImage,
  forceSquare,
  onChooseImage,
  onUnselectImage,
  onCloseModal,
}) => {
  const { t } = useTranslation();
  const [imageRef, setImageRef] = useState(null);
  const [cropSetting, setCropSettings] = useState({});
  const [cropLoading, setCropLoading] = useState(false);

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      onConfirmCrop();
    },
    dependsOn: [selectedImage, onChooseImage, imageRef, cropSetting],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onUnselectImage();
    },
    dependsOn: [onUnselectImage],
  });

  const onCropChange = (crop) => {
    setCropSettings(crop);
  };

  const onImageLoaded = (image) => {
    setImageRef(image);

    // console.log({
    //   imageWidth: image.width,
    //   imageHeight: image.height,
    //   naturalWidth: image.naturalWidth,
    //   naturalHeight: image.naturalHeight,
    // });

    setTimeout(() => {
      if (forceSquare) {
        if (image.width > image.height) {
          setCropSettings({
            unit: "px",
            width: image.height,
            height: image.height,
            y: 0,
            x: Math.floor((image.width - image.height) / 2),
            aspect: 1,
          });
        } else {
          setCropSettings({
            unit: "px",
            width: image.width,
            height: image.width,
            x: 0,
            y: Math.floor((image.height - image.width) / 2),
            aspect: 1,
          });
        }
      } else {
        setCropSettings({
          unit: "px",
          width: image.width,
          height: image.height,
          x: 0,
          y: 0,
        });
      }
    });

    return false;
  };

  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");

    const maxImageDimension = 1920;
    let constrainedWidth = image.naturalWidth;
    let constrainedHeight = image.naturalHeight;
    // check if original image is too big
    if (
      image.naturalWidth > maxImageDimension ||
      image.naturalHeight > maxImageDimension
    ) {
      // make sure the aspect ratio of both dimensions is correct
      if (image.naturalWidth > image.naturalHeight) {
        constrainedWidth = maxImageDimension;
        const factor = image.naturalWidth / maxImageDimension;
        constrainedHeight = image.naturalHeight / factor;
      } else {
        constrainedHeight = maxImageDimension;
        const factor = image.naturalHeight / maxImageDimension;
        constrainedWidth = image.naturalWidth / factor;
      }
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const scaleXConstrained = constrainedWidth / image.width;
    const scaleYConstrained = constrainedHeight / image.height;
    canvas.width = crop.width * scaleXConstrained;
    canvas.height = crop.height * scaleYConstrained;
    const ctx = canvas.getContext("2d");

    const x = isNaN(crop.x) ? 0 : crop.x;
    const y = isNaN(crop.y) ? 0 : crop.y;

    // console.log({
    //   imageWidth: image.width,
    //   imageHeight: image.height,
    //   naturalWidth: image.naturalWidth,
    //   constrainedWidth,
    //   naturalHeight: image.naturalHeight,
    //   constrainedHeight,
    //   cropWidth: crop.width,
    //   cropHeight: crop.height,
    //   scaleXConstrained,
    //   scaleYConstrained,
    //   x,
    //   y,
    // });

    const scaledX = x * scaleX;
    const scaledY = y * scaleY;

    ctx.drawImage(
      image,
      scaledX,
      scaledY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleXConstrained,
      crop.height * scaleYConstrained
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            // console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          blob.lastModifiedDate = new Date();

          const file = new File([blob], fileName, { type: "image/jpeg" });
          // console.log({ filesizeMB: (file.size / (1024 * 1024)).toFixed(2) });
          resolve({
            croppedImage: file,
            croppedWidth: constrainedWidth,
            croppedHeight: constrainedHeight,
            croppedX: scaledX,
            coppedY: scaledY,
          });
        },
        "image/jpeg",
        0.8
      );
    });
  };

  const performCrop = async () => {
    if (imageRef && cropSetting.width && cropSetting.height) {
      const tmpCroppedImageUrl = await getCroppedImg(
        imageRef,
        cropSetting,
        "image.jpeg"
      );
      return tmpCroppedImageUrl;
    }

    return null;
  };

  const onConfirmCrop = async () => {
    let imageInput = {
      cropAspect: cropSetting.aspect,
      ...selectedImage.meta,
    };

    if (selectedImage.meta.source === "Upload") {
      // crop locally
      setCropLoading(true);
      const {
        croppedImage,
        croppedWidth,
        croppedHeight,
        croppedX,
        croppedY,
      } = await performCrop();
      setCropLoading(false);

      imageInput = {
        ...imageInput,
        upload: croppedImage,
        cropX: croppedX,
        cropY: croppedY,
        cropWidth: croppedWidth,
        cropHeight: croppedHeight,
      };
    } else {
      // crop in backend
      const scaleFactor = imageRef.naturalWidth / imageRef.width;

      imageInput = {
        ...imageInput,
        cropX: Math.floor(cropSetting.x * scaleFactor),
        cropY: Math.floor(cropSetting.y * scaleFactor),
        cropWidth: Math.min(
          imageRef.naturalWidth,
          Math.floor(cropSetting.width * scaleFactor)
        ),
        cropHeight: Math.min(
          imageRef.naturalHeight,
          Math.floor(cropSetting.height * scaleFactor)
        ),
        width: imageRef.naturalWidth,
        height: imageRef.naturalHeight,
      };
    }

    onChooseImage(imageInput);
    onCloseModal();
  };

  let bodyContent = null;
  let footerContent = null;

  const imageLoadingSpinner =
    imageRef == null ? (
      <div className={styles.imageLoadingWrapper}>
        <BeatLoader sizeUnit="px" size={10} color="#7A7A7A" />
      </div>
    ) : null;

  bodyContent = (
    <>
      {imageLoadingSpinner}
      <div className={styles.cropContainer}>
        <ReactCrop
          src={selectedImage.url}
          crop={cropSetting}
          onChange={onCropChange}
          onImageLoaded={onImageLoaded}
          onImageError={(error) => {
            Logger.error(error);
            toast.error(t(`error.IMAGE_LOAD_FAILED`));
            onUnselectImage();
          }}
          // crossorigin="anonymous"
          keepSelection={forceSquare}
          imageStyle={{
            maxHeight: "70vh",
          }}
          style={
            imageRef == null ? { visibility: "hidden", height: "0px" } : {}
          }
        />
      </div>
    </>
  );

  footerContent = (
    <footer className="modal-card-foot">
      <button
        className={`button is-success${cropLoading ? " is-loading" : ""}`}
        type="submit"
        disabled={imageRef == null}
        onClick={() => {
          onConfirmCrop();
        }}
      >
        {t("wisdomtree.modal_choose_image.edit.choose")}
      </button>
      <button
        className="button"
        type="button"
        onClick={() => onUnselectImage()}
      >
        {t("wisdomtree.modal_choose_image.edit.back")}
      </button>
      <button className="button" type="button" onClick={onCloseModal}>
        {t("wisdomtree.modal_choose_image.edit.cancel")}
      </button>
    </footer>
  );

  return (
    <div className={`modal-card ${modalStyles.modalCard}`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.modal_choose_image.edit.title")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${modalStyles.cardBody}`}>
        {bodyContent}
      </section>
      {footerContent}
    </div>
  );
};

EditImage.propTypes = propTypesEditImage;
EditImage.defaultProps = defaultPropsEditImage;

export default EditImage;
