import React from "react";
import PropTypes from "prop-types";

import styles from "./ImageSourceInfo.module.scss";

const propTypesImageSourceInfo = {
  source: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  authorUrl: PropTypes.string.isRequired,
};
const defaultPropsImageSourceInfo = {};

const ImageSourceInfo = ({ source, author, authorUrl }) => {
  let infoContent = null;
  if (source === "Unsplash") {
    infoContent = (
      <>
        <a
          href={`${authorUrl}?utm_source=Wisdom Tree&utm_medium=referral`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {author}
        </a>
        <span> on </span>
        <a
          href="https://unsplash.com/?utm_source=Wisdom Tree&utm_medium=referral"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Unsplash
        </a>
      </>
    );
  } else {
    infoContent = (
      <>
        <a
          href={`${authorUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {author}
        </a>
      </>
    );
  }
  return <div className={styles.container}>{infoContent}</div>;
};

ImageSourceInfo.propTypes = propTypesImageSourceInfo;
ImageSourceInfo.defaultProps = defaultPropsImageSourceInfo;

export default ImageSourceInfo;
