import React from "react";
import PropTypes from "prop-types";

import styles from "./QuestInfo.module.scss";
import { useTranslation } from "react-i18next";
import {
  mergeUserAfterStartNextQuest,
  START_NEXT_QUEST,
} from "../../../../GraphQl/quests";
import Logger from "js-logger";
import useTutorialModal from "../../../../UI/Modal/TutorialModal/TutorialModal";
import { getContentForQuest } from "../../../../Utils/QuestContent";
import { useMutation } from "@apollo/client";

const propTypesQuestInfo = {
  user: PropTypes.object.isRequired,
  onDialogOpenedChanged: PropTypes.func.isRequired,
};
const defaultPropsQuestInfo = {};

const QuestInfo = ({ user, onDialogOpenedChanged }) => {
  const { t } = useTranslation();

  const [startQuest] = useMutation(START_NEXT_QUEST, {
    update: (cache, mutationResult) => {
      mergeUserAfterStartNextQuest(cache, mutationResult);
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  const {
    open: openTutorialModal,
    content: tutorialModalContent,
  } = useTutorialModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
  });

  const clickOnNewQuest = () => {
    openTutorialModal({
      cancelable: true,
      content: getContentForQuest(user.nextQuest, user.jobToBeDone, true),
      onFinish: async () => {
        await startQuest();
      },
    });
  };

  return (
    <>
      <div className={`box ${styles.container}`}>
        <div className={styles.textContainer}>
          <p className={styles.title}>
            {t(`wisdomtree.quests.${user.nextQuest}.info.title`)}
          </p>
          <p className={styles.subtitle}>
            {t(`wisdomtree.quests.${user.nextQuest}.info.subtitle`)}
          </p>
        </div>
        <div
          className={`${styles.buttonContainer}`}
          data-id="button.new-quest"
          onClick={() => {
            clickOnNewQuest();
          }}
        >
          <div className={`${styles.questButton}`}>
            <div className={styles.buttonText}>
              <span>{t("wisdomtree.quests.new_quest")}</span>
            </div>
            <span className={`icon ${styles.icon}`}>
              <i className={`fas fa-exclamation-circle`} />
            </span>
          </div>
        </div>
      </div>
      {tutorialModalContent}
    </>
  );
};

QuestInfo.propTypes = propTypesQuestInfo;
QuestInfo.defaultProps = defaultPropsQuestInfo;

export default QuestInfo;
