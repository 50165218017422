/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./SearchResultBlock.module.scss";
import stylesVariables from "../../../variables.scss";
import BlockIdentifier from "../BlockIdentifier/BlockIdentifier";
import { useLazyQuery } from "@apollo/client";
import { GET_BLOCK_CONTENT } from "../../GraphQl/blocks";
import Logger from "js-logger";
import Spinner from "../Spinner/Spinner";
import ContentQuillEditor from "../Editor/ContentQuillEditor";

const propTypesSearchResultBlock = {
  block: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    contentHtml: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool,
  duplicate: PropTypes.bool,
  onClick: PropTypes.func,
};
const defaultPropsSearchResultBlock = {
  selected: false,
  duplicate: false,
  onClick: () => {},
};

const SearchResultBlock = ({ block, selected, duplicate, onClick }) => {
  const [
    getBlockContent,
    { loading: getBlockContentLoading, data: getBlockContentData },
  ] = useLazyQuery(GET_BLOCK_CONTENT, {
    onError: (err) => {
      Logger.error(err);
    },
  });
  const blockContent = getBlockContentData
    ? getBlockContentData.block.content
    : null;

  useEffect(() => {
    if (selected && !blockContent) {
      getBlockContent({
        variables: { id: block.id },
      });
    }
  }, [selected, blockContent]);

  let parentEntryContent = null;
  if (duplicate && block.entry.parentEntry)
    parentEntryContent = (
      <p className={styles.parentEntryNameContainer}>
        <span className={styles.parentEntryName}>
          {block.entry.parentEntry.name}
        </span>
        <span className={styles.nameSeperator}>›</span>
      </p>
    );

  let entryCategoryIndicator = null;
  if (block.entry.category && block.entry.category.color) {
    entryCategoryIndicator = (
      <div
        className={styles.categoryIndicator}
        style={{ backgroundColor: block.entry.category.color.hex }}
      />
    );
  }

  let detailsContent = null;
  if (selected && block.type !== "IMAGE" && block.type !== "WEB") {
    detailsContent = (
      <div className={styles.detailsContainer}>
        {getBlockContentLoading ? (
          <Spinner />
        ) : blockContent ? (
          <ContentQuillEditor
            key={block.id}
            elementId={block.id}
            elementContent={blockContent}
            stylesClassName="blockContentEditor"
            editMode={false}
            focusInitially={false}
          />
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={`box ${styles.container}  ${
        onClick != null ? styles.clickable : null
      }`}
      onClick={onClick}
      role="button"
      tabIndex={0}
      data-search-id={block.id}
      data-search-type="block"
    >
      <div className={styles.innerContainer}>
        <div
          className={`${styles.topContainer} ${
            selected ? styles.selected : null
          }`}
        >
          {block.entry.image ? (
            <img
              src={block.entry.image.url}
              className={styles.image}
              alt="block"
              key={block.id}
            />
          ) : (
            <span className={`icon ${styles.image} ${styles.emptyImage}`}>
              <i className="fas fa-1x fa-image" />
            </span>
          )}
          {entryCategoryIndicator}
          <div className="content">
            <div className={styles.name}>
              {parentEntryContent}
              <BlockIdentifier
                block={block}
                textSize="18px"
                iconSize="18px"
                textColor={selected ? "white" : null}
                iconColor={selected ? "white" : null}
              />
            </div>
          </div>
        </div>
        {detailsContent}
      </div>
    </div>
  );
};

SearchResultBlock.propTypes = propTypesSearchResultBlock;
SearchResultBlock.defaultProps = defaultPropsSearchResultBlock;

export default SearchResultBlock;
