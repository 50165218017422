/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import { useHotkey } from "../../../Utils/Hotkeys";
import { useBaseModal } from "../BaseModal/BaseModal";
import OtherUserModal from "./OtherUser/OtherUserModal";
import CurrentUserModal from "./CurrentUser/CurrentUserModal";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

const useUserProfileModal = ({ onOpen, onClose }) => {
  const [nestedDialogOpened, setNestedDialogOpened] = useState(false);
  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose,
    hasNestedOpened: nestedDialogOpened,
  });
  const [userId, setUserId] = useState(null);

  const openModal = (userIdToShow) => {
    setUserId(userIdToShow);
    open();
  };

  const closeModal = () => {
    close();
    setUserId(null);
  };

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!isOpened) return;
      if (nestedDialogOpened) return;
      close();
    },
    dependsOn: [close, nestedDialogOpened, isOpened],
  });

  let content = null;
  if (isOpened) {
    if (userId) {
      content = (
        <OtherUserModal
          show={isOpened}
          userId={userId}
          onCloseHandler={close}
        />
      );
    } else {
      content = (
        <CurrentUserModal
          show={isOpened}
          onCloseHandler={close}
          onDialogOpenedChanged={(opened) => setNestedDialogOpened(opened)}
        />
      );
    }
  }

  return { open: openModal, close: closeModal, content };
};

useUserProfileModal.propTypes = propTypes;
useUserProfileModal.defaultProps = defaultProps;

export default useUserProfileModal;
