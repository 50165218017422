import * as d3 from "d3";
import * as graphHelper from "./GraphHelper";
import { vibrate } from "../../Utils/ReactNativeMessageUtils";

class EntryMove {
  constructor(graphConfig, isEntryDraggingEnabled) {
    this.graphConfig = graphConfig;
    this.isEntryDraggingEnabled = isEntryDraggingEnabled;
  }

  onNodeStartDrag(rootElement, d) {
    // prevent dragging multiple nodes at the same time
    if (this.dragInfo && this.dragInfo.isDragging) return;

    this.dragInfo = {
      isDragging: true,
      entryId: d.data.id,
      firstDragHappened: false,
      interruptedByZoomChange: false,
      longPressInitiated: false,
      longPressInterrupted: false,
      startAt: Date.now(),
      startX: d3.event.x,
      startY: d3.event.y,
      startNodeX: Math.round(d.data.x),
      startNodeY: Math.round(d.data.y),
    };

    setTimeout(() => {
      if (
        this.dragInfo.entryId === d.data.id &&
        !this.dragInfo.longPressInitiated &&
        !this.dragInfo.interruptedByZoomChange &&
        !this.dragInfo.longPressInterrupted
      ) {
        if (!this.isEntryDraggingEnabled()) return;

        this.dragInfo.longPressInitiated = true;
        this.indicateNodeDragged(rootElement, d);
        vibrate(this.graphConfig.dragNodeLongpressVibrateMs);
      }
    }, this.graphConfig.dragNodeDelay);
  }

  onNodeDrag(rootElement, d) {
    if (this.dragInfo.entryId !== d.data.id) return;
    if (this.dragInfo.interruptedByZoomChange) return;
    if (this.dragInfo.longPressInterrupted) return;

    if (
      this.graphConfig.dragNodeRequireLongpress &&
      !this.dragInfo.longPressInitiated
    ) {
      const outOfBoundsX =
        d3.event.x < d.data.x - d.data.nodeWidth / 2 ||
        d3.event.x > d.data.x + d.data.nodeWidth / 2;
      const outOfBoundsY =
        d3.event.y < d.data.y - d.data.nodeHeight / 2 ||
        d3.event.y > d.data.y + d.data.nodeHeight / 2;
      const outOfBounds = outOfBoundsX || outOfBoundsY;

      if (outOfBounds) {
        this.dragInfo.longPressInterrupted = true;
        return;
      }

      const a = Math.abs(this.dragInfo.startX - d3.event.x);
      const b = Math.abs(this.dragInfo.startY - d3.event.y);
      const distance = Math.sqrt(a * a + b * b);

      if (distance > this.graphConfig.dragNodeLongpressDistanceThreshold) {
        this.dragInfo.longPressInterrupted = true;
        return;
      }
    }

    if (!this.dragInfo.longPressInitiated) return;
    if (!this.isEntryDraggingEnabled()) return;

    let { dx } = d3.event;
    let { dy } = d3.event;

    if (!this.dragInfo.firstDragHappened) {
      dx = d3.event.x - this.dragInfo.startX;
      dy = d3.event.y - this.dragInfo.startY;
      this.dragInfo.firstDragHappened = true;
    }

    graphHelper.updateNodePositions(d, d3.selectAll(".node"), dx, dy);
    graphHelper.updateEdgePositionsForAllNodes(d, d3.selectAll(".edge"));
  }

  onNodeEndDrag(rootElement, d) {
    if (this.dragInfo.entryId !== d.data.id) return null;

    let entryUpdate = {};
    if (
      this.dragInfo.startNodeX !== d.data.x ||
      this.dragInfo.startNodeY !== d.data.y
    ) {
      entryUpdate = {
        ...entryUpdate,
        x: Math.round(d.data.x),
        y: Math.round(d.data.y),
      };
    }

    if (this.indicatorNodeDraggedActive)
      this.revertIndicateNodeDragged(rootElement, d);

    this.dragInfo = {
      isDragging: false,
    };

    return entryUpdate;
  }

  // eslint-disable-next-line class-methods-use-this
  indicateNodeDragged(rootElement, node) {
    const affectedNodesAndEdges = graphHelper.getAllChildNodesAndEdgesOfNodeRecursive(
      rootElement,
      node
    );
    const affectedNodes = [node, ...affectedNodesAndEdges.nodes];
    const affectedEdges = [...affectedNodesAndEdges.edges];

    affectedEdges.forEach((e) => {
      e.attr(
        "stroke-width",
        this.graphConfig.edgeStrokeWidth +
          (this.graphConfig.edgeStrokeWidth / 100) * 10
      );
    });

    affectedNodes.forEach((n) => {
      const entryId = n.data.id;
      // move node to top
      d3.select(rootElement)
        .select("#nodes")
        .node()
        .appendChild(
          d3
            .select(rootElement)
            .select(`#entryId_${entryId}`)
            .node()
        );

      // scale node
      const selectorNode = d3
        .select(rootElement)
        .select(`#entryId_${entryId}`)
        .select(".singleNode");

      graphHelper.scaleNodeUpForDragEffect(selectorNode);

      // change shadow
      const selectorNodeDragged = d3
        .select(rootElement)
        .select(`#entryId_${entryId}`)
        .select(".nodeRect");

      selectorNodeDragged.attr("filter", `url(#nodeDraggedShadow)`);
    });

    this.indicatorNodeDraggedActive = true;
  }

  // eslint-disable-next-line class-methods-use-this
  revertIndicateNodeDragged(rootElement, node) {
    const affectedNodesAndEdges = graphHelper.getAllChildNodesAndEdgesOfNodeRecursive(
      rootElement,
      node
    );
    const affectedNodes = [node, ...affectedNodesAndEdges.nodes];
    const affectedEdges = [...affectedNodesAndEdges.edges];

    affectedEdges.forEach((e) => {
      e.attr("stroke-width", this.graphConfig.edgeStrokeWidth);
    });

    affectedNodes.forEach((n) => {
      const entryId = n.data.id;
      const selector = d3
        .select(rootElement)
        .select(`#entryId_${entryId}`)
        .select(".singleNode");

      graphHelper.scaleNodeDownForDragEffect(selector);

      const selectorNodeDragged = d3
        .select(rootElement)
        .select(`#entryId_${entryId}`)
        .select(".nodeRect");

      selectorNodeDragged.attr("filter", `url(#nodeShadow)`);
    });

    this.indicatorNodeDraggedActive = false;
  }

  isDragging() {
    return this.dragInfo && this.dragInfo.isDragging;
  }

  isLongPressInitiated() {
    return this.dragInfo && this.dragInfo.longPressInitiated;
  }
}

export default EntryMove;
