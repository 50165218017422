/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";
import Logger from "js-logger";

export function useHotkey({
  keyNames,
  callback,
  dependsOn = [],
  skipIfInEditor = false,
  requiresFocusIn,
}) {
  useEffect(() => {
    function onKeyDown(e) {
      try {
        if (skipIfInEditor && isEditorFocussed()) return;
        if (
          requiresFocusIn &&
          document.activeElement &&
          document.activeElement.className &&
          typeof document.activeElement.className === "string" &&
          !document.activeElement.className.includes(requiresFocusIn)
        )
          return;
        if (keyNames.includes(e.key) || keyNames.includes(e.code)) {
          callback(e);
        }
      } catch (error) {
        Logger.error(error);
      }
    }

    document.addEventListener("keydown", onKeyDown, false);

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, dependsOn);
}

const isEditorFocussed = () => {
  if (!document.activeElement) return false;

  let isInQuillEditor;
  let isInInput;
  let isInQuoteSource;

  if (
    document.activeElement.className &&
    typeof document.activeElement.className === "string"
  ) {
    isInQuillEditor = document.activeElement.className.includes("ql-editor");
    isInInput = document.activeElement.className.includes("input");
    isInQuoteSource = document.activeElement.className.includes("source");
  }

  let isInQuillTooltipEditor;
  if (
    document.activeElement.parentNode &&
    document.activeElement.parentNode.className &&
    typeof document.activeElement.parentNode.className === "string"
  ) {
    isInQuillTooltipEditor = document.activeElement.parentNode.className.includes(
      "ql-tooltip-editor"
    );
  }

  const isInSearch = document.activeElement.dataset.id === "search.input";
  return (
    isInQuillEditor ||
    isInQuillTooltipEditor ||
    isInSearch ||
    isInInput ||
    isInQuoteSource
  );
};
