/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import * as d3 from "d3";

export function getAbsolutePosition(XorY, node) {
  let currentNode = node;
  let absolutePos = node.data[XorY];
  while (currentNode.parent) {
    currentNode = currentNode.parent;
    absolutePos += currentNode.data[XorY];
  }
  return absolutePos;
}

export function distanceBetweenNodes(node1, node2) {
  return {
    diffX: Math.abs(node1.data.x - node2.data.x),
    diffY: Math.abs(node1.data.y - node2.data.y),
  };
}

export function doNodesCollide(node1, node2) {
  const xCollide =
    Math.abs(node1.data.x - node2.data.x) -
      node1.data.nodeWidth / 2 -
      node2.data.nodeWidth / 2 <=
    0;
  const yCollide =
    Math.abs(node1.data.y - node2.data.y) -
      node1.data.nodeHeight / 2 -
      node2.data.nodeHeight / 2 <=
    0;

  return xCollide && yCollide;
}

export function isNodeChildOfNode(node, parent) {
  if (!node.parent) return false;

  let currentNode = node;
  while (currentNode.parent) {
    if (currentNode.parent === parent) {
      return true;
    }
    currentNode = currentNode.parent;
  }
  return false;
}

export function updateNodePositions(parentNode, nodeSelector, dx, dy) {
  // eslint-disable-next-line func-names
  nodeSelector.each(function(node) {
    if (
      node.data.id === parentNode.data.id ||
      isNodeChildOfNode(node, parentNode)
    ) {
      node.data.x += dx;
      node.data.y += dy;
      d3.select(this).attr(
        "transform",
        `translate(${node.data.x},${node.data.y})`
      );
    }
  });
}

export function updateEdgePositionsForAllNodes(node, selector) {
  // eslint-disable-next-line func-names
  selector.each(function(edge) {
    if (edge.source.data.id === node.data.id) {
      d3.select(this)
        .attr("x1", node.data.x)
        .attr("y1", node.data.y)
        .attr("x2", edge.target.data.x)
        .attr("y2", edge.target.data.y);
    } else if (edge.target.data.id === node.data.id) {
      d3.select(this)
        .attr("x2", node.data.x)
        .attr("y2", node.data.y);
    } else if (isNodeChildOfNode(edge.source, node)) {
      d3.select(this)
        .attr("x1", edge.source.data.x)
        .attr("y1", edge.source.data.y)
        .attr("x2", edge.target.data.x)
        .attr("y2", edge.target.data.y);
    } else if (isNodeChildOfNode(edge.target, node)) {
      d3.select(this)
        .attr("x1", edge.source.data.x)
        .attr("y1", edge.source.data.y)
        .attr("x2", edge.target.data.x)
        .attr("y2", edge.target.data.y);
    }
  });
}

export function getTextWidth(text, textMeasurementContext) {
  const metrics = textMeasurementContext.measureText(text);
  return Math.ceil(metrics.width);
}

export function getEntryNodeWidth(entryName, entryImage, graphConfig) {
  return (
    (entryImage ? graphConfig.nodeImageSize : 0) +
    graphConfig.nodeTextPaddingLeft +
    getTextWidth(entryName, graphConfig.textMeasurementContext) +
    graphConfig.nodeTextPaddingRight
  );
}

export function getArrayOfChildPositions(nodeData) {
  const childPositions = [];
  nodeData.children.forEach((element) => {
    childPositions.push({
      x: element.data.x,
      y: element.data.y,
    });
  });
  return childPositions;
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function parseTransform(a) {
  const b = {};
  // eslint-disable-next-line no-restricted-syntax
  // eslint-disable-next-line guard-for-in
  for (const i in (a = a.match(/(\w+)\(([^,)]+),?([^)]+)?\)/gi))) {
    const c = a[i].match(/[\w\.\-]+/g);
    b[c.shift()] = c;
  }
  return b;
}

export function scaleNodeUpForDragEffect(selector) {
  const parsedTransform = parseTransform(selector.attr("transform"));

  const translateX = parsedTransform.translate[0];
  const translateY = parsedTransform.translate[1];
  const newTranslateX = (translateX / 100) * 105;
  const newTranslateY = (translateY / 100) * 105;

  selector.attr(
    "transform",
    `translate(${newTranslateX},${newTranslateY}), scale(1.05)`
  );
}

export function scaleNodeDownForDragEffect(selector) {
  const parsedTransform = parseTransform(selector.attr("transform"));

  const translateX = parsedTransform.translate[0];
  const translateY = parsedTransform.translate[1];
  const newTranslateX = (translateX / 105) * 100;
  const newTranslateY = (translateY / 105) * 100;

  selector.attr("transform", `translate(${newTranslateX},${newTranslateY})`);
}

export function getAllChildrenOfNodeRecursive(node) {
  const children = [];

  if (node.children) {
    node.children.forEach((childNode) => {
      children.push(childNode);
      children.push(...getAllChildrenOfNodeRecursive(childNode));
    });
  }

  return children;
}

export function getAllChildNodesAndEdgesOfNodeRecursive(rootElement, node) {
  const nodes = [];
  const edges = [];

  if (node.children) {
    node.children.forEach((childNode) => {
      nodes.push(childNode);
      edges.push(
        d3
          .select(rootElement)
          .select(`#edgeId_${node.data.id}_${childNode.data.id}`)
      );

      const nodesAndEdges = getAllChildNodesAndEdgesOfNodeRecursive(
        rootElement,
        childNode
      );
      nodes.push(...nodesAndEdges.nodes);
      edges.push(...nodesAndEdges.edges);
    });
  }

  return { nodes, edges };
}
