import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./VerifyEmail.module.scss";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import Logger from "js-logger";
import Spinner from "../../UI/Spinner/Spinner";
import ErrorMessages from "../ErrorMessages";

const propTypesVerifyEmail = {};
const defaultPropsVerifyEmail = {};

const VERIFY_USER = gql`
  mutation($token: String!) {
    verifyUser(token: $token) {
      code
      message
    }
  }
`;

const VerifyEmail = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [token] = useState(queryString.parse(location.search).verifyToken);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const [verifyUser, { loading: verifyUserLoading }] = useMutation(
    VERIFY_USER,
    {
      onError: (error) => {
        Logger.error(error);
        setError(error);
      },
      onCompleted: (data) => {
        setResponse(data.verifyUser);
      },
    }
  );

  useEffect(() => {
    if (token) {
      verifyUser({
        variables: {
          token,
        },
      });
    }
  }, [token]);

  let content = null;

  if (verifyUserLoading) {
    content = (
      <div className={styles.innerContainer}>
        <div className={styles.loadingSpinner}>
          <Spinner />
        </div>

        <div className={styles.subtitle}>
          <p>{t("wisdomtree.verify.loading")}</p>
        </div>
      </div>
    );
  } else if (response) {
    if (response.code === "200") {
      content = (
        <div className={styles.innerContainer}>
          <p className={styles.title}>{t("wisdomtree.verify.success_title")}</p>
          <div className={styles.subtitle}>
            <p>{t("wisdomtree.verify.success_subtitle")}</p>
          </div>
          <a
            className={`button is-primary ${styles.button}`}
            href={process.env.REACT_APP_HOST}
          >
            {t("wisdomtree.verify.button")}
          </a>
        </div>
      );
    }
  } else if (error) {
    const errorCode = ErrorMessages.getErrorCode(error);
    content = (
      <div className={styles.innerContainer}>
        <p className={styles.title}>{t("wisdomtree.verify.failure_title")}</p>
        <div className={styles.subtitle}>
          <p>
            {errorCode === "VERIFICATION_TOKEN_EXPIRED"
              ? t("wisdomtree.verify.failure_subtitle2")
              : t("wisdomtree.verify.failure_subtitle1")}
          </p>
        </div>
        <a
          className={`button is-primary ${styles.button}`}
          href={process.env.REACT_APP_HOST}
        >
          {t("wisdomtree.verify.button")}
        </a>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <span className={`icon ${styles.icon}`}>
        <i
          className={`fas fa-3x ${error ? "fa-user-times" : "fa-user-check"}`}
        />
      </span>
      {content}
    </div>
  );
};

VerifyEmail.propTypes = propTypesVerifyEmail;
VerifyEmail.defaultProps = defaultPropsVerifyEmail;

export default VerifyEmail;
