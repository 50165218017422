import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./NewEntryButton.module.scss";
import { TooltipDelayed } from "../../../UI/Tooltip/Tooltip";
import GlowOverlay from "../../../UI/GlowOverlay/GlowOverlay";
import { hasQuestGoal } from "../../../Utils/QuestHelper";
import QuestIcon from "../../../UI/QuestIcon/QuestIcon";

const propTypes = {
  selectedEntry: PropTypes.shape({
    id: PropTypes.string,
  }),
  user: PropTypes.shape({
    currentQuest: PropTypes.object,
  }),
};

const defaultProps = {
  selectedEntry: null,
  user: null,
};

const NewEntryButton = ({ selectedEntry, user }) => {
  const { t } = useTranslation();
  const newButtonTo = selectedEntry
    ? `/entry/${selectedEntry.id}?new=true`
    : `/home?new=true`;

  const hasQuest = user ? hasQuestGoal(user, "entries") : false;

  return (
    <TooltipDelayed content={t("wisdomtree.entry_browser.tooltips.entry_new")}>
      <Link
        className={`${styles.button} button is-light`}
        to={newButtonTo}
        data-id="button.new-entry"
      >
        <span className={`icon ${styles.iconGray}`}>
          <i className="fas fa-plus" />
        </span>
        <span>{t("wisdomtree.entry_browser.new_entry")}</span>
        {hasQuest ? <QuestIcon /> : null}
      </Link>
    </TooltipDelayed>
  );
};

NewEntryButton.propTypes = propTypes;
NewEntryButton.defaultProps = defaultProps;

export default NewEntryButton;
