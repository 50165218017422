import React from "react";
import PropTypes from "prop-types";

import styles from "./Checkbox.module.scss";

const propTypesCheckbox = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  text: PropTypes.string,
};
const defaultPropsCheckbox = {
  checked: false,
  onChange: () => {},
  onClick: () => {},
  text: "Check me!",
};

const Checkbox = ({ checked, onChange, onClick, text }) => {
  return (
    <div className={`pretty p-default p-curve p-smooth ${styles.container}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        onClick={onClick}
      />
      <div className={`state p-primary ${styles.labelContainer}`}>
        <label>{text}</label>
      </div>
    </div>
  );
};

Checkbox.propTypes = propTypesCheckbox;
Checkbox.defaultProps = defaultPropsCheckbox;

export default Checkbox;
