/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { throttle } from "lodash";

import stylesModal from "../ChooseImageModal.module.scss";
import styles from "./SearchImage.module.scss";
import { useHotkey } from "../../../../Utils/Hotkeys";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import ImageGallery from "./ImageGallery/ImageGallery";
import SearchInput from "./SearchInput/SearchInput";
import { useMobile } from "../../../../Utils/Responsive";
import ImageSuggestions from "./ImageSuggestions/ImageSuggestions";
import { isValidHttpUrl } from "../../../../Utils/Utils";
import useDropZone, {
  TYPE_FILE,
  TYPE_URL,
  TYPE_HTML,
} from "../../../../Utils/UseDropZone";

const propTypesSearchImage = {
  onlyFileUpload: PropTypes.bool,
  onSelectImage: PropTypes.func.isRequired,
  onURLEntered: PropTypes.func.isRequired,
  onDropContent: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  entityType: PropTypes.string,
  entityId: PropTypes.string,
};
const defaultPropsSearchImage = {
  onlyFileUpload: false,
  hidden: false,
  entityType: null,
  entityId: null,
};

const SearchImage = ({
  onlyFileUpload,
  onSelectImage,
  onURLEntered,
  onDropContent,
  onCloseModal,
  hidden,
  entityType,
  entityId,
}) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [selectedSource, setSelectedSource] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const [scrollValues, setScrollValues] = useState(null);
  const scrollParent = useRef(null);

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (selectedSource) {
        setSelectedSource(null);
        return;
      }
      onCloseModal();
    },
    dependsOn: [selectedSource],
  });

  useEffect(() => {
    setSelectedSource(null);
  }, [searchInput]);

  const onImageUploaded = (e) => {
    onSelectImage(
      URL.createObjectURL(e.target.files[0]),
      { source: "Upload" },
      null
    );
  };

  const onImageSelected = (imageElement) => {
    onSelectImage(
      imageElement.url,
      {
        source: imageElement.source,
        sourceUrl: imageElement.url,
        author: imageElement.author,
        authorUrl: imageElement.authorUrl,
        sourceId: imageElement.sourceId,
        searchQuery: searchInput,
      },
      imageElement
    );
  };

  const [{ isOver, canDrop }, dropRef] = useDropZone({
    accept: [TYPE_FILE, TYPE_URL, TYPE_HTML],
    drop: (item) => {
      onDropContent(item);
    },
  });

  const onScrollHandler = useCallback(
    throttle(() => {
      if (scrollParent.current == null) return;
      setScrollValues({
        scrollTop: scrollParent.current.scrollTop,
        offsetHeight: scrollParent.current.offsetHeight,
        scrollHeight: scrollParent.current.scrollHeight,
      });
    }, 500),
    []
  );

  const onShowMoreClicked = (source, percentageScrolled) => {
    setSelectedSource(source);
    setTimeout(() => {
      const targetScroll =
        scrollParent.current.scrollHeight * percentageScrolled;
      scrollParent.current.scrollTo(0, targetScroll);
    }, 1);
  };

  let searchContent = null;
  let bodyContent = null;
  let footerContent = null;

  const search = (
    <SearchInput
      autoFocus
      onSearch={(query) => {
        const isURL = isValidHttpUrl(query);

        if (isURL) {
          onURLEntered(query);
        } else {
          setSearchInput(query);
        }
      }}
      onClear={() => {
        setSearchInput(null);
        setSelectedSource(null);
      }}
      initialQuery={searchInput}
    />
  );

  const fileUpload = (
    <div
      id={styles.fileUpload}
      className={`file ${
        onlyFileUpload ? styles.centerAligned : styles.rightAligned
      }`}
    >
      <label id={styles.fileLabel} className="file-label">
        <input
          className="file-input"
          type="file"
          name="image"
          multiple={false}
          accept="image/png, image/jpeg"
          onChange={onImageUploaded}
        />
        <span className="file-cta">
          <span
            className={`file-icon ${
              isMobile && !onlyFileUpload ? styles.fileIconMobile : null
            }`}
          >
            <i className="fas fa-upload" />
          </span>
          {!isMobile || onlyFileUpload ? (
            <span className="file-label">
              {t("wisdomtree.modal_choose_image.search.choose_file_button")}
            </span>
          ) : null}
        </span>
      </label>
    </div>
  );

  let suggestionsContent = null;
  if (searchInput == null && !hidden) {
    suggestionsContent = (
      <ImageSuggestions
        entityType={entityType}
        entityId={entityId}
        onSearchQuerySelected={(searchQuery) => {
          setSearchInput(searchQuery);
        }}
        onImageSelected={onImageSelected}
      />
    );
  }

  searchContent = (
    <section
      className={`${styles.searchContainer} ${
        canDrop && isOver ? styles.onDrop : null
      }`}
      ref={dropRef}
    >
      <div className={styles.searchTopBarContainer}>
        {onlyFileUpload ? null : search}
        {fileUpload}
      </div>
      {suggestionsContent}
    </section>
  );

  footerContent = (
    <footer className="modal-card-foot">
      {selectedSource ? (
        <button
          className="button"
          type="button"
          onClick={() => setSelectedSource(null)}
        >
          {t("wisdomtree.modal_choose_image.search.back")}
        </button>
      ) : null}
      <button className="button" type="button" onClick={onCloseModal}>
        {t("wisdomtree.modal_choose_image.search.cancel")}
      </button>
    </footer>
  );

  if (searchInput && searchInput.length > 0) {
    if (selectedSource == null) {
      bodyContent = (
        <>
          <ImageGallery
            preview
            query={searchInput}
            source="Google"
            // source="Unsplash"
            scrollValues={scrollValues}
            onImageSelected={onImageSelected}
            onShowMoreClicked={(percentageScrolled) => {
              onShowMoreClicked("Google", percentageScrolled);
            }}
          />
          <ImageGallery
            preview
            query={searchInput}
            source="Unsplash"
            // source="Unsplash"
            scrollValues={scrollValues}
            onImageSelected={onImageSelected}
            onShowMoreClicked={(percentageScrolled) => {
              onShowMoreClicked("Unsplash", percentageScrolled);
            }}
          />
        </>
      );
    } else {
      bodyContent = (
        <>
          <ImageGallery
            preview={false}
            query={searchInput}
            source={selectedSource}
            // source="Unsplash"
            scrollValues={scrollValues}
            onImageSelected={onImageSelected}
          />
        </>
      );
    }
  }

  return (
    <div
      className={`modal-card ${stylesModal.modalCard}`}
      style={{ display: hidden ? "none" : "flex" }}
      onPaste={(e) => {
        if (e.clipboardData.files && e.clipboardData.files.length > 0) {
          for (const file of e.clipboardData.files) {
            if (file.type.startsWith("image/")) {
              onDropContent({
                files: [file],
              });
              return;
            }
          }
        }
      }}
    >
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.modal_choose_image.search.title")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>

      {searchContent}

      <section
        className={`modal-card-body ${stylesModal.cardBody}`}
        onScroll={onScrollHandler}
        ref={scrollParent}
      >
        {bodyContent}
      </section>
      {footerContent}
    </div>
  );
};

SearchImage.propTypes = propTypesSearchImage;
SearchImage.defaultProps = defaultPropsSearchImage;

export default SearchImage;
