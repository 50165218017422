/* eslint-disable class-methods-use-this */
import * as d3 from "d3";

class GraphCamera {
  constructor(graphConfig, onZoomChanged) {
    this.graphConfig = graphConfig;
    this.onZoomChanged = onZoomChanged;
    this.locked = false;
  }

  initialize(rootElement, width, height) {
    this.width = width;
    this.height = height;

    const svg = d3.select("svg");
    const rootGroup = d3.select("#rootGroup");

    this.zoom = d3.zoom();
    this.zoom.scaleExtent([this.graphConfig.minZoom, this.graphConfig.maxZoom]);
    this.zoom.on("zoom", () => {
      this.onZoomChanged(d3.event.transform);
      rootGroup.attr("transform", d3.event.transform);
    });

    this.zoom.filter(() => {
      return !this.locked && !d3.event.ctrlKey && !d3.event.button;
    });

    svg.call(this.zoom).on("dblclick.zoom", null);

    this.zoomTo(
      rootElement,
      {
        x: 0,
        y: 0,
        scale: this.graphConfig.defaultZoom,
      },
      0
    );
  }

  lock() {
    this.locked = true;
  }

  unlock() {
    this.locked = false;
  }

  zoomToCenterEntry(rootElement, entryId) {
    if (!entryId) {
      this.zoomTo(
        rootElement,
        {
          x: 0,
          y: 0,
          scale: this.graphConfig.defaultZoom,
        },
        this.recentlyUpdated ? 0 : this.graphConfig.cameraPanDuration
      );
      return;
    }

    const newSelectedNode = d3
      .select(rootElement)
      .select(`#entryId_${entryId}`);
    const newSelectedNodeData = newSelectedNode.data()[0];
    this.zoomTo(
      rootElement,
      {
        x: newSelectedNodeData.data.x,
        y: newSelectedNodeData.data.y,
        scale: this.graphConfig.defaultZoom,
      },
      this.recentlyUpdated ? 0 : this.graphConfig.cameraPanDuration
    );
  }

  zoomToEntry(rootElement, entryId) {
    if (!entryId) {
      this.zoomToRootNode(rootElement);
      return;
    }

    const newSelectedNode = d3
      .select(rootElement)
      .select(`#entryId_${entryId}`);
    const newSelectionData = newSelectedNode.data()[0];
    this.zoomToFitEntryNode(rootElement, newSelectionData);
  }

  zoomToRootNode(rootElement) {
    const allNodesSelection = d3.select(rootElement).selectAll(".node");
    const allRootEntryNodes = [];
    allNodesSelection.each((node) => {
      if (node.depth === 1) allRootEntryNodes.push(node);
    });
    this.zoomToFitEntryNode(rootElement, {
      data: {
        x: 0,
        y: 0,
        nodeWidth: this.graphConfig.rootNodeRadius * 2,
        nodeHeight: this.graphConfig.rootNodeRadius * 2,
      },
      children: allRootEntryNodes,
    });
  }

  zoomToNode(rootElement, node) {
    const currentZoomTransform = this.getCurrentZoomTransform(rootElement);

    this.zoomTo(
      rootElement,
      {
        x: node.data.x,
        y: node.data.y,
        scale:
          currentZoomTransform.k < this.graphConfig.defaultZoom
            ? this.graphConfig.defaultZoom
            : null,
      },
      this.recentlyUpdated ? 0 : this.graphConfig.cameraPanDuration
    );
  }

  zoomToFitEntryNode(rootElement, entryNode) {
    const bounds = this.getBoundingRectForEntryNode(entryNode);
    this.zoomToFitBounds(rootElement, bounds, null);
  }

  zoomToFitNewEntryMode(rootElement, parentNode, newEntryNode) {
    let parentNodeSanitized = parentNode;
    if (parentNode == null) {
      parentNodeSanitized = {
        data: {
          x: 0,
          y: 0,
          nodeWidth: this.graphConfig.rootNodeRadius * 2,
          nodeHeight: this.graphConfig.rootNodeRadius * 2,
        },
      };
    }
    const bounds = this.getBoundingRectForNodes([
      parentNodeSanitized,
      newEntryNode,
    ]);
    this.zoomToFitBounds(rootElement, bounds, 0.5);
  }

  zoomToFitBounds(rootElement, bounds, fixedPadding) {
    const graphBounds = this.getGraphVisibleBounds();

    const midX = bounds.x + bounds.width / 2;
    const midY = bounds.y + bounds.height / 2;

    const graphHeight =
      graphBounds.height -
      Math.min(window.scrollY, this.graphConfig.scrollYScrollTopThreshold);
    const graphWidth = graphBounds.width;

    // http://bl.ocks.org/TWiStErRob/b1c62730e01fe33baa2dea0d0aa29359

    let targetPadding = fixedPadding;
    if (!fixedPadding) {
      targetPadding = this.graphConfig.zoomTargetPaddingPercentage;
      const scaleWithDefaultPadding =
        targetPadding /
        Math.max(bounds.width / graphWidth, bounds.height / graphHeight);

      if (scaleWithDefaultPadding < 1) {
        targetPadding =
          this.graphConfig.zoomTargetPaddingPercentage +
          (1 - scaleWithDefaultPadding) *
            (1 - this.graphConfig.zoomTargetPaddingPercentage);
      }
    }

    const optimalScale = Math.max(
      this.graphConfig.zoomTargetMinZoom,
      Math.min(
        this.graphConfig.zoomTargetMaxZoom,
        targetPadding /
          Math.max(bounds.width / graphWidth, bounds.height / graphHeight)
      )
    );

    // console.log({
    //   scaleWithDefaultPadding,
    //   defaultPadding: this.graphConfig.zoomTargetPaddingPercentage,
    //   targetPadding,
    //   optimalScale,
    // });

    this.zoomTo(
      rootElement,
      {
        x: midX,
        y: midY,
        scale: optimalScale,
      },
      this.recentlyUpdated ? 0 : this.graphConfig.cameraPanDuration
    );
  }

  zoomTo(rootElement, zoomObject, duration) {
    const svgSelection = d3.select(rootElement).select("svg");
    const currentZoomTransform = this.getCurrentZoomTransform(rootElement);

    const targetScale = zoomObject.scale
      ? zoomObject.scale
      : currentZoomTransform.k;
    const targetX =
      zoomObject.x != null
        ? this.width / 2 - zoomObject.x * targetScale
        : currentZoomTransform.x;
    const targetY =
      zoomObject.y != null
        ? this.height / 2 - zoomObject.y * targetScale
        : currentZoomTransform.y;

    if (duration === 0) {
      svgSelection.call(
        this.zoom.transform,
        d3.zoomIdentity.translate(targetX, targetY).scale(targetScale)
      );
    } else {
      svgSelection
        .transition()
        .duration(duration)
        .ease(d3.easeCubic)
        .call(
          this.zoom.transform,
          d3.zoomIdentity.translate(targetX, targetY).scale(targetScale)
        );
    }

    svgSelection
      .transition()
      .duration(duration)
      .ease(d3.easeCubic)
      .call(
        this.zoom.transform,
        d3.zoomIdentity.translate(targetX, targetY).scale(targetScale)
      );
  }

  getCurrentZoomTransform(rootElement) {
    return d3.zoomTransform(
      d3
        .select(rootElement)
        .select("svg")
        .node()
    );
  }

  getGraphVisibleBounds() {
    const graphContainer = document.getElementById("graphContainer");

    return {
      width: graphContainer.clientWidth,
      height: graphContainer.clientHeight,
    };
  }

  getBoundingRectForEntryNode(node) {
    const nodesToConsider = [node];

    if (node.children) {
      node.children.forEach((childNode) => {
        const distanceX = Math.abs(node.data.x - childNode.data.x);
        const distanceY = Math.abs(node.data.y - childNode.data.y);

        const visibleBounds = this.getGraphVisibleBounds();
        if (
          distanceX * this.graphConfig.zoomTargetMinZoom <
            visibleBounds.width * 0.8 &&
          distanceY * this.graphConfig.zoomTargetMinZoom <
            visibleBounds.height * 0.8
        ) {
          nodesToConsider.push(childNode);
        }
      });
    }

    let boundingRect = this.getBoundingRectForNodes(nodesToConsider);

    const diameter = Math.sqrt(
      boundingRect.width * boundingRect.width +
        boundingRect.height * boundingRect.height
    );

    if (node.parent) {
      const parentX = node.parent.data.x;
      const parentY = node.parent.data.y;

      const a = Math.abs(node.data.x - parentX);
      const b = Math.abs(node.data.y - parentY);
      const distanceToParent = Math.sqrt(a * a + b * b);

      if (
        diameter + distanceToParent <
        this.graphConfig.zoomTargetIncludeParentThreshold
      ) {
        nodesToConsider.push(node.parent);
        boundingRect = this.getBoundingRectForNodes(nodesToConsider);
      }
    }

    return boundingRect;
  }

  getBoundingRectForNodes(nodes) {
    let minX;
    let minY;
    let maxX;
    let maxY;

    nodes.forEach((n) => {
      const nodeMinX = n.data.x - n.data.nodeWidth / 2;
      const nodeMaxX = n.data.x + n.data.nodeWidth / 2;
      const nodeMinY = n.data.y - n.data.nodeHeight / 2;
      const nodeMaxY = n.data.y + n.data.nodeHeight / 2;

      if (minX === undefined || nodeMinX < minX) {
        minX = nodeMinX;
      }

      if (maxX === undefined || nodeMaxX > maxX) {
        maxX = nodeMaxX;
      }

      if (minY === undefined || nodeMinY < minY) {
        minY = nodeMinY;
      }

      if (maxY === undefined || nodeMaxY > maxY) {
        maxY = nodeMaxY;
      }
    });

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
  }
}

export default GraphCamera;
