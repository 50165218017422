/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./AddBlockDropdown.module.scss";
import { getIconForBlockType } from "../../../../../Utils/Utils";
import Dropdown from "../../../../../UI/Dropdown/Dropdown";
import { hasQuestGoal } from "../../../../../Utils/QuestHelper";

const propTypes = {
  onCreateBlock: PropTypes.func.isRequired,
  small: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onDropdownFocus: PropTypes.func,
  onDropdownBlur: PropTypes.func,
  user: PropTypes.shape({
    currentQuest: PropTypes.object,
  }),
};

const defaultProps = {
  small: false,
  isLoading: false,
  disabled: false,
  onDropdownFocus: () => {},
  onDropdownBlur: () => {},
  user: null,
};

const AddBlockDropdown = ({
  onCreateBlock,
  small,
  isLoading,
  disabled,
  onDropdownFocus,
  onDropdownBlur,
  user,
}) => {
  const { t } = useTranslation();

  const blockTypes = ["NOTE", "IMAGE", "QUOTE", "WEB"];
  const items = blockTypes.map((type) => {
    return {
      key: type,
      icon: getIconForBlockType(type),
      text: t(`wisdomtree.block.${type}`),
      tooltip: t(`wisdomtree.entry_browser.tooltips.block_type_${type}`),
    };
  });

  const hasQuest = user ? hasQuestGoal(user, "blocks") : false;

  return (
    <div id={styles.dropdownContainer}>
      <Dropdown
        data-id="dropdown.add-content"
        items={items}
        buttonText={
          small ? null : t(`wisdomtree.entry_browser.entry_add_content`)
        }
        buttonTooltip={
          small
            ? null
            : t("wisdomtree.entry_browser.tooltips.entry_add_content")
        }
        buttonIcon="fa-plus"
        small={small}
        disabled={disabled}
        hasQuest={hasQuest}
        isLoading={isLoading}
        isUsingHover={false}
        showDropdownIcon={false}
        onDropdownFocus={onDropdownFocus}
        onDropdownBlur={onDropdownBlur}
        onItemClicked={(item) => onCreateBlock(item.key)}
      />
    </div>
  );
};

AddBlockDropdown.propTypes = propTypes;
AddBlockDropdown.defaultProps = defaultProps;

export default AddBlockDropdown;
