/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";

import styles from "./ClickableImage.module.scss";
import { TooltipImage, TooltipDelayed } from "../Tooltip/Tooltip";
import Spinner from "../Spinner/Spinner";
import QuestIcon from "../QuestIcon/QuestIcon";
import useDropZone, {
  TYPE_FILE,
  TYPE_HTML,
  TYPE_URL,
} from "../../Utils/UseDropZone";

const propTypesClickableImage = {
  imageUrl: PropTypes.string,
  emptyIcon: PropTypes.string,
  editMode: PropTypes.bool,
  onImageClicked: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  altText: PropTypes.string,
  preview: PropTypes.bool,
  borderRadius: PropTypes.shape({
    bottomLeft: PropTypes.number,
    bottomRight: PropTypes.number,
    topLeft: PropTypes.number,
    topRight: PropTypes.number,
  }),
  round: PropTypes.bool,
  tooltip: PropTypes.string,
  isLoading: PropTypes.bool,
  hasQuest: PropTypes.bool,
};
const defaultPropsClickableImage = {
  imageUrl: null,
  emptyIcon: "fa-image",
  editMode: false,
  height: 128,
  width: 128,
  altText: "image",
  preview: true,
  borderRadius: {
    bottomLeft: 0,
    bottomRight: 0,
    topLeft: 0,
    topRight: 0,
  },
  round: false,
  tooltip: null,
  isLoading: false,
  hasQuest: false,
  dropAllowed: true,
  onImageClicked: () => {},
};

const ClickableImage = ({
  imageUrl,
  emptyIcon,
  editMode,
  dropAllowed,
  onImageClicked,
  onImageDropped,
  height,
  width,
  altText,
  preview,
  borderRadius,
  round,
  tooltip,
  isLoading,
  hasQuest,
}) => {
  const [{ isOver, canDrop }, dropRef] = useDropZone({
    accept: editMode ? [TYPE_FILE, TYPE_URL, TYPE_HTML] : [],
    drop: (item) => {
      if (onImageDropped) onImageDropped(item);
    },
  });

  let imageContent = null;

  let emptyIconClass = `fas fa-2x ${emptyIcon}`;
  if (width > 80) emptyIconClass = `fas fa-3x ${emptyIcon}`;
  if (width < 40) emptyIconClass = `fas fa ${emptyIcon}`;

  const imageStyle = {
    height,
    width,
    borderBottomLeftRadius: borderRadius.bottomLeft,
    borderBottomRightRadius: borderRadius.buttomRight,
    borderTopLeftRadius: borderRadius.topLeft,
    borderTopRightRadius: borderRadius.topRight,
  };

  if (round) imageStyle.borderRadius = "50%";

  let imageInnerContent = null;
  if (isLoading) {
    let spinnerSize = 8;
    if (width > 80) spinnerSize = 10;
    if (width < 40) spinnerSize = 4;

    imageInnerContent = <Spinner size={spinnerSize} />;
  } else if (imageUrl) {
    imageInnerContent = (
      <img
        src={imageUrl}
        key={imageUrl}
        alt={altText}
        height={height}
        width={width}
      />
    );
  } else {
    imageInnerContent = (
      <span
        className={`icon is-large ${styles.emptyImageIcon} ${
          editMode ? styles.emptyImageIconClickable : ""
        }`}
      >
        <i className={emptyIconClass} />
      </span>
    );
  }

  let sizeClass = styles.normal;
  if (width > 80) sizeClass = styles.big;
  if (width < 40) sizeClass = styles.small;

  imageContent = (
    <figure
      type="button"
      className={`image ${styles.emptyImage} ${sizeClass} ${
        styles.imageContainer
      } ${canDrop && isOver ? styles.onDrop : null}`}
      onClick={onImageClicked}
      style={imageStyle}
      ref={dropAllowed ? dropRef : null}
    >
      {imageInnerContent}
      {editMode && !isLoading ? (
        <div className={styles.selectionOverlay} />
      ) : null}
      {hasQuest && !isLoading ? (
        <div className={styles.questContainer}>
          <div>
            <QuestIcon />
          </div>
        </div>
      ) : null}
    </figure>
  );

  if (preview && imageUrl && !isLoading) {
    imageContent = (
      <TooltipImage imageUrl={imageUrl}>{imageContent}</TooltipImage>
    );
  } else if (!isLoading && tooltip && (!imageUrl || !preview)) {
    imageContent = (
      <TooltipDelayed content={tooltip}>{imageContent}</TooltipDelayed>
    );
  }

  return imageContent;
};

ClickableImage.propTypes = propTypesClickableImage;
ClickableImage.defaultProps = defaultPropsClickableImage;

export default ClickableImage;
