/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Logger from "js-logger";
import { useTranslation } from "react-i18next";

import styles from "./Tags.module.scss";
import Tag from "./Tag/Tag";
import Autocomplete from "../Autocomplete/Autocomplete";
import {
  GET_TAGS,
  DELETE_TAG,
  mergeTagsAfterDelete,
  UPDATE_TAG,
  mergeTagsAfterUpdate,
} from "../../GraphQl/tags";
import { TooltipDelayed } from "../Tooltip/Tooltip";
import useChooseColorModal from "../Modal/ChooseColorModal/ChooseColorModal";
import useDeleteTagModal from "../Modal/DeleteTagModal/DeleteTagModal";

const propTypesTags = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  onUpdateTags: PropTypes.func,
  onNewTagBlur: PropTypes.func,
  showNewButton: PropTypes.bool,
  tooltipAddNew: PropTypes.string,
  tooltipRemove: PropTypes.string,
  tooltipDeleteEverywhere: PropTypes.string,
  onDialogOpenedChanged: PropTypes.func,
  onTagRemoved: PropTypes.func,
  onTagColorChanged: PropTypes.func,
  editMode: PropTypes.bool,
};
const defaultPropsTags = {
  tags: [],
  onUpdateTags: () => {},
  onNewTagBlur: () => {},
  showNewButton: true,
  tooltipAddNew: null,
  tooltipRemove: null,
  tooltipDeleteEverywhere: null,
  onDialogOpenedChanged: () => {},
  onTagRemoved: () => {},
  onTagColorChanged: () => {},
  editMode: true,
};

const Tags = ({
  tags,
  onUpdateTags,
  onNewTagBlur,
  showNewButton,
  tooltipAddNew,
  tooltipRemove,
  tooltipDeleteEverywhere,
  onDialogOpenedChanged,
  onTagRemoved,
  onTagColorChanged,
  editMode,
}) => {
  const { t } = useTranslation();
  const [newTagActive, setNewTagActive] = useState(false);

  useEffect(() => {
    if (tags.length === 0) setNewTagActive(true);
  }, [tags]);

  const [getAllTags, { data: getAllTagsData }] = useLazyQuery(GET_TAGS, {
    onError: (err) => {
      Logger.error(err);
    },
  });
  const allTags = getAllTagsData ? getAllTagsData.tags : [];

  useEffect(() => {
    if (editMode) {
      getAllTags();
    }
  }, [editMode]);

  const [updateTagColor, { loading: updateTagColorLoading }] = useMutation(
    UPDATE_TAG,
    {
      update: mergeTagsAfterUpdate,
      onCompleted: () => {
        closeChooseTagColorModal();
      },
      onError: (err) => {
        Logger.error(err);
        closeChooseTagColorModal();
      },
    }
  );

  const [resetTagColor, { loading: resetTagColorLoading }] = useMutation(
    UPDATE_TAG,
    {
      update: mergeTagsAfterUpdate,
      onCompleted: () => {
        closeChooseTagColorModal();
      },
      onError: (err) => {
        Logger.error(err);
        closeChooseTagColorModal();
      },
    }
  );

  const [deleteTag, { loading: deleteTagLoading }] = useMutation(DELETE_TAG, {
    update: mergeTagsAfterDelete,
    onCompleted: () => {
      closeDeleteTagModal();
    },
    onError: (err) => {
      Logger.error(err);
      closeDeleteTagModal();
    },
  });

  const onConfirmDeleteTag = (tag) => {
    deleteTag({
      variables: {
        name: tag.name,
      },
    });
    onTagRemoved(tag);
  };

  const onChooseTagColor = (tag, color) => {
    updateTagColor({
      variables: {
        name: tag.name,
        input: {
          colorId: color.id,
        },
      },
    });
    onTagColorChanged();
  };

  const onResetTagColor = () => {
    resetTagColor({
      variables: {
        name: chooseTagColorCurrentTag.name,
        input: {
          colorId: null,
        },
      },
    });
    onTagColorChanged();
  };

  const onNewTagClick = () => {
    setNewTagActive(!newTagActive);
  };

  const onEnterNewTag = (newTag) => {
    // dont allow empty tags
    if (newTag.length === 0) return;

    // dont allow tags that are already added
    if (
      tags.find(
        (exsitingTag) => newTag.toUpperCase() === exsitingTag.name.toUpperCase()
      ) != null
    )
      return;

    onUpdateTags([...tags.map((tag) => tag.name), newTag]);
  };

  const onRemoveTag = (tagToRemove) => {
    // dont allow empty tags
    if (tagToRemove.length === 0) return;

    onUpdateTags([
      ...tags
        .filter((tag) => tag.name !== tagToRemove.name)
        .map((tag) => tag.name),
    ]);
    onTagRemoved(tagToRemove);
  };

  const onChangeColor = (tag) => {
    openChooseTagColorModal(tag);
  };

  const onDeleteTagEverywhere = (tagToDelete) => {
    if (tagToDelete.length === 0) return;

    openDeleteTagModal(tagToDelete);
  };

  const {
    open: openDeleteTagModal,
    close: closeDeleteTagModal,
    content: deleteTagModalContent,
  } = useDeleteTagModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
    onDeleteTag: onConfirmDeleteTag,
    loading: deleteTagLoading,
  });

  const {
    open: openChooseTagColorModal,
    close: closeChooseTagColorModal,
    content: chooseTagColorModalContent,
    entity: chooseTagColorCurrentTag,
  } = useChooseColorModal({
    onOpen: () => onDialogOpenedChanged(true),
    onClose: () => onDialogOpenedChanged(false),
    hasReset: (entity) => {
      return entity.color != null;
    },
    onColorChosen: onChooseTagColor,
    onReset: onResetTagColor,
    chooseLoading: updateTagColorLoading,
    resetLoading: resetTagColorLoading,
    initialColor: (entity) => {
      return entity.color ? entity.color : null;
    },
  });

  const tagsContent = tags.map((tag) => {
    return (
      <Tag
        key={tag.name}
        tag={tag}
        editMode={editMode}
        onRemove={onRemoveTag}
        onChangeColor={onChangeColor}
        onDeleteEverywhere={onDeleteTagEverywhere}
        tooltipRemove={tooltipRemove}
        tooltipDeleteEverywhere={tooltipDeleteEverywhere}
      />
    );
  });

  const suggestions = allTags
    .filter((ft) => {
      return tags.find((exsitingTag) => ft.name === exsitingTag.name) == null;
    })
    .map((ft) => ft.name);

  let newButtonContent = null;
  if (editMode) {
    newButtonContent = (
      <span
        onClick={onNewTagClick}
        role="button"
        tabIndex="0"
        className={`tag "is-light" is-normal ${styles.plusButton} ${
          !showNewButton ? styles.hidden : null
        }`}
      >
        +
      </span>
    );

    if (tooltipAddNew) {
      newButtonContent = (
        <TooltipDelayed content={tooltipAddNew}>
          {newButtonContent}
        </TooltipDelayed>
      );
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={`tags ${styles.tagsContainer}`}>
          {tagsContent}
          {newTagActive ? (
            <div className={styles.autoCompleteContainer}>
              <Autocomplete
                inputStyle={styles.input}
                suggestionItemStyles={{ fontSize: "0.75rem" }}
                hintText={t("wisdomtree.entry_browser.tag_add_new_hint")}
                maxLength={35}
                suggestions={suggestions}
                defaultSuggestion
                defaultSuggestionPostfix={` ${t(
                  "wisdomtree.entry_browser.tag_add_new_suggestion"
                )}`}
                shouldShowDefaultSuggestion={(text) => {
                  return (
                    tags.find(
                      (exsitingTag) =>
                        text.toUpperCase() === exsitingTag.name.toUpperCase()
                    ) == null
                  );
                }}
                popupMinWidth={200}
                autoFocus
                onAccept={onEnterNewTag}
                onBlur={() => {
                  setNewTagActive(false);
                  onNewTagBlur();
                }}
              />
            </div>
          ) : (
            newButtonContent
          )}
        </div>
      </div>
      {deleteTagModalContent}
      {chooseTagColorModalContent}
    </>
  );
};

Tags.propTypes = propTypesTags;
Tags.defaultProps = defaultPropsTags;

export default Tags;
