import React from "react";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import styles from "./Admin.module.scss";
import Header from "../Header/Header";
import Overview from "./Overview/Overview";
import UserDetail from "./UserDetail/UserDetail";
import UserManagement from "./UserManagement/UserManagement";
import CommonEntries from "./CommonEntries/CommonEntries";
import CommonEntryDetail from "./CommonEntryDetail/CommonEntryDetail";
import ChangeNotes from "./ChangeNotes/ChangeNotes";

const propTypesAdmin = {
  darkMode: PropTypes.bool,
};
const defaultPropsAdmin = {
  darkMode: false,
};

const Admin = ({ darkMode }) => {
  const content = (
    <Switch>
      <Route path="/admin/user/:userId" component={UserDetail} />
      <Route path="/admin/users" component={UserManagement} />
      <Route path="/admin/changeNotes" component={ChangeNotes} />
      <Route path="/admin/commonEntries" component={CommonEntries} />
      <Route
        path="/admin/commonEntry/:commonEntryId"
        component={CommonEntryDetail}
      />
      <Route path="/admin" component={Overview} />
    </Switch>
  );

  return (
    <>
      <Helmet>
        <title>Admin - WisdomTree</title>
      </Helmet>
      <div className={styles.container}>
        <Header
          darkMode={darkMode}
          hasInviteFriends={false}
          hasSearch={false}
          hasSettings={false}
        />

        <div className={styles.contentContainer}>{content}</div>
      </div>
    </>
  );
};

Admin.propTypes = propTypesAdmin;
Admin.defaultProps = defaultPropsAdmin;

export default Admin;
