import React from "react";
import PropTypes from "prop-types";

import styles from "./QuestIcon.module.scss";

const propTypesQuestIcon = {};
const defaultPropsQuestIcon = {};

const QuestIcon = () => {
  return (
    <span className={`icon ${styles.icon}`}>
      <i className="fas fa-exclamation-circle" />
    </span>
  );
};

QuestIcon.propTypes = propTypesQuestIcon;
QuestIcon.defaultProps = defaultPropsQuestIcon;

export default QuestIcon;
