import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./CategoryCard.module.scss";
import Spinner from "../../../Spinner/Spinner";
import Dropdown from "../../../Dropdown/Dropdown";
import { isMobile as isMobileDevice } from "react-device-detect";

const propTypesCategoryCard = {
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.shape({
      id: PropTypes.string,
      hex: PropTypes.string,
    }),
  }),
  onCategorySelected: PropTypes.func,
  onEditCategory: PropTypes.func,
  onDeleteCategory: PropTypes.func,
  isLoading: PropTypes.bool,
  selectable: PropTypes.bool,
};
const defaultPropsCategoryCard = {
  onCategorySelected: () => {},
  onEditCategory: () => {},
  selectable: true,
};

const CategoryCard = ({
  category,
  onCategorySelected,
  onEditCategory,
  onDeleteCategory,
  isLoading,
  selectable,
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const onClick = () => {
    if (selectable) onCategorySelected(category);
  };

  let colorContent = null;
  if (category) {
    colorContent = (
      <div
        className={styles.colorRect}
        style={{ backgroundColor: category.color.hex }}
      />
    );
  } else {
    colorContent = (
      <div className={styles.noneRect}>
        <span className={`icon ${styles.icon}`}>
          <i className="fas fa-slash" />
        </span>
      </div>
    );
  }

  let text = category
    ? category.name
    : t("wisdomtree.modal_set_category.no_category");
  const textContent = (
    <div className={styles.textContainer}>
      <p className={styles.name}>{text}</p>
      {category && category.description ? (
        <p className={styles.description}>{category.description}</p>
      ) : null}
    </div>
  );

  let loadingContent = null;
  if (isLoading)
    loadingContent = (
      <div className={styles.loadingContainer}>
        <Spinner size={8} />
      </div>
    );

  let actionsButton = null;
  if (category) {
    const actions = [];
    actions.push({
      key: "edit",
      icon: "fa-pen",
      text: t("wisdomtree.modal_edit_category.category_edit"),
      tooltip: t(`wisdomtree.entry_browser.tooltips.category_edit`),
      handler: onEditCategory,
    });
    actions.push({
      key: "remove",
      icon: "fa-trash",
      text: t("wisdomtree.modal_edit_category.category_delete"),
      tooltip: t(`wisdomtree.entry_browser.tooltips.category_delete`),
      handler: onDeleteCategory,
    });

    actionsButton = (
      <div className={styles.actionDropdown}>
        <Dropdown
          items={actions}
          buttonIcon="fa-ellipsis-h"
          buttonTooltip={t(
            "wisdomtree.entry_browser.tooltips.category_edit_dropdown"
          )}
          showDropdownIcon={false}
          isUsingHover={false}
          buttonHidden={!isMobileDevice && !isFocused && !isHovered}
          onItemClicked={(item) => item.handler()}
          alignment="is-right"
        />
      </div>
    );
  }

  return (
    <div
      className={`box ${styles.container} ${
        selectable ? styles.selectable : null
      } ${category == null ? styles.noCategory : null}}`}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        // enter key
        if (e.keyCode === 13) {
          onClick();
        }
      }}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
    >
      {colorContent}
      {textContent}
      {!isLoading ? actionsButton : null}
      {loadingContent}
    </div>
  );
};

CategoryCard.propTypes = propTypesCategoryCard;
CategoryCard.defaultProps = defaultPropsCategoryCard;

export default CategoryCard;
