import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import styles from "./VideoPlayer.module.scss";
import Spinner from "../Spinner/Spinner";

const propTypesVideoPlayer = {
  src: PropTypes.string.isRequired,
  paused: PropTypes.bool,
};
const defaultPropsVideoPlayer = {
  paused: false,
};

const VideoPlayer = ({ src, paused }) => {
  const [isLoading, setLoading] = useState(true);
  const [progress, setProgess] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    if (paused && videoRef.current) {
      videoRef.current.pause();
    } else if (!paused && videoRef.current) {
      videoRef.current.play();
    }
  }, [paused]);

  useEffect(() => {
    setProgess(0);
  }, [src]);

  const videoWidth = videoRef.current ? videoRef.current.clientWidth : 0;
  const videoHeight = videoRef.current ? videoRef.current.clientHeight : 0;

  return (
    <div className={styles.container}>
      <video
        key={src}
        ref={videoRef}
        className={`${styles.video} ${isLoading ? styles.isLoading : null}`}
        autoPlay
        playsInline
        muted
        loop
        height={600}
        width={1200}
        onCanPlay={() => {
          setLoading(false);
        }}
        onTimeUpdate={() => {
          if (videoRef.current) {
            const currentTime = videoRef.current.currentTime;
            const duration = videoRef.current.duration;

            const newProgress =
              Math.round((currentTime / duration) * 100) / 100;
            setProgess(newProgress);
          }
        }}
      >
        <source src={src} type="video/mp4" />
      </video>
      <div
        className={`${styles.progressContainer}`}
        style={{
          width: videoWidth,
        }}
      >
        <div
          className={`${styles.progress}`}
          style={{
            width: videoWidth * progress,
            // transform: `translateY(${-2}px)`,
          }}
        />
      </div>
      <div
        className={`${styles.spinnerContainer} ${
          isLoading ? styles.active : null
        }`}
      >
        <Spinner />
      </div>
    </div>
  );
};

VideoPlayer.propTypes = propTypesVideoPlayer;
VideoPlayer.defaultProps = defaultPropsVideoPlayer;

export default VideoPlayer;
