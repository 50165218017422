import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import Logger from "js-logger";

import styles from "./CommonEntries.module.scss";
import BackButton from "../UI/BackButton/BackButton";
import Title from "../UI/Title/Title";
import CommonEntriesList from "./CommonEntriesList/CommonEntriesList";
import CreateVariant from "./CreateVariant/CreateVariant";
import {
  CREATE_COMMON_ENTRY_VARIANT,
  mergeCommonEntriesAfterCreate,
  UPDATE_COMMON_ENTRIES,
} from "../../GraphQl/commonEntries";
import Spinner from "../../UI/Spinner/Spinner";

const propTypesCommonEntries = {};
const defaultPropsCommonEntries = {};

const CommonEntries = () => {
  const [createCommonEntry] = useMutation(CREATE_COMMON_ENTRY_VARIANT, {
    update: mergeCommonEntriesAfterCreate,
    onError: (err) => {
      Logger.error(err);
    },
  });

  const [
    updateCommonEntries,
    { loading: updateCommonEntriesLoading },
  ] = useMutation(UPDATE_COMMON_ENTRIES, {
    update: () => {
      window.location.reload();
    },
    onError: (err) => {
      Logger.error(err);
    },
  });

  let listContent = null;

  if (updateCommonEntriesLoading) {
    listContent = <Spinner />;
  } else {
    listContent = (
      <>
        <div className={styles.createNewContainer}>
          <CreateVariant
            placeholder="Create new common entry ..."
            onCreateVariant={({ name, parentId }) => {
              createCommonEntry({
                variables: {
                  input: {
                    name,
                    parentCommonEntryId: parentId.length > 0 ? parentId : null,
                    approved: true,
                  },
                },
              });
            }}
          />
        </div>

        <CommonEntriesList />
      </>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        <div className={styles.backButtonContainer}>
          <BackButton linkTo="/admin" />
        </div>

        <button
          type="button"
          className={`button is-light ${styles.updateButton}`}
          onClick={() => {
            updateCommonEntries();
          }}
        >
          <span className={`icon ${styles.gray}`}>
            <i className="fas fa-redo" />
          </span>
          <span>Update</span>
        </button>
      </div>

      <Title text="Common Entries" icon="fa-feather-alt" />
      {listContent}
    </div>
  );
};

CommonEntries.propTypes = propTypesCommonEntries;
CommonEntries.defaultProps = defaultPropsCommonEntries;

export default CommonEntries;
