import React from "react";
import PropTypes from "prop-types";

import styles from "./BlockIdentifier.module.scss";
import stylesVariables from "../../../variables.scss";
import { useTranslation } from "react-i18next";
import { getIconForBlockType, truncate } from "../../Utils/Utils";

const propTypesBlockIdentifier = {
  block: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    entry: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.name,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }).isRequired,
  textSize: PropTypes.string,
  textColor: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
};
const defaultPropsBlockIdentifier = {
  textSize: "16px",
  textColor: null,
  iconSize: "16px",
  iconColor: null,
};

const BlockIdentifier = ({
  block,
  textSize,
  textColor,
  iconSize,
  iconColor,
}) => {
  const { t } = useTranslation();

  const contentBlockIcon = (
    <span
      className={`${styles.icon} icon`}
      style={{
        width: iconSize,
        height: iconSize,
        maxHeight: iconSize,
        color: iconColor,
      }}
    >
      <i className={`fas ${getIconForBlockType(block.type)}`} />
    </span>
  );

  let contentBlockText = null;
  let blockText = null;
  if (block.title) {
    blockText = truncate(block.title, 22, true);
  } else {
    blockText = t(`wisdomtree.block.${block.type}`);
  }
  contentBlockText = (
    <p
      className={styles.blockText}
      style={{ fontSize: textSize, marginLeft: "6px", color: textColor }}
    >
      {blockText}
    </p>
  );

  const contentEntryName = (
    <p
      className={styles.blockText}
      style={{ fontSize: textSize, color: textColor }}
    >
      {truncate(block.entry.name, 22, true)}
    </p>
  );

  return (
    <>
      {contentEntryName}
      <p
        className={styles.textConnector}
        style={{ fontSize: textSize, color: iconColor }}
      >
        &ndash;
      </p>
      {contentBlockIcon}
      {contentBlockText}
    </>
  );
};

BlockIdentifier.propTypes = propTypesBlockIdentifier;
BlockIdentifier.defaultProps = defaultPropsBlockIdentifier;

export default BlockIdentifier;
