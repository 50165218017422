import { useMutation, useReactiveVar } from "@apollo/client";
import Logger from "js-logger";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mobileNotificationPermissionStatus } from "../../../config/apollo-cache";
import {
  mergeUserAfterRemovePushSubscription,
  REMOVE_PUSH_SUBSCRIPTION,
} from "../../GraphQl/user";
import Spinner from "../../UI/Spinner/Spinner";
import { logout } from "../../Utils/Logout";
import { browserSupportsPush } from "../../Utils/PushNotifications";
import useReactNativeEvents from "../../Utils/ReactNativeEventListener";
import {
  isMobileApp,
  requestDevicePushToken,
} from "../../Utils/ReactNativeMessageUtils";
import styles from "./Logout.module.scss";

const Logout = () => {
  const { t } = useTranslation();

  const [
    removePushSubscription,
    { loading: addPushSubscriptionLoading },
  ] = useMutation(REMOVE_PUSH_SUBSCRIPTION, {
    update: mergeUserAfterRemovePushSubscription,
    onError: (err) => {
      Logger.error(err);
      logout();
    },
    onCompleted: () => {
      logout();
    },
  });

  useReactNativeEvents({
    handleEvent: (event) => {
      if (event.type === "devicePushToken") {
        removePushSubscription({
          variables: {
            input: {
              subscription: { token: event.token },
            },
          },
        });
        return;
      }
    },
  });

  useEffect(() => {
    if (isMobileApp()) {
      if (mobileNotificationPermissionStatus() === "granted") {
        requestDevicePushToken();
      } else {
        logout();
      }
    } else if (!browserSupportsPush()) {
      logout();
    } else {
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager
          .getSubscription()
          .then((existingSubscription) => {
            if (existingSubscription) {
              removePushSubscription({
                variables: {
                  input: {
                    subscription: existingSubscription,
                  },
                },
              });
            } else {
              logout();
            }
          })
          .catch((error) => {
            Logger.error(error);
            logout();
          });
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <span className={`icon ${styles.icon}`}>
        <i className="fas fa-3x fa-sign-out-alt" />
      </span>
      <div className={styles.innerContainer}>
        <div className={styles.loadingSpinner}>
          <Spinner />
        </div>
        <div className={styles.subtitle}>
          <p>{t("auth.logout_hint")}</p>
        </div>
      </div>
    </div>
  );
};

export default Logout;
