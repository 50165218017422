import React from "react";
import PropTypes from "prop-types";

// import styles from "./BlockNote.module.scss";
import BlockHeader from "../BlockHeader/BlockHeader";

const propTypesBlockNote = {
  editMode: PropTypes.bool,
  blockTypeContent: PropTypes.node,
  blockActionsButtonContent: PropTypes.node,
  blockTitleEditorContent: PropTypes.node,
  blockCardsButtonContent: PropTypes.node,
  blockContentEditorContent: PropTypes.node,
  blockImageContent: PropTypes.node,
  blockTagsContent: PropTypes.node,
  blockReferencesContent: PropTypes.node,
};
const defaultPropsBlockNote = {
  editMode: true,
  blockActionsButtonContent: null,
  blockTypeContent: null,
  blockTitleEditorContent: null,
  blockCardsButtonContent: null,
  blockContentEditorContent: null,
  blockImageContent: null,
  blockTagsContent: null,
  blockReferencesContent: null,
};

const BlockNote = ({
  editMode,
  blockTypeContent,
  blockActionsButtonContent,
  blockTitleEditorContent,
  blockCardsButtonContent,
  blockContentEditorContent,
  blockImageContent,
  blockTagsContent,
  blockReferencesContent,
}) => {
  return (
    <>
      <BlockHeader
        editMode={editMode}
        noDivider={!editMode && !blockContentEditorContent}
        blockTypeContent={blockTypeContent}
        blockActionsButtonContent={blockActionsButtonContent}
        blockTitleEditorContent={blockTitleEditorContent}
        blockCardsButtonContent={blockCardsButtonContent}
        blockImageContent={blockImageContent}
      />
      {blockContentEditorContent}
      {blockTagsContent}
      {blockReferencesContent}
    </>
  );
};

BlockNote.propTypes = propTypesBlockNote;
BlockNote.defaultProps = defaultPropsBlockNote;

export default BlockNote;
