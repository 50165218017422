import React from "react";
import PropTypes from "prop-types";

import styles from "./DottedProgressIndicator.module.scss";
import { max } from "moment";
import { Circle } from "rc-progress";

const propTypesDottedProgressIndicator = {
  progress: PropTypes.number.isRequired,
  animatingTo: PropTypes.number,
  max: PropTypes.number.isRequired,
  onClickDot: PropTypes.func,
};
const defaultPropsDottedProgressIndicator = {
  animatingTo: null,
  onClickDot: () => {},
};

const DottedProgressIndicator = ({
  progress,
  max,
  animatingTo,
  onClickDot,
}) => {
  const dots = [];

  const current = animatingTo != null ? animatingTo : progress;
  const isAnimating = animatingTo != null;

  for (let i = 0; i < max; i++) {
    dots.push(
      <span
        key={i}
        role="button"
        onClick={() => {
          onClickDot(i);
        }}
        className={`${styles.dot} ${i === current ? styles.active : null} ${
          isAnimating ? styles.animating : null
        }`}
      ></span>
    );
  }

  return <div className={styles.container}>{dots}</div>;
};

DottedProgressIndicator.propTypes = propTypesDottedProgressIndicator;
DottedProgressIndicator.defaultProps = defaultPropsDottedProgressIndicator;

export default DottedProgressIndicator;
