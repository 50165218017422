import React from "react";
import PropTypes from "prop-types";

import styles from "./AnimatedEmoji.module.scss";

const propTypesAnimatedEmoji = {
  emoji: PropTypes.string.isRequired,
  size: PropTypes.number,
};
const defaultPropsAnimatedEmoji = {
  size: 20,
};

const AnimatedEmoji = ({ emoji, size }) => {
  return (
    <div className={styles.container}>
      <span style={{ fontSize: size }}>{emoji}</span>
    </div>
  );
};

AnimatedEmoji.propTypes = propTypesAnimatedEmoji;
AnimatedEmoji.defaultProps = defaultPropsAnimatedEmoji;

export default AnimatedEmoji;
