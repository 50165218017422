import React from "react";
import PropTypes from "prop-types";

import styles from "./ExpProgress.module.scss";

const propTypesExpProgress = {
  levelProgress: PropTypes.number.isRequired,
};
const defaultPropsExpProgress = {};

const ExpProgress = ({ levelProgress }) => {
  const levelProgressPercent = Math.max(levelProgress * 100, 3);

  return (
    <div className={styles.progressbarOuter}>
      <div
        className={styles.progressbarInner}
        style={{ width: `${levelProgressPercent}%` }}
      />
    </div>
  );
};

ExpProgress.propTypes = propTypesExpProgress;
ExpProgress.defaultProps = defaultPropsExpProgress;

export default ExpProgress;
