import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isMobile as isMobileDevice } from "react-device-detect";

import styles from "./BlockQuote.module.scss";
import BlockHeader from "../BlockHeader/BlockHeader";
import ClickableImage from "../../../../../../UI/ClickableImage/ClickableImage";
import { useTranslation } from "react-i18next";

const propTypesBlockQuote = {
  block: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  onAddImage: PropTypes.func,
  onUpdateBlockSource: PropTypes.func,
  blockTypeContent: PropTypes.node,
  blockActionsButtonContent: PropTypes.node,
  blockTitleEditorContent: PropTypes.node,
  blockCardsButtonContent: PropTypes.node,
  blockContentEditorContent: PropTypes.node,
  blockImageContent: PropTypes.node,
  blockTagsContent: PropTypes.node,
  blockReferencesContent: PropTypes.node,
  blockDailyQuoteButtonContent: PropTypes.node,
};
const defaultPropsBlockQuote = {
  editMode: true,
  onAddImage: () => {},
  onUpdateBlockSource: () => {},
  blockActionsButtonContent: null,
  blockTypeContent: null,
  blockTitleEditorContent: null,
  blockCardsButtonContent: null,
  blockContentEditorContent: null,
  blockTagsContent: null,
  blockReferencesContent: null,
  blockDailyQuoteButtonContent: null,
};

const BlockQuote = ({
  block,
  editMode,
  onAddImage,
  onUpdateBlockSource,
  blockTypeContent,
  blockActionsButtonContent,
  blockTitleEditorContent,
  blockCardsButtonContent,
  blockContentEditorContent,
  blockTagsContent,
  blockReferencesContent,
  blockDailyQuoteButtonContent,
}) => {
  const { t } = useTranslation();
  const [source, setSource] = useState("");

  useEffect(() => {
    setSource(block.source ? block.source : "");
  }, []);

  const blockImageContent = (
    <ClickableImage
      editMode={editMode || block.image}
      imageUrl={block.image ? block.image.url : null}
      isLoading={block.isImageUpdating}
      round
      emptyIcon="fa-user"
      height={34}
      width={34}
      onImageClicked={() => {
        if ((editMode || block.image) && !block.isImageUpdating) onAddImage();
      }}
      onImageDropped={(drop) => {
        if (editMode && !block.isImageUpdating) onAddImage(drop);
      }}
      altText="author"
      preview={!isMobileDevice}
    />
  );

  const sourceInputContent = (
    <input
      className={`${styles.source}`}
      type="text"
      value={source}
      disabled={!editMode}
      placeholder={t("wisdomtree.entry_browser.block_quote_unknown")}
      onChange={(event) => {
        const newSource = event.target.value;
        setSource(newSource);
        onUpdateBlockSource(newSource);
      }}
    />
  );

  return (
    <>
      <BlockHeader
        editMode={editMode}
        blockTypeContent={blockTypeContent}
        blockActionsButtonContent={blockActionsButtonContent}
        blockTitleEditorContent={blockTitleEditorContent}
        blockCardsButtonContent={blockCardsButtonContent}
        blockDailyQuoteButtonContent={blockDailyQuoteButtonContent}
      />
      {blockContentEditorContent}
      <div className={styles.sourceContainer}>
        <span className={styles.dash}>—</span>
        {blockImageContent}
        {sourceInputContent}
      </div>
      {blockTagsContent}
      {blockReferencesContent}
    </>
  );
};

BlockQuote.propTypes = propTypesBlockQuote;
BlockQuote.defaultProps = defaultPropsBlockQuote;

export default BlockQuote;
