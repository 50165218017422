/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";

import styles from "./BlockImage.module.scss";
import BlockHeader from "../BlockHeader/BlockHeader";
import useDropZone, {
  TYPE_FILE,
  TYPE_URL,
  TYPE_HTML,
} from "../../../../../../Utils/UseDropZone";

const propTypesBlockImage = {
  block: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  editMode: PropTypes.bool,
  blockTypeContent: PropTypes.node,
  blockActionsButtonContent: PropTypes.node,
  blockTitleEditorContent: PropTypes.node,
  blockContentEditorContent: PropTypes.node,
  blockCardsButtonContent: PropTypes.node,
  blockTagsContent: PropTypes.node,
  blockReferencesContent: PropTypes.node,
  onAddImage: PropTypes.func.isRequired,
};
const defaultPropsBlockImage = {
  editMode: true,
  blockActionsButtonContent: null,
  blockTypeContent: null,
  blockTitleEditorContent: null,
  blockContentEditorContent: null,
  blockCardsButtonContent: null,
  blockTagsContent: null,
  blockReferencesContent: null,
};

const BlockImage = ({
  block,
  editMode,
  blockTypeContent,
  blockActionsButtonContent,
  blockTitleEditorContent,
  blockContentEditorContent,
  blockCardsButtonContent,
  blockTagsContent,
  blockReferencesContent,
  onAddImage,
}) => {
  const { t } = useTranslation();
  const {
    ref: containerRef,
    width: backgroundWidth = 0,
    height: backgroundHeight = 0,
  } = useResizeObserver();

  const [{ isOver, canDrop }, dropRef] = useDropZone({
    accept: [TYPE_FILE, TYPE_URL, TYPE_HTML],
    drop: (item) => {
      onAddImage(item);
    },
  });

  let blockFullImageContent;

  if (block.image || editMode) {
    blockFullImageContent = (
      <div className={`${styles.container}`}>
        <div
          className={`${styles.dropContainer} ${
            canDrop && isOver ? styles.onDrop : null
          }`}
          ref={dropRef}
        >
          <div
            className={`${styles.innerContainer} ${
              !block.image ? styles.emptyImage : null
            }`}
            onClick={() => {
              onAddImage();
            }}
            role="button"
            tabIndex={0}
            ref={containerRef}
          >
            {block.image ? (
              <img
                className={styles.imageBackground}
                src={block.image.url}
                alt="background"
                style={{
                  width: backgroundWidth,
                  height: backgroundHeight,
                }}
              />
            ) : null}
            <figure
              type="button"
              className={`image ${styles.imageContainer}`}
              onClick={() => {}}
            >
              {block.image ? (
                <img src={block.image.url} alt="block" />
              ) : (
                <div className={styles.emptyImageContainer}>
                  <span
                    className={`icon is-large ${styles.emptyImageIcon} ${
                      editMode ? styles.emptyImageIconClickable : ""
                    }`}
                  >
                    <i className="fas fa-3x fa-image" />
                  </span>
                  <p>{t("wisdomtree.entry_browser.block_image_choose_hint")}</p>
                </div>
              )}
            </figure>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <BlockHeader
        editMode={editMode}
        noDivider
        blockTypeContent={blockTypeContent}
        blockActionsButtonContent={blockActionsButtonContent}
        blockTitleEditorContent={blockTitleEditorContent}
        blockCardsButtonContent={blockCardsButtonContent}
      />
      {blockFullImageContent}
      {blockContentEditorContent}
      {blockTagsContent}
      {blockReferencesContent}
    </>
  );
};

BlockImage.propTypes = propTypesBlockImage;
BlockImage.defaultProps = defaultPropsBlockImage;

export default BlockImage;
