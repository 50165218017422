/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./BlockType.module.scss";
import { getIconForBlockType } from "../../../Utils/Utils";

const propTypes = {
  blockType: PropTypes.string.isRequired,
  onDoubleTap: PropTypes.func,
};

const defaultProps = {
  onDoubleTap: () => {},
};

const BlockType = ({ blockType }) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={styles.container}>
      <span className={`icon ${styles.gray}`}>
        <i className={`fas ${getIconForBlockType(blockType)}`} />
      </span>
      <p className={`${styles.typeText} ${styles.gray}`}>
        {t(`wisdomtree.block.${blockType}`)}
      </p>
    </div>
  );
};

BlockType.propTypes = propTypes;
BlockType.defaultProps = defaultProps;

export default BlockType;
