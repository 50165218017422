import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Logger from "js-logger";

import styles from "./FeedbackContent.module.scss";
import NewMessage from "../../../UI/Chat/NewMessage/NewMessage";
import {
  CREATE_MESSAGE,
  mergeConversationAfterUpdate,
  GET_CONVERSATION,
  MARK_MESSAGES_AS_READ,
  resetUnreadCountAfterUpdate,
} from "../../../GraphQl/feedback";
import MessageList from "../../../UI/Chat/MessageList/MessageList";
import Spinner from "../../../UI/Spinner/Spinner";
import { useMobileOrTablet } from "../../../Utils/Responsive";

const propTypesFeedbackContent = {};
const defaultPropsFeedbackContent = {};

const FeedbackContent = () => {
  const isMobileOrTablet = useMobileOrTablet();

  const {
    loading: getConversationLoading,
    data: getConversationData,
    refetch: refetchConversation,
  } = useQuery(GET_CONVERSATION, {
    pollInterval: 2000,
  });
  const conversation = getConversationData
    ? getConversationData.conversation
    : null;

  const [createMessage] = useMutation(CREATE_MESSAGE, {
    update: mergeConversationAfterUpdate,
    onError: (err) => {
      Logger.error(err);
    },
  });

  const [markMessagesAsRead] = useMutation(MARK_MESSAGES_AS_READ, {
    update: resetUnreadCountAfterUpdate,
    onError: (err) => {
      Logger.error(err);
    },
  });

  useEffect(() => {
    refetchConversation();
  }, []);

  useEffect(() => {
    markMessagesAsRead();
  }, [conversation]);

  const sendNewMessageHandler = (message) => {
    createMessage({
      variables: {
        input: {
          text: message,
        },
      },
    });
  };

  let messageContent = null;
  if (getConversationLoading) {
    messageContent = (
      <div className={styles.loadingContainer}>
        <Spinner />
      </div>
    );
  } else if (getConversationData) {
    messageContent = (
      <MessageList messages={getConversationData.conversation} isUserChat />
    );
  }

  return (
    <div className={styles.feedbackContainer}>
      {messageContent}
      <NewMessage
        onSendNewMessage={sendNewMessageHandler}
        focusInitially={!isMobileOrTablet}
        saveDraft
      />
    </div>
  );
};

FeedbackContent.propTypes = propTypesFeedbackContent;
FeedbackContent.defaultProps = defaultPropsFeedbackContent;

export default FeedbackContent;
