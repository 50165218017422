import { gql } from "@apollo/client";

const FRAGMENT_BASIC_COLOR = gql`
  fragment BasicColor on Color {
    id
    hex
  }
`;

const FRAGMENT_COMPLETE_COLOR = gql`
  fragment CompleteColor on Color {
    id
    hex
    usageCount
  }
`;

const FRAGMENT_COMPLETE_TAG = gql`
  fragment CompleteTag on Tag {
    id
    name
    color {
      ...BasicColor
    }
  }
  ${FRAGMENT_BASIC_COLOR}
`;

const FRAGMENT_BASIC_CATEGORY = gql`
  fragment BasicCategory on Category {
    id
    type
    name
    description
    color {
      ...BasicColor
    }
  }
  ${FRAGMENT_BASIC_COLOR}
`;

const FRAGMENT_COMPLETE_CATEGORY = gql`
  fragment CompleteCategory on Category {
    id
    type
    name
    description
    color {
      ...BasicColor
    }
    usageCount
  }
  ${FRAGMENT_BASIC_COLOR}
`;

const FRAGMENT_COMPLETE_WEB_LINK = gql`
  fragment CompleteWebLink on WebLink {
    id
    url
    title
    description
    source
    imageUrl
    iconUrl
    lastFetchedAt
    updatedAt
    createdAt
  }
`;

const FRAGMENT_COMPLETE_REFERENCE_ENDPOINT = gql`
  fragment CompleteReferenceEndpoint on ReferenceEndpoint {
    entry {
      id
      name
      image {
        id
        url
      }
    }
    block {
      id
      title
      type
      entry {
        id
        name
        image {
          id
          url
        }
      }
    }
  }
`;

const FRAGMENT_COMPLETE_REFERENCE = gql`
  fragment CompleteReference on Reference {
    id
    type
    title
    descriptionTo
    descriptionFrom
    source {
      ...CompleteReferenceEndpoint
    }
    target {
      ...CompleteReferenceEndpoint
    }
    webLink {
      ...CompleteWebLink
    }
    createdAt
  }
  ${FRAGMENT_COMPLETE_REFERENCE_ENDPOINT}
  ${FRAGMENT_COMPLETE_WEB_LINK}
`;

const FRAGMENT_BASIC_ENTRY = gql`
  fragment BasicEntry on Entry {
    id
    name
    parentEntryId
    childEntryIds
    image {
      id
      url
    }
    isImageUpdating @client
    tags {
      ...CompleteTag
    }
    category {
      ...BasicCategory
    }
    x
    y
    size
    createdAt
  }
  ${FRAGMENT_COMPLETE_TAG}
  ${FRAGMENT_BASIC_CATEGORY}
`;

const FRAGMENT_COMPLETE_BLOCK = gql`
  fragment CompleteBlock on Block {
    id
    entryId
    type
    position
    title
    content
    source
    image {
      id
      url
      sourceUrl
      author
      authorUrl
      source
    }
    isImageUpdating @client
    cardsAmount
    cardsActiveAmount
    dusted
    dust
    dailyQuote
    tags {
      ...CompleteTag
    }
    references {
      ...CompleteReference
    }
    referencedBy {
      ...CompleteReference
    }
    webLink {
      ...CompleteWebLink
    }
  }
  ${FRAGMENT_COMPLETE_TAG}
  ${FRAGMENT_COMPLETE_REFERENCE}
  ${FRAGMENT_COMPLETE_WEB_LINK}
`;

const FRAGMENT_DETAILED_ENTRY = gql`
  fragment DetailedEntry on Entry {
    id
    cardsActiveAmountRecursive
    cardsDueAmountRecursive
    shared
    bookmarked
    image {
      id
      url
      sourceUrl
      author
      authorUrl
      source
    }
    references {
      ...CompleteReference
    }
    referencedBy {
      ...CompleteReference
    }
    blocks {
      ...CompleteBlock
    }
  }
  ${FRAGMENT_COMPLETE_REFERENCE}
  ${FRAGMENT_COMPLETE_BLOCK}
`;

const FRAGMENT_COMPLETE_ENTRY = gql`
  fragment CompleteEntry on Entry {
    ...BasicEntry
    ...DetailedEntry
  }
  ${FRAGMENT_BASIC_ENTRY}
  ${FRAGMENT_DETAILED_ENTRY}
`;

const FRAGMENT_COMPLETE_CARD = gql`
  fragment CompleteCard on Card {
    id
    type
    active
    valid
    difficulty
    daysBetweenReviews
    lastReviewed
    frontText
    frontTitle
    frontImage {
      id
      url
      sourceUrl
      author
      authorUrl
      source
    }
    isFrontImageUpdating @client
    frontTextFromParent
    frontTitleFromParent
    frontImageFromParent
    backText
    backTitle
    backImage {
      id
      url
      sourceUrl
      author
      authorUrl
      source
    }
    isBackImageUpdating @client
    backTextFromParent
    backTitleFromParent
    backImageFromParent
    updatedAt
    createdAt
  }
`;

const FRAGMENT_COMPLETE_COMMON_ENTRY_VARIANT = gql`
  fragment CompleteCommonEntryVariant on CommonEntryVariant {
    id
    name
    approved
    language
    userCount
    createdAt
    commonEntry {
      id
      name
    }
    parentCommonEntry {
      id
      name
    }
  }
`;

const FRAGMENT_COMPLETE_COMMON_ENTRY = gql`
  fragment CompleteCommonEntry on CommonEntry {
    id
    name
    userCount
    createdAt
  }
`;

const FRAGMENT_COMPLETE_COMMON_ENTRY_WITH_VARIANTS = gql`
  fragment CompleteCommonEntryWithVariants on CommonEntry {
    id
    name
    userCount
    createdAt
    variants {
      ...CompleteCommonEntryVariant
    }
    images {
      id
      imageSource {
        url
      }
      userCount
      approved
      createdAt
    }
    imageSearchQueries {
      id
      query
      userCount
      approved
      createdAt
    }
  }
  ${FRAGMENT_COMPLETE_COMMON_ENTRY_VARIANT}
`;

const FRAGMENT_COMPLETE_CHANGE_NOTE = gql`
  fragment CompleteChangeNote on ChangeNote {
    id
    text_en
    text_de
    createdAt
  }
`;

const FRAGMENT_COMPLETE_QUEST = gql`
  fragment CompleteQuest on Quest {
    type
    completed
    progress
  }
`;

const FRAGMENT_USER_UPDATE = gql`
  fragment UserUpdate on User {
    size
    experienceAmount
    level
    levelProgress
    experienceMissingForNextLevel
    currentQuest {
      ...CompleteQuest
    }
  }
  ${FRAGMENT_COMPLETE_QUEST}
`;

const FRAGMENT_COMPLETE_USER_EXP_EVENT = gql`
  fragment CompleteUserExpEvent on UserExpEvent {
    exp
    levelUp
  }
`;

const FRAGMENT_BASIC_FEATURE_REQUEST = gql`
  fragment BasicFeatureRequest on FeatureRequest {
    id
    title
    description
    status
    votes
    hasVoted
    commentsAmount
  }
`;

const FRAGMENT_COMPLETE_FEATURE_REQUEST_COMMENT = gql`
  fragment CompleteFeatureRequestComment on FeatureRequestComment {
    id
    text
    author {
      id
      name
      image {
        id
        url
      }
    }
    createdAt
    updatedAt
  }
`;

const FRAGMENT_COMPLETE_FEATURE_REQUEST = gql`
  fragment CompleteFeatureRequest on FeatureRequest {
    id
    title
    description
    status
    votes
    hasVoted
    commentsAmount
    comments {
      ...CompleteFeatureRequestComment
    }
    author {
      id
      name
      image {
        id
        url
      }
    }
    createdAt
    updatedAt
  }
  ${FRAGMENT_COMPLETE_FEATURE_REQUEST_COMMENT}
`;

export {
  FRAGMENT_BASIC_ENTRY,
  FRAGMENT_DETAILED_ENTRY,
  FRAGMENT_COMPLETE_ENTRY,
  FRAGMENT_COMPLETE_BLOCK,
  FRAGMENT_COMPLETE_CARD,
  FRAGMENT_COMPLETE_TAG,
  FRAGMENT_COMPLETE_COMMON_ENTRY_VARIANT,
  FRAGMENT_COMPLETE_COMMON_ENTRY_WITH_VARIANTS,
  FRAGMENT_COMPLETE_COMMON_ENTRY,
  FRAGMENT_COMPLETE_CHANGE_NOTE,
  FRAGMENT_USER_UPDATE,
  FRAGMENT_COMPLETE_USER_EXP_EVENT,
  FRAGMENT_COMPLETE_WEB_LINK,
  FRAGMENT_COMPLETE_QUEST,
  FRAGMENT_BASIC_CATEGORY,
  FRAGMENT_COMPLETE_CATEGORY,
  FRAGMENT_BASIC_COLOR,
  FRAGMENT_COMPLETE_COLOR,
  FRAGMENT_BASIC_FEATURE_REQUEST,
  FRAGMENT_COMPLETE_FEATURE_REQUEST,
  FRAGMENT_COMPLETE_FEATURE_REQUEST_COMMENT,
};
