import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import styles from "./FeatureGroup.module.scss";
import Feature from "./Feature/Feature";
import useFeatureRequestModal from "../../UI/FeatureRequestModal/FeatureRequestModal";

const propTypesFeatureGroup = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  hasSubmitButton: PropTypes.bool,
};
const defaultPropsFeatureGroup = {
  color: "#009f4b",
  features: [],
  hasSubmitButton: false,
};

const FeatureGroup = ({ title, color, features, hasSubmitButton }) => {
  const history = useHistory();
  const {
    open: openFeatureRequestModal,
    content: featureRequestModalContent,
  } = useFeatureRequestModal({
    onOpen: () => {},
    onClose: () => {},
    onCreate: (createdFeatureRequest) => {
      history.push(`/roadmap/feature/${createdFeatureRequest.id}`);
    },
  });

  const featuresContent = features
    .sort((a, b) => b.votes - a.votes)
    .map((feature) => <Feature key={feature.id} {...feature} />);
  return (
    <div className={styles.container}>
      <div className={styles.groupTitleContainer}>
        {color != null ? (
          <span
            className={styles.groupColor}
            style={{ backgroundColor: color }}
          ></span>
        ) : null}
        <span className={styles.groupTitle}>{title}</span>
        {hasSubmitButton ? (
          <button
            className={`${styles.submitButton} button is-light`}
            onClick={() => {
              openFeatureRequestModal();
            }}
          >
            <span className={`icon ${styles.icon}`}>
              <i className="fas fa-plus" />
            </span>
            <span>Submit</span>
          </button>
        ) : null}
      </div>
      <div className={styles.featuresListContainer}>{featuresContent}</div>
      {featureRequestModalContent}
    </div>
  );
};

FeatureGroup.propTypes = propTypesFeatureGroup;
FeatureGroup.defaultProps = defaultPropsFeatureGroup;

export default FeatureGroup;
