/* eslint-disable no-loop-func */
import * as d3 from "d3";
import { isColorLight } from "../../Utils/Utils";

class EntrySelection {
  constructor(graphConfig, entryContextMenu) {
    this.graphConfig = graphConfig;
    this.entryContextMenu = entryContextMenu;
  }

  getCurrentId() {
    return this.selectedEntryId;
  }

  getPreviousId() {
    return this.lastSelectedEntryId;
  }

  hasSelectedNode() {
    return this.selectedEntryId != null;
  }

  getSelectedNodeColors(entry) {
    let selectColor = this.graphConfig.nodeSelectedColor;
    let selectTextColor = this.graphConfig.nodeTextSelectColor;
    if (entry.category && entry.category.color) {
      selectColor = entry.category.color.hex;
      const isSelectColorLight = isColorLight(selectColor);
      if (isSelectColorLight)
        selectTextColor = this.graphConfig.nodeTextSelectColorLight;
    }
    return { selectColor, selectTextColor };
  }

  updateNodeStyleForSelection(
    rootElement,
    entryId,
    selectColor,
    selectTextColor
  ) {
    const newSelectedNode = d3
      .select(rootElement)
      .select(`#entryId_${entryId}`);

    newSelectedNode
      .select(".nodeIndicatorHiddenChildrenRect")
      .attr("display", (d) => {
        return "none";
      });

    newSelectedNode.select(".nodeRect").attr("fill", selectColor);

    newSelectedNode.select(".nodeRect").attr("stroke-width", 0);
    newSelectedNode.select(".nodeText").attr("fill", selectTextColor);

    newSelectedNode.select(".nodeIndicatorRect").attr("fill", selectColor);
    newSelectedNode.select(".nodeIndicatorText").attr("fill", selectTextColor);
  }

  updateRootNodeStyleForSelection(rootElement) {
    const rootNode = d3.select(rootElement).select("#entryId_-1");
    rootNode
      .select("circle")
      .attr("stroke", this.graphConfig.nodeSelectedColor)
      .attr("stroke-width", 4);
    rootNode.select(".nodeIndicatorHiddenChildrenRect").attr("display", (d) => {
      return "none";
    });

    rootNode
      .select(".nodeIndicatorRect")
      .attr("fill", this.graphConfig.nodeSelectedColor);

    d3.select(rootElement)
      .select("#entryId_-1")
      .select(".rootNodeSize")
      .select(".nodeIndicatorText")
      .attr("fill", "#fff");
  }

  preSelect(rootElement, entryId, entries) {
    if (!entryId) {
      // rootnode was selected
      return;
    }
    const entry = entries.find((entry) => entry.id === entryId);
    this.preSelectNode(rootElement, entryId, entry);
  }

  select(rootElement, entryId, entries) {
    if (this.hasSelectedNode()) {
      this.deselectNode(rootElement);
    } else if (!this.hasSelectedNode() && entryId) {
      // root node was selected previously and now changed
      this.deselectRootNode(rootElement);
      this.showRootNodeHiddenChildrenIfNeeded(rootElement, entryId, entries);
    }

    if (!entryId) {
      // rootnode was selected
      this.selectRootNode(rootElement);
      return;
    }

    this.lastSelectedEntryId = this.selectedEntryId;
    const entry = entries.find((entry) => entry.id === entryId);
    this.selectNode(rootElement, entryId, entry);
  }

  // indicate selection for direct click feedback
  preSelectNode(rootElement, entryId, entry) {
    const { selectColor, selectTextColor } = this.getSelectedNodeColors(entry);

    this.updateNodeStyleForSelection(
      rootElement,
      entryId,
      selectColor,
      selectTextColor
    );
  }

  selectNode(rootElement, entryId, entry) {
    const { selectColor, selectTextColor } = this.getSelectedNodeColors(entry);

    this.updateNodeStyleForSelection(
      rootElement,
      entryId,
      selectColor,
      selectTextColor
    );

    setTimeout(() => {
      this.entryContextMenu.showOn(
        document.querySelector(`#entryId_${entryId} .nodeRect`),
        entryId
      );
    }, 0);

    this.selectedEntryId = entryId;
  }

  deselectNode(rootElement) {
    const previouslySelectedNode = d3
      .select(rootElement)
      .select(`#entryId_${this.selectedEntryId}`);
    previouslySelectedNode
      .select(".nodeRect")
      .attr("fill", this.graphConfig.nodeColor);
    previouslySelectedNode
      .select(".nodeRect")
      .attr("stroke-width", this.graphConfig.nodeStrokeWidth);
    previouslySelectedNode
      .select(".nodeText")
      .attr("fill", this.graphConfig.nodeTextColor);

    previouslySelectedNode
      .select(".nodeIndicatorRect")
      .attr("fill", this.graphConfig.indicatorColor);
    previouslySelectedNode
      .select(".nodeIndicatorText")
      .attr("fill", this.graphConfig.indicatorTextColor);
  }

  preSelectRootNode(rootElement) {
    this.updateRootNodeStyleForSelection(rootElement);
  }

  selectRootNode(rootElement) {
    this.selectedEntryId = null;
    setTimeout(() => {
      this.entryContextMenu.showOn(document.querySelector(`#entryId_-1`), null);
    }, 0);

    this.updateRootNodeStyleForSelection(rootElement);
  }

  deselectRootNode(rootElement) {
    const rootNode = d3.select(rootElement).select("#entryId_-1");
    rootNode
      .select("circle")
      .attr("stroke", this.graphConfig.nodeSelectedColor)
      .attr("stroke-width", 0);

    rootNode
      .select(".nodeIndicatorRect")
      .attr("fill", this.graphConfig.indicatorColor);

    d3.select(rootElement)
      .select("#entryId_-1")
      .select(".rootNodeSize")
      .select(".nodeIndicatorText")
      .attr("fill", this.graphConfig.indicatorTextColor);
  }

  showRootNodeHiddenChildrenIfNeeded(rootElement, selectedEntryId, entries) {
    for (const entry of entries) {
      if (entry.parentEntryId == null && entry.id != selectedEntryId) {
        const rootNode = d3.select(rootElement).select("#entryId_-1");
        rootNode
          .select(".nodeIndicatorHiddenChildrenRect")
          .attr("display", (d) => {
            return "inline";
          });
        return;
      }
    }
  }
}

export default EntrySelection;
