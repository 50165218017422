import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./Auth.module.scss";
import Login from "./Login/Login";
import Register from "./Register/Register";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import logo from "../../logo_white.svg";
import RequestAccess from "./RequestAccess/RequestAccess";
import ReCaptchaWrapper from "./ReCaptchaWrapper/ReCaptchaWrapper";
// import usePlausibleAnalytics from "../Utils/PlausibleAnalytics";

const propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

const Auth = ({ match: { url } }) => {
  const { t } = useTranslation();
  // usePlausibleAnalytics();

  useEffect(() => {
    let captcha = document.getElementsByClassName("grecaptcha-badge")[0];
    if (captcha) {
      captcha.style.removeProperty("visibility");
    }
    return () => {
      // eslint-disable-next-line prefer-destructuring
      captcha = document.getElementsByClassName("grecaptcha-badge")[0];
      if (captcha) {
        captcha.style.visibility = "hidden";
      }
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={`${styles.logoContainer}`}>
          <a
            className={styles.logoLink}
            href={process.env.REACT_APP_LINK_WEBSITE}
          >
            <img className={styles.logo} src={logo} alt="logo" />
          </a>
        </div>
        <div className={styles.authBox}>
          <Switch>
            <Route path={`${url}/login`} component={Login} />
            <Route
              path={`${url}/register`}
              component={() => {
                return (
                  <ReCaptchaWrapper>
                    <Register />
                  </ReCaptchaWrapper>
                );
              }}
            />
            <Route
              path={`${url}/forgot-password`}
              component={() => {
                return (
                  <ReCaptchaWrapper>
                    <ForgotPassword />
                  </ReCaptchaWrapper>
                );
              }}
            />
            <Route path={`${url}/reset-password`} component={ResetPassword} />
            <Route
              path={`${url}/request-access`}
              component={() => {
                return (
                  <ReCaptchaWrapper>
                    <RequestAccess />
                  </ReCaptchaWrapper>
                );
              }}
            />
            <Redirect from="/" to={`${url}/login`} />
          </Switch>
        </div>
      </div>
      <div className={styles.footerContainer}>
        {process.env.REACT_APP_LINK_IMPRINT != null &&
        process.env.REACT_APP_LINK_IMPRINT.length > 0 ? (
          <a href={process.env.REACT_APP_LINK_IMPRINT}>
            {t("wisdomtree.imprint")}
          </a>
        ) : null}
        {process.env.REACT_APP_LINK_PRIVACY != null &&
        process.env.REACT_APP_LINK_PRIVACY.length > 0 ? (
          <a href={process.env.REACT_APP_LINK_PRIVACY}>
            {t("wisdomtree.privacy")}
          </a>
        ) : null}
        {process.env.REACT_APP_LINK_TERMS != null &&
        process.env.REACT_APP_LINK_TERMS.length > 0 ? (
          <a href={process.env.REACT_APP_LINK_TERMS}>{t("wisdomtree.terms")}</a>
        ) : null}
      </div>
    </div>
  );
};

Auth.propTypes = propTypes;

export default Auth;
