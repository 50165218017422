import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { useHotkey } from "../../Utils/Hotkeys";
import styles from "./EntryBrowser.module.scss";
import EntryBrowserHome from "./EntryBrowserHome/EntryBrowserHome";
import EntryBrowserNewEntry from "./EntryBrowserNewEntry/EntryBrowserNewEntry";
import EntryBrowserSelectedEntry from "./EntryBrowserSelectedEntry/EntryBrowserSelectedEntry";

const propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  newMode: PropTypes.bool,
  editMode: PropTypes.bool,
  sharingId: PropTypes.string,
  sharingRootEntryId: PropTypes.string,
  selectedEntry: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  selectedEntryDetails: PropTypes.shape({
    references: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
        target: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
      })
    ),
    referencedBy: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
        target: PropTypes.shape({
          entry: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          block: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
      })
    ),
  }),
  selectedEntryDetailsLoading: PropTypes.bool,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  onCreateEntry: PropTypes.func.isRequired,
  onDeleteEntry: PropTypes.func.isRequired,
  newEntryDraft: PropTypes.shape({
    name: PropTypes.string,
  }),
  onUpdateNewEntryDraft: PropTypes.func.isRequired,
  onUpdateEntry: PropTypes.func.isRequired,
  onUpdateEntryImage: PropTypes.func.isRequired,
  newEntryLoading: PropTypes.bool,
  deleteEntryLoading: PropTypes.bool,
  dialogOpened: PropTypes.bool,
  onDialogOpenedChanged: PropTypes.func.isRequired,
  graphContainerHeight: PropTypes.number.isRequired,
};

const defaultProps = {
  user: null,
  newMode: false,
  editMode: false,
  sharingId: null,
  sharingRootEntryId: null,
  selectedEntry: null,
  selectedEntryDetails: null,
  selectedEntryDetailsLoading: false,
  entries: [],
  newEntryDraft: null,
  newEntryLoading: false,
  deleteEntryLoading: false,
  dialogOpened: false,
};

const EntryBrowser = ({
  user,
  selectedEntry,
  selectedEntryDetails,
  selectedEntryDetailsLoading,
  entries,
  newMode,
  editMode,
  sharingId,
  sharingRootEntryId,
  newEntryDraft,
  onCreateEntry,
  onDeleteEntry,
  onUpdateNewEntryDraft,
  onUpdateEntry,
  onUpdateEntryImage,
  newEntryLoading,
  deleteEntryLoading,
  dialogOpened,
  onDialogOpenedChanged,
  graphContainerHeight,
}) => {
  const history = useHistory();

  useHotkey({
    keyNames: ["+"],
    callback: () => {
      if (editMode && !newMode && !dialogOpened) {
        const newUrl = selectedEntry
          ? `/entry/${selectedEntry.id}?new=true`
          : `/home?new=true`;
        history.push(newUrl);
      }
    },
    skipIfInEditor: true,
    dependsOn: [editMode, newMode, dialogOpened, selectedEntry, history],
  });

  let content = null;
  if (entries) {
    // entries are loaded
    if (newMode) {
      content = (
        <EntryBrowserNewEntry
          selectedEntryId={selectedEntry ? selectedEntry.id : null}
          newEntryDraft={newEntryDraft}
          onCreateEntry={onCreateEntry}
          onUpdateNewEntryDraft={onUpdateNewEntryDraft}
          newEntryLoading={newEntryLoading}
          graphContainerHeight={graphContainerHeight}
          onDialogOpenedChanged={onDialogOpenedChanged}
          dialogOpened={dialogOpened}
        />
      );
    } else if (selectedEntry) {
      // an entry is selected
      content = (
        <EntryBrowserSelectedEntry
          selectedEntry={selectedEntry}
          selectedEntryDetails={selectedEntryDetails}
          selectedEntryDetailsLoading={selectedEntryDetailsLoading}
          sharingId={sharingId}
          sharingRootEntryId={sharingRootEntryId}
          entries={entries}
          user={user}
          editMode={editMode}
          onDeleteEntry={onDeleteEntry}
          onUpdateEntry={onUpdateEntry}
          onUpdateEntryImage={onUpdateEntryImage}
          deleteEntryLoading={deleteEntryLoading}
          dialogOpened={dialogOpened}
          onDialogOpenedChanged={onDialogOpenedChanged}
          graphContainerHeight={graphContainerHeight}
        />
      );
    } else {
      // no selected entry, show list of all top level entries
      content = (
        <EntryBrowserHome
          editMode={editMode}
          user={user}
          entries={entries}
          sharingId={sharingId}
          onDialogOpenedChanged={onDialogOpenedChanged}
          graphContainerHeight={graphContainerHeight}
        />
      );
    }
  }

  return (
    <div id={styles.entryBrowser} className="columns is-mobile">
      <div className="column" />
      <div
        id="ContentContainer"
        className={`${styles.contentContainer} column is-11-mobile is-two-thirds-tablet is-half-widescreen`}
      >
        {content}
      </div>
      <div className="column" />
    </div>
  );
};

EntryBrowser.propTypes = propTypes;
EntryBrowser.defaultProps = defaultProps;

export default EntryBrowser;
