import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "./DataExport.module.scss";
import { useTranslation } from "react-i18next";
import {
  GET_EXISTING_EXPORT,
  mergeExistingAfterStart,
  START_EXPORT,
} from "../../../../GraphQl/export";
import { useMutation, useQuery } from "@apollo/client";
import Logger from "js-logger";
import Spinner from "../../../Spinner/Spinner";
import { TooltipDelayed } from "../../../Tooltip/Tooltip";

const propTypesDataExport = {};
const defaultPropsDataExport = {};

const DataExport = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const {
    loading: getExistingExportLoading,
    data: getExistingExportData,
    startPolling,
    stopPolling,
  } = useQuery(GET_EXISTING_EXPORT, {
    fetchPolicy: "cache-and-network",
    pollInterval: 2000,
  });
  const existingExport = getExistingExportData
    ? getExistingExportData.existingExport
    : null;

  useEffect(() => {
    if (!existingExport || (existingExport && !existingExport.running)) {
      setLoading(false);
      stopPolling();
    } else if (existingExport && existingExport.running) {
      startPolling(2000);
    }
  }, [existingExport]);

  const [startExport, { loading: startExportLoading }] = useMutation(
    START_EXPORT,
    {
      update: mergeExistingAfterStart,
      onError: (err) => {
        Logger.error(err);
        setLoading(false);
      },
    }
  );

  let content;

  if (loading) {
    content = (
      <div className={styles.contentContainer}>
        <button
          className={`button is-loading ${styles.mainButton}`}
          type="button"
        />
        <p className={styles.infoText}>
          {t("wisdomtree.modal_user_settings.export.info_loading")}
        </p>
      </div>
    );
  } else if (existingExport && !existingExport.failed && existingExport.url) {
    content = (
      <div className={styles.contentContainer}>
        <div className={styles.buttonsContainer}>
          <a
            className={`button ${styles.mainButton}`}
            href={existingExport.url}
          >
            {t("wisdomtree.modal_user_settings.export.button_download")}
          </a>
          <TooltipDelayed
            content={t(
              "wisdomtree.modal_user_settings.export.button_refresh_tooltip"
            )}
          >
            <button
              className={`button ${styles.refreshButton}`}
              type="button"
              onClick={() => {
                setLoading(true);
                startExport();
              }}
            >
              <span className={`${styles.icon} icon is-small`}>
                <i className="fas fa-sync-alt"></i>
              </span>
            </button>
          </TooltipDelayed>
        </div>
        <p className={styles.infoText}>
          {t("wisdomtree.modal_user_settings.export.info_created_at", {
            time: moment(existingExport.createdAt, "x").format("LLL"),
          })}
        </p>
      </div>
    );
  } else {
    content = (
      <div className={styles.contentContainer}>
        <button
          className={`button ${styles.mainButton}`}
          data-id="button.data-export"
          type="button"
          disabled={startExportLoading}
          onClick={() => {
            setLoading(true);
            startExport();
          }}
        >
          {t("wisdomtree.modal_user_settings.export.button_start")}
        </button>
        {existingExport && existingExport.failed ? (
          <p className={`${styles.infoText} ${styles.error}`}>
            {t("wisdomtree.modal_user_settings.export.info_failed")}
          </p>
        ) : null}
      </div>
    );
  }

  return <div className={styles.container}>{content}</div>;
};

DataExport.propTypes = propTypesDataExport;
DataExport.defaultProps = defaultPropsDataExport;

export default DataExport;
