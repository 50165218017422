import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import styles from "./MessageList.module.scss";
import Message from "./Message/Message";

const propTypesMessageList = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      fromUser: PropTypes.shape({
        id: PropTypes.string
      }),
      toUser: PropTypes.shape({
        id: PropTypes.string
      })
    })
  ).isRequired,
  isUserChat: PropTypes.bool.isRequired
};
const defaultPropsMessageList = {};

const MessageList = ({ isUserChat, messages }) => {
  const messageContainer = useRef(null);

  useEffect(() => {
    if (messageContainer.current) {
      messageContainer.current.scrollTop =
        messageContainer.current.scrollHeight;
    }
  }, [messages, messageContainer.current]);

  const isFromCurrentUser = (message, userChat) => {
    return userChat ? message.toUser == null : message.toUser != null;
  };

  const messagesContent = [];
  messages.forEach((message, index) => {
    let lastMessage = false;

    if (index + 1 === messages.length) {
      lastMessage = true;
    } else if (
      messages[index + 1].fromUser.id !== messages[index].fromUser.id ||
      isFromCurrentUser(messages[index + 1], isUserChat) !==
        isFromCurrentUser(messages[index], isUserChat)
    ) {
      lastMessage = true;
    }

    let useImage = true;
    if (isFromCurrentUser(messages[index], isUserChat) && isUserChat) {
      useImage = false;
    }

    messagesContent.push(
      <Message
        key={message.id}
        message={message}
        fromCurrentUser={isFromCurrentUser(message, isUserChat)}
        lastMessage={lastMessage}
        useImage={useImage}
      />
    );
  });

  if (messagesContent.length === 0) return <div />;

  return (
    <div className={styles.messageContainer} ref={messageContainer}>
      {messagesContent}
    </div>
  );
};

MessageList.propTypes = propTypesMessageList;
MessageList.defaultProps = defaultPropsMessageList;

export default MessageList;
