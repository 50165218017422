/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
import styles from "./DeleteCategoryModal.module.css";
import { useHotkey } from "../../../Utils/Hotkeys";
import BaseModal, { useBaseModal } from "../BaseModal/BaseModal";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";

const propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onDeleteCategory: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  nested: PropTypes.bool,
};

const defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  loading: false,
  nested: false,
};

const useDeleteCategoryModal = ({
  onOpen,
  onClose,
  onDeleteCategory,
  loading,
  nested,
}) => {
  const { isOpened, open, close } = useBaseModal({
    onOpen,
    onClose,
    isNested: nested,
  });
  const [category, setCategory] = useState(null);

  let content = null;
  if (isOpened) {
    content = (
      <DeleteCategoryModal
        show={isOpened}
        onCloseHandler={close}
        category={category}
        onDeleteHandler={onDeleteCategory}
        deleteLoading={loading}
        nested={nested}
      />
    );
  }

  const openModal = (categoryToDelete) => {
    setCategory(categoryToDelete);
    open();
  };

  const closeModal = () => {
    close();
    setCategory(null);
  };

  return { open: openModal, close: closeModal, content };
};

useDeleteCategoryModal.propTypes = propTypes;
useDeleteCategoryModal.defaultProps = defaultProps;

const propTypesModal = {
  show: PropTypes.bool.isRequired,
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  deleteLoading: PropTypes.bool.isRequired,
  onDeleteHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  nested: PropTypes.bool,
};

const defaultPropsModal = {
  nested: false,
};

const DeleteCategoryModal = ({
  show,
  nested,
  category,
  deleteLoading,
  onDeleteHandler,
  onCloseHandler,
}) => {
  const { t } = useTranslation();
  const text = t("wisdomtree.modal_delete_category.text", {
    category_name: category.name,
  });

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!show) return;
      onDeleteHandler(category);
    },
    dependsOn: [onDeleteHandler, show],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (!show) return;
      onCloseHandler();
    },
    dependsOn: [onCloseHandler, show],
  });

  return (
    <BaseModal show={show} onCloseHandler={onCloseHandler} nested={nested}>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {t("wisdomtree.modal_delete_category.title")}
          </p>
          <ModalCloseButton onClick={onCloseHandler} disabled={deleteLoading} />
        </header>
        <section className="modal-card-body">
          <p>{text}</p>
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-danger ${deleteLoading ? `is-loading` : ``}`}
            type="submit"
            onClick={() => {
              onDeleteHandler(category);
            }}
          >
            {t("wisdomtree.modal_delete_category.delete")}
          </button>
          <button
            className="button"
            type="button"
            onClick={onCloseHandler}
            disabled={deleteLoading}
          >
            {t("wisdomtree.modal_delete_category.cancel")}
          </button>
        </footer>
      </div>
    </BaseModal>
  );
};

DeleteCategoryModal.propTypes = propTypesModal;
DeleteCategoryModal.defaultProps = defaultPropsModal;

export default useDeleteCategoryModal;
