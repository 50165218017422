import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./SearchWithSuggestions.module.scss";
import { useMobileOrTablet } from "../../Utils/Responsive";
import SearchResultBlock from "../SearchResultBlock/SearchResultBlock";
import { useHotkey } from "../../Utils/Hotkeys";
import Spinner from "../Spinner/Spinner";
import SearchInput from "../SearchInput/SearchInput";
import SearchResultEntry from "../SearchResultEntry/SearchResultEntry";

const propTypesSearchWithSuggestions = {
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  suggestionsLoading: PropTypes.bool,
  resultsLoading: PropTypes.bool,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      entry: PropTypes.object,
      block: PropTypes.object,
    })
  ),
  results: PropTypes.arrayOf(
    PropTypes.shape({
      entry: PropTypes.object,
      block: PropTypes.object,
    })
  ),
  noResultsMessage: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
};
const defaultPropsSearchWithSuggestions = {
  suggestionsLoading: false,
  resultsLoading: false,
  suggestions: null,
  results: null,
};

const SearchWithSuggestions = ({
  onSearch,
  onSelect,
  onSubmit,
  onCancel,
  suggestionsLoading,
  resultsLoading,
  suggestions,
  results,
  noResultsMessage,
  searchPlaceholder,
}) => {
  const isMobileOrTablet = useMobileOrTablet();
  const [searchText, setSearchText] = useState("");
  const [selection, setSelection] = useState(null);
  const [duplicateResultsMap, setDuplicateResultsMap] = useState(new Map());

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      const hasFocussedEntity =
        document.activeElement &&
        document.activeElement.getAttribute("data-search-id") != null;
      const focussedEntityId = document.activeElement.getAttribute(
        "data-search-id"
      );
      const focussedEntityType = document.activeElement.getAttribute(
        "data-search-type"
      );
      if (
        selection &&
        (!hasFocussedEntity || focussedEntityId === selection.entity.id)
      ) {
        onSubmit(selection);
      } else if (hasFocussedEntity) {
        let focussedEntity = null;

        let relevantResults = searchText.length > 0 ? results : suggestions;
        if (focussedEntityType === "entry") {
          const matchingResult = relevantResults.find(
            (result) => result.entry && result.entry.id === focussedEntityId
          );
          focussedEntity = matchingResult.entry;
        } else {
          const matchingResult = relevantResults.find(
            (result) => result.block && result.block.id === focussedEntityId
          );
          focussedEntity = matchingResult.block;
        }

        setSelection({ entity: focussedEntity, type: focussedEntityType });
      }
    },
    dependsOn: [onSubmit, results, suggestions, selection],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      if (
        searchText.length !== 0 &&
        document.activeElement.className.includes("input")
      ) {
        setSearchText("");
        return;
      }

      onCancel();
    },
    dependsOn: [onCancel, searchText],
  });

  useEffect(() => {
    if (selection) {
      let relevantResults = searchText.length > 0 ? results : suggestions;

      if (
        !relevantResults ||
        relevantResults.find(
          (result) =>
            (result.entry && result.entry.id === selection.entity.id) ||
            (result.block && result.block.id === selection.entity.id)
        ) == null
      ) {
        setSelection(null);
      }
    }
  }, [results, searchText]);

  useEffect(() => {
    onSelect(selection);
  }, [selection]);

  useEffect(() => {
    let relevantResults = searchText.length > 0 ? results : suggestions;
    if (relevantResults) {
      const map = new Map();

      relevantResults.forEach((result) => {
        if (result.entry) {
          const similarEntry = relevantResults.find((r) => {
            return (
              r.entry &&
              r.entry.name.toUpperCase() === result.entry.name.toUpperCase() &&
              r.entry.id !== result.entry.id
            );
          });
          map.set("entry_" + result.entry.id, similarEntry != null);
        } else if (result.block) {
          const similarBlock = relevantResults.find((r) => {
            return (
              r.block &&
              r.block.entry.name.toUpperCase() ===
                result.block.entry.name.toUpperCase() &&
              r.block.entry.id !== result.block.entry.id
            );
          });
          map.set("block_" + result.block.id, similarBlock != null);
        }
      });
      setDuplicateResultsMap(map);
    }
  }, [results, suggestions, searchText]);

  const handleSearchTextChange = (event) => {
    const text = event.target.value;
    setSearchText(text);

    if (text.length >= 1) {
      onSearch(text);
    }
  };

  const handleSearchTextClear = () => {
    setSearchText("");
  };

  let searchResultContent = null;

  let relevantResults = searchText.length > 0 ? results : suggestions;
  let relevantLoading =
    searchText.length > 0 ? resultsLoading : suggestionsLoading;

  if (relevantResults) {
    if (relevantResults.length > 0) {
      searchResultContent = relevantResults.map((searchResult) => {
        if (searchResult.entry) {
          return (
            <SearchResultEntry
              key={`entry_${searchResult.entry.id}`}
              entry={searchResult.entry}
              onClick={() => {
                setSelection({ type: "entry", entity: searchResult.entry });
              }}
              selected={
                selection &&
                selection.type === "entry" &&
                selection.entity.id === searchResult.entry.id
              }
              duplicate={duplicateResultsMap.get(
                "entry_" + searchResult.entry.id
              )}
              home={searchResult.entry.id === "-1"}
            />
          );
        } else if (searchResult.block) {
          return (
            <SearchResultBlock
              key={`block_${searchResult.block.id}`}
              block={searchResult.block}
              onClick={() =>
                setSelection({ type: "block", entity: searchResult.block })
              }
              selected={
                selection &&
                selection.type === "block" &&
                selection.entity.id === searchResult.block.id
              }
              duplicate={duplicateResultsMap.get(
                "block_" + searchResult.block.id
              )}
            />
          );
        }
      });
    } else {
      searchResultContent = (
        <p className={styles.noResults}>{noResultsMessage}</p>
      );
    }
  } else if (relevantLoading) {
    searchResultContent = <Spinner />;
  }

  return (
    <div>
      <div className={styles.searchContainer}>
        <SearchInput
          placeholder={searchPlaceholder}
          autoFocus={!isMobileOrTablet}
          value={searchText}
          onChange={handleSearchTextChange}
          onClear={handleSearchTextClear}
        />
      </div>

      <div className={styles.resultsContainer}>{searchResultContent}</div>
    </div>
  );
};

SearchWithSuggestions.propTypes = propTypesSearchWithSuggestions;
SearchWithSuggestions.defaultProps = defaultPropsSearchWithSuggestions;

export default SearchWithSuggestions;
