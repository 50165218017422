import React, { useState } from "react";
import PropTypes from "prop-types";
import Logger from "js-logger";

import styles from "./EditWeb.module.scss";
import { useTranslation } from "react-i18next";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import { useMutation } from "@apollo/client";
import {
  UPDATE_REFERENCE,
  mergeAfterUpdate,
} from "../../../../GraphQl/references";
import { useHotkey } from "../../../../Utils/Hotkeys";
import EditReferenceText from "../../../References/EditReferenceText/EditReferenceText";
import EditReferenceWeb from "../../../References/EditReferenceWeb/EditReferenceWeb";
import { FETCH_WEB_LINK } from "../../../../GraphQl/webLinks";

const propTypesAddInternalLink = {
  onCloseModal: PropTypes.func.isRequired,
  reference: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    descriptionTo: PropTypes.string,
    webLink: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      iconUrl: PropTypes.string,
    }),
  }).isRequired,
};
const defaultPropsAddInternalLink = {};

const EditWeb = ({ onCloseModal, reference }) => {
  const { t } = useTranslation();

  const [webLink, setWebLink] = useState(reference.webLink);
  const [attemptedFetch, setAttemptedFetch] = useState(true);
  const [urlText, setUrlText] = useState(reference.webLink.url);
  const [titleText, setTitleText] = useState(
    reference.title ? reference.title : ""
  );
  const [descriptionText, setDescriptionText] = useState(
    reference.descriptionTo ? reference.descriptionTo : ""
  );

  const onChangeUrlTextHandler = (event) => {
    const url = event.target.value;
    setUrlText(url);

    if (url && url.length > 0) {
      fetchWebLink({
        variables: {
          url: url,
        },
        context: {
          debounceKey: `fetchWebLink`,
          debounceTimeout: 600,
        },
      });
    }
  };

  const onChangeTitleTextHandler = (event) => {
    const title = event.target.value;
    setTitleText(title);
  };

  const onChangeDescriptionTextHandler = (event) => {
    const description = event.target.value;
    setDescriptionText(description);
  };

  const [fetchWebLink, { loading: fetchWebLinkLoading }] = useMutation(
    FETCH_WEB_LINK,
    {
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: (data) => {
        setAttemptedFetch(true);

        const newWebLink = data.fetchWebLink;
        if (
          titleText.length === 0 ||
          (webLink != null && webLink.title === titleText)
        ) {
          setTitleText(newWebLink && newWebLink.title ? newWebLink.title : "");
        }

        if (newWebLink) {
          setWebLink(newWebLink);
          document.getElementById("submitButton").focus();
        } else {
          setWebLink(null);
        }
      },
    }
  );

  const [updateReference, { loading: updateReferenceLoading }] = useMutation(
    UPDATE_REFERENCE,
    {
      update: (cache, mutationResult) => {
        mergeAfterUpdate(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {
        onCloseModal();
      },
    }
  );

  const urlChanged = urlText.length > 0 && urlText !== reference.webLink.url;
  const titleChanged = titleText.length > 0 && titleText !== reference.title;
  const descriptionChanged =
    !(reference.descriptionTo == null && descriptionText.length === 0) &&
    descriptionText !== reference.descriptionTo;

  const canUpdate =
    !updateReferenceLoading &&
    !fetchWebLinkLoading &&
    webLink != null &&
    (urlChanged || titleChanged || descriptionChanged);

  const onSubmit = () => {
    updateReference({
      variables: {
        id: reference.id,
        input: {
          title: titleText,
          descriptionTo: descriptionText.length > 0 ? descriptionText : null,
          webLinkId: webLink.id,
        },
      },
    });
  };

  useHotkey({
    keyNames: ["Enter"],
    callback: () => {
      if (!webLink || !canUpdate || updateReferenceLoading) return;
      onSubmit();
    },
    dependsOn: [onSubmit, canUpdate, webLink, updateReferenceLoading],
  });

  useHotkey({
    keyNames: ["Escape"],
    callback: () => {
      onCloseModal();
    },
    dependsOn: [onCloseModal],
  });

  const content = (
    <EditReferenceWeb
      attemptedFetch={attemptedFetch}
      webLink={webLink}
      url={urlText}
      title={titleText}
      description={descriptionText}
      fetchWebLinkLoading={fetchWebLinkLoading}
      onChangeUrl={onChangeUrlTextHandler}
      onChangeTitle={onChangeTitleTextHandler}
      onChangeDescription={onChangeDescriptionTextHandler}
    />
  );

  return (
    <div className={`modal-card`}>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {t("wisdomtree.modal_edit_reference.edit_web.title")}
        </p>
        <ModalCloseButton onClick={onCloseModal} />
      </header>
      <section className={`modal-card-body ${styles.body}`}>{content}</section>
      <footer className="modal-card-foot">
        <button
          id="submitButton"
          className={`button is-primary ${
            updateReferenceLoading ? `is-loading` : ``
          }`}
          type="submit"
          disabled={!canUpdate}
          onClick={() => {
            onSubmit();
          }}
        >
          {t("wisdomtree.modal_edit_reference.edit_web.edit")}
        </button>
        <button className="button" type="button" onClick={onCloseModal}>
          {t("wisdomtree.modal_edit_reference.edit_web.cancel")}
        </button>
      </footer>
    </div>
  );
};

EditWeb.propTypes = propTypesAddInternalLink;
EditWeb.defaultProps = defaultPropsAddInternalLink;

export default EditWeb;
