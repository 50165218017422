import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isMobile as isMobileDevice } from "react-device-detect";

import styles from "./ColorPicker.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_COLOR,
  DELETE_COLOR,
  GET_COLORS,
  mergeColorsAfterCreate,
  mergeColorsAfterDelete,
} from "../../GraphQl/colors";
import ColorSwatch from "./ColorSwatch/ColorSwatch";
import Spinner from "../Spinner/Spinner";
import ColorEditPopup from "./ColorEditPopup/ColorEditPopup";
import Logger from "js-logger";

const propTypesColorPicker = {
  selectedColor: PropTypes.shape({
    id: PropTypes.string,
    hex: PropTypes.string,
  }),
  onColorSelected: PropTypes.func,
  autoSelect: PropTypes.bool,
  onPopupShownChanged: PropTypes.func,
};
const defaultPropsColorPicker = {
  selectedColor: null,
  onColorSelected: () => {},
  autoSelect: false,
  onPopupShownChanged: () => {},
};

const ColorPicker = ({
  selectedColor,
  onColorSelected,
  autoSelect,
  onPopupShownChanged,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [lastCreatedColorId, setLastCreatedColorId] = useState(null);
  const [deleteColorLoadingId, setDeleteColorLoadingId] = useState(null);
  const [popupOpened, setPopupOpened] = useState(false);

  useEffect(() => {
    onPopupShownChanged(popupOpened);

    if (!popupOpened) setIsFocused(false);
  }, [popupOpened]);

  const { loading: getColorsLoading, data: getColorsData } = useQuery(
    GET_COLORS
  );
  const colors = getColorsData ? getColorsData.colors : null;

  const [createColor, { loading: createColorLoading }] = useMutation(
    CREATE_COLOR,
    {
      update: (cache, mutationResult) => {
        const { createdColor } = mutationResult.data.createColor;
        if (createdColor) {
          onColorSelected(createdColor);
          setLastCreatedColorId(createdColor.id);
        }
        mergeColorsAfterCreate(cache, mutationResult);
      },
      onError: (err) => {
        Logger.error(err);
      },
      onCompleted: () => {},
    }
  );

  const [deleteColor, { loading: deleteColorLoading }] = useMutation(
    DELETE_COLOR,
    {
      update: (cache, mutationResult) => {
        const { deletedColorIds } = mutationResult.data.deleteColor;
        const deletedColorId = deletedColorIds[0];

        if (selectedColor && deletedColorId === selectedColor.id) {
          const deletedColorIndex = colors.findIndex(
            (c) => c.id === deletedColorId
          );
          let newIndex;
          if (deletedColorIndex > 0) {
            newIndex = deletedColorIndex - 1;
          } else {
            newIndex = deletedColorIndex + 1;
          }
          onColorSelected(colors[newIndex]);
        }

        mergeColorsAfterDelete(cache, mutationResult);
      },
      onError: (err) => {
        setDeleteColorLoadingId(null);
        Logger.error(err);
      },
      onCompleted: () => {
        setDeleteColorLoadingId(null);
        setPopupOpened(false);
      },
    }
  );

  const scrollToColorSwatch = (color) => {
    const container = document.getElementById("colorContainer");
    const colorSwatch = document.getElementById(color.id);

    if (container && colorSwatch) {
      const widthContainer = parseInt(window.getComputedStyle(container).width);
      const widthElement = parseInt(window.getComputedStyle(colorSwatch).width);

      const offsetLeftElement = colorSwatch.offsetLeft;

      const isOutsideScroll =
        offsetLeftElement + widthElement >
        widthContainer + container.scrollLeft;

      if (isOutsideScroll) {
        container.scrollLeft =
          offsetLeftElement + widthElement * 2 - widthContainer;
      }
    }
  };

  useEffect(() => {
    if (colors && autoSelect && !selectedColor) {
      onColorSelected(colors[0]);
    }
  }, [colors, selectedColor]);

  useEffect(() => {
    if (selectedColor) {
      setTimeout(() => {
        scrollToColorSwatch(selectedColor);
      }, 50);
    }
  }, [selectedColor]);

  const handleCreateColor = () => {
    createColor({
      variables: {
        input: {},
      },
    });
  };

  const handleDeleteColor = (colorToDelete) => {
    setDeleteColorLoadingId(colorToDelete.id);
    deleteColor({
      variables: {
        id: colorToDelete.id,
      },
    });
  };

  let colorsContent = null;
  let newColorContent = null;
  if (colors) {
    colorsContent = colors.map((color) => (
      <ColorEditPopup
        key={color.id}
        disabled={!selectedColor || selectedColor.id != color.id}
        color={color}
        openInEdit={lastCreatedColorId && color.id === lastCreatedColorId}
        onDeleteColor={(colorToDelete) => {
          handleDeleteColor(colorToDelete);
        }}
        deleteLoading={
          deleteColorLoading &&
          deleteColorLoadingId &&
          deleteColorLoadingId === color.id
        }
        onPopupShownChanged={setPopupOpened}
        hasDelete={colors.length > 1}
      >
        <div id={`${color.id}`} className={styles.swatchContainer}>
          <ColorSwatch
            color={color}
            isSelected={selectedColor ? selectedColor.id === color.id : false}
            onSelectColor={(color) => {
              onColorSelected(color);
              setTimeout(() => {
                document.activeElement.blur();
              }, 50);
            }}
          />
        </div>
      </ColorEditPopup>
    ));

    if (createColorLoading) {
      newColorContent = (
        <div className={styles.newLoadingContainer}>
          <Spinner size={8} />
        </div>
      );
    } else {
      const showNewButton = isMobileDevice || isHovered || isFocused;
      newColorContent = (
        <span
          onClick={() => {
            handleCreateColor();
          }}
          onKeyDown={(e) => {
            // enter key
            if (e.keyCode === 13) {
              setTimeout(() => {
                handleCreateColor();
              }, 50);
            }
          }}
          role="button"
          tabIndex="0"
          className={`tag "is-light" is-normal new-color ${styles.plusButton} ${
            !showNewButton ? styles.hidden : null
          }`}
        >
          +
        </span>
      );
    }
  } else if (getColorsLoading) {
    colorsContent = (
      <div className={styles.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={styles.container}
      id={`colorContainer`}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
    >
      <div className={styles.innerContainer}>
        <div className={styles.colorsContainer}>{colorsContent}</div>
        {newColorContent}
      </div>
    </div>
  );
};

ColorPicker.propTypes = propTypesColorPicker;
ColorPicker.defaultProps = defaultPropsColorPicker;

export default ColorPicker;
