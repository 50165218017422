/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

import styles from "./CommonEntriesList.module.scss";
import { GET_COMMON_ENTRIES_FILTERED_WITH_VARIANTS } from "../../../GraphQl/commonEntries";
import Spinner from "../../../UI/Spinner/Spinner";
import CommonEntry from "./CommonEntry/CommonEntry";

const propTypesCommonEntriesList = {};
const defaultPropsCommonEntriesList = {};

const CommonEntriesList = () => {
  const [searchText, setSearchText] = useState("");
  const [excludeDisapproved, setExcludeDisapproved] = useState(true);

  const {
    loading: getCommonEntriesLoading,
    data: getCommonEntriesData,
    refetch: refetchCommonEntries,
  } = useQuery(GET_COMMON_ENTRIES_FILTERED_WITH_VARIANTS, {
    pollInterval: 10000,
    variables: {
      input: {
        searchText,
        excludeDisapproved,
      },
    },
  });
  const commonEntries = getCommonEntriesData
    ? getCommonEntriesData.commonEntriesFiltered
    : [];

  useEffect(() => {
    refetchCommonEntries();
  }, []);

  let content = null;
  if (getCommonEntriesLoading) {
    content = <Spinner />;
  } else {
    const commonEntriesContent = commonEntries.map((commonEntry) => {
      return <CommonEntry key={commonEntry.id} commonEntry={commonEntry} />;
    });
    content = <div className={styles.container}>{commonEntriesContent}</div>;
  }
  return (
    <div>
      <div className={styles.filters}>
        <div
          className={`control has-icons-left has-icons-right ${styles.searchBar}`}
        >
          <input
            data-id="search.input"
            className={`input ${styles.input}`}
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            spellCheck={false}
            autoComplete="off"
          />
          <span className="icon is-small is-left">
            <i className="fas fa-search" />
          </span>
        </div>
        <div className={styles.checkboxContainer}>
          <div className="pretty p-default p-curve p-smooth">
            <input
              type="checkbox"
              checked={excludeDisapproved}
              onChange={(event) => {
                setExcludeDisapproved(event.target.checked);
              }}
            />
            <div className={`state p-primary ${styles.shareEntriesTitle}`}>
              <label>Exclude Disapproved</label>
            </div>
          </div>
        </div>
      </div>

      {content}
    </div>
  );
};

CommonEntriesList.propTypes = propTypesCommonEntriesList;
CommonEntriesList.defaultProps = defaultPropsCommonEntriesList;

export default CommonEntriesList;
